export const BluetoothEvents = {
  onDeviceFound: 'onDeviceFound',
  onDeviceUpdated: 'onDeviceUpdated',
  onBTStateUpdate: 'onBTStateUpdated',
};
export const BluetoothStates = {
  PoweredOn: 'on',
  PoweredOff: 'off',
};

class BluetoothManager {
  addListener() {}

  removeListener() {}

  getBluetoothState() {
    return BluetoothStates.PoweredOff;
  }
}

export default new BluetoothManager();
