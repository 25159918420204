import React from 'react';
import Icon from 'react-native-vector-icons/FontAwesome5Pro';

export const FontAwesomeIconTypes = [
  'barcode-read',
  'calculator',
  'calendar-alt',
  'caret-down',
  'check-circle',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'coffee-togo',
  'cog',
  'credit-card-front',
  'envelope',
  'external-link',
  'heart-circle',
  'home',
  'info-circle',
  'mobile-android-alt',
  'mug-hot',
  'paper-plane',
  'plus-circle',
  'plus',
  'ribbon',
  'running',
  'search',
  'share-alt',
  'shopping-cart',
  'star',
  'step-forward',
  'store-alt',
  'times-circle',
  'times',
  'trash-alt',
  'usd-circle',
  'utensils',
] as const;

export type FontAwesomeIconType = (typeof FontAwesomeIconTypes)[number];

type FontAwesomeIconProps = {
  name: FontAwesomeIconType;
} & React.ComponentProps<typeof Icon>;

export default function FontAwesomeIcon(
  props: FontAwesomeIconProps,
): JSX.Element {
  return <Icon {...props} />;
}
