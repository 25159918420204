import * as React from 'react';
import MainConsumerContext from 'src/components/MainConsumerContext';
import type {MainConsumerContextType} from 'src/types/Screen';
import MachinesProvider from './machines';
type MainConsumerProviderProps = {
  children: React.ReactNode;
  accountId: string;
  oldContext: MainConsumerContextType;
};
export default function MainConsumerProvider(props: MainConsumerProviderProps) {
  const machines = MachinesProvider(props.accountId);
  const value = {...props.oldContext, machines};
  return (
    <MainConsumerContext.Provider value={value}>
      {props.children}
    </MainConsumerContext.Provider>
  );
}
