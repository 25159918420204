class CrashlyticsEvents {
  log(
    _type: string,
    _name: string,
    _data: string,
    _guid?: string,
    _additionalData?: Record<string, unknown>,
    _response?: Record<string, unknown>,
  ) {
    return null;
  }

  crashLogEnableSetup() {
    return null;
  }
}

export default new CrashlyticsEvents();
