import React from 'react';
import {
  Keyboard,
  Platform,
  TouchableWithoutFeedback,
  StyleSheet,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withScrollToElement from '../../hoc/withScrollToElement';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {ScrollToElementProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import Localized from 'src/constants/AppStrings';
import {linkPayroll} from '../../helpers/LinkPayrollHelper';
import {alertError} from '../../helpers/AlertHelper';
import AppEventTypes from 'src/constants/AppEventTypes';
import Util from 'src/Util';
import uuid from 'src/nativeModules/UUID';
import moment from 'moment';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {ScrollView} from 'react-native-gesture-handler';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type KioskCreateAccountProps = ScrollToElementProps & {
  importId?: string;
  importLocationId?: string;
  navigation?: NavigationProp<KioskCreateAccount>;
};
type KioskCreateAccountState = {
  email: string;
  pin: string;
  hasError: boolean;
  errorMessage?: string;
  previousRoute: string | null;
};

class KioskCreateAccount extends React.Component<
  KioskCreateAccountProps,
  KioskCreateAccountState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: KioskCreateAccountProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      email: '',
      pin: '',
      hasError: false,
      errorMessage: '',
      previousRoute: null,
    };
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'KioskCreateAccountScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState()?.routes,
    );

    this.setState({previousRoute: previousRoute});
  }

  async handleClick() {
    this.context.actions.showSpinner();
    const model = {
      Email: this.state.email,
      Pin: this.state.pin,
    };

    const errorMessage = this.validate(model);

    FirebaseAnalytic.trackEvent('handleClick', 'KioskCreateAccountScreen', {
      ...this.props,
      ...this.state,
      errorMessage,
    });
    if (errorMessage) {
      alertError(errorMessage);
      this.setState({
        hasError: true,
        errorMessage: errorMessage,
      });

      this.context.actions.hideSpinner();
    } else {
      try {
        const response = await ActionsFactory.getAccountActions().validatePin(
          model.Email,
          model.Pin,
        );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'ValidatePin',
          JSON.stringify({email: model.Email}),
          JSON.stringify(response),
        );
        FirebaseAnalytic.trackEvent(
          'handleClick validatePin',
          'KioskCreateAccountScreen',
          {
            ...this.props,
            ...this.state,
            response,
          },
        );
        if (response && response.accountId) {
          this.createKioskAccountEvent(response?.accountId);
          if (this.props.importId) {
            // Payroll Link API call
            const linkResult = await linkPayroll(
              this.props.importId,
              this.props.importLocationId,
              response.accountId,
              response.locationId,
            );

            if (!linkResult) {
              return;
            }
          }

          NavActions.push(AppRoutes.KioskCreatePassword, {
            data: {
              accountId: response.accountId,
              email: model.Email,
              isPasswordSet: response.isPasswordSet,
            },
          });
        } else {
          const error: any = new Error(Localized.Errors.invalid_email_or_pin);
          error.showMessage = true;

          this.setState({
            hasError: true,
            errorMessage: Localized.Errors.invalid_email_or_pin,
          });
          throw error;
        }
      } catch (e) {
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'ValidatePin',
          JSON.stringify({email: model.Email}),
          JSON.stringify(e),
        );
        if (!e.showMessage) {
          CrashlyticsEvents.log(
            'Exception',
            'KioskCreateAccountScreen:HandleClick',
            e.message ? e.message : e.toString(),
          );
          Events.Error.trackEvent(
            'Exception',
            'KioskCreateAccountScreen:HandleClick',
            e.message ? e.message : e.toString(),
          );
          alertError(Localized.Errors.invalid_email_or_pin);
        } else {
          alertError(e.message);
        }
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  async createKioskAccountEvent(accountId: string) {
    FirebaseAnalytic.trackEvent(
      'createKioskAccountEvent',
      'KioskCreateAccountScreen',
      {
        ...this.props,
        ...this.state,
        accountId,
      },
    );
    let response;
    try {
      response = await ActionsFactory.getAccountActions().updateAppEvent(
        accountId,
        AppEventTypes.AccountCreated,
        Util.getCurrentUTCDate(),
        Util.getCurrentDate(),
        moment.tz.guess(),
        Util.getAppOsName(),
      );

      FirebaseAnalytic.trackEvent(
        'createKioskAccountEvent updateAppEvent',
        'KioskCreateAccountScreen',
        {
          ...this.props,
          ...this.state,
          accountId,
          response,
        },
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'KioskCreateAccountScreen:AssociateAccountEvent',
        error.message ? error.message : error.toString(),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'KioskCreateAccountScreen:AssociateAccountEvent',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  validate(model: {Email: string; Pin: string}) {
    if (!model.Pin || model.Pin.length < 4) {
      return Localized.Errors.pin_has_to_be_four_digits;
    }

    if (!model.Email || !model.Pin) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  render() {
    return (
      <BaseAccountSetupScreen
        previousRoute={this.state.previousRoute}
        headlineText={Localized.Labels.setup_app}
        instructionText={Localized.Labels.enter_information}
      >
        <ScrollView
          contentContainerStyle={styles.scrollView}
          style={Styles.Style.maxWidthContainer}
          automaticallyAdjustContentInsets={false}
        >
          <TouchableWithoutFeedback
            accessible={false}
            onPress={() => (Platform.OS !== 'web' ? Keyboard.dismiss : null)}
          >
            <View
              style={[styles.inputsContainer, Styles.Style.maxWidthContainer]}
            >
              <AllyTextInput
                label={Localized.Labels.email}
                value={this.state.email}
                hasError={this.state.hasError}
                errorMessage={null}
                helperText={null}
                accessible={true}
                accessibilityLabel="Email"
                accessibilityValue={{text: this.state.email}}
                onChangeText={(text) => this.setState({email: text})}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />

              <AllyTextInput
                label={Localized.Labels.pin}
                value={this.state.pin}
                hasError={this.state.hasError}
                errorMessage={this.state.errorMessage}
                showHelperText={
                  this.state.hasError && this.state.errorMessage ? false : true
                }
                helperText={Localized.Errors.pin_has_to_be_four_digits}
                accessible={true}
                accessibilityLabel="PIN"
                accessibilityValue={{text: this.state.pin}}
                maxLength={4}
                secureTextEntry={true}
                onChangeText={(text) => this.setState({pin: text})}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        <RoundedButton
          accessibilityRole="button"
          accessibilityLabel="Next"
          accessible={true}
          aria-label="Next"
          role="button"
          buttonType={ButtonType.action}
          onPress={this.handleClick}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  scrollView: {
    alignSelf: 'stretch',
    paddingBottom: Styles.Heights.h4,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(KioskCreateAccount),
);
