import * as React from 'react';
import {PixelRatio, StyleSheet} from 'react-native';
import Image from 'react-native-scalable-image';
import type {PromotionType} from 'src/types/Promotions';
import Styles from '../../Styles';
import InViewPort from '../../elements/InViewPort';
import AVTouchableOpacity from '../AVTouchableOpacity';
import {ensureHttps} from 'src/services/StringUtils';

type ImagePromotionProps = {
  navigation: any;
  promotion: PromotionType;
  onClick: () => void;
  isConnected: boolean;
  width: number;
  promotionViewed: (promotion: PromotionType) => void;
};

class ImagePromotion extends React.Component<ImagePromotionProps> {
  constructor(props: ImagePromotionProps) {
    super(props);
    this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
  }

  shouldComponentUpdate(nextProps: ImagePromotionProps) {
    return (
      this.props.promotion.imagePath !== nextProps.promotion.imagePath ||
      this.props.width !== nextProps.width
    );
  }

  onVisibilityChanged(isVisible: boolean) {
    if (isVisible) {
      this.props.promotionViewed(this.props.promotion);
    }
  }

  render() {
    const source = {
      uri: this.props.promotion.fullUrl,
    };

    if (this.props.promotion.local) {
      source.uri = this.props.promotion.imagePath;
    }
    source.uri = ensureHttps(source.uri);
    const promotionSize = {
      width: this.props.width,
    };
    return (
      <InViewPort onChange={this.onVisibilityChanged} stopWhenVisible>
        <AVTouchableOpacity
          onPress={this.props.onClick}
          style={[Styles.Style.promotionContainer, promotionSize]}
          disabled={!this.props.isConnected}
          accessibilityRole="imagebutton"
          accessibilityLabel={this.props.promotion.displayName}
        >
          <Image
            width={this.props.width}
            source={source}
            style={styles.image}
          />
        </AVTouchableOpacity>
      </InViewPort>
    );
  }
}

const styles = StyleSheet.create({
  image: {
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    flex: 1,
    overflow: 'hidden',
  },
});
export default ImagePromotion;
