import React from 'react';
import {View, StyleSheet} from 'react-native';
import Localized from 'src/constants/AppStrings';
import CanteenUIManager from './CanteenUIManager';
import PromotionsHeader from '../promotions/CanteenPromotionsHeader';
import PantryLogo from '../../img/svg/PantryLogo';
const scrollRangeForAnimation = 140;

class PantryUIManager extends CanteenUIManager {
  getPromotionsHeader(
    qrCode: string,
    balance: number,
    animationScrollY: any,
    strings: typeof Localized,
    isConnected: boolean,
  ) {
    return (
      <PromotionsHeader
        qrCode={qrCode}
        balance={balance}
        hideBalance
        animationScrollY={animationScrollY}
        strings={strings}
        scrollRangeForAnimation={scrollRangeForAnimation}
        isConnected={isConnected}
      />
    );
  }

  getBalanceContainer(
    _smallText: boolean,
    _strings: typeof Localized,
    _isButton = true,
  ) {
    return null;
  }

  shouldHideFunding() {
    return true;
  }

  getLoginLogoImage() {
    return (
      <View
        accessible={true}
        accessibilityLabel={Localized.Labels.pantry_logo}
        aria-label={Localized.Labels.pantry_logo}
        style={styles.logoContainer}
      >
        <PantryLogo height="75%" width="60%" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  logoContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
});
export default PantryUIManager;
