import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';
import AVText from '../../../AVText';
import AVTouchableOpacity from '../../../AVTouchableOpacity';
import Styles from '../../../../Styles';

type KeyProps<T extends string> = {
  onClick: (value: T) => void;
  value: T;
  disabled?: boolean;
  specialKey?: boolean;
  style?: StyleProp<TextStyle>;
};
export default function Key<T extends string>({
  onClick,
  value,
  style = null,
  disabled = false,
  specialKey = false,
}: KeyProps<T>) {
  function onPress() {
    onClick(value);
  }

  return (
    <AVTouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.container, specialKey && styles.specialContainer]}
      accessibilityLabel={value}
    >
      <AVText style={[styles.digit, style]}>{value}</AVText>
    </AVTouchableOpacity>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderColor: Styles.lightGray,
    borderWidth: 1,
    justifyContent: 'center',
  },
  specialContainer: {
    backgroundColor: Styles.bgColor,
  },
  digit: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    textAlign: 'center',
  },
});
