import React from 'react';
import {StyleSheet, ScrollView, View, Dimensions} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import BirthMonthDropdown, {
  getMonthText,
} from '../../elements/account/BirthMonthDropdown';
import BirthYearDropdown from '../../elements/account/BirthYearDropdown';
import GenderDropdown from '../../elements/account/GenderDropdown';
import BackSubheader from '../../elements/BackSubheader';
import MenuOption from '../../elements/MenuOption';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import ShadowContainer from '../../elements/ShadowContainer';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import LocaleDropdown from '../../elements/account/LocaleDropdown';
import InfoIcon from '../../elements/InfoIcon';
import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {authStore} from 'src/init';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ManageAccountProps = IsConnectedProps & {
  dispatch: AppDispatch;
  navigation?: NavigationProp<ManageAccount>;
};
type ManageAccountState = {
  firstName: string;
  lastName: string;
  gender: string;
  birthMonth: string;
  birthYear: string;
  jobTitle: string;
  locale: string;
  previousRoute: string;
  genderMenuVisible: boolean;
  birthMonthMenuVisible: boolean;
  birthYearMenuVisible: boolean;
  localeMenuVisible: boolean;
  isSSO: boolean;
};

const maxWidth = Dimensions.get('screen').width - Styles.Spacing.m3 * 2;
const MAX_NAME_LENGTH = 20;

class ManageAccount extends React.Component<
  ManageAccountProps,
  ManageAccountState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ManageAccountProps) {
    super(props);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.convertMonthToText = this.convertMonthToText.bind(this);
    this.getBirthdate = this.getBirthdate.bind(this);
    this.genderSelected = this.genderSelected.bind(this);
    this.birthMonthSelected = this.birthMonthSelected.bind(this);
    this.birthYearSelected = this.birthYearSelected.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.localeSelected = this.localeSelected.bind(this);
    this.state = {
      firstName: AccountStore.getFirstName(),
      lastName: AccountStore.getLastName(),
      gender: AccountStore.getGender(),
      birthMonth: AccountStore.getBirthMonth(),
      birthYear: AccountStore.getBirthYear(),
      jobTitle: AccountStore.getJobTitle(),
      locale: AccountStore.getLocale(),
      previousRoute: '',
      genderMenuVisible: false,
      birthMonthMenuVisible: false,
      birthYearMenuVisible: false,
      localeMenuVisible: false,
      isSSO: false,
    };
  }
  async handleIsSSO() {
    const isSSO = await authStore.getIsSSO();
    this.setState({isSSO});
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.handleIsSSO();
  }

  handleMenuVisibilityChange = (visible: boolean, whichMenu: string) => {
    switch (whichMenu) {
      case 'gender':
        this.setState({genderMenuVisible: visible});
        break;
      case 'birthMonth':
        this.setState({birthMonthMenuVisible: visible});
        break;
      case 'birthYear':
        this.setState({birthYearMenuVisible: visible});
        break;
      case 'locale':
        this.setState({localeMenuVisible: visible});
        break;
      default:
        break;
    }
  };

  localeSelected(locale: string) {
    this.setState({
      locale,
    });
  }

  genderSelected(gender: string) {
    this.setState({
      gender: gender,
      genderMenuVisible: false,
    });
  }

  birthMonthSelected(birthMonth: string) {
    this.setState({
      birthMonth: birthMonth,
      birthMonthMenuVisible: false,
    });
  }

  birthYearSelected(birthYear: string) {
    this.setState({
      birthYear: birthYear,
      birthYearMenuVisible: false,
    });
  }

  getBirthdate(): string {
    const birthMonth = AccountStore.getBirthMonth();
    const birthYear = AccountStore.getBirthYear();
    const birthMonthText = this.convertMonthToText(birthMonth);
    return `${birthMonthText} ${birthYear || ''}`;
  }

  convertMonthToText(month) {
    if (!month) {
      return '';
    }

    return getMonthText(false, month);
  }

  handleSave() {
    const errorMessage = this.validate();

    if (errorMessage) {
      alertError(errorMessage);
    } else {
      this.saveChanges();
    }
  }

  validate() {
    if (!this.state.firstName || !this.state.lastName) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  saveChanges = async () => {
    const {dispatch} = this.props;
    dispatch(showSpinner());
    let response;
    try {
      response = await ActionsFactory.getAccountActions().updateInfo(
        AccountStore.getAccountId(),
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          gender: this.state.gender ? this.state.gender : undefined,
          birthMonth:
            this.state.birthMonth !== '00'
              ? +this.state.birthMonth!
              : undefined,
          birthYear: this.state.birthYear ? +this.state.birthYear : undefined,
          jobTitle: this.state.jobTitle ? this.state.jobTitle : undefined,
          locale: this.state.locale ? this.state.locale : undefined,
        },
      );

      FirebaseAnalytic.trackEvent('saveChanges', 'ManageAccountScreen', {
        ...this.props,
        ...this.state,
        response,
      });

      if (response) {
        NavActions.pop();
      } else {
        alertError(Localized.Errors.unable_to_save_changes);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ManageAccountScreen:SaveChanges',
        error.message ? error.message : error.toString(),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ManageAccountScreen:SaveChanges',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.unable_to_save_changes, guid);
    } finally {
      dispatch(hideSpinner());
    }
  };

  handleChangeEmail() {
    NavActions.push(AppRoutes.ChangeEmail);
  }

  getDisplayGender(value: string) {
    if (value === 'Male') {
      return Localized.Labels.male;
    } else if (value === 'Female') {
      return Localized.Labels.female;
    }

    return Localized.Labels.other;
  }

  render() {
    return (
      <BackSubheader
        title={Localized.Labels.personal_info}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <KeyboardAvoidingView behavior="height" insideTab>
          <ScrollView style={Styles.Style.flex}>
            <ShadowContainer
              style={[styles.content, Styles.Style.maxWidthContainer]}
            >
              <View>
                <AllyTextInput
                  label={Localized.Labels.first_name}
                  value={this.state.firstName}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.first_name}
                  accessibilityValue={{text: this.state.firstName}}
                  onChangeText={(text) => this.setState({firstName: text})}
                  autoCapitalize="words"
                  maxLength={MAX_NAME_LENGTH}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />
                <AllyTextInput
                  label={Localized.Labels.last_name}
                  value={this.state.lastName}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.last_name}
                  accessibilityValue={{text: this.state.lastName}}
                  onChangeText={(text) => this.setState({lastName: text})}
                  autoCapitalize="words"
                  maxLength={MAX_NAME_LENGTH}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />

                <View
                  style={{marginTop: Styles.Spacing.m2}}
                  accessible={true}
                  accessibilityLabel="Gender"
                  accessibilityRole="menu"
                  accessibilityValue={{text: this.state.gender}}
                  accessibilityState={{
                    expanded: this.state.genderMenuVisible,
                  }}
                  accessibilityElementsHidden={
                    this.state.birthMonthMenuVisible ||
                    this.state.birthYearMenuVisible
                  }
                  importantForAccessibility={
                    this.state.birthMonthMenuVisible ||
                    this.state.birthYearMenuVisible
                      ? 'no-hide-descendants'
                      : 'yes'
                  }
                  role="menu"
                  aria-label={'Gender'}
                  aria-valuetext={this.state.gender}
                  aria-expanded={this.state.genderMenuVisible}
                >
                  <GenderDropdown
                    selectedValue={this.state.gender}
                    onSelect={this.genderSelected}
                    onVisibilityChange={() =>
                      this.handleMenuVisibilityChange(
                        !this.state.genderMenuVisible,
                        'gender',
                      )
                    }
                  />
                </View>

                <View
                  accessible={true}
                  accessibilityLabel="Birth Month"
                  accessibilityRole="menu"
                  accessibilityValue={{text: this.state.birthMonth}}
                  accessibilityState={{
                    expanded: this.state.birthMonthMenuVisible,
                  }}
                  accessibilityElementsHidden={
                    this.state.genderMenuVisible ||
                    this.state.birthYearMenuVisible
                  }
                  importantForAccessibility={
                    this.state.genderMenuVisible ||
                    this.state.birthYearMenuVisible
                      ? 'no-hide-descendants'
                      : 'yes'
                  }
                  role="menu"
                  aria-label={'Birth Month'}
                  aria-valuetext={this.state.birthMonth}
                  aria-expanded={this.state.birthMonthMenuVisible}
                >
                  <BirthMonthDropdown
                    selectedValue={this.state.birthMonth}
                    onSelect={this.birthMonthSelected}
                    onVisibilityChange={() =>
                      this.handleMenuVisibilityChange(
                        !this.state.birthMonthMenuVisible,
                        'birthMonth',
                      )
                    }
                    showNumbers={false}
                  />
                </View>

                <View
                  accessible={true}
                  accessibilityLabel="Birth Year"
                  accessibilityRole="menu"
                  accessibilityValue={{text: this.state.birthYear}}
                  accessibilityState={{
                    expanded: this.state.birthYearMenuVisible,
                  }}
                  accessibilityElementsHidden={
                    this.state.genderMenuVisible ||
                    this.state.birthMonthMenuVisible
                  }
                  importantForAccessibility={
                    this.state.genderMenuVisible ||
                    this.state.birthMonthMenuVisible
                      ? 'no-hide-descendants'
                      : 'yes'
                  }
                  role="menu"
                  aria-label={'Birth Year'}
                  aria-valuetext={this.state.birthYear}
                  aria-expanded={this.state.birthYearMenuVisible}
                >
                  <BirthYearDropdown
                    selectedValue={this.state.birthYear}
                    onSelect={this.birthYearSelected}
                    onVisibilityChange={() =>
                      this.handleMenuVisibilityChange(
                        !this.state.birthYearMenuVisible,
                        'birthYear',
                      )
                    }
                  />
                </View>

                <View
                  style={{marginTop: Styles.Spacing.m1}}
                  accessibilityElementsHidden={
                    this.state.genderMenuVisible ||
                    this.state.birthMonthMenuVisible ||
                    this.state.birthYearMenuVisible
                  }
                  importantForAccessibility={
                    this.state.genderMenuVisible ||
                    this.state.birthMonthMenuVisible ||
                    this.state.birthYearMenuVisible
                      ? 'no-hide-descendants'
                      : 'yes'
                  }
                >
                  <AllyTextInput
                    label={Localized.Labels.job_title}
                    value={this.state.jobTitle}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.job_title}
                    accessibilityValue={{text: this.state.jobTitle}}
                    onChangeText={(text) => this.setState({jobTitle: text})}
                    autoCapitalize="words"
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>

                <View style={styles.localeContainer}>
                  <View
                    style={[
                      styles.localeDropdown,
                      styles.dropdownContainer,
                      {
                        width: maxWidth - Styles.Spacing.m2,
                        marginHorizontal: 2,
                      },
                    ]}
                    accessible={true}
                    accessibilityLabel="Locale drop down"
                    accessibilityRole="menu"
                    accessibilityValue={{text: this.state.locale}}
                    accessibilityState={{
                      expanded: this.state.localeMenuVisible,
                    }}
                    accessibilityElementsHidden={
                      this.state.genderMenuVisible ||
                      this.state.birthMonthMenuVisible ||
                      this.state.birthYearMenuVisible
                    }
                    importantForAccessibility={
                      this.state.genderMenuVisible ||
                      this.state.birthMonthMenuVisible ||
                      this.state.birthYearMenuVisible
                        ? 'no-hide-descendants'
                        : 'yes'
                    }
                    role="menu"
                    aria-label="Locale drop down"
                    aria-valuetext={this.state.locale}
                    aria-expanded={this.state.localeMenuVisible}
                  >
                    <LocaleDropdown
                      style={{flex: 1}}
                      selectedValue={this.state.locale}
                      onSelect={this.localeSelected}
                      onVisibilityChange={() =>
                        this.handleMenuVisibilityChange(
                          !this.state.localeMenuVisible,
                          'locale',
                        )
                      }
                    />
                  </View>
                  <View
                    accessible={true}
                    accessibilityLabel="Information"
                    accessibilityRole="imagebutton"
                    accessibilityHint="Double tap to show the help information"
                    aria-label="Information"
                    role="button"
                    style={styles.localeIcon}
                  >
                    <InfoIcon
                      infoMessage={Localized.Labels.formatString(
                        Localized.Labels.system_language_explanation,
                        Settings.appDisplayName,
                      )}
                    />
                  </View>
                </View>
                {this.state.isSSO !== true && (
                  <MenuOption
                    accessibilityLabel="Change Email"
                    label={Localized.Labels.change_email}
                    onPress={this.handleChangeEmail}
                    style={styles.menu}
                  />
                )}
              </View>
            </ShadowContainer>
          </ScrollView>
        </KeyboardAvoidingView>
        {this.props.isConnected && (
          <RoundedButton
            buttonType={ButtonType.action}
            text={Localized.Buttons.save}
            onPress={this.handleSave}
            accessible={true}
            accessibilityLabel={Localized.Buttons.save}
            accessibilityRole="button"
            role="button"
            aria-label={Localized.Buttons.save}
          />
        )}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m5,
  },
  menu: {
    height: Styles.Heights.h6,
    marginBottom: Styles.Spacing.m5,
    marginTop: Styles.Spacing.m2,
    marginHorizontal: 0,
  },
  dropdownContainer: {
    marginTop: Styles.Spacing.m1,
    width: maxWidth - 4,
    marginHorizontal: 2,
  },
  localeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  localeDropdown: {
    flex: 1,
    marginTop: Styles.Spacing.m2,
  },
  localeIcon: {
    marginRight: Styles.Spacing.m0,
    marginLeft: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m3,
  },
});
const ConnectedManageAccount = connect(null, (dispatch: AppDispatch) => ({
  dispatch,
}))(ManageAccount);
export default withForwardedNavigationParams()(
  withIsConnected(ConnectedManageAccount),
);
