import moment from 'moment';
import Api from '../Api';
import {UrlTypes} from 'src/types/UrlTypes';
import {store} from '../../redux/store';

export type BunnRecipeSize = {
  hasProduct: boolean;
  id: number;
  price: string;
  sizeEnabled: boolean;
  sizeName: string;
};
export type BunnRecipe = {
  bold: boolean;
  customName: string;
  description: string;
  enabled: boolean;
  hasProduct: boolean;
  id: number;
  leaveRoom: boolean;
  sizes: BunnRecipeSize[];
};
export type BunnMenu = {
  machineId: number;
  machineModel: number;
  machineName: string;
  machineType: number;
  ownerName: string;
  serialNumber: string;
  recipes: BunnRecipe[];
};
export type BunnStatus = {
  description: string;
  machineId: number;
  status: number;
};
type BunnStartSessionResponse = {
  description: string;
  machineId: number;
  status: number;
};
type BunnOAuth = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  session_state: string;
  scope: string;
  tokenExpiry: moment.Moment | null | undefined;
  refreshTokenExpiry: moment.Moment | null | undefined;
};
const BUNN_CLIENT_ID = 'retail365-client';
const BUNN_USERNAME = 'retail365-user';
const BUNN_PASSWORD =
  'canned heritage popular dramatic unifier serve concerned';
const BUNN_CLIENT_SECRET = '8f942e68-2729-4983-aff2-333622fada12';
const BUNN_SCOPE = 'touchless-scope';
const BUNN_OAUTH_URL_PATH =
  '/auth/realms/bunn-api/protocol/openid-connect/token';
const BUNN_API_BASE_URL_PATH = '/equipment/touchless';

export class BunnApi {
  token?: BunnOAuth;

  async startSession(uuid: string): Promise<BunnStartSessionResponse> {
    const url = Api.getFullUrl(
      this.getApiBaseUrl(),
      `/v1/order/machine/${encodeURIComponent(uuid)}/start-session`,
    );
    return this.fetch(url, null, 'POST', await this.getToken());
  }

  async getMenu(uuid: string): Promise<BunnMenu> {
    const url = Api.getFullUrl(
      this.getApiBaseUrl(),
      `/v1/order/machine/${encodeURIComponent(uuid)}/menu`,
    );
    return this.fetch(url, null, 'GET', await this.getToken());
  }

  async getStatus(uuid: string): Promise<BunnStatus> {
    const url = Api.getFullUrl(
      this.getApiBaseUrl(),
      `/v1/order/machine/${encodeURIComponent(uuid)}/status`,
    );
    return this.fetch(url, null, 'GET', await this.getToken());
  }

  async dispense(
    uuid: string,
    bold: boolean,
    drinkId: number,
    item: string,
    leaveRoom: boolean,
    sizeId: number,
    code: string | null = null,
  ) {
    const url = Api.getFullUrl(
      this.getApiBaseUrl(),
      `/v1/order/machine/${encodeURIComponent(uuid)}/dispense`,
    );
    const body = {
      bold,
      code,
      drinkId,
      item,
      leaveRoom,
      sizeId,
      paid: true,
    };
    return this.fetch(url, body, 'POST', await this.getToken());
  }

  async mobileMode(uuid: string) {
    const url = Api.getFullUrl(
      this.getApiBaseUrl(),
      `/v1/order/machine/${encodeURIComponent(uuid)}/mobile-mode`,
    );
    return this.fetch(url, null, 'POST', await this.getToken());
  }

  async getToken(): Promise<string> {
    if (this.token) {
      if (this.hasTokenExpired()) {
        this.token = await this.refreshToken();
      }
    } else {
      this.token = await this.fetchToken();
    }

    return this.token.access_token;
  }

  hasTokenExpired(): boolean {
    if (this.token && this.token.tokenExpiry) {
      return this.token.tokenExpiry.isBefore(moment());
    }

    return true;
  }

  hasRefreshTokenExpired(): boolean {
    if (this.token && this.token.refreshTokenExpiry) {
      return this.token.refreshTokenExpiry.isBefore(moment());
    }

    return true;
  }

  fetchToken(): Promise<BunnOAuth> {
    const params = {
      grant_type: 'password',
      client_id: BUNN_CLIENT_ID,
      username: BUNN_USERNAME,
      password: BUNN_PASSWORD,
      client_secret: BUNN_CLIENT_SECRET,
      scope: BUNN_SCOPE,
    };
    const paramString = this.getFormParams(params);
    return this.retrieveToken(paramString);
  }

  refreshToken(): Promise<BunnOAuth> {
    if (this.hasRefreshTokenExpired()) {
      return this.fetchToken();
    }

    const params = {
      grant_type: 'refresh_token',
      client_id: BUNN_CLIENT_ID,
      client_secret: BUNN_CLIENT_SECRET,
      refresh_token: this.token!.refresh_token,
    };
    const paramString = this.getFormParams(params);
    return this.retrieveToken(paramString);
  }

  async retrieveToken(paramString: string): Promise<BunnOAuth> {
    const oauthUrl = this.getOauthUrl();
    const response = await fetch(oauthUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: paramString,
    });
    const token = await response.json();
    token.tokenExpiry = moment().add(token.expires_in - 5, 'seconds');
    token.refreshTokenExpiry = moment().add(
      token.refresh_expires_in - 5,
      'seconds',
    );
    return token;
  }

  getOauthUrl() {
    const baseUrl = store.getState().environment.serviceUrls[UrlTypes.bunnapi];
    return Api.getFullUrl(baseUrl, BUNN_OAUTH_URL_PATH);
  }

  getApiBaseUrl() {
    const baseUrl = store.getState().environment.serviceUrls[UrlTypes.bunnapi];
    return Api.getFullUrl(baseUrl, BUNN_API_BASE_URL_PATH);
  }

  getFormParams(params: any) {
    return Api.getParamString(params).substring(1);
  }

  async fetch(url: string, data: any, type: string, token: string) {
    let body = '';

    if (data) {
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    });
    const json = await response.json();

    return json;
  }
}

export default new BunnApi();
