import {useState, useCallback, useEffect} from 'react';
import {Dimensions, EmitterSubscription} from 'react-native';
type onLayoutEvent = {
  nativeEvent: {
    layout: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  };
};
type ComponentSize =
  | {
      width: number;
      height: number;
    }
  | null
  | undefined;
export function useComponentSize(): [
  ComponentSize,
  (event: onLayoutEvent) => void,
] {
  const [size, setSize] = useState<ComponentSize>();
  const onLayout = useCallback((event: onLayoutEvent) => {
    const {width, height} = event.nativeEvent.layout;
    setSize({
      width,
      height,
    });
  }, []);
  return [size, onLayout];
}
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(Dimensions.get('window'));
  const [onChangeSub, setOnChangeSub] = useState<EmitterSubscription>();
  useEffect(() => {
    function onChange(result) {
      setWindowSize(result.window);
    }

    setOnChangeSub(Dimensions.addEventListener('change', onChange));
    return () => onChangeSub?.remove();
  }, [windowSize]);
  return windowSize;
}
