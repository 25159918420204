import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type WaterProps = {
  width: number;
  height: number;
};
export default class Water extends React.Component<WaterProps> {
  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <Path
          fill="#FFFFFF"
          d="M288.2,66v11.1c0,14.8,8.9,28.1,22.6,33.8l15.1,6.3c15.9,6.6,26.3,22.1,26.3,39.3v320
				c0,14.1-11.5,25.6-25.6,25.6c-14.1,0-25.6-11.5-25.6-25.6V460c0,23.2-18.8,42-42,42h-5.9c-23.2,0-42-18.8-42-42v16.4
				c0,14.1-11.5,25.6-25.6,25.6h0c-14.1,0-25.6-11.5-25.6-25.6v-320c0-17.2,10.4-32.7,26.3-39.3l15.1-6.3c13.7-5.7,22.6-19,22.6-33.8
				V66H288.2z"
        />
        <Path fill="#00CCF2" d="M159.9,184h192.1v200H159.9V184z" />
        <Path
          fill="#00CCF2"
          d="M289.2,64h-66.5c-5.9,0-10.8-4.8-10.8-10.8V20.8c0-5.9,4.8-10.8,10.8-10.8h66.5c5.9,0,10.8,4.8,10.8,10.8v32.5
				C300,59.2,295.2,64,289.2,64z"
        />
        <Path
          d="M329.8,107.9l-15.1-6.3c-10-4.1-16.4-13.8-16.4-24.6v-5.1c6.9-3.4,11.8-10.5,11.8-18.7V20.8C310,9.3,300.7,0,289.2,0h-66.5
				C211.3,0,202,9.3,202,20.8v32.5c0,8.3,4.9,15.5,12,18.8v5c0,10.8-6.4,20.4-16.4,24.6l-15.1,6.3c-19.7,8.2-32.4,27.2-32.4,48.6v226.5
				c0,0.4-0.1,0.7-0.1,1.1s0,0.7,0.1,1.1v91.3c0,19.6,16,35.6,35.6,35.6c12.5,0,23.5-6.5,29.9-16.2c9.5,10,22.9,16.2,37.7,16.2h5.9
				c14.8,0,28.2-6.2,37.7-16.2c6.4,9.8,17.4,16.2,29.9,16.2c19.6,0,35.6-16,35.6-35.6v-320C362.2,135.1,349.5,116,329.8,107.9z
				M222,20.8c0-0.4,0.3-0.8,0.8-0.8h66.5c0.4,0,0.8,0.3,0.8,0.8v32.5c0,0.4-0.3,0.8-0.8,0.8h-66.5c-0.4,0-0.8-0.3-0.8-0.8V20.8z
				M170,156.4c0-13.2,7.9-25,20.1-30.1l15.1-6.3c17.5-7.2,28.8-24.1,28.8-43V74h44.2v3.1c0,18.9,11.3,35.8,28.8,43l15.1,6.3
				c12.2,5.1,20.1,16.9,20.1,30.1V174H239.9c-5.5,0-10,4.5-10,10s4.5,10,10,10h102.3v180H170V156.4z M326.7,492
				c-8.6,0-15.6-7-15.6-15.6V460c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10c0,17.6-14.4,32-32,32h-5.9c-17.6,0-32-14.4-32-32
				c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10v16.4c0,8.6-7,15.6-15.6,15.6c-8.6,0-15.6-7-15.6-15.6V394h172.2v82.4
				C342.2,485,335.3,492,326.7,492z"
        />
        <Path
          d="M209.9,184c0-2.6-1.1-5.2-2.9-7.1c-1.9-1.9-4.4-2.9-7.1-2.9c-2.6,0-5.2,1.1-7.1,2.9c-1.9,1.9-2.9,4.4-2.9,7.1
				s1.1,5.2,2.9,7.1c1.9,1.9,4.4,2.9,7.1,2.9c2.6,0,5.2-1.1,7.1-2.9C208.9,189.2,209.9,186.6,209.9,184z"
        />
        <Path d="M104,94H87.8c-5.5,0-10,4.5-10,10s4.5,10,10,10H104c5.5,0,10-4.5,10-10S109.5,94,104,94z" />
        <Path d="M39.1,94H24c-5.5,0-10,4.5-10,10s4.5,10,10,10h15.1c5.5,0,10-4.5,10-10S44.6,94,39.1,94z" />
        <Path d="M64,54c-5.5,0-10,4.5-10,10v14.6c0,5.5,4.5,10,10,10s10-4.5,10-10V64C74,58.5,69.5,54,64,54z" />
        <Path d="M64,122.2c-5.5,0-10,4.5-10,10V144c0,5.5,4.5,10,10,10s10-4.5,10-10v-11.8C74,126.7,69.5,122.2,64,122.2z" />
        <Path d="M488,398h-16.2c-5.5,0-10,4.5-10,10s4.5,10,10,10H488c5.5,0,10-4.5,10-10S493.5,398,488,398z" />
        <Path d="M423.1,398H408c-5.5,0-10,4.5-10,10s4.5,10,10,10h15.1c5.5,0,10-4.5,10-10S428.6,398,423.1,398z" />
        <Path d="M448,358c-5.5,0-10,4.5-10,10v14.6c0,5.5,4.5,10,10,10s10-4.5,10-10V368C458,362.5,453.5,358,448,358z" />
        <Path d="M448,426.2c-5.5,0-10,4.5-10,10V448c0,5.5,4.5,10,10,10s10-4.5,10-10v-11.8C458,430.7,453.5,426.2,448,426.2z" />
        <Path
          d="M259.7,355.5c25.8,0.3,49.4-14.6,60.3-38c-6.8,3-14.3,4.5-21.7,4.3c-29.3,0-53-23.8-53-53c0.3-19.8,11.2-37.9,28.6-47.4
				c-4.7-0.6-9.4-0.9-14.1-0.8c-37.3,0-67.5,30.2-67.5,67.5C192.2,325.3,222.4,355.5,259.7,355.5z"
        />
      </Svg>
    );
  }
}
