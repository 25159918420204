import {Characteristic} from 'react-native-ble-plx';
import {code} from 'currency-codes';
import {encodeValue, Radix} from '../Encoding';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
const SELECTION_KEY_BASE = 48;
const plainTextHex =
  '17de126d06451a6800000000000000010000000a0000000000000000ffded12e';
const paymentStartMeta = {
  userIdLength: 16,
  creditLength: 8,
  currencyLength: 4,
  selectionLength: 8,
  revalueLength: 2,
};
export default class PaymentStartCharacteristic {
  characteristic: Characteristic;

  constructor(characteristic: Characteristic) {
    this.characteristic = characteristic;
  }

  async startPayment(
    userId: string,
    credit: number,
    selections: string[],
    currencyCode = 'USD',
    revalueCapable = false,
  ) {
    const userIdHex = encodeValue(userId, 'ascii', 'hex').padStart(
      paymentStartMeta.userIdLength,
      '0',
    );
    const creditNoDecimals = Math.floor(credit * 100);
    const creditHex = creditNoDecimals
      .toString(Radix.Hex)
      .padStart(paymentStartMeta.creditLength, '0');
    const currencyHex =
      PaymentStartCharacteristic.getCurrencyNumberHex(currencyCode);
    const selectionHex = PaymentStartCharacteristic.getSelectionHex(selections);
    const revalueHex = revalueCapable ? '01' : '00';
    const paymentStartPayloadHex =
      userIdHex + creditHex + currencyHex + selectionHex + revalueHex;
    const paymentStartPayloadBase64 = encodeValue(
      paymentStartPayloadHex,
      'hex',
      'base64',
    );
    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.CharacteristicPreWrite,
      {
        uuid: this.characteristic.uuid,
        value: paymentStartPayloadHex,
      },
    );
    await this.characteristic.writeWithResponse(paymentStartPayloadBase64);
    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.CharacteristicPostWrite,
      {
        uuid: this.characteristic.uuid,
        value: paymentStartPayloadHex,
      },
    );
  }

  static getSelectionHex(selections: string[]): string {
    const selectionsHex = selections.map((selection: string) => {
      const selectionNumber = parseInt(selection, Radix.Decimal);
      // numbers 10, 11, 12, 13, 14, 15 : use the code 0x30+number , ( i.e. key 10 is
      //  0x3A, 11 is 0x3B...15 is 0x3F )
      let selectionHex = '';

      if (
        !isNaN(selectionNumber) &&
        selectionNumber >= 10 &&
        selectionNumber <= 15
      ) {
        const finalDecimal = selectionNumber + SELECTION_KEY_BASE;
        selectionHex = finalDecimal.toString(Radix.Hex).padStart(2, '0');
      } else {
        selectionHex = encodeValue(selection, 'ascii', 'hex');
      }

      return selectionHex;
    });
    return selectionsHex.join('').padStart(8, '0');
  }

  // USD => 0348
  static getCurrencyNumberHex(currencyCode: string): string {
    const currencyNumber = parseInt(code(currencyCode).number, Radix.Decimal);
    return currencyNumber.toString(Radix.Hex).padStart(4, '0');
  }
}
