import {EventEmitter} from 'events';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import PlanogramConstants from 'src/constants/PlanogramConstants';
import PersistentStore from 'src/services/PersistentStoreService';
import type {DispatchAction} from 'src/dispatchers/DispatchTypes';
import {ProductType} from 'src/types/ProductType';
const CHANGE = 'change';

class PlanogramStore {
  _emitter: EventEmitter = new EventEmitter();
  _dispatchToken: number;
  _companyProducts: Array<ProductType> = [];
  _filteredProducts: Array<ProductType> = [];
  _fullProducts: Array<ProductType> = [];
  _showSearch: Record<string, boolean> = {};
  _errorFetching = false;
  _tags: Array<string> = [];
  _filteredTags: Array<string> = [];
  _selectedTag = '';

  constructor() {
    PersistentStore.getCompanyProducts()
      .then((products) => {
        if (products != null) {
          this._companyProducts = products;
        }
      })
      .catch((e) => console.log('getCompanyProducts() Error: ', e));
    this._dispatchToken = AppDispatcher.register((action: DispatchAction) => {
      if (action.actionType === PlanogramConstants.FETCH_PLANOGRAM_SUCCESS) {
        const {deviceId, response} = action.data;
        PersistentStore.setPlanogram(deviceId, response);
        this._showSearch[deviceId] = response.ShowCategorySearchView;

        if (response.UsePlanogram) {
          this._fullProducts = [];

          if (response.Trays && response.Trays.length > 0) {
            for (const tray of response.Trays) {
              for (const product of tray.Products) {
                this._fullProducts.push(product.Product);
              }
            }
          }
        } else {
          this._fullProducts = response.Products;
        }

        this._filteredProducts = this._fullProducts;
        this._tags = response.Tags;
        this._tags?.sort();
        this._filteredTags = this._tags;
        this._selectedTag = '';

        this._emitChange();
      }

      if (action.actionType === PlanogramConstants.COMPANY_PRODUCTS_FETCHED) {
        this._companyProducts = action.data.response;
        PersistentStore.setCompanyProducts(action.data.response);

        this._emitChange();
      }

      if (action.actionType === PlanogramConstants.FILTER_UPDATED) {
        const search = action.data.filter.toLowerCase();
        this.updateFilteredProducts(search);

        this._emitChange();
      }

      if (action.actionType === PlanogramConstants.TAG_SELECTED) {
        const tag = action.data.tag;
        this._selectedTag = tag;
        this.updateFilteredProducts();
      }

      if (action.actionType === PlanogramConstants.TAG_FILTER_UPDATED) {
        const query = action.data.query.toLowerCase();

        if (query) {
          this._filteredTags = this._tags.filter(
            (tag: string) => tag.toLowerCase().indexOf(query) >= 0,
          );
        } else {
          this._filteredTags = this._tags;
        }

        this._emitChange();
      }
    });
  }

  updateFilteredProducts(search = '') {
    if (this._selectedTag) {
      this._filteredProducts = this._fullProducts.filter(
        (product) =>
          product.Tags !== null &&
          product.Tags.length > 0 &&
          product.Tags.indexOf(this._selectedTag) >= 0,
      );
    } else {
      this._filteredProducts = this._fullProducts;
    }

    if (search) {
      this._filteredProducts = this._filteredProducts.filter((product) => {
        const nameMatch =
          product.Product.Name.toLowerCase().indexOf(search) >= 0;
        const tagsMatch =
          product.Tags != null &&
          product.Tags.length > 0 &&
          product.Tags.some(
            (tag: string) => tag.toLowerCase().indexOf(search) >= 0,
          );
        const categoryMatch =
          product.Product.CategoryName.toLowerCase().indexOf(search) >= 0;
        return nameMatch || tagsMatch || categoryMatch;
      });
    }
  }

  _emitChange() {
    this._emitter.emit(CHANGE);
  }

  addChangeListener(callback: () => void) {
    this._emitter.on(CHANGE, callback);
  }

  removeChangeListener(callback: () => void) {
    this._emitter.removeListener(CHANGE, callback);
  }

  getPlanogramAsync(deviceId: string) {
    return PersistentStore.getPlanogram(deviceId);
  }

  getErrorFetching() {
    return this._errorFetching;
  }

  getCompanyProducts() {
    return this._companyProducts;
  }

  getFilteredProducts() {
    return this._filteredProducts;
  }

  getFilteredTags(): any {
    return this._filteredTags.slice(0);
  }

  showSearch(deviceId: string): boolean {
    const result = this._showSearch[deviceId];

    if (result == null) {
      return false;
    } else {
      return result;
    }
  }
}

export default new PlanogramStore();
