import type {PaymentToken} from 'src/types/serverTypes/Account';

export interface CreditCard {
  id: string;
  provider: number;
  providerKey: string;
  cardIssuer: string;
  expirationMonth: number;
  expirationYear: number;
  name: string;
  accountBalanceId: string;
  isDefault: boolean;
  dateCreated: string | null | undefined;
}
export default class CreditCardBuilder {
  id: string;
  provider: number;
  providerKey: string;
  cardIssuer: string;
  expirationMonth: number;
  expirationYear: number;
  name: string;
  accountBalanceId: string;
  isDefault: boolean;
  dateCreated: string | null | undefined;

  static build(serverPaymentToken: PaymentToken): CreditCard {
    return {
      id: serverPaymentToken.balanceTokenId,
      provider: serverPaymentToken.provider,
      providerKey: serverPaymentToken.providerKey,
      cardIssuer: serverPaymentToken.cardIssuer,
      expirationMonth: serverPaymentToken.expirationMonth,
      expirationYear: serverPaymentToken.expirationYear,
      name: serverPaymentToken.name,
      accountBalanceId: serverPaymentToken.accountBalanceId,
      isDefault: serverPaymentToken.isDefault,
      dateCreated: serverPaymentToken.dateCreated,
    };
  }
}
