import {encodeValue} from '../Encoding';
import Events, {VendorsExchangeActions} from 'src/logging/Events';

export enum MachineStatus {
  NoSelection = 'ff',
  LastSelectionOK = '80',
  SelectionStarted = '81',
  LastSelectionFailed = '82',
  LastSelectionNotAvailable = '84',
}
export default class KeypadConfigurationCharacteristic {
  keypadType: string;
  machineStatus: MachineStatus;

  constructor(value: string, uuid: string) {
    this.parseConfig(value, uuid);
  }

  parseConfig(base64Value: string, uuid: string) {
    const keypadHexValue = encodeValue(base64Value, 'base64', 'hex');

    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.KeypadConfiguration,
      {
        uuid: uuid,
        value: keypadHexValue,
      },
    );

    // example: 01ff
    if (keypadHexValue.length === 4) {
      this.keypadType = keypadHexValue.substring(0, 2);
      this.machineStatus = keypadHexValue.substring(2, 4) as MachineStatus;
    }
  }
}
