import {Linking} from 'react-native';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import type {MoblicoPromotionType} from 'src/types/Promotions';
export function handleClick(promotion: MoblicoPromotionType) {
  if (promotion.clickToUrl) {
    if (promotion.clickToUrl.startsWith('http')) {
      Linking.openURL(promotion.clickToUrl);
    } else if (promotion.deal) {
      NavActions.push(AppRoutes.DealDetail, {
        deal: promotion.deal,
      });
    }
  }

  Events.Promotion.trackEvent(
    promotion.id.toString(),
    promotion.caption,
    'CLICKED',
    true,
  );
}
