import React from 'react';
import {StyleSheet, View, Text, Platform} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {CartItem} from 'src/types/TransactionDetail';
import AVText from '../AVText';
import AccountStore from 'src/stores/AccountStore';

type SaleDetailProps = {
  detail: CartItem;
  currency?: string;
  cartcheckOut?: boolean;
};

const SaleDetail: React.FC<SaleDetailProps> = ({
  detail,
  currency,
  cartcheckOut,
}) => {
  let itemPrice = parseFloat(detail.Price ?? '');
  let modifiers: JSX.Element[] | null = null;

  if (detail.Modifiers && detail.Modifiers.length > 0) {
    detail.Modifiers.sort((a, b) => b.Price - a.Price);
    modifiers = detail.Modifiers.map((mod) => {
      if (cartcheckOut) {
        itemPrice -= mod.Price;
      }
      return (
        <View accessible={true} key={mod.Id} style={styles.row}>
          <AVText
            accessibilityLabel={mod.Name}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            aria-label={mod.Name}
            style={styles.modifier}
          >
            {mod.Name}
          </AVText>
          {mod.Price > 0 && (
            <AVText key={mod.Id} style={styles.modifierAmount}>
              +
              <AVFormattedCurrency
                style={styles.modifierAmount}
                value={mod.Price}
                accessibilityLabel={`+$${mod.Price}`}
                aria-label={`+$${mod.Price}`}
                currency={currency ?? AccountStore.getCurrency()}
              />
            </AVText>
          )}
        </View>
      );
    });
  }
  const supLabel = (
    <View>
      <AVText
        accessible={true}
        style={[
          styles.rowContainer,
          {justifyContent: 'flex-start', marginLeft: 20},
        ]}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
        accessibilityLabel={`${Localized.Labels.sup_fee} label`}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.sup_fee} label, text`}
      >
        {Localized.Labels.sup_fee}{' '}
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          numberOfLines={1}
          style={[
            styles.rowContainer,
            Platform.OS !== 'web' ? {marginLeft: -20} : {marginLeft: 0},
          ]}
          accessible={true}
          accessibilityLabel={`SUP amount $${detail.TransactionChargeAmount}`}
          aria-label={`SUP amount $${detail.TransactionChargeAmount}, text`}
          value={detail.TransactionChargeAmount}
          currency={AccountStore.getCurrency()}
        />
      </AVText>
    </View>
  );
  return (
    <>
      <View accessible={true} style={styles.row}>
        <Text
          accessibilityLabel={detail.Name}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          aria-label={detail.Name}
          style={styles.item}
        >
          {detail.Name}
        </Text>
        <AVFormattedCurrency
          style={styles.amount}
          value={itemPrice}
          accessibilityLabel={`$${itemPrice}`}
          aria-label={`$${itemPrice}`}
          currency={currency ?? AccountStore.getCurrency()}
        />
      </View>
      {detail.TransactionChargeAmount > 0 && supLabel}
      {modifiers}
    </>
  );
};

const styles = StyleSheet.create({
  amount: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  item: {
    color: Styles.darkColor,
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  modifier: {
    flex: 1,
    flexDirection: 'row',
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    marginVertical: 10,
    maxWidth: '70%',
  },
  modifierAmount: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
  },
  row: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m0,
    paddingHorizontal: Styles.Spacing.m2,
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingRight: Styles.Spacing.m2,
    color: Styles.lightGray,
  },
});
export default SaleDetail;
