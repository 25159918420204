import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import BackSubheader from '../../elements/BackSubheader';
import ScreenContext from '../../ScreenContext';
import AVText from '../../elements/AVText';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import type {BlockedUser} from 'src/types/Snack';
import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {
  getSnackBlockedUsers,
  unblockSnackUser,
  UnblockSnackUserParams,
} from 'src/redux/thunks/snackThunks';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type SnackBlockedUsersProps = {
  navigation: NavigationProp<SnackBlockedUsersScreen>;
  loadingBlockedUsers: boolean;
  blockedUsers: Array<BlockedUser>;
  getSnackBlockedUsers(): void;
  unblockSnackUser(params: UnblockSnackUserParams): void;
};
type SnackBlockedUsersState = {
  previousRoute?: string | null;
};

class SnackBlockedUsersScreen extends React.Component<
  SnackBlockedUsersProps,
  SnackBlockedUsersState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.renderItem = this.renderItem.bind(this);
    this.getKeyForListItem = this.getKeyForListItem.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderBlockedUsers = this.renderBlockedUsers.bind(this);
    this.renderNoUsersBlockedView = this.renderNoUsersBlockedView.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'SnackBlockedUsersScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.props.getSnackBlockedUsers();
  }

  getKeyForListItem(item) {
    FirebaseAnalytic.trackEvent(
      'getKeyForListItem',
      'SnackBlockedUsersScreen',
      {
        ...this.props,
        ...this.state,
        item,
      },
    );
    return item.blockedAccountId;
  }

  renderItem({item}) {
    return (
      <View style={styles.itemContainer}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={styles.textStyle}
        >
          {item.blockedName}
        </AVText>
        <RoundedButton
          buttonType={ButtonType.outline}
          text={Localized.Buttons.unblock}
          color={Styles.lightGray}
          onPress={() => {
            this.props.unblockSnackUser({
              blockedAccountId: item.blockedAccountId,
            });
          }}
          accessibilityLabel="Unblock User"
        />
      </View>
    );
  }

  renderNoUsersBlockedView() {
    return (
      <AVText style={styles.noUsersBlockedMessageTextStyle}>
        {Localized.Labels.no_users_blocked_snack_msg}
      </AVText>
    );
  }

  renderEmpty() {
    return (
      <BackSubheader
        title={Localized.Labels.blocked_users}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      />
    );
  }

  renderBlockedUsers() {
    return (
      <FlatList
        refreshing={this.props.loadingBlockedUsers}
        onRefresh={this.props.getSnackBlockedUsers}
        data={this.props.blockedUsers}
        renderItem={this.renderItem}
      />
    );
  }

  render() {
    if (this.props.loadingBlockedUsers) {
      return this.renderEmpty();
    } else {
      return (
        <BackSubheader
          title={Localized.Labels.blocked_users}
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        >
          {this.props.blockedUsers?.length > 0
            ? this.renderBlockedUsers()
            : this.renderNoUsersBlockedView()}
        </BackSubheader>
      );
    }
  }
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Styles.Spacing.m3,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Styles.lightGray,
  },
  textStyle: {
    fontSize: Styles.Fonts.f1,
    alignSelf: 'center',
  },
  noUsersBlockedMessageTextStyle: {
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'center',
  },
  listContainer: {
    flex: 1,
  },
});

const mapStateToProps = (state: RootState) => {
  return {
    loadingBlockedUsers: state.snack?.loadingBlockedUsers,
    blockedUsers: state.snack?.blockedUsers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSnackBlockedUsers: () => dispatch(getSnackBlockedUsers()),
  unblockSnackUser: (params) => dispatch(unblockSnackUser(params)),
});

export default compose(
  withForwardedNavigationParams(),
  withIsConnected,
  connect(mapStateToProps, mapDispatchToProps),
)(SnackBlockedUsersScreen);
