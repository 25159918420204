import * as React from 'react';
import {View} from 'react-native';
import {
  useNavigationBuilder,
  TabRouter,
  TabActions,
  createNavigatorFactory,
} from '@react-navigation/native';
import {useNetInfo} from '@react-native-community/netinfo';
import ScreenContext from 'src/components/ScreenContext';
import Styles from 'src/components/Styles';
import TopWebBar from 'src/components/elements/TopWebBar';
import UIManager from 'src/components/elements/ui/UIManager';
import type {ScreenProps} from 'src/types/Screen';
import Util from 'src/Util';

function TopTabNavigator(props) {
  const {state, navigation, descriptors} = useNavigationBuilder(
    TabRouter,
    props,
  );
  const netInfo = useNetInfo();
  const context: ScreenProps = React.useContext(ScreenContext);
  const routes = state.routes.map((route, index) => ({
    // @ts-ignore
    label: descriptors[route.key].options.tabBarLabel,
    isActive: state.index === index,
    key: route.key,
    onPress: () => {
      const event = navigation.emit({
        type: 'tabPress',
        target: route.key,
      }) as {defaultPrevented: boolean};

      if (!event.defaultPrevented) {
        navigation.dispatch({
          ...TabActions.jumpTo(route.name),
          target: state.key,
        });
      }
    },
  }));
  return (
    <View style={Styles.Style.flex}>
      <TopWebBar
        hideFunding={UIManager.shouldHideFunding()}
        onFundingPress={() =>
          context.actions.navigateToFunding(netInfo.isConnected, {
            transDate: Util.getCurrentDate(),
          })
        }
        routes={routes}
      />
      <View style={Styles.Style.flex}>
        {descriptors[state.routes[state.index].key].render()}
      </View>
    </View>
  );
}

export default createNavigatorFactory(TopTabNavigator);
