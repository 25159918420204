import React from 'react';
import {
  FlatList,
  PixelRatio,
  StyleProp,
  StyleSheet,
  Dimensions,
  Text,
  ViewStyle,
  View,
} from 'react-native';
import Icon from '../../icons/FontAwesomeIcon';
import Button from '../../elements/Button';
import Styles from '../../Styles';
import type {ProductCategory} from 'src/types/ProductCategory';
import Localized from 'src/constants/AppStrings';
type ProductCategoriesProps = {
  categories: Array<ProductCategory>;
  selectedCategory: string | number;
  onCategoryPress: (category: ProductCategory) => void;
  style: StyleProp<ViewStyle>;
};
const maxWidth =
  Dimensions.get('screen').width * (PixelRatio.getFontScale() > 1 ? 1 : 0.75);

export default (props: ProductCategoriesProps) => {
  const renderItem = ({item, index}) => {
    let itemName = item.name;

    if (item.iconName) {
      itemName = (
        <Text maxFontSizeMultiplier={2}>
          <Icon
            name={item.iconName}
            size={Styles.Fonts.f1}
            style={styles.icon}
          />
          {` ${item.name}`}
        </Text>
      );
    }

    return (
      <Button
        overrideStyles={[
          styles.categoryButton,
          props.selectedCategory === item.key && styles.selected,
          index === 0 && styles.firstItem,
          index === props.categories.length - 1 && styles.lastItem,
        ]}
        onPress={() => props.onCategoryPress(item)}
        overrideTextStyles={styles.categoryText}
        text={itemName}
        accessibilityLabel={`${item.name} ${Localized.Buttons.category}`}
        accessibilityHint="Double tap to filter products by selected category"
        accessibilityRole="button"
        aria-label={`${item.name} ${Localized.Buttons.category}`}
        role="button"
      />
    );
  };

  return (
    <View>
      <FlatList
        style={props.style}
        data={props.categories}
        renderItem={renderItem}
        horizontal={true}
        keyExtractor={(item) => `${item.key}`}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  categoryButton: {
    backgroundColor: Styles.lightGray,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    height: undefined,
    marginRight: Styles.Spacing.m1,
    padding: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    maxWidth: maxWidth,
    marginTop: 5,
    marginBottom: 10,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  categoryText: {
    fontSize: Styles.Fonts.f1,
  },
  selected: {
    backgroundColor: Styles.primaryColor,
  },
  icon: {
    marginRight: Styles.Spacing.m2,
  },
  firstItem: {
    marginLeft: Styles.Spacing.m3,
  },
  lastItem: {
    marginRight: Styles.Spacing.m3,
  },
});
