import _ from 'lodash';
import React from 'react';
import NBDropdown from '../NBDropdown';
import Localized from 'src/constants/AppStrings';
type BirthMonthDropdownProps = {
  style?: any;
  selectedValue: string;
  showNumbers: boolean;
  accessibilityValue?: {[key: string]: string};
  onSelect: (value: string) => void;
  onVisibilityChange: (visible: boolean) => void;
};
const monthMap = {
  '01': Localized.Labels.january,
  '02': Localized.Labels.february,
  '03': Localized.Labels.march,
  '04': Localized.Labels.april,
  '05': Localized.Labels.may,
  '06': Localized.Labels.june,
  '07': Localized.Labels.july,
  '08': Localized.Labels.august,
  '09': Localized.Labels.september,
  '10': Localized.Labels.october,
  '11': Localized.Labels.november,
  '12': Localized.Labels.december,
};
export const getMonthText = (numeric: boolean, value: string): string => {
  if (numeric) {
    return value;
  }

  return monthMap[value];
};

const getMonths = (numeric: boolean) => {
  let startMonth = 1;
  const endMonth = 12;
  const months: Array<{value: string; text: string}> = [];

  while (startMonth <= endMonth) {
    const value = _.padStart(String(startMonth), 2, '0');

    months.push({
      value,
      text: getMonthText(numeric, value),
    });
    startMonth++;
  }

  return months;
};

const BirthMonthDropdown = (props: BirthMonthDropdownProps) => {
  return (
    <NBDropdown
      accessible={true}
      accessibilityLabel={Localized.Labels.birth_month}
      accessibilityRole="menu"
      accessibilityValue={{text: props.selectedValue}}
      aria-label={Localized.Labels.birth_month}
      role="menu"
      aria-valuetext={props.selectedValue}
      label={Localized.Labels.birth_month}
      selectedValue={props.selectedValue}
      style={props.style}
      onSelect={props.onSelect}
      onVisibilityChange={props.onVisibilityChange}
      options={getMonths(props.showNumbers)}
    />
  );
};

export default BirthMonthDropdown;
