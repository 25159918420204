import {createAsyncThunk} from '@reduxjs/toolkit';
import uuid from '../../../nativeModules/UUID';
import Events from '../../../logging/Events';
import {alertError} from '../../../components/helpers/AlertHelper';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import Api from 'src/api/Api';
import AccountStore from '../../../stores/AccountStore';
import ActionsFactory from '../../../actions/ActionsFactory';
import {EnvironmentKey, EnvironmentsType} from '../../../models/Environment';
import {AppDispatch, RootState} from '../../store';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export const deepLinkCheckEnvironments = createAsyncThunk<
  {
    environment?: EnvironmentsType;
    error?: boolean;
  },
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'environment/deepLinkCheckEnvironments',
  async (newEnvironment, {dispatch, getState}) => {
    dispatch(showSpinner());

    try {
      const response = await Api.discoverUrls();
      if (
        response.hasOwnProperty('urls') &&
        response.urls.hasOwnProperty('TEST')
      ) {
        delete response.urls.TEST;
      }

      if (response) {
        const keys = Object.keys(response.urls);
        const currentEnvironment: EnvironmentKey = getState().environment.env;
        if (
          keys.includes(newEnvironment) &&
          currentEnvironment !== newEnvironment
        ) {
          // logout because environment is changing
          //TODO remove AccountStore
          if (AccountStore.isLoggedIn()) {
            ActionsFactory.getAccountActions().logout();
          }
        }

        return {
          environment: response.urls,
          error: false,
        };
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'environment/deepLinkCheckEnvironments',
        error.message ? error.message : JSON.stringify(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'environment/deepLinkCheckEnvironments',
        error.message ? error.message : JSON.stringify(error),
        guid,
      );
      alertError('Failed to load environments.', guid);
    } finally {
      dispatch(hideSpinner());
    }

    return {error: true};
  },
);
