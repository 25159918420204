import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import InboxScreen from 'src/components/screens/inbox/InboxScreen';
import MessageDetailScreen from 'src/components/screens/inbox/MessageDetailScreen';
import DealDetailScreen from 'src/components/screens/inbox/DealDetailScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
const Stack = createStackNavigator();
export default function InboxNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Inbox}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen name={AppRoutes.Inbox} component={InboxScreen} />
      <Stack.Screen
        name={AppRoutes.MessageDetail}
        component={MessageDetailScreen}
      />
      <Stack.Screen name={AppRoutes.DealDetail} component={DealDetailScreen} />
      <Stack.Screen
        name={AppRoutes.SnackDetail}
        component={SnackDetailScreen}
      />
    </Stack.Navigator>
  );
}
