import * as React from 'react';
import {ScrollView, findNodeHandle} from 'react-native';
import AVTextInput from '../elements/AVTextInput';
export default function withScrollToElement(Component: any) {
  class ComponentWithScrollToElement extends React.Component<any> {
    static scrollToElement(
      scrollView: ScrollView | null,
      element: AVTextInput | null,
    ) {
      if (scrollView && element) {
        const nodeHandle = findNodeHandle(element);
        const scrollResponder = scrollView.getScrollResponder();

        if (nodeHandle && scrollResponder) {
          scrollResponder.scrollResponderScrollNativeHandleToKeyboard(
            nodeHandle,
            220,
            true,
          );
        }
      }
    }

    render() {
      return (
        <Component
          scrollToElement={ComponentWithScrollToElement.scrollToElement}
          {...this.props}
        />
      );
    }
  }

  return ComponentWithScrollToElement;
}
