//These are not used; only here to make web works

type SetUserType = {
  accountId: string;
  email: string;
  org: string;
  location: string;
  region: string;
  city: string;
  zip: string;
  currency: string;
  environment: string;
};

function setUser({
  accountId,
  email,
  org,
  location,
  region,
  city,
  zip,
  currency,
  environment,
}: SetUserType): SetUserType {
  return {
    accountId,
    email,
    org,
    location,
    region,
    city,
    zip,
    currency,
    environment,
  };
}

function trackEvent(
  errorName: string,
  params: Record<string, string>,
): Record<string, string> {
  return {errorName, ...params};
}

export default {
  setUser,
  trackEvent,
};
