import * as React from 'react';
import {StyleSheet, View, TouchableOpacity, Platform, Text} from 'react-native';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import AVText from '../AVText';
import type {CartItem as CartItemType} from 'src/types/TransactionDetail';
import SwipeableRow from '../DeleteSwipeableRow';
import CartTypes from 'src/constants/cart/CartTypes';
import CartStore from 'src/stores/CartStore';
import Localized from 'src/constants/AppStrings';
import DeleteX from 'src/components/img/svg/DeleteXButton';
import AccountStore from 'src/stores/AccountStore';
import SupLeafIcon from 'src/components/img/svg/SupLeafIcon';

function CoffeeSelectionSizeName() {
  return (
    <View style={styles.rowContainer}>
      <AVText
        accessible={true}
        accessibilityLabel={`Coffee size ${CartStore.coffeeSelection.sizeName}`}
        aria-label={`Coffee size ${CartStore.coffeeSelection.sizeName}`}
        numberOfLines={1}
        style={[Styles.Style.description, styles.modifierText]}
      >
        {CartStore.coffeeSelection.sizeName}
      </AVText>
    </View>
  );
}

function CoffeeSelectionLeaveRoom() {
  return (
    <View style={[styles.rowContainer]}>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        accessible={true}
        accessibilityLabel={Localized.Labels.leave_room}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.leave_room}, text`}
        numberOfLines={1}
        style={[Styles.Style.description, styles.modifierText]}
      >
        {Localized.Labels.leave_room}
      </AVText>
    </View>
  );
}

function CoffeeSelectionBold() {
  return (
    <View style={[styles.rowContainer]}>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        accessible={true}
        accessibilityLabel={Localized.Labels.bold}
        aria-label={Localized.Labels.bold}
        numberOfLines={1}
        style={[Styles.Style.description, styles.modifierText]}
      >
        {Localized.Labels.bold}
      </AVText>
    </View>
  );
}

type CartItemProps = {
  trashClick?: (lineNumbers: Array<number>) => void;
  item: CartItemType;
  editClicked?: (item: CartItemType) => void;
  canEdit: boolean;
  canDelete: boolean;
  cartType: CartTypes;
  supFee: number;
};

class CartItem extends React.Component<CartItemProps> {
  swipeableRow?: SwipeableRow;

  constructor(props: CartItemProps) {
    super(props);
    this.trashClick = this.trashClick.bind(this);
    this.updateRef = this.updateRef.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
  }

  itemClicked() {
    if (this.props.editClicked) {
      this.props.editClicked(this.props.item);
    }
  }

  trashClick() {
    if (this.props.trashClick) {
      this.props.trashClick([this.props.item.LineNumber]);
    }

    if (this.swipeableRow) {
      this.swipeableRow.close();
    }
  }

  updateRef(ref: SwipeableRow | null) {
    if (ref) {
      this.swipeableRow = ref;
    }
  }

  getCoffeeModifiers() {
    const coffeeModifiers: JSX.Element[] = [];
    coffeeModifiers.push(<CoffeeSelectionSizeName />);

    if (CartStore.coffeeSelection.leaveRoom) {
      coffeeModifiers.push(<CoffeeSelectionLeaveRoom />);
    }

    if (CartStore.coffeeSelection.bold) {
      coffeeModifiers.push(<CoffeeSelectionBold />);
    }

    return coffeeModifiers;
  }

  render() {

    let discountRow: JSX.Element | null = null;
    const supRow = (
      <View
        style={[
          styles.rowContainer,
          {justifyContent: 'flex-start', marginLeft: 50},
        ]}
      >
        <SupLeafIcon />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          numberOfLines={1}
          style={[
            Styles.Style.description,
            styles.discountText,
            {marginTop: -3, fontSize: Styles.Fonts.f1},
          ]}
        >
          {Localized.Labels.sup_fee}
        </AVText>
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          numberOfLines={1}
          style={[
            Styles.Style.description,
            styles.discountText,
            {marginTop: -3, fontSize: Styles.Fonts.f1},
          ]}
          accessible={true}
          accessibilityLabel={`SUP amount $${this.props.item.TransactionChargeAmount}`}
          aria-label={`SUP amount $${this.props.item.TransactionChargeAmount}, text`}
          value={this.props.item.TransactionChargeAmount}
          currency={AccountStore.getCurrency()}
        />
      </View>
    );

    if (
      this.props.item.Discount &&
      parseFloat(this.props.item.Discount) > 0 &&
      this.props.item.DiscountDisplayName
    ) {
      discountRow = (
        <View style={[styles.rowContainer]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            numberOfLines={1}
            style={[Styles.Style.description, styles.discountText]}
          >
            {this.props.item.DiscountDisplayName}
          </AVText>
          <AVText
            style={[
              styles.discountAmountText,
              styles.modifierDiscountAmountText,
            ]}
          >
            -
            <AVFormattedCurrency
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`Discount amount $${parseFloat(
                this.props.item.Discount,
              )}`}
              aria-label={`Discount amount $${parseFloat(
                this.props.item.Discount,
              )}, text`}
              value={parseFloat(this.props.item.Discount)}
              currency={AccountStore.getCurrency()}
            />
          </AVText>
        </View>
      );
    }

    let modifiers: JSX.Element[] | null = null;

    if (this.props.item.Modifiers && this.props.item.Modifiers.length > 0) {
      this.props.item.Modifiers.sort((a, b) => b.Price - a.Price);

      modifiers = this.props.item.Modifiers.map((modifier, index) => {
        let priceText: JSX.Element | null = null;

        if (modifier.Price) {
          //the type is listed as a number everywhere, but a string comes through
          const price = parseFloat(`${modifier.Price}`);

          if (price > 0) {
            priceText = (
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessible={true}
                accessibilityRole="text"
                accessibilityLabel={`$${price.toFixed(2)}`}
                aria-label={`$${price.toFixed(2)}`}
                style={[styles.discountText, styles.modifierDiscountAmountText]}
              >
                +
                <AVFormattedCurrency
                  style={[Styles.Style.description, styles.modifierText]}
                  value={price}
                  currency={AccountStore.getCurrency()}
                />
              </AVText>
            );
          }
        }

        return (
          <View key={index} style={[styles.rowContainer]}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`${modifier.Name}`}
              accessibilityRole="text"
              aria-label={`${modifier.Name}, text`}
              style={[
                Styles.Style.description,
                styles.modifierText,
                {
                  maxWidth: '60%',
                },
              ]}
            >
              {modifier.Name}
            </Text>
            {priceText}
          </View>
        );
      });
    }

    if (this.props.cartType === CartTypes.TouchlessCoffee) {
      modifiers = this.getCoffeeModifiers();
    }

    const row = (
      <TouchableOpacity
        accessible={false}
        accessibilityLabel="Edit product"
        accessibilityRole="button"
        accessibilityState={{disabled: !this.props.canEdit}}
        role="button"
        aria-disabled={!this.props.canEdit}
        aria-label="Edit product"
        style={styles.container}
        onPress={this.itemClicked}
        disabled={!this.props.canEdit}
      >
        <View style={styles.mainRow}>
          <View style={styles.rowContainer}>
            <View style={styles.mainSection}>
              {Platform.OS !== 'web' && (
                <TouchableOpacity
                  accessible={true}
                  accessibilityLabel="Delete product"
                  accessibilityRole="button"
                  accessibilityHint={`Double tap to delete ${this.props.item?.Description} from your cart`}
                  role="button"
                  aria-label="Delete product"
                  style={styles.deleteButton}
                  onPress={this.trashClick}
                >
                  <DeleteX color={Styles.lightGray} />
                </TouchableOpacity>
              )}
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                style={Styles.Style.description}
              >
                {this.props.item.Description}
              </AVText>
              {this.props.item.SoldOut && (
                <AVText
                  accessible={true}
                  accessibilityLabel={Localized.Labels.sold_out}
                  accessibilityRole="text"
                  aria-label={Localized.Labels.sold_out}
                  style={styles.status}
                >
                  {Localized.Labels.sold_out}
                </AVText>
              )}
            </View>
            <AVFormattedCurrency
              style={[
                styles.amount,
                {
                  marginTop: 13,
                },
              ]}
              value={parseFloat(this.props.item.Price ?? '0')}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={`Item price: $${parseFloat(
                this.props.item.Price ?? '0',
              ).toFixed(2)}`}
              aria-label={`Item price: $${parseFloat(
                this.props.item.Price ?? '0',
              ).toFixed(2)}, text`}
              testID="itemPrice"
              currency={AccountStore.getCurrency()}
            />
          </View>
          {discountRow}
          {this.props.item?.TransactionChargeName &&
            this.props.item?.TransactionChargeAmount &&
            supRow}
          {modifiers}
        </View>
      </TouchableOpacity>
    );

    if (!this.props.canDelete) {
      return <View accessible={true}>{row}</View>;
    } else {
      return (
        <SwipeableRow
          onDeletePressed={this.trashClick}
          type="remove"
          ref={this.updateRef}
        >
          {row}
        </SwipeableRow>
      );
    }
  }
}

const styles = StyleSheet.create({
  amount: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    marginRight: 0,
  },
  container: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m2,
    marginBottom: 10,
  },
  discountAmountText: {
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f0,
  },
  discountText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m1,
  },
  modifierText: {
    color: Styles.lightGray,
    left: 44,
    fontSize: Styles.Fonts.f1,
    marginTop: 4,
    marginBottom: 4,
  },
  mainRow: {
    flex: 1,
    paddingLeft: Styles.Spacing.m1,
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingRight: Styles.Spacing.m2,
    color: Styles.lightGray,
  },
  trashIcon: {
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m1,
  },
  status: {
    color: Styles.dangerColor,
    textTransform: 'uppercase',
  },
  mainSection: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  deleteButton: {
    marginRight: Styles.Spacing.m0,
    justifyContent: 'center',
    minHeight: Styles.Heights.touchTargetHeight1,
    minWidth: Styles.Heights.touchTargetHeight1,
  },
  modifierDiscountAmountText: {
    position: 'absolute',
    right: Styles.Spacing.m2,
    marginTop: Platform.OS === 'web' ? Styles.Spacing.m2 : Styles.Spacing.m0,
  },
});
export default CartItem;
