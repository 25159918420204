import {HttpVerbs} from 'src/api/Api';

const DEFAULT_TIMEOUT = 120 * 1000; // 120 seconds

export default class HttpClient {
  constructor(
    private newFetch: (
      input: RequestInfo,
      init?: RequestInit,
    ) => Promise<Response>,
  ) {
    this.newFetch = newFetch || fetch;
  }

  static parseJsonSafe(text: string) {
    try {
      return JSON.parse(text);
    } catch {
      return text;
    }
  }

  // Returns a new promise object that
  // resolve with a json object on good api call or reject with an error object on failure
  // This method provide timeout functionality that fetch API lacks
  // and provides a simpler way to api consumer to determine success and failure api request
  // by handling success request in promise's onFulfilled and failure in onRejected callback.
  public fetchJSON(url: string, opts?: any) {
    let timeout = DEFAULT_TIMEOUT;

    if (opts) {
      if (opts.params) {
        url = `${url}?${opts.params}`;
      }

      timeout = opts.timeout || DEFAULT_TIMEOUT;

      delete opts.timeout;

      // for chrome developer console as ios/native code doesn't support cors
      // “cors” will allow requests for assets on the same-origin and other origins
      // which return the appropriate CORs headers.
      opts.mode = opts.mode || 'cors';
    }



    // https://developers.google.com/web/updates/2015/03/introduction-to-fetch
    // https://davidwalsh.name/fetch
    const fetchPromise = this.newFetch(url, opts);

    // https://developer.mozilla.org/en-US/docs/Mozilla/JavaScript_code_modules/Promise.jsm/Deferred
    // https://developer.mozilla.org/en-US/docs/Mozilla/JavaScript_code_modules/Promise.jsm/Promise#Constructor
    const promise = new Promise((resolve, reject) => {
      let timeoutObject: number;

      if (timeout) {
        // https://facebook.github.io/react-native/docs/timers.html#timermixin
        timeoutObject = window.setTimeout(() => {
          const message =
            'Network request timed out. Please check your connection to api server!';
          const error = this.createError('NetworkTimedoutError', message);

          reject(error);
        }, timeout);
      }

      fetchPromise
        .then((resp) => {
          clearTimeout(timeoutObject);
          if (resp.ok) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
            // NOTE: This assume api always returns json data, otherwise, it may stuck on resp.json() on empty response
            // https://developer.mozilla.org/en-US/docs/Web/API/Body/json
            if (opts.method === HttpVerbs.Delete) {
              return resolve({status: 'ok'});
            }
            resp
              .text()
              .then((text) => {
                const data = HttpClient.parseJsonSafe(text);

                resolve(data);
              })
              .catch(() => {
                const error = this.createError(
                  'JSONParseError',
                  `${url} => Error parsing json`,
                  resp,
                );

                reject(error);
              });
          } else {
            resp
              .text()
              .then((text) => {
                const data = HttpClient.parseJsonSafe(text);
                const message =
                  data.msg ||
                  text ||
                  `response not ok with status: ${resp.status}`;
                const error = this.createError(
                  'StatusNotOkError',
                  message,
                  resp,
                );

                reject(error);
              })
              .catch((err) => {
                console.error(err);
                const error = this.createError(
                  'JSONParseError',
                  `${url} => Error parsing json`,
                  resp,
                );

                reject(error);
              });
          }
        })
        .catch((err) => {
          clearTimeout(timeoutObject);
          const error = this.createError(
            err.name,
            `${JSON.stringify(err)}. Error fetching ${url}`,
          );

          reject(error);
        })
        .catch(reject); // throw unhandled error, usually syntax error in then and cache block
    });

    return promise;
  }

  private createError(name: string, message: string, response?: any) {
    const error: any = new Error(message);
    error.name = name;
    error.networkResponse = response;
    return error;
  }
}
