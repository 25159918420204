import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../Styles';
import AVFormattedCurrency from './AVFormattedCurrency';
import AVText from './AVText';
import {useAppSelector} from 'src/redux/hooks';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';

type Props = WithLocalizedProps & {
  isCentered?: boolean;
  smallText?: boolean;
  primaryColor?: boolean;
  isButton?: boolean;
};

const BalanceContainer: React.FC<Props> = ({
  strings,
  isCentered,
  smallText,
  primaryColor,
  isButton,
}) => {
  const defaultBalance = useAppSelector(
    (state) => state.account.account.defaultBalance,
  );
  const textStyle = primaryColor ? styles.textPrimary : styles.textWhite;
  return (
    <View
      style={[styles.container, isCentered && styles.center]}
      accessible={true}
      accessibilityRole={isButton ? 'button' : 'text'}
    >
      <View style={styles.balanceContainer}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          style={[
            textStyle,
            primaryColor ? styles.label : styles.textWhite,
            smallText && styles.labelSmall,
          ]}
        >
          {strings.Labels.your_balance}
        </AVText>
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={
            defaultBalance ? `$${defaultBalance.balance.toString()}` : '0'
          }
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={[textStyle, styles.balance, smallText && styles.balanceSmall]}
          value={defaultBalance?.balance ?? 0}
          currency={defaultBalance?.currency ?? AccountStore.getCurrency()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  balance: {
    alignSelf: 'flex-end',
    fontSize: Styles.Fonts.f3,
    fontWeight: '600',
  },
  balanceContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  balanceSmall: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '300',
  },
  center: {
    alignItems: 'center',
  },
  container: {
    justifyContent: 'space-between',
  },
  label: {
    color: Styles.darkColor,
    alignSelf: 'flex-end',
    fontSize: Styles.Fonts.f1,
    position: 'relative',
  },
  labelSmall: {
    fontSize: Styles.Fonts.f0,
  },
  textPrimary: {
    color: Styles.balanceColor,
  },
  textWhite: {
    color: Styles.white,
  },
});

export default BalanceContainer;
