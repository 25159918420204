import type {
  BunnMenu,
  BunnRecipe,
  BunnRecipeSize,
} from 'src/api/coffee/BunnApi';

class BunnMenuStore {
  menu!: BunnMenu;
  selectedRecipe!: BunnRecipe | null;
  selectedSize!: BunnRecipeSize | null;
  leaveRoom = false;
  bold = false;
  uuid!: string;

  resetMenu(menu: BunnMenu, uuid: string) {
    this.menu = menu;
    this.uuid = uuid;
    this.selectedRecipe = null;
    this.selectedSize = null;
    this.leaveRoom = false;
    this.bold = false;
  }

  get AvailableRecipes(): BunnRecipe[] {
    if (!this.menu || !this.menu.recipes) {
      return [];
    }

    return this.menu.recipes.filter((recipe) => recipe.enabled);
  }

  get AvailableSizes(): BunnRecipeSize[] {
    if (this.selectedRecipe) {
      return this.selectedRecipe.sizes.filter((size) => size.sizeEnabled);
    }

    return [];
  }

  get SelectedRecipe() {
    if (this.selectedRecipe) {
      return this.selectedRecipe;
    }

    return this.selectedRecipe;
  }

  get SelectedSize() {
    if (this.selectedSize) {
      return this.selectedSize;
    }
  }

  get MenuIdentifier() {
    return this.uuid;
  }

  selectRecipe(recipe: BunnRecipe) {
    this.selectedRecipe = recipe;
  }

  selectSize(size: BunnRecipeSize) {
    this.selectedSize = size;
  }
}

export default new BunnMenuStore();
