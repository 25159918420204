import * as React from 'react';
import DashedLine from 'react-native-dashed-line';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {Funding} from 'src/types/TransactionDetail';
import {PixelRatio, StyleSheet, View, ScrollView} from 'react-native';
import AVText from '../AVText';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {WithLocalizedProps} from 'src/types/PropTypes';

type FundDetailProps = WithLocalizedProps & {
  detail: Funding;
  handleEmailReceipt: (transId: string) => void;
  handleRefund: (transId: string) => void;
};

const ALLOWED_REQUEST_REFUND_COUNTRIES = ['USA', 'CAN'];

const FundDetail = (props: FundDetailProps) => {
  let emailReceiptButton: JSX.Element | null = null;
  if (
    AccountStore.getRegion() === 'USA' &&
    !Util.isDatePastNinetyDays(props.detail.DateString)
  ) {
    emailReceiptButton = (
      <RoundedButton
        buttonType={ButtonType.action}
        accessible={true}
        accessibilityLabel={props.strings.Buttons.email_receipt}
        aria-label={props.strings.Buttons.email_receipt}
        accessibilityRole="button"
        role="button"
        onPress={() => {
          props.handleEmailReceipt(props.detail.Id);
        }}
        text={props.strings.Buttons.email_receipt}
      />
    );
  }

  let isRefundAllowed = false;
  if (ALLOWED_REQUEST_REFUND_COUNTRIES.includes(AccountStore.getRegion())) {
    isRefundAllowed = true;
  }
  const formattedDate = Util.formatDate(props.detail.DateString, 'LLL');
  return (
    <View style={styles.container}>
      <ScrollView style={styles.historyContainer}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          accessible={true}
          accessibilityLabel={`${props.strings.Labels.date} ${formattedDate}`}
          aria-label={`${props.strings.Labels.date} ${formattedDate}`}
          style={styles.date}
        >
          {formattedDate}
        </AVText>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          accessible={true}
          accessibilityLabel={`${props.strings.Labels.location} ${props.detail.Location}`}
          aria-label={`${props.strings.Labels.location} ${props.detail.Location}`}
          style={styles.location}
        >
          {props.detail.Location}
        </AVText>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          accessible={true}
          accessibilityLabel={`${props.strings.Labels.transaction_id}`}
          aria-label={`${props.strings.Labels.transaction_id}`}
          style={styles.trans}
        >
          {props.detail.Id}
        </AVText>

        {false &&
          isRefundAllowed &&
          props.detail.Processor !== undefined &&
          props.detail.Status !== 'VOID' &&
          (props.detail.Processor === Settings.processors.stripe ||
            props.detail.Processor === Settings.processors.heartland) && (
            <RoundedButton
              buttonType={ButtonType.outline}
              containerStyle={[
                styles.refundButton,
                props.detail.past90Days && styles.disabledRefundButton,
              ]}
              accessible={true}
              accessibilityLabel={props.strings.Buttons.request_refund}
              accessibilityRole="button"
              aria-label={props.strings.Buttons.request_refund}
              role="button"
              onPress={() => {
                props.handleRefund(props.detail.Id);
              }}
              text={props.strings.Buttons.request_refund}
            />
          )}
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={styles.dashStyle}
        />
        <View style={[styles.row]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={props.strings.Labels.account_funding}
            aria-label={props.strings.Labels.account_funding}
            style={styles.largeLabel}
          >
            {props.strings.Labels.account_funding}
          </AVText>
        </View>

        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={styles.dashStyle}
        />
        <View accessible={true} style={Styles.Style.summarySection}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessibilityLabel={props.strings.Labels.total}
              aria-label={props.strings.Labels.total}
              style={[styles.total]}
            >
              {props.strings.Labels.total}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[styles.total]}
              value={props.detail.Amount}
              accessibilityLabel={`$${props.detail.Amount}`}
              aria-label={`$${props.detail.Amount}`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              currency={AccountStore.getCurrency()}
            />
          </View>
        </View>
      </ScrollView>
      {AccountStore.getRegion() === 'USA' && (
        <RoundedButton
          accessible={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessibilityLabel={props.strings.Buttons.email_receipt}
          aria-label={props.strings.Buttons.email_receipt}
          accessibilityRole="button"
          role="button"
          buttonType={ButtonType.action}
          onPress={() => {
            props.handleEmailReceipt(props.detail.Id);
          }}
          text={props.strings.Buttons.email_receipt}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  date: {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
  },
  disabledRefundButton: {
    opacity: 0.5,
  },
  historyContainer: {
    flex: 1,
    marginBottom: Styles.Spacing.m5,
    padding: Styles.Spacing.m3,
  },
  largeLabel: {
    fontSize: Styles.Fonts.f1,
  },
  location: {
    fontSize: Styles.Fonts.f1,
  },
  refundButton: {
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  total: {
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  trans: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
});
export default FundDetail;
