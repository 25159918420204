import * as React from 'react';
import TextInput from '../AVTextInput';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import NBDropdown from '../NBDropdown';
import Styles from 'src/components/Styles';
type AmountDropdownProps = JSX.LibraryManagedAttributes<
  typeof TextInput,
  React.ComponentProps<typeof TextInput>
> & {
  formatNumber: (value: number) => string;
  remainingDue?: number;
  selectedValue: number;
  anchorPosition: string;
  onSelect: (value: string) => void;
  onVisibilityChange?: (visible: boolean) => void;
};

const AmountDropdown = (props: AmountDropdownProps) => {
  let amounts = AccountStore.getFundingOptions();

  if (props.remainingDue) {
    amounts = amounts.filter((amount) => amount > props.remainingDue);
  }

  const amountOptions = amounts.map((option) => ({
    value: option,
    text: props.formatNumber(option),
  }));

  if (props.remainingDue && props.remainingDue > 1) {
    amountOptions.unshift({
      value: props.remainingDue,
      text: `${props.formatNumber(props.remainingDue)} (${
        Localized.Labels.due_amount
      })`,
    });
  }

  return (
    <NBDropdown
      anchorPosition={props.anchorPosition}
      onVisibilityChange={props.onVisibilityChange}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      label={Localized.Labels.add_amount}
      options={amountOptions}
      onSelect={props.onSelect}
      selectedValue={props.selectedValue}
    />
  );
};

export default AmountDropdown;
