import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Styles from 'src/components/Styles';
type StarProps = {
  width: number;
  height: number;
};
export default class Star extends React.Component<StarProps> {
  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 497.5 478.9"
      >
        <Path
          fill={Styles.goldColor}
          d="M18.2,216.8l87,74.4c8.7,7.5,12.6,19.2,9.9,30.4L88.5,433c-6.3,26.5,22.5,47.5,45.8,33.3l97.7-59.7
					c9.8-6,22.2-6,32,0l97.7,59.7c23.3,14.2,52.1-6.7,45.8-33.3l-26.6-111.4c-2.7-11.2,1.1-22.9,9.9-30.4l87-74.4
					c20.7-17.7,9.7-51.7-17.5-53.8l-114.1-9.1c-11.5-0.9-21.5-8.2-25.9-18.8L276.3,29.3c-10.5-25.2-46.2-25.2-56.6,0l-43.9,105.7
					c-4.4,10.6-14.4,17.9-25.9,18.8L35.7,163C8.5,165.1-2.5,199.1,18.2,216.8z"
        />
      </Svg>
    );
  }
}
