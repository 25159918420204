import * as React from 'react';
import Svg, {G, Path, Polygon} from 'react-native-svg';
type LogoProps = {
  size: number;
  isAllWhite?: boolean;
}; // tslint:disable

export default class Logo extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 513 513"
      >
        <G
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <G id="whitelogo-(1)" fillRule="nonzero">
            <Path
              d="M256.7,486.9 C129.9,486.9 26.7,383.7 26.7,256.9 C26.7,130.1 129.9,26.9 256.7,26.9 C383.5,26.9 486.7,130.1 486.7,256.9 C486.7,297 476.4,334.7 458.3,367.6 L480.7,381.3 C501.2,344.4 513,302 513,256.9 C513,115.6 398,0.7 256.8,0.7 C115.5,0.7 0.6,115.6 0.6,256.9 C0.6,398.2 115.6,513.1 256.8,513.1 C258.1,513.1 259.4,513.1 260.7,513.1 L260.7,486.9 C259.2,486.9 257.9,486.9 256.7,486.9 Z"
              id="Shape"
              fill="black"
            />
            <Path
              d="M276.2,78.2 C353,86 419.1,143.3 434.2,223 C452.7,320.1 388.8,414.1 291.7,432.7 C194.6,451.3 100.5,387.2 82,290.1 C73.7,246.4 82.1,203.4 102.6,167.6 L85,155.6 C61.4,196 51.7,244.7 61.1,294.1 C81.8,402.7 187,474.2 295.6,453.5 C404.2,432.8 475.8,327.6 455.1,219 C437.7,127.7 365.3,62.8 276.7,56.7 L276.2,78.2 Z"
              id="Shape"
              fill={this.props.isAllWhite ? '#FFFFFF' : '#2555D9'}
            />
            <G
              id="Group"
              transform="translate(283.000000, 389.000000)"
              fill="black"
            >
              <Path
                d="M24.9,90.8 L27.4,90.4 L32.3,117.8 C29.9,118.2 27.5,118.7 25.2,119.1 L23,106.9 L18.3,114 L17.5,114.1 L10.6,109.1 L12.8,121.3 C10.4,121.7 8,122.2 5.7,122.6 L0.8,95.2 L3.4,94.7 L16.2,104.6 L24.9,90.8 Z"
                id="Shape"
              />
              <Path
                d="M58.4,106.1 L48,109.6 L47.6,113.4 L41,115.6 L40.7,114.6 L44,85 L46.9,84 L67.3,105.7 L67.6,106.7 L61,108.9 L58.4,106.1 Z M48.9,95.1 L48.2,103 L54.2,101 L48.9,95.1 Z"
                id="Shape"
              />
              <Path
                d="M96.4,92 L96.8,92.9 L89.7,96.3 L81.9,91 L78.6,92.6 L82.2,99.9 C80.1,100.9 77.9,102 75.8,103 C71.8,94.7 67.8,86.4 63.7,78 C67.3,76.3 71,74.5 74.6,72.7 C84.4,68 91.9,78.9 87.7,86.3 L96.4,92 Z M77.3,78.6 C75.8,79.3 74.2,80.1 72.7,80.8 C73.7,82.8 74.8,85 75.7,87 L80.3,84.8 C83.8,83.1 81,76.8 77.3,78.6 Z"
                id="Shape"
              />
              <Polygon
                id="Shape"
                points="107.1 74.6 106.4 78.6 110.3 84.6 104.6 88.3 89.7 65.4 95.4 61.7 102 71.8 102.1 67.6 102.5 57.1 109.5 52.5 108.1 67.8 124.4 75.4 117.6 79.8"
              />
              <Path
                d="M131.1,42.4 C127.9,45 126.4,46.4 123.2,49 L125.7,52 L132.9,46 C134.2,47.6 135.6,49.2 136.9,50.8 L129.7,56.8 C130.7,58 131.8,59.3 132.8,60.5 C135.9,57.9 137.8,56.4 141,53.7 C142.3,55.3 143.7,56.9 145,58.5 C140,62.7 136.4,65.7 131.4,69.8 C125.5,62.7 119.5,55.6 113.6,48.5 C118.5,44.4 121.9,41.6 126.9,37.4 C128.4,39.1 129.8,40.7 131.1,42.4 Z"
                id="Shape"
              />
              <Path
                d="M136.5,37.5 C135,36 133.4,34.6 131.9,33.1 L146.1,18.2 C147.6,19.6 149.1,21.1 150.7,22.6 L146,27.5 L161.5,42.3 C159.9,44 158.3,45.7 156.7,47.4 L141.2,32.6 L136.5,37.5 Z"
                id="Shape"
              />
              <Path
                d="M174.5,13.6 C172.9,12.4 171.2,14 169.4,16.1 C165.4,20.7 161.4,22.6 156.5,19 C146.8,12 159.7,-5.2 169.2,2.1 C167.7,4.1 166.6,5.6 165.2,7.5 C162.3,5.3 158,11.4 160.5,13.6 C162.1,15 163.7,13.6 165.4,11.8 C169.2,7.8 173.1,3.9 178.6,8.1 C187.9,15.1 174.5,33.3 164.8,25.8 C166.2,23.9 167.5,22.3 168.9,20.3 C171.8,22.5 177.1,15.6 174.5,13.6 Z"
                id="Shape"
              />
            </G>
            <Path
              d="M314.3,255.2 L322.2,189 L397.8,189 L397.8,212.1 L346.5,212.1 L343.6,238 C345.7,236.8 348.5,235.7 352,234.7 C355.5,233.7 358.9,233.3 362.2,233.3 C375.1,233.3 384.9,237.1 391.8,244.7 C398.7,252.3 402.1,263 402.1,276.7 C402.1,285 400.2,292.5 396.5,299.2 C392.8,305.9 387.6,311.1 380.9,314.7 C374.2,318.3 366.3,320.1 357.2,320.1 C349.1,320.1 341.5,318.4 334.4,315.1 C327.3,311.8 321.7,307.2 317.7,301.3 C313.7,295.5 311.7,288.9 311.7,281.5 L341.7,281.5 C342,286.2 343.5,290 346.2,292.8 C348.9,295.6 352.5,297 356.9,297 C367,297 372,289.6 372,274.7 C372,260.9 365.8,254 353.5,254 C346.5,254 341.3,256.2 337.9,260.7 L314.3,255.2 Z"
              id="Shape"
              fill="black"
            />
            <Path
              d="M284.5,188.1 L284.5,211.6 L283.2,211.6 C272,211.6 262.8,214.3 255.6,219.6 C248.4,225 244,232.4 242.3,241.9 C249.1,235.3 257.6,231.9 267.9,231.9 C279.1,231.9 288,236 294.6,244.2 C301.2,252.4 304.6,263.1 304.6,276.4 C304.6,284.6 302.7,292.2 298.8,299.1 C294.9,306 289.5,311.3 282.5,315.2 C275.5,319.1 267.8,321 259.4,321 C250.2,321 242,318.9 234.8,314.7 C227.6,310.5 221.9,304.6 217.9,296.8 C213.9,289 211.8,280.1 211.7,270 L211.7,258 C211.7,244.7 214.6,232.7 220.3,222.1 C226,211.5 234.2,203.1 244.8,197.1 C255.4,191.1 267.2,188 280.1,188 L284.5,188 L284.5,188.1 Z M257.5,255.2 C253.5,255.2 250.2,256.1 247.5,257.9 C244.8,259.7 242.9,262 241.5,264.6 L241.5,273.5 C241.5,289.8 247.2,297.9 258.5,297.9 C263.1,297.9 266.9,295.9 269.9,291.8 C272.9,287.7 274.5,282.6 274.5,276.5 C274.5,270.2 272.9,265 269.8,261.1 C266.8,257.1 262.6,255.2 257.5,255.2 Z"
              id="Shape"
              fill="black"
            />
            <Path
              d="M142.4,242.1 L156.5,242.1 C167.7,242.1 173.3,236.6 173.3,225.7 C173.3,221.4 172,218 169.3,215.3 C166.6,212.6 162.9,211.3 158,211.3 C154,211.3 150.6,212.5 147.7,214.8 C144.8,217.1 143.3,220 143.3,223.4 L113.4,223.4 C113.4,216.6 115.3,210.5 119.1,205.2 C122.9,199.9 128.1,195.7 134.9,192.7 C141.6,189.7 149,188.2 157.1,188.2 C171.5,188.2 182.8,191.5 191,198.1 C199.2,204.7 203.3,213.7 203.3,225.2 C203.3,230.8 201.6,236 198.2,241 C194.8,245.9 189.8,250 183.3,253 C190.2,255.5 195.6,259.3 199.5,264.3 C203.4,269.4 205.4,275.6 205.4,283.1 C205.4,294.6 201,303.9 192.1,310.8 C183.2,317.7 171.5,321.2 157.1,321.2 C148.6,321.2 140.8,319.6 133.5,316.4 C126.2,313.2 120.8,308.7 117,303 C113.3,297.3 111.4,290.8 111.4,283.5 L141.5,283.5 C141.5,287.5 143.1,290.9 146.3,293.8 C149.5,296.7 153.4,298.2 158.1,298.2 C163.4,298.2 167.6,296.7 170.7,293.8 C173.8,290.9 175.4,287.1 175.4,282.6 C175.4,276.1 173.8,271.5 170.5,268.7 C167.2,266 162.7,264.6 157,264.6 L142.4,264.6 L142.4,242.1 Z"
              id="Shape"
              fill="black"
            />
            <G
              id="Group"
              transform="translate(98.000000, 55.000000)"
              fill="black"
            >
              <Path
                d="M35.5,75.7 L36.3,76.4 L31.1,82.3 L21.9,80.5 L19.5,83.2 L25.6,88.6 C24,90.4 22.5,92.2 20.9,93.9 C14,87.8 7.2,81.8 0.3,75.7 C2.9,72.7 5.6,69.7 8.3,66.7 C15.5,58.6 26.5,65.8 25.5,74.1 L35.5,75.7 Z M13,70.7 C11.9,71.9 10.8,73.2 9.7,74.5 C11.4,76 13.2,77.6 14.9,79.1 L18.2,75.3 C20.7,72.4 15.7,67.7 13,70.7 Z"
                id="Shape"
              />
              <Path
                d="M41.6,44.4 C38.4,47 36.9,48.3 33.7,50.8 L36.2,53.8 L43.4,47.9 C44.7,49.5 46,51.1 47.3,52.7 L40.1,58.6 C41.1,59.8 42.1,61.1 43.2,62.3 C46.3,59.7 48.2,58.3 51.4,55.6 C52.7,57.2 54,58.8 55.3,60.4 C50.3,64.5 46.7,67.4 41.7,71.5 C35.9,64.4 30.1,57.3 24.3,50.2 C29.2,46.2 32.6,43.4 37.6,39.4 C38.9,41.1 40.3,42.8 41.6,44.4 Z"
                id="Shape"
              />
              <Path
                d="M49.6,38.7 C48.5,36.9 47.5,35.1 46.4,33.3 L64,22.9 C65.1,24.7 66.1,26.5 67.2,28.3 L61.4,31.7 L72.2,49.9 C70.2,51.1 68.2,52.3 66.2,53.5 L55.4,35.3 L49.6,38.7 Z"
                id="Shape"
              />
              <Path
                d="M96.3,34.8 L86.3,38.9 L86.1,42.7 L79.7,45.3 L79.3,44.4 L80.6,14.9 L83.4,13.8 L105,33.9 L105.4,34.8 L99,37.4 L96.3,34.8 Z M86.2,24.6 L86.1,32.5 L91.9,30.1 L86.2,24.6 Z"
                id="Shape"
              />
              <Polygon
                id="Shape"
                points="124.6 24 126 30 112.4 33.2 111 27.2 114.4 26.4 111 11.6 107.9 12.3 106.5 6.3 119.5 3.3 120.9 9.3 117.8 10 121.2 24.8"
              />
              <Path
                d="M142.9,21.4 L152.8,20.5 C153,22.8 153.1,24.5 153.3,26.8 C147.7,27.3 141.9,27.8 136.3,28.3 L134,1 C136.3,0.8 138.7,0.6 141,0.4 C142.3,14.5 141.9,9.8 142.9,21.4 Z"
                id="Shape"
              />
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
