import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../Styles';
import Icon from './AVIcon';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
import PinButton from './PinButton';
import type {Digit} from './PinButton';
type Props = {
  /**
   * @returns true if the passcode should be reset
   */
  onPinEntered: (passcode: string) => Promise<boolean>;
  label: string;
};

function PinControl({onPinEntered, label}: Props) {
  const [passcode, setPasscode] = useState('');

  async function onClick(digit: Digit) {
    const newPasscode = passcode + digit;

    if (passcode.length < 4) {
      setPasscode(newPasscode);
    }

    if (newPasscode.length === 4) {
      const shouldReset = await onPinEntered(newPasscode);

      if (shouldReset) {
        setPasscode('');
      }
    }
  }

  function onBackspace() {
    if (passcode.length <= 0) {
      return;
    }

    const newPasscode = passcode.substring(0, passcode.length - 1);
    setPasscode(newPasscode);
  }

  return (
    <View style={styles.container}>
      <AVText style={styles.label}>{label}</AVText>
      <View style={styles.entryContainer}>
        <View style={styles.row}>
          <View style={[styles.entry, passcode.length > 0 && styles.filled]} />
          <View style={[styles.entry, passcode.length > 1 && styles.filled]} />
          <View style={[styles.entry, passcode.length > 2 && styles.filled]} />
          <View style={[styles.entry, passcode.length > 3 && styles.filled]} />
        </View>
      </View>
      <View style={styles.digitContainer}>
        <View style={styles.row}>
          <PinButton onClick={onClick} digit="1" />
          <PinButton onClick={onClick} digit="2" />
          <PinButton onClick={onClick} digit="3" />
        </View>
        <View style={styles.row}>
          <PinButton onClick={onClick} digit="4" />
          <PinButton onClick={onClick} digit="5" />
          <PinButton onClick={onClick} digit="6" />
        </View>
        <View style={styles.row}>
          <PinButton onClick={onClick} digit="7" />
          <PinButton onClick={onClick} digit="8" />
          <PinButton onClick={onClick} digit="9" />
        </View>
        <View style={styles.row}>
          <View style={styles.emptyDigit} />
          <PinButton onClick={onClick} digit="0" />
          <AVTouchableOpacity
            onPress={onBackspace}
            accessibilityLabel="Backspace"
          >
            <View style={[styles.emptyDigit, styles.deleteContainer]}>
              <Icon
                name="ios-backspace"
                color={Styles.black}
                size={Styles.Fonts.f4}
                style={styles.delete}
              />
            </View>
          </AVTouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Styles.Spacing.m4,
  },
  delete: {
    height: Styles.Fonts.f4,
    width: Styles.Fonts.f4,
  },
  deleteContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  digitContainer: {
    alignItems: 'center',
    marginTop: Styles.Spacing.m5,
  },
  emptyDigit: {
    height: Styles.Fonts.f4 * 2,
    width: Styles.Fonts.f4 * 2,
  },
  entry: {
    borderColor: Styles.primaryColor,
    borderRadius: 65,
    borderWidth: 2,
    height: Styles.Heights.h2,
    marginHorizontal: Styles.Spacing.m1,
    overflow: 'hidden',
    width: Styles.Heights.h2,
  },
  entryContainer: {
    alignItems: 'center',
    height: Styles.Heights.h1,
    marginTop: Styles.Spacing.m4,
  },
  filled: {
    backgroundColor: Styles.primaryColor,
  },
  label: {
    alignSelf: 'center',
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    flexDirection: 'row',
  },
});
export default PinControl;
