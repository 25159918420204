import VendorsExchangeDevice from './bluetooth/vendorsExchange/VendorsExchangeDevice';
import VendingMachineConfig from 'src/models/VendingMachineConfig';
import {PaymentRequest} from './bluetooth/vendorsExchange/PaymentRequestCharacteristic';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';

export enum VendingStates {
  waitingForSelection = 0,
  waitingForRequest = 1,
  waitingForStatus = 2,
}

class VEVendSession {
  device: VendorsExchangeDevice;
  machineConfig: VendingMachineConfig;
  items: Array<PaymentRequest>;
  state: VendingStates;
  pendingItem: PaymentRequest | null;

  startSession(device: VendorsExchangeDevice, config: VendingMachineConfig) {
    this.device = device;
    this.machineConfig = config;
    this.items = [];
    this.state = VendingStates.waitingForSelection;
  }

  get Device(): VendorsExchangeDevice {
    return this.device;
  }

  get MachineConfig(): VendingMachineConfig {
    return this.machineConfig;
  }

  get State(): VendingStates {
    return this.state;
  }

  get AuthorizationAmount(): number {
    const balance = AccountStore.getAccountBalance();
    const remaining = balance - this.CartTotal;
    return Math.min(remaining, this.machineConfig.AuthorizationAmount);
  }

  get CartTotal(): number {
    return this.items.reduce((a, b) => a + (b.selectionPrice || 0), 0);
  }

  get Items(): Array<PaymentRequest> {
    return this.items;
  }

  get AVLiveItems(): Array<{
    key: string;
    price: number;
    desc: string;
  }> {
    return this.items.map((item) => ({
      key: item.selectionId,
      price: item.selectionPrice,
      desc: Localized.Labels.vended_item,
    }));
  }

  get PendingItem(): PaymentRequest | null {
    return this.pendingItem;
  }

  setState(state: VendingStates) {
    this.state = state;
  }

  addItemToCart(item: PaymentRequest) {
    this.pendingItem = null;
    this.items.push(item);
    this.state = VendingStates.waitingForSelection;
  }

  addPendingItem(item: PaymentRequest) {
    this.pendingItem = item;
    this.state = VendingStates.waitingForStatus;
  }

  clearPendingItem() {
    this.pendingItem = null;
    this.state = VendingStates.waitingForSelection;
  }
}

export default new VEVendSession();
