import Strings from 'src/constants/AppStrings';
export const getTaxDescription = (
  strings: typeof Strings,
  taxDescription: string,
  number?: number,
) => {
  if (taxDescription === 'Tax' || !taxDescription) {
    let result = strings.Labels.tax;

    if (number) {
      result += ` ${number}`;
    }

    return result;
  } else if (taxDescription.includes('TPS')) {
    return taxDescription.replace('TPS', strings.Labels.gst);
  } else if (taxDescription.includes('GST')) {
    return taxDescription.replace('GST', strings.Labels.gst);
  } else if (taxDescription.includes('TVQ')) {
    return taxDescription.replace('TVQ', strings.Labels.pst);
  } else if (taxDescription.includes('PST')) {
    return taxDescription.replace('PST', strings.Labels.pst);
  } else if (taxDescription.includes('VAT')) {
    return taxDescription.replace('VAT', strings.Labels.vat);
  }

  return taxDescription;
};
