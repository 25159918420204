import React from 'react';
import {View, TouchableOpacity, Text, StyleSheet} from 'react-native';
import moment from 'moment';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import {ScreenProps} from 'src/types/Screen';
import {DealStatus} from 'src/services/aws/DealRepository';
import DealHelper from '../../helpers/DealHelper';
import DealModel from 'src/models/Moblico/Deal';
type DealProps = ScreenProps & {
  deal: DealModel;
  onPress: (deal: DealModel) => void;
};
type DealState = {
  expired: boolean;
  completed: boolean;
};

class Deal extends React.Component<DealProps, DealState> {
  acceptingDeal = false;

  constructor(props: DealProps) {
    super(props);
    this.state = {
      expired: moment().isAfter(moment(props.deal.endDate)),
      completed: props.deal.dealStatus === DealStatus.redeemed,
    };
    this.renderClaimSection = this.renderClaimSection.bind(this);
    this.renderExpirationText = this.renderExpirationText.bind(this);
    this.acceptDeal = this.acceptDeal.bind(this);
    this.onPress = this.onPress.bind(this);
  }

  async acceptDeal() {
    const {deal, onPress, ...rest} = this.props;
    DealHelper.claimDeal(rest, this.props.deal.id);
  }

  onPress() {
    if (this.props.onPress) {
      this.props.onPress(this.props.deal);
    }
  }

  renderClaimSection() {
    if (this.props.deal.dealStatus === DealStatus.pulled) {
      return (
        <RoundedButton
          buttonType={ButtonType.normal}
          text={Localized.Labels.claim}
          onPress={this.acceptDeal}
          containerStyle={styles.claimButton}
          accessibilityLabel={Localized.Labels.claim}
        />
      );
    } else if (this.props.deal.dealStatus === DealStatus.redeemed) {
      let text: string = Localized.Labels.redeemed_1_time;

      if (this.props.deal.redeemedCount > 1) {
        text = Localized.Labels.formatString(
          Localized.Labels.redeemed_x_times,
          this.props.deal.redeemedCount,
        ) as string;
      }

      return (
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={[styles.dealTarget, styles.successText]}
        >
          {text}
        </Text>
      );
    }

    return null;
  }

  renderExpirationText() {
    let expirationPrefix = Localized.Labels.expires;

    if (this.state.expired) {
      expirationPrefix = Localized.Labels.expired;
    }

    const expirationText = `${expirationPrefix} ${moment(
      this.props.deal.endDate,
    ).calendar(null, {
      sameDay: `[${Localized.Labels.today}]`,
      nextWeek: 'dddd',
    })}`;
    return (
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        style={styles.expirationDate}
      >
        {expirationText}
      </Text>
    );
  }

  renderArrow() {
    if (this.state.expired || this.state.completed) {
      return null;
    }

    return (
      <View style={styles.arrow}>
        <FontAwesome5Pro
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </View>
    );
  }

  render() {
    return (
      <TouchableOpacity
        style={styles.container}
        disabled={this.state.expired || this.state.completed}
        onPress={this.onPress}
      >
        <View style={styles.mainContent}>
          <View style={styles.topSection}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={styles.dealName}
            >
              {this.props.deal.name}
            </Text>
            {this.renderExpirationText()}
          </View>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
            style={styles.dealDesc}
          >
            {this.props.deal.description}
          </Text>
          {this.renderClaimSection()}
        </View>
        {this.renderArrow()}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: Styles.Spacing.m3,
  },
  container: {
    padding: Styles.Spacing.m3,
    flex: 1,
    flexDirection: 'row',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  topSection: {
    flexDirection: 'row',
  },
  mainContent: {
    flex: 1,
  },
  rightSection: {
    justifyContent: 'space-between',
  },
  expirationDate: {
    paddingTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  dealName: {
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
    flex: 1,
    marginRight: Styles.Spacing.m2,
    fontWeight: '600',
    color: Styles.darkColor,
  },
  dealDesc: {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m1,
    color: Styles.darkColor,
  },
  claimButton: {
    alignSelf: 'flex-end',
  },
  dealTarget: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
    alignSelf: 'flex-end',
  },
  successText: {
    color: Styles.positiveColor,
    fontWeight: 'bold',
  },
});
export default Deal;
