import React from 'react';
import type {PlanogramInnerProduct} from 'src/types/ProductType';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Styles from '../../Styles';
import {StyleSheet, View, FlatList} from 'react-native';
import PlanogramAction from 'src/actions/PlanogramActions';
import PlanogramStore from 'src/stores/PlanogramStore';
import TextInput from '../AVTextInput';
import AVText from '../AVText';
import Icon from '../AVIcon';
import AVTouchableOpacity from '../AVTouchableOpacity';
import {WithLocalizedProps} from 'src/types/PropTypes';

type ProductTagSearchScreenProps = WithLocalizedProps & {
  deviceId: string;
  tagSelected: (tag: string) => void;
  onConnect: () => void;
};
type ProductTagSearchScreenState = {
  tags: Array<string>;
};

class ProductTagSearchScreen extends React.Component<
  ProductTagSearchScreenProps,
  ProductTagSearchScreenState
> {
  static handleSearchChange(value: string) {
    if (value.length === 0 || value.length >= 3) {
      PlanogramAction.tagQueryEntered(value);
    }
  }

  onChange: () => void;
  search: TextInput;

  constructor(props: ProductTagSearchScreenProps) {
    super(props);
    const tags = this.compileTags();
    this.state = {
      tags,
    };
    this.productClicked = this.productClicked.bind(this);
    this.renderTag = this.renderTag.bind(this);
    this.handleClickTag = this.handleClickTag.bind(this);
    this.onPlanogramStoreChanged = this.onPlanogramStoreChanged.bind(this);
    this.compileTags = this.compileTags.bind(this);
  }

  componentDidMount() {
    PlanogramStore.addChangeListener(this.onPlanogramStoreChanged);
  }

  componentWillUnmount() {
    PlanogramStore.removeChangeListener(this.onPlanogramStoreChanged);
  }

  onPlanogramStoreChanged() {
    const tags = this.compileTags();
    this.setState({
      tags,
    });
  }

  compileTags() {
    let tags = PlanogramStore.getFilteredTags();

    if (!tags) {
      tags = [];
    }

    tags.unshift(this.props.strings.Labels.all_products);
    return tags;
  }

  handleClickTag(tag: string) {
    this.props.tagSelected(tag);
  }

  renderTag(tag: any) {
    return (
      <AVTouchableOpacity
        key={tag.item}
        style={styles.cell}
        accessibilityLabel={'ChangeEmail'}
        onPress={() => this.handleClickTag(tag.item)}
      >
        <AVText style={styles.cellText}>{tag.item}</AVText>
        <Icon name="ios-arrow-forward" color={Styles.lightGray} size={24} />
      </AVTouchableOpacity>
    );
  }

  productClicked(product: PlanogramInnerProduct) {
    NavActions.push(AppRoutes.ProductNutrition, {
      deviceId: this.props.deviceId,
      product,
      onConnect: this.props.onConnect,
    });
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.searchField}>
          <Icon
            name="md-search"
            size={30}
            color={Styles.lightGray}
            style={styles.searchIcon}
            accessibilityLabel="Search"
          />
          <TextInput
            style={styles.searchText}
            placeholder={this.props.strings.Labels.search_view}
            onChangeText={ProductTagSearchScreen.handleSearchChange}
            clearButtonMode="while-editing"
          />
        </View>
        <FlatList
          style={styles.trayContainer}
          data={this.state.tags}
          renderItem={this.renderTag}
          keyExtractor={(item) => item}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cell: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Styles.bgColor,
    flexDirection: 'row',
    height: Styles.Heights.h5,
    justifyContent: 'space-between',
    paddingHorizontal: Styles.Spacing.m2,
  },
  cellText: {
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
  },
  container: {
    backgroundColor: Styles.bgColor,
    flex: 1,
  },
  searchField: {
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderColor: Styles.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: 'row',
    height: Styles.Heights.h4,
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m1,
  },
  searchIcon: {
    height: Styles.Heights.h3,
    width: Styles.Heights.h3,
  },
  searchText: {
    flex: 1,
    fontSize: Styles.Fonts.f3,
  },
  trayContainer: {
    flex: 1,
    marginTop: Styles.Spacing.m2,
  },
});
export default ProductTagSearchScreen;
