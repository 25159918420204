import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch} from '../../store';
import PlatformApi from 'src/api/PlatformApi';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import {acceptSnack} from './acceptSnack';
import {SnackStatus} from '../../slices/snackSlice';
import {blockUser} from './blockUser';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import Events from 'src/logging/Events';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type LoadSnackDetailParams = {
  locationId: string;
  snackId: string;
  snackAction?: string;
};
export const loadSnackDetail = createAsyncThunk<
  GiftItemType,
  LoadSnackDetailParams,
  {dispatch: AppDispatch}
>(
  'snack/loadSnackDetail',
  async ({locationId, snackId, snackAction}, {dispatch}) => {
    dispatch(showSpinner());
    let response;
    try {
      response = await PlatformApi.loadSnackDetails(locationId, snackId);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'LoadSnackDetails',
        JSON.stringify({locationId, snackId}),
        JSON.stringify(response),
      );

      if (
        snackAction === SnackActions.AcceptSnack &&
        response.status === SnackStatus.Sent
      ) {
        dispatch(acceptSnack({snackDetails: response}));
      } else if (snackAction === SnackActions.BlockSnack) {
        dispatch(blockUser({snackDetails: response}));
      } else {
        dispatch(hideSpinner());
        return response;
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'snack/loadSnackDetail',
        JSON.stringify(e),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'snack/loadSnackDetail',
        JSON.stringify(e),
      );
    }
  },
);
