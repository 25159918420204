import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import NavActions from 'src/actions/NavActions';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import HttpClient from 'src/api/HttpClient';
import Events from 'src/logging/Events';
import {loadGiftFeed} from './loadGiftFeed';
import PlatformApi from 'src/api/PlatformApi';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type SendSnackToUserParams = {
  locationId: string;
  amount: number;
  description: string | null;
  email: string;
  isPrivate: boolean;
  stickerId: number;
  transDate: string;
};
export const sendSnackToUser = createAsyncThunk<
  void,
  SendSnackToUserParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'snack/sendSnackToUser',
  async (
    {locationId, amount, description, email, isPrivate, stickerId, transDate},
    {dispatch, getState},
  ) => {
    dispatch(showSpinner());
    let response;
    try {
      const accountId = getState().account.account.id;
      const accountBalanceId = getState().account.account.defaultBalance.id;

      response = await PlatformApi.sendSnackToUser(
        locationId,
        amount,
        description,
        email,
        isPrivate,
        accountId,
        stickerId,
        transDate,
        accountBalanceId,
        Settings.buildType,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'SendSnackToUser',
        JSON.stringify({
          locationId,
          amount,
          description,
          email,
          isPrivate,
          accountId,
          stickerId,
          transDate,
          accountBalanceId,
        }),
        JSON.stringify(response),
      );

      if (response) {
        const responseCode = HttpClient.parseJsonSafe(
          response?.status ?? response?.statusCode,
        );
        if (responseCode === 404) {
          alertError(
            Localized.Errors.person_cannot_receive_snack_error,
            null,
            undefined,
            Localized.Errors.unable_to_send,
          );
          return;
        }
        dispatch(
          loadGiftFeed({
            locationId: AccountStore.getLocationId(),
          }),
        );
        await ActionsFactory.getAccountActions().getBalance(
          AccountStore.getAccountId(),
        );

        NavActions.pop();
        setImmediate(() => {
          alertSuccess(
            Localized.Success.formatString(
              Localized.Success.snack_sent_success,
              response.amount.toFixed(2).toString(),
              response.receiverName,
            ),
          );
        });
      }
    } catch (err) {
      const errorCode = HttpClient.parseJsonSafe(
        err?.status ?? err?.statusCode,
      );

      if (errorCode === 404) {
        alertError(
          Localized.Errors.person_cannot_receive_snack_error,
          null,
          undefined,
          Localized.Errors.unable_to_send,
        );
      } else {
        alertError(Localized.Errors.error);
        CrashlyticsEvents.log(
          'Exception',
          'snack/sendSnackToUser',
          err.message ? err.message : JSON.stringify(err),
          response,
        );
        Events.Error.trackEvent(
          'Exception',
          'snack/sendSnackToUser',
          err.message ? err.message : JSON.stringify(err),
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  },
);
