import * as React from 'react';
import CreditCardImage from './CreditCardImage';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import AccountConstants from 'src/constants/AccountConstants';
import NBDropdown from './NBDropdown';
import {CreditCard} from 'src/models/CreditCard';

type PaymentMethodDropdownProps = {
  cards: Array<CreditCard>;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  payrollAvailable: boolean;
  showAddNewPayment: boolean;
  value: string;
  anchorPosition: string;
  onSelect: (value: string) => void;
  onVisibilityChange?: (visible: boolean) => void;
};
const PAYROLL = 'PAYROLL';
export default class PaymentMethodDropdown extends React.Component<PaymentMethodDropdownProps> {
  static defaultProps = {
    showAddNewPayment: true,
  };

  constructor(props: PaymentMethodDropdownProps) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(value: string) {
    if (value === AccountConstants.ADD_PAYMENT_TOKEN) {
      NavActions.nestedPush(
        [AppRoutes.Main, AppRoutes.Settings],
        AppRoutes.Cards,
      );
    } else {
      this.props.onSelect(value);
    }
  }

  render() {
    const cardOptions: any = this.props.cards.map((card) => ({
      value: card.id,
      text: card.name,
      image: <CreditCardImage issuer={card.cardIssuer} />,
    }));

    if (this.props.applePayAvailable) {
      cardOptions.unshift({
        value: AccountConstants.APPLE_PAY_TOKEN,
        text: AccountConstants.APPLE_PAY_LABEL,
        image: (
          <CreditCardImage
            issuer={AccountConstants.APPLE_PAY_TOKEN as string}
          />
        ),
      });
    }

    if (this.props.payrollAvailable) {
      cardOptions.unshift({
        value: AccountConstants.PAYROLL_TOKEN,
        text: AccountConstants.PAYROLL_LABEL,
        image: (
          <CreditCardImage issuer={AccountConstants.PAYROLL_TOKEN as string} />
        ),
      });
    }

    if (this.props.showAddNewPayment) {
      cardOptions.push({
        value: AccountConstants.ADD_PAYMENT_TOKEN,
        text: Localized.Labels.add_new_payment,
        image: (
          <FontAwesome5Pro name="plus-circle" color="#555" size={26} light />
        ),
      });
    }

    if (this.props.googlePayAvailable) {
      cardOptions.unshift({
        value: AccountConstants.GOOGLE_PAY_TOKEN,
        text: AccountConstants.GOOGLE_PAY_LABEL,
        image: (
          <CreditCardImage
            issuer={AccountConstants.GOOGLE_PAY_TOKEN as string}
          />
        ),
      });
    }

    return (
      <NBDropdown
        accessibilityLabel={Localized.Labels.payment_method_drop_down}
        accessibilityRole="menu"
        accessibilityValue={{text: this.props.value}}
        aria-label={Localized.Labels.payment_method_drop_down}
        role="menu"
        anchorPosition={this.props.anchorPosition}
        onVisibilityChange={this.props.onVisibilityChange}
        label={Localized.Labels.payment}
        options={cardOptions}
        onSelect={this.onSelect}
        selectedValue={
          this.props.value === PAYROLL
            ? AccountConstants.PAYROLL_TOKEN
            : this.props.value
        }
      />
    );
  }
}
