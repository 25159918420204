class SyncService {
  addOnSyncListener(callback: () => void) {}

  addOnSyncFinishedListener(callback: () => void) {}

  addEvents() {}

  removeEvents() {}

  performSyncWithServer() {}
}

export default new SyncService();
