import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type LogoProps = {
  size: number;
}; // tslint:disable

export default class MasterCard extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        width={(32 / 21) * this.props.size}
        height={this.props.size}
        viewBox="0 0 32 21"
      >
        <Path
          d="M26.58,21L2.42,21A2.4,2.4 0,0 1,0 18.62L0,4.38A2.4,2.4 0,0 1,2.42 2h24.16A2.4,2.4 0,0 1,29 4.38v14.25A2.4,2.4 0,0 1,26.58 21z"
          fill="#003663"
        />
        <Path
          d="M10.5,11.5m-6.5,0a6.5,6.5 0,1 1,13 0a6.5,6.5 0,1 1,-13 0"
          fill="#EB1C26"
        />
        <Path
          d="M18.5,11.5m-6.5,0a6.5,6.5 0,1 1,13 0a6.5,6.5 0,1 1,-13 0"
          fill="#F99F1B"
        />
        <Path
          d="M14.5,6.38a6.49,6.49 0,0 0,0 10.24,6.49 6.49,0 0,0 0,-10.24z"
          fill="#EF5D20"
        />
      </Svg>
    );
  }
}
