import Events from 'src/logging/Events';
import AVLiveApi, {HardwareTypes} from 'src/api/AVLiveApi';
import AccountApi from 'src/api/AccountApi';
import PlatformApi from 'src/api/PlatformApi';
import AppApi from 'src/api/AppApi';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import AccountStore from 'src/stores/AccountStore';
import type {VendingMachine} from 'src/services/bluetooth/VendingMachineInterface';
import MachineConstants from 'src/constants/MachineConstants';
import GmaApi from 'src/api/GmaApi';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class MachineActions {
  clearMachines() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.CLEAR_MACHINES,
    });
  }

  clearMarkets() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.CLEAR_MARKETS,
    });
  }

  async machineAdded(machine: VendingMachine) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MACHINE_ADDED,
      data: {
        machine,
      },
    });
    Events.BluetoothDeviceScanned.trackEvent('machine', machine.deviceId);
    let _gmaId = '';
    const locationId = AccountStore.getLocationId();

    if (!locationId) {
      _gmaId = AccountStore.getAccountId();
    }
    let _machineresponse;
    try {
      _machineresponse = await AVLiveApi.getMachineInfo(
        machine.deviceId,
        _gmaId,
      );
      Events.BluetoothDeviceFound.trackEvent(
        'machine',
        machine.deviceId,
        _machineresponse.Name,
      );
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.MACHINE_UPDATED,
        data: {
          machine: {..._machineresponse, DeviceId: machine.deviceId},
        },
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'MachineActions:MachineAdded',
        error.message ? error.message : error.toString(),
        _machineresponse,
      );
      Events.Error.trackEvent(
        'Exception',
        'MachineActions:MachineAdded',
        error.message ? error.message : error.toString(),
      );
    }
  }

  clearDevices() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.CLEAR_DEVICES,
      data: {},
    });
  }

  errorConnecting() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.ERROR_CONNECTING,
      data: {
        error: 'general',
      },
    });
  }

  machineNotAvailable(isOperator: boolean, deviceId: string) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.ERROR_CONNECTING,
      data: {
        error: 'notavailable',
      },
    });

    if (!isOperator) {
      AVLiveApi.sendEmail(
        deviceId,
        'AirVend Inside Mdb Communication Down',
        '',
        '',
        'This machine has lost communication with the AirVend Inside Device (MDB)',
      );
    }
  }

  invalidKey() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.ERROR_CONNECTING,
      data: {
        error: 'invalidkey',
      },
    });
  }

  async deviceAddedVE(device: VendorsExchangeDevice) {
    Events.BluetoothDeviceScanned.trackEvent('VE Device', device.deviceId);
    const response: any = await AVLiveApi.getMachineInfoV3(
      device.deviceId,
      AccountStore.getAccountId(),
      HardwareTypes.VEX,
    );

    if (response) {
      Events.BluetoothDeviceFound.trackEvent(
        'VE Device',
        device.deviceId,
        response.Name,
      );

      if (response.Name) {
        device.setDeviceName(response.Name);
        device.setImageUrl(response.ImageUrl);
        AppDispatcher.handleViewAction({
          actionType: MachineConstants.MACHINE_ADDED,
          data: {
            machine: device,
          },
        });
      } else {
        AppDispatcher.handleViewAction({
          actionType: MachineConstants.DEVICE_ADDED,
          data: {
            device,
          },
        });
      }
    }
  }

  async deviceAdded(device: VendingMachine) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.DEVICE_ADDED,
      data: {
        device,
      },
    });
    Events.BluetoothDeviceScanned.trackEvent('device', device.deviceId);
    let gmaId = '';
    const locationId = AccountStore.getLocationId();

    if (!locationId) {
      gmaId = AccountStore.getAccountId();
    }
    let response;
    try {
      response = await AVLiveApi.getMachineInfo(device.deviceId, gmaId);
      Events.BluetoothDeviceFound.trackEvent(
        'device',
        device.deviceId,
        response.Name,
      );
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.DEVICE_UPDATED,
        data: {
          machine: {...response, DeviceId: device.deviceId},
        },
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'Machine:DeviceAdded',
        error.message ? error.message : error.toString(),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'Machine:DeviceAdded',
        error.message ? error.message : error.toString(),
      );
    }
  }

  async handleServiceComplete(deviceId: string, data: any) {
    try {
      const response = await AVLiveApi.serviceComplete(
        deviceId,
        data.date,
        data.cashBagAmount,
      );
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.SERVICE_COMPLETE,
        data: {
          response: response,
          deviceId: deviceId,
        },
      });
    } catch (error) {
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.SERVICE_COMPLETE_ERROR,
        data: {
          error: error,
          deviceId: deviceId,
        },
      });
    }
  }

  dexDataReceived(message: string) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.DEX_DATA_RECEIVED,
      data: {
        dexDataReceived: message,
      },
    });
  }

  dexFailed(message: string) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.DEX_FAILED,
      data: {
        dexFailed: message,
      },
    });
  }

  deviceUnattached(deviceId: string) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.DEVICE_UNATTACHED,
      data: {
        deviceId: deviceId,
      },
    });
  }

  setupDataReceived(deviceId: string, setupData: any) {
    // update the store with the data from the device immediately
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.UPLOAD_SETUP_ERROR,
      data: {
        deviceId: deviceId,
        setupData: setupData,
      },
    });
  }

  async checkForUpdate(deviceId: string, version: string) {
    const payload = await AVLiveApi.checkVersion(deviceId, version);

    AppDispatcher.handleViewAction({
      actionType: MachineConstants.CHECK_VERSION,
      data: {
        payload,
      },
    });
  }

  uploadSetupData(deviceId: string, setupData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await AVLiveApi.setup(deviceId, setupData);

        if (response.MachineConfig.MachineId > 0) {
          resolve(response);
        } else {
          this.deviceUnattached(deviceId);
          reject();
        }

        AppDispatcher.handleViewAction({
          actionType: MachineConstants.UPLOAD_SETUP,
          data: {
            response,
            deviceId,
            setupData,
          },
        });
      } catch (error) {
        reject(error);
        CrashlyticsEvents.log(
          'Exception',
          'MachineActions:UploadSetupData',
          error.message ? error.message : error.toString(),
          response,
        );
        Events.Error.trackEvent(
          'Exception',
          'MachineActions:UploadSetupData',
          error.message ? error.message : error.toString(),
        );
        AppDispatcher.handleViewAction({
          actionType: MachineConstants.UPLOAD_SETUP_ERROR,
          data: {
            error,
            deviceId,
            setupData,
          },
        });
      }
    });
  }

  uploadDexFiles(deviceId: string, files: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AVLiveApi.uploadDexFiles(deviceId, files);
        resolve(response);
        AppDispatcher.handleViewAction({
          actionType: MachineConstants.UPLOAD_DEX_FILES,
          data: {
            response: response,
            deviceId: deviceId,
            files: files,
          },
        });
      } catch (error) {
        reject(error);
        AppDispatcher.handleViewAction({
          actionType: MachineConstants.UPLOAD_DEX_FILES_ERROR,
          data: {
            error: error,
            deviceId: deviceId,
            files: files,
          },
        });
      }
    });
  }

  sendEmail(
    deviceId: string,
    subject: string,
    name: string,
    email: string,
    comments: string,
  ) {
    AVLiveApi.sendEmail(deviceId, subject, name, email, comments); // We don't care about the response for now.
  }

  machineRebooting(reason: string) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MACHINE_REBOOTING,
      data: {
        reason: reason,
      },
    });
  }

  disconnectedFromMachine() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.DISCONNECT_FROM_MACHINE,
    });
  }

  async addMachineImage(deviceId: string, imageData: string, date: string) {
    const response = await AVLiveApi.addMachineImage(deviceId, imageData, date);

    if (response) {
      this.notifyImageUpdate(deviceId, response);
    }

    return response;
  }

  machineImageAddedOffline(deviceId: string, base64Image: string) {
    const imageUrl = 'data:image/jpeg;base64,' + base64Image;
    this.notifyImageUpdate(deviceId, imageUrl);
  }

  machineImageDeletedOffline(deviceId: string) {
    this.notifyImageUpdate(deviceId, null);
  }

  notifyImageUpdate(deviceId: string, imageUrl: string | null) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MACHINE_IMAGE_UPDATED,
      data: {
        imageUrl,
        deviceId,
      },
    });
  }

  async deleteMachineImage(deviceId: string, date: string) {
    const response = await AVLiveApi.deleteMachineImage(deviceId, date);

    if (response) {
      this.notifyImageUpdate(deviceId, null);
    }

    return response;
  }

  async marketScanned(
    marketId: string,
    gmaId: string,
    type: string,
    distance = 0,
  ) {
    Events.BluetoothDeviceScanned.trackEvent(type, marketId, distance);

    if (AccountStore.isDemo()) {
      const response = {
        status: 'ok',
        msg: 'success',
        data: {
          id: 'e36b2b54c6ff0708b293c40d43dd7f50',
          org: 'a241a2924c6d6096522c51d1583778b0',
          type: 'sos',
          name: 'LocationA',
          city: 'Santa Clara',
          state: 'CA',
          timezone: 'America/Los_Angeles',
          startcardbal: 0,
          sitecode: 0,
          flag1: 0,
          nbr: 0,
          isdisabled: 'N',
          country: 'USA',
          broadcastid: '323334353637',
        },
      };
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.MARKET_UPDATED,
        data: {
          response,
        },
      });
      return Promise.resolve(response);
    }

    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MARKET_SCANNED,
      data: {
        market: {
          deviceId: marketId,
          distance,
        },
      },
    });
    const deviceResponse = await AccountApi.beaconLookUp(marketId, gmaId);
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'BeaconLookUp',
      JSON.stringify({marketId, gmaId}),
      JSON.stringify(deviceResponse),
    );

    let locationResponse: any;
    let paymentResponse: any;
    let canUserMakePurchaseResponse: any;
    if (deviceResponse && deviceResponse?.accountId) {
      locationResponse = await PlatformApi.fetchLocation(
        deviceResponse?.locationId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchLocation',
        JSON.stringify({locationId: deviceResponse?.locationId}),
        JSON.stringify(locationResponse),
      );
      paymentResponse = await AppApi.getPaymentCredentials(
        deviceResponse?.accountId,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'GetPaymentCredentials',
        JSON.stringify({accountId: deviceResponse?.accountId}),
        JSON.stringify(paymentResponse),
      );
      canUserMakePurchaseResponse = await AppApi.canUserMakePurchase(
        deviceResponse?.accountId,
        deviceResponse?.orgId,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'CanUserMakePurchase',
        JSON.stringify({
          accountId: deviceResponse?.accountId,
          orgId: deviceResponse?.orgId,
        }),
        JSON.stringify(canUserMakePurchaseResponse),
      );

      if (locationResponse && locationResponse?.locationId) {
        Events.BluetoothDeviceFound.trackEvent(
          'beacon',
          locationResponse.locationId,
          locationResponse.name,
        );
      }
    }

    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MARKET_UPDATED,
      data: {
        response: {
          ...locationResponse,
          ...deviceResponse,
          processorType: paymentResponse?.type,
          canUserMakePurchaseAtOrg:
            canUserMakePurchaseResponse?.canUserMakePurchase,
        },
        distance,
      },
    });
    return {
      ...deviceResponse,
      ...locationResponse,
      processorType: paymentResponse?.type,
      canUserMakePurchaseAtOrg:
        canUserMakePurchaseResponse?.canUserMakePurchase,
    };
  }

  // It will be removed once new API is ready
  // Used for Canteen Pantry create account flow
  async marketScannedLegacy(
    marketId: string,
    gmaId: string,
    type: string,
    distance = 0,
  ) {
    Events.BluetoothDeviceScanned.trackEvent(type, marketId, distance);

    if (AccountStore.isDemo()) {
      const response = {
        status: 'ok',
        msg: 'success',
        data: {
          id: 'e36b2b54c6ff0708b293c40d43dd7f50',
          org: 'a241a2924c6d6096522c51d1583778b0',
          type: 'sos',
          name: 'LocationA',
          city: 'Santa Clara',
          state: 'CA',
          timezone: 'America/Los_Angeles',
          startcardbal: 0,
          sitecode: 0,
          flag1: 0,
          nbr: 0,
          isdisabled: 'N',
          country: 'USA',
          broadcastid: '323334353637',
        },
      };
      AppDispatcher.handleViewAction({
        actionType: MachineConstants.MARKET_UPDATED_LEGACY,
        data: {
          response,
        },
      });
      return Promise.resolve(response);
    }

    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MARKET_SCANNED,
      data: {
        market: {
          deviceId: marketId,
          distance,
        },
      },
    });

    const response = await GmaApi.getLocationFromScannedId(
      marketId,
      gmaId,
      type,
    );

    if (response.status === 'ok') {
      Events.BluetoothDeviceFound.trackEvent(
        'beacon',
        response.data.id,
        response.data.name,
      );
    }

    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MARKET_UPDATED_LEGACY,
      data: {
        response,
        distance,
      },
    });
    return response;
  }

  uploadVmcLog(deviceId: string, logs: any) {
    return AVLiveApi.uploadVmcLog(deviceId, logs);
  }

  vmcLogReceived(deviceId: string, logs: any) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.VMC_LOG_RECEIVED,
      data: {
        logs,
        deviceId,
      },
    });
  }

  serviceModeChanged(serviceMode: boolean) {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.SERVICE_MODE_CHANGED,
      data: {
        serviceMode,
      },
    });
  }

  machineConnected() {
    AppDispatcher.handleViewAction({
      actionType: MachineConstants.MACHINE_CONNECTED,
    });
  }

  uploadMDBOptions(deviceId: string, mdbOptions: any) {
    return AVLiveApi.uploadMDBOptions(deviceId, mdbOptions);
  }
}

export default new MachineActions();
