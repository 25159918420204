import {displayCommands} from 'src/constants/BluetoothCommands';
import {hex2ascii} from './Encoding';
import Events from 'src/logging/Events';
export default class BluetoothLogger {
  logDataReceived(command: string, message: string, channel = 1) {
    this._addLogMessage(
      `BT <- channel: ${channel}` +
        ` command: ${this._commandToString(command)}` +
        ` message: ${this._hexMessageToString(message)}`,
    );
  }

  logHexDataSent(command: string, hexMessage: string, channel = 1) {
    this._addLogMessage(
      `BT -> channel: ${channel}` +
        ` command: ${this._commandToString(command)}` +
        ` message: ${hexMessage}`,
    );
  }

  logBinDataSent(command: string, hexMessage: number, channel: number) {
    this._addLogMessage(
      `BT -> channel: ${channel}` + ` binary block: ${hexMessage}`,
    );
  }

  logAsciiDataSent(command: string, asciiMessage: string, channel: number) {
    this._addLogMessage(
      `BT -> channel: ${channel}` +
        ` command: ${this._commandToString(command)}` +
        ` message: ${asciiMessage}`,
    );
  }

  onConnectionError() {
    this._addLogMessage('BT Connection Error!');
  }

  _addLogMessage(message: string) {
    Events.BluetoothMessage.trackEvent(message);
  }

  _commandToString(command: string) {
    return displayCommands[command] || command;
  }

  _hexMessageToString(message: string) {
    if (message && message.length > 0) {
      return hex2ascii(message);
    }

    return '';
  }
}
