import AccountActions from './AccountActions';
import type {MessageType} from 'src/types/MessageType';
import type {CCModel} from 'src/types/CCModel';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import AccountConstants from 'src/constants/AccountConstants';
import Settings from 'src/Settings';
const mkaResponse = {
  id: '9e77f8bc9f17c746d2dab16efc205d48',
  firstname: 'Brent',
  lastname: 'Mouritsen',
  concatname: 'Brent Mouritsen',
  passwordhash: '5v4Bn1pEANKG4X66V+Va85Mp3OSzkFNupiTGjc0xtVM=',
  passwordsalt: '3D8Gzg==',
  pinhash: 'FraswYij6y3dY9qwjHX+82aJtHK8D9PHvVlUEgI0FRM=',
  pinsalt: 'megLrA==',
  org: '3a1befa7d3170532600cb7fda6d9e2eb',
  location: '70d4f13554b3f34f10af8290071ad2d2',
  email: 'brent.mouritsen+3@gmail.com',
  points: Settings.buildType === BuildTypes.pantry ? 0 : 10517,
  accounttype: 0,
  balance: 7.02,
  reminderamount: 5.02,
  isdisabled: 'N',
  datecreated: '2017-08-14 10:06:59',
  lastupdated: '2019-01-08 14:49:20',
  lastbalupdtime: '2018-10-09 15:00:05',
  privacyversion: 0.01,
  privacydate: '2018-05-17 14:20:52',
  locale: 'en-US',
  currency: 'USD',
  region: 'USA',
  app: 'default',
  phone: 'adsfsdfdsaf',
  marketaccounts: [
    {
      id: '330fb02768c7eb7d7531624588e28592',
      org: 'a241a2924c6d6096522c51d1583778b0',
      location: '92ffb024ef7b538ce47981d1c39cf8e2',
      marketaccount: '9e77f8bc9f17c746d2dab16efc205d48',
      value: 'd21cd2359b592a24c89b3ac237c21ed3',
      usepin: 'N',
      idtype: 2,
      datecreated: null,
      lastupdated: '2019-03-18 16:57:27.0',
      isdisabled: 'N',
    },
    {
      id: 'ed803cf861bc81cc9912b3ef39c022a1',
      org: 'a241a2924c6d6096522c51d1583778b0',
      location: '92ffb024ef7b538ce47981d1c39cf8e2',
      marketaccount: '9e77f8bc9f17c746d2dab16efc205d48',
      value: 'a188253c4b3e74456d301ff1282952e8',
      usepin: 'N',
      idtype: 5,
      datecreated: null,
      lastupdated: '2019-03-18 16:57:27.0',
      isdisabled: 'N',
    },
  ],
  showPayroll: true,
  payrollAutoComplete: true,
  locationUserKey: '1564',
  locationName: 'Highwoods Properties',
  orgUserKey: '35025',
  eatItUrl: 'https://api.compassdigital.org/staging',
  showEatItOrDeleteIt: true,
  fundingOptions: '10,15,25,40,50',
  data: {
    id: '9e77f8bc9f17c746d2dab16efc205d48',
    firstname: 'Brent',
    lastname: 'Mouritsen',
    concatname: 'Brent Mouritsen',
    passwordhash: '5v4Bn1pEANKG4X66V+Va85Mp3OSzkFNupiTGjc0xtVM=',
    passwordsalt: '3D8Gzg==',
    pinhash: 'FraswYij6y3dY9qwjHX+82aJtHK8D9PHvVlUEgI0FRM=',
    pinsalt: 'megLrA==',
    org: '3a1befa7d3170532600cb7fda6d9e2eb',
    location: '70d4f13554b3f34f10af8290071ad2d2',
    email: 'brent.mouritsen+3@gmail.com',
    points: Settings.buildType === BuildTypes.pantry ? 0 : 10517,
    accounttype: 0,
    balance: 7.02,
    reminderamount: 5.02,
    isdisabled: 'N',
    datecreated: '2017-08-14 10:06:59.0',
    lastupdated: '2019-01-08 14:49:20.0',
    lastbalupdtime: '2018-10-09 15:00:05.0',
    privacyversion: 0.01,
    privacydate: '2018-05-17 14:20:52.0',
    locale: 'en-US',
    currency: 'USD',
    region: 'USA',
    app: 'test',
    phone: 'adsfsdfdsaf',
    marketaccounts: [
      {
        id: '330fb02768c7eb7d7531624588e28592',
        org: 'a241a2924c6d6096522c51d1583778b0',
        location: '92ffb024ef7b538ce47981d1c39cf8e2',
        marketaccount: '9e77f8bc9f17c746d2dab16efc205d48',
        value: 'd21cd2359b592a24c89b3ac237c21ed3',
        usepin: 'N',
        idtype: 2,
        datecreated: null,
        lastupdated: '2019-03-18 16:57:27.0',
        isdisabled: 'N',
      },
      {
        id: 'ed803cf861bc81cc9912b3ef39c022a1',
        org: 'a241a2924c6d6096522c51d1583778b0',
        location: '92ffb024ef7b538ce47981d1c39cf8e2',
        marketaccount: '9e77f8bc9f17c746d2dab16efc205d48',
        value: 'a188253c4b3e74456d301ff1282952e8',
        usepin: 'N',
        idtype: 5,
        datecreated: null,
        lastupdated: '2019-03-18 16:57:27.0',
        isdisabled: 'N',
      },
    ],
    showPayroll: true,
    payrollAutoComplete: true,
    locationUserKey: '1564',
    locationName: 'Highwoods Properties',
    orgUserKey: '35025',
    eatItUrl: 'https://api.compassdigital.org/staging',
    showEatItOrDeleteIt: true,
    fundingOptions: '10,15,25,40,50',
  },
  msg: 'success',
  status: 'ok',
};

class DemoAccountActions extends AccountActions {
  issueRefund(): Promise<any> {
    return Promise.resolve({
      status: 'ok',
      msg: 'success',
      data: {
        transactionid: '000000RS-refund',
        date: 1534287600000,
        transdate: '2018-08-15 00:00:00.0',
        amount: 10,
        cardissuer: 'Visa',
        name: 'x1111',
        processor: 14,
      },
    });
  }

  emailRefundReceipt(): Promise<any> {
    return Promise.resolve({
      response: {
        status: 'ok',
      },
    });
  }

  async login(username: string, password: string) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.LOGIN_SUCCESS,
      data: {
        username,
        password,
        demo: true,
        showPayroll: true,
        payrollAutoComplete: true,
        showEatItOrDeleteIt: true,
        response: {
          IsOperator: username === 'op',
          AccountId: 417,
          Verified: true,
          AuthorizedDevices: [],
          QRValue: 'ZQOLYF',
        },
      },
    });
    return Promise.resolve({
      IsOperator: username === 'op',
      AccountId: 417,
    });
  }

  async loginLegacy() {
    return this.loginGma('admin', 'admin!');
  }

  async loginGma(username: string, password: string) {
    if (username === 'op') {
      return Promise.resolve({
        status: 'err',
      });
    }

    AppDispatcher.handleViewAction({
      actionType: AccountConstants.LOGIN_GMA_SUCCESS,
      data: {
        username,
        password,
        demo: true,
        showPayroll: true,
        payrollAutoComplete: true,
        showEatItOrDeleteIt: true,
        response: mkaResponse,
      },
    });
    return Promise.resolve(mkaResponse);
  }

  async deleteCreditCard() {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DELETE_CREDIT_CARD_SUCCESS,
      data: {
        response: {},
      },
    });
    return {
      status: 'ok',
    };
  }

  async getProcessorCreds() {
    return {
      url: 'https://api.heartlandportico.com/SecureSubmit.v1/api/token',
      key: 'key',
      test: true,
      type: 'heartland',
      merchantId: 'merchantId',
    };
  }

  async addCreditCard(ccModel: CCModel) {
    const response = {
      status: 'ok',
    };
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.ADD_CREDIT_CARD_SUCCESS,
      data: {
        ccModel,
        response,
      },
    });
    return Promise.resolve(response);
  }

  async loadPurchaseHistory(accountId: string, page = 1, pageSize = 5) {
    let response = {
      items: [
        {
          balanceAdjustmentId: '3bf3d8adeaed89a29759204bf280e827',
          accountId: 'a0649088fa59e84abae9654d83c3de52',
          accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
          type: 'Sale',
          balanceType: 'DEFAULT',
          transactionId: 'bea0003B9-16203715937',
          deviceName: '365PAY-0000000003B9',
          amount: -1.19,
          dateCreated: '2021-05-07 07:13:19',
          transactionDate: '2021-05-07 12:43:17',
        },
        {
          balanceAdjustmentId: '094061c2b73b615e7a548b093acecbc5',
          accountId: 'a0649088fa59e84abae9654d83c3de52',
          accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
          type: 'Sale',
          balanceType: 'DEFAULT',
          transactionId: 'bea0003B9-16203715769',
          deviceName: '365PAY-0000000003B9',
          amount: -1.73,
          dateCreated: '2021-05-07 07:13:01',
          transactionDate: '2021-05-07 12:43:00',
        },
        {
          balanceAdjustmentId: 'b2cd1dfc07b816ec2915f25244509ebd',
          accountId: 'a0649088fa59e84abae9654d83c3de52',
          accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
          type: 'Sale',
          balanceType: 'DEFAULT',
          transactionId: 'bea0003B9-16203715583',
          deviceName: '365PAY-0000000003B9',
          amount: -1.73,
          dateCreated: '2021-05-07 07:12:42',
          transactionDate: '2021-05-07 12:42:40',
        },
      ],
      metadata: {
        pagination: {
          limit: 50,
          offset: 0,
          previousOffset: null,
          nextOffset: null,
          currentPage: null,
          pageCount: 1,
          totalCount: 3,
        },
        sortedBy: [],
      },
    };

    if (page > 1) {
      response = {
        items: [
          {
            balanceAdjustmentId: '3bf3d8adeaed89a29759204bf280e827',
            accountId: 'a0649088fa59e84abae9654d83c3de52',
            accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
            type: 'Sale',
            balanceType: 'DEFAULT',
            transactionId: 'bea0003B9-16203715937',
            deviceName: '365PAY-0000000003B9',
            amount: -1.19,
            dateCreated: '2021-05-07 07:13:19',
            transactionDate: '2021-05-07 12:43:17',
          },
          {
            balanceAdjustmentId: '094061c2b73b615e7a548b093acecbc5',
            accountId: 'a0649088fa59e84abae9654d83c3de52',
            accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
            type: 'Sale',
            balanceType: 'DEFAULT',
            transactionId: 'bea0003B9-16203715769',
            deviceName: '365PAY-0000000003B9',
            amount: -1.73,
            dateCreated: '2021-05-07 07:13:01',
            transactionDate: '2021-05-07 12:43:00',
          },
          {
            balanceAdjustmentId: 'b2cd1dfc07b816ec2915f25244509ebd',
            accountId: 'a0649088fa59e84abae9654d83c3de52',
            accountBalanceId: 'aeaa2c090c08aaa1b3239269b37d6586',
            type: 'Sale',
            balanceType: 'DEFAULT',
            transactionId: 'bea0003B9-16203715583',
            deviceName: '365PAY-0000000003B9',
            amount: -1.73,
            dateCreated: '2021-05-07 07:12:42',
            transactionDate: '2021-05-07 12:42:40',
          },
        ],
        metadata: {
          pagination: {
            limit: 50,
            offset: 0,
            previousOffset: null,
            nextOffset: null,
            currentPage: null,
            pageCount: 1,
            totalCount: 3,
          },
          sortedBy: [],
        },
      };
    }

    AppDispatcher.handleViewAction({
      actionType: AccountConstants.LOAD_PURCHASE_HISTORY_SUCCESS_GMA,
      data: {
        response,
        page,
        pageSize,
      },
    });
    return Promise.resolve(response);
  }

  emailReceipt() {
    return Promise.resolve({
      response: {
        status: 'ok',
      },
    });
  }

  emailFundingReceipt() {
    return Promise.resolve({
      response: {
        status: 'ok',
      },
    });
  }

  async loadPurchaseDetail(
    accountId: string,
    transactionId: string,
    type: string,
  ): Promise<any> {
    if (type === AccountConstants.SALE_TYPE) {
      const response = {
        Msg: 'success',
        Status: 'ok',
        Data: {
          Id: transactionId,
          DateString: '2018-03-27 10:42:18.0',
          DateLong: 1522143738000,
          Type: null,
          Amount: 2.4,
          Status: 'ok',
          Tax: 0.6,
          Tax1: 0.2,
          TaxCat1: null,
          Tax1Label: 'Tax 1',
          Tax2: 0.2,
          TaxCat2: null,
          Tax2Label: 'Tax 2',
          Tax3: 0.2,
          TaxCat3: null,
          Tax3Label: 'Tax 3',
          Deposit: 1,
          Location: 'LocationA',
          Timezone: 'America/Los_Angeles',
          Points: 240,
          Items: [
            {
              Id: 'carl1001-1657',
              Name: 'Aquafina',
              Price: 1.3,
              Quantity: 1,
            },
          ],
          Payments: [
            {
              Id: 'carl1001-1657',
              Amount: 2.4,
              Type: 'ACCOUNT',
            },
            {
              Id: 'carl1001-1657',
              Amount: 0.5,
              Type: 'CARTDISC',
            },
          ],
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_SALE_DETAIL,
        data: {
          response,
        },
      });
      return Promise.resolve(response);
    } else if (
      type === AccountConstants.FUNDING_TYPE ||
      type === AccountConstants.REFUND_TYPE
    ) {
      const updatedResponse = {
        Status: 'ok',
        Msg: 'success',
        Data: {
          Id: 'carl1001-142',
          DateLong: 1523633160000,
          DateString: '2018-04-13 16:26:00.0',
          Amount: 10,
          CardIssuer: 'visa',
          Name: '3456XXXXXX1234',
          Status: 'ok',
          Processor: 20,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_FUNDING_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return Promise.resolve(updatedResponse);
    }

    return Promise.resolve({});
  }

  async getRedemptionThresholds() {
    const response = {
      status: 'ok',
      msg: 'success',
      data: [
        {
          id: 'b961e4745597f3cf49404cf2aaeec737',
          org: '3a1befa7d3170532600cb7fda6d9e2eb',
          redeemvalue: 1,
          points: 10000,
          additionalpoints: 10000,
        },
        {
          id: 'b46e4da15404d24e4c0f2d5abad6f172',
          org: '3a1befa7d3170532600cb7fda6d9e2eb',
          redeemvalue: 3,
          points: 15000,
          additionalpoints: 5000,
        },
        {
          id: '4d04755eb54b16608f726ee6a242d69b',
          org: '3a1befa7d3170532600cb7fda6d9e2eb',
          redeemvalue: 5,
          points: 20000,
          additionalpoints: 5000,
        },
        {
          id: 'ada2e758bbfa43636153c8c6fa37ab30',
          org: '3a1befa7d3170532600cb7fda6d9e2eb',
          redeemvalue: 9,
          points: 30000,
          additionalpoints: 10000,
        },
      ],
    };
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.THRESHOLDS_LOADED,
      data: {
        response,
      },
    });
    return response;
  }

  async loadPromotions() {
    const response = {
      status: 'ok',
      msg: 'success',
      discountHeaders: [
        {
          id: 'a6e1194d655925f819445c7696edb842',
          name: 'redbull',
          score: 0,
          type: 2,
          amount: 0.25,
          percentage: 1,
          displayname: 'Buy 1 Get 1 Free',
          transmin: 0,
          imgpath: '../components/img/promotion-demo/GoldPeak.jpg',
          local: true,
        },
        {
          id: 'bb1f400ed6adf6a67b674bb2fbcaac6b',
          name: 'jacklinks',
          score: 0,
          type: 2,
          amount: 0.5,
          percentage: 1,
          displayname: 'Buy 1 Get 1 Free',
          transmin: 0,
          imgpath: '../components/img/promotion-demo/Bubly.jpg',
          local: true,
        },
        {
          id: 'discountheader1',
          name: 'jacklinks',
          score: 0,
          type: 2,
          amount: 0.5,
          percentage: 1,
          displayname: 'Buy 1 Get 1 Free',
          transmin: 0,
          imgpath: '../components/img/promotion-demo/RedBull.jpg',
          local: true,
        },
      ],
      discountItems: [
        {
          id: '0fb3a8d8cf3ebdbf4fb2663b14dcfc7f',
          discountheader: 'bb1f400ed6adf6a67b674bb2fbcaac6b',
          item: 'Jack Links Jerky',
          category: null,
          discountby: 'item',
          quantity: 1,
        },
        {
          id: 'bc21f6d613988f4f40b142bcec0adef5',
          discountheader: 'a6e1194d655925f819445c7696edb842',
          item: 'Redbull',
          category: null,
          discountby: 'item',
          quantity: 1,
        },
        {
          id: 'discountitem1',
          discountheader: 'discountheader1',
          item: 'Redbull',
          category: null,
          discountby: 'item',
          quantity: 1,
        },
      ],
      imgserver: 'https://static.test3.readytouchpos.com/images',
    };
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.PROMOTIONS_LOADED,
      data: {
        response,
      },
    });
    return response;
  }

  async getBalance(accountId: string, forceUpdate?: boolean, dispatch = true) {
    if (dispatch) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.GET_BALANCE_SUCCESS,
        data: mkaResponse,
      });
    }

    return Promise.resolve({
      status: 'ok',
      data: mkaResponse,
    });
  }

  async addFunds() {
    return Promise.resolve(true);
  }

  async addFundsPayroll() {
    return Promise.resolve(true);
  }

  chargeCard() {
    return Promise.resolve({
      status: 'ok',
    });
  }

  addFundsApplePay() {
    return Promise.resolve({
      status: 'ok',
    });
  }

  addFundsGooglePay() {
    return Promise.resolve({
      status: 'ok',
    });
  }

  async getPayrollId() {
    return Promise.resolve({
      status: 'ok',
      data: [
        {
          value: '123456789',
          isdisabled: 'N',
          id: 'mkiid',
        },
      ],
    });
  }

  async deleteMessage(message: MessageType) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DELETE_MESSAGE,
      data: {
        message,
      },
    });
  }

  async messageRead(message: MessageType) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.MESSAGE_READ,
      data: {
        message,
      },
    });
  }

  async refreshMessages() {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.MESSAGE_UPDATE,
    });
  }

  getReferralDetails(referralId: string) {
    return Promise.resolve({
      id: '0a05bfd292af70cec33094f6e4ceeaac',
      campaign: 'ec25a57011305ba07330d13eee728b56',
      org: '5231f89ab6a80f8e907a49d74eb7c6be',
      location: '62031e6edab5f3a8e8a7176dc7afc9d4',
      sender: 'd331a6137579f80ee2eabb074fc3e22c',
      receiver: null,
      method: 'email',
      contact: 'user.name@email.com',
      appName: '365Pay',
      senderRedeem: 'N',
      receiverRedeem: 'N',
      senderName: 'first last',
      receiverName: null,
      isActive: true,
      endDate: null,
      amount: 7.0,
      dateCreated: '2021-02-17 20:02:31',
      lastUpdated: '2021-02-17 20:02:31',
    });
  }
}

export default new DemoAccountActions();
