import {Notifications} from 'expo';
declare global {
  interface Window {
    OneSignal: any;
  }
}

window.OneSignal = window.OneSignal || [];

class OneSignalWeb {
  setAppId(appId: string) {
    window.OneSignal.push(function () {
      window.OneSignal.init({
        appId,
      });
    });
  }

  setNotificationWillShowInForegroundHandler(handler: (data: any) => any) {
    Notifications.addListener((data) =>
      handler({
        payload: {
          additionalData: {...data.payload.data},
        },
      }),
    );
  }

  setNotificationOpenedHandler(handler: (data: any) => any) {
    window.OneSignal.push(function () {
      window.OneSignal.on('notificationDisplay', (data) =>
        handler({
          notification: {
            payload: {
              additionalData: {...data},
            },
          },
        }),
      );
    });
  }

  sendTags(tags: any) {
    window.OneSignal.push(function () {
      window.OneSignal.sendTags(tags);
    });
  }

  setExternalUserId(accountId: string) {
    window.OneSignal.push(function () {
      window.OneSignal.setExternalUserId(accountId);
    });
  }

  removeExternalUserId() {
    window.OneSignal.push(function () {
      window.OneSignal.removeExternalUserId();
    });
  }

  getTags(callback: (tags: any) => any) {
    window.OneSignal.push(function () {
      window.OneSignal.getTags(callback);
    });
  }

  promptForPushNotificationsWithUserResponse(_callback: (result: any) => any) {
    window.OneSignal.push(function () {
      window.OneSignal.showSlidedownPrompt({
        force: true,
      });
    });
  }

  inFocusDisplaying(_displayOption: any) {}

  clearHandlers() {}
}

export default new OneSignalWeb();
