import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {PromotionType} from 'src/types/Promotions';
import type {IsConnectedProps} from 'src/types/Screen';
import withIsConnected from '../../hoc/withIsConnected';
import {useComponentSize} from '../../Hooks';
import VideoPromotion from './VideoPromotion';
import ImagePromotion from './ImagePromotion';
import Styles from '../../Styles';
type PromotionProps = IsConnectedProps & {
  promotion: PromotionType;
  baseUrl: string;
  onClick: () => void;
  navigation: any;
  promotionViewed: (promotion: PromotionType) => void;
};

function Promotion(props: PromotionProps) {
  const [size, onLayout] = useComponentSize();
  const promotion: PromotionType = props.promotion as any;
  const PromotionComponent = props.promotion.video
    ? VideoPromotion
    : ImagePromotion;

  return (
    <View onLayout={onLayout} style={styles.container}>
      {size && (
        <PromotionComponent
          onClick={props.onClick}
          promotionViewed={props.promotionViewed}
          navigation={props.navigation}
          isConnected={props.isConnected}
          promotion={promotion}
          width={size.width}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
});
export default withIsConnected(Promotion);
