import CoffeeMachineTypes from 'src/constants/CoffeeMachineTypes';
import LockerTypes from 'src/constants/LockerTypes';
import {DNA} from '../types/dnaTypes';

export type Sfecfg = {
  lockerType: number;
  isSendASnackEnabled: boolean;
  isInHiatusMode: boolean;
  coffeeMachineType: string;
  hasOrderNote: boolean;
  hasScanAndPay: boolean;
  dna?: DNA;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  is365PayAfterTaxPromo: boolean;
};
export default class LocationFeatures {
  readonly HasScanAndPay: boolean = true;
  readonly CoffeeMachine: string = 'None';
  readonly HasBunnCoffeeMachine: boolean = false;
  readonly IsInHiatusMode: boolean = false;
  readonly HasSendSnack: boolean = false;
  readonly HasOrderNote: boolean = false;
  LockerType: number = LockerTypes.None;
  readonly HasConsumerRoles: boolean = false;
  consumerRoleButton?: string = null;
  readonly Is365PayAfterTaxPromo: boolean = false;

  constructor(features?: Sfecfg) {
    const sfeConfig = features;

    if (!sfeConfig) {
      return;
    }

    this.HasScanAndPay = sfeConfig.hasScanAndPay;
    this.CoffeeMachine = sfeConfig.coffeeMachineType || 'None';
    this.HasBunnCoffeeMachine =
      this.CoffeeMachine.toUpperCase() === CoffeeMachineTypes.Bunn.type;
    this.IsInHiatusMode = sfeConfig.isInHiatusMode;
    this.HasSendSnack = sfeConfig.isSendASnackEnabled;
    this.HasOrderNote = sfeConfig.hasOrderNote;
    this.LockerType = Number(sfeConfig.lockerType) || LockerTypes.None;
    this.HasConsumerRoles = sfeConfig.hasConsumerRoles;
    this.consumerRoleButton = sfeConfig.consumerRoleButton;
    this.Is365PayAfterTaxPromo = sfeConfig.is365PayAfterTaxPromo;
  }
}
