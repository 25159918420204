import * as React from 'react';
import {View, Dimensions} from 'react-native';
type InViewPortProps = {
  disabled?: boolean;
  delay?: number;
  children: React.ReactNode;
  stopWhenVisible: boolean;
  onChange: (isVisble: boolean) => void;
};
type InViewPortState = {
  rectTop: number;
  rectBottom: number;
};
export default class InViewPort extends React.Component<
  InViewPortProps,
  InViewPortState
> {
  lastValue: boolean | null;
  myView: View;
  interval: any;

  constructor(props: InViewPortProps) {
    super(props);
    this.state = {
      rectTop: 0,
      rectBottom: 0,
    };
  }

  componentDidMount() {
    if (!this.props.disabled) {
      this.startWatching();
    }
  }

  componentWillUnmount() {
    this.stopWatching();
  }

  componentDidUpdate(prevProps: InViewPortProps) {
    if (prevProps.disabled !== this.props.disabled) {
      if (this.props.disabled) {
        this.stopWatching();
      } else {
        this.lastValue = null;
        this.startWatching();
      }
    }
  }

  startWatching() {
    if (this.interval) {
      return;
    }

    this.interval = setInterval(() => {
      if (!this.myView) {
        return;
      }

      this.myView.measure((x, y, width, height, pageX, pageY) => {
        this.setState({
          rectTop: pageY,
          rectBottom: pageY + height,
        });
      });
      this.isInViewPort();
    }, this.props.delay || 300);
  }

  stopWatching() {
    this.interval = clearInterval(this.interval);
  }

  isInViewPort() {
    const window = Dimensions.get('window');
    const halfViewHeight = (this.state.rectBottom - this.state.rectTop) / 2;
    const isVisible =
      this.state.rectBottom !== 0 &&
      ((this.state.rectTop >= 0 &&
        this.state.rectTop <= window.height - halfViewHeight) ||
        (this.state.rectBottom <= window.height &&
          this.state.rectBottom >= halfViewHeight));

    if (this.lastValue !== isVisible) {
      this.lastValue = isVisible;
      this.props.onChange(isVisible);
    }

    if (isVisible && this.props.stopWhenVisible) {
      this.stopWatching();
    }
  }

  render() {
    return (
      <View
        collapsable={false}
        ref={(component) => {
          this.myView = component;
        }}
        {...this.props}
      >
        {this.props.children}
      </View>
    );
  }
}
