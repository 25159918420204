import * as React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import type {PlanogramInnerProduct} from 'src/types/ProductType';
import {StyleSheet, View, ScrollView} from 'react-native';
import GiveCreditFooter from '../../elements/planogram/GiveCreditFooter';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';

type ProductNutritionScreenProps = {
  product: PlanogramInnerProduct;
  onConnect: () => void;
};

class ProductNutritionScreen extends React.Component<ProductNutritionScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ProductNutritionScreenProps) {
    super(props);
    this.onBackSelect = this.onBackSelect.bind(this);
  }

  onBackSelect() {
    NavActions.pop();
  }

  render() {
    return (
      <BackSubheader title={this.props.product.Name}>
        <ScrollView
          automaticallyAdjustContentInsets={false}
          style={styles.content}
        >
          <View style={styles.performanceFacts}>
            <View
              style={[Styles.Style.performanceFactsHeader, styles.thickRow]}
            >
              <AVText style={styles.performanceFactsTitle}>
                {Localized.Labels.nutrition_facts}
              </AVText>
              <AVText style={styles.smallInfo}>
                {' '}
                {Localized.Labels.serving_size} {this.props.product.ServingSize}
              </AVText>
            </View>
            <View style={styles.performanceFactsTable}>
              <View>
                <View style={styles.row}>
                  <AVText style={styles.smallInfo}>
                    {Localized.Labels.amount_per_serving}
                  </AVText>
                </View>
              </View>
              <View>
                <View style={[styles.row, styles.mediumRow]}>
                  <View style={[styles.subRow, Styles.Style.left]}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.calories}{' '}
                    </AVText>
                    <AVText>{this.props.product.Calories}</AVText>
                  </View>
                  <View style={[styles.subRow, Styles.Style.right]}>
                    <AVText>{Localized.Labels.calories_from_fat} </AVText>
                    <AVText>{this.props.product.CaloriesFromFat}</AVText>
                  </View>
                </View>
                <View style={[styles.thickRow, styles.row, styles.rightRow]}>
                  <View style={Styles.Style.right}>
                    <AVText>% {Localized.Labels.daily_value}</AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.subRow}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.total_fat}{' '}
                    </AVText>
                    <AVText>{this.props.product.TotalFat}</AVText>
                  </View>
                  <View style={Styles.Style.right}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.TotalFatPercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.subRow, styles.indent]}>
                    <AVText>{Localized.Labels.saturated_fat} </AVText>
                    <AVText>{this.props.product.SaturatedFat}</AVText>
                  </View>
                  <View>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.SaturatedFatPercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.subRow, styles.indent]}>
                    <AVText>{Localized.Labels.trans_fat} </AVText>
                    <AVText>{this.props.product.TransFat}</AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.subRow}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.cholesterol}{' '}
                    </AVText>
                    <AVText>{this.props.product.Cholesterol}</AVText>
                  </View>
                  <View>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.CholesterolPercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.subRow}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.sodium}{' '}
                    </AVText>
                    <AVText>{this.props.product.Sodium}</AVText>
                  </View>
                  <View>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.SodiumPercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.subRow}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.total_carbohydrate}
                    </AVText>
                    <AVText>{this.props.product.TotalCarbohydrate}</AVText>
                  </View>
                  <View>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.TotalCarbohydratePercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.subRow, styles.indent]}>
                    <AVText>{Localized.Labels.dietary_fiber} </AVText>
                    <AVText>{this.props.product.DietaryFiber}</AVText>
                  </View>
                  <View>
                    <AVText style={{fontWeight: 'bold'}}>
                      {this.props.product.DietaryFiberPercent}%
                    </AVText>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={[styles.subRow, styles.indent]}>
                    <AVText>{Localized.Labels.sugars} </AVText>
                    <AVText>{this.props.product.Sugars}</AVText>
                  </View>
                </View>
                <View style={[styles.thickRow, styles.row, styles.lastRow]}>
                  <View style={styles.subRow}>
                    <AVText style={{fontWeight: 'bold'}}>
                      {Localized.Labels.protein}{' '}
                    </AVText>
                    <AVText>{this.props.product.Protein}</AVText>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <GiveCreditFooter onPress={this.props.onConnect} />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  bold: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
  content: {
    margin: Styles.Spacing.m1,
    padding: Styles.Spacing.m1,
  },
  footer: {
    alignSelf: 'flex-end',
  },
  indent: {
    marginLeft: Styles.Spacing.m3,
  },
  lastRow: {
    borderBottomWidth: 0,
  },
  mediumRow: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: Styles.Spacing.m2,
  },
  performanceFacts: {
    backgroundColor: Styles.white,
    borderColor: Styles.darkColor,
    borderWidth: 1,
    padding: Styles.Spacing.m2,
  },
  performanceFactsTable: {
    marginTop: Styles.Spacing.m1,
  },
  performanceFactsTitle: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
  },
  rightRow: {
    justifyContent: 'flex-end',
  },
  row: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m1,
  },
  smallInfo: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m1,
    marginTop: -Styles.Spacing.m1,
  },
  subRow: {
    flexDirection: 'row',
  },
  thickRow: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<ProductNutritionScreenProps>()(
  ProductNutritionScreen,
);
