import Api from './Api';
export class CapSvrApi {
  checkCartForZeroInventory(location: string, items: string[]) {
    const capSvrReceipt = this.getCapSvrApiBase();
    const params: any = {
      locid: location,
      items: items,
    };
    const url = Api.getFullUrl(capSvrReceipt, '/product/checkzeroinventory');
    return Api.fetch(
      url,
      params,
      'POST',
      true,
      false,
      'application/x-www-form-urlencoded',
    );
  }

  getCapSvrApiBase(): string {
    return Api.getTallyUrl('capsvr');
  }
}
export default new CapSvrApi();
