import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import AVText from '../AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../RoundedButton';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Locker from '../../img/svg/Locker';
import {OrderStatusEnum} from 'src/constants/OrderStatusEnum';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import type {SaleOrder} from 'src/types/TransactionDetail';
import Util from 'src/Util';
type OrderSectionProps = {
  orders: Array<SaleOrder>;
  onButtonClick: (order: SaleOrder) => void;
  onCloseClick: (index: number) => void;
};
const OrderType = {
  [PreparationMethodValues.None]: {
    logo: () => {
      return null;
    },
    showCode: false,
  },
  [PreparationMethodValues.DineIn]: {
    logo: () => {
      return (
        <FontAwesome5Pro
          name="utensils"
          color={Styles.primaryColor}
          size={Styles.Fonts.f3}
          solid
          accessibilityLabel="Dine In"
        />
      );
    },
    showCode: false,
  },
  [PreparationMethodValues.ToGo]: {
    logo: () => {
      return (
        <FontAwesome5Pro
          name="running"
          color={Styles.primaryColor}
          size={Styles.Fonts.f3}
          solid
          accessibilityLabel="To GO"
        />
      );
    },
    showCode: false,
  },
  [PreparationMethodValues.Locker]: {
    logo: () => {
      return <Locker color={Styles.primaryColor} size={Styles.Fonts.f3} />;
    },
    showCode: true,
  },
};
const OrderState = {
  [OrderStatusEnum.Sent]: {
    title: Localized.Labels.your_order_sent,
    buttonLabel: Localized.Labels.pickup_options,
    showCode: true,
  },
  [OrderStatusEnum.Preparing]: {
    title: Localized.Labels.your_order_preparing,
    buttonLabel: Localized.Labels.pickup_options,
    showCode: true,
  },
  [OrderStatusEnum.Received]: {
    title: Localized.Labels.your_order_received,
    buttonLabel: Localized.Labels.view_order,
    showCode: true,
  },
  [OrderStatusEnum.Ready]: {
    title: Localized.Labels.your_order_ready,
    buttonLabel: Localized.Labels.ready_to_pick_up,
    showCode: true,
  },
  [OrderStatusEnum.Retrieved]: {
    title: Localized.Labels.your_order_retrieved,
    buttonLabel: Localized.Labels.view_order,
    showCode: false,
  },
  [OrderStatusEnum.Expired]: {
    title: Localized.Labels.your_order_expired,
    buttonLabel: Localized.Labels.view_order,
    showCode: false,
  },
};
export default (props: OrderSectionProps) => {
  const {orders} = props;

  if (!orders && orders.length < 0) {
    return null;
  }

  const items = orders.map((order) => {
    const orderState = OrderState[order.status] ?? {
      title: '',
      buttonLabel: Localized.Labels.view_order,
      showCode: false,
    };
    const orderType = OrderType[order.delivery];
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <RoundedButton
            buttonType={ButtonType.normal}
            text={orderState.buttonLabel}
            containerStyle={styles.button}
            onPress={() => props.onButtonClick(order)}
            accessible={true}
            accessibilityLabel={orderState.buttonLabel}
            accessibilityRole="button"
            role="button"
            aria-label={orderState.buttonLabel}
          />
        </View>
        <View style={styles.mainContent}>
          <View
            style={styles.logoContainer}
            aria-label={Localized.Labels.order_type_logo}
            accessibilityLabel={Localized.Labels.order_type_logo}
            accessibilityRole="image"
            role="img"
            accessible={true}
          >
            {orderType.logo()}
          </View>
          <View style={styles.content}>
            <AVText
              accessible={true}
              accessibilityLabel={orderState.title}
              accessibilityRole="text"
              aria-label={`${orderState.title}, text`}
              style={styles.title}
            >
              {orderState.title}
            </AVText>
            <View style={styles.labelContainer}>
              <AVText
                accessible={true}
                accessibilityLabel={order.name}
                accessibilityRole="text"
                aria-label={`${order.name}, text`}
                style={styles.label}
              >
                {order.name}
              </AVText>
              {orderType.showCode && orderState.showCode && (
                <AVText
                  style={styles.code}
                  accessible={true}
                  accessibilityLabel={`${
                    Localized.Labels.discount_code
                  }: ${Util.getTransactionCode(order?.transactionId)}`}
                  aria-label={`${
                    Localized.Labels.discount_code
                  }: ${Util.getTransactionCode(order?.transactionId)}`}
                >
                  {Localized.Labels.formatString(
                    Localized.Labels.enter_colon_code,
                    Util.getTransactionCode(order?.transactionId),
                  )}
                </AVText>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  });
  return <>{items}</>;
};
const styles = StyleSheet.create({
  container: {
    borderColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'column',
    paddingStart: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  header: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m2,
    justifyContent: 'flex-end',
  },
  mainContent: {
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  content: {
    flexDirection: 'column',
    marginLeft: Styles.Spacing.m2,
    flex: 1,
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  logoContainer: {
    width: Styles.Fonts.f3,
    justifyContent: 'center',
  },
  title: {
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
  code: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  label: {
    marginTop: Styles.Spacing.m1,
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  button: {
    alignSelf: 'flex-end',
  },
});
