import React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import Styles from '../Styles';
type ShadowContainerProps = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};
export default function ShadowContainer(props: ShadowContainerProps) {
  return <View style={[styles.container, props.style]}>{props.children}</View>;
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
  },
});
