import React from 'react';
import {StyleSheet} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import ScanditView from '../../elements/ScanditView';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';
type FeedbackLocationScanScreenProps = Record<string, never>;
type FeedbackLocationScanScreenState = {
  scanning: boolean;
};

class FeedbackLocationScanScreen extends React.Component<
  FeedbackLocationScanScreenProps,
  FeedbackLocationScanScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: FeedbackLocationScanScreenProps) {
    super(props);
    this.state = {
      scanning: true,
    };
    this.codeScanned = this.codeScanned.bind(this);
  }

  async codeScanned(code: string) {
    FirebaseAnalytic.trackEvent('codeScanned', 'FeedbackLocationScanScreen', {
      ...this.props,
      ...this.state,
    });

    this.setState({
      scanning: false,
    });
    this.context.actions.showSpinner();
    let response;
    try {
      response = await ActionsFactory.getAccountActions().getLocation(code);
      FirebaseAnalytic.trackEvent(
        'codeScanned getLocation',
        'FeedbackLocationScanScreen',
        {
          ...this.props,
          ...this.state,
          response,
        },
      );
      if (response.locationFeatures.showEatItOrDeleteIt) {
        // We need to call it again to associate the user with this location
        await ActionsFactory.getAccountActions().getLocation(
          code,
          AccountStore.getAccountId(),
        );
        NavActions.replace(AppRoutes.ProductFeedbackInstruction, {
          eatItUrl: AccountStore.getEatItUrl(),
        });
      } else if (response.locationId) {
        alertError(
          Localized.Errors.this_feature_is_not_available_for_this_location,
          null,
          () => {
            this.setState({
              scanning: true,
            });
          },
          Localized.Errors.eat_it_or_delete_it_not_available,
        );
      } else {
        throw new Error('Invalid location');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'FeedbackLocationScanScreen:CodeScanned',
        error.message ? error.message : error.toString(),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'FeedbackLocationScanScreen:CodeScanned',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(
        Localized.Errors.please_try_again,
        guid,
        () => {
          this.setState({
            scanning: true,
          });
        },
        Localized.Errors.error_scanning_qr_code,
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    return (
      <BackSubheader
        style={Styles.Style.subheader}
        title={Localized.Labels.eat_it_or_delete_it}
      >
        <AVText style={styles.title}>
          {Localized.Labels.scan_qr_to_go_to_eat_it_or_delete_it}
        </AVText>
        <ScanditView
          style={styles.scanditContainer}
          scanning={this.state.scanning}
          barcodeScanned={this.codeScanned}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  scanView: {
    flex: 1,
  },
  scanditContainer: {
    flex: 1,
  },
  title: {
    fontSize: Styles.Fonts.f3,
    margin: Styles.Spacing.m2,
    textAlign: 'center',
  },
});
export default withForwardedNavigationParams<FeedbackLocationScanScreenProps>()(
  FeedbackLocationScanScreen,
);
