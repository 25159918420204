import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../../ScreenContext';
import AccountHistoryList, {
  filterTypes,
} from '../../../elements/account/AccountHistoryList';
import SwitchSelector from '../../../elements/SwitchSelector';
import withIsConnected from '../../../hoc/withIsConnected';
import BaseScreen from '../../BaseScreen';
import type {IsConnectedProps} from 'src/types/Screen';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../../nativeModules/FirebaseAnalytic';
type PurchaseHistoryScreenProps = IsConnectedProps;
type PurchaseHistoryScreenState = {
  filterType: string;
};

class PurchaseHistoryScreen extends React.Component<
  PurchaseHistoryScreenProps,
  PurchaseHistoryScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: PurchaseHistoryScreenProps) {
    super(props);
    this.state = {
      filterType: filterTypes.all,
    };
  }

  render() {
    return (
      <BaseScreen
        title={Localized.Labels.history}
        rightView={
          <SwitchSelector
            onPress={(value) => {
              this.setState({
                filterType: value.toString(),
              });
              FirebaseAnalytic.trackEvent(
                'SwitchSelector',
                'PurchaseHistoryScreen',
                {
                  ...this.props,
                  ...this.state,
                },
              );
            }}
            options={[
              {
                text: Localized.Labels.all,
                label: 'All',
                value: '',
              },
              {
                text: Localized.Labels.funding,
                label: 'Funding',
                value: filterTypes.funding as string,
              },
            ]}
          />
        }
      >
        <AccountHistoryList filterType={this.state.filterType} />
      </BaseScreen>
    );
  }
}

export default withForwardedNavigationParams()(
  withIsConnected(PurchaseHistoryScreen),
);
