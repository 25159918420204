import Api, {HttpVerbs} from 'src/api/Api';
import AccountStore from 'src/stores/AccountStore';
import {CCModel} from 'src/types/CCModel';
import {UrlTypes} from 'src/types/UrlTypes';
import {Account} from 'src/types/serverTypes/Account';
import {
  AccountSendSnackInfo,
  AccountUpdateModel,
  Auth0Signup,
} from 'src/types/SetupModel';
import {GiftCardDetail} from 'src/types/GiftCardDetail';
import Util from 'src/Util';
import {VerifyPinTypes} from 'src/types/VerifyPinTypes';
import Settings from 'src/Settings';
import type {SetupModel} from 'src/types/SetupModel';

interface ISearchEGiftCardResponse {
  items: Array<GiftCardDetail>;
}
export class AccountApi {
  verifyEmail(
    email: string,
    buildType: string,
    locale: string,
    location: string,
    version: string,
    referralId: string | null | undefined,
  ) {
    const url = this.getFullUrl('/accounts');
    const body = {
      email,
      app: buildType,
      localization: {
        locale,
      },
      locationId: location,
      privacy: {
        version,
      },
      referralId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post, true);
  }

  fetchAccount(accountId: string): Promise<Account> {
    const url = this.getFullUrl(`/accounts/${accountId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchAccountTally(accountId: string): Promise<Account> {
    const url = this.getLegacyUrl(`/accounts/${accountId}`);
    return Api.fetch(url, null, HttpVerbs.Get, false);
  }

  fetchBalances(accountId: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/balances`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchTokens(accountId: string, accountBalanceId: string) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/balances/${accountBalanceId}/tokens`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  updateDefaultToken(
    accountId: string,
    accountBalanceId: string,
    balanceTokenId: string,
  ) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/balances/${accountBalanceId}/tokens/${balanceTokenId}/default`,
    );
    const body = {
      isDefault: true,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Put);
  }

  redeemRewards(
    accountId: string,
    points: number,
    transactionDate: string,
    appName: string,
  ) {
    const url = this.getFullUrl(`/actions/accounts/${accountId}/redeem-points`);
    const body = {
      appName,
      points,
      source: '365Pay',
      transactionDate,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  addMarketAccountToken(balanceId: string, ccModel: CCModel) {
    const url = this.getFullUrl(
      `/accounts/${ccModel.AccountId}/balances/${balanceId}/tokens`,
    );
    const body = {
      provider: ccModel.Provider,
      providerKey: ccModel.Token,
      cardIssuer: ccModel.Issuer,
      cardHolder: '',
      expirationMonth: ccModel.ExpirationMonth,
      expirationYear: ccModel.ExpirationYear,
      name: ccModel.CardNumberMask,
      cardBrandTransactionId: ccModel.CardBrandTransactionId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  convertLegacyBalanceAndTokens(accountId: string, useTally = false) {
    let url: string;
    if (useTally) {
      url = this.getLegacyUrl(
        `/actions/accounts/${accountId}/convert-legacy-balance-and-tokens`,
      );
      return Api.fetch(url, null, HttpVerbs.Post, false);
    } else {
      url = this.getFullUrl(
        `/actions/accounts/${accountId}/convert-legacy-balance-and-tokens`,
      );
      return Api.fetchGateway(url, null, HttpVerbs.Post);
    }
  }

  setupAutoFund(
    accountId: string,
    accountBalanceId: string,
    token: string,
    amount: number,
    fallBelowBalance: number,
  ) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/balances/${accountBalanceId}/autofund`,
    );
    const body = {
      balanceTokenId: token,
      balance: fallBelowBalance,
      amount,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Put);
  }

  updateAccount(updatedModel: AccountUpdateModel, accountId: string) {
    const url = this.getFullUrl(`/accounts/${accountId}`);
    return Api.fetchGateway(url, updatedModel, HttpVerbs.Patch);
  }

  validateEmailPin(email: string, pin: string) {
    const url = this.getFullUrl('/actions/accounts/verify-by-email', {
      email,
    });
    return Api.fetchGateway(url, {pin}, HttpVerbs.Post, true);
  }

  acceptPrivacy(accountId: string, version: string, date: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/privacy`);
    const body = {version, date};
    return Api.fetchGateway(url, body, HttpVerbs.Put);
  }

  updateSendSnackStatus(accountId: string, status: AccountSendSnackInfo) {
    const url = this.getFullUrl(`/accounts/${accountId}`);
    return Api.fetchGateway(url, status, HttpVerbs.Patch);
  }

  getHistory(accountId: string, offset: number, limit: number) {
    if (!accountId) {
      // I think it is happening here
      // No accountId returns empty history items
      return {
        items: [],
        metadata: {
          pagination: {
            limit,
            offset,
            previousOffset: null,
            nextOffset: null,
            currentPage: null,
            pageCount: 0,
            totalCount: 0,
          },
          sortedBy: [],
        },
      };
    }

    if (accountId && accountId !== '-1') {
      const url = this.getFullUrl(
        `/accounts/${accountId}/balance-adjustment-history`,
        {
          offset,
          limit,
        },
      );

      return Api.fetchGateway(url, null, HttpVerbs.Get);
    }
  }

  async setPayrollDeductOptIn(
    accountId: string,
    optIn: boolean,
    payrollDeductGroupId: string,
  ) {
    const url = this.getFullUrl(
      `/actions/accounts/${accountId}/payroll-deduct/opt-in`,
    );
    const body = {
      optIn,
      payrollDeductGroupId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  changePassword(
    accountId: string,
    password: string,
    currentPassword?: string,
  ) {
    const body = {
      password,
      currentPassword,
    };
    const url = this.getFullUrl(`/accounts/${accountId}/password`);
    return Api.fetchGateway(url, body, HttpVerbs.Patch);
  }

  tallyChangePassword(
    accountId: string,
    password: string,
    currentPassword?: string,
  ) {
    const body = {
      password,
      currentPassword,
    };
    const url = this.getLegacyUrl(`/accounts/${accountId}/password`);
    return Api.fetch(url, body, HttpVerbs.Patch, false);
  }

  async updateConsumerEngagementId(
    accountId: string,
    consumerEngagementId: string,
  ) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/consumer-engagement-id/${consumerEngagementId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Put);
  }

  getLinkedLocations(accountId: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/linked-locations`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  addLocationLink(accountId: string, locationId: string) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/linked-locations/${locationId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Put);
  }

  setupAccountAuth0(signupModel: Auth0Signup) {
    const body = {
      email: signupModel.email,
      password: signupModel.password,
      payAppType: Settings.buildType,
    };
    const url = this.getFullUrl('/actions/accounts/create-auth0-user');
    return Api.fetch(url, body, HttpVerbs.Post);
  }

  async addOrUpdateMarketCard(
    accountId: string,
    value: string,
    type: number,
    accountBalanceId = '',
  ) {
    const body = {
      value,
      type,
      usePin: false,
      accountId,
      accountBalanceId,
    };
    const url = this.getFullUrl(`/accounts/${accountId}/identifiers`);
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  transferEGiftCardBalance(params: {
    giftCardBalanceId: string;
    giftCardAccountId: string;
    amount: number;
  }) {
    const transactionId = Util.guid();
    const url = this.getFullUrl(
      `/accounts/${params.giftCardAccountId}/balances/${
        params.giftCardBalanceId
      }/transfer/${AccountStore.getAccountBalanceId()}`,
    );

    return Api.fetchGateway(
      url,
      {
        amount: params.amount,
        transactionId,
        deviceName: '365Pay',
        transactionDate: Util.getCurrentDate('YYYY-MM-DD HH:mm:ss'),
        type: 'Sale',
      },
      HttpVerbs.Post,
    );
  }

  searchEGiftCard(code: string): Promise<ISearchEGiftCardResponse> {
    const url = this.getFullUrl('/accounts', {
      isExactSearch: true,
      q: code,
      includeBalances: true,
      searchBy: 'QRCode',
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  getFullUrl(path: string, params?: any) {
    const baseUrl = Api.getGatewayUrl(UrlTypes.accountapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  getLegacyUrl(path: string, params?: any) {
    const baseUrl = Api.getTallyUrl(UrlTypes.legacyaccountapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  async changeEmail(
    accountId: string,
    email: string,
    buildType: string,
    useTally = false,
  ) {
    let url: string;
    const postObject = {
      email,
      source: buildType,
    };
    if (useTally) {
      url = this.getLegacyUrl(`/accounts/${accountId}/email-update`);
      return Api.fetch(url, postObject, HttpVerbs.Post);
    } else {
      url = this.getFullUrl(`/accounts/${accountId}/email-update`);
      return Api.fetchGateway(url, postObject, HttpVerbs.Post);
    }
  }

  async verifyPin(type: string, pin: string, value: string) {
    const url = this.getLegacyUrl('/actions/accounts/verify-by-pin', {
      type,
      value,
    });
    const body = {
      pin,
    };
    return await Api.fetch(url, body, HttpVerbs.Post);
  }

  verifyPinQR(pin: string, qrValue: string) {
    return this.verifyPin(VerifyPinTypes.QRCODE, pin, qrValue);
  }

  verifyPinScanCode(pin: string, code: string) {
    return this.verifyPin(VerifyPinTypes.SCANCODE, pin, code);
  }

  retrieveAutoFund(accountId: string, accountBalanceId: string) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/balances/${accountBalanceId}/autofund`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }
  setPin(accountId: string, pin: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/pin`);
    const postObject = {
      pin,
    };
    return Api.fetchGateway(url, postObject, HttpVerbs.Patch);
  }
  async deleteAssociatedDiscountCode(accountId: string, identifierId: string) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/identifiers/${identifierId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Delete);
  }
  deviceLookUp(locationId: string, query: string) {
    const url = this.getFullUrl('/devices/lookup-account', {
      locationId,
      deviceId: '365Pay',
      query,
      searchBy: 'AccountId',
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }
  beaconLookUp(scannedId: string, gmaId: string) {
    const url = this.getFullUrl('/devices/beacon-lookup', {
      broadcastId: scannedId,
      accountId: gmaId,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  setupAccountPayrollImport(setupModel: SetupModel) {
    const url = this.getLegacyUrl('/accounts');
    return Api.fetch(url, setupModel, HttpVerbs.Post);
  }

  getCurrentPrivacyVersion(accountId: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/privacy`);
    return Api.fetchGateway(url, null, 'GET');
  }

  getAccountIdentifier(accountId: string, type: number) {
    const url = this.getFullUrl(`/accounts/${accountId}/identifiers`, {
      accountId,
      type,
    });
    return Api.fetchGateway(url, null, 'GET');
  }
}
export default new AccountApi();
