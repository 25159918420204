import React from 'react';
import ReactPlayer from 'react-player';
type VideoPlayerProps = {
  source: {
    uri: string;
  };
  style: any;
  poster: string;
  paused: boolean;
  onEnd: () => void;
  onProgress: () => void;
  onLoad: (payload: {duration: number}) => void;
  controls: boolean;
};

function reduceStyle(style: any): any {
  if (Array.isArray(style)) {
    return style.reduce(
      (nextStyle, prevStyle) => ({...prevStyle, ...nextStyle}),
      {},
    );
  }

  return style;
}

export default class VideoPlayer extends React.Component<VideoPlayerProps> {
  player: any;

  seek(seconds: number) {
    this.player.seekTo(seconds, 'seconds');
  }

  ref = (player: any) => {
    this.player = player;
  };
  onDuration = (duration: number) => {
    this.props.onLoad({
      duration,
    });
  };

  render() {
    const props = this.props;
    const style = reduceStyle(props.style);
    return (
      <ReactPlayer
        ref={this.ref}
        url={props.source.uri}
        width={style.width}
        height={style.height}
        playing={!props.paused}
        onEnded={props.onEnd}
        onSeek={props.onProgress}
        onDuration={this.onDuration}
        style={style}
      />
    );
  }
}
