import * as React from 'react';
import Berry from '../../img/svg/sendSnackStickers/Berry';
import Cheetos from '../../img/svg/sendSnackStickers/Cheetos';
import Coke from '../../img/svg/sendSnackStickers/Coke';
import Doritos from '../../img/svg/sendSnackStickers/Doritos';
import MountainDew from '../../img/svg/sendSnackStickers/MountainDew';
import SevenUp from '../../img/svg/sendSnackStickers/SevenUp';
import StickerId from 'src/constants/sendSnack/StickerId';
type StickerComponentProps = {
  id: number;
  imageSize: number;
};
export default function StickerComponent(props: StickerComponentProps) {
  const {id, imageSize} = props;

  switch (id) {
    case StickerId.Berry:
      return <Berry size={imageSize} />;

    case StickerId.Cheetos:
      return <Cheetos size={imageSize} />;

    case StickerId.Coke:
      return <Coke size={imageSize} />;

    case StickerId.Doritos:
      return <Doritos size={imageSize} />;

    case StickerId.MountainDew:
      return <MountainDew size={imageSize} />;

    case StickerId.SevenUp:
      return <SevenUp size={imageSize} />;

    default:
      return null;
  }
}
