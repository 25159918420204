import {
  Balance as ServerBalance,
  BalanceTypes,
} from 'src/types/serverTypes/Account';

export interface Balance {
  id: string;
  currency: string;
  type: BalanceTypes;
  isDefault: boolean;
  isSubsidy: boolean;
  balance: number;
  isDisabled: boolean;
}
export default class BalanceBuilder {
  static build(serverBalance: ServerBalance): Balance {
    const type = serverBalance.type as BalanceTypes;
    const isDefault =
      type === BalanceTypes.Default ||
      type === BalanceTypes.Stripe ||
      type === BalanceTypes.USConnect;

    const isSubsidy =
      type === BalanceTypes.RollOver || type === BalanceTypes.TopOff;

    return {
      balance: serverBalance.balance,
      currency: serverBalance.currency,
      type,
      id: serverBalance.accountBalanceId,
      isDefault,
      isSubsidy,
      isDisabled: serverBalance.isDisabled,
    };
  }
}
