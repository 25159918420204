import {NativeModules} from 'react-native';
import {encodeValue} from './bluetooth/Encoding';
import KeyService from './VESharedKeyService';
const encryption = NativeModules.EncryptionModule;
const ivHex = '00000000000000000000000000000000';
export async function encrypt(
  textHex: string,
  publicKeyHex: string,
): Promise<string> {
  const keyHex = await KeyService.getSharedKey(publicKeyHex);
  const keyBase64 = encodeValue(keyHex, 'hex', 'base64');
  const ivBase64 = encodeValue(ivHex, 'hex', 'base64');
  const textBase64 = encodeValue(textHex, 'hex', 'base64');
  const base64Encrypt = await encryption.encrypt(
    textBase64,
    keyBase64,
    ivBase64,
  );
  return encodeValue(base64Encrypt, 'base64', 'hex');
}
export async function decrypt(
  cipherHex: string,
  publicKeyHex: string,
): Promise<string> {
  const keyHex = await KeyService.getSharedKey(publicKeyHex);
  const keyBase64 = encodeValue(keyHex, 'hex', 'base64');
  const ivBase64 = encodeValue(ivHex, 'hex', 'base64');
  const cipherBase64 = encodeValue(cipherHex, 'hex', 'base64');
  const base64Result = await encryption.decrypt(
    cipherBase64,
    keyBase64,
    ivBase64,
  );
  return encodeValue(base64Result, 'base64', 'hex');
}
