import React from 'react';
import Svg, {G, Circle, Path} from 'react-native-svg';

type DeleteXProps = {
  color?: string;
};

const DeleteX = (props: DeleteXProps) => {
  const color = props.color ? props.color : '#244EC2';
  return (
    <Svg width={'32'} height={'32'} viewBox={'0 0 32 32'}>
      <G fill="none" fillRule="evenodd">
        <Circle fill={color} cx={16} cy={16} r={16} />
        <Path
          d="m21.847 9.333-5.86 5.854-5.834-5.826-.82.82L15.167 16l-5.834 5.826.82.813 5.833-5.82 5.861 5.848.82-.813L16.806 16l5.86-5.847z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
};

export default DeleteX;
