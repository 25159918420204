enum MachineConstants {
  MACHINE_ADDED = 'MACHINE_ADDED',
  MACHINE_UPDATED = 'MACHINE_UPDATED',
  CLEAR_MACHINES = 'CLEAR_MACHINES',
  DEVICES_LOADED_SUCCESS = 'DEVICES_LOADED_SUCCESS',
  DEVICES_LOADED_FAIL = 'DEVICES_LOADED_FAIL',
  DEVICE_ADDED = 'DEVICE_ADDED',
  CLEAR_DEVICES = 'CLEAR_DEVICES',
  LOAD_UNATTACHED_MACHINES_SUCCESS = 'LOAD_UNATTACHED_MACHINES_SUCCESS',
  LOAD_UNATTACHED_MACHINES_ERROR = 'LOAD_UNATTACHED_MACHINES_ERROR',
  MACHINE_ATTACHED = 'MACHINE_ATTACHED',
  MACHINE_ATTACHED_FAILURE = 'MACHINE_ATTACHED_FAILURE',
  SERVICE_COMPLETE = 'SERVICE_COMPLETE',
  SERVICE_COMPLETE_ERROR = 'SERVICE_COMPLETE_ERROR',
  UPLOAD_DEX_FILES = 'UPLOAD_DEX_FILES',
  UPLOAD_DEX_FILES_ERROR = 'UPLOAD_DEX_FILES_ERROR',
  SETTINGS_UPDATED = 'SETTINGS_UPDATED',
  SETTINGS_UPDATED_FAILED = 'SETTINGS_UPDATED_FAILED',
  ERROR_CONNECTING = 'ERROR_CONNECTING',
  UPLOAD_SETUP = 'UPLOAD_SETUP',
  UPLOAD_SETUP_ERROR = 'UPLOAD_SETUP_ERROR',
  MACHINE_REBOOTING = 'MACHINE_REBOOTING',
  WIFI_UPDATED = 'WIFI_UPDATED',
  DEVICE_UNATTACHED = 'DEVICE_UNATTACHED',
  DISCONNECT_FROM_MACHINE = 'DISCONNECT_FROM_MACHINE',
  DEX_DATA_RECEIVED = 'DEX_DATA_RECEIVED',
  DEX_FAILED = 'DEX_FAILED',
  MARKET_SCANNED = 'MARKET_SCANNED',
  MARKET_UPDATED = 'MARKET_UPDATED',
  MARKET_UPDATED_LEGACY = 'MARKET_UPDATED_LEGACY',
  CLEAR_MARKETS = 'CLEAR_MARKETS',
  VMC_LOG_RECEIVED = 'VMC_LOG_RECEIVED',
  SERVICE_MODE_CHANGED = 'SERVICE_MODE_CHANGED',
  MACHINE_CONNECTED = 'MACHINE_CONNECTED',
  MACHINE_MODELS_FETCHED = 'MACHINE_MODELS_FETCHED',
  CREATE_MACHINE_RESPONSE = 'CREATE_MACHINE_RESPONSE',
  BEACON_UPDATED = 'BEACON_UPDATED',
  CASHLESS_DEVICE_UPDATED = 'CASHLESS_DEVICE_UPDATED',
  DEVICE_UPDATED = 'DEVICE_UPDATED',
  MACHINE_IMAGE_UPDATED = 'MACHINE_IMAGE_UPDATED',
  CHECK_VERSION = 'CHECK_VERSION',
}

export default MachineConstants;
