import AVLiveApi from 'src/api/AVLiveApi';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import AccountStore from 'src/stores/AccountStore';
import PlanogramConstants from 'src/constants/PlanogramConstants';

class PlanogramActions {
  async loadPlanogram(deviceId: string) {
    try {
      let response;

      if (AccountStore.isDemo()) {
        response = require('../demo/FetchPlanogram.json');
      } else {
        response = await AVLiveApi.fetchPlanogram(deviceId);
      }

      AppDispatcher.handleViewAction({
        actionType: PlanogramConstants.FETCH_PLANOGRAM_SUCCESS,
        data: {
          deviceId: deviceId,
          response: response,
        },
      });
      return response;
    } catch (error) {
      AppDispatcher.handleViewAction({
        actionType: PlanogramConstants.FETCH_PLANOGRAM_ERROR,
        data: {
          deviceId: deviceId,
          error: error,
        },
      });
      return error;
    }
  }

  async requestCompanyProducts(deviceId: string) {
    let response;

    if (AccountStore.isDemo()) {
      response = require('../demo/GetCompanyProducts.json');
    } else {
      response = await AVLiveApi.getCompanyProducts(deviceId);
    }

    AppDispatcher.handleViewAction({
      actionType: PlanogramConstants.COMPANY_PRODUCTS_FETCHED,
      data: {
        response,
        deviceId,
      },
    });
    return {
      response,
      deviceId,
    };
  }

  uploadInventory(
    deviceId: string,
    userId: string,
    planogramId: string,
    productLocations: any,
    date: string,
  ) {
    return AVLiveApi.updateProducts(
      deviceId,
      userId,
      planogramId,
      productLocations,
      date,
    );
  }

  filterEntered(filter: string) {
    AppDispatcher.handleViewAction({
      actionType: PlanogramConstants.FILTER_UPDATED,
      data: {
        filter,
      },
    });
  }

  tagQueryEntered(query: string) {
    AppDispatcher.handleViewAction({
      actionType: PlanogramConstants.TAG_FILTER_UPDATED,
      data: {
        query,
      },
    });
  }

  tagSelected(tag: string) {
    AppDispatcher.handleViewAction({
      actionType: PlanogramConstants.TAG_SELECTED,
      data: {
        tag,
      },
    });
  }
}

export default new PlanogramActions();
