import React from 'react';
import {Text, TextProps} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import Styles from 'src/components/Styles';

type AVFormattedCurrencyProps = TextProps & {
  accessibilityLabel: string;
  prependedString?: string;
  maxFontSizeMultiplier?: number;
  value: number;
  currency: string;
};

const AVFormattedCurrency = (props: AVFormattedCurrencyProps) => {
  const {formatCurrency} = useGlobalize();

  if (typeof props.value === 'number') {
    return (
      <Text
        maxFontSizeMultiplier={
          props.maxFontSizeMultiplier ?? Styles.FontSizeMultiplier.maxfm11
        }
        nativeID={props.accessibilityLabel}
        {...props}
      >
        {formatCurrency(props.value, props.currency)}
      </Text>
    );
  } else {
    return <Text {...props} />;
  }
};

export default AVFormattedCurrency;
