enum PlanogramConstants {
  FETCH_PLANOGRAM_SUCCESS = 'FETCH_PLANOGRAM_SUCCESS',
  FETCH_PLANOGRAM_ERROR = 'FETCH_PLANOGRAM_ERROR',
  COMPANY_PRODUCTS_FETCHED = 'COMPANY_PRODUCTS_FETCHED',
  FILTER_UPDATED = 'FILTER_UPDATED',
  TAG_SELECTED = 'TAG_SELECTED',
  TAG_FILTER_UPDATED = 'TAG_FILTER_UPDATED',
}

export default PlanogramConstants;
