import React from 'react';
import {StyleSheet, View, Image} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import BaseScreen from '../BaseScreen';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import UUIDGenerator from 'react-native-uuid-generator';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ProductFeedbackInstructionProps = {
  eatItUrl: string;
};

class ProductFeedbackInstruction extends React.Component<ProductFeedbackInstructionProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ProductFeedbackInstructionProps) {
    super(props);
  }

  getEatItLocation = async () => {
    let eatItLocation = '';
    let response;
    try {
      response = await ActionsFactory.getAccountActions().retrieveEatItLocation(
        this.props.eatItUrl,
        AccountStore.getOrgUserKey(),
      );
      FirebaseAnalytic.trackEvent(
        'getEatItLocation',
        'ProductFeedbackInstructionScreen',
        {
          ...this.props,
          response,
        },
      );
      if (response.locations && response.locations.length > 0) {
        const location = response.locations[0];
        eatItLocation = location.id;
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductFeedbackInstructionScreen:GetEatItLocation',
        error.message ? error.message : error.toString(),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductFeedbackInstructionScreen:GetEatItLocation',
        error.message ? error.message : error.toString(),
      );
    }

    return eatItLocation;
  };
  getUniqueDeviceId = async () => {
    let id = '';

    try {
      id = await UUIDGenerator.getRandomUUID();
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductFeedbackInstructionScreen:GetUniqueDeviceId',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductFeedbackInstructionScreen:GetUniqueDeviceId',
        error.message ? error.message : error.toString(),
      );
    }

    return id;
  };
  startSwipingClicked = async () => {
    this.context.actions.showSpinner();
    const id = await this.getUniqueDeviceId();
    const eatItLocation = await this.getEatItLocation();
    this.context.actions.hideSpinner();
    FirebaseAnalytic.trackEvent(
      'startSwipingClicked',
      'ProductFeedbackInstructionScreen',
      {
        ...this.props,
        id,
        eatItLocation,
      },
    );
    if (eatItLocation) {
      NavActions.replace(AppRoutes.ProductFeedback, {
        eatItLocation,
        uniqueId: id,
        eatItUrl: this.props.eatItUrl,
      });
    } else {
      alertError(Localized.Errors.eat_it_or_delete_it_not_available);
    }
  };
  renderInstructionView = (require: any, label: string) => {
    return (
      <View style={styles.instructionView}>
        {this.renderImage(require)}
        <AVText style={styles.instruction}>{label}</AVText>
      </View>
    );
  };
  renderInstructionRow = () => {
    return (
      <View style={styles.instructionRow}>
        {this.renderInstructionView(
          require('../../img/product-feedback/swipeleft.png'),
          Localized.Labels.swipe_left_to_dislike,
        )}
        {this.renderInstructionView(
          require('../../img/product-feedback/swipeup.png'),
          Localized.Labels.swipe_up_to_skip,
        )}
        {this.renderInstructionView(
          require('../../img/product-feedback/swiperight.png'),
          Localized.Labels.swipe_right_to_like,
        )}
      </View>
    );
  };
  renderImage = (require: any) => {
    return <Image style={styles.image} source={require} />;
  };
  renderImageRow = () => {
    return (
      <View style={styles.imageRow}>
        {this.renderImage(
          require('../../img/product-feedback/instructions-left.png'),
        )}
        {this.renderImage(
          require('../../img/product-feedback/instructions-center.png'),
        )}
        {this.renderImage(
          require('../../img/product-feedback/instructions-right.png'),
        )}
      </View>
    );
  };

  render() {
    return (
      <BaseScreen title={Localized.Labels.eat_it_or_delete_it}>
        <View style={styles.content}>
          {this.renderImageRow()}
          {this.renderInstructionRow()}
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.startSwipingClicked}
          accessibilityLabel="StartSwiping"
          text={Localized.Buttons.start_swiping}
        />
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    margin: Styles.Spacing.m3,
  },
  image: {
    flex: 1,
    height: undefined,
    resizeMode: 'contain',
    width: undefined,
  },
  imageRow: {
    flex: 0.3,
    flexDirection: 'row',
  },
  instruction: {
    flex: 0.6,
    textAlign: 'center',
  },
  instructionRow: {
    flex: 0.3,
    flexDirection: 'row',
    marginVertical: Styles.Spacing.m2,
  },
  instructionView: {
    flex: 1,
    marginHorizontal: 5,
  },
  override: {
    marginTop: Styles.Spacing.m1,
  },
});
export default withForwardedNavigationParams<ProductFeedbackInstructionProps>()(
  ProductFeedbackInstruction,
);
