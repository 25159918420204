import moment, {DurationInputArg1} from 'moment';
import _ from 'lodash';
import {CreditCard} from './models/CreditCard';
import Settings from './Settings';
import {Platform} from 'react-native';

type FormatOptionsType = {
  maximumFractionDigits: number;
  minimumFractionDigits: number;
};
type DateTimeObj = {
  date: string;
  time: string;
};
export function delay(interval: number) {
  return new Promise<void>((fulfill: any) => {
    setTimeout(fulfill, interval);
  });
}
export async function delayForUX(started: any) {
  if (started) {
    const duration = moment().diff(started || moment(), 'ms');

    if (duration < 2000) {
      await delay(2000 - duration);
    }
  }
}
export function numberWithCommas(x: number) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
export function formatEpochDate(epoch: number) {
  const options: any = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };
  return new Date(epoch).toLocaleDateString([], options);
}
export function formatEpochDateTime(epoch: number) {
  const options: any = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(epoch).toLocaleDateString([], options);
}
export function retrieveDate(
  subtractDuration: DurationInputArg1,
  formatString = 'YYYY-MM-DD',
) {
  return moment().subtract(subtractDuration).format(formatString);
}
export function getCurrentDate(format = 'YYYY-MM-DD HH:mm:ss') {
  return moment().format(format);
}
export function getCurrentTimeMillis() {
  return moment().valueOf();
}

export function calculateDaysInMillis(days: number) {
  const now = moment().add(days, 'd').valueOf();
  return now;
}

export function getCurrentUTCDate(format = 'YYYY-MM-DD HH:mm:ss') {
  const localDate = moment().format(format);
  const utcDate = moment(localDate, format).utc();
  return utcDate.format(format);
}
export function formatDate(
  date: Date | string | number,
  format = 'YYYY-MM-DD HH:mm',
) {
  return moment(date).format(format);
}
export function getDurationFromDate(date: string) {
  const now = moment();
  const sentDate = moment(date);
  const years = now.diff(sentDate, 'years');

  if (years > 0) {
    return years + 'y';
  }

  const days = now.subtract(years, 'years').diff(sentDate, 'days');

  if (days > 0) {
    return days + 'd';
  }

  const hours = now.subtract(days, 'days').diff(sentDate, 'hours');

  if (hours > 0) {
    return hours + 'h';
  }

  const minutes = now.subtract(hours, 'hours').diff(sentDate, 'minutes');

  if (minutes > 0) {
    return minutes + 'm';
  }

  return '1m';
}

export function isDatePastNinetyDays(date: string) {
  const ninetyDaysAgo = moment().subtract({
    days: 90,
  });
  return moment(date).isBefore(ninetyDaysAgo);
}

export function guid() {
  let d = new Date().getTime();

  if (
    window &&
    window.performance &&
    typeof window.performance.now === 'function'
  ) {
    d += window.performance.now(); // use high-precision timer if available
  }

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
export function formatPercent(numerator: number, denominator: number) {
  const percent = Math.floor((numerator / denominator) * 100);
  return `${percent}%`;
}
export function formatNumber(
  context: any,
  value: number,
  options: FormatOptionsType = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
): string {
  if (!context || !context.globalize) {
    return value.toString();
  }

  const formatter = context.globalize.getNumberFormatter(options);
  return typeof value === 'number' ? formatter(value) : '';
}
export function formatCurrency(
  context: any,
  value: number,
  currency: string,
  options: FormatOptionsType = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
): string {
  if (!context || !context.globalize) {
    return '$' + value.toFixed(2).toString();
  }

  const currencyFormatter = context.globalize.getCurrencyFormatter(
    currency,
    options,
  );
  return currencyFormatter(
    typeof value === 'string' ? parseFloat(value) : value,
  );
}
export function onlyNumbers(text: string) {
  return text.replace(/\D/g, '');
}
export function isOnlyNumbers(text: string) {
  return /^\d+$/.test(text);
}
export function hashCode(toHash: string) {
  let hash = 0;

  if (toHash.length === 0) {
    return hash;
  }

  for (let i = 0; i < toHash.length; i++) {
    const char = toHash.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}
export function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}
export function parseDateTime(datetime: string): DateTimeObj {
  return {
    date: datetime.substring(0, 10),
    time: datetime.substring(11),
  };
}
export function getLast4(ccNumber: string): string {
  if (ccNumber && ccNumber.length > 4) {
    return ccNumber.substring(ccNumber.length - 4);
  }

  return ccNumber;
}
export function changeExtension(fileName: string, extension: string): string {
  const pos = fileName.lastIndexOf('.');
  return `${fileName.substr(0, pos < 0 ? fileName.length : pos)}.${extension}`;
}
export function getLocationAddress(location: any) {
  let address = '';

  if (location.address) {
    address = location.address;
  }

  if (location.city) {
    if (address) {
      address += ', ';
    }

    address += location.city;
  }

  if (location.state) {
    if (address) {
      address += ', ';
    }

    address += location.state;
  }

  return address;
}
export function parseUrlParams(url: string): Record<string, string> {
  const params: Record<string, string> = {};

  if (url) {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    let match = regex.exec(url);

    while (match) {
      params[match[1]] = match[2];
      match = regex.exec(url);
    }
  }

  return params;
}
export function getTransactionCode(transactionId: string) {
  return transactionId?.slice(-6) ?? '';
}
export function getAmountInCents(amount: number): number {
  return Math.round((amount + Number.EPSILON) * 100);
}
export function roundNumber(amount: number, numDigits: number): number {
  const adjustment1: any = 'e+' + numDigits;
  const adjustment2 = 'e-' + numDigits;
  return +(Math.round(amount + adjustment1) + adjustment2);
}
export function isCollectionEqual(x: any, y: any) {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
}

// checks if val is null, undefined, empty or not a number
export function checkValue(val) {
  let canBeUsed = true;
  if (val === null || val === undefined || val === '' || isNaN(val)) {
    canBeUsed = false;
  }
  return canBeUsed;
}

// Checks the navigation state to determine the screen the user has come from
export function getPreviousRouteName(
  routes: Array<{key: string; name: string; params?: unknown; path?: string}>,
): string {
  if (routes === null || routes === undefined || routes.length === 0) {
    return '';
  } else if (routes.length === 1) {
    return '';
  }
  const previousRoute = routes[routes.length - 2];
  return previousRoute.name;
}

export function addLeadingZero(value: string, length: number): string {
  return value.padStart(length, '0');
}

export function filterExpiredCreditCards(
  creditCards: Array<CreditCard>,
): Array<CreditCard> {
  const currentMonth = moment().format('MM');
  const currentYear = moment().format('YYYY');
  const creditCardsFiltered = creditCards.filter(
    (c) =>
      c.expirationYear > +currentYear ||
      (c.expirationYear === +currentYear && c.expirationMonth >= +currentMonth),
  );
  return creditCardsFiltered;
}

export function getAppOsName(): string {
  return Settings.appDisplayName + '-' + Platform.OS;
}

export default {
  delay,
  numberWithCommas,
  formatEpochDate,
  formatEpochDateTime,
  retrieveDate,
  getCurrentDate,
  calculateDaysInMillis,
  getCurrentTimeMillis,
  getCurrentUTCDate,
  formatDate,
  guid,
  formatPercent,
  formatNumber,
  formatCurrency,
  onlyNumbers,
  isOnlyNumbers,
  hashCode,
  random,
  parseDateTime,
  getLast4,
  changeExtension,
  getLocationAddress,
  parseUrlParams,
  getTransactionCode,
  getDurationFromDate,
  isDatePastNinetyDays,
  delayForUX,
  getAmountInCents,
  roundNumber,
  isCollectionEqual,
  checkValue,
  getPreviousRouteName,
  addLeadingZero,
  filterExpiredCreditCards,
  getAppOsName,
};
