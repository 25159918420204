import React from 'react';
import {View, TouchableWithoutFeedback, StyleSheet} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import AVText from './AVText';
import type {MachineType} from 'src/types/MachineType';
import {isDisabled, getDisabledReason} from './MarketCell';
import {WithLocalizedProps} from 'src/types/PropTypes';

type CoffeeMachineCellProps = WithLocalizedProps & {
  machine: MachineType;
  machineSelect: (machine: any) => void;
};

const CoffeeMachineCell = (props: CoffeeMachineCellProps) => {
  const image = (
    <View style={[styles.emptyImage]}>
      <FontAwesome5Pro
        name="mug-hot"
        color={Styles.primaryColor}
        size={Styles.Fonts.f4}
        solid
      />
    </View>
  );
  const disabled = isDisabled(props.machine);
  const disabledReason = getDisabledReason(
    disabled,
    props.machine,
    props.strings,
  );
  const label = <AVText>{props.strings.Labels.coffee_machine}</AVText>;
  const mainContent = (
    <TouchableWithoutFeedback
      style={[Styles.Style.machineName]}
      onPress={props.machineSelect}
      accessibilityLabel={props.strings.Labels.coffee_machine}
      //@ts-ignore
      nativeID={props.strings.Labels.coffee_machine}
      disabled={disabled}
    >
      <View style={Styles.Style.marketContent}>
        {image}
        <View style={Styles.Style.machineTextContainer}>
          <AVText numberOfLines={1} style={Styles.Style.machineText}>
            {props.machine.name}
          </AVText>
          {label}
          {disabledReason}
        </View>
        <FontAwesome5Pro
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </View>
    </TouchableWithoutFeedback>
  );
  return (
    <View
      style={[Styles.Style.standalone, disabled && Styles.Style.disabledMarket]}
    >
      {mainContent}
    </View>
  );
};

const styles = StyleSheet.create({
  emptyImage: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m3,
    width: Styles.Spacing.m5,
  },
});
export default CoffeeMachineCell;
