import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import CreatePasswordScanScreen from '../../elements/createAccount/CreatePasswordScan';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import ActionsFactory from 'src/actions/ActionsFactory';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Localized from 'src/constants/AppStrings';
import {Platform, StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import BackSubheader from '../../elements/BackSubheader';
import {alertError} from '../../helpers/AlertHelper';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ScanMarketCardProps = {
  importId?: string;
  importLocationId?: string;
  navigation?: NavigationProp<ScanMarketCard>;
};
type ScanMarketCardState = {
  scanning: boolean;
  code: string;
  previousRoute: string | null;
};

class ScanMarketCard extends React.Component<
  ScanMarketCardProps,
  ScanMarketCardState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ScanMarketCardProps) {
    super(props);
    this.state = {
      scanning: true,
      code: '',
      previousRoute: null,
    };
    this.cardScanned = this.cardScanned.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState()?.routes,
    );

    this.setState({previousRoute: previousRoute});
  }

  async cardScanned(code: string) {
    this.setState({
      scanning: false,
    });

    try {
      const response =
        await ActionsFactory.getAccountActions().loadAccountByMarketCard(code);

      if (response.status === 'ok') {
        FirebaseAnalytic.trackEvent('CardScan', 'ScanMarketCardScreen', {
          ...this.props,
          ...this.state,
        });
        NavActions.replace(AppRoutes.EnterPin, {
          data: {...response.data},
          code,
          ...this.props,
        });
      } else {
        throw new Error('invalid scan code');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      const displayError =
        Settings.buildType === BuildTypes.canteen
          ? Localized.Errors.connectandpay_not_recognized
          : Localized.Errors.code_not_recognized;
      alertError(displayError, guid);
      this.setState({
        scanning: true,
      });
      CrashlyticsEvents.log(
        'Exception',
        'ScanMarketCardScreen:CardScanned',
        error.message ? error.message : error.toString(),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanMarketCardScreen:CardScanned',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  render() {
    return Platform.select({
      web: (
        <BackSubheader
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={Localized.Labels.enter_market_card}
        >
          <View style={Styles.Style.maxWidthContainer}>
            <AllyTextInput
              label={Localized.Labels.market_card}
              value={this.state.code}
              accessible={true}
              accessibilityLabel={Localized.Labels.market_card}
              accessibilityValue={{text: this.state.code}}
              onChangeText={(text) => this.setState({code: text})}
            />
          </View>
          <RoundedButton
            buttonType={ButtonType.action}
            accessibilityLabel="Done"
            onPress={() => this.cardScanned(this.state.code)}
            text={Localized.Buttons.done}
          />
        </BackSubheader>
      ),
      default: (
        <CreatePasswordScanScreen
          label={
            Settings.buildType === BuildTypes.canteen
              ? Localized.Labels.scan_your_connectandpay_card
              : Localized.Labels.scan_your_market_card
          }
          barcodeScanned={this.cardScanned}
          scanning={this.state.scanning}
          strings={Localized}
          previousRoute={this.state.previousRoute}
        />
      ),
    });
  }
}

const styles = StyleSheet.create({
  marketCard: {
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<ScanMarketCardProps>()(
  ScanMarketCard,
);
