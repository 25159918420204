import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import Styles from '../../Styles';

import {WithLocalizedProps} from 'src/types/PropTypes';
import AllyTextInput from 'src/components/elements/AllyTextInput';

type SearchFieldProps = WithLocalizedProps & {
  onChangeText: (text: string) => void;
  value: string;
  currentCategory?: string;
};

const SearchField = (props: SearchFieldProps) => {
  const [placeholder, setPlaceholder] = useState(
    `${props.strings.Labels.search}...`,
  );
  return (
    <View style={styles.searchFieldContainer}>
      <AllyTextInput
        accessible={true}
        accessibilityLabel={`Search for products under the "${props.currentCategory}" category`}
        accessibilityValue={{text: props.value}}
        accessibilityRole="search"
        label={`${props.strings.Labels.search}...`}
        value={props.value}
        onFocus={() => setPlaceholder(`${props.strings.Labels.search}...`)}
        onBlur={() => setPlaceholder('')}
        clearButtonMode="while-editing"
        onChangeText={props.onChangeText}
        autoCapitalize="none"
        placeholder={placeholder}
        hasIcon={true}
        iconName="search"
        iconLabel="Search icon"
        iconPressed={null}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchFieldContainer: {
    backgroundColor: Styles.white,
    paddingTop: Styles.Spacing.m2,
    paddingBottom: Styles.Spacing.m3,
    paddingHorizontal: 8,
  },
  searchText: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginLeft: Styles.Spacing.m2,
  },
});

export default SearchField;
