import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import HttpClient from 'src/api/HttpClient';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import {BlockedUser} from 'src/types/Snack';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export const getSnackBlockedUsers = createAsyncThunk<
  BlockedUser[],
  never,
  {dispatch: AppDispatch; state: RootState}
>('snack/getSnackBlockedUsers', async (_, {dispatch, getState}) => {
  dispatch(showSpinner());
  try {
    const accountId = getState().account.account.id;
    return await PlatformApi.getSnackBlockedUsers(accountId);
  } catch (err) {
    const errorCode = HttpClient.parseJsonSafe(
      err?.statusCode ?? err.statusCode,
    );

    if (errorCode === 404) {
      alertError(Localized.Errors.error_fetching_blocked_users);
    } else {
      CrashlyticsEvents.log(
        'Exception',
        'snack/getSnackBlockedUsers',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'snack/getSnackBlockedUsers',
        err.message ? err.message : err.toString(),
      );
    }
  } finally {
    dispatch(hideSpinner());
  }
});
