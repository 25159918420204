import * as React from 'react';
import {
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  PixelRatio,
} from 'react-native';
import type {MoblicoPromotionType} from 'src/types/Promotions';
import Styles from '../../Styles';
import Image from 'react-native-scalable-image';
import {handleClick} from './MoblicoPromotionClickHandler';
import {ensureHttps} from 'src/services/StringUtils';

type MoblicoPromotionProps = {
  promotion: MoblicoPromotionType;
  isConnected: boolean;
  width: number;
  index: number;
};

function MoblicoPromotion(props: MoblicoPromotionProps) {
  const onPress = () => {
    handleClick(props.promotion);
  };

  // force HTTPS
  const uri = ensureHttps(props.promotion.image.url);
  return (
    <TouchableWithoutFeedback onPress={onPress} disabled={!props.isConnected}>
      <View
        style={styles.container}
        accessibilityLabel={`Promotion${props.index}`}
        nativeID={props.promotion.caption}
      >
        <Image
          source={{
            uri,
          }}
          style={styles.image}
          width={props.width}
        />
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    flex: 1,
    shadowColor: Styles.darkColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.4,
    shadowRadius: Styles.Spacing.m2,
  },
  image: {
    resizeMode: 'contain',
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
  },
});
export default MoblicoPromotion;
