import React from 'react';
import {StyleSheet, View, PixelRatio, TouchableOpacity} from 'react-native';
import type {BundleType} from 'src/types/TransactionDetail';
import AVText from '../AVText';
import Icon from '../../icons/FontAwesomeIcon';
import AVFormattedCurrency from '../AVFormattedCurrency';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import SwipeableRow from '../DeleteSwipeableRow';
import {CartItemModifier} from 'src/types/Cart';
import {confirm} from '../../helpers/AlertHelper';
import AccountStore from 'src/stores/AccountStore';
import SupLeafIcon from 'src/components/img/svg/SupLeafIcon';
type CartBundleProps = {
  trashClick?: (lineNumbers: Array<number>) => void;
  bundle: BundleType;
};

const CartBundle = (props: CartBundleProps): JSX.Element => {
  const trashClick = (lineNumbers: Array<number>) => {
    if (props.trashClick) {
      confirm(
        Localized.Labels.are_you_sure_delete_item,
        () => {
          props.trashClick(lineNumbers);
        },
        undefined,
        Localized.Buttons.delete_item,
        Localized.Buttons.cancel,
        Localized.Buttons.ok,
      );
    }
  };

  const totalDiscount = props.bundle.items.reduce((prev, cur) => {
    if (cur.Discount) {
      return prev + parseFloat(cur.Discount) * 1;
    } else {
      return prev;
    }
  }, 0);

  const totalPrice = props.bundle.items.reduce((prev, cur) => {
    const price = parseFloat(cur.Price ?? '');
    let modifierPrice = 0;

    if (cur.Modifiers) {
      modifierPrice = cur.Modifiers.reduce(
        (prevModifierPrice: number, modifier: CartItemModifier) => {
          if (modifier.Price) {
            return prevModifierPrice + parseFloat(modifier.Price.toString());
          } else {
            return prevModifierPrice;
          }
        },
        0,
      );
    }

    return prev + price + modifierPrice;
  }, 0);

  const discountRow = (
    <View style={[styles.discountRow]}>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        style={styles.discountText}
      >
        {Localized.Labels.discount} (
        <AVFormattedCurrency
          accessible={true}
          accessibilityLabel={`${Localized.Labels.discount} ${totalDiscount}`}
          aria-label={`${Localized.Labels.discount} ${totalDiscount}`}
          value={totalDiscount}
          currency={AccountStore.getCurrency()}
        />
        )
      </AVText>
    </View>
  );

  const items = props.bundle.items.map((item) => {
    const icon = (
      <AVText>
        <Icon name="times" size={Styles.Fonts.f1} style={styles.icon} />
        {` ${item.Description || ''}`}
      </AVText>
    );
    return (
      <>
        <TouchableOpacity
          accessible={true}
          accessibilityLabel="Delete product"
          accessibilityRole="button"
          accessibilityHint={`Double tap to delete ${
            item?.Name ?? ' this item '
          } from your cart`}
          role="button"
          aria-label="Delete product"
          style={styles.categoryButton}
          onPress={() => trashClick([item.LineNumber || -1])}
        >
          {icon}
        </TouchableOpacity>
      </>
    );
  });

  const totalSupFee = props.bundle.items.reduce(
    (acc, detail) => acc + detail.TransactionChargeAmount,
    0,
  );

  return (
    <SwipeableRow
      onDeletePressed={() =>
        trashClick(props.bundle.items.map((item) => item.LineNumber || -1))
      }
      type="remove"
    >
      <View style={styles.container}>
        <View style={styles.bundle}>
          <View style={styles.mainRow}>
            <View style={styles.rowContainer}>
              <AVText
                accessibilityLabel={`${
                  props.bundle.title
                }, $${totalPrice.toFixed(2)}`}
                accessibilityRole="text"
                aria-label={`${props.bundle.title}, $${totalPrice.toFixed(
                  2,
                )}, text`}
                numberOfLines={1}
                style={styles.description}
              >
                {props.bundle.title}
              </AVText>
              <AVFormattedCurrency
                style={styles.amount}
                value={totalPrice}
                accessibilityLabel="itemPrice"
                currency={AccountStore.getCurrency()}
              />
            </View>
            {discountRow}
          </View>
        </View>
        {items}
        {!!totalSupFee && (
          <View style={{alignItems: 'center', flexDirection: 'row'}}>
            <SupLeafIcon />
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              numberOfLines={1}
              style={styles.supLine}
            >
              {Localized.Labels.sup_fee}
            </AVText>
            <AVFormattedCurrency
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              numberOfLines={1}
              style={styles.supLine}
              accessible={true}
              accessibilityLabel={`SUP amount $${totalSupFee}`}
              aria-label={`SUP amount $${totalSupFee}, text`}
              value={totalSupFee}
              currency={AccountStore.getCurrency()}
            />
          </View>
        )}
      </View>
    </SwipeableRow>
  );
};

const styles = StyleSheet.create({
  amount: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  bundle: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m1,
  },
  container: {
    alignSelf: 'stretch',
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m2,
  },
  description: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '300',
    marginRight: Styles.Spacing.m2,
    flex: 1,
  },
  discountText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    alignSelf: 'flex-end',
  },
  icon: {
    marginRight: Styles.Spacing.m2,
  },
  item: {
    backgroundColor: Styles.primaryColor,
    borderRadius: Styles.Spacing.m1,
    flexDirection: 'row',
    marginRight: Styles.Spacing.m1,
    padding: Styles.Spacing.m1,
  },
  itemText: {
    color: Styles.white,
  },
  items: {
    flexDirection: 'row',
  },
  mainRow: {
    flex: 1,
  },
  rowContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  discountRow: {
    alignItems: 'flex-end',
  },
  supLine: {
    fontWeight: '300',
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginLeft: 5,
  },
  smallIcon: {
    height: 20,
    marginTop: -2.5,
    width: 20,
  },
  categoryButton: {
    backgroundColor: Styles.lightGray,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    height: undefined,
    marginRight: Styles.Spacing.m1,
    padding: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m2,
    alignSelf: 'flex-start',
  },
  categoryText: {
    fontSize: Styles.Fonts.f1,
    fontWeight: undefined,
  },
});
export default CartBundle;
