class SQLiteDatabase {
  close() {}

  async executeSql<T>(
    query: string,
    params?: string[],
  ): Promise<T | null | undefined> {
    return null;
  }
}

class SQLite {
  enablePromise(enable: boolean) {}

  openDatabase(
    name: string,
    version: string,
    displayName: string,
    size: number,
  ): SQLiteDatabase {
    return new SQLiteDatabase();
  }
}

export default new SQLite();
