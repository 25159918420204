import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

export default function SupLeafIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} viewBox="0 0 24 24" fill="#000000">
      <Path
        d="M21.318 2.013V2v.008L21.316 2l-.002.037c-.01.1-.021.2-.035.3-.907 4.61-17.82-.18-16.318 9.277 1.177-.425 2.415-.7 3.574-.918a36.653 36.653 0 012.752-.38c.845-.072 1.726-.18 2.552-.369 1.986-.444 3.925-1.304 5.407-2.727a10.144 10.144 0 01-4.938 3.455c-.654.22-1.327.381-2.007.498-.62.095-1.264.199-1.872.357-1.635.417-3.195 1.15-4.51 2.228a11.253 11.253 0 00-3.2 4.278 11.19 11.19 0 00-.663 2.082c-.014.066-.025.138-.037.208a1.45 1.45 0 102.877.35c.053-.943.345-1.932.737-2.816.266-.606.603-1.17.995-1.685 3.478 5.617 18.143 5.285 14.69-14.162z"
        fill={props.color}
      />
    </Svg>
  );
}
