import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import Styles from '../Styles';
import type {ScreenProps} from 'src/types/Screen';
import RoundedButton, {ButtonType} from './RoundedButton';
import {ReferralStatus} from 'src/types/Referral';
import AVText from './AVText';
import Settings from 'src/Settings';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import type {Referral} from 'src/types/Referral';
import TimeUtils from 'src/services/TimeUtils';
type ReferralItemProps = ScreenProps &
  WithGlobalizeProps & {
    referral: Referral;
    onPress: () => void;
  };

class ReferralItem extends React.Component<ReferralItemProps> {
  constructor(props: ReferralItemProps) {
    super(props);
  }

  renderDescription(isSender, status) {
    const {referral} = this.props;
    const {Labels} = Localized;
    const prefix = isSender
      ? Labels.formatString(
          Labels.your_friend_joined,
          referral.receiverName?.split(' ')?.[0] ?? '',
        )
      : Labels.formatString(Labels.thanks_for_joining, Settings.appDisplayName);
    let content: any = '';

    switch (status) {
      case ReferralStatus.Active:
        content = Labels.formatString(
          Labels.here_is_your_referral_amount,
          Util.formatCurrency(
            this.props,
            referral.amount ?? 0,
            AccountStore.getCurrency(),
          ),
        );
        break;

      case ReferralStatus.Claimed:
        content = Labels.successfully_claimed_reward;
        break;

      case ReferralStatus.Expired:
        content = Labels.reward_expired;
        break;
    }

    return `${prefix} ${content}`;
  }

  renderStatus(isSender, status) {
    if (status === ReferralStatus.Expired) {
      return (
        <AVText
          style={[
            styles.status,
            {
              color: Styles.dangerColor,
            },
          ]}
        >
          {Localized.Labels.expired}
        </AVText>
      );
    }

    if (status === ReferralStatus.Claimed) {
      return <AVText style={styles.status}>{Localized.Labels.claimed}</AVText>;
    }

    return <AVText style={styles.status}>{}</AVText>;
  }

  render() {
    const {referral, onPress} = this.props;
    const isSender = referral.sender === AccountStore.getAccountId();
    const status = isSender ? referral.senderRedeem : referral.receiverRedeem;
    return (
      <View style={styles.container}>
        <FontAwesome5Pro
          name="star"
          color={Styles.goldColor}
          size={Styles.Fonts.f2}
          solid
        />
        <View style={styles.mainContent}>
          <View style={styles.topSection}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={styles.title}
            >
              {isSender ? Localized.Labels.referral : Localized.Labels.sign_up}
            </Text>
            {this.renderStatus(isSender, status)}
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={styles.date}
            >
              {TimeUtils.formatInboxDate(referral.dateCreated)}
            </AVText>
          </View>
          <View style={styles.bottomSection}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={styles.description}
            >
              {this.renderDescription(isSender, status)}
            </Text>
            {status === ReferralStatus.Active && (
              <RoundedButton
                buttonType={ButtonType.normal}
                text={Localized.Labels.claim}
                onPress={onPress}
                accessibilityLabel="Invite Button"
              />
            )}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: Styles.Spacing.m3,
    paddingRight: Styles.Spacing.m3 + Styles.Spacing.m2,
    flex: 1,
    flexDirection: 'row',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  mainContent: {
    flex: 1,
    marginStart: Styles.Spacing.m2,
    marginEnd: Styles.Spacing.m3,
  },
  topSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Styles.Spacing.m1,
  },
  status: {
    fontWeight: '600',
    flex: 1,
    fontSize: Styles.Fonts.f0,
    marginStart: Styles.Spacing.m2,
    color: Styles.lightGray,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
    color: Styles.darkColor,
  },
  description: {
    flex: 1,
    fontSize: Styles.Fonts.f0,
    marginEnd: Styles.Spacing.m5,
    marginBottom: Styles.Spacing.m1,
  },
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
});
export default withGlobalize(ReferralItem);
