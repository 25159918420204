import Events from 'src/logging/Events';
import UUIDGenerator from 'src/nativeModules/UUID';
import AccountStore from 'src/stores/AccountStore';
import EventEmitter from 'events';
import AppSyncClientProvider from './AppSyncClientProvider';
import AppSyncAuthentication from './AppSyncAuthenticate';
import gql from 'graphql-tag';
import {messagesByOwner} from './graphql/queries';
import {createMessage, deleteMessage, updateMessage} from './graphql/mutations';
import {MessageType} from 'src/types/MessageType';
import {
  MessagesByOwnerQueryVariables,
  CreateMessageInput,
  UpdateMessageInput,
  DeleteMessageInput,
  DeleteMessageMutation,
} from './API';
import CrashlyticsEvents from 'src/logging/Crashlytics';
export class MessageRepository extends EventEmitter {
  MESSAGES_EVENT = 'messages';

  constructor() {
    super();
    AccountStore.addChangeListener(() => {
      if (AccountStore.isDemo()) {
        this.emit(this.MESSAGES_EVENT);
      }
    });
  }

  async refresh(): Promise<boolean> {
    const accountId = AccountStore.getAccountId();

    if (accountId && accountId !== '-1') {
      await AppSyncAuthentication.authenticate();
      return this._getUserMessages(accountId);
    }

    return false;
  }

  _getQuery(uuid: string) {
    const variables: MessagesByOwnerQueryVariables = {
      owner: uuid,
      limit: 1000,
    };
    const query = gql(messagesByOwner);
    return {
      variables,
      query,
    };
  }

  _readCache(cache: any, uuid: string): any {
    try {
      return cache.readQuery(this._getQuery(uuid));
    } catch {
      // assume nothing is in cache
      return {
        MessagesByOwner: {
          __typename: 'ModelMessageConnection',
          items: [],
          nextToken: '',
        },
      };
    }
  }

  async getMessages(): Promise<Array<MessageType>> {
    if (AccountStore.isDemo()) {
      return AccountStore.getDemoMessages();
    }

    const accountId = AccountStore.getAccountId();
    const client = await AppSyncClientProvider.getClient();

    if (accountId && accountId !== '-1' && client) {
      const data = this._readCache(client.cache, accountId);

      if (
        data.MessagesByOwner &&
        data.MessagesByOwner.items &&
        data.MessagesByOwner.items.length
      ) {
        return JSON.parse(JSON.stringify(data.MessagesByOwner.items)).reverse();
      }
    }

    return [];
  }

  async getNumUnreadMessages(): Promise<number> {
    const messages = await this.getMessages();
    return messages.filter((message) => !message.read).length;
  }

  addMessageListener(callback: () => void) {
    this.addListener(this.MESSAGES_EVENT, callback);
  }

  removeMessageListener(callback: () => void) {
    this.removeListener(this.MESSAGES_EVENT, callback);
  }

  _updateCache(cache: any, data: any, uuid: string): void {
    const cacheQuery = this._readCache(cache, uuid);

    if (data.createMessage) {
      cacheQuery.MessagesByOwner.items = [
        ...cacheQuery.MessagesByOwner.items.filter(
          (item) => item.id !== data.createMessage.id,
        ),
        data.createMessage,
      ];
    }

    if (data.updateMessage) {
      cacheQuery.MessagesByOwner.items = [
        ...cacheQuery.MessagesByOwner.items.filter(
          (item) => item.id !== data.updateMessage.id,
        ),
        data.updateMessage,
      ];
      cacheQuery.MessagesByOwner.items.sort((a, b) =>
        a.date > b.date ? 1 : -1,
      );
    }

    if (data.deleteMessage) {
      cacheQuery.MessagesByOwner.items = [
        ...cacheQuery.MessagesByOwner.items.filter(
          (item) => item.id !== data.deleteMessage.id,
        ),
      ];
    }

    cache.writeQuery({...this._getQuery(uuid), data: cacheQuery});
  }

  async _getUserMessages(uuid: string): Promise<boolean> {
    try {
      const client = await AppSyncClientProvider.getClient();
      await client.query({
        ...this._getQuery(uuid),
        fetchPolicy: 'network-only',
      });
      this.emit(this.MESSAGES_EVENT);
      return true;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MessageRepository:GetUserMessages',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MessageRepository:GetUserMessages',
        e.message ? e.message : e.toString(),
      );
    }

    return false;
  }

  async createMessage(uuid: string, message: MessageType): Promise<boolean> {
    try {
      await AppSyncAuthentication.authenticate();
      const id = await UUIDGenerator.getRandomUUID();
      const input: CreateMessageInput = {
        id: id,
        subject: message.subject,
        message: message.message,
        from: message.from,
        date: message.date,
        type: message.type || null,
        value: message.value || null,
        icon: message.icon || null,
        read: message.read,
        owner: uuid,
      };
      const optimisticOutput: any = {
        createMessage: {
          __typename: 'Message',
          ...input,
        },
      };
      const client = await AppSyncClientProvider.getClient();
      await client.mutate({
        mutation: gql(createMessage),
        variables: {
          input,
        },
        optimisticResponse: () => optimisticOutput,
        update: (cache, {data}) => this._updateCache(cache, data, uuid),
      });
      this.emit(this.MESSAGES_EVENT);
      return true;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MessageRepository:CreateMessage',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MessageRepository:CreateMessage',
        e.message ? e.message : e.toString(),
      );
    }

    return false;
  }

  async updateMessage(uuid: string, message: MessageType): Promise<boolean> {
    try {
      await AppSyncAuthentication.authenticate();

      if (message.id) {
        const input: UpdateMessageInput = {
          id: message.id,
          read: message.read,
        };
        const optimisticOutput: any = {
          updateMessage: {
            __typename: 'Message',
            ...message,
            owner: uuid,
          },
        };
        const client = await AppSyncClientProvider.getClient();
        await client.mutate({
          mutation: gql(updateMessage),
          variables: {
            input,
          },
          optimisticResponse: () => optimisticOutput,
          update: (cache, {data}) => this._updateCache(cache, data, uuid),
        });
        this.emit(this.MESSAGES_EVENT);
        return true;
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MessageRepository:UpdateMessage',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MessageRepository:UpdateMessage',
        e.message ? e.message : e.toString(),
      );
    }

    return false;
  }

  async deleteMessage(uuid: string, message: MessageType): Promise<boolean> {
    try {
      await AppSyncAuthentication.authenticate();

      if (message.id) {
        const input: DeleteMessageInput = {
          id: message.id,
        };
        const optimisticOutput: DeleteMessageMutation = {
          deleteMessage: {
            __typename: 'Message',
            id: message.id,
            subject: message.subject,
            message: message.message,
            from: message.from,
            date: message.date,
            type: message.type || null,
            value: message.value || null,
            icon: message.icon || null,
            read: message.read,
            owner: uuid,
          },
        };
        const client = await AppSyncClientProvider.getClient();
        await client.mutate({
          mutation: gql(deleteMessage),
          variables: {
            input,
          },
          optimisticResponse: () => optimisticOutput,
          update: (cache, {data}) => this._updateCache(cache, data, uuid),
        });
        this.emit(this.MESSAGES_EVENT);
        return true;
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MessageRepository:DeleteMessage',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MessageRepository:DeleteMessage',
        e.message ? e.message : e.toString(),
      );
    }

    return false;
  }
}
const instance = new MessageRepository();
export default instance;
