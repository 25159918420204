import React from 'react';
import {View, StyleSheet, Switch, Platform, ScrollView} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import CreditCardImage from '../CreditCardImage';
import NBDropdown from '../NBDropdown';
import PaymentMethodDropdown from '../PaymentMethodDropdown';
import AmountDropdown from './AmountDropdown';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import AccountConstants from 'src/constants/AccountConstants';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess, confirm} from '../../helpers/AlertHelper';
import {CreditCard} from 'src/models/CreditCard';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type Props = WithGlobalizeProps & {
  close: () => void;
  creditCards: Array<CreditCard>;
  defaultNonNfcPaymentToken: string;
  payrollAvailable: boolean;
};

type State = {
  amount: number;
  fallBelowAmount: number;
  cardToken: string;
  issuer: string;
  autoFundOn: boolean;
  paymentMenuVisible: boolean;
  amountMenuVisible: boolean;
  fallBelowMenuVisible: boolean;
};

class ExpressFundingControl extends React.Component<Props, State> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: Props) {
    super(props);
    const token =
      AccountStore.getAutoFundCardToken() ||
      this.props.defaultNonNfcPaymentToken;
    const issuer = AccountStore.getPaymentIssuer(token, this.props.creditCards);
    this.state = {
      amount:
        AccountStore.getAutoFundAmount() || AccountStore.getDefaultAmount(),
      fallBelowAmount:
        AccountStore.getAutoFundFallBelowAmount() ||
        AccountConstants.DEFAULT_FALL_BELOW_AMOUNT,
      cardToken: token,
      issuer,
      autoFundOn: AccountStore.isAutoFundingOn(),
      paymentMenuVisible: false,
      amountMenuVisible: false,
      fallBelowMenuVisible: false,
    };
    this.formatCurrency = this.formatCurrency.bind(this);
    this.amountSelected = this.amountSelected.bind(this);
    this.fallBelowAmountSelected = this.fallBelowAmountSelected.bind(this);
    this.cardSelected = this.cardSelected.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.turnAutoFundingOff = this.turnAutoFundingOff.bind(this);
    this.autoFundingChanged = this.autoFundingChanged.bind(this);
    this.fetchAutoFundDetails = this.fetchAutoFundDetails.bind(this);
  }

  componentDidMount(): void {
    this.fetchAutoFundDetails();
  }

  async fetchAutoFundDetails() {
    this.context.actions.showSpinner();
    let response;
    try {
      response = await ActionsFactory.getAccountActions().retrieveAutoFund(
        AccountStore.getAccountId(),
        AccountStore.getAccountBalanceId(),
      );
      if (response?.balanceTokenId) {
        this.cardSelected(response?.balanceTokenId);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ExpressFundingControl:FetchAutoFundDetails',
        error.message ? error.message : error.toString(),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ExpressFundingControl:FetchAutoFundDetails',
        error.message ? error.message : error.toString(),
        guid,
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  formatCurrency(value) {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  }

  cardSelected(token: string) {
    this.setState({
      cardToken: token,
      issuer: AccountStore.getPaymentIssuer(token, this.props.creditCards),
      paymentMenuVisible: false,
    });
  }

  amountSelected(value: string) {
    this.setState({
      amount: +value,
      amountMenuVisible: false,
    });
  }

  fallBelowAmountSelected(value: string) {
    this.setState({
      fallBelowAmount: +value,
      fallBelowMenuVisible: false,
    });
  }

  turnAutoFundingOff() {
    if (this.state.autoFundOn !== AccountStore.isAutoFundingOn()) {
      confirm(Localized.Labels.turn_off_auto_funding, async () => {
        this.context.actions.showSpinner();
        let response;
        try {
          response = await ActionsFactory.getAccountActions().turnOffAutoFund(
            AccountStore.getAccountId(),
            AccountStore.getAccountBalanceId(),
          );
          Logger.Log.LogAPIEvent(
            'PaymentAPI',
            'TurnOffAutoFund',
            JSON.stringify({
              accountBalanceId: AccountStore.getAccountBalanceId(),
              accountId: AccountStore.getAccountId(),
            }),
            JSON.stringify(response),
          );

          if (response.status === 'ok') {
            Events.AutoFund.trackEvent('TURNED_OFF');
            ActionsFactory.getAccountActions().retrieveAutoFund(
              AccountStore.getAccountId(),
              AccountStore.getAccountBalanceId(),
            );
            ActionsFactory.getAccountActions().getBalance(
              AccountStore.getAccountId(),
              true,
            );
            this.props.close();
          } else {
            alertError(Localized.Errors.error);
          }
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'ExpressFundingControl:TurnAutoFundingOff',
            error.message ? error.message : error.toString(),
            guid,
            response,
          );
          Events.Error.trackEvent(
            'Exception',
            'ExpressFundingControl:TurnAutoFundingOff',
            error.message ? error.message : error.toString(),
            guid,
          );
          alertError(Localized.Errors.error, guid);
          Logger.Log.LogAPIEvent(
            'PaymentAPI',
            'TurnOffAutoFund-Error',
            JSON.stringify({
              accountId: AccountStore.getAccountId(),
              accountBalanceId: AccountStore.getAccountBalanceId(),
            }),
            JSON.stringify(error),
          );
        } finally {
          this.context.actions.hideSpinner();
        }
      });
    } else {
      this.props.close();
    }
  }

  autoFundingChanged(): boolean {
    return (
      this.state.amount !== AccountStore.getAutoFundAmount() ||
      this.state.fallBelowAmount !==
        AccountStore.getAutoFundFallBelowAmount() ||
      this.state.cardToken !== AccountStore.getAutoFundCardToken()
    );
  }

  async saveClicked() {
    if (
      !this.state.cardToken ||
      !this.state.amount ||
      !this.state.fallBelowAmount
    ) {
      alertError(Localized.Errors.all_fields_required);
    } else if (!this.state.autoFundOn) {
      this.turnAutoFundingOff();
    } else if (this.autoFundingChanged()) {
      this.context.actions.showSpinner();
      let response;
      try {
        response = await ActionsFactory.getAccountActions().setupAutoFund(
          AccountStore.getAccountId(),
          AccountStore.getAccountBalanceId(),
          this.state.cardToken,
          this.state.amount,
          this.state.fallBelowAmount,
        );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetupAutoFund',
          JSON.stringify({
            amount: this.state.amount,
            accountId: AccountStore.getAccountId(),
            cardToken: this.state.cardToken,
            accountBalanceId: AccountStore.getAccountBalanceId(),
            fallBelowAmount: this.state.fallBelowAmount,
          }),
          JSON.stringify(response),
        );

        if (response) {
          Events.AutoFund.trackEvent(
            'TURNED_ON',
            this.state.amount,
            this.state.fallBelowAmount,
            this.state.cardToken,
          );
          ActionsFactory.getAccountActions().retrieveAutoFund(
            AccountStore.getAccountId(),
            AccountStore.getAccountBalanceId(),
          );
          ActionsFactory.getAccountActions().getBalance(
            AccountStore.getAccountId(),
            true,
          );
          alertSuccess(Localized.Success.auto_funding_successfully_set, () => {
            this.props.close();
          });
        } else {
          alertError(Localized.Errors.error_setting_auto_funding);
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ExpressFundingControl:SaveClicked',
          error.message ? error.message : error.toString(),
          guid,
          response,
        );
        Events.Error.trackEvent(
          'Exception',
          'ExpressFundingControl:SaveClicked',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.error_setting_auto_funding, guid);
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetupAutoFund-Error',
          JSON.stringify({
            fallBelowAmount: this.state.fallBelowAmount,
            accountId: AccountStore.getAccountId(),
            accountBalanceId: AccountStore.getAccountBalanceId(),
            cardToken: this.state.cardToken,
            amount: this.state.amount,
          }),
          JSON.stringify(error),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    } else {
      this.props.close();
    }
  }

  handleMenuVisibilityChange(visible: boolean, whichMenu: string) {
    switch (whichMenu) {
      case 'payment':
        this.setState({paymentMenuVisible: visible});
        break;
      case 'amount':
        this.setState({amountMenuVisible: visible});
        break;
      case 'fallBelow':
        this.setState({fallBelowMenuVisible: visible});
        break;
      default:
        break;
    }
  }

  render() {
    const creditCardsFiltered = Util.filterExpiredCreditCards(
      this.props.creditCards,
    );
    return (
      <ScrollView style={styles.container}>
        <View style={Styles.Style.maxWidthContainer}>
          <View style={styles.topContainer}>
            <View style={styles.toggleContainer}>
              <AVText
                accessible={true}
                accessibilityLabel={Localized.Labels.auto_reload}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                accessibilityRole="text"
                aria-label={Localized.Labels.auto_reload}
                style={styles.toggleLabel}
              >
                {Localized.Labels.auto_reload}
              </AVText>
              <Switch
                style={styles.switch}
                accessible={true}
                accessibilityLabel={Localized.Buttons.enable_auto_funding}
                aria-label={Localized.Buttons.enable_auto_funding}
                accessibilityHint={
                  'Double tap to turn auto funding ' + this.state.autoFundOn
                    ? 'off'
                    : 'on'
                }
                accessibilityState={{checked: this.state.autoFundOn}}
                accessibilityRole="switch"
                role="switch"
                nativeID="enableAutoFundSwitch"
                value={this.state.autoFundOn}
                onValueChange={(value: boolean) =>
                  this.setState({
                    autoFundOn: value,
                  })
                }
                trackColor={{
                  false: Styles.lightGray,
                  true: Styles.primaryColor,
                }}
              />
            </View>
            <View
              accessible={true}
              accessibilityLabel={Localized.Labels.when_funds_fall_below}
              aria-label={Localized.Labels.when_funds_fall_below}
              accessibilityHint="Select when you want auto-reload to take place"
              accessibilityRole="menu"
              role="menu"
              accessibilityValue={{
                text: this.formatCurrency(this.state.fallBelowAmount),
              }}
              accessibilityState={{expanded: this.state.fallBelowMenuVisible}}
              accessibilityElementsHidden={
                this.state.amountMenuVisible || this.state.paymentMenuVisible
              }
              importantForAccessibility={
                this.state.amountMenuVisible || this.state.paymentMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <NBDropdown
                anchorPosition="bottom"
                onSelect={this.fallBelowAmountSelected}
                selectedValue={this.state.fallBelowAmount}
                label={Localized.Labels.when_funds_fall_below}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.fallBelowMenuVisible,
                    'fallBelow',
                  )
                }
                options={[
                  {
                    value: 5,
                    text: this.formatCurrency(5),
                  },
                  {
                    value: 10,
                    text: this.formatCurrency(10),
                  },
                  {
                    value: 15,
                    text: this.formatCurrency(15),
                  },
                  {
                    value: 20,
                    text: this.formatCurrency(20),
                  },
                ]}
              />
            </View>
            <View
              accessible={true}
              accessibilityLabel={Localized.Labels.amount_drop_down}
              aria-label={Localized.Labels.amount_drop_down}
              role="menu"
              accessibilityRole="menu"
              accessibilityHint="Select the amount to be added to your account"
              accessibilityValue={{
                text: this.formatCurrency(this.state.amount).toString(),
              }}
              accessibilityState={{expanded: this.state.amountMenuVisible}}
              accessibilityElementsHidden={
                this.state.paymentMenuVisible || this.state.fallBelowMenuVisible
              }
              importantForAccessibility={
                this.state.paymentMenuVisible || this.state.fallBelowMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <AmountDropdown
                anchorPosition="top"
                onSelect={this.amountSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.amountMenuVisible,
                    'amount',
                  )
                }
                selectedValue={this.state.amount}
                formatNumber={this.formatCurrency}
              />
            </View>
          </View>

          <View style={styles.paymentContainer}>
            <View style={styles.ccImage}>
              <CreditCardImage issuer={this.state.issuer} />
            </View>
            <View
              accessible={true}
              accessibilityLabel={Localized.Labels.payment_method_drop_down}
              aria-label={Localized.Labels.payment_method_drop_down}
              role="menu"
              accessibilityRole="menu"
              accessibilityHint="Select the payment method you would like to use"
              accessibilityValue={{text: this.state.issuer}}
              accessibilityState={{expanded: this.state.paymentMenuVisible}}
              accessibilityElementsHidden={
                this.state.amountMenuVisible || this.state.fallBelowMenuVisible
              }
              importantForAccessibility={
                this.state.amountMenuVisible || this.state.fallBelowMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
              style={styles.dropdown}
            >
              <PaymentMethodDropdown
                onSelect={this.cardSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.paymentMenuVisible,
                    'payment',
                  )
                }
                anchorPosition={'top'}
                value={this.state.cardToken}
                cards={creditCardsFiltered}
                applePayAvailable={false}
                googlePayAvailable={false}
                payrollAvailable={this.props.payrollAvailable}
              />
            </View>
          </View>
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.saveClicked}
            containerStyle={styles.saveButtonContainer}
            accessible={true}
            accessibilityLabel={Localized.Buttons.save_auto_fund_settings}
            aria-label={Localized.Buttons.save_auto_fund_settings}
            accessibilityRole="button"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            role="button"
            text={Localized.Buttons.save}
          />
          <View style={styles.emptyView} />
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  ccImage: {
    justifyContent: 'center',
    width: Styles.Spacing.m4 + Styles.Spacing.m3,
  },
  container: {
    ...(Platform.OS === 'web' && {
      flex: 1,
    }),
    backgroundColor: Styles.white,
    height: 410,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
  },
  dropdown: {
    flex: 1,
  },
  paymentContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  switch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  toggleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m3,
  },
  toggleLabel: {
    alignSelf: 'center',
    color: Styles.darkColor,
  },
  topContainer: {
    marginLeft: Styles.Spacing.m4 + Styles.Spacing.m3,
  },
  saveButtonContainer: {
    bottom: Styles.Heights.h3,
    zIndex: 1,
  },
  emptyView: {
    height: Styles.Heights.h8,
  },
});

const ConnectedExpressFundingControl = connect((state: RootState) => ({
  creditCards: state.account.creditCards,
  payrollAvailable: state.account.account.isPayrollAvailable,
  defaultNonNfcPaymentToken: state.account.defaultNonNfcPaymentToken,
}))(ExpressFundingControl);
type WithGlobalizeType = {
  close: () => void;
} & WithGlobalizeProps;
export default withGlobalize<WithGlobalizeType>(ConnectedExpressFundingControl);
