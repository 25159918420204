import * as React from 'react';
import Svg, {G, Path, Polygon, Circle, Rect} from 'react-native-svg';
type MountainDewProps = {
  size: number | string;
};
export default class MountainDew extends React.Component<MountainDewProps> {
  render() {
    const {size} = this.props;
    return (
      <Svg width={size} height={size} viewBox="0 0 320 320">
        <G
          id="Brand/MountainDew"
          stroke="none"
          stroke-width="1"
          fill="none"
          fillRule="evenodd"
        >
          <Rect
            id="Rectangle"
            fill="#FFFFFF"
            opacity="0"
            x="0"
            y="0"
            width="320"
            height="320"
          />
          <G
            id="soda"
            transform="translate(108.000000, 18.000000)"
            fillRule="nonzero"
          >
            <Path
              d="M86.0962624,238.589939 C94.4376443,242.930311 100.13697,251.645393 100.13697,261.69133 C100.13697,268.357792 97.622969,274.446054 93.4985667,279.052265 L11.7788568,279.052265 C7.65445449,274.446054 5.14045389,268.357792 5.14045389,261.69133 C5.14045389,254.503713 8.05968811,247.987894 12.775033,243.277009 C14.6459816,241.40783 16.8124015,239.822212 19.1817155,238.589385 L5.14100825,176.333262 L5.14100825,106.890076 L31.7167941,50.466899 L73.5611838,50.466899 L100.13697,106.890076 L100.13697,176.333815 L86.0962624,238.589939 Z"
              id="Shape"
              fill="#CEFACF"
            />
            <Path
              d="M100.13697,106.890076 L73.2605857,50.466899 L61.5446351,50.466899 L88.421019,106.890076 L88.421019,176.333815 L74.2214977,238.589939 C82.6572288,242.930311 88.421019,251.645393 88.421019,261.69133 C88.421019,268.357792 85.8785826,274.446054 81.7075293,279.052265 L93.4234799,279.052265 C97.5945332,274.446054 100.13697,268.357792 100.13697,261.69133 C100.13697,251.645393 94.3726187,242.929757 85.9374483,238.589939 L100.13697,176.333815 L100.13697,106.890076 Z"
              id="Shape"
              fill="#000000"
              opacity="0.2"
            />
            <Path
              d="M4.15090686,106.890076 L31.0272908,50.466899 L42.7432414,50.466899 L15.8668574,106.890076 L15.8668574,176.333815 L30.0663788,238.589939 C21.6306477,242.930311 15.8668574,251.645393 15.8668574,261.69133 C15.8668574,268.357792 18.4092938,274.446054 22.5803472,279.052265 L10.8643966,279.052265 C6.69334326,274.446054 4.15090686,268.357792 4.15090686,261.69133 C4.15090686,251.645393 9.9152577,242.929757 18.3504282,238.589939 L4.15090686,176.333815 L4.15090686,106.890076 Z"
              id="Shape"
              fill="#FFFFFF"
              opacity="0.2"
            />
            <Polygon
              id="Rectangle-path"
              fill="#FF7C80"
              points="5.14045389 106.87108 17.0150184 106.87108 17.0150184 176.139373 5.14045389 176.139373"
            />
            <Path
              d="M31.7482743,176.139373 C31.7482743,141.505226 78.3669347,141.505226 78.3669347,106.87108 L49.2302719,106.87108 C49.2302719,141.505226 25.9209417,141.505226 25.9209417,176.139373"
              id="Shape"
              fill="#FFFFFF"
            />
            <Polygon
              id="Rectangle-path"
              fill="#55A256"
              points="34.826865 4.94773519 71.4401055 4.94773519 71.4401055 33.6445993 34.826865 33.6445993"
            />
            <Polygon
              id="Rectangle-path"
              fill="#000000"
              opacity="0.2"
              points="59.565541 4.94773519 71.4401055 4.94773519 71.4401055 33.6445993 59.565541 33.6445993"
            />
            <Path
              d="M71.092864,37.673192 L74.6473904,37.673192 L74.6473904,0 L28.6520395,0 L28.6520395,37.673192 L32.2065658,37.673192 L0.192718703,106.252957 L0.192718703,177.259474 L13.5486039,237.006967 C11.9254069,238.104691 10.414458,239.356618 9.03281474,240.749436 C3.3323669,246.496542 0.192718703,254.137902 0.192718703,262.265722 C0.192718703,269.7612 2.92244088,276.964912 7.8795195,282.551159 L9.16487145,284 L94.1334579,284 L95.4188099,282.551159 C100.375889,276.964357 103.105611,269.760645 103.105611,262.265722 C103.105611,252.025656 98.0632454,242.626495 89.7497255,237.006967 L103.105611,177.260029 L103.105611,106.252957 L71.092864,37.673192 Z M37.2758926,8.69360021 L66.022987,8.69360021 L66.022987,28.9790372 L37.2758926,28.9790372 L37.2758926,8.69360021 Z M65.620214,46.3673469 L37.6786656,46.3673469 L41.7372084,37.6737467 L61.5616712,37.6737467 L65.620214,46.3673469 Z M33.6206731,55.0609471 L69.6787568,55.0609471 L91.9996417,102.877135 L11.299238,102.877135 L33.6206731,55.0609471 Z M79.9197544,241.435028 L82.8635185,242.981494 C90.0308963,246.746151 94.4823077,254.135129 94.4823077,262.265167 C94.4823077,266.991096 92.9620049,271.55561 90.170106,275.305845 L13.1282234,275.305845 C10.3363245,271.555056 8.81602165,266.991096 8.81602165,262.265167 C8.81602165,256.459265 11.0582345,251.00115 15.1299829,246.895916 C16.4511002,245.564114 17.9499439,244.423679 19.578093,243.473501 L50.3594109,243.473501 L50.3594109,234.779901 L21.8907361,234.779901 L9.89558523,181.12231 L93.4021939,181.12231 L79.9197544,241.435028 Z"
              id="Shape"
              fill="#000000"
            />
            <Circle id="Oval" fill="#55A256" cx="53" cy="142" r="23" />
            <Path
              d="M57,110 C44.2974508,110 34,120.297451 34,133 C34,145.702549 44.2974508,156 57,156"
              id="Path"
              fill="#000000"
              opacity="0.2"
              transform="translate(45.500000, 133.000000) rotate(50.000000) translate(-45.500000, -133.000000) "
            />
          </G>
        </G>
      </Svg>
    );
  }
}
