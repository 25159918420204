//Cannot create files in web app and Logger feature will be applicable only for mobile apps
class Log {
  async setupLocalDebugLog(_enabled: boolean, _email: string, _env: string) {
    //setupLocalDebugLog-Local Log not applicable to Web
    return '';
  }

  async LogAPIEvent(
    _ApiType: string,
    _ApiName: string,
    _requestParams: string,
    _response: string,
  ) {
    //LogAPIEvent-Local Log not applicable to Web
    return;
  }

  async shareLogFile() {
    //ShareLogFile-Local Log not applicable to Web
    return '';
  }

  isLogEnabled() {
    //isLogEnabled-Local Log not applicable to Web & return false always
    return false;
  }
}

export default class Logger {
  static Log = new Log();
}
