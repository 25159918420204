import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}
function DevicesAndScanCardWhite({size = 640, color = '#fff'}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 640 640">
      <Path
        d="M328.44 559.66l6.65-32.7 5.72-28.16 1.93-9.48 82.19-404.56 1.92-9.48 3.29-16.2c1.02-5.03-1.62-9.76-6.02-11.29.07.96.02 1.96-.18 2.97l-3.29 16.2-1.93 9.48-82.19 404.55-1.93 9.48-5.72 28.16-6.64 32.7c-1.13 5.59-6.35 9.41-11.66 8.54L98.16 524.93h-.08l-2.37-.4h-.03l-2.66-.45-13.9 68.54c-1.11 5.48 2.1 10.61 7.19 11.67l216.25 35.64c5.18.63 10.2-3.14 11.31-8.62l14.45-71.26c.03-.13.09-.26.12-.39z"
        fill="#fff"
      />
      <Path
        d="M93.67 521.22l212.94 35.03c5.3.87 10.52-2.95 11.66-8.53l6.64-32.7 5.72-28.16 1.93-9.48 82.18-404.54 1.93-9.48 3.29-16.2c1.13-5.59-2.24-10.82-7.55-11.69L197.56.12c-5.3-.87-10.52 2.95-11.66 8.53l-3.29 16.2-1.93 9.48L98.5 438.88l-1.93 9.48-5.72 28.16-6.64 32.7c-1.14 5.59 2.24 10.82 7.55 11.69l1.84.3.07.06v-.05zm146.92-30.62l-15.51 27.15c-1.44 2.53-3.97 3.9-6.45 3.49l-30.19-4.97c-2.42-.4-4.24-2.42-4.64-5.17l-4.47-30.44c-.36-2.45.45-4.98 2.16-6.76 1.49-1.54 3.42-2.25 5.3-1.94l50.17 8.25c1.86.31 3.37 1.56 4.17 3.44.91 2.17.71 4.76-.53 6.94zm80.86.95l-9.95 48.98c-.92 4.54-5.17 7.65-9.48 6.94l-46.5-7.65c-4.3-.71-7.06-4.97-6.14-9.51l9.95-48.98c.92-4.54 5.17-7.65 9.48-6.94l46.5 7.65c4.3.71 7.06 4.97 6.14 9.51zm-211.89-61.34l78.07-384.25c1.14-5.6 6.37-9.43 11.69-8.56l196.79 32.37c5.32.87 8.71 6.12 7.57 11.73l-78.06 384.25c-1.14 5.6-6.37 9.43-11.69 8.56l-196.79-32.37c-5.32-.88-8.71-6.12-7.57-11.73zm-11.38 61.45l15.51-27.16c1.44-2.53 3.97-3.9 6.45-3.49l30.19 4.97c2.42.4 4.24 2.43 4.64 5.17l4.47 30.44c.36 2.45-.45 4.98-2.16 6.76-1.49 1.54-3.42 2.25-5.3 1.94l-50.17-8.25c-1.86-.3-3.37-1.56-4.16-3.44-.91-2.17-.72-4.76.53-6.94z"
        fill="#fff"
      />
      <Path
        d="M101.28 498.5l50.66 8.35c1.03.17 1.83-.47 2.21-.86.88-.92 1.31-2.28 1.12-3.56l-4.52-30.81c-.18-1.27-.95-2.19-1.95-2.36l-30.49-5.03c-1.03-.17-2.12.48-2.79 1.65l-15.66 27.48c-.65 1.14-.76 2.54-.3 3.65.2.48.7 1.31 1.71 1.48zM236.51 482.83l-50.66-8.35c-1.03-.17-1.83.47-2.21.86-.88.92-1.31 2.28-1.12 3.56l4.52 30.81c.18 1.26.95 2.19 1.95 2.35l30.49 5.02c1.03.17 2.12-.48 2.79-1.65l15.66-27.48c.65-1.14.76-2.54.3-3.65-.2-.48-.69-1.31-1.71-1.48zM314.7 485.63l-47.04-7.71c-2.46-.4-4.88 1.37-5.41 3.95l-10.06 49.37c-.53 2.58 1.05 5.01 3.5 5.41l8.41 1.38.21-1.02c.21-1.04 1.18-1.75 2.17-1.58l26.65 4.37c.99.16 1.62 1.13 1.41 2.17l-.21 1.02 8.4 1.38c2.46.4 4.88-1.37 5.41-3.95l10.06-49.37c.53-2.58-1.05-5.01-3.5-5.41zm-10.11 7.01l-7.38 36.21c-.62 3.02-3.45 5.09-6.33 4.62l-19.79-3.24c-2.88-.47-4.71-3.31-4.1-6.33l7.38-36.21c.62-3.02 3.45-5.09 6.33-4.62l19.79 3.25c2.88.47 4.71 3.31 4.1 6.33zM424.82 139.53l1.96-9.75 3.36-16.65c.89-4.42-.96-8.62-4.32-10.73l-90.66 449.82-2.14 10.63-6.36 31.56-7.39 36.66c-.42 2.11-1.37 3.99-2.66 5.52 4.64-.25 8.81-3.88 9.82-8.88l2.74-13.61 102.89-45.91-45.96-230.27 38.72-198.39zM452.49 335.97c.16-1.19.57-2.23 1.14-2.94.19-.24.4-.44.63-.59.49-.34 1.03-.46 1.56-.34l46.91 10.99c1.49.35 2.36 2.5 2.04 4.99l-.1.77 14.51 3.4c.49.12.98.09 1.44-.07.96-.33 1.65-1.24 1.8-2.35l21.68-165.15c.23-1.77-.91-3.52-2.54-3.91l-81.15-19.02c-.28-.07-.57-.09-.85-.06-1.25.12-2.2 1.11-2.38 2.48L435.5 329.32c-.23 1.77.91 3.52 2.54 3.9l14.38 3.37.09-.65zm-3.2-24.79l13.21-100.61c.16-1.22.79-2.29 1.73-2.94.9-.63 1.99-.83 3.08-.57l56.45 13.23c2.26.53 3.84 2.93 3.53 5.34l-13.21 100.61c-.16 1.22-.79 2.29-1.73 2.94-.71.49-1.54.72-2.4.67-.23-.01-.45-.05-.68-.1l-56.45-13.23c-2.26-.53-3.84-2.93-3.52-5.34z"
        fill="#fff"
      />
      <Path
        d="M528.68 357.45c.44-.02 1.16-.89 1.94-2.24.37-.65.59-2.44.59-2.44.47-3.37 1.44-10.28 2.72-19.45l-2.54-3.11-2.71 20.49-1.38.54-8.32 2.82c-.06 0-.11 0-.17.01h-.48c-.09 0-.18-.02-.27-.03H518l-.33-.06-15.68-3.65.25-1.93c.24-1.83-.36-3.37-1.05-3.53l-47-10.93c-.06-.01-.25-.06-.53.14-.59.4-1.07 1.43-1.22 2.61l-.27 2.06-9.92-2.31c.51.6 1.19 1.04 1.93 1.21l82.52 19.5s1.54.32 1.98.29zM545.82 229.79l4.11-1.78c3.31-22.58 5.8-39.98 6.07-42.94.04-.48-.1-.88-.46-1.26-.74-.79-2.15-1.33-3.55-1.67.33 1.02.32 2.05.2 2.73l-6.37 44.93z"
        fill="#fff"
      />
      <Path
        d="M558.14 175.86l-89.61-19.24s-.35-.09-.67.05l-1.05.4-.08.03-5.8 2.02 80.85 18.83c2.29.53 3.89 2.95 3.57 5.38L523.37 349.7c-.02.18-.06.36-.11.54 0 .03-.01.06-.02.08-.05.17-.1.33-.16.48-.01.03-.03.06-.04.09-.02.04-.03.08-.05.13l5.39-1.92.03.04 2.83-21.38-2.96-3.63 12.32-93.22 3.67-1.7 6.14-46.49s.59-3.25-2.07-4.43c0 0 8.65.51 8.28 4.94-.21 2.54-1.89 15.25-4.26 32.71l-1.39 10.15-.21 1.54-8.96 4.16-12.15 91.95 6.79 8.32-.24 1.77-.12-.15c-1.77 12.7-2.91 20.82-2.91 20.82s-.14.57-.67 1.24c.29-.11.45-.18.45-.18l17.44-6.81 8.95-3.45c1.03-.42 1.51-.49 1.57-1.59l.19-164c.1-1.75-1.22-3.48-2.96-3.86zM531.45 483.14l-.62.63c-8.68 8.44-22.74 8.45-31.43-.01l-33.67-32.77c-3.88-3.78-6.02-8.6-6.43-13.54-.51 6.11 1.63 12.39 6.43 17.06l33.67 32.77c8.69 8.46 22.75 8.45 31.43.01l.62-.63a21.228 21.228 0 005.8-16.41c-.39 4.66-2.32 9.22-5.8 12.88z"
        fill="#fff"
      />
      <Path
        d="M465.72 418.29a21.475 21.475 0 00-6.42 17.17 21.51 21.51 0 006.43 13.64l33.67 33.01c8.69 8.52 22.75 8.52 31.43.01l.62-.63c3.48-3.69 5.41-8.28 5.8-12.98.51-6.16-1.63-12.49-6.43-17.2l-33.67-33.01c-8.69-8.52-22.76-8.52-31.43-.01zm10.34 10.14c-1.63 1.59-4.23 1.63-5.82.07s-1.55-4.11.07-5.7c1.63-1.59 4.23-1.63 5.82-.07 1.59 1.56 1.55 4.11-.07 5.7zm2.39 10.05l7.85-7.7 1.2 1.17-7.86 7.7-1.2-1.17zm2.39 2.35l7.86-7.7 2.39 2.35-7.85 7.7-2.39-2.35zm3.59 3.52l7.86-7.7 1.2 1.17-7.85 7.7-1.2-1.17zm2.39 2.35l7.86-7.7 2.39 2.35-7.85 7.7-2.39-2.35zm36.44 20.32l-7.86 7.7-2.39-2.35 7.86-7.7 2.39 2.35zm-3.59-3.52l-7.86 7.7-1.2-1.17 7.86-7.7 1.2 1.17zm-2.39-2.35l-7.85 7.7-1.2-1.17 7.86-7.7 1.2 1.17zm-2.39-2.35l-7.86 7.7-2.39-2.35 7.86-7.7 2.39 2.35zm-3.59-3.52l-7.85 7.7-1.2-1.17 7.86-7.7 1.2 1.17zm-2.39-2.35l-7.86 7.7-2.39-2.35 7.85-7.7 2.39 2.35zm-3.45-3.38l-7.85 7.7-2.39-2.35 7.85-7.7 2.39 2.35zm-3.59-3.52l-7.85 7.7-1.2-1.17 7.86-7.7 1.2 1.17zm-2.39-2.35l-7.85 7.7-1.2-1.17 7.86-7.7 1.2 1.17z"
        fill="#fff"
      />
    </Svg>
  );
}

export default DevicesAndScanCardWhite;
