import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import Util from 'src/Util';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import ActionsFactory from 'src/actions/ActionsFactory';
import {SnackStatus} from '../../slices/snackSlice';
import AccountStore from 'src/stores/AccountStore';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type CancelSnackParams = {
  snackDetails: GiftItemType;
};
export const cancelSnack = createAsyncThunk<
  GiftItemType,
  CancelSnackParams,
  {dispatch: AppDispatch; state: RootState}
>('snack/cancelSnack', async ({snackDetails}, {dispatch}) => {
  dispatch(showSpinner());
  try {
    const cancelSnackRes = await PlatformApi.cancelSnack(
      snackDetails.locationId,
      snackDetails.snackId,
      Util.getCurrentDate(),
    );
    Logger.Log.LogAPIEvent(
      'PlatformAPI',
      'CancelSnack',
      JSON.stringify({
        locationId: snackDetails.locationId,
        snackId: snackDetails.snackId,
      }),
      JSON.stringify(cancelSnackRes),
    );
    alertSuccess(
      Localized.Success.formatString(
        Localized.Success.cancelled_amount_has_been_returned_to_your_account,
        snackDetails.amount.toFixed(2).toString(),
      ),
      undefined,
      Localized.Success.snack_cancelled,
    );

    //TODO will remove
    await ActionsFactory.getAccountActions().getBalance(
      AccountStore.getAccountId(),
    );

    return {
      ...snackDetails,
      status: SnackStatus.Cancelled,
    };
  } catch (error) {
    if (error?.code === PlatformApiErrors.SnackCancelled) {
      alertError(Localized.Errors.snack_already_cancelled);
    } else {
      alertError(Localized.Errors.error);
      CrashlyticsEvents.log(
        'Exception',
        'snack/cancelSnack',
        error.message ? error.message : JSON.stringify(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'snack/cancelSnack',
        error.message ? error.message : JSON.stringify(error),
      );
    }
  } finally {
    dispatch(hideSpinner());
  }
});
