import {combineReducers} from '@reduxjs/toolkit';
import accountReducer from './slices/accountSlice';
import environmentReducer from './slices/environmentSlice';
import screenReducer from './slices/screenSlice';
import {changeAuth0Client} from '../nativeModules/Auth0';
import fundingReducer from './slices/fundingSlice';
import menuReducer from './slices/menuSlice';
import snackReducer from './slices/snackSlice';
import fireBaseConfigReducer from './slices/firebaseRemoteConfigSlice';

const baseReducer = combineReducers({
  account: accountReducer,
  environment: environmentReducer,
  funding: fundingReducer,
  menu: menuReducer,
  screen: screenReducer,
  snack: snackReducer,
  firebaseConfig: fireBaseConfigReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'account/logout') {
    state = {
      environment: state.environment,
    };
    state.environment && changeAuth0Client(state.environment.env);
    state.funding = undefined;
  }

  return baseReducer(state, action);
};
