import * as React from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  Platform,
  PixelRatio,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../ScreenContext';
import BackSubheader from '../elements/BackSubheader';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

const LOCATION_CODE_MAX_LENGTH = 12;
const LOCATION_CODE_MIN_LENGTH = 1;

type LocationCodeScreenProps = {
  navigation: NavigationProp<LocationCodeScreen>;
};

type LocationCodeScreenState = {
  locationCode: string;
  previousRoute: string | null;
};

class LocationCodeScreen extends React.Component<
  LocationCodeScreenProps,
  LocationCodeScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: LocationCodeScreenProps) {
    super(props);
    this.state = {
      locationCode: '',
      previousRoute: null,
    };
    this.saveClick = this.saveClick.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'LocationCodeScreen', {
      ...this.props,
      ...this.state,
    });
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState().routes,
    );
    this.setState({previousRoute});
  }

  async saveClick() {
    FirebaseAnalytic.trackEvent('saveClick', 'LocationCodeScreen', {
      ...this.props,
      ...this.state,
    });
    if (
      this.state.locationCode &&
      this.state.locationCode.length >= LOCATION_CODE_MIN_LENGTH
    ) {
      const homeLocation = AccountStore.getLocationId();

      // this is a working around to get the subsidy balance into a new account without the API changes.
      if (homeLocation === null) {
        const res =
          await ActionsFactory.getAccountActions().getLocationByBeacon(
            this.state.locationCode,
            AccountStore.getAccountId(),
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'GetLocationByBeacon',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            locationCode: this.state.locationCode,
          }),
          JSON.stringify(res),
        );
        const deviceLookUpResponse =
          await ActionsFactory.getAccountActions().deviceLookUp(
            res.locationId,
            AccountStore.getAccountId(),
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'DeviceLookUp',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            locationId: res.locationId,
          }),
          JSON.stringify(deviceLookUpResponse),
        );
      }

      const response = await ActionsFactory.getAccountActions().getLocationV1(
        this.state.locationCode,
      );
      const location = response;

      FirebaseAnalytic.trackEvent(
        'saveClick getLocationV1',
        'LocationCodeScreen',
        {
          ...this.props,
          ...this.state,
          response,
          homeLocation,
        },
      );

      if (!location || !location?.data?.name) {
        alertError(
          Localized.Errors.dont_know_location_code,
          null,
          undefined,
          Localized.Errors.invalid_location_code,
        );
      } else if (location?.data.hasOrderAhead) {
        confirm(
          this.getLocationAddressDisplay(location),
          async () => {
            if (!homeLocation) {
              // Make the location the user's home location
              const resp =
                await ActionsFactory.getAccountActions().getLocationV1(
                  this.state.locationCode,
                  AccountStore.getAccountId(),
                );
              if (resp && resp.status === 'ok') {
                await ActionsFactory.getAccountActions().getLocation(
                  resp.data.id,
                );
              }
            }

            ActionsFactory.getAccountActions().addLocationLink(
              AccountStore.getAccountId(),
              location.data.id,
            );
            NavActions.pop();
          },
          undefined,
          location.data.name,
          Localized.Labels.back,
          Localized.Buttons.confirm,
        );
      } else if (!location?.data.hasOrderAhead) {
        alertError(
          Localized.Errors.location_has_no_orderAhead,
          null,
          undefined,
          Localized.Errors.sorry,
        );
      } else {
        confirm(
          this.getLocationAddressDisplay(location),
          async () => {
            // Make the location the user's home location
            const resp = await ActionsFactory.getAccountActions().getLocationV1(
              this.state.locationCode,
              AccountStore.getAccountId(),
            );
            if (resp && resp.status === 'ok') {
              await ActionsFactory.getAccountActions().getLocation(
                resp.data.id,
              );
            }
            NavActions.pop();
          },
          undefined,
          location.data.name,
          Localized.Labels.back,
          Localized.Buttons.confirm,
        );
      }
    } else {
      alertError(
        Localized.Errors.dont_know_location_code,
        null,
        undefined,
        Localized.Errors.invalid_location_code,
      );
    }
  }

  getLocationAddressDisplay(location) {
    FirebaseAnalytic.trackEvent(
      'getLocationAddressDisplay',
      'LocationCodeScreen',
      {
        ...this.props,
        ...this.state,
        location,
      },
    );
    if (location.data.city && location.data.state) {
      return `${location.data.city}, ${location.data.state}`;
    } else if (location.data.city) {
      return location.data.city;
    } else if (location.data.state) {
      return location.data.state;
    }

    return '';
  }

  render() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.link_new_location}
      >
        <KeyboardAvoidingView behavior="height" insideTab>
          <ScrollView>
            <View style={styles.content}>
              <Text
                style={styles.headerText}
                accessible={true}
                aria-label="Enter the location code, text"
                accessibilityLabel="Enter the location code"
                maxFontSizeMultiplier={4}
              >
                {Localized.Labels.enter_your_location_code}
              </Text>

              <View style={{width: Platform.OS === 'web' ? '40%' : '100%'}}>
                <AllyTextInput
                  accessible={true}
                  accessibilityLabel={Localized.Labels.location_code}
                  accessibilityHint={
                    Localized.Labels.please_enter_location_code
                  }
                  accessibilityValue={{text: this.state.locationCode}}
                  label={Localized.Labels.location_code}
                  value={this.state.locationCode}
                  onChangeText={(text: string) => {
                    this.setState({
                      locationCode: text,
                    });
                  }}
                  autoCapitalize="none"
                  showHelperText={true}
                  helperText={Localized.Labels.please_enter_location_code}
                  maxLength={LOCATION_CODE_MAX_LENGTH}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />
              </View>
            </View>
            {PixelRatio.getFontScale() > 1 && (
              <View style={{height: Styles.Heights.headerHeight}} />
            )}
          </ScrollView>
          <RoundedButton
            accessible={true}
            accessibilityLabel={Localized.Buttons.save}
            accessibilityRole="button"
            role="button"
            aria-label={Localized.Buttons.save}
            buttonType={ButtonType.action}
            text={Localized.Buttons.save}
            onPress={this.saveClick}
          />
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
  },
  headerText: {
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    color: Styles.darkColor,
  },
});
export default withForwardedNavigationParams<LocationCodeScreenProps>()(
  LocationCodeScreen,
);
