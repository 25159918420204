import React from 'react';
import {StyleSheet, Animated} from 'react-native';
import {withSelfMeasure} from '../../utils/SelfMeasureBehavior';
import {compose} from 'recompose';
import buildTransform from '../../utils/BuildTransform';
import DefaultLogo from '../../img/svg/365Logo';
import SFLogo from '../../img/svg/SFLogo';
import MarketLogo from '../../img/svg/MarketLogo';
import Styles from '../../Styles';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';

const AnimatedLogo = ({
  animationRange,
  onLayoutSetMeasurements,
  elementX,
  elementY,
  elementHeight,
  elementWidth,
}) => {
  const animateImage = buildTransform(
    animationRange,
    elementX,
    elementY,
    elementHeight,
    elementWidth,
    elementX,
    -3,
    1,
  );
  let logo;

  if (Settings.buildType === BuildTypes.default) {
    logo = DefaultLogo;
  }

  if (Settings.buildType === BuildTypes.social) {
    logo = SFLogo;
  }

  if (Settings.buildType === BuildTypes.market) {
    logo = MarketLogo;
  }

  if (logo) {
    const AnimatedLogoComponent = Animated.createAnimatedComponent(logo);
    return (
      <AnimatedLogoComponent
        size={Styles.Heights.h5}
        style={[animateImage]}
        onLayout={(event) => onLayoutSetMeasurements(event)}
        fillColor={Styles.black}
      />
    );
  }

  return (
    <Animated.Image
      style={[styles.icon, animateImage]}
      source={require('../../img/canteen-icon.png')}
      onLayout={(event) => onLayoutSetMeasurements(event)}
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    borderRadius: Styles.Heights.h5 / 2,
    height: Styles.Heights.h5,
    resizeMode: 'contain',
    width: Styles.Heights.h5,
  },
});
const enhance = compose(withSelfMeasure);
export default enhance(AnimatedLogo);
