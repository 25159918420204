const commands = {
  KEEP_ALIVE: '01',
  SYSTEM_COMMENT: '20',
  UPLOAD_DATA: '31',
  SEND_CREDIT_CODE: '41',
  VEND_REQUEST_CODE: '58',
  VEND_REQUEST_RESPONSE_CODE: '42',
  DEX_REQUEST_CODE: '43',
  DEX_FILE_CODE: '44',
  PASSCODE_CODE: '46',
  PASSCODE_VERIFIED_CODE: '47',
  SET_TIME_CODE: '4a',
  WEB_RESPONSE_CODE: '4c',
  WEB_RESULT_CODE: '4d',
  WEB_REQUEST_CODE: '4e',
  BLUETOOTH_ERROR_CODE: '4f',
  PING_REQUEST_CODE: '50',
  PING_RESPONSE_CODE: '51',
  PING_RESULT_CODE: '52',
  DEX_DATA_REQUEST_CODE: '53',
  DEX_DATA_UPLOADED_CODE: '54',
  DEX_DATA_READ_CODE: '55',
  DEX_FAILED_CODE: '56',
  VEND_RESPONSE_CODE: '57',
  SET_NAME_CODE: '59',
  BT_SERVER_BUSY: '5a',
  START_VMC: '5b',
  STOP_VMC: '5c',
  VMC_LOG: '5d',
  RESET_VMC: '5e',
  SERVICE_MODE: '5f',
  SETUP_REQUEST_CODE: '60',
  SETUP_RESPONSE_CODE: '61',
  SETUP_RESULT_CODE: '62',
  REBOOT_CODE: '63',
  TRANSACTION_UPLOADED_CODE: '64',
  SET_ENVIRONMENT_CODE: '65',
  SESSION_ENDED_CODE: '66',
  UPDATE_COMMAND: '67',
  DEVICE_REBOOT_CODE: '69',
  CHECK_IP_ADDRESS: '6a',
  PIC_UPDATE: '6b',
  CLOSE_CONNECTION_CODE: '1f',
  LONG_PACKET_COMMAND: '90',
};
const displayCommands = buildDisplayCommands(commands);

function buildDisplayCommands(codes) {
  const display = {};
  Object.keys(codes).forEach((prop) => {
    display[codes[prop]] = prop;
  });
  return display;
}

export {commands, displayCommands};
