import {alertError, alertSuccess} from '../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import GmaApi from 'src/api/GmaApi';
import AppApi from 'src/api/AppApi';
import MoblicoService from 'src/services/MoblicoService';
import AccountConstants from 'src/constants/AccountConstants';
import AccountStore from 'src/stores/AccountStore';
import Events from 'src/logging/Events';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import {authStore} from 'src/init';
import {storeReceipt} from 'src/redux/slices/accountSlice';
import {store} from 'src/redux/store';
import {parseParams} from 'src/navigators/AppNavigator.web';
import {deepLinkCheckEnvironments} from '../../redux/thunks/environmentThunks';
import {Platform} from 'react-native';
import PersistentStore from 'src/services/PersistentStoreService';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type genericParams = {
  env: string | void;
  accountId: string;
  referralId: string | null | undefined;
  token: string | null | undefined;
};

export type verifyEmailParams = {
  env: string | void;
  marketaccount?: string;
  verificationtoken?: string;
  accountId?: string;
  token?: string;
  launchAuth0Login: () => void;
};

export type pdeParams = {
  env: string | void;
  importId: string;
};

export type referralParams = {
  env: string | void;
  referralId: string;
};
export type receiptParams = {
  env: string | void;
  transactionId: string;
  marketAccount: string;
  transactionDate: string;
  $fallback_url: string;
};
export type savedReceipt = {
  transactionId: string;
  marketAccount: string;
  transactionType: any;
  transactionDate: string | string[];
};

export type snackParams = {
  env: string | void;
  snackId: string;
  locationId: string;
};

export async function changeEnvironment(newEnvironment: string | void) {
  if (!newEnvironment) {
    return;
  }

  try {
    store.dispatch(deepLinkCheckEnvironments(newEnvironment));
  } catch (error) {
    CrashlyticsEvents.log(
      'Exception',
      'DeepLinkHandler:changeEnvironment',
      error.message ? error.message : error.toString(),
    );
    Events.Error.trackEvent(
      'Exception',
      'DeepLinkHandler:changeEnvironment',
      error.message ? error.message : error.toString(),
    );
  }
}

export async function handleCreateAccount({
  env: newEnvironment,
  accountId,
  referralId,
  token,
}: genericParams) {
  await changeEnvironment(newEnvironment);

  try {
    const response = await GmaApi.getBalance(accountId);

    if (
      response &&
      response.email &&
      response.email.length > 0 &&
      response.firstName
    ) {
      alertError(Localized.Errors.account_already_setup);
    } else if (response.data) {
      NavActions.push(AppRoutes.Welcome, {
        accountId,
        email: response.data.unverifiedemail,
        referralId,
        token,
      });
    }
  } catch (e) {
    alertError(Localized.Errors.problem_creating_account);
    CrashlyticsEvents.log(
      'Exception',
      'DeepLinkHandler:handleCreateAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        accountId,
        token,
        referralId,
      },
    );
    Events.Error.trackEvent(
      'Exception',
      'DeepLinkHandler:handleCreateAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        accountId,
        token,
        referralId,
      },
    );
  }
}

export async function handleVerifyEmail({
  env: newEnvironment,
  marketaccount,
  verificationtoken,
  accountId,
  token,
  launchAuth0Login,
}: verifyEmailParams) {
  const gmaId = marketaccount ?? accountId;
  const accountToken = verificationtoken ?? token;
  try {
    if (!gmaId || !accountToken) {
      throw new Error('Invalid Link');
    }

    await changeEnvironment(newEnvironment);
    const response = await GmaApi.getBalance(gmaId);
    const email = response.data.unverifiedemail ?? response.email;

    if (email) {
      const result = await GmaApi.confirmEmailAddress(
        gmaId,
        email,
        accountToken,
      );

      if (result.status === 'ok') {
        // Update the user's email address in Moblico's system
        MoblicoService.updateUserEmail(response.data.email, email);

        // Show success alert and prompt login
        const auth0LaunchCallback =
          Platform.OS === 'web' && !launchAuth0Login
            ? () => AccountStore.emitEmailVerified()
            : launchAuth0Login;
        alertSuccess(
          Localized.Labels.sign_in_to_complete_setup,
          auth0LaunchCallback,
        );
      }
    }
  } catch (e) {
    alertError(Localized.Errors.unable_to_change_email);

    CrashlyticsEvents.log(
      'Exception',
      'DeepLinkHandler:handleVerifyEmail',
      e.message ? e.message : e.toString(),
      '',
      {
        gmaId,
        accountToken,
      },
    );
    Events.Error.trackEvent(
      'Exception',
      'DeepLinkHandler:handleVerifyEmail',
      e.message ? e.message : e.toString(),
      '',
      {
        gmaId,
        accountToken,
      },
    );
  }
}
export async function handleStartAccount({
  env: newEnvironment,
  importId,
}: pdeParams) {
  await changeEnvironment(newEnvironment);

  try {
    const response = await AppApi.fetchPayrollImport(importId);
    await PersistentStore.setPayrollImportData({
      importId,
      locationId: response.locationId,
    });

    if (response) {
      NavActions.push(AppRoutes.CreateAccountEnterEmail, {
        ...response,
        importId,
        firstName: response.firstName,
        locationId: response.locationId,
        lastName: response.lastName,
        email: response.linkingEmail,
        pin: response.pin,
        startingBalance: response.startingBalance,
      });
    }
  } catch (e) {
    CrashlyticsEvents.log(
      'Exception',
      'DeepLinkHandler:HandleStartAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        importId,
      },
    );
    Events.Error.trackEvent(
      'Exception',
      'DeepLinkHandler:HandleStartAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        importId,
      },
    );
    alertError(Localized.Errors.invalid_import_id);
  }
}
export async function handleLinkAccount({
  env: newEnvironment,
  importId,
}: pdeParams) {
  try {
    await changeEnvironment(newEnvironment);
    const response = await AppApi.fetchPayrollImport(importId);
    await PersistentStore.setPayrollImportData({
      importId,
      locationId: response.locationId,
    });

    if (response) {
      if (!AccountStore.isLoggedIn()) {
        NavActions.push(AppRoutes.Welcome);
      } else {
        NavActions.push(AppRoutes.Main);
      }
    }
  } catch (e) {
    CrashlyticsEvents.log(
      'Exception',
      'DeepLinkHandler:HandleLinkAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        importId,
      },
    );
    Events.Error.trackEvent(
      'Exception',
      'DeepLinkHandler:HandleLinkAccount',
      e.message ? e.message : e.toString(),
      '',
      {
        importId,
      },
    );
    alertError(Localized.Errors.invalid_import_id);
  }
}
export async function handlePayrollUnsubscribe({
  env: newEnvironment,
  importId,
}: pdeParams) {
  try {
    await changeEnvironment(newEnvironment);
    await AppApi.payrollUnsubscribe(importId);
    alertSuccess(Localized.Success.success);
  } catch (e) {
    if (!e?.okResponse) {
      CrashlyticsEvents.log(
        'Exception',
        'DeepLinkHandler:HandlePayrollUnsubscribe',
        e.message ? e.message : e.toString(),
        '',
        {
          importId,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'DeepLinkHandler:HandlePayrollUnsubscribe',
        e.message ? e.message : e.toString(),
        '',
        {
          importId,
        },
      );
    }
  }
}
export async function handleReferral({
  env: newEnvironment,
  referralId,
}: referralParams) {
  try {
    await changeEnvironment(newEnvironment);
    NavActions.push(AppRoutes.ReferralLanding, {
      referralId,
    });
  } catch (e) {
    if (!e?.okResponse) {
      CrashlyticsEvents.log(
        'Exception',
        'DeepLinkHandler:Referral',
        e.message ? e.message : e.toString(),
        '',
        {
          referralId,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'DeepLinkHandler:Referral',
        e.message ? e.message : e.toString(),
        '',
        {
          referralId,
        },
      );
    }
  }
}
export async function handleReceipt({
  env: newEnvironment,
  transactionId,
  marketAccount,
  transactionDate,
  $fallback_url,
}: receiptParams) {
  let exportTransactionDate: string | string[] = transactionDate;
  if ($fallback_url) {
    exportTransactionDate = parseParams($fallback_url).transactionDate;
  }

  try {
    await changeEnvironment(newEnvironment);
    const accessToken = await authStore.getAccessToken();
    if (!accessToken) {
      const savedReceipt: savedReceipt = {
        transactionId,
        transactionType: AccountConstants.SALE_TYPE,
        marketAccount,
        transactionDate: exportTransactionDate,
      };
      store.dispatch(storeReceipt(savedReceipt));
      return NavActions.navigate(AppRoutes.Welcome);
    }
    NavActions.push(AppRoutes.PurchaseHistoryDetail, {
      transactionId,
      transactionType: AccountConstants.SALE_TYPE,
      marketAccount,
      transactionDate: exportTransactionDate,
    });
  } catch (e) {
    if (!e?.okResponse) {
      CrashlyticsEvents.log(
        'Exception',
        'DeepLinkHandler:Receipt',
        e.message ? e.message : e.toString(),
        '',
        {
          transactionId,
          marketAccount,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'DeepLinkHandler:Receipt',
        e.message ? e.message : e.toString(),
        '',
        {
          transactionId,
          marketAccount,
        },
      );
    }
  }
}
export async function handleSnack(
  {env: newEnvironment, snackId, locationId}: snackParams,
  snackAction: keyof typeof SnackActions,
) {
  try {
    await changeEnvironment(newEnvironment);

    if (AccountStore.isLoggedIn()) {
      NavActions.navigate(AppRoutes.SnackDetail, {
        snackId,
        snackLocationId: locationId,
        snackAction,
      });
    } else {
      NavActions.navigate(AppRoutes.Welcome, {
        snack: {
          snackId,
          locationId,
          snackAction,
        },
      });
    }
  } catch (e) {
    if (!e?.okResponse) {
      CrashlyticsEvents.log(
        'Exception',
        'DeepLinkHandler:Snack',
        e.message ? e.message : e.toString(),
        '',
        {
          snackId,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'DeepLinkHandler:Snack',
        e.message ? e.message : e.toString(),
        '',
        {
          snackId,
        },
      );
    }
  }
}
