import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from 'src/components/elements/AVText';
import BackSubheader from 'src/components/elements/BackSubheader';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import {getPreviousRouteName} from '../../../Util';
import {useNavigation} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

const DeleteAccountScreen = () => {
  const navigation = useNavigation();
  const previousRoute = getPreviousRouteName(navigation?.getState()?.routes);

  const deletePressed = async () => {
    const {deleteAccount, logout} = ActionsFactory.getAccountActions();
    const deleteResponse = await deleteAccount(
      AccountStore.getAccountId(),
      AccountStore.getEmail(),
    );
    Logger.Log.LogAPIEvent(
      'AppAPI',
      'DeleteAccount',
      JSON.stringify({
        accountId: AccountStore.getAccountId(),
        email: AccountStore.getEmail(),
      }),
      JSON.stringify(deleteResponse),
    );
    FirebaseAnalytic.trackEvent('deletePressed', 'DeleteAccountScreen', {
      acocuntId: AccountStore.getAccountId(),
      email: AccountStore.getEmail(),
    });

    logout();
  };
  return (
    <BackSubheader
      previousRoute={previousRoute}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
      title={Localized.Labels.delete_account}
    >
      <ScrollView style={styles.content}>
        <AVText style={styles.disclaimerTextBold}>
          {Localized.Labels.voluntary_deletion_1}
        </AVText>
        <AVText style={styles.disclaimerText}>
          {Localized.Labels.voluntary_deletion_2}
        </AVText>
      </ScrollView>
      <RoundedButton
        buttonType={ButtonType.action}
        color={Styles.dangerColor}
        onPress={deletePressed}
        accessibilityLabel="Continue with Account Deletion"
        text={Localized.Buttons.delete}
      />
    </BackSubheader>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  disclaimerText: {
    marginBottom: Styles.Spacing.m1,
  },
  disclaimerTextBold: {
    marginBottom: Styles.Spacing.m1,
    fontWeight: 'bold',
  },
});

export default DeleteAccountScreen;
