import {Characteristic} from 'react-native-ble-plx';
import {number} from 'currency-codes';
import {encodeValue, Radix} from '../Encoding';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
const paymentConfigMeta = {
  deviceTypeLength: 16,
  currencyLength: 4,
  ipLength: 8,
  publicKeyLength: 64,
  timeoutLength: 2,
};
export default class PaymentConfigurationCharacteristic {
  characteristic: Characteristic;
  deviceType: string;
  currencyCode: string;
  ipAddress: string;
  publicKey: string;
  timeout: number;

  constructor(characteristic: Characteristic) {
    this.characteristic = characteristic;
  }

  async parsePaymentConfig() {
    const readCharacteristic = await this.characteristic.read();
    const paymentConfigHex = encodeValue(
      readCharacteristic.value,
      'base64',
      'hex',
    );
    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.PaymentConfiguration,
      {
        uuid: this.characteristic.uuid,
        value: paymentConfigHex,
      },
    );
    const deviceTypeHex = paymentConfigHex.substring(
      0,
      paymentConfigMeta.deviceTypeLength,
    );
    const currencyHex = paymentConfigHex.substring(
      paymentConfigMeta.deviceTypeLength,
      paymentConfigMeta.deviceTypeLength + paymentConfigMeta.currencyLength,
    );
    const ipHex = paymentConfigHex.substring(
      paymentConfigMeta.deviceTypeLength + paymentConfigMeta.currencyLength,
      paymentConfigMeta.deviceTypeLength +
        paymentConfigMeta.currencyLength +
        paymentConfigMeta.ipLength,
    );
    const publicKeyHex = paymentConfigHex.substring(
      paymentConfigMeta.deviceTypeLength +
        paymentConfigMeta.currencyLength +
        paymentConfigMeta.ipLength,
      paymentConfigMeta.deviceTypeLength +
        paymentConfigMeta.currencyLength +
        paymentConfigMeta.ipLength +
        paymentConfigMeta.publicKeyLength,
    );
    const timeoutHex = paymentConfigHex.substring(
      paymentConfigHex.length - paymentConfigMeta.timeoutLength,
      paymentConfigHex.length,
    );
    this.deviceType = encodeValue(deviceTypeHex, 'hex', 'ascii');
    this.currencyCode =
      PaymentConfigurationCharacteristic.getCurrencyCode(currencyHex);
    this.ipAddress = ipHex; // Don't think this will even be used for now

    this.publicKey = publicKeyHex;
    this.timeout = parseInt(timeoutHex, Radix.Hex);
  }

  // 0348 => USD
  static getCurrencyCode(currencyHex: string): string {
    const currencyNumber = parseInt(currencyHex, Radix.Hex);
    return number(`${currencyNumber}`).code;
  }
}
