import * as React from 'react';
import {StyleSheet, Image, View, ScrollView} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import moment from 'moment';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import AccountStore from 'src/stores/AccountStore';
import DealRepository, {DealStatus} from 'src/services/aws/DealRepository';
import Events, {PromotionActions} from 'src/logging/Events';
import AVText from '../../elements/AVText';
import Util from 'src/Util';
import ProgressBar from '../../elements/ProgressBar';
import DealHelper from '../../helpers/DealHelper';
import Localized from 'src/constants/AppStrings';
import Deal from 'src/models/Moblico/Deal';
import {ensureHttps} from 'src/services/StringUtils';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type DealDetailScreenProps = WithGlobalizeProps & {
  deal: Deal;
};
type DealDetailScreenState = {
  deal: Deal;
};

class DealDetailScreen extends React.Component<
  DealDetailScreenProps,
  DealDetailScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: DealDetailScreenProps) {
    super(props);
    this.state = {
      deal: DealRepository.getDeal(props.deal.id) ?? props.deal,
    };
    this.claimDeal = this.claimDeal.bind(this);
    this.onDealsChanged = this.onDealsChanged.bind(this);
    this.renderStatusSection = this.renderStatusSection.bind(this);
    this.renderClaimButton = this.renderClaimButton.bind(this);
    this.renderBottom = this.renderBottom.bind(this);
  }

  componentDidMount() {
    DealRepository.addDealListener(this.onDealsChanged);
    Events.Promotion.trackEvent(
      this.state.deal.id.toString(),
      this.state.deal.name,
      PromotionActions.ViewedDeal,
      true,
    );
  }

  componentWillUnmount() {
    DealRepository.removeDealListener(this.onDealsChanged);
  }

  onDealsChanged() {
    const deal = DealRepository.getDeal(this.state.deal.id);

    if (deal) {
      this.setState({
        deal,
      });
    }
  }

  async claimDeal() {
    DealHelper.claimDeal(this.context, this.state.deal.id);
    FirebaseAnalytic.trackEvent('ClaimDeal', 'DealDetailScreen: ClaimDeal', {
      ...this.props,
      ...this.state,
    });
  }

  renderClaimButton() {
    if (this.state.deal.dealStatus === DealStatus.pulled) {
      return (
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.claimDeal}
          text={Localized.Labels.claim}
        />
      );
    }

    return null;
  }

  renderBottom() {
    if (this.state.deal.dealStatus === DealStatus.pulled) {
      return <View style={styles.bottom} />;
    }

    return null;
  }

  renderQuantityStatusSection() {
    const remainingAmount =
      this.state.deal.targetGoalQuantity - this.state.deal.currentGoalQuantity;
    const percentComplete =
      this.state.deal.currentGoalQuantity / this.state.deal.targetGoalQuantity;
    const remaining = 1 - percentComplete;
    let statusText = Localized.Labels.formatString(
      Localized.Labels.x_down_y_to_go,
      this.state.deal.currentGoalQuantity,
      remainingAmount,
    );

    if (this.state.deal.dealStatus === DealStatus.redeemed) {
      statusText = Localized.Labels.goal_achieved;
    }

    return this.renderStatusComponents(
      statusText,
      remainingAmount,
      percentComplete,
      remaining,
    );
  }

  renderAmountStatusSection() {
    const targetGoalAmount = this.state.deal.targetGoalAmount / 100;
    const currentGoalAmount = this.state.deal.currentGoalAmount / 100;
    const remainingAmount = targetGoalAmount - currentGoalAmount;
    const percentComplete = currentGoalAmount / targetGoalAmount || 0;
    const remaining = 1 - percentComplete;
    let statusText = Localized.Labels.formatString(
      Localized.Labels.x_down_y_to_go,
      Util.formatCurrency(
        this.props,
        currentGoalAmount,
        AccountStore.getCurrency(),
      ),
      Util.formatCurrency(
        this.props,
        remainingAmount,
        AccountStore.getCurrency(),
      ),
    );

    if (this.state.deal.dealStatus === DealStatus.redeemed) {
      statusText = Localized.Labels.goal_achieved;
    }

    return this.renderStatusComponents(
      statusText,
      remainingAmount,
      percentComplete,
      remaining,
    );
  }

  renderStatusSection() {
    if (this.state.deal.dealStatus !== DealStatus.pulled) {
      if (this.state.deal.targetGoalQuantity > 0) {
        return this.renderQuantityStatusSection();
      }

      return this.renderAmountStatusSection();
    }

    return null;
  }

  renderStatusComponents(
    statusText,
    remainingAmount,
    percentComplete,
    remaining,
  ) {
    return (
      <View style={styles.statusSection}>
        <AVText
          style={[
            styles.statusText,
            remainingAmount === 0 && styles.successText,
          ]}
        >
          {statusText}
        </AVText>
        <ProgressBar progress={percentComplete} />
      </View>
    );
  }

  render() {
    let uri = this.state.deal.image;

    if (typeof uri === 'string') {
      // force HTTPS
      uri = ensureHttps(uri);
    }

    return (
      <BackSubheader title={this.state.deal.promotionText}>
        <View style={styles.content}>
          <ScrollView>
            <View style={styles.topRow}>
              <Image
                style={styles.image}
                source={{
                  uri,
                }}
              />
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
                style={styles.name}
              >
                {this.state.deal.name}
              </AVText>
            </View>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={styles.description}
            >
              {this.state.deal.description}
            </AVText>
            {this.renderStatusSection()}
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
              style={styles.expiration}
            >
              {`${Localized.Labels.ends} ${moment(
                this.state.deal.endDate,
              ).calendar()}`}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
              style={styles.legalese}
            >
              {this.state.deal.legalese}
            </AVText>
            {this.renderBottom()}
          </ScrollView>
        </View>
        {this.renderClaimButton()}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
    flex: 1,
  },
  bottom: {
    height: Styles.Spacing.m5,
    width: '100%',
  },
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  description: {
    marginBottom: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f1,
  },
  expiration: {
    fontSize: Styles.Fonts.f0,
    textAlign: 'center',
    marginBottom: Styles.Spacing.m3,
  },
  legalese: {
    fontSize: Styles.Fonts.f0,
    textAlign: 'center',
    color: Styles.lightGray,
  },
  name: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    marginLeft: Styles.Spacing.m2,
    flex: 1,
  },
  fromContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  message: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  qrcode: {
    alignItems: 'center',
    marginBottom: Styles.Spacing.m3,
  },
  topRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m3,
  },
  trashContainer: {
    marginBottom: Styles.Spacing.m1,
  },
  image: {
    width: Styles.Spacing.m6,
    height: Styles.Spacing.m6,
  },
  statusText: {
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m2,
    textAlign: 'center',
    marginBottom: Styles.Spacing.m1,
  },
  statusSection: {
    marginBottom: Styles.Spacing.m3,
  },
  statusProgressContainer: {
    height: Styles.Spacing.m2,
    backgroundColor: Styles.lightGray,
    borderRadius: Styles.Spacing.m2,
    flexDirection: 'row',
  },
  successText: {
    color: Styles.positiveColor,
    fontWeight: 'bold',
  },
});
export default withForwardedNavigationParams()(withGlobalize(DealDetailScreen));
