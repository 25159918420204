import FirebaseAnalytic from './FirebaseAnalytic';
type Properties = Record<string, string>;

class Analytics {
  trackEvent(eventName: string, properties?: Properties) {
    FirebaseAnalytic.trackEvent(eventName, 'Analytics', properties);
  }

  trackPageView(screen: string, properties?: Properties) {
    FirebaseAnalytic.trackPageView(screen, properties);
  }
}

export default new Analytics();
