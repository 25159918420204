import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import PurchaseHistoryDetailScreen from 'src/components/screens/account/history/PurchaseHistoryDetailScreen';
import ProductCategorySearchScreen from 'src/components/screens/cart/ProductCategorySearchScreen';
import ReceiptScreen from 'src/components/screens/cart/ReceiptScreen';
import ScanScreen from 'src/components/screens/cart/ScanScreen';
import CreditCardScreen from 'src/components/screens/funding/CreditCardScreen';
import InsufficientCreditScreen from 'src/components/screens/funding/InsufficientCreditScreen';
import MainProductScreen from 'src/components/screens/planogram/MainProductScreen';
import ProductNutritionScreen from 'src/components/screens/planogram/ProductNutritionScreen';
import ContactUsScreen from 'src/components/screens/vending/ContactUsScreen';
import VendingScreen from 'src/components/screens/vending/VendingScreen';
import VendorsExchangeVendingScreen from 'src/components/screens/vending/VendorsExchangeVendingScreen';
import Machines from 'src/components/elements/Machines';
import LocationCodeScreen from 'src/components/screens/LocationCodeScreen';
import PickupTimeScreen from 'src/components/screens/orderAhead/PickupTimeScreen';
import MenuScreen from 'src/components/screens/orderAhead/MenuScreen';
import ProductDetailScreen from 'src/components/screens/orderAhead/ProductDetailScreen';
import CoffeeMachineScreen from 'src/components/screens/cart/coffee/CoffeeMachineScreen';
import PickupLocationScreen from 'src/components/screens/orderAhead/PickupLocationScreen';
import DisclaimerScreen from 'src/components/screens/orderAhead/DisclaimerScreen';
import RecentOrdersScreen from 'src/components/screens/orderAhead/RecentOrdersScreen';
import BunnDispenseScreen from 'src/components/screens/cart/coffee/BunnDispenseScreen';
import ProductUpsellScreen from 'src/components/screens/orderAhead/ProductUpsellScreen';
import ShareReceiptScreen from 'src/components/screens/cart/ShareReceiptScreen';
import OrderNoteScreen from 'src/components/screens/orderAhead/OrderNoteScreen';
import ViewNutritionScreen from 'src/components/screens/orderAhead/ViewNutritionScreen';
import ScanDiscountCodeScreen from 'src/components/screens/cart/ScanDiscountCodeScreen';
import EnterDiscountCodeScreen from 'src/components/screens/cart/EnterDiscountCodeScreen';
const Stack = createStackNavigator();
export default function ShopNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Machines}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen name={AppRoutes.Machines} component={Machines} />
      <Stack.Screen name={AppRoutes.Scan} component={ScanScreen} />
      <Stack.Screen
        name={AppRoutes.CoffeeMachine}
        component={CoffeeMachineScreen}
      />
      <Stack.Screen
        name={AppRoutes.BunnDispense}
        component={BunnDispenseScreen}
      />
      <Stack.Screen
        name={AppRoutes.InsufficientCredit}
        component={InsufficientCreditScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductCategorySearch}
        component={ProductCategorySearchScreen}
      />
      <Stack.Screen name={AppRoutes.Vending} component={VendingScreen} />
      <Stack.Screen
        name={AppRoutes.VendorsExchangeVending}
        component={VendorsExchangeVendingScreen}
      />
      <Stack.Screen name={AppRoutes.Receipt} component={ReceiptScreen} />
      <Stack.Screen name={AppRoutes.ContactUs} component={ContactUsScreen} />
      <Stack.Screen
        name={AppRoutes.MainProduct}
        component={MainProductScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductNutrition}
        component={ProductNutritionScreen}
      />
      <Stack.Screen name={AppRoutes.CreditCard} component={CreditCardScreen} />
      <Stack.Screen
        name={AppRoutes.PurchaseHistoryDetail}
        component={PurchaseHistoryDetailScreen}
      />
      <Stack.Screen
        name={AppRoutes.LocationCode}
        component={LocationCodeScreen}
      />
      <Stack.Screen name={AppRoutes.PickupTime} component={PickupTimeScreen} />
      <Stack.Screen name={AppRoutes.Menu} component={MenuScreen} />
      <Stack.Screen name={AppRoutes.Disclaimer} component={DisclaimerScreen} />
      <Stack.Screen
        name={AppRoutes.RecentOrders}
        component={RecentOrdersScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductDetail}
        component={ProductDetailScreen}
      />
      <Stack.Screen
        name={AppRoutes.ViewNutrition}
        component={ViewNutritionScreen}
      />
      <Stack.Screen
        name={AppRoutes.PickupLocation}
        component={PickupLocationScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductUpsell}
        component={ProductUpsellScreen}
      />
      <Stack.Screen
        name={AppRoutes.ScanDiscountCode}
        component={ScanDiscountCodeScreen}
      />
      <Stack.Screen
        name={AppRoutes.ShareReceipt}
        component={ShareReceiptScreen}
      />
      <Stack.Screen name={AppRoutes.OrderNote} component={OrderNoteScreen} />
      <Stack.Screen
        name={AppRoutes.EnterDiscountCode}
        component={EnterDiscountCodeScreen}
      />
    </Stack.Navigator>
  );
}
