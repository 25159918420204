import EventEmitter from 'events';
import Events from 'src/logging/Events';
import Deal from 'src/models/Moblico/Deal';
import CrashlyticsEvents from 'src/logging/Crashlytics';
export const DealStatus = {
  accepted: 8,
  redeemed: 6,
  pulled: 4,
};

class DealRepository extends EventEmitter {
  DEALS_EVENT = 'deals';
  deals: Array<Deal> = [];

  addDealListener(callback: () => void | Promise<void>) {
    this.addListener(this.DEALS_EVENT, callback);
  }

  removeDealListener(callback: () => void | Promise<void>) {
    this.removeListener(this.DEALS_EVENT, callback);
  }

  addDeals(deals: Array<Deal>) {
    this.deals = deals;
    this.emit(this.DEALS_EVENT);
  }

  acceptDeal(dealId: number): boolean {
    try {
      const existingDeal = this.deals.find((deal) => deal.id === dealId);

      if (existingDeal) {
        existingDeal.dealStatus = DealStatus.accepted;
        this.emit(this.DEALS_EVENT);
      }

      return true;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'DealRepository:UpdateDeal',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'DealRepository:UpdateDeal',
        e.message ? e.message : e.toString(),
      );
    }

    return false;
  }

  getDeals(): Array<Deal> {
    return this.deals;
  }

  getDeal(dealId: number): Deal | null | undefined {
    return this.deals.find((deal) => deal.id === dealId);
  }

  clearDeals() {
    this.deals = [];
  }
}

export default new DealRepository();
