import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import {Balance} from 'src/models/Balance';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import Person from '../../img/svg/Person';
import Reward from '../../img/svg/Reward';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import UIManager from 'src/components/elements/ui/UIManager';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';

type BalanceItemProps = {
  balanceItem: Balance;
};

const BalanceItem = ({balanceItem}: BalanceItemProps) => {
  const getBalanceName = () => {
    const {type} = balanceItem;
    switch (type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe:
        return Localized.Labels.my_account;
      case BalanceTypes.RollOver:
      case BalanceTypes.TopOff:
        return Localized.Labels.subsidy;
      default:
        return type;
    }
  };

  const getBalanceAmount = () => {
    return balanceItem.balance;
  };

  const getBalanceIcon = () => {
    switch (balanceItem.type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe:
        return (
          <View
            accessible={true}
            accessibilityLabel={`${Localized.Labels.user_icon}, `}
            aria-label={`${Localized.Labels.user_icon}, `}
            accessibilityRole="image"
          >
            <Person size={Styles.Heights.h3} />
          </View>
        );
      case BalanceTypes.RollOver:
      case BalanceTypes.TopOff:
        return (
          <View
            accessible={true}
            accessibilityLabel={`${Localized.Labels.subsidy_icon}, `}
            aria-label={`${Localized.Labels.subsidy_icon}, `}
            accessibilityRole="image"
          >
            <Reward size={Styles.Heights.h3} />
          </View>
        );
      default:
        return null;
    }
  };

  const fund = () => {
    NavActions.navigate(AppRoutes.MyModal, {transDate: Util.getCurrentDate()});
  };

  const getBalanceButton = () => {
    if (UIManager.shouldHideFunding()) {
      return null;
    }
    switch (balanceItem.type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe:
        return (
          <RoundedButton
            buttonType={ButtonType.outline}
            containerStyle={styles.button}
            textStyle={styles.fundButtonText}
            accessible={true}
            accessibilityRole="button"
            accessibilityLabel={Localized.Labels.fund_account}
            aria-label={Localized.Labels.fund_account}
            role="button"
            onPress={fund}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            text={Localized.Labels.fund}
          />
        );

      default:
        return null;
    }
  };

  const icon = getBalanceIcon();
  const name = getBalanceName();
  const amount = getBalanceAmount();

  return (
    <View>
      <View style={styles.container}>
        {icon}
        <View style={styles.balanceContainer}>
          <AVFormattedCurrency
            style={[styles.balanceAmountText]}
            value={amount}
            accessible={true}
            accessibilityLabel={`${name}: ${Util.formatCurrency(
              null,
              amount,
              AccountStore.getCurrency(),
            )}`}
            aria-label={`${name}: ${Util.formatCurrency(
              null,
              amount,
              AccountStore.getCurrency(),
            )}`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
            currency={balanceItem.currency}
          />
          <Text
            accessible={false}
            accessibilityLabel={name}
            aria-label={name}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={styles.balanceTypeText}
          >
            {name}
          </Text>
        </View>
      </View>
      {getBalanceButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  balanceContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingHorizontal: Styles.Spacing.m1,
  },
  balanceAmountText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'left',
    flex: 1,
  },
  balanceTypeText: {
    color: Styles.lightGray,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'left',
    flex: 1,
  },
  button: {
    right: Styles.Spacing.m3,
    position: 'absolute',
    top: Styles.Spacing.m2,
  },
  fundButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default BalanceItem;
