import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import MessageTypes from 'src/constants/MessageTypes';
import Util from 'src/Util';
import BackSubheader from '../../elements/BackSubheader';
import HeaderButton from '../../elements/HeaderButton';
import ShadowContainer from '../../elements/ShadowContainer';
import Styles from '../../Styles';
import type {MessageType} from 'src/types/MessageType';
import ActionsFactory from 'src/actions/ActionsFactory';
import Localized from 'src/constants/AppStrings';
import {confirm} from '../../helpers/AlertHelper';
type MessageDetailScreenProps = {
  message: MessageType;
  renderIcon: (message: MessageType) => React.ReactNode;
};
import AVText from '../../elements/AVText';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

class MessageDetailScreen extends React.Component<MessageDetailScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: MessageDetailScreenProps) {
    super(props);
    this.renderType = this.renderType.bind(this);
    this.deleteClicked = this.deleteClicked.bind(this);
  }

  deleteClicked() {
    confirm(Localized.Labels.confirm_delete_message, () => {
      ActionsFactory.getAccountActions().deleteMessage(this.props.message);
      NavActions.pop();
    });
    FirebaseAnalytic.trackEvent('delete', 'MessageDetailScreen: Delete', {
      ...this.props,
      ...this.state,
    });
  }

  renderType() {
    if (this.props.message.type === MessageTypes.qrcode) {
      return (
        <View style={styles.qrcode}>
          <QRCode value={this.props.message.value} size={Styles.Spacing.m6} />
        </View>
      );
    }

    return null;
  }

  render() {
    return (
      <BackSubheader
        title={this.props.message.subject}
        rightView={
          <HeaderButton
            type="Delete"
            label={Localized.Buttons.delete}
            onPress={this.deleteClicked}
          />
        }
      >
        <ShadowContainer style={styles.content}>
          <>
            <View style={styles.topRow}>
              <View style={styles.fromContainer}>
                {this.props.renderIcon(this.props.message)}
                <AVText style={styles.from}>{this.props.message.from}</AVText>
              </View>
              <AVText style={styles.date}>
                {Util.formatDate(this.props.message.date)}
              </AVText>
            </View>
            {this.renderType()}
            <AVText style={styles.message}>{this.props.message.message}</AVText>
          </>
        </ShadowContainer>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  from: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    marginLeft: Styles.Spacing.m2,
  },
  fromContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  message: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  qrcode: {
    alignItems: 'center',
    marginBottom: Styles.Spacing.m3,
  },
  topRow: {
    alignItems: 'center',
    borderColor: Styles.lightGray,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m3,
  },
});
export default withForwardedNavigationParams<MessageDetailScreenProps>()(
  MessageDetailScreen,
);
