import moment from 'moment';
import type {Deal as ServerDeal} from 'src/types/serverTypes/Moblico';
export default class Deal {
  id: number;
  description: string;
  endDate: moment.Moment;
  name: string;
  currentGoalAmount: number;
  currentGoalQuantity: number;
  targetGoalAmount: number;
  targetGoalQuantity: number;
  dealStatus: number;
  image: string | null;
  legalese: string;
  redeemedCount: number;
  numberOfUsesPerCode: number;
  promotionText: string;

  constructor(serverDeal: ServerDeal) {
    this.id = serverDeal.id;
    this.description = serverDeal.description;
    this.endDate = moment(serverDeal.endDate);
    this.name = serverDeal.name;
    this.currentGoalAmount = serverDeal.currentGoalAmount;
    this.currentGoalQuantity = serverDeal.currentGoalQuantity;
    this.targetGoalAmount = serverDeal.targetGoalAmount;
    this.targetGoalQuantity = serverDeal.targetGoalQuantity;
    this.dealStatus = serverDeal.dealStatus;
    this.image = serverDeal.image?.url;
    this.legalese = serverDeal.legalese;
    this.redeemedCount = serverDeal.redeemedCount;
    this.numberOfUsesPerCode = serverDeal.numberOfUsesPerCode;
    this.promotionText = serverDeal.promoText;
  }
}
