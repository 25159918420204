import React, {useState} from 'react';
import {View, StyleSheet, FlatList} from 'react-native';
import AVTouchableOpacity from './../AVTouchableOpacity';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import StickerComponent from './StickerComponent';
import StickerId from 'src/constants/sendSnack/StickerId';

const StickerList: React.FC<{
  size: number;
  onStickerSelection: (arg0: number) => void;
}> = (props) => {
  const [selected, setSelected] = useState();

  const renderItem = ({item}) => {
    const {size, onStickerSelection} = props;
    const onClick = (id) => {
      setSelected(id);
      onStickerSelection(id);
    };
    return (
      <AVTouchableOpacity
        onPress={() => onClick(item)}
        accessible={true}
        accessibilityRole="imagebutton"
        accessibilityLabel="Snack Stickers"
        aria-label="Send stickers"
      >
        <View style={styles.stickerContainer}>
          <StickerComponent id={item} imageSize={size} />
        </View>
        {selected === item && (
          <FontAwesome5Pro
            style={styles.checkCircle}
            name="check-circle"
            color={Styles.balanceColor}
            size={Styles.Fonts.f2}
            solid
          />
        )}
      </AVTouchableOpacity>
    );
  };

  return (
    <FlatList
      horizontal
      style={styles.stickerListContainer}
      data={Object.values(StickerId)}
      keyExtractor={(item) => item.toString()}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  stickerContainer: {
    marginVertical: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m1,
  },
  stickerListContainer: {
    width: '100%',
  },
  checkCircle: {
    position: 'absolute',
    top: Styles.Spacing.m1,
    right: Styles.Spacing.m0,
  },
});

export default StickerList;
