import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import HttpClient from 'src/api/HttpClient';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import {getSnackBlockedUsers} from './getSnackBlockedUsers';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type UnblockSnackUserParams = {
  blockedAccountId: string;
};
export const unblockSnackUser = createAsyncThunk<
  void,
  UnblockSnackUserParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'snack/unblockSnackUser',
  async ({blockedAccountId}, {dispatch, getState}) => {
    dispatch(showSpinner());
    let unblockResponse;
    try {
      const accountId = getState().account.account.id;

      unblockResponse = await PlatformApi.unblockSnackUser(
        accountId,
        blockedAccountId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'UnblockSnackUser',
        JSON.stringify({blockedAccountId, accountId}),
        JSON.stringify(unblockResponse),
      );
      dispatch(getSnackBlockedUsers());
    } catch (err) {
      const errorCode = HttpClient.parseJsonSafe(
        err?.statusCode ?? err.statusCode,
      );

      if (errorCode === 404) {
        alertError(Localized.Errors.unable_to_save_changes);
      } else {
        CrashlyticsEvents.log(
          'Exception',
          'snack:unblockSnackUser',
          err.message ? err.message : err.toString(),
          unblockResponse,
        );
        Events.Error.trackEvent(
          'Exception',
          'snack:unblockSnackUser',
          err.message ? err.message : err.toString(),
        );
      }
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'UnblockSnackUser',
        JSON.stringify({blockedAccountId}),
        JSON.stringify(err),
      );
    } finally {
      dispatch(hideSpinner());
    }
  },
);
