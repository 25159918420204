import * as React from 'react';
import {Platform, StyleSheet, Text, TouchableOpacity} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
const color = Platform.select({
  web: Styles.lightGray,
  default: Styles.white,
});
type Props = {
  onPress: () => void;
};
export default function RecentOrdersButton(props: Props) {
  return (
    <TouchableOpacity
      accessible={true}
      accessibilityLabel={Localized.Buttons.recent_orders}
      accessibilityRole="button"
      accessibilityHint="Double tap to navigate to the recent orders screen"
      accessibilityState={{disabled: false}}
      aria-label={Localized.Buttons.recent_orders}
      role="button"
      onPress={props.onPress}
      style={styles.recentOrderButton}
    >
      <FontAwesome5Pro
        aria-hidden={true}
        accessibilityElementsHidden={true}
        importantForAccessibility="no-hide-descendants"
        name="calendar-alt"
        color={color}
        size={Styles.Fonts.f2 * 0.8}
        light
        style={styles.recentOrderIcon}
      />
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={styles.recentOrderText}
      >
        {Localized.Buttons.recent_orders}
      </Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  recentOrderButton: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: Styles.Heights.touchTargetHeight2,
    maxWidth: Styles.Heights.splashIconHeight,
  },
  recentOrderText: {
    marginTop: Platform.OS === 'web' ? Styles.Spacing.m0 : Styles.Spacing.m3,
    color,
    marginLeft: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f2,
    lineHeight: Styles.Fonts.f3,
  },
  recentOrderIcon: {
    alignSelf: 'center',
    marginTop: Platform.OS === 'web' ? Styles.Spacing.m0 : Styles.Spacing.m3,
  },
});
