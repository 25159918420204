import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  PixelRatio,
  StyleProp,
  ViewStyle,
} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import EmptyCartSvg from '../../img/svg/EmptyCart';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../RoundedButton';
import AVText from '../AVText';

type EmptyCartProps = {
  style: StyleProp<ViewStyle>;
  orderAhead: boolean;
  onAddItem: () => void;
};

const EmptyCart = (props: EmptyCartProps) => (
  <View style={props.style}>
    {props.orderAhead ? (
      <View style={styles.container}>
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={1}
          dashColor="#999"
          style={[
            {
              marginTop: -1 * Styles.Spacing.m1,
            },
          ]}
        />
        <View style={styles.mainContent}>
          <Text
            accessible={true}
            accessibilityLabel={Localized.Labels.your_order_is_empty}
            aria-label={Localized.Labels.your_order_is_empty}
            accessibilityRole="text"
            style={styles.emptyText}
          >
            {Localized.Labels.your_order_is_empty}
          </Text>
        </View>
        <RoundedButton
          accessible={true}
          accessibilityLabel={Localized.Buttons.add_items}
          accessibilityRole="button"
          aria-label={Localized.Buttons.add_items}
          role="button"
          text={Localized.Buttons.add_items}
          buttonType={ButtonType.normal}
          onPress={props.onAddItem}
        />
      </View>
    ) : (
      <>
        <AVText
          accessible={true}
          accessibilityLabel={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          aria-label={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          style={styles.label}
        >
          {Localized.Labels.barcode_refocus_and_find_non_barcoded_items}
        </AVText>
        <EmptyCartSvg width="100%" height="100%" />
      </>
    )}
  </View>
);

const styles = StyleSheet.create({
  emptyText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  container: {
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
  mainContent: {
    flexDirection: 'row',
    marginVertical: Styles.Spacing.m2,
  },
  label: {
    margin: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f0,
    textAlign: 'center',
    color: Styles.darkColor,
  },
});

export default EmptyCart;
