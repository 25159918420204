import * as React from 'react';
import moment from 'moment';
import MenuService from 'src/services/MenuService';
import TransactionStore from 'src/stores/TransactionStore';
import TransactionActions from 'src/actions/TransactionActions';
import type {TimeSlotType} from 'src/types/Menu';
import TimeUtils from 'src/services/TimeUtils';

type PickupTimeTimerProps = {
  interval: number;
  leadTime: number;
  onCartIsInvalid: () => void;
};

class PickupTimeTimer extends React.Component<PickupTimeTimerProps> {
  pickupTimeout: any;
  pickupTime?: TimeSlotType;

  constructor(props: PickupTimeTimerProps) {
    super(props);
    this.setPickupTimeout = this.setPickupTimeout.bind(this);
    this.updatePickupTime = this.updatePickupTime.bind(this);
    this.clearPickupTimeout = this.clearPickupTimeout.bind(this);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
  }

  componentDidMount() {
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
    this.setPickupTimeout();
  }

  componentWillUnmount() {
    this.clearPickupTimeout();
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const pickupTime = TransactionStore.getPickupTime();

    if (!pickupTime) {
      this.clearPickupTimeout();
    } else if (this.pickupTime !== pickupTime) {
      this.setPickupTimeout(pickupTime);
    }
  }

  clearPickupTimeout() {
    if (this.pickupTimeout) {
      clearTimeout(this.pickupTimeout);
      this.pickupTimeout = null;
    }
  }

  setPickupTimeout(
    pickupTime: TimeSlotType = TransactionStore.getPickupTime(),
  ) {
    this.pickupTime = pickupTime;
    this.clearPickupTimeout();
    const now = MenuService.getCurrentLocationTime();
    const expireTime = moment(pickupTime?.time, 'LT').subtract(
      pickupTime?.leadTime,
      'minutes',
    );
    if (
      this.pickupTime &&
      TimeUtils.isDateGreaterThanToday(this.pickupTime.date)
    ) {
      expireTime.add(1, 'day');
    }
    const duration = moment.duration(expireTime.diff(now));
    const durationMilliseconds = duration.as('milliseconds');

    if (durationMilliseconds > 0) {
      this.pickupTimeout = setTimeout(() => {
        this.updatePickupTime();
      }, durationMilliseconds);
    } else {
      this.updatePickupTime();
    }
  }

  updatePickupTime() {
    const pickupTime = MenuService.getNextAvailableTime(
      TransactionStore.getAvailableTimesFromServer(),
      TransactionStore.getScanCodes(),
      this.props.leadTime,
      this.props.interval,
    );

    if (pickupTime) {
      TransactionActions.pickupTimeUpdated(pickupTime);
      this.setPickupTimeout(pickupTime);
    } else {
      this.props.onCartIsInvalid();
    }
  }

  render() {
    return null;
  }
}

export default PickupTimeTimer;
