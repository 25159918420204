import {commands} from 'src/constants/BluetoothCommands';
import {Radix} from '../Encoding';
import {PACKET_PREFIX, PACKET_SUFFIX} from './PacketConstants';
export type Packet = {
  packetNum: number;
  payload: string;
};
const PACKET_SIZE = 480;
const FINAL_PACKET_PAYLOAD = '00';
export default class PacketGenerator {
  static generatePackets(command: string, hexMessage: string): Packet[] {
    const message = command + hexMessage;

    if (message.length <= PACKET_SIZE) {
      const fullMessage =
        PACKET_PREFIX +
        this.getMessageLengthHex(message) +
        message +
        PACKET_SUFFIX;
      return [
        {
          packetNum: 0,
          payload: fullMessage,
        },
      ];
    }

    // Add + 1 for the final packet
    const numPackets = Math.ceil(message.length / PACKET_SIZE) + 1;
    const packets = [];

    for (let i = 0; i < numPackets; i++) {
      packets.push(this.getPacket(message, i, numPackets));
    }

    packets.push(this.getFinalPacket(numPackets));
    return packets;
  }

  static getFinalPacket(numPackets: number): Packet {
    const payload = this.buildPacketPayload(
      FINAL_PACKET_PAYLOAD,
      numPackets,
      numPackets,
    );
    return {
      packetNum: numPackets,
      payload,
    };
  }

  static buildPacketPayload(
    payload: string,
    sequence: number,
    total: number,
  ): string {
    const fullPayload =
      commands.LONG_PACKET_COMMAND +
      this.getBCDHex(sequence) +
      this.getBCDHex(total) +
      payload;
    return (
      PACKET_PREFIX +
      PacketGenerator.getMessageLengthHex(fullPayload) +
      fullPayload +
      PACKET_SUFFIX
    );
  }

  static getPacket(
    message: string,
    packetNum: number,
    numPackets: number,
  ): Packet {
    const start = packetNum * PACKET_SIZE;
    let end = start + PACKET_SIZE;

    if (end > message.length) {
      end = undefined;
    }

    const messageSnippet = message.substring(start, end);
    const packetPayload = PacketGenerator.buildPacketPayload(
      messageSnippet,
      packetNum,
      numPackets,
    );
    return {
      packetNum,
      payload: packetPayload,
    };
  }

  static getMessageLengthHex(message: string) {
    // divided by two because two hex-string characters
    // equals one byte
    return (message.length / 2).toString(Radix.Hex).padStart(2, '0');
  }

  static getBCDHex(num: number) {
    const numString = num.toString();
    return numString.padStart(4, '0');
  }
}
