export class BranchApi {
  async fetchReceiptLink(
    transactionId: string,
    transactionDate: string,
    accountId: string,
    key: string,
    fallbackUrl: string,
  ) {
    const data: any = {
      branch_key: key,
      channel: 'receipt',
      campaign: transactionId,
      data: {
        $fallback_url: `${fallbackUrl}/receipt?transactionId=${transactionId}&transactionDate=${transactionDate}&marketAccount=${accountId}`,
        transactionId,
        marketAccount: accountId,
      },
    };

    try {
      const result = await fetch('https://api2.branch.io/v1/url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const resultJson = await result?.json();
      return resultJson?.url;
    } catch (e) {
      return null;
    }
  }
}
export default new BranchApi();
