import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  isAllWhite?: boolean;
}; // tslint:disable

export default class Logo extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        id="Capa_1"
        x="0px"
        y="0px"
        enable-background="new 0 0 112 112"
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 112 112"
      >
        <Defs>
          <ClipPath id="_clipPath_5mE7jPmEsdQS4E71P4pu7nf9fIAkOXJr">
            <Rect width="112" height="112" />
          </ClipPath>
        </Defs>
        <G clip-path="url(#_clipPath_5mE7jPmEsdQS4E71P4pu7nf9fIAkOXJr)">
          <G id="Group">
            <G id="Group">
              <Path
                d=" M 54.638 35.038 C 63.038 23.838 80.538 14.038 98.238 20.538 C 109.038 24.438 100.538 44.538 94.538 41.238 C 84.238 35.538 68.538 35.938 59.238 42.038 C 57.638 42.938 51.138 39.638 54.638 35.038 Z "
                fillRule="evenodd"
                fill="rgb(255,136,0)"
              />
              <Path
                d=" M 56.738 76.438 C 48.338 87.638 30.838 97.438 13.138 90.938 C 2.438 87.038 10.838 66.938 16.938 70.238 C 27.238 75.938 42.938 75.538 52.238 69.438 C 53.738 68.538 60.238 71.738 56.738 76.438 Z "
                fillRule="evenodd"
                fill="rgb(255,136,0)"
              />
              <Path
                d=" M 69.538 40.338 C 83.338 38.338 102.738 43.738 110.638 60.838 C 115.438 71.238 95.338 79.438 93.338 72.838 C 90.038 61.538 78.638 50.738 67.838 48.438 C 66.138 48.038 63.838 41.138 69.538 40.338 Z "
                fillRule="evenodd"
                fill="rgb(153,204,51)"
              />
              <Path
                d=" M 41.838 71.138 C 28.038 73.138 8.638 67.738 0.738 50.638 C -4.062 40.238 16.038 32.038 18.038 38.638 C 21.238 49.838 32.638 60.738 43.438 62.938 C 45.238 63.338 47.538 70.238 41.838 71.138 Z "
                fillRule="evenodd"
                fill="rgb(153,204,51)"
              />
              <Path
                d=" M 76.338 54.638 C 87.538 63.038 97.338 80.538 90.838 98.238 C 86.938 109.038 66.838 100.538 70.138 94.538 C 75.838 84.238 75.438 68.538 69.338 59.238 C 68.538 57.638 71.738 51.138 76.338 54.638 Z "
                fillRule="evenodd"
                fill="rgb(255,136,0)"
              />
              <Path
                d=" M 35.038 56.738 C 23.838 48.438 13.938 30.938 20.438 13.238 C 24.338 2.438 44.438 10.938 41.138 16.938 C 35.438 27.238 35.838 42.938 41.938 52.238 C 42.838 53.738 39.638 60.238 35.038 56.738 Z "
                fillRule="evenodd"
                fill="rgb(255,136,0)"
              />
              <Path
                d=" M 71.138 69.538 C 73.138 83.338 67.738 102.738 50.638 110.638 C 40.238 115.438 32.038 95.338 38.638 93.338 C 49.938 90.038 60.738 78.638 63.038 67.838 C 63.338 66.138 70.238 63.838 71.138 69.538 Z "
                fillRule="evenodd"
                fill="rgb(153,204,51)"
              />
              <Path
                d=" M 40.238 41.838 C 38.238 28.038 43.638 8.638 60.738 0.738 C 71.138 -4.062 79.338 16.038 72.738 18.038 C 61.538 21.238 50.638 32.638 48.438 43.438 C 48.038 45.238 41.138 47.538 40.238 41.838 Z "
                fillRule="evenodd"
                fill="rgb(153,204,51)"
              />
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
