import * as React from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import NavActions from 'src/actions/NavActions';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type OrderNoteScreenProps = {
  note: string;
  onNoteChange: (note: string) => void;
};
type OrderNoteScreenState = {
  note: string;
  count: number;
};
const FREE_TEXT_MAX_LENGTH = 80;

class OrderNoteScreen extends React.Component<
  OrderNoteScreenProps,
  OrderNoteScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      note: props.note,
      count: props.note ? props.note.length : 0,
    };
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSave() {
    FirebaseAnalytic.trackEvent('onSave', 'OrderNoteScreen', {
      ...this.props,
      ...this.state,
    });

    this.props.onNoteChange(this.state.note);
    NavActions.pop();
  }

  onChange(note: string) {
    FirebaseAnalytic.trackEvent('onChange', 'OrderNoteScreen', {
      ...this.props,
      ...this.state,
      note,
    });

    this.setState({
      note,
      count: note.length,
    });
  }

  render() {
    return (
      <BackSubheader
        title={Localized.Buttons.special_instructions}
        rightView={
          <AVText style={styles.label}>
            {`${this.state.count}/${FREE_TEXT_MAX_LENGTH}`}
          </AVText>
        }
      >
        <View style={styles.content}>
          <TextInput
            style={styles.input}
            value={this.state.note}
            placeholder={Localized.Labels.enter_special_instructions}
            multiline={true}
            onChangeText={this.onChange}
            maxLength={FREE_TEXT_MAX_LENGTH}
          />
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.onSave}
          accessibilityLabel="Save"
          text={Localized.Buttons.save}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  input: {
    flex: 1,
    padding: Styles.Spacing.m2,
  },
  label: {
    fontSize: Styles.Fonts.f1,
  },
});
export default withForwardedNavigationParams<OrderNoteScreenProps>()(
  OrderNoteScreen,
);
