import React from 'react';
import NBDropdown from './NBDropdown';
import Localized from 'src/constants/AppStrings';
import {StyleProp, ViewStyle} from 'react-native';
import {CountryArray} from 'src/constants/Countries';

type CountryDropdownProps = {
  style?: StyleProp<ViewStyle>;
  selectedValue: string;
  accessibilityValue?: {[key: string]: string};
  onSelect: (value: string) => void;
  onVisibilityChange: (visible: boolean) => void;
};

const CountryDropdown = (props: CountryDropdownProps) => {
  const countryOptions: Array<{text: string; value: string}> = CountryArray.map(
    (country) => ({
      value: country.code3,
      text: country.label,
    }),
  );
  return (
    <NBDropdown
      accessible={true}
      accessibilityLabel={Localized.Labels.country}
      accessibilityRole="menu"
      accessibilityValue={{text: props.selectedValue}}
      aria-label={Localized.Labels.country}
      role="menu"
      aria-valuetext={props.selectedValue}
      label={Localized.Labels.country}
      selectedValue={props.selectedValue}
      style={props.style}
      onSelect={props.onSelect}
      onVisibilityChange={props.onVisibilityChange}
      options={countryOptions}
    />
  );
};

export default CountryDropdown;
