import React from 'react';
import {Platform, StyleSheet} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import NavActions from 'src/actions/NavActions';
import Styles from '../Styles';
import Header from './Header';
import AVTouchableOpacity from './AVTouchableOpacity';

type BackSubheaderProps = {
  onBackSelect?: () => void | Promise<void>;
  style?: any;
  title: any;
  popToTop?: boolean;
  textStyle?: any;
  pop?: boolean;
  rightView?: React.ReactNode;
  bottomView?: React.ReactNode;
  children?: React.ReactNode;
  previousRoute?: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
};

const iconColor = Platform.select({
  web: Styles.darkColor,
  default: Styles.white,
});

const BackSubheader = (props: BackSubheaderProps) => {
  const onBackSelect = () => {
    if (props.pop) {
      if (props.popToTop) {
        NavActions.popToTop();
      } else {
        NavActions.pop();
      }
    }

    if (props.onBackSelect) {
      props.onBackSelect();
    }
  };

  return (
    <Header
      title={props.title}
      rightView={props.rightView}
      onTitlePress={onBackSelect}
      style={props.style}
      leftView={
        <AVTouchableOpacity
          testID="backButton"
          style={styles.close}
          accessibilityHint={props?.accessibilityHint}
          accessibilityLabel={props?.accessibilityLabel}
          accessibilityRole={'button'}
          onPress={onBackSelect}
        >
          <FontAwesome5Pro
            name="chevron-left"
            color={iconColor}
            size={Styles.Fonts.f2}
            style={styles.backIcon}
            light
          />
        </AVTouchableOpacity>
      }
      bottomView={props.bottomView}
    >
      {props.children}
    </Header>
  );
};

BackSubheader.defaultProps = {
  popToTop: false,
  pop: true,
};

const styles = StyleSheet.create({
  backIcon: {
    marginVertical: Styles.Spacing.m2,
  },
  close: {
    alignItems: Platform.OS === 'web' ? 'flex-end' : 'flex-start',
    justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start',
    paddingLeft: Styles.Spacing.m1,
    paddingTop:
      Platform.OS === 'web' ? Styles.Spacing.m1 / 2 : Styles.Spacing.m1,
    height:
      Platform.OS === 'web' ? undefined : Styles.Heights.touchTargetHeight2,
    width:
      Platform.OS === 'web' ? undefined : Styles.Heights.touchTargetHeight2,
  },
});

export default BackSubheader;
