import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  PermissionsAndroid,
  Platform,
  FlatList,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import withIsConnected from '../../hoc/withIsConnected';
import BaseScreen from '../BaseScreen';
import AVText from '../../elements/AVText';
import Styles from '../../Styles';
import NavActions from 'src/actions/NavActions';
import SearchField from '../../elements/cart/SearchField';
import RequirePermission from '../../elements/RequirePermission';
import Contacts from 'react-native-contacts';
import Events from 'src/logging/Events';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ContactItem = {
  firstName: string;
  lastName: string;
  email: string;
};

type ChooseContactScreenProps = {
  onContactSelect: (arg0: string) => void;
};

type ChooseContactScreenState = {
  loading: boolean;
  contacts: Array<ContactItem>;
  filteredContacts: Array<ContactItem>;
  searchText: string;
};

class ChooseContactScreen extends React.Component<
  ChooseContactScreenProps,
  ChooseContactScreenState
> {
  static contextType = ScreenContext;

  constructor(props: ChooseContactScreenProps) {
    super(props);
    this.renderContactItem = this.renderContactItem.bind(this);
    this.getKeyForListItem = this.getKeyForListItem.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
    this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
    this.state = {
      loading: false,
      contacts: [],
      filteredContacts: [],
      searchText: '',
    };
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
    });
    if (Platform.OS === 'ios') {
      this.loadContacts();
    }
  }

  onPermissionChange = (granted: string) => {
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      this.loadContacts();
    }
  };

  async loadContacts() {
    const contactsArray: Array<ContactItem> = [];

    try {
      const contacts = await Contacts.getAll();
      contacts.map((contact) => {
        contact.emailAddresses?.forEach((emailAddress) => {
          contactsArray.push({
            firstName: contact.givenName,
            lastName: contact.familyName,
            email: emailAddress.email,
          });
        });
      });
      contactsArray.sort(function (a, b) {
        const nameA = a.firstName.toLowerCase();
        const nameB = b.firstName.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      this.setState(
        {
          contacts: contactsArray,
          loading: false,
        },
        () => {
          FirebaseAnalytic.trackEvent('loadContacts', 'ChooseContactScreen', {
            ...this.props,
            ...this.state,
          });
        },
      );
    } catch (err) {
      this.setState({
        loading: false,
      });
      CrashlyticsEvents.log(
        'Exception',
        'ChooseContactScreen:loadContacts',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ChooseContactScreen:loadContacts',
        err.message ? err.message : err.toString(),
      );
    }

    Contacts.checkPermission();
  }

  getKeyForListItem(item) {
    return item.email;
  }

  onClickContact(email) {
    FirebaseAnalytic.trackEvent('onClickContact', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.props.onContactSelect(email);
    NavActions.pop();
  }

  onSearchTextChanged(value) {
    const contacts = this.state.contacts;
    const searchText = value.trim().toLowerCase();
    const filteredContacts = contacts.filter((contact) => {
      return (
        `${contact.firstName} ${contact.lastName}`
          .toLowerCase()
          .startsWith(searchText) ||
        contact.lastName.toLowerCase().startsWith(searchText)
      );
    });
    this.setState(
      {
        searchText: value,
        filteredContacts,
      },
      () => {
        FirebaseAnalytic.trackEvent(
          'onSearchTextChanged',
          'ChooseContactScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
    );
  }

  renderContactItem({item}) {
    return (
      <TouchableOpacity onPress={() => this.onClickContact(item.email)}>
        <View style={styles.contactItemContainer}>
          <AVText style={styles.nameTextStyle}>
            {item.firstName} {item.lastName}
          </AVText>
          <AVText style={styles.emailTextStyle}>{item.email}</AVText>
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <BaseScreen title={Localized.Labels.choose_contact}>
        <View style={styles.content}>
          <SearchField
            onChangeText={this.onSearchTextChanged}
            strings={Localized}
            value={this.state.searchText}
          />
          <RequirePermission
            permission={PermissionsAndroid.PERMISSIONS.READ_CONTACTS}
            onPermissionChange={this.onPermissionChange}
          />
          <FlatList
            refreshing={false}
            data={
              this.state.searchText === ''
                ? this.state.contacts
                : this.state.filteredContacts
            }
            renderItem={this.renderContactItem}
            keyExtractor={this.getKeyForListItem}
          />
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  contactItemContainer: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  nameTextStyle: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    marginHorizontal: Styles.Spacing.m3,
  },
  emailTextStyle: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    marginHorizontal: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m1,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(ChooseContactScreen),
);
