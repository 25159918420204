import {EventEmitter} from 'events';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import type {DispatchAction} from 'src/dispatchers/DispatchTypes';
import TransactionConstants from 'src/constants/TransactionConstants';
import AccountConstants from 'src/constants/AccountConstants';
import type {PickupLocation, LocationType} from 'src/types/Location';
import type {PreparationMethodDefaults} from 'src/types/PreparationMethods';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import type {TransactionDetail, CartItem} from 'src/types/TransactionDetail';
import type {TimeSlotType} from 'src/types/Menu';

const PICKUP_LOCATION_CHANGED = 'pickupLocationChanged';
const PICKUP_TIME_CHANGED = 'pickupTimeChanged';
const AUTHORIZE = 'authorize';
const CHANGE = 'change';
export const PICKUP_LOCATIONS_KEYWORD = '**pickup location';

class TransactionStore {
  _emitter: EventEmitter = new EventEmitter();
  _dispatchToken: number;
  _authorizeResult: any = {};
  _authorizeError: any = {};
  _transaction: TransactionDetail | null = null;
  _transactionItems?: Array<CartItem> | null;
  _pickupTime?: TimeSlotType | null;
  _afterTaxPromoApplied = false;
  _pickupLocation: PickupLocation | null = null;
  _availableTimesFromServer: TimeSlotType[] | null = null;
  _refreshAfterTaxPickTimeChanged = false;

  constructor() {
    this._dispatchToken = AppDispatcher.register((action: DispatchAction) => {
      if (action.actionType === TransactionConstants.AUTHORIZE) {
        this._authorizeResult = action.data.response;

        this._emitAuthorize();
      }

      if (action.actionType === TransactionConstants.AUTHORIZE_ERROR) {
        this._authorizeError = action.data.error;

        this._emitAuthorize();
      }

      if (action.actionType === TransactionConstants.TEST_TRANSACTION) {
        this._authorizeResult = {
          AuthResult: {
            TransactionId: action.data.transactionId,
          },
        };

        this._emitAuthorize();
      }

      if (action.actionType === TransactionConstants.SHOPPING_CART_UPDATED) {
        if (action.data.response.Status === 'OK') {
          this._transaction = action.data.response;
          this._transactionItems = this._transaction?.Items.filter(
            (item) => item.Status !== 'NRF',
          );

          this._emitChange();
        }
      }

      if (
        action.actionType === TransactionConstants.CHECKOUT ||
        action.actionType === TransactionConstants.SHOPPING_CART_CANCELLED ||
        action.actionType === AccountConstants.LOGOUT
      ) {
        if (
          action.actionType === TransactionConstants.SHOPPING_CART_CANCELLED ||
          action.actionType === AccountConstants.LOGOUT ||
          action.data.response.Status === 'OK'
        ) {
          this._transaction = null;
          this._transactionItems = null;
          this._pickupTime = null;
          this._pickupLocation = null;
          this._availableTimesFromServer = null;
          this._afterTaxPromoApplied = false;
          this._refreshAfterTaxPickTimeChanged = false;

          this._emitChange();

          this._emitPickupLocationChanged();
        }
      }

      if (
        action.actionType ===
        TransactionConstants.SHOPPING_CART_TRANSACTIONS_CLEARED
      ) {
        this._transaction = null;
        this._transactionItems = null;

        this._emitChange();

        this._emitPickupLocationChanged();
      }

      if (action.actionType === TransactionConstants.PICKUP_TIME_CHANGED) {
        this._pickupTime = action.data.pickupTime;
        if (this.isAfterTaxPromoApplied()) {
          this._refreshAfterTaxPickTimeChanged = true;
        }

        this._emitChange();
        this._emitPickupTimeChanged();
      }

      if (action.actionType === TransactionConstants.AFTER_TAX_PROMO_APPLIED) {
        this._afterTaxPromoApplied = true;
        this._emitChange();
      }

      if (action.actionType === TransactionConstants.PICKUP_LOCATION_CHANGED) {
        this._pickupLocation = action.data.pickupLocation;

        this._emitChange();

        this._emitPickupLocationChanged();
      }

      if (action.actionType === TransactionConstants.AVAILABLE_TIMES_CHANGED) {
        this._availableTimesFromServer = action.data.availableTimes;

        this._emitChange();
      }

      if (action.actionType === TransactionConstants.ZERO_INVENTORY_UPDATE) {
        this._transactionItems = action.data.items;

        this._emitChange();
      }
    });
  }

  _emitPickupLocationChanged() {
    this._emitter.emit(PICKUP_LOCATION_CHANGED);
  }

  addPickupLocationCHangedListener(callback: () => void) {
    this._emitter.on(PICKUP_LOCATION_CHANGED, callback);
  }

  removePickupLocationChangedListener(callback: () => void) {
    this._emitter.removeListener(PICKUP_LOCATION_CHANGED, callback);
  }

  addPickupTimeChangedListener(callback: () => void) {
    this._emitter.on(PICKUP_TIME_CHANGED, callback);
  }

  removePickupTimeChangedListener(callback: () => void) {
    this._emitter.removeListener(PICKUP_TIME_CHANGED, callback);
  }

  _emitPickupTimeChanged() {
    this._emitter.emit(PICKUP_TIME_CHANGED);
  }

  _emitAuthorize() {
    this._emitter.emit(AUTHORIZE);
  }

  addAuthorizeListener(callback: () => void) {
    this._emitter.on(AUTHORIZE, callback);
  }

  removeAuthorizeListener(callback: () => void) {
    this._emitter.removeListener(AUTHORIZE, callback);
  }

  _emitChange() {
    this._emitter.emit(CHANGE);
  }

  addChangeListener(callback: () => void) {
    this._emitter.on(CHANGE, callback);
  }

  removeChangeListener(callback: () => void) {
    this._emitter.removeListener(CHANGE, callback);
  }

  getAuthorizeError() {
    return this._authorizeError;
  }

  getAuthorizeResult() {
    return this._authorizeResult;
  }

  getTransaction() {
    return this._transaction;
  }

  getPickupTime() {
    return this._pickupTime;
  }

  getAvailableTimesFromServer(): TimeSlotType[] | null {
    return this._availableTimesFromServer;
  }

  getNumItems() {
    return this.getAllItems().length;
  }

  getDueAmount(): number {
    if (this._transaction) {
      return parseFloat(this._transaction.Due);
    }

    return 0;
  }

  getDiscount(): number {
    return parseFloat(this._transaction?.PromotionDiscount ?? '0');
  }

  isAfterTaxPromoApplied(): boolean {
    return this._afterTaxPromoApplied;
  }

  isRefreshAfterTaxPromotion(): boolean {
    return this._refreshAfterTaxPickTimeChanged;
  }

  afterTaxPromotionRefreshed() {
    this._refreshAfterTaxPickTimeChanged = false;
  }

  getSup(): number {
    return parseFloat(this._transaction?.SupFee ?? '0');
  }

  getScanCodes(): string[] {
    return this.getAllItems().map((item) => item.BarCode);
  }

  getDisplayItems(): Array<CartItem> {
    if (this._transactionItems) {
      return this._transactionItems.filter(
        (item: CartItem) =>
          !item.Description?.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
      );
    }

    return [];
  }

  getAllItems() {
    if (this._transactionItems) {
      return this._transactionItems;
    }

    return [];
  }

  getRemainingDue(balance: number): number {
    return this.getDueAmount() - balance;
  }

  getPickupLocation(): PickupLocation | null {
    if (this._pickupLocation && this._pickupLocation.pickupLocationId) {
      return this._pickupLocation;
    }

    return null;
  }

  getPickupLocationId(): string | null {
    if (this._pickupLocation && this._pickupLocation.pickupLocationId) {
      return this._pickupLocation.pickupLocationId;
    }

    return null;
  }

  getPickupLocationName(): string {
    // First see if a "normal" pickup location was selected.
    if (this._pickupLocation && this._pickupLocation.name) {
      return this._pickupLocation.name;
    }

    if (this._transactionItems) {
      const pickupLocationItem = this._transactionItems.find((item: CartItem) =>
        item.Description?.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
      );

      if (
        pickupLocationItem &&
        pickupLocationItem.Modifiers &&
        pickupLocationItem.Modifiers.length === 1
      ) {
        return pickupLocationItem.Modifiers[0].Name ?? '';
      }
    }
    return '';
  }

  getPreparationMethodDefaults(
    location?: LocationType,
  ): PreparationMethodDefaults {
    const pickupLocation = this.getPickupLocation();
    const defaults: PreparationMethodDefaults = {
      togoAvailable: false,
      dineInAvailable: false,
      lockerAvailable: false,
      selectedMethod: PreparationMethodValues.None,
    };

    if (!location || !location.onlineOrderConfig?.hasDiningPreference) {
      defaults.togoAvailable = false;
      defaults.dineInAvailable = false;
    } else if (pickupLocation) {
      defaults.togoAvailable = pickupLocation.hasToGo;
      defaults.dineInAvailable = pickupLocation.hasDineIn;
      defaults.lockerAvailable = pickupLocation.hasLocker;
    } else if (
      location.onlineOrderConfig.hasDineIn ||
      location.onlineOrderConfig.hasToGo
    ) {
      defaults.togoAvailable = location.onlineOrderConfig.hasToGo;
      defaults.dineInAvailable = location.onlineOrderConfig.hasDineIn;
    } else if (location.onlineOrderConfig?.hasDiningPreference) {
      defaults.togoAvailable = true;
      defaults.dineInAvailable = true;
      defaults.selectedMethod = location.onlineOrderConfig
        .diningPreference[0] as PreparationMethodValues;
    } else {
      defaults.togoAvailable = false;
      defaults.dineInAvailable = false;
    }

    if (defaults.selectedMethod === PreparationMethodValues.None) {
      if (defaults.lockerAvailable) {
        defaults.selectedMethod = PreparationMethodValues.Locker;
      } else if (defaults.togoAvailable) {
        defaults.selectedMethod = PreparationMethodValues.ToGo;
      } else if (defaults.dineInAvailable) {
        defaults.selectedMethod = PreparationMethodValues.DineIn;
      }
    }

    return defaults;
  }

  getUpsellName(): string {
    if (this._transaction && this._transaction.UpsellOptions) {
      return this._transaction.UpsellOptions.Name;
    }

    return '';
  }

  getUpsellItems() {
    if (
      this._transaction &&
      this._transaction.UpsellOptions &&
      this._transaction.UpsellOptions.UpsellItems
    ) {
      return this._transaction.UpsellOptions.UpsellItems;
    }

    return [];
  }

  getUpsellMaxItems(): number {
    if (this._transaction && this._transaction.UpsellOptions) {
      return this._transaction.UpsellOptions.MaxItems || 0;
    }

    return 0;
  }

  getNutritionCalorieTotal(): string {
    return this._transaction?.NutritionCalorieTotal
      ? this._transaction?.NutritionCalorieTotal
      : '';
  }

  getNutritionDiscountAmount(): string {
    return this._transaction?.NutritionDiscountAmount
      ? this._transaction?.NutritionDiscountAmount
      : '';
  }

  getNutritionDisplayColor(): string {
    return this._transaction?.NutritionDisplayColor
      ? this._transaction?.NutritionDisplayColor
      : '';
  }

  getNutritionDisplayLetter(): string {
    return this._transaction?.NutritionDisplayLetter
      ? this._transaction?.NutritionDisplayLetter
      : '';
  }

  getNutritionPromoName(): string {
    return this._transaction?.NutritionPromoName
      ? this._transaction?.NutritionPromoName
      : '';
  }

  getSalesTransactionCharges(): Array<unknown> {
    return this._transaction?.SalesTransactionCharges ?? [];
  }
}

export default new TransactionStore();
