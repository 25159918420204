import moment from 'moment';
import React from 'react';
import {
  EmitterSubscription,
  Keyboard,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import AVTextInput from '../../elements/AVTextInput';
import withScrollToElement from '../../hoc/withScrollToElement';
import type {ScrollToElementProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import Styles from '../../Styles';
import type {Referral} from 'src/types/Referral';
import {HttpResponseCodes} from 'src/api/Api';
import Localized from 'src/constants/AppStrings';
import {alertError, alertInProgress} from '../../helpers/AlertHelper';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import AVText from 'src/components/elements/AVText';
import {ScreenHeight, ScreenWidth} from 'react-native-elements/dist/helpers';
import BackSubheader from 'src/components/elements/BackSubheader';
import EmailLink from 'src/components/img/svg/EmailLink';
import PasswordInput from '../../PasswordInput';
import {Auth0Signup} from 'src/types/SetupModel';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import {isValidEmail} from 'src/services/ValidatorService';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import AppRoutes from 'src/AppRoutes';

type CreateAccountEnterEmailProps = ScrollToElementProps & {
  location: string;
  privacyVersion: string;
  startTime?: moment.Moment;
  referral: Referral | null | undefined;
  isPasswordValid: boolean;
  navigation?: NavigationProp<CreateAccountEnterEmail>;
  importId?: string;
  email?: string;
  pin?: string;
  firstName?: string;
  lastName?: string;
  locationId?: string;
  startingBalance?: number;
};

type CreateAccountEnterEmailState = {
  email: string;
  password: string;
  isKeyboardOpen: boolean;
  errorMessage: string[];
  previousRoute?: string;
  showAlert: boolean;
  alertType: string;
};

class CreateAccountEnterEmail extends React.Component<
  CreateAccountEnterEmailProps,
  CreateAccountEnterEmailState
> {
  static defaultProps = {
    location: null,
  };

  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  email: AVTextInput | null;
  password: AVTextInput | null;
  scrollView: ScrollView | null;
  keyboardDidShowListener: EmitterSubscription;
  keyboardDidHideListener: EmitterSubscription;
  constructor(props: CreateAccountEnterEmailProps) {
    super(props);
    this.state = {
      email: props.email ?? '',
      password: '',
      isKeyboardOpen: false,
      errorMessage: [],
      previousRoute: '',
      showAlert: false,
      alertType: null,
    };
    this.handleBack = this.handleBack.bind(this);
    this.displayAccountSetupError = this.displayAccountSetupError.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.keyboardDidShow = this.keyboardDidShow.bind(this);
    this.keyboardDidHide = this.keyboardDidHide.bind(this);
    this.password = null;
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'CreateAccountEnterEmailScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState().routes,
    );
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this.keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this.keyboardDidHide,
    );

    this.setState({previousRoute: previousRoute});
  }

  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  keyboardDidShow() {
    this.setState({isKeyboardOpen: true});
  }

  keyboardDidHide() {
    this.setState({isKeyboardOpen: false});
  }

  handleCancel() {
    const startTime = this.props.startTime ?? moment();
    Events.AccountCreation.trackEvent(startTime, 'EnterEmail');
  }

  handleBack() {
    NavActions.popToTop();
  }

  displayAccountSetupError(error: any) {
    let message = Localized.Errors.problem_creating_account;

    if (error.statusCode === HttpResponseCodes.Conflict) {
      message += ` ${Localized.Errors.email_already_exists}`;

      if (!message.endsWith('.')) {
        message += '.';
      }
    } else {
      message += ` ${Localized.Errors.check_internet_connection}`;
    }
    FirebaseAnalytic.trackEvent(
      'displayAccountSetupError',
      'CreateAccountEnterEmailScreen',
      {
        ...this.props,
        ...this.state,
        message,
      },
    );
    alertError(message);
  }

  async handleClick() {
    const {email, password, errorMessage} = this.state;

    FirebaseAnalytic.trackEvent('handleNext', 'CreateAccountEnterEmailScreen', {
      ...this.props,
      ...this.state,
    });

    if (!email || !password) {
      alertError(Localized.Errors.all_fields_required);
      return;
    }
    if (!isValidEmail(email)) {
      alertError(Localized.Errors.please_enter_valid_email);
      return;
    }
    if (errorMessage.length > 0) {
      alertError(errorMessage.join('\n'));
    } else {
      this.context.actions.showSpinner();

      try {
        if (this.props.importId) {
          NavActions.push(AppRoutes.CountrySelection, {
            importId: this.props.importId,
            email: email,
            pin: this.props.pin,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            password: password,
            locationId: this.props.locationId,
            startingBalance: this.props.startingBalance,
          });
        } else {
          alertInProgress(
            Localized.Success.create_account_instructions,
            async () => {
              try {
                const signupModel: Auth0Signup = {
                  email: email,
                  password: password,
                };

                const response =
                  await ActionsFactory.getAccountActions().setupAccountAuth0(
                    signupModel,
                  );

                FirebaseAnalytic.trackEvent(
                  'handleNext ' + Localized.Success.create_account_instructions,
                  'CreateAccountEnterEmailScreen',
                  {
                    ...this.props,
                    ...this.state,
                    response,
                  },
                );

                Events.AccountCreation.trackEvent(
                  this.props.startTime,
                  '',
                  `Create new account ${JSON.stringify(response)}`,
                );
                if (response.statusCode === HttpResponseCodes.Conflict) {
                  this.displayAccountSetupError(response);
                  return;
                }

                this.handleBack();
              } catch (error) {
                this.displayAccountSetupError(error);
              }
            },
          );
        }
      } catch (error) {
        this.displayAccountSetupError(error);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  render() {
    if (Platform.OS === 'web') {
      return this.renderWeb();
    }
    return (
      <BackSubheader
        accessibilityLabel="Back arrow"
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.get_you_going}
        onBackSelect={this.handleCancel}
      >
        <ScrollView horizontal={false} automaticallyAdjustContentInsets={false}>
          <View style={styles.container}>
            <View>
              {this.props.importId ? null : (
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  accessible={true}
                  accessibilityRole="text"
                  accessibilityLabel={
                    Localized.Labels.enter_email_for_confirmation
                  }
                  aria-label={`${Localized.Labels.enter_email_for_confirmation}, text`}
                  style={styles.instructions}
                >
                  {Localized.Labels.enter_email_for_confirmation}
                </AVText>
              )}
              <View style={{marginTop: Styles.Spacing.m2}}>
                <AllyTextInput
                  label={Localized.Labels.email}
                  value={this.state.email}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.email}
                  accessibilityValue={{text: this.state.email}}
                  aria-label={Localized.Labels.email}
                  onChangeText={(text) => this.setState({email: text})}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                />
              </View>
              <View style={{marginTop: Styles.Spacing.m1}}>
                <PasswordInput
                  needValidation
                  accessible={true}
                  accessibilityLabel={Localized.Labels.password}
                  aria-label={Localized.Labels.password}
                  accessibilityValue={{text: this.state.password}}
                  label={Localized.Labels.password}
                  value={this.state.password}
                  onChangeText={(password, errorMessage) =>
                    this.setState({
                      password,
                      errorMessage,
                    })
                  }
                />
              </View>
            </View>
            {!this.state.isKeyboardOpen && (
              <AVTouchableOpacity
                accessible={true}
                accessibilityRole="button"
                role="button"
                style={styles.box}
                onPress={this.handleClick}
                testID="continueButton"
              >
                <EmailLink size={ScreenWidth * 0.3} />
                <View style={styles.boxText}>
                  {this.props.importId ? null : (
                    <AVText
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                      accessible={true}
                      accessibilityRole="text"
                      accessibilityLabel={Localized.Labels.send_link_to_email}
                      aria-label={Localized.Labels.send_link_to_email}
                      style={styles.descText}
                      testID="sendLinkToEmail"
                    >
                      {Localized.Labels.send_link_to_email}
                    </AVText>
                  )}
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                    accessible={true}
                    accessibilityRole="text"
                    aria-label={Localized.Labels.click_here_to_continue}
                    style={styles.clickText}
                    testID="clickHereToContinue"
                  >
                    {Localized.Labels.click_here_to_continue}
                  </AVText>
                </View>
              </AVTouchableOpacity>
            )}
          </View>
        </ScrollView>
      </BackSubheader>
    );
  }

  renderWeb() {
    return (
      <BackSubheader
        accessibilityLabel="Back arrow"
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.get_you_going}
      >
        <View style={[Styles.Style.flex, styles.container]}>
          <View style={[Styles.Style.flex, styles.bottomRow]}>
            <AVText
              style={styles.title}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.enter_email_for_confirmation}
              aria-label={`${Localized.Labels.enter_email_for_confirmation}, text`}
            >
              {Localized.Labels.enter_email_for_confirmation}
            </AVText>

            <View style={{paddingTop: Styles.Spacing.m4}}>
              <AllyTextInput
                label={Localized.Labels.email}
                value={this.state.email}
                accessible={true}
                accessibilityLabel={Localized.Labels.email}
                aria-label={Localized.Labels.email}
                onChangeText={(text: string) => this.setState({email: text})}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              />
            </View>
            <View style={{marginTop: Styles.Spacing.m2}}>
              <PasswordInput
                needValidation
                accessible={true}
                accessibilityLabel={Localized.Labels.password}
                aria-label={Localized.Labels.password}
                label={Localized.Labels.password}
                value={this.state.password}
                onChangeText={(password, errorMessage) =>
                  this.setState({
                    password,
                    errorMessage,
                  })
                }
              />
            </View>
          </View>
          <AVTouchableOpacity
            accessible={true}
            accessibilityRole="button"
            role="button"
            style={styles.boxWeb}
            onPress={this.handleClick}
          >
            <View style={styles.letter}>
              <EmailLink size={ScreenWidth * 0.2} />
            </View>
            <AVText
              accessible={true}
              accessibilityLabel={Localized.Labels.send_link_to_email}
              aria-label={Localized.Labels.send_link_to_email}
              style={styles.descText}
            >
              {Localized.Labels.send_link_to_email}
            </AVText>
            <AVText
              accessible={true}
              accessibilityLabel={Localized.Labels.click_here_to_continue}
              aria-label={Localized.Labels.click_here_to_continue}
              style={styles.clickText}
            >
              {Localized.Labels.click_here_to_continue}
            </AVText>
          </AVTouchableOpacity>
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  instructions: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    marginVertical: Styles.Spacing.m1,
    textAlign: 'left',
  },
  bottomRow: {
    marginBottom: Styles.Spacing.m5,
  },
  title: {
    fontSize: Styles.Fonts.f2,
    marginTop: Styles.Spacing.m2,
  },
  boxWeb: {
    borderRadius: 8,
    backgroundColor: Styles.lightGray,
    padding: Styles.Spacing.m4,
    marginBottom: Styles.Spacing.m3,
  },
  box: {
    marginTop: Styles.Spacing.m3,
    width: '100%',
    height: ScreenHeight * 0.27,
    borderRadius: 8,
    backgroundColor: Styles.lightGray,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  boxText: {
    width: '50%',
  },
  letter: {},
  descText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
    marginBottom: 10,
  },
  clickText: {
    fontSize: Styles.Fonts.f3,
    color: Styles.white,
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    padding: Styles.Spacing.m3,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  passwordRuleText: {
    paddingLeft: 20,
    paddingVertical: 5,
    color: Styles.darkColor,
  },
  passwordRule: {
    paddingLeft: 40,
    paddingBottom: 5,
    color: Styles.lightGray,
  },
  text: {
    paddingHorizontal: 0,
    backgroundColor: Styles.white,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 1,
    paddingVertical: 5,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(CreateAccountEnterEmail),
);
