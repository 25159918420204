import moment, {Moment} from 'moment';
import PaymentTypes from 'src/constants/cart/PaymentTypes';
import PaymentStatus from 'src/constants/cart/PaymentStatus';
import {CartItemModifier} from 'src/types/Cart';
import {CartItem} from 'src/types/TransactionDetail';

export type CoffeeSelection = {
  uuid: string;
  bold: boolean;
  drinkId: number;
  item: string;
  leaveRoom: boolean;
  sizeId: number;
  sizeName: string;
};

const TRANSID_PREFIX = 'bea';
const PREFIX_LENGTH = 6;

export type PaymentType = {
  balance: number;
  amount: number;
  type: PaymentTypes;
  status: PaymentStatus;
  GMABalanceId: string;
  seq?: number;
};

type PendingItemState = {
  item: CartItem;
  quantity: number;
  modifiers: Record<string, CartItemModifier[]>;
};
export class CartStore {
  coffeeSelection: CoffeeSelection;
  transactionId = '';
  sequenceNumber = 0;
  beaconId = '';
  payments: Array<PaymentType> = [];
  locationId = '';
  locationName = '';
  startTime!: Moment;
  pending!: PendingItemState;

  constructor() {
    this.coffeeSelection = {
      uuid: '',
      bold: false,
      drinkId: 0,
      item: '',
      leaveRoom: false,
      sizeId: 0,
      sizeName: '',
    };
    this.resetCart();
  }

  initializeSession(
    beaconId: string,
    locationId: string,
    locationName: string,
  ) {
    this.resetCart();
    this.beaconId = beaconId;
    this.sequenceNumber = this.getSequenceNumber();
    this.transactionId = this.getTransactionId(
      this.beaconId,
      this.sequenceNumber,
    );
    this.locationId = locationId;
    this.locationName = locationName;
    this.startTime = moment();
  }

  resetCart() {
    this.transactionId = '';
    this.sequenceNumber = 0;
    this.payments = [];
    this.locationId = '';
    this.pending = {} as PendingItemState;
  }

  clearPayments() {
    this.payments = [];
  }

  get CoffeeSelection() {
    return this.coffeeSelection;
  }

  get TransactionId() {
    return this.transactionId;
  }

  get SequenceNumber() {
    return this.sequenceNumber;
  }

  get Payments() {
    if (!this.payments) {
      this.payments = [];
    }

    return this.payments;
  }

  get LocationName() {
    return this.locationName;
  }

  get LocationId() {
    return this.locationId;
  }

  get Coupons() {
    return [];
  }

  get Discount(): number {
    return 0;
  }

  get SessionStartTime(): Moment {
    return this.startTime;
  }

  setCoffeeSelection(coffeeSelection: CoffeeSelection) {
    this.coffeeSelection = coffeeSelection;
  }

  addPayment(payment: PaymentType) {
    this.Payments.push({...payment, seq: this.Payments.length + 1});
  }

  getSequenceNumber(): number {
    return Date.now();
  }

  getTransactionId(beaconId: string, sequenceNumber: number): string {
    let prefixId = beaconId;

    if (!prefixId) {
      prefixId = '';
    }

    if (prefixId.length > PREFIX_LENGTH) {
      prefixId = prefixId.substring(prefixId.length - PREFIX_LENGTH);
    }

    return `${TRANSID_PREFIX}${prefixId}-${sequenceNumber}`;
  }

  setPendingQuantity(quantity: number) {
    this.pending.quantity = quantity;
  }

  getPendingQuantity(): number {
    return this.pending.quantity;
  }

  setPendingItem(item: CartItem) {
    this.pending.item = item;
    this.pending.modifiers = {};
  }

  getPendingItem(): CartItem {
    return this.pending.item;
  }

  setUpsellModifiers(tabId: string, modifiers: Array<CartItemModifier>) {
    this.pending.modifiers[tabId] = modifiers;
  }

  getPendingModifiers(): Array<CartItemModifier> {
    const modifiers: Array<CartItemModifier> = [];
    Object.keys(this.pending.modifiers).forEach((tabId) => {
      this.pending.modifiers[tabId].forEach((modifier) => {
        modifiers.push(modifier);
      });
    });
    return modifiers;
  }
}
export default new CartStore();
