import TSFInsideDevice, {TSFInsideEvents} from './TSFInsideDevice';
export default class DemoTSFInsideDevice extends TSFInsideDevice {
  startConnection() {
    this.emit(TSFInsideEvents.onConnected);
    return Promise.resolve();
  }

  sendHexMessage() {
    return Promise.resolve();
  }
}
