import React from 'react';
import {StyleSheet} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
export type Digit = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type Props = {
  onClick: (digit: Digit) => Promise<void> | void;
  digit: Digit;
};
export default function PinButton({onClick, digit}: Props) {
  function onPress() {
    onClick(digit);
  }

  return (
    <AVTouchableOpacity onPress={onPress} accessibilityLabel={digit}>
      <AVText style={styles.digit}>{digit}</AVText>
    </AVTouchableOpacity>
  );
}
const styles = StyleSheet.create({
  digit: {
    borderColor: Styles.black,
    borderRadius: Styles.Fonts.f4,
    borderWidth: 2,
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    height: Styles.Fonts.f4 * 2,
    margin: Styles.Spacing.m1,
    paddingTop: Styles.Spacing.m2,
    textAlign: 'center',
    width: Styles.Fonts.f4 * 2,
  },
});
