import * as _ from 'lodash';
import * as React from 'react';
import chroma from 'chroma-js';
import {
  Animated,
  Easing,
  I18nManager,
  PanResponder,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Styles from '../Styles';
import AVTouchableOpacity from './AVTouchableOpacity';
import Settings from 'src/Settings';
type OptionType = {
  text: string;
  label: string;
  value: string;
  activeColor?: string;
};
type SwitchSelectorProps = {
  initial?: number;
  options: Array<OptionType>;
  value?: number;
  buttonColor: string;
  disabled: boolean;
  switchAnimationDuration: number;
  fontSize: number;
  returnObject: boolean;
  onPress: (arg0: OptionType | string) => void;
  style: StyleProp<ViewStyle>;
  textStyle: StyleProp<TextStyle>;
  selectedTextStyle: StyleProp<TextStyle>;
  textColor: string;
  selectedColor: string;
  backgroundColor: string;
  borderColor: string;
  borderRadius: number;
  bold: boolean;
};
type SwitchSelectorState = {
  selected: number;
  optionWidths: Array<number>;
  height: number;
};
export default class SwitchSelector extends React.Component<
  SwitchSelectorProps,
  SwitchSelectorState
> {
  static defaultProps = {
    style: {},
    textStyle: {},
    selectedTextStyle: {},
    fontSize: Styles.Fonts.f0,
    borderRadius: 50,
    bold: true,
    returnObject: false,
    switchAnimationDuration: 200,
    disabled: false,
    ...Platform.select({
      web: {
        textColor: Styles.headerColor,
        selectedColor: Styles.white,
        backgroundColor: Styles.white,
        borderColor: Styles.lightGray,
        buttonColor: Styles.headerColor,
      },
      default: {
        textColor: Styles.white,
        selectedColor: Styles.headerColor,
        backgroundColor: chroma(Styles.headerColor).set('hsl.l', '*.76').hex(),
        borderColor: Styles.headerColor,
        buttonColor: Styles.white,
      },
    }),
  };
  animatedValue: Animated.Value;
  _panResponder: any;

  constructor(props: SwitchSelectorProps) {
    super(props);
    const optionWidths = this.props.options.map(() => {
      return 0;
    });
    this.state = {
      selected: this.props.initial ? this.props.initial : 0,
      optionWidths,
      height: 0,
    };
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: this.shouldSetResponder,
      onMoveShouldSetPanResponder: this.shouldSetResponder,
      onPanResponderRelease: this.responderEnd,
      onPanResponderTerminate: this.responderEnd,
    });
    this.animatedValue = new Animated.Value(
      this.props.initial
        ? I18nManager.isRTL
          ? -(this.props.initial / this.props.options.length)
          : this.props.initial / this.props.options.length
        : 0,
    );
  }

  componentDidUpdate(prevProps: SwitchSelectorProps) {
    if (
      typeof this.props.value !== 'undefined' &&
      prevProps.value !== this.props.value
    ) {
      this.toggleItem(this.props.value);
    }
  }

  shouldSetResponder = (evt: any, gestureState: any) => {
    return (
      evt.nativeEvent.touches.length === 1 &&
      !(Math.abs(gestureState.dx) < 5 && Math.abs(gestureState.dy) < 5)
    );
  };
  responderEnd = (evt: any, gestureState: any) => {
    if (this.props.disabled) {
      return;
    }

    const swipeDirection = this._getSwipeDirection(gestureState);

    if (
      swipeDirection === 'RIGHT' &&
      this.state.selected < this.props.options.length - 1
    ) {
      this.toggleItem(this.state.selected + 1);
    } else if (swipeDirection === 'LEFT' && this.state.selected > 0) {
      this.toggleItem(this.state.selected - 1);
    }
  };

  _getSwipeDirection(gestureState) {
    const {dx, dy, vx} = gestureState;

    // 0.1 velocity
    if (Math.abs(vx) > 0.1 && Math.abs(dy) < 80) {
      return dx > 0 ? 'RIGHT' : 'LEFT';
    }

    return null;
  }

  getBgColor() {
    const {selected} = this.state;
    const {options, buttonColor} = this.props;
    return options[selected].activeColor || buttonColor;
  }

  animate = (value: number, last: number) => {
    this.animatedValue.setValue(last);
    Animated.timing(this.animatedValue, {
      toValue: value,
      duration: this.props.switchAnimationDuration,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  };
  toggleItem = (index: number, callOnPress = true) => {
    const {options, returnObject, onPress} = this.props;
    ReactNativeHapticFeedback.trigger('impactLight', Settings.hapticOptions);

    if (options.length <= 1 || index === null || isNaN(index)) {
      return;
    }

    this.animate(
      I18nManager.isRTL ? -index : index,
      I18nManager.isRTL ? -this.state.selected : this.state.selected,
    );

    if (callOnPress && onPress) {
      onPress(returnObject ? options[index] : options[index].value);
    }

    this.setState({
      selected: index,
    });
  };
  getTranslateXOutputRange = () => {
    const result: number[] = [];

    for (let i = 0; i < this.state.optionWidths.length; i++) {
      if (i === 0) {
        result[0] = 0;
      } else {
        let j = i - 1;
        let sum = 0;

        while (j >= 0) {
          sum += this.state.optionWidths[j];
          j -= 1;
        }

        result[i] = sum;
      }
    }

    return result;
  };

  render() {
    const {
      style,
      textStyle,
      selectedTextStyle,
      textColor,
      selectedColor,
      backgroundColor,
      borderRadius,
      borderColor,
      bold,
      disabled,
    } = this.props;
    const options = this.props.options.map((element, index) => (
      <AVTouchableOpacity
        key={index}
        disabled={disabled}
        style={[
          styles.button,
          {
            borderRadius,
            borderColor,
          },
        ]}
        onPress={() => this.toggleItem(index)}
        accessibilityLabel={`${element.label} Toggle`}
        onLayout={(event) => {
          const {height, width} = event.nativeEvent.layout;

          const widths = _.clone(this.state.optionWidths);

          widths[index] = width;
          this.setState({
            optionWidths: widths,
            height,
          });
        }}
      >
        <Animated.Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={[
            styles.text,
            this.state.selected === index ? selectedTextStyle : textStyle,
            {
              color: this.state.selected === index ? selectedColor : textColor,
              fontSize: this.props.fontSize,
            },
            bold && styles.bold,
          ]}
        >
          {element.text}
        </Animated.Text>
      </AVTouchableOpacity>
    ));
    return (
      <View style={[styles.row, style]}>
        <View {...this._panResponder.panHandlers} style={styles.flex1}>
          <View
            style={[
              styles.container,
              {
                borderRadius,
                backgroundColor,
                borderColor,
              },
            ]}
          >
            <View style={styles.animatedContainer}>
              {
                <Animated.View
                  style={[
                    {
                      height: this.state.height,
                      backgroundColor: this.getBgColor(),
                      width: this.animatedValue.interpolate({
                        inputRange: _.range(this.state.optionWidths.length),
                        outputRange: this.state.optionWidths,
                      }),
                      transform: [
                        {
                          translateX: this.animatedValue.interpolate({
                            inputRange: _.range(this.state.optionWidths.length),
                            outputRange: this.getTranslateXOutputRange(),
                            extrapolate: 'clamp',
                          }),
                        },
                      ],
                      borderRadius,
                    },
                    styles.animated,
                  ]}
                />
              }
              {options}
            </View>
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  animated: {
    position: 'absolute',
  },
  animatedContainer: {
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
  },
  button: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
    paddingVertical: PixelRatio.roundToNearestPixel(Styles.Spacing.m1 * 1.3),
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  container: {
    borderWidth: 1,
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  flex1: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  row: {
    flexDirection: 'row',
  },
  text: {
    backgroundColor: Styles.transparent,
    textAlign: 'center',
  },
});
