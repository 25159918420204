import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import Carousel, {Pagination} from 'react-native-snap-carousel';
import Styles from '../../Styles';
import withIsConnected from '../../hoc/withIsConnected';
import MoblicoPromotion from './MoblicoPromotion.web';
import type {MoblicoPromotionType} from 'src/types/Promotions';
type MoblicoPromotionsCarouselProps = {
  promos: Array<MoblicoPromotionType>;
  isConnected: boolean;
  promotionViewed: (promotion: any) => void;
};
type MoblicoPromotionsCarouselState = {
  activeItem: number;
  width: number;
};

class MoblicoPromotionsCarousel extends React.Component<
  MoblicoPromotionsCarouselProps,
  MoblicoPromotionsCarouselState
> {
  carousel: any = React.createRef();

  constructor(props: MoblicoPromotionsCarouselProps) {
    super(props);
    this.state = {
      activeItem: 0,
      width: -1,
    };
    this.onLayout = this.onLayout.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.promotionViewed = this.promotionViewed.bind(this);
    this.getPromotionWidth = this.getPromotionWidth.bind(this);
  }

  onLayout(event: any) {
    const {width} = event.nativeEvent.layout;
    this.setState({
      width,
    });
  }

  promotionViewed(index: number) {
    const promotion = this.props.promos[index];

    if (this.props.promotionViewed) {
      this.props.promotionViewed(promotion);
    }

    this.setState({
      activeItem: index,
    });
  }

  getPromotionWidth() {
    if (this.props.promos.length < 2) {
      return this.state.width;
    } else {
      return this.state.width * 0.8;
    }
  }

  renderItem(param) {
    return (
      <MoblicoPromotion
        promotion={param.item}
        isConnected={this.props.isConnected}
        width={this.getPromotionWidth()}
        index={param.index}
      />
    );
  }

  render() {
    if (this.state.width <= 0) {
      return <View onLayout={this.onLayout} />;
    }

    return (
      <View onLayout={this.onLayout} style={styles.container}>
        <Carousel
          ref={this.carousel}
          data={this.props.promos}
          renderItem={this.renderItem}
          sliderWidth={this.getPromotionWidth() * 3}
          itemWidth={this.getPromotionWidth()}
          onScrollIndexChanged={this.promotionViewed}
          inactiveSlideOpacity={1.0}
          vertical={false}
        />
        {this.carousel.current && (
          <Pagination
            carouselRef={this.carousel.current}
            dotsLength={this.props.promos.length}
            activeDotIndex={this.state.activeItem}
            tappableDots
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    marginVertical: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
});
export default withIsConnected(MoblicoPromotionsCarousel);
