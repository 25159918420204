import moment from 'moment';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Events from 'src/logging/Events';
import SafeAreaView from '../../elements/SafeAreaView';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import Settings from 'src/Settings';
import type {Referral} from 'src/types/Referral';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type ExistingAccountScreenProps = {
  location: string;
  privacyVersion: string;
  startTime?: moment.Moment;
  referral: Referral | null | undefined;
};

class ExistingAccountScreen extends React.Component<ExistingAccountScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ExistingAccountScreenProps) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleLinkAccount = this.handleLinkAccount.bind(this);
  }

  handleCancel() {
    const startTime: moment.Moment = this.props.startTime ?? moment();
    Events.AccountCreation.trackEvent(startTime, 'ExistingAccount');
  }

  handleContinue() {
    FirebaseAnalytic.trackEvent('handleContinue', 'EnterPinScreen', {
      ...this.props,
      navigate: AppRoutes.CreateAccountEnterEmail,
    });
    NavActions.replace(AppRoutes.CreateAccountEnterEmail, {...this.props});
  }

  handleLinkAccount() {
    FirebaseAnalytic.trackEvent('handleLinkAccount', 'EnterPinScreen', {
      ...this.props,
      navigate: AppRoutes.EnteredEmail,
    });
    NavActions.replace(AppRoutes.EnteredEmail, {...this.props});
  }

  renderImage() {
    if (Settings.buildType === BuildTypes.canteen) {
      return (
        <Image
          source={require('../../img/devices/canteen.png')}
          style={styles.image}
          resizeMode="contain"
        />
      );
    } else {
      return (
        <Image
          source={require('../../img/devices/default.png')}
          style={styles.image}
          resizeMode="contain"
        />
      );
    }
  }

  render() {
    return (
      <SafeAreaView
        style={Styles.Style.flex}
        forceInset={{
          top: 'never',
        }}
      >
        <BackSubheader
          title={Localized.Labels.create_account}
          onBackSelect={this.handleCancel}
        >
          <View style={styles.content}>
            {this.renderImage()}
            <View style={styles.textContent}>
              <AVText style={styles.headerText}>
                {Localized.Labels.already_have_account}
              </AVText>
              <AVText style={styles.bodyText}>
                {Localized.Labels.if_you_already_created_an_account}
              </AVText>
              <RoundedButton
                buttonType={ButtonType.outline}
                containerStyle={styles.linkBtn}
                onPress={this.handleLinkAccount}
                accessibilityLabel="Link Acccount"
                text={Localized.Buttons.setup_your_app}
              />
              <AVText style={[styles.bodyText, styles.bottomText]}>
                {Localized.Labels.continue_to_create_account}
              </AVText>
            </View>
            <RoundedButton
              buttonType={ButtonType.action}
              onPress={this.handleContinue}
              accessibilityLabel="Continue"
              text={Localized.Buttons.continue}
            />
          </View>
        </BackSubheader>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  bodyText: {
    fontSize: Styles.Fonts.f2,
    marginVertical: Styles.Spacing.m2,
    textAlign: 'center',
  },
  bottomText: {
    marginTop: Styles.Spacing.m1,
  },
  content: {
    backgroundColor: Styles.white,
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  headerText: {
    fontSize: Styles.Fonts.f3,
    fontWeight: '700',
  },
  image: {
    height: '30%',
    marginVertical: Styles.Spacing.m3,
    width: '100%',
  },
  linkBtn: {
    alignSelf: 'center',
    marginVertical: Styles.Spacing.m2,
  },
  textContent: {
    alignItems: 'center',
  },
});
export default withForwardedNavigationParams<ExistingAccountScreenProps>()(
  ExistingAccountScreen,
);
