import _ from 'lodash';
import * as React from 'react';
import {
  Animated,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Rocket from '../img/svg/Rocket';
import Styles from '../Styles';
import AVText from './AVText';
import type {RewardType} from 'src/types/Rewards';
import AccountStore from 'src/stores/AccountStore';
type RewardSectionProps = WithGlobalizeProps & {
  rewards: number;
  style: StyleProp<ViewStyle>;
  isRewardButtonShown?: boolean;
  rewardSteps: Array<RewardType>;
};
type RewardSectionState = {
  rocketSpin: Animated.Value;
  rocketDistance: Animated.Value;
  isFirstLoad: boolean;
  loadingBarWidth: number;
};

class RewardSection extends React.Component<
  RewardSectionProps,
  RewardSectionState
> {
  constructor(props: RewardSectionProps) {
    super(props);
    this.state = {
      rocketSpin: new Animated.Value(0),
      rocketDistance: new Animated.Value(0),
      isFirstLoad: true,
      loadingBarWidth: 0,
    };
  }

  handleLayout = (event) => {
    if (event.nativeEvent.layout.width !== this.state.loadingBarWidth) {
      this.setState({
        loadingBarWidth: event.nativeEvent.layout.width,
      });
    }
  };

  shouldComponentUpdate(
    nextProps: RewardSectionProps,
    nextState: RewardSectionState,
  ) {
    return (
      !_.isEqual(nextProps.rewardSteps, this.props.rewardSteps) ||
      nextProps.rewards !== this.props.rewards ||
      nextState.loadingBarWidth !== this.state.loadingBarWidth
    );
  }

  componentDidUpdate(prevProps: RewardSectionProps) {
    if (
      this.state.isFirstLoad &&
      this.props.rewardSteps &&
      this.props.rewardSteps.length > 0 &&
      this.props.rewards > 0
    ) {
      this.firstMotion(this.props);
      return;
    }

    if (prevProps.rewards !== this.props.rewards) {
      this.getRocketMovementAnimation(this.props).start();
    }
  }

  componentDidMount() {
    if (
      !this.props.rewardSteps ||
      this.props.rewardSteps.length <= 0 ||
      this.props.rewards <= 0
    ) {
      return;
    }

    this.firstMotion(this.props);
  }

  firstMotion(props: RewardSectionProps) {
    if (this.state.loadingBarWidth > 0) {
      this.setState({
        isFirstLoad: false,
      });
      Animated.parallel([
        Animated.timing(this.state.rocketSpin, {
          delay: 300,
          toValue: 60,
          duration: 500,
          useNativeDriver: Platform.OS !== 'web',
        }),
        this.getRocketMovementAnimation(props),
      ]).start();
    }
  }

  getRocketMovementAnimation(props: RewardSectionProps) {
    const maxPoints = props.rewardSteps[props.rewardSteps.length - 1].points;
    const rocketPercent = Math.min(props.rewards / maxPoints, 1) * 0.9375;
    return Animated.timing(this.state.rocketDistance, {
      delay: 300,
      toValue: rocketPercent,
      duration: 2800,
      useNativeDriver: Platform.OS !== 'web',
    });
  }

  render() {
    let i = 0;
    let currentPoints = this.props.rewards;
    let prevPoints = 0;
    let additionalPoints = 0;
    let rewardValue = 0;

    const rewardOptions = this.props.rewardSteps.map((reward) => {
      let availableFlex = 1;
      let unavailableFlex = 1;
      const available = reward.points <= this.props.rewards;
      additionalPoints = reward.points - prevPoints;
      prevPoints = reward.points;

      if (additionalPoints === 0) {
        return null;
      }

      if (this.props.rewards > reward.points) {
        rewardValue = reward.redeemvalue;
      }
      if (currentPoints > 0) {
        if (currentPoints > reward.points) {
          availableFlex = 1;
          unavailableFlex = 0;
        } else {
          availableFlex = currentPoints / additionalPoints;
          unavailableFlex = 1 - availableFlex;
        }
        currentPoints -= additionalPoints;
      } else {
        availableFlex = 0;
      }

      return (
        <View
          key={i++}
          style={[
            styles.rewardOption,
            {
              flex: additionalPoints,
            },
          ]}
        >
          <View
            style={[
              styles.availableOption,
              {
                flex: availableFlex,
              },
            ]}
          />
          <View
            style={[
              styles.unavailableOption,
              {
                flex: unavailableFlex,
              },
            ]}
          />
          <View style={styles.overlayText}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={false}
              style={[styles.rewardText, available && styles.availableText]}
            >
              {Util.formatCurrency(
                this.props,
                reward.redeemvalue,
                AccountStore.getCurrency(),
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                },
              )}
            </Text>
          </View>
          <View
            style={[styles.overlayPoint, available && styles.availablePoint]}
          />
        </View>
      );
    });

    const pointsNumber = `${Util.formatNumber(this.props, this.props.rewards, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })}`;
    const largestAvailableRewardAmount = Util.formatCurrency(
      this.props,
      rewardValue,
      AccountStore.getCurrency(),
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      },
    );
    const rocketView = (
      <View
        accessible={true}
        accessibilityLabel={`${largestAvailableRewardAmount} in rewards available`}
        accessibilityRole="image"
        aria-label={`${largestAvailableRewardAmount} in rewards available`}
        role="img"
        style={[
          styles.graph,
          styles.rocketView,
          this.props.isRewardButtonShown && {
            marginTop: Styles.Spacing.m3,
          },
        ]}
        onLayout={this.handleLayout}
      >
        <Animated.View
          style={[
            styles.rocket,
            {
              transform: [
                {
                  translateX: this.state.rocketDistance.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, this.state.loadingBarWidth],
                  }),
                },
                {
                  rotate: this.state.rocketSpin.interpolate({
                    inputRange: [0, 360],
                    outputRange: ['0deg', '360deg'],
                  }),
                },
                {
                  perspective: 1000,
                },
              ],
            },
          ]}
        >
          <Rocket color={Styles.primaryColor} size={26} />
        </Animated.View>
      </View>
    );
    return (
      <View key={-1} style={[styles.imageContainer, this.props.style]}>
        <View style={styles.image}>
          <View style={styles.header}>
            <View style={styles.rewardOption}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                style={styles.pointNumberLabel}
                accessibilityLabel={`${this.props.rewards} reward points`}
                accessibilityRole="text"
                aria-label={`${this.props.rewards} reward points, text`}
                accessible={true}
              >
                {pointsNumber}
              </AVText>
              <Text
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                style={styles.pointsLabel}
              >
                {Localized.Labels.points}
              </Text>
            </View>
            {this.props.isRewardButtonShown ? (
              <RoundedButton
                buttonType={ButtonType.normal}
                text={Localized.Labels.redeem}
                containerStyle={styles.button}
                onPress={() => NavActions.navigate(AppRoutes.Rewards)}
                accessible={true}
                accessibilityRole="button"
                accessibilityLabel={Localized.Buttons.redeem_reward_points}
                aria-label={Localized.Buttons.redeem_reward_points}
                role="button"
              />
            ) : null}
          </View>
          {rocketView}
          <View style={styles.graph}>
            <View style={[styles.line, styles.flex15]}>{rewardOptions}</View>
            <View style={styles.line}>
              <View key={i++} style={[styles.rewardOption, styles.flex1]}>
                <View
                  style={[
                    styles.unavailableOption,
                    {
                      flex: Styles.Spacing.m1,
                    },
                  ]}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  availableOption: {
    borderBottomWidth: PixelRatio.roundToNearestPixel(2.5),
    borderColor: Styles.primaryColor,
  },
  availablePoint: {
    backgroundColor: Styles.primaryColor,
    borderColor: Styles.primaryColor,
  },
  availableText: {
    color: Styles.primaryColor,
  },
  button: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 2,
  },
  flex1: {
    flex: 1,
  },
  flex15: {
    flex: 15,
  },
  graph: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m1,
  },
  header: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    padding: Styles.Spacing.m2,
    paddingTop: Styles.Spacing.m3,
  },
  line: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m1,
  },
  overlayPoint: {
    backgroundColor: Styles.white,
    borderColor: Styles.lightGray,
    borderRadius: PixelRatio.roundToNearestPixel(10),
    borderWidth: PixelRatio.roundToNearestPixel(2),
    height: PixelRatio.roundToNearestPixel(10),
    position: 'absolute',
    right: 0,
    top: -Styles.Spacing.m1,
    width: PixelRatio.roundToNearestPixel(10),
  },
  overlayText: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: -2,
    top: Styles.Spacing.m2,
  },
  pointNumberLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '300',
  },
  pointsLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    marginTop: 2,
  },
  rewardOption: {
    flexDirection: 'row',
  },
  rewardText: {
    fontWeight: '300',
  },
  rocket: {
    left: -13,
  },
  rocketView: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    marginBottom: -2,
  },
  unavailableOption: {
    borderBottomWidth: PixelRatio.roundToNearestPixel(2.5),
    borderColor: Styles.lightGray,
  },
});
export default withGlobalize(RewardSection);
