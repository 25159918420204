import {Buffer} from 'buffer';
import crc32 from './Crc32Posix';
export const Radix = {
  Decimal: 10,
  Hex: 16,
  Binary: 2,
};

export function hex2ascii(hexx: string): string {
  return encodeValue(hexx, 'hex', 'ascii');
}
export function encodeValue(
  value: string,
  sourceEncoding: BufferEncoding,
  targetEncoding: BufferEncoding,
): string {
  let result = Buffer.from(value, sourceEncoding).toString(targetEncoding);

  if (targetEncoding === 'ascii') {
    result = result.replace(/\0/g, '');
  }

  return result;
}
export function getCrc32Posix(hexValue: string): string {
  const buffer = Buffer.from(hexValue, 'hex');
  const crc32Buffer = crc32(buffer);
  return crc32Buffer.toString('hex');
}
export function convertHexStringToAmount(hexString: string) {
  let asciiString = hex2ascii(hexString);
  asciiString = asciiString.replace(/^0+/, '');
  const amount = Number(asciiString);
  return amount / 100;
}
export function convertDecimalToAsciiString(
  decimal: number,
  numDigits: number,
) {
  let newAmount = decimal * 100;
  newAmount = Number(newAmount.toFixed(0));
  return convertNumberToAsciiString(newAmount, numDigits);
}
export function convertNumberToAsciiString(
  amount: number | string,
  numDigits: number,
) {
  const s = '000000000' + amount;
  return s.substr(s.length - numDigits);
}
