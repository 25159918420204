// this is an auto generated file. This will be overwritten
export const getMessage =
  /* GraphQL */
  `
    query GetMessage($id: ID!) {
      getMessage(id: $id) {
        id
        subject
        message
        from
        date
        type
        value
        icon
        read
        owner
      }
    }
  `;
export const listMessages =
  /* GraphQL */
  `
    query ListMessages(
      $filter: ModelMessageFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          subject
          message
          from
          date
          type
          value
          icon
          read
          owner
        }
        nextToken
      }
    }
  `;
export const getDeal =
  /* GraphQL */
  `
    query GetDeal($id: ID!) {
      getDeal(id: $id) {
        id
        dealId
        description
        endDate
        name
        currentGoalAmount
        currentGoalQuantity
        targetGoalAmount
        targetGoalQuantity
        dealStatus
        owner
        legalese
        image
        redeemedCount
        numberOfUsesPerCode
      }
    }
  `;
export const listDeals =
  /* GraphQL */
  `
    query ListDeals(
      $filter: ModelDealFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          dealId
          description
          endDate
          name
          currentGoalAmount
          currentGoalQuantity
          targetGoalAmount
          targetGoalQuantity
          dealStatus
          owner
          legalese
          image
          redeemedCount
          numberOfUsesPerCode
        }
        nextToken
      }
    }
  `;
export const messagesByOwner =
  /* GraphQL */
  `
    query MessagesByOwner(
      $owner: String
      $date: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelMessageFilterInput
      $limit: Int
      $nextToken: String
    ) {
      MessagesByOwner(
        owner: $owner
        date: $date
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          subject
          message
          from
          date
          type
          value
          icon
          read
          owner
        }
        nextToken
      }
    }
  `;
export const dealsByOwner =
  /* GraphQL */
  `
    query DealsByOwner(
      $owner: String
      $sortDirection: ModelSortDirection
      $filter: ModelDealFilterInput
      $limit: Int
      $nextToken: String
    ) {
      DealsByOwner(
        owner: $owner
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          dealId
          description
          endDate
          name
          currentGoalAmount
          currentGoalQuantity
          targetGoalAmount
          targetGoalQuantity
          dealStatus
          owner
          legalese
          image
          redeemedCount
          numberOfUsesPerCode
        }
        nextToken
      }
    }
  `;
