import * as React from 'react';
import TextInput from '../elements/AVTextInput';
import Styles from '../Styles';
import {KeyboardType, ScrollView, StyleSheet, View} from 'react-native';
import AVText from '../elements/AVText';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import BackSubheader from '../elements/BackSubheader';
import InviteFriends from '../img/svg/InviteFriends';
import AVTouchableOpacity from '../elements/AVTouchableOpacity';
import type {ReferralCampaign} from 'src/types/Referral';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import ScreenContext from '../ScreenContext';
import Settings from 'src/Settings';
import NavActions from 'src/actions/NavActions';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import moment from 'moment';
import {isValidEmail, isValidPhoneNumber} from 'src/services/ValidatorService';
import Events from 'src/logging/Events';
import ReferralActions from 'src/actions/ReferralActions';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import HttpClient from 'src/api/HttpClient';
import {InputTypes, Keyboards} from 'src/constants/KeyboardTypes';
import {alertSuccess, alertError} from '../helpers/AlertHelper';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ReferralScreenProps = {
  referralCampaign: ReferralCampaign;
  onSuccess: () => void;
};

type ReferralScreenState = {
  inputType: keyof typeof InputTypes;
  value: string;
  error?: string;
};

class ReferralScreen extends React.Component<
  ReferralScreenProps,
  ReferralScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      inputType: InputTypes.email,
      value: '',
      error: '',
    };
    this.sendInvite = this.sendInvite.bind(this);
    this.onInputTypeSelect = this.onInputTypeSelect.bind(this);
  }

  async sendInvite() {
    FirebaseAnalytic.trackEvent('sendInvite', 'ReferralScreen', {
      ...this.props,
      ...this.state,
    });
    const {inputType, value} = this.state;

    if (inputType === InputTypes.email && !isValidEmail(value)) {
      this.setState({
        error: Localized.Errors.please_enter_the_valid_email,
      });
      return;
    }

    if (inputType === InputTypes.text && !isValidPhoneNumber(value)) {
      this.setState({
        error: Localized.Errors.please_enter_valid_phone_number,
      });
      return;
    }

    this.context.actions.showSpinner();
    this.setState({
      error: '',
    });

    try {
      const response = await ReferralActions.sendReferralInvite(
        this.props.referralCampaign.campaignId,
        AccountStore.getAccountId(),
        inputType,
        value,
        Settings.buildType,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'SendReferralInvite',
        JSON.stringify({
          campaignId: this.props.referralCampaign.campaignId,
          accountId: AccountStore.getAccountId(),
          inputType,
          value,
        }),
        JSON.stringify(response),
      );
      const referralResponse = HttpClient.parseJsonSafe(
        response?.message ?? response.toString(),
      );

      if (referralResponse?.code === PlatformApiErrors.AccountAlreadyExists) {
        this.setState({
          error: Localized.Labels.account_already_setup_referral,
        });
        return;
      } else if (
        referralResponse?.code === PlatformApiErrors.CampaignAlreadyExpired
      ) {
        alertError(Localized.Labels.campaign_already_expired);
        return;
      }
      alertSuccess(Localized.Success.sent_successfully, () => {
        this.props.onSuccess();
        NavActions.pop();
      });
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());
      alertError(Localized.Labels.error_sending_the_sms);
      CrashlyticsEvents.log('Exception', 'ReferralScreen:SendInvite', error);
      Events.Error.trackEvent('Exception', 'ReferralScreen:SendInvite', error);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'SendReferralInvite-Error',
        JSON.stringify({
          inputType,
          campaignId: this.props.referralCampaign.campaignId,
          value,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  label() {
    const {endDate, maxRedeem} = this.props.referralCampaign;
    const formattedEndDate = endDate
      ? moment.utc(endDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
      : endDate;
    const {label, values} =
      formattedEndDate && maxRedeem
        ? {
            label: Localized.Labels.offer_expires_on,
            values: [formattedEndDate, maxRedeem],
          }
        : formattedEndDate
        ? {
            label: Localized.Labels.offer_expires_endDate,
            values: [formattedEndDate],
          }
        : maxRedeem
        ? {
            label: Localized.Labels.offer_expires_redeem,
            values: [maxRedeem],
          }
        : {
            label: '',
            values: [],
          };

    if (label) {
      return (
        <AVText style={styles.expireLabel}>
          {Localized.Labels.formatString(label, ...values)}
        </AVText>
      );
    }
  }

  render() {
    const {inputType, value, error} = this.state;
    const {amount} = this.props.referralCampaign;
    const keyboard = Keyboards[inputType];
    return (
      <BackSubheader title={Localized.Labels.invite_friends}>
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView contentContainerStyle={styles.content}>
            <InviteFriends size={3 * Styles.Fonts.f6} />
            <AVText
              style={styles.inviteFriendLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Labels.invite_friends_and_get}
            </AVText>
            <AVText
              style={styles.amountLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Util.formatCurrency(
                this.props,
                amount,
                AccountStore.getCurrency(),
              )}
            </AVText>
            <AVText
              style={styles.cashSpendLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Labels.in_cash_to_spend_in_app}
            </AVText>
            {this.label()}

            <View style={styles.buttonsView}>
              <AVTouchableOpacity
                onPress={() => this.onInputTypeSelect(InputTypes.email)}
                accessibilityLabel="Email Input"
              >
                <AVText
                  style={[
                    styles.type,
                    inputType !== InputTypes.email && styles.disabled,
                  ]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                >
                  {Localized.Labels.email}
                </AVText>
              </AVTouchableOpacity>

              <AVTouchableOpacity
                onPress={() => this.onInputTypeSelect(InputTypes.text)}
                accessibilityLabel="Phone Number Input"
              >
                <AVText
                  style={[
                    styles.type,
                    inputType !== InputTypes.text && styles.disabled,
                    {
                      marginLeft: Styles.Spacing.m2,
                    },
                  ]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                >
                  {Localized.Labels.text}
                </AVText>
              </AVTouchableOpacity>
            </View>
            <TextInput
              accessibilityLabel="Email"
              value={value}
              autoFocus={false}
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="next"
              keyboardType={keyboard.type as KeyboardType}
              maxLength={keyboard.length}
              onChangeText={(e) => {
                this.setState({
                  error: undefined,
                  value: e,
                });
              }}
              placeholder={
                inputType === InputTypes.email
                  ? Localized.Labels.email_address
                  : Localized.Labels.text
              }
              clearButtonMode="while-editing"
              style={styles.textField}
            />
            <AVText
              style={styles.errorLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {error}
            </AVText>
          </ScrollView>
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.sendInvite}
            accessibilityLabel="Send Invite"
            text={Localized.Buttons.send_invite}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          />
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }

  onInputTypeSelect(inputType) {
    this.setState({
      inputType,
      error: undefined,
      value: '',
    });
  }
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: Styles.Spacing.m3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inviteFriendLabel: {
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Styles.darkColor,
  },
  amountLabel: {
    fontSize: Styles.Fonts.f5,
    fontWeight: 'bold',
    color: Styles.balanceColor,
  },
  cashSpendLabel: {
    fontSize: Styles.Fonts.f1,
  },
  expireLabel: {
    color: Styles.balanceColor,
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f0,
    textAlign: 'center',
  },
  rewardLabel: {
    color: Styles.balanceColor,
    fontSize: Styles.Fonts.f0,
  },
  buttonsView: {
    flexDirection: 'row',
    marginTop: Styles.Spacing.m3,
    alignSelf: 'flex-start',
  },
  textField: {
    marginTop: Styles.Spacing.m2,
    alignSelf: 'stretch',
    height: Styles.Heights.h3,
  },
  type: {
    color: Styles.white,
    borderRadius: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    backgroundColor: Styles.primaryColor,
    fontWeight: 'bold',
  },
  disabled: {
    backgroundColor: Styles.lightGray,
  },
  errorLabel: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
    marginBottom: Styles.Spacing.m6,
  },
});
export default withForwardedNavigationParams<ReferralScreenProps>()(
  ReferralScreen,
);
