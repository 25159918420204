import {Radix} from 'src/services/bluetooth/Encoding';
export type VendingMachineSetting = {
  CompanyOnly: boolean;
  GmaOnly: boolean;
  Key: string;
  Label: string;
  Type: number;
  Value: string;
};
type AuthResult = {
  AuthorizationAmount: number;
  TimeoutBetweenVends: number;
  TransactionId: string;
  TransactionType: string;
};
const defaults = {
  AuthAmount: 5,
  TimeBetweenVends: 30,
  VendsPerTransaction: 3,
  DiscountAmount: 0,
};
export default class VendingMachineConfig {
  readonly AuthorizationAmount: number = defaults.AuthAmount;
  readonly TransactionId: string = '';
  readonly TimeoutBetweenVends: number = defaults.TimeBetweenVends;
  readonly VendsPerTransaction: number = defaults.VendsPerTransaction;
  readonly DiscountAmount: number = defaults.DiscountAmount;

  constructor(auth: AuthResult, settings: VendingMachineSetting[]) {
    this.AuthorizationAmount = auth.AuthorizationAmount;
    this.TransactionId = auth.TransactionId;
    this.TimeoutBetweenVends = auth.TimeoutBetweenVends;
    const parsedSettings = this.parseSettings(settings);
    this.VendsPerTransaction = parseInt(
      parsedSettings.VENDS_PER_TRANSACTION || defaults.VendsPerTransaction,
      Radix.Decimal,
    );
    this.DiscountAmount = parseInt(
      parsedSettings.DISC_AMOUNT || 0,
      Radix.Decimal,
    );
  }

  parseSettings(settings: VendingMachineSetting[]): any {
    const parsedSettings = {};

    if (!settings) {
      return parsedSettings;
    }

    settings.forEach(
      (setting) => (parsedSettings[setting.Key] = setting.Value),
    );
    return parsedSettings;
  }
}
