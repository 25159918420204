import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import BackSubheader from '../../elements/BackSubheader';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import Styles from '../../Styles';
export default function PayrollDeductAgreementScreen() {
  return (
    <BackSubheader title={Localized.Labels.payroll_deduct_agreement}>
      <ScrollView contentContainerStyle={styles.content}>
        <AVText style={styles.agreementText}>
          {Localized.Labels.payroll_deduct_agreement_text}
        </AVText>
      </ScrollView>
    </BackSubheader>
  );
}
const styles = StyleSheet.create({
  agreementText: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
  },
  content: {
    padding: Styles.Spacing.m3,
  },
});
