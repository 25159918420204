import {createSlice} from '@reduxjs/toolkit';
import remoteConfig, {
  FirebaseRemoteConfigTypes,
} from '@react-native-firebase/remote-config';
import {Platform} from 'react-native';

import PersistentStore from 'src/services/PersistentStoreService';
import {
  isLatestAppVersionAvailable,
  isSoftDialogRenderRemaining,
  isAlertIntervalChangedOnFBaseRemote,
  isUserAppVersionBelowMinimum,
  isUserInAllowedEmailListForForceUpdate,
  getAlertPayload,
  getAppVersion,
} from './firebaseLocalRead';
import Logger from 'src/logging/Logger';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const firebBaseRemoteConfigSlice = createSlice({
  name: 'firebaseRemoteConfig',
  initialState,
  reducers: {
    fetchRemoteConfigStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    fetchRemoteConfigSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchRemoteConfigFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const fetchRemoteConfig =
  (forceRefetch: boolean) => async (dispatch, getState) => {
    const currentState = getState();
    if (isAlertStateUnavailable(currentState) || forceRefetch) {
      try {
        dispatch(fetchRemoteConfigStart());
        await remoteConfig().fetch(0);
        await remoteConfig().fetchAndActivate();
        const data = remoteConfig().getAll();
        processRemoteConfig(data, dispatch);
      } catch (error) {
        dispatch(fetchRemoteConfigFailure(error.message));
      }
    }
  };

const processRemoteConfig = async (fetchedRemotely, dispatch) => {
  await checkAppLocalDebugLog(fetchedRemotely);
  const fireBaseConfig = await convertToHashMap(fetchedRemotely);

  if (await shouldShowForceUpdateAlert(fireBaseConfig)) {
    dispatch(fetchRemoteConfigSuccess(getAlertPayload(fireBaseConfig, false)));
  } else if (await shouldShowSoftUpdateAlert(fireBaseConfig)) {
    dispatch(fetchRemoteConfigSuccess(getAlertPayload(fireBaseConfig, true)));
  }
  // In Any case of appVersionUpdate, intervalUpdate, skipDialogUpdate, renderCount update
  // store the incoming json
  PersistentStore.setFireBaseRemoteConfig(JSON.stringify(fetchedRemotely));
};

const shouldShowSoftUpdateAlert = async (fireBaseConfig) => {
  let showSoftDialog = false;
  const appVersion = await getAppVersion();
  const showSoftAlertDialog = fireBaseConfig
    .get(`${Platform.OS}_soft_update_status`)
    .asBoolean();
  const isLatestAppVersion = isLatestAppVersionAvailable(
    fireBaseConfig,
    appVersion,
  );
  if (showSoftAlertDialog && isLatestAppVersion) {
    const isAlertPendingToShow = await isSoftDialogRenderRemaining(
      fireBaseConfig,
    );
    const isAlertIntervalChangedOnFBase =
      await isAlertIntervalChangedOnFBaseRemote(fireBaseConfig);
    showSoftDialog = isAlertPendingToShow || isAlertIntervalChangedOnFBase;
  } else {
    showSoftDialog = false;
  }
  return showSoftDialog;
};

const shouldShowForceUpdateAlert = async (fireBaseConfig): Promise<boolean> => {
  const forceUpdateAlert = fireBaseConfig
    .get(`${Platform.OS}_force_update_status`)
    .asBoolean();
  const appVersion = await getAppVersion();
  const isUserBelowMinAppVersion = isUserAppVersionBelowMinimum(
    fireBaseConfig,
    appVersion,
  );
  const isUserAllowedToSeeForceUpdate =
    isUserInAllowedEmailListForForceUpdate(fireBaseConfig);
  return (
    isUserBelowMinAppVersion &&
    forceUpdateAlert &&
    isUserAllowedToSeeForceUpdate
  );
};

export const isAlertStateUnavailable = (currentState) => {
  const sessionData = currentState.firebaseConfig;
  return sessionData.data == null || sessionData.data == undefined;
};

const checkAppLocalDebugLog = async (fireBaseConfig) => {
  const localDebugLogEnabled =
    fireBaseConfig?.app_local_debug_log_enabled?._value;
  const localDebugLogEnv =
    fireBaseConfig?.app_local_debug_log_environment?._value;
  const localDebugLogEmail =
    fireBaseConfig?.app_local_debug_log_user_email_address?._value;
  await Logger.Log.setupLocalDebugLog(
    localDebugLogEnabled,
    localDebugLogEmail,
    localDebugLogEnv,
  );
};

/**
 * Convert firebase hashMap to independent hashMap
 * to be used internally and type casting
 * extracting of persisted firbease data
 * @param data firebase hashmap
 * @returns independent hashMap of type
 */
const convertToHashMap = async (data) => {
  const fetchedRemotely = data;
  const fireBaseConfig = new Map<
    string,
    FirebaseRemoteConfigTypes.ConfigValue
  >();
  Object.entries(fetchedRemotely).forEach(($) => {
    const [key, entry] = $;
    const value = entry as FirebaseRemoteConfigTypes.ConfigValue;
    if (key.indexOf(Platform.OS) != -1) {
      fireBaseConfig.set(key, value);
    }
  });
  return fireBaseConfig;
};

export const {
  fetchRemoteConfigStart,
  fetchRemoteConfigSuccess,
  fetchRemoteConfigFailure,
} = firebBaseRemoteConfigSlice.actions;

export default firebBaseRemoteConfigSlice.reducer;
