import React from 'react';
import {View, StyleSheet} from 'react-native';
import Styles from '../Styles';
type ProgressBarProps = {
  progress: number;
  color?: string;
  unfilledColor?: string;
};
export default function ProgressBar({
  progress,
  color = Styles.primaryColor,
  unfilledColor = Styles.lightGray,
}: ProgressBarProps) {
  if (progress > 1) {
    progress = 1;
  }

  if (progress < 0) {
    progress = 0;
  }

  const remaining = 1 - progress;
  return (
    <View
      style={[
        styles.statusProgressContainer,
        {
          backgroundColor: unfilledColor,
        },
      ]}
    >
      <View
        style={{
          flex: progress,
          backgroundColor: color,
          borderRadius: Styles.Spacing.m2,
        }}
      />
      <View
        style={{
          flex: remaining,
        }}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  statusProgressContainer: {
    height: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m2,
    flexDirection: 'row',
  },
});
