import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';

export const IonicIconTypes = [
  'ios-alert',
  'ios-arrow-down',
  'ios-arrow-forward',
  'ios-backspace',
  'ios-card',
  'ios-heart',
  'ios-person',
  'md-alert',
  'md-apps',
  'md-checkmark-circle',
  'md-search',
  'scan-outline',
] as const;

export type IonicIconType = (typeof IonicIconTypes)[number];

type IonicIconProps = {
  name: IonicIconType;
} & React.ComponentProps<typeof Icon>;

export default function IonicIcon(props: IonicIconProps): JSX.Element {
  return <Icon {...props} />;
}
