type RationaleType = {
  title: string;
  message: string;
};
type RequirePermissionProps = {
  permission: string;
  rationale?: RationaleType;
  onPermissionChange?: (granted: string) => void;
};
export default function RequirePermission(props: RequirePermissionProps) {
  return null;
}
