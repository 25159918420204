import React from 'react';
import {View, StyleSheet, ScrollView, Platform} from 'react-native';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../ScreenContext';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import BackSubheader from '../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import withIsConnected from '../hoc/withIsConnected';
import Styles from '../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../elements/ui/UIManager';
import type {RewardType} from 'src/types/Rewards';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../helpers/AlertHelper';
import AVText from '../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import {RootState, store} from 'src/redux/store';
import {
  adjustDefaultBalance,
  adjustPoints,
} from 'src/redux/slices/accountSlice';
import {connect} from 'react-redux';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type RewardsScreenProps = IsConnectedProps & {
  availablePoints: number;
  currentBalance: number;
  navigation?: NavigationProp<RewardsScreen>;
};
type RewardsScreenState = {
  rewardSteps: Array<RewardType>;
  availablePoints: number;
  currentBalance: number;
  previousRoute?: string | null;
};

class RewardsScreen extends React.Component<
  RewardsScreenProps,
  RewardsScreenState
> {
  redeeming: boolean;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: RewardsScreenProps) {
    super(props);
    this.state = {
      rewardSteps: [],
      availablePoints: props.availablePoints,
      currentBalance: props.currentBalance,
      previousRoute: null,
    };
    this.redeeming = false;
    this.redeemRewards = this.redeemRewards.bind(this);
  }

  async componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );

    let rewardSteps;
    try {
      const response = AccountStore.getThresholdsResponse();

      if (response?.status === 'ok') {
        rewardSteps = response.data;
      } else {
        alertError(Localized.Errors.error);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'RewardsScreen:Constructor',
        error.message ? error.message : error.toString(),
        guid,
        rewardSteps,
      );
      Events.Error.trackEvent(
        'Exception',
        'RewardsScreen:Constructor',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.error, guid);
    }

    this.setState(
      {
        rewardSteps,
        previousRoute,
      },
      () => {
        FirebaseAnalytic.trackEvent('componentDidMount', 'RewardsScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.availablePoints !== prevState.availablePoints) {
      return {
        availablePoints: nextProps.availablePoints,
        currentBalance: nextProps.currentBalance,
      };
    }
  }

  async redeemRewards(points: number, redeemValue: number) {
    if (!this.redeeming) {
      try {
        this.redeeming = true;
        await ActionsFactory.getAccountActions().redeemRewards(
          AccountStore.getAccountId(),
          points,
          Util.getCurrentDate(),
        );
        Events.RewardsRedeem.trackEvent(points, redeemValue);
        alertSuccess(
          Localized.Success.formatString(
            Localized.Success.hasBeenAddedToYourAccount,
            Util.formatCurrency(
              this.props,
              redeemValue,
              AccountStore.getCurrency(),
            ),
          ),
          () => {
            store.dispatch(
              adjustDefaultBalance({
                amount: redeemValue,
                reason: Localized.Labels.redeem,
              }),
            );
            store.dispatch(adjustPoints({points}));
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RewardsScreen:RedeemRewards',
          error.message ? error.message : error.toString(),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RewardsScreen:RedeemRewards',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.error, guid);
      } finally {
        this.redeeming = false;
      }
    }
  }

  render() {
    let i = 0;
    const currency = AccountStore.getDisplayCurrency();

    const redeemList = this.state.rewardSteps.map((reward) => {
      const available = reward.points <= this.state.availablePoints;
      return (
        <View
          key={i++}
          style={[styles.redeemList, available && styles.enableRedeemList]}
        >
          <View
            accessible={true}
            accessibilityRole={'text'}
            style={styles.amountAndCurrencyContainer}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={false}
              style={styles.currency}
            >
              {currency}
            </AVText>
            <AVText
              adjustsFontSizeToFit
              numberOfLines={1}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessible={false}
              style={styles.redeemAmount}
            >
              {reward.redeemvalue}
            </AVText>
          </View>

          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={styles.redeemExplanation}
          >
            {Localized.Labels.formatString(
              Localized.Labels.redeem_explanation,
              Util.formatNumber(this.props, reward.points, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }),
              `${currency}${reward.redeemvalue}`,
            )}
          </AVText>
          {this.props.isConnected && (
            <RoundedButton
              accessibilityLabel={`Redeem ${reward.points} points`}
              text={Localized.Labels.redeem}
              onPress={() =>
                this.redeemRewards(reward.points, reward.redeemvalue)
              }
              containerStyle={styles.btnStyle}
              disabled={!available}
              buttonType={ButtonType.outline}
            />
          )}
        </View>
      );
    });

    return (
      <BackSubheader
        title={Localized.Labels.rewards}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        rightView={
          Platform.OS !== 'web' &&
          UIManager.getBalanceContainer(false, Localized, false)
        }
      >
        <View style={styles.content}>
          {UIManager.getHomeRewardSection(
            this.state.availablePoints,
            this.state.rewardSteps,
            styles.imageContainer,
            false,
          )}
          <ScrollView style={styles.list}>{redeemList}</ScrollView>
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  btnStyle: {
    marginTop: Styles.Spacing.m2,
  },
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  amountAndCurrencyContainer: {
    flex: 0.35,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
  },
  currency: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  enableRedeemList: {
    borderBottomColor: Styles.positiveColor,
  },
  imageContainer: {
    height: 90,
    marginBottom: Styles.Spacing.m4,
    marginTop: -1 * Styles.Spacing.m2,
  },
  list: {
    marginTop: Styles.Spacing.m2,
  },
  redeemAmount: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
  },
  redeemExplanation: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    marginRight: Styles.Spacing.m3,
  },
  redeemList: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m2,
  },
});

const ConnectedRewardsScreen = connect((state: RootState) => ({
  availablePoints: state.account.account.points,
  currentBalance: state.account.account.defaultBalance.balance,
}))(RewardsScreen);

export default withForwardedNavigationParams()(
  withGlobalize(withIsConnected(ConnectedRewardsScreen)),
);
