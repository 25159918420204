import React from 'react';
import NBDropdown from '../NBDropdown';
import Localized from 'src/constants/AppStrings';
import {StyleProp, ViewStyle} from 'react-native';

type BirthYearDropdownProps = {
  style?: StyleProp<ViewStyle>;
  selectedValue: string;
  accessibilityValue?: {[key: string]: string};
  onSelect: (value: string) => void;
  onVisibilityChange: (visible: boolean) => void;
};
const YEARS_BACK = 80;

const getYears = (): Array<{
  text: string;
  value: number;
}> => {
  const currentYear = new Date().getFullYear();
  let startYear = currentYear - YEARS_BACK;
  const years: Array<{value: number; text: string}> = [];

  while (startYear <= currentYear) {
    years.push({
      text: startYear.toString(),
      value: startYear,
    });
    startYear++;
  }

  return years.reverse();
};

const BirthYearDropdown = (props: BirthYearDropdownProps) => {
  const years = getYears();
  return (
    <NBDropdown
      accessible={true}
      accessibilityLabel={Localized.Labels.birth_year}
      accessibilityRole="menu"
      accessibilityValue={{text: props.selectedValue}}
      aria-label={Localized.Labels.birth_year}
      role="menu"
      aria-valuetext={props.selectedValue}
      label={Localized.Labels.birth_year}
      selectedValue={props.selectedValue}
      style={props.style}
      onSelect={props.onSelect}
      onVisibilityChange={props.onVisibilityChange}
      options={years}
    />
  );
};

export default BirthYearDropdown;
