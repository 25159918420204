// this is an auto generated file. This will be overwritten
export const createMessage = `mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    id
    subject
    message
    from
    date
    type
    value
    icon
    read
    owner
  }
}
`;
export const updateMessage = `mutation UpdateMessage($input: UpdateMessageInput!) {
  updateMessage(input: $input) {
    id
    subject
    message
    from
    date
    type
    value
    icon
    read
    owner
  }
}
`;
export const deleteMessage = `mutation DeleteMessage($input: DeleteMessageInput!) {
  deleteMessage(input: $input) {
    id
    subject
    message
    from
    date
    type
    value
    icon
    read
    owner
  }
}
`;
export const createDeal =
  /* GraphQL */
  `
    mutation CreateDeal($input: CreateDealInput!) {
      createDeal(input: $input) {
        id
        dealId
        description
        endDate
        name
        currentGoalAmount
        currentGoalQuantity
        targetGoalAmount
        targetGoalQuantity
        dealStatus
        owner
        legalese
        image
        redeemedCount
        numberOfUsesPerCode
      }
    }
  `;
export const updateDeal =
  /* GraphQL */
  `
    mutation UpdateDeal($input: UpdateDealInput!) {
      updateDeal(input: $input) {
        id
        dealId
        description
        endDate
        name
        currentGoalAmount
        currentGoalQuantity
        targetGoalAmount
        targetGoalQuantity
        dealStatus
        owner
        legalese
        image
        redeemedCount
        numberOfUsesPerCode
      }
    }
  `;
export const deleteDeal =
  /* GraphQL */
  `
    mutation DeleteDeal($input: DeleteDealInput!) {
      deleteDeal(input: $input) {
        id
        dealId
        description
        endDate
        name
        currentGoalAmount
        currentGoalQuantity
        targetGoalAmount
        targetGoalQuantity
        dealStatus
        owner
        legalese
        image
        redeemedCount
        numberOfUsesPerCode
      }
    }
  `;
