import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import NavActions from 'src/actions/NavActions';
import ReferralActions from 'src/actions/ReferralActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import CreateAccountPrivacyPolicyHandler from 'src/components/elements/createAccount/CreateAccountPrivacyPolicyHandler';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import SafeAreaView from 'src/components/elements/SafeAreaView';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Events from 'src/logging/Events';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import Logo from 'src/components/elements/Logo';
import {Referral} from 'src/types/Referral';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import {useGlobalize} from 'react-native-globalize';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ReferralLandingProps = {
  referralId: string;
};

const ReferralLandingScreen: React.FC<ReferralLandingProps> = (
  props: ReferralLandingProps,
) => {
  const {formatCurrency} = useGlobalize();
  const [referral, setReferral] = useState<Referral>();
  const privacyHandler = useRef<CreateAccountPrivacyPolicyHandler>(null);

  useEffect(() => {
    const fetchReferral = async () => {
      try {
        const details = await ReferralActions.getReferralDetails(
          props.referralId,
        );
        FirebaseAnalytic.trackEvent(
          'useEffect fetchReferral',
          'ReferralLandingScreen',
          {
            ...props,
            details,
          },
        );
        setReferral(details);
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'ReferralLandingScreen:GetReferralDetails',
          error.message ? error.message : error.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'ReferralLandingScreen:GetReferralDetails',
          error.message ? error.message : error.toString(),
        );
      }
    };
    fetchReferral();
  }, []);

  const onPress = () => {
    FirebaseAnalytic.trackEvent('onPress', 'ReferralLandingScreen', {
      ...props,
      referral,
    });
    if (Settings.buildType === BuildTypes.pantry) {
      NavActions.replace(AppRoutes.PantryMarketSearch, {referral});
    } else if (privacyHandler) {
      privacyHandler.current.showPrivacyPolicy();
    }
  };
  return !referral ? (
    <LoadingScreen />
  ) : (
    <SafeAreaView style={styles.container}>
      <View style={styles.logo}>
        <Logo />
      </View>
      {referral?.isActive && referral?.receiver === null ? (
        <>
          <AVText style={styles.title}>
            {Localized.Labels.great_deals_your_free}
          </AVText>
          <AVText style={styles.amount}>
            {formatCurrency(referral?.amount ?? 0, AccountStore.getCurrency())}
          </AVText>
          <AVText style={styles.text}>
            {Localized.Labels.is_just_few_steps_away}
          </AVText>
        </>
      ) : (
        <>
          <AVText
            style={[
              styles.title,
              {
                marginBottom: Styles.Spacing.m3,
              },
            ]}
          >
            {Localized.Labels.unfortunately_your_reward_has_expired}
          </AVText>
          <AVText style={styles.text}>
            {Localized.Labels.but_savings_and_convenience}
          </AVText>
        </>
      )}
      <CreateAccountPrivacyPolicyHandler
        ref={privacyHandler}
        params={{
          referral,
        }}
      />
      <RoundedButton
        text={Localized.Buttons.lets_do_it}
        buttonType={ButtonType.action}
        onPress={onPress}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: Styles.Spacing.m4,
  },
  logo: {
    marginVertical: Styles.Spacing.m4,
  },
  title: {
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f2,
    textAlign: 'center',
  },
  amount: {
    fontSize: Styles.Fonts.f6,
    fontWeight: 'bold',
    color: Styles.balanceColor,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
  },
});

export default withForwardedNavigationParams<ReferralLandingProps>()(
  ReferralLandingScreen,
);
