import AsyncStorage from '@react-native-async-storage/async-storage';
import InAppReview from 'react-native-in-app-review';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';

let hasSeenPrompt: boolean = null;
let transactionCount: number = null;

class AppRatingService {
  async getData() {
    try {
      const userHasSeenPrompt = await AsyncStorage.getItem('hasSeenPrompt');
      if (userHasSeenPrompt === null) {
        hasSeenPrompt = false;
      } else {
        hasSeenPrompt = userHasSeenPrompt === 'true';
      }

      const count = await AsyncStorage.getItem('transactionCount');
      if (count === null) {
        transactionCount = 0;
      } else {
        transactionCount = parseInt(count);
      }
    } catch (error) {
      FirebaseAnalytic.trackEvent('getData()', 'AppRatingService', {
        error,
      });
    }
  }

  async setData() {
    await AsyncStorage.setItem('hasSeenPrompt', hasSeenPrompt.toString());
    await AsyncStorage.setItem('transactionCount', transactionCount.toString());
  }

  async showPrompt() {
    if (InAppReview.isAvailable()) {
      await this.getData();

      if (
        (hasSeenPrompt === false && transactionCount === 3) ||
        (hasSeenPrompt && transactionCount === 20)
      ) {
        InAppReview.RequestInAppReview()
          .then(async (hasFlowFinishedSuccessfully: boolean) => {
            if (hasFlowFinishedSuccessfully) {
              return this.updateValues();
            }
          })
          .catch((error: string) => {
            FirebaseAnalytic.trackEvent('showPrompt()', 'AppRatingService', {
              error,
            });
          });
      } else {
        return this.updateValues();
      }
    }
  }

  /**
   * First time: ask the user after 3 transactions
   * After that, prompt after 20 transactions
   */
  async updateValues() {
    if (hasSeenPrompt === false) {
      if (transactionCount < 3) {
        hasSeenPrompt = false;
        transactionCount = transactionCount + 1;
      } else {
        hasSeenPrompt = true;
        transactionCount = 0;
      }
    } else if (hasSeenPrompt === true) {
      hasSeenPrompt = true;
      if (transactionCount < 20) {
        transactionCount = transactionCount + 1;
      } else {
        transactionCount = 0;
      }
    }
    await this.setData();
  }
}

export default new AppRatingService();
