import * as React from 'react';
import {Platform, StyleSheet} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import AVText from './AVText';
import Styles from '../Styles';
import AVTouchableOpacity from './AVTouchableOpacity';

type ButtonType = 'Add' | 'Add Later' | 'Delete' | 'Search' | 'None';
type HeaderButtonProps = {
  label: string;
  type: ButtonType;
  onPress: () => void;
};
const color = Platform.select({
  web: Styles.lightGray,
  default: Styles.white,
});

export default function HeaderButton(props: HeaderButtonProps) {
  function Label() {
    return (
      <AVText numberOfLines={1} style={styles.label}>
        {props.label}
      </AVText>
    );
  }

  function Touchable(touchableProps: {children: React.ReactNode}) {
    return (
      <AVTouchableOpacity
        accessible={true}
        accessibilityLabel={props.type + ', ' + props.label}
        accessibilityRole="button"
        aria-label={props.type + ', ' + props.label}
        role="button"
        style={styles.container}
        onPress={props.onPress}
      >
        {touchableProps.children}
      </AVTouchableOpacity>
    );
  }

  switch (props.type) {
    case 'Add':
      return (
        <Touchable>
          <FontAwesome5Pro name="plus" color={color} size={Styles.Fonts.f3} />
          {Platform.OS === 'web' && <Label />}
        </Touchable>
      );

    case 'Add Later':
      return (
        <Touchable>
          <Label />
        </Touchable>
      );

    case 'Delete':
      return (
        <Touchable>
          <FontAwesome5Pro
            name="trash-alt"
            color={color}
            size={Styles.Fonts.f2}
            light
          />
          {Platform.OS === 'web' && <Label />}
        </Touchable>
      );

    case 'Search':
      return (
        <Touchable>
          <FontAwesome5Pro name="search" color={color} size={Styles.Fonts.f3} />
          {Platform.OS === 'web' && <Label />}
        </Touchable>
      );

    case 'None':
    default:
      return null;
  }
}
const styles = StyleSheet.create({
  container: {
    minHeight: Styles.Heights.touchTargetHeight1,
    minWidth: Styles.Heights.touchTargetHeight1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: Styles.Spacing.m1,
  },
  label: {
    alignSelf: 'center',
    color,
    fontSize: Styles.Fonts.f1,
    paddingStart: Styles.Spacing.m2,
  },
});
