import {handleNextAction, initStripe} from 'src/nativeModules/Stripe';
import type {ScreenProps} from 'src/types/Screen';
import ApplePay from 'src/nativeModules/ApplePay';
import Util from 'src/Util';
import Events from 'src/logging/Events';
import ActionsFactory from 'src/actions/ActionsFactory';
import {get2LetterCountryCode, getCurrency} from 'src/constants/Countries';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {store} from 'src/redux/store';
import GooglePay from 'src/nativeModules/GooglePay';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import AppRatingService from 'src/services/AppRatingService';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class FundingService {
  async getPaymentIntent(token: string, amount: number) {
    const paymentIntentResponse: any =
      await ActionsFactory.getAccountActions().createPaymentIntent(
        AccountStore.getAccountId(),
        token,
        amount,
        Util.getCurrentDate(),
      );
    return paymentIntentResponse;
  }

  async addFundsApplePay(
    amount: number,
    props: ScreenProps,
    errorHandle: () => void,
    successCallback: () => void,
  ) {
    Events.Info.trackEvent('FundingService:AddFundsApplePay', {amount});

    props.actions.showSpinner(`${Localized.Labels.adding_funds}...`);
    let sandbox = false;
    let processor = Settings.processors.heartland;

    try {
      const paymentCredentials = store.getState().account.paymentCredentials;
      processor = Settings.processors[paymentCredentials.type];
      sandbox = paymentCredentials.isTest;
      Events.Info.trackEvent(
        'FundingService:AddFundsApplePay paymentCredentials',
        {
          amount,
          sandbox,
          processor,
          paymentCredentials,
        },
      );

      let clientSecret = '';

      if (processor === Settings.processors.stripe) {
        const response =
          await ActionsFactory.getAccountActions().fetchPaymentIntentClientSecret(
            AccountStore.getAccountId(),
            AccountStore.getAccountBalanceId(),
            amount,
            Util.getCurrentDate(),
            processor,
            sandbox,
            'applepay',
          );

        clientSecret = response.clientSecret;
      }

      const paymentData = await ApplePay.presentApplePay(
        amount,
        get2LetterCountryCode(AccountStore.getRegion()),
        AccountStore.getCurrency() || getCurrency(AccountStore.getRegion()),
        sandbox,
        processor,
        clientSecret,
        paymentCredentials.key,
        paymentCredentials.merchantId,
      );

      Events.Info.trackEvent(
        'FundingService:AddFundsApplePay paymentMethodId ' + processor,
        {paymentData},
      );

      //Check call API Stripe
      const applePayResult =
        await ActionsFactory.getAccountActions().addFundsApplePay(
          paymentData,
          AccountStore.getAccountId(),
          AccountStore.getAccountBalanceId(),
          amount,
          Util.getCurrentDate(),
          sandbox,
          processor,
        );

      Events.Info.trackEvent(
        'FundingService:AddFundsApplePay applePayResult',
        applePayResult,
      );
      if (applePayResult.status === 'ok') {
        if (processor === Settings.processors.heartland) {
          ApplePay.paymentSucceeded(
            processor,
            applePayResult.clientSecret,
            amount,
            get2LetterCountryCode(AccountStore.getRegion()),
            AccountStore.getCurrency() || getCurrency(AccountStore.getRegion()),
          );
        }
        props.actions.hideSpinner();

        successCallback();
        await AppRatingService.showPrompt();
      } else {
        throw new Error(applePayResult);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'FundingService:AddFundsApplePay',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFundsApplePay',
        error.message ? error.message : error.toString(),
      );

      props.actions.hideSpinner();
      ApplePay.paymentFailed(processor);
      errorHandle();
    }
  }

  async addFundsGooglePay(
    amount: number,
    props: ScreenProps,
    successCallback: () => void,
    errorCallback: () => void,
  ) {
    props.actions.showSpinner(`${Localized.Labels.adding_funds}...`);
    let sandbox = false;
    let processor = Settings.processors.stripe;

    try {
      const paymentCredentials = store.getState().account.paymentCredentials;
      processor = Settings.processors[paymentCredentials.type];
      sandbox = paymentCredentials.isTest;
      const currencyCode =
        AccountStore.getCurrency() || getCurrency(AccountStore.getRegion());

      FirebaseAnalytic.trackEvent(
        'addFundsGooglePay',
        'GooglePayFundingService',
        {
          ...props,
          paymentAvailable: paymentCredentials.paymentAvailable,
          googlePayAvailable: paymentCredentials.googlePayAvailable,
          isTest: paymentCredentials.isTest,
          type: paymentCredentials.type,
          url: paymentCredentials.url,
          countryCode: get2LetterCountryCode(AccountStore.getRegion()),
          amount,
          currencyCode,
          processor,
          sandbox,
        },
      );

      if (processor === Settings.processors.stripe) {
        const response =
          await ActionsFactory.getAccountActions().fetchPaymentIntentClientSecret(
            AccountStore.getAccountId(),
            AccountStore.getAccountBalanceId(),
            amount,
            Util.getCurrentDate(),
            processor,
            sandbox,
            'googlepay',
          );
        if (response && response?.clientSecret) {
          await GooglePay.presentGooglePay(
            amount,
            get2LetterCountryCode(AccountStore.getRegion()),
            currencyCode,
            processor,
            paymentCredentials.key,
            response?.clientSecret,
          );

          props.actions.hideSpinner();
          successCallback();
          await AppRatingService.showPrompt();
        } else {
          props.actions.hideSpinner();
          errorCallback();
        }
      } else {
        const token = await GooglePay.presentGooglePay(
          amount,
          get2LetterCountryCode(AccountStore.getRegion()),
          currencyCode,
          processor,
          paymentCredentials.merchantId,
        );
        const result =
          await ActionsFactory.getAccountActions().addFundsGooglePay(
            token,
            AccountStore.getAccountId(),
            AccountStore.getAccountBalanceId(),
            amount,
            Util.getCurrentDate(),
            sandbox,
            processor,
          );

        props.actions.hideSpinner();
        if (result.status === 'ok') {
          successCallback();
          await AppRatingService.showPrompt();
        } else {
          errorCallback();
        }
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'FundingService:AddFundsGooglePay',
        error?.message ?? error?.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFundsGooglePay',
        error?.message ?? error?.toString(),
      );
      props.actions.hideSpinner();
      errorCallback();
    }
  }

  async addFundsPayroll(
    amount: number,
    payrollMkiId: string,
    transDate: string,
    props: ScreenProps,
    successCallback: () => void,
    errorCallback: (message?: string) => void,
  ) {
    props.actions.showSpinner(`${Localized.Labels.adding_funds}...`);

    try {
      const response: any =
        await ActionsFactory.getAccountActions().addFundsPayroll(
          AccountStore.getAccountId(),
          AccountStore.getAccountBalanceId(),
          payrollMkiId,
          amount,
          transDate,
        );

      if (response.status === 'ok') {
        props.actions.hideSpinner();
        successCallback();
        await AppRatingService.showPrompt();
      } else {
        props.actions.hideSpinner();
        errorCallback(response.message);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'FundingService:AddFundsPayroll',
        error?.message ?? error?.toString() ?? '',
      );
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFundsPayroll',
        error?.message ?? error?.toString() ?? '',
      );
      props.actions.hideSpinner();
      errorCallback();
    }
  }

  async addFundsStripe(token: string, amount: number) {
    const paymentIntentResponse = await this.getPaymentIntent(token, amount);

    if (paymentIntentResponse.status !== 'ok') {
      return false;
    }

    if (!paymentIntentResponse.data) {
      return true;
    }

    const paymentCredentials = store.getState().account.paymentCredentials;
    initStripe({
      publishableKey: paymentCredentials.key,
    });
    const {error, paymentIntent} = await handleNextAction(
      paymentIntentResponse.data.clientSecret,
    );
    if (error) {
      CrashlyticsEvents.log(
        'Exception',
        'FundingService:AddFunds',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFunds',
        error.message ? error.message : error.toString(),
      );
      return false;
    }

    const response: any =
      await ActionsFactory.getAccountActions().confirmPaymentIntent(
        AccountStore.getAccountId(),
        paymentIntent.id,
        paymentIntentResponse.data.transId,
      );

    return response.status === 'ok';
  }
}

export default new FundingService();
