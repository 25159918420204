export enum BalanceTypes {
  Default = 'DEFAULT',
  Stripe = 'STRIPE',
  USConnect = 'USConnect',
  Payroll = 'PAYROLL',
  TopOff = 'TOP_OFF',
  RollOver = 'ROLL_OVER',
  Split = 'SPLIT',
}
export enum IdentifierTypes {
  ScanCode = 0,
  FingerPrint = 1,
  QrCode = 2,
  TaxId = 4,
  Vas = 5,
  PayrollId = 6,
  USConnect = 7,
  ConsumerRole = 8,
}
export type Identifier = {
  accountIdentifierId: string;
  value: string | null | undefined;
  type: number | null | undefined;
  orgId: string | null | undefined;
  locationId: string | null | undefined;
  accountId: string | null | undefined;
  dateCreated: string | null | undefined;
  lastUpdated: string | null | undefined;
  isDisabled: boolean | null | undefined;
  usePin: boolean | null | undefined;
  accountBalanceId: string | null | undefined;
  scope: string;
};
export type Balance = {
  accountBalanceId: string;
  accountId: string;
  type: string;
  balance: number;
  currency: string;
  dateCreated: string | null | undefined;
  lastUpdated: string | null | undefined;
  displayName: string | null | undefined;
  orgId: string | null | undefined;
  locationId: string | null | undefined;
  isDisabled: boolean;
};
type Localization = {
  currency: string | null | undefined;
  locale: string | null | undefined;
  region: string | null | undefined;
};
type Address = {
  street1: string | null | undefined;
  street2: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  country: string | null | undefined;
  postalCode: string | null | undefined;
};
type Privacy = {
  version: number | null | undefined;
  date: string | null | undefined;
};
type Biometric = {
  version: number | null | undefined;
  date: string | null | undefined;
};
type Demographics = {
  birthMonth: number | null | undefined;
  birthYear: number | null | undefined;
  gender: string | null | undefined;
  jobTitle: string | null | undefined;
};
type ServiceAccount = {
  serviceAccountId: string;
  accountId: string;
  serviceType: string;
  dateCreated: string;
  externalId: string | null | undefined;
  email: string | null | undefined;
  disabled: boolean;
};
export type Account = {
  accountId: string;
  orgId: string | null | undefined;
  locationId: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  identifiers: Array<Identifier> | null | undefined;
  balances: Array<Balance> | null | undefined;
  unverifiedEmail: string | null | undefined;
  userName: string | null | undefined;
  points: number;
  phone: string | null | undefined;
  dateCreated: string | null | undefined;
  lastUpdated: string | null | undefined;
  disabled: boolean;
  localization: Localization | null | undefined;
  address: Address | null | undefined;
  pantryGroupId: string | null | undefined;
  privacy: Privacy | null | undefined;
  biometric: Biometric | null | undefined;
  demographics: Demographics | null | undefined;
  paymentCustomerId: string | null | undefined;
  consumerEngagementId: string | null | undefined;
  payrollDeductGroupId: string | null | undefined;
  app: string | null | undefined;
  optedOutPayrollDeduct: boolean;
  serviceAccounts: Array<ServiceAccount> | null | undefined;
  pinHash: string | null | undefined;
  pinSalt: string | null | undefined;
  sendaSnackEnabled: boolean | null | undefined;
  appTour: string | null | undefined;
};
export type PaymentToken = {
  balanceTokenId: string;
  accountId: string;
  accountBalanceId: string;
  dateCreated: string | null | undefined;
  lastUpdated: string;
  provider: number;
  providerKey: string;
  cardIssuer: string;
  cardHolder: string | null | undefined;
  expirationMonth: number;
  expirationYear: number;
  name: string;
  cardBrandTransactionId: string | null | undefined;
  isDefault: boolean;
};
export type PaymentCredentials = {
  url: string | null | undefined;
  key: string | null | undefined;
  test: boolean | null | undefined;
  type: string;
  merchantId: string | null | undefined;
  useConnect: boolean | null | undefined;
};
export type PayrollDeductInfo = {
  payCycle: string;
  spendLimit: number;
  resetDate: string;
};
