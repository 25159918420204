import Localized from './AppStrings';
export const Countries = {
  usa: {
    label: Localized.Buttons.usa,
    currency: 'USD',
    code2: 'US',
    code3: 'USA',
  },
  canada: {
    label: Localized.Buttons.canada,
    currency: 'CAD',
    code2: 'CA',
    code3: 'CAN',
  },
  uk: {
    label: Localized.Buttons.uk,
    currency: 'GBP',
    code2: 'GB',
    code3: 'GBR',
  },
  italy: {
    label: Localized.Buttons.italy,
    currency: 'EUR',
    code2: 'IT',
    code3: 'ITA',
  },
  australia: {
    label: Localized.Buttons.australia,
    currency: 'AUD',
    code2: 'AU',
    code3: 'AUS',
  },
  switzerland: {
    label: Localized.Buttons.switzerland,
    currency: 'CHF',
    code2: 'CH',
    code3: 'CHE',
  },
  ireland: {
    label: Localized.Buttons.ireland,
    currency: 'EUR',
    code2: 'IE',
    code3: 'IRL',
  },
  netherlands: {
    label: Localized.Buttons.netherlands,
    currency: 'EUR',
    code2: 'NL',
    code3: 'NLD',
  },
  germany: {
    label: Localized.Buttons.germany,
    currency: 'EUR',
    code2: 'DE',
    code3: 'DEU',
  },
  spain: {
    label: Localized.Buttons.spain,
    currency: 'EUR',
    code2: 'ES',
    code3: 'ESP',
  },
  france: {
    label: Localized.Buttons.france,
    currency: 'EUR',
    code2: 'FR',
    code3: 'FRA',
  },
  belgium: {
    label: Localized.Buttons.belgium,
    currency: 'EUR',
    code2: 'BE',
    code3: 'BEL',
  },
  sweden: {
    label: Localized.Buttons.sweden,
    currency: 'SEK',
    code2: 'SE',
    code3: 'SWE',
  },
  denmark: {
    label: Localized.Buttons.denmark,
    currency: 'DKK',
    code2: 'DK',
    code3: 'DNK',
  },
  norway: {
    label: Localized.Buttons.norway,
    currency: 'NOK',
    code2: 'NO',
    code3: 'NOR',
  },
  luxembourg: {
    label: Localized.Buttons.luxembourg,
    currency: 'EUR',
    code2: 'LU',
    code3: 'LUX',
  },
  austria: {
    label: Localized.Buttons.austria,
    currency: 'EUR',
    code2: 'AT',
    code3: 'AUT',
  },
  finland: {
    label: Localized.Buttons.finland,
    currency: 'EUR',
    code2: 'FI',
    code3: 'FIN',
  },
  new_zealand: {
    label: Localized.Buttons.new_zealand,
    currency: 'NZD',
    code2: 'NZ',
    code3: 'NZL',
  },
};
export const CountryArray = Object.values(Countries).sort(
  (countryA, countryB) => {
    if (countryA.label < countryB.label) {
      return -1;
    }

    if (countryA.label > countryB.label) {
      return 1;
    }

    return 0;
  },
);
export function get2LetterCountryCode(countryCode3Letter) {
  const result = CountryArray.find((c) => c.code3 === countryCode3Letter);

  if (result) {
    return result.code2;
  }

  return Countries.usa.code2;
}
export function get3LetterCountryCode(countryCode2Letter) {
  const result = CountryArray.find((c) => c.code2 === countryCode2Letter);

  if (result) {
    return result.code3;
  }

  return Countries.usa.code3;
}
export function getCurrency(countryCode3Letter: string) {
  const result = CountryArray.find((c) => c.code3 === countryCode3Letter);

  if (result) {
    return result.currency;
  }

  return Countries.usa.currency;
}
export function getLabel(countryCode3Letter: string) {
  const country = CountryArray.find((c) => c.code3 === countryCode3Letter);

  if (country) {
    return country.label;
  }

  return Countries.usa.label;
}
