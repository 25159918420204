import React from 'react';
import {StyleSheet} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import AVText from 'src/components/elements/AVText';
import SafeAreaView from 'src/components/elements/SafeAreaView';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

const CheckEmailScreen = () => (
  <SafeAreaView style={styles.background}>
    <FontAwesome5Pro
      name="envelope"
      size={Styles.Heights.splashIconHeight}
      style={styles.indicator}
    />
    <AVText style={styles.caption}>
      {Localized.Success.verify_email_has_been_sent}
    </AVText>
  </SafeAreaView>
);

const styles = StyleSheet.create({
  background: {
    backgroundColor: Styles.primaryColor,
    minHeight: Styles.ScreenHeight,
    minWidth: Styles.ScreenWidth,
  },
  caption: {
    color: Styles.bgColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: '500',
    padding: Styles.Heights.h1,
    textAlign: 'center',
  },
  indicator: {
    alignSelf: 'center',
    color: Styles.bgColor,
    paddingTop: Styles.Heights.h5,
    paddingBottom: Styles.Heights.h3,
  },
});

export default CheckEmailScreen;
