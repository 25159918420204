import _ from 'lodash';

const UNAUTHORIZED = 401;

// The max number of login attempts for a single request.
const MAX_LOGIN_ATTEMPTS = 5;

/**
 * Manages obtaining a json web token from tally for authentication.
 * When the token expires, this class will take care of obtaining
 * a new token and then retrying the request.
 * @class TallyHttpClient
 */
export default class TallyHttpClient {
  private token: string | null = null;
  private registerPromise: Promise<any> | null = null;
  private loginPromise: Promise<any> | null = null;
  private newFetch: (
    input: RequestInfo,
    init?: RequestInit,
  ) => Promise<Response>;

  constructor(
    private tallyUrl: string | undefined,
    private apiKey: string | undefined,
    private password: string | undefined,
    private registrationKey: string,
    private onRegistered: (apiKey: string, password: string) => any,
    newFetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  ) {
    this.newFetch = newFetch || fetch;
  }

  /**
   * Get the current token.
   * @returns {string} The current token.
   * @memberOf TallyHttpClient
   */
  public getToken() {
    return this.token;
  }

  /**
   * Set the apikey and the password.
   * @param {any} apiKey
   * @param {any} password
   * @memberOf TallyHttpClient
   */
  public setCredentials(apiKey: string, password: string) {
    this.apiKey = apiKey;
    this.password = password;
  }

  /**
   * Set the url.
   * @param {any} url
   * @memberOf TallyHttpClient
   */
  public setUrl(url: string) {
    this.tallyUrl = url;
  }

  /**
   * Wrapper for the fetch api.
   * @param {string} url The url for the request.
   * @param {any} [opts={}] The options for the request.
   * @returns {Promise} A promise that will be resolved when the request completes.
   * If we are unable to obtain a json web token,
   * the promise will be rejected with the response of the login request.
   * @memberOf TallyHttpClient
   */
  public fetch = (url: string, opts: RequestInit = {}): Promise<any> => {
    if (!this.apiKey) {
      return this.doRegister(url, opts);
    }
    if (!this.token) {
      // Only retry the login a few times before giving up.
      // Otherwise, we will be in an infinite loop.
      if (!this.shouldDoLogin(opts)) {
        return new Promise((resolve, reject) => {
          reject('login-failure');
        });
      }
      // If we don't have a token yet, login to get one.
      return this.doLogin(url, opts);
    }

    // Setup the Authorization header.
    opts.headers = opts.headers || {};

    _.assign(opts.headers, {Authorization: `Bearer ${this.token}`});

    // Do the request.
    return this.newFetch(url, opts).then((resp: any) => {
      if (resp.status === UNAUTHORIZED) {
        // Our token must have expired, so get a new one.
        return this.doLogin(url, opts);
      }

      return resp;
    });
  };

  private shouldDoLogin(opts: any) {
    if (opts.__loginAttempts >= MAX_LOGIN_ATTEMPTS) {
      return false;
    }

    opts.__loginAttempts = !Number.isInteger(opts.__loginAttempts)
      ? 1
      : opts.__loginAttempts + 1;

    return true;
  }

  private doRegister(url: string, opts: any) {
    return this.register(this.registrationKey).then(() => {
      return this.fetch(url, opts);
    });
  }

  /**
   * Register this device with tally.
   * @param {string} deviceId A 32 character unique id.
   * @param {string} registrationKey The registration key for this application.
   * @returns
   * A json object that represents the device's api key. The id property is the deviceId.
   * Example: { id: 'e4d65350bcf94016b03137178c868305', apikey: '18d144a840b64ba2bc3a6864df75dcfc' }
   * @memberOf TallyHttpClient
   */
  private register(registrationKey: string, deviceId?: string) {
    // Don't do the register request if there is one pending.
    if (this.registerPromise) {
      return this.registerPromise;
    }
    const registerUrl = `${this.tallyUrl}/auth/register`;
    const json = {
      deviceid: deviceId,
      registrationkey: registrationKey,
    };

    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(json),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    this.registerPromise = this.fetchJson(registerUrl, fetchOptions).then(
      (response) => {
        this.setCredentials(response.apikey, response.password);

        if (this.onRegistered) {
          this.onRegistered(response.apikey, response.password);
        }
      },
    );

    return this.registerPromise.then(
      () => {
        this.registerPromise = null;
      },
      () => {
        this.registerPromise = null;
      },
    );
  }

  private fetchJson(url: string, opts: any): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(url, opts).then((resp) => {
        if (resp.ok) {
          resp.json().then((data: any) => {
            resolve(data);
          }, reject);
        } else {
          reject(resp);
        }
      }, reject);
    });
  }

  private login() {
    // Don't do the login request if there is one pending.
    if (this.loginPromise) {
      return this.loginPromise;
    }

    const loginUrl = `${this.tallyUrl}/auth/login`;
    const json = {
      apikey: this.apiKey,
      password: this.password,
    };

    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(json),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    this.loginPromise = this.fetchJson(loginUrl, fetchOptions).then(
      (data: any) => {
        this.token = data.token;
      },
    );

    return this.loginPromise.then(
      () => {
        this.loginPromise = null;
      },
      () => {
        this.loginPromise = null;
      },
    );
  }

  private doLogin(url: string, opts: any) {
    return this.login()
      .then(() => {
        return this.fetch(url, opts);
      })
      .catch((e) => {
        if (e.status === UNAUTHORIZED) {
          return this.doRegister(url, opts);
        }
      });
  }
}
