import React from 'react';
import {Platform, ScrollView, StyleSheet, Text, View} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events, {FundStatus} from 'src/logging/Events';
import FundingService from 'src/services/FundingService';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import CreditCardImage from '../CreditCardImage';
import PaymentMethodDropdown from '../PaymentMethodDropdown';
import AmountDropdown from './AmountDropdown';
import Localized from 'src/constants/AppStrings';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError} from '../../helpers/AlertHelper';
import AccountStore from 'src/stores/AccountStore';
import AccountConstants from 'src/constants/AccountConstants';
import {CreditCard} from 'src/models/CreditCard';
import {AppDispatch, RootState, store} from 'src/redux/store';
import {connect} from 'react-redux';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {
  adjustDefaultBalance,
  fetchAccount,
} from 'src/redux/slices/accountSlice';
import {
  addFundsCard,
  IAddFundsCardParams,
} from '../../../redux/thunks/fundingThunks';
import AppRatingService from 'src/services/AppRatingService';

const PAYROLL_LIMIT_ERROR = 'insufficient-payroll-balance';

type FundAccountTabProps = WithGlobalizeProps & {
  remainingDue: number;
  isSnackGiftDue: boolean;
  close: () => void;
  addFundsSuccess?: () => void;
  creditCards: Array<CreditCard>;
  payrollAvailable: boolean;
  paymentCredentials: PaymentCredentials;
  defaultPaymentMethodToken: string;
  payrollIdentifierId: string;
  transDate: string;
  addFundsCard(params: IAddFundsCardParams): Promise<unknown>;
};

type FundAccountState = {
  amount: number;
  cardToken: string;
  issuer: string;
  autoFundOn: boolean;
  autoFundBelowAmount: number;
  autoFundAmount: number;
  paymentMenuVisible: boolean;
  amountMenuVisible: boolean;
};

class FundAccountTab extends React.Component<
  FundAccountTabProps,
  FundAccountState
> {
  static defaultProps = {
    remainingDue: 0,
    isSnackGiftDue: false,
  };
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: FundAccountTabProps) {
    super(props);
    const token = props.defaultPaymentMethodToken;
    const issuer = AccountStore.getPaymentIssuer(token, props.creditCards);
    let amount = AccountStore.getDefaultAmount();

    if (props.remainingDue > 1) {
      amount = props.remainingDue;
    }

    this.state = {
      amount,
      cardToken: token,
      issuer,
      autoFundOn: AccountStore.isAutoFundingOn(),
      autoFundAmount: AccountStore.getAutoFundAmount(),
      autoFundBelowAmount: AccountStore.getAutoFundFallBelowAmount(),
      paymentMenuVisible: false,
      amountMenuVisible: false,
    };

    this.formatCurrency = this.formatCurrency.bind(this);
    this.amountSelected = this.amountSelected.bind(this);
    this.onAccountStoreChanged = this.onAccountStoreChanged.bind(this);
    this.cardSelected = this.cardSelected.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.validate = this.validate.bind(this);
    this.renderAutoReloadSection = this.renderAutoReloadSection.bind(this);
    this.addFundsFailed = this.addFundsFailed.bind(this);
    this.addFundsSuccess = this.addFundsSuccess.bind(this);
  }

  componentDidMount() {
    AccountStore.addChangeListener(this.onAccountStoreChanged);
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
  }

  handleMenuVisibilityChange(visible: boolean, whichMenu: string) {
    switch (whichMenu) {
      case 'payment':
        this.setState({paymentMenuVisible: visible});
        break;
      case 'amount':
        this.setState({amountMenuVisible: visible});
        break;
      default:
        break;
    }
  }

  formatCurrency(value: number) {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  }

  amountSelected(value: string) {
    this.setState({
      amount: +value,
      amountMenuVisible: false,
    });
  }

  cardSelected(value: string) {
    this.setState({
      cardToken: value,
      issuer: AccountStore.getPaymentIssuer(value, this.props.creditCards),
      paymentMenuVisible: false,
    });
  }

  addClicked() {
    const error = this.validate();

    if (error) {
      alertError(error);
    } else if (this.state.cardToken === AccountConstants.PAYROLL_TOKEN) {
      FundingService.addFundsPayroll(
        this.state.amount,
        this.props.payrollIdentifierId,
        this.props.transDate,
        this.context,
        this.addFundsSuccess,
        this.addFundsFailed,
      ).then(async () => {
        await AppRatingService.showPrompt();
      });
    } else if (this.state.cardToken === AccountConstants.APPLE_PAY_TOKEN) {
      FundingService.addFundsApplePay(
        this.state.amount,
        this.context,
        this.addFundsFailed,
        this.addFundsSuccess,
      ).then(async () => {
        await AppRatingService.showPrompt();
      });
    } else if (this.state.cardToken === AccountConstants.GOOGLE_PAY_TOKEN) {
      FundingService.addFundsGooglePay(
        this.state.amount,
        this.context,
        this.addFundsSuccess,
        this.addFundsFailed,
      ).then(async () => {
        await AppRatingService.showPrompt();
      });
    } else {
      this.props
        .addFundsCard({
          amount: this.state.amount,
          tokenId: this.state.cardToken,
          addFundSuccess: this.props.remainingDue && this.addFundsSuccess,
        })
        .then(() => this.props.close?.())
        .then(async () => {
          await AppRatingService.showPrompt();
        });
    }
  }

  addFundsSuccess() {
    Events.AddFunds.trackEvent(
      FundStatus.Success,
      this.state.amount,
      this.state.cardToken,
      this.props.paymentCredentials.type,
    );
    ActionsFactory.getAccountActions().getBalance(
      AccountStore.getAccountId(),
      true,
    );
    store.dispatch(
      adjustDefaultBalance({
        amount: this.state.amount,
        reason: Localized.Labels.add_funds,
      }),
    );
    store.dispatch(fetchAccount(AccountStore.getAccountId()));

    if (this.props.close) {
      this.props.close();
    }

    if (this.props.addFundsSuccess) {
      this.props.addFundsSuccess();
    }
  }

  addFundsFailed(msg?: string) {
    let errorMessage = Localized.Errors.failed_to_add_funds;

    if (msg === PAYROLL_LIMIT_ERROR) {
      errorMessage = Localized.Errors.reached_payroll_deduct_limit;
    }

    alertError(errorMessage);
  }

  validate() {
    if (!this.state.cardToken || !this.state.amount) {
      return Localized.Errors.select_account_and_amount;
    } else if (
      this.state.amount !== this.props.remainingDue &&
      this.state.amount < AccountConstants.MINIMUM_FUNDING_AMOUNT
    ) {
      return `${Localized.Errors.minimum_amount_is} ${this.formatCurrency(
        AccountConstants.MINIMUM_FUNDING_AMOUNT,
      )}`;
    } else if (isNaN(this.state.amount)) {
      return Localized.Errors.invalid_amount;
    }

    return '';
  }

  onAccountStoreChanged() {
    this.setState({
      autoFundOn: AccountStore.isAutoFundingOn(),
      autoFundAmount: AccountStore.getAutoFundAmount(),
      autoFundBelowAmount: AccountStore.getAutoFundFallBelowAmount(),
    });
  }

  renderAutoReloadSection() {
    let summaryText: string | string[] = Localized.Labels.click_edit_to_turn_on;

    if (this.state.autoFundOn) {
      summaryText = Localized.Labels.formatString(
        Localized.Labels.when_balance_is_below,
        this.formatCurrency(this.state.autoFundAmount),
        this.formatCurrency(this.state.autoFundBelowAmount),
      );
    }
    return (
      <View style={styles.autoReloadContainer}>
        <View accessible={true} style={styles.autoReloadText}>
          <Text
            accessibilityLabel={`${Localized.Labels.auto_reload}, `}
            aria-label={`${Localized.Labels.auto_reload}, `}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            style={{color: Styles.darkColor}}
          >
            {Localized.Labels.auto_reload}
          </Text>
          <Text
            accessibilityLabel={summaryText.toString()}
            aria-label={summaryText.toString()}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            style={styles.reloadSummary}
          >
            {summaryText}
          </Text>
        </View>
        <RoundedButton
          text={Localized.Buttons.edit}
          containerStyle={styles.autoLoadButton}
          textStyle={styles.editButtonText}
          onPress={() => {
            NavActions.navigate(AppRoutes.AutoReloadPanel);
          }}
          buttonType={ButtonType.outline}
          accessible={true}
          accessibilityLabel={Localized.Labels.edit_auto_reload}
          aria-label={Localized.Labels.edit_auto_reload}
          accessibilityHint="Double tap to set up or edit automatic reload"
          accessibilityRole="button"
          role="button"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        />
      </View>
    );
  }

  canAddFund = () => {
    const creditCardsFiltered = Util.filterExpiredCreditCards(
      this.props.creditCards,
    );

    return !(
      this.props.paymentCredentials.googlePayAvailable ||
      this.props.paymentCredentials.applePayAvailable ||
      creditCardsFiltered.length > 0 ||
      this.props.payrollAvailable
    );
  };
  render() {
    let buttonText = `${Localized.Buttons.add} ${this.formatCurrency(
      this.state.amount,
    )}`;

    if (this.props.remainingDue) {
      buttonText = `${Localized.Buttons.checkout} ${this.formatCurrency(
        this.state.amount,
      )}`;
    }

    if (this.props.isSnackGiftDue) {
      buttonText = Localized.Buttons.fund_and_send_snack;
    }

    const creditCardsFiltered = Util.filterExpiredCreditCards(
      this.props.creditCards,
    );

    return (
      <ScrollView style={styles.container}>
        <View style={Styles.Style.maxWidthContainer}>
          <View style={styles.paymentContainer}>
            <View
              accessibilityLabel={`${this.state.issuer} icon`}
              aria-label={`${this.state.issuer} icon`}
              accessibilityRole="image"
              role="img"
              style={[
                styles.ccImage,
                this.state.issuer === AccountConstants.GOOGLE_PAY_TOKEN && {
                  right: Styles.Spacing.m1,
                },
              ]}
            >
              <CreditCardImage issuer={this.state.issuer} />
            </View>
            <View
              accessible={true}
              accessibilityLabel={Localized.Labels.payment_method_drop_down}
              aria-label={Localized.Labels.payment_method_drop_down}
              aria-valuetext={this.state.issuer}
              role="menu"
              accessibilityRole="menu"
              accessibilityHint="Select the payment method you would like to use"
              accessibilityValue={{text: this.state.issuer}}
              accessibilityState={{expanded: this.state.paymentMenuVisible}}
              style={[styles.dropdown]}
            >
              <PaymentMethodDropdown
                anchorPosition="bottom"
                onSelect={this.cardSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.paymentMenuVisible,
                    'payment',
                  )
                }
                value={this.state.cardToken}
                cards={creditCardsFiltered}
                applePayAvailable={
                  this.props.paymentCredentials.applePayAvailable
                }
                googlePayAvailable={
                  this.props.paymentCredentials.googlePayAvailable
                }
                payrollAvailable={this.props.payrollAvailable}
              />
            </View>
          </View>
          <View
            accessible={true}
            accessibilityLabel={Localized.Labels.amount_drop_down}
            aria-label={Localized.Labels.amount_drop_down}
            role="menu"
            accessibilityRole="menu"
            accessibilityHint="Select the amount to be added to your account"
            accessibilityValue={{
              text: this.formatCurrency(this.state.amount).toString(),
            }}
            accessibilityState={{expanded: this.state.amountMenuVisible}}
            style={{marginLeft: Styles.Spacing.m4 + Styles.Spacing.m3}}
          >
            <AmountDropdown
              anchorPosition="top"
              onSelect={this.amountSelected}
              onVisibilityChange={() =>
                this.handleMenuVisibilityChange(
                  !this.state.amountMenuVisible,
                  'amount',
                )
              }
              selectedValue={this.state.amount}
              formatNumber={this.formatCurrency}
              remainingDue={this.props.remainingDue}
            />
          </View>
          <View
            accessibilityElementsHidden={
              this.state.paymentMenuVisible || this.state.amountMenuVisible
            }
            importantForAccessibility={
              this.state.paymentMenuVisible || this.state.amountMenuVisible
                ? 'no-hide-descendants'
                : 'yes'
            }
          >
            {this.props.remainingDue === 0 && this.renderAutoReloadSection()}
          </View>
        </View>

        <View style={{marginTop: 60}}>
          <RoundedButton
            buttonType={ButtonType.outline}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            containerStyle={[styles.balancesButton]}
            textStyle={Styles.Style.actionButtonText}
            accessible={true}
            accessibilityLabel={Localized.Buttons.view_balances}
            aria-label={Localized.Buttons.view_balances}
            accessibilityHint="Double tap to navigate to the balances screen"
            accessibilityRole="button"
            role="button"
            onPress={() => {
              NavActions.navigate(AppRoutes.Balances);
            }}
            text="Balances"
          />
          <RoundedButton
            buttonType={ButtonType.action}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            containerStyle={[styles.balancesButton]}
            textStyle={Styles.Style.actionButtonText}
            accessibilityLabel={buttonText}
            aria-label={buttonText}
            accessibilityHint="This amount can be modified with the drop down above"
            accessibilityRole="button"
            role="button"
            onPress={this.addClicked}
            text={buttonText}
            disabled={this.canAddFund()}
          />
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  autoLoadButton: {
    alignSelf: 'center',
  },
  autoReloadContainer: {
    flexDirection: 'row',
    marginLeft: Styles.Spacing.m4 + Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
  autoReloadText: {
    flex: 1,
  },
  ccImage: {
    justifyContent: 'center',
    marginTop: Styles.Spacing.m1,
    width: Styles.Spacing.m4 + Styles.Spacing.m3,
  },
  container: {
    ...(Platform.OS === 'web' && {
      flex: 1,
    }),
    backgroundColor: Styles.white,
    height: 360,
    paddingBottom: Styles.Spacing.m4,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
  },
  dropdown: {
    flex: 1,
  },
  paymentContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  reloadSummary: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  balancesButton: {
    bottom: Styles.Spacing.m2 + Styles.Spacing.m4,
  },
  editButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});

const ConnectedFundAccountTab = connect(
  (state: RootState) => ({
    paymentCredentials: state.account.paymentCredentials,
    creditCards: state.account.creditCards,
    defaultPaymentMethodToken: state.account.defaultPaymentToken,
    payrollAvailable: state.account.account.isPayrollAvailable,
    payrollIdentifierId: state.account.account.payrollIdentifier?.id,
  }),
  (dispatch: AppDispatch) => ({
    addFundsCard: (params) => dispatch(addFundsCard(params)),
  }),
)(FundAccountTab);
type WithGlobalizeType = {
  transDate: string;
  remainingDue: number;
  isSnackGiftDue: boolean;
  close: () => void;
  addFundsSuccess?: () => void;
} & WithGlobalizeProps;
export default withGlobalize<WithGlobalizeType>(ConnectedFundAccountTab);
