import PersistentStore from './PersistentStoreService';
import AVLiveApi from 'src/api/AVLiveApi';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
export default class VESharedKeyService {
  static async getSharedKey(publicKey: string) {
    let veKeys = await PersistentStore.getVendorsExchangeKeys();

    if (!veKeys) {
      veKeys = {};
    }

    let sharedKey = veKeys[publicKey];

    if (!sharedKey) {
      const sharedKeyResponse = await AVLiveApi.getSharedKey(publicKey);

      if (sharedKeyResponse && sharedKeyResponse.Success) {
        sharedKey = sharedKeyResponse.sharedKey;
        veKeys[publicKey] = sharedKey;
        PersistentStore.setVendorsExchangeKeys(veKeys);
        Events.VendorsExchange.trackEvent(VendorsExchangeActions.NewSharedKey, {
          publicKey,
          sharedKey,
        });
      }
    }

    return sharedKey;
  }
}
