import * as React from 'react';
import {Text, TextProps} from 'react-native';
type AVTextProps = TextProps & {
  children: React.ReactNode;
  accessibilityLabel?: string;
  accessibilityRole?: string;
  maxFontSizeMultiplier?: number;
  accessible?: boolean;
};

const AVText = (props: AVTextProps) => (
  <Text
    nativeID={props.accessibilityLabel}
    accessible={props?.accessible}
    accessibilityLabel={props.accessibilityLabel}
    accessibilityRole={props?.accessibilityRole}
    maxFontSizeMultiplier={props?.maxFontSizeMultiplier}
    {...props}
  />
);

export default AVText;
