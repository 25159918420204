import {EventEmitter} from 'events';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import AccountConstants from 'src/constants/AccountConstants';
import PersistentStore from 'src/services/PersistentStoreService';
import type {DispatchAction} from 'src/dispatchers/DispatchTypes';
const CHANGE = 'change';

class SettingStore {
  _emitter: EventEmitter = new EventEmitter();
  _tallyPromises: Promise<any>;
  _dispatchToken: number;
  _tallyApiKey = '';
  _tallyPassword = '';

  constructor() {
    this._tallyPromises = Promise.all([
      PersistentStore.getTallyApiKey().then((value: string | null) => {
        if (value !== null && value.trim().length > 0) {
          this._tallyApiKey = value;
        }
      }),
      PersistentStore.getTallyPassword().then((value: string | null) => {
        if (value !== null && value.trim().length > 0) {
          this._tallyPassword = value;
        }
      }),
    ]);
    this._dispatchToken = AppDispatcher.register((action: DispatchAction) => {
      if (action.actionType === AccountConstants.TALLY_REGISTERED) {
        const {apiKey, password} = action.data;
        this._tallyApiKey = apiKey;
        this._tallyPassword = password;
        PersistentStore.setTallyApiKey(apiKey);
        PersistentStore.setTallyPassword(password);
      }

      if (action.actionType === AccountConstants.ENVIRONMENT_CHANGED) {
        this._tallyApiKey = '';
        this._tallyPassword = '';
        PersistentStore.setTallyApiKey('');
        PersistentStore.setTallyPassword('');

        this._emitChange();
      }
    });
  }

  _emitChange() {
    this._emitter.emit(CHANGE);
  }

  addChangeListener(callback: () => void) {
    this._emitter.on(CHANGE, callback);
  }

  removeChangeListener(callback: () => void) {
    this._emitter.removeListener(CHANGE, callback);
  }

  async waitForTally() {
    await this._tallyPromises;
  }

  getTallyApiKey() {
    return this._tallyApiKey;
  }

  getTallyPassword() {
    return this._tallyPassword;
  }
}

export default new SettingStore();
