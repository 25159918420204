import {FirebaseRemoteConfigTypes} from '@react-native-firebase/remote-config';
import {Platform} from 'react-native';
import Util from 'src/Util';
import PersistentStore from 'src/services/PersistentStoreService';
import AccountStore from 'src/stores/AccountStore';
import _ from 'lodash';
import DeviceInfo from '../../nativeModules/DeviceInfo';

/**
 * Resets Soft dialog render count & next soft alert time
 * based on persisted time window expiry
 * @param value firebaseRemoteConfiguration
 */
const checkDelayWindowReset = async () => {
  const nextAlertInterval = await getAbsoluteSoftAlertDelay();
  if (Util.getCurrentTimeMillis() >= nextAlertInterval) {
    const interval = await getSoftpUpdateIntervalFromStore();
    await storeNextSoftAlertEpoch(interval);
    await storeRenderCount(-1);
  }
};

/**
 * Resets and store next soft alert time
 * based on _soft_update_interval change on firebase remote
 * @param value firebaseRemoteConfiguration
 */

const isAlertIntervalChangedOnFBaseRemote = async (fireBaseConfig) => {
  const alertInterval = await getSoftpUpdateIntervalFromStore();
  const firebaseInterval = fireBaseConfig.get(
    `${Platform.OS}_soft_update_interval`,
  )['_value'];
  if (alertInterval != firebaseInterval) {
    await storeNextSoftAlertEpoch(firebaseInterval);
    return true;
  }
  return false;
};

/**
 * Show dialog
 * @param fireBaseConfig
 * @returns showDialog boolean
 */
const isSoftDialogRenderRemaining = async (fireBaseConfig) => {
  let showDialog = false;
  await checkDelayWindowReset();
  const storedRenderedCount = await getSoftDialogStoredFBaseRenderCount();
  const fBaseRemoteRenderCount = fireBaseConfig
    .get(`${Platform.OS}_soft_update_render_count`)
    .asNumber();
  const appRenderedCount = await getRenderedCount();
  const alertIntervalChanged = await isAlertIntervalChangedOnFBaseRemote(
    fireBaseConfig,
  );

  if (fBaseRemoteRenderCount == undefined || fBaseRemoteRenderCount === 0) {
    showDialog = false;
  } else if (storedRenderedCount != fBaseRemoteRenderCount) {
    showDialog = true;
    storeRenderCount(0);
  } else if (alertIntervalChanged) {
    showDialog = true;
    storeRenderCount(0);
  } else if (appRenderedCount < storedRenderedCount) {
    storeRenderCount(appRenderedCount);
    showDialog = true;
  } else {
    // Rendering of soft alert dialog has exceeded limits
    showDialog = false;
  }
  return showDialog;
};

/**
 * Store new time starting from currentTimeMillis
 * for next soft dialog to show
 * @param value newDays
 */
const storeNextSoftAlertEpoch = async (newDays) => {
  const nextAlertEpoch = Util.calculateDaysInMillis(newDays);
  await PersistentStore.setLastSoftAppUpdateAlertShown(nextAlertEpoch);
};

/**
 * Get stored _soft_update_interval if data exists
 * @param value newDays
 */
const getSoftpUpdateIntervalFromStore = async () => {
  const json = await PersistentStore.getFireBaseRemoteConfig();
  if (json != undefined && json != null) {
    const data = await JSON.parse(json);
    const interval = data[`${Platform.OS}_soft_update_interval`]['_value'];
    return interval;
  } else return -1;
};

/**
 * Returns timestamp at which soft alert Dialog has to be shown
 * @returns timestamp
 */
const getAbsoluteSoftAlertDelay = async () => {
  return await getAbsoluteSoftDialogDelay();
};

/**
 * Returns next TimeStamp at which soft alert Dialog has to be shown
 * @returns
 */
const getAbsoluteSoftDialogDelay = async (): Promise<number> => {
  const lastShownTimeStamp =
    await PersistentStore.getLastSoftAppUpdateAlertShown();

  if (lastShownTimeStamp != undefined) {
    return lastShownTimeStamp;
  } else {
    return -1;
  }
};

const getRenderedCount = async (): Promise<number> => {
  const softDialogRenderCount =
    await PersistentStore.getSoftDialogRenderCount();
  if (softDialogRenderCount != undefined) {
    return softDialogRenderCount;
  } else {
    return 1;
  }
};

const getSoftDialogStoredFBaseRenderCount = async (): Promise<number> => {
  const json = await PersistentStore.getFireBaseRemoteConfig();
  if (json == undefined || json == null) {
    return -1;
  }
  const data = JSON.parse(json);
  const interval = data[`${Platform.OS}_soft_update_render_count`]['_value'];
  if (interval != undefined) {
    return interval;
  } else {
    return -1;
  }
};

const storeRenderCount = async (latestRenderCount) => {
  await PersistentStore.updateSoftDialogRenderCount(latestRenderCount + 1);
};

const isLatestAppVersionAvailable = (
  fireBaseConfig: Map<string, FirebaseRemoteConfigTypes.ConfigValue>,
  oldAppVersion: string,
): boolean => {
  const newAppVersion = fireBaseConfig.get(
    `${Platform.OS}_soft_update_new_app_version`,
  )['_value'];
  return (
    newAppVersion.localeCompare(oldAppVersion, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) > 0
  );
};

const isUserAppVersionBelowMinimum = (
  fireBaseConfig: Map<string, FirebaseRemoteConfigTypes.ConfigValue>,
  oldAppVersion: string,
): boolean => {
  const newAppVersion = fireBaseConfig
    .get(`${Platform.OS}_force_update_minimum_app_version`)
    .asString();
  return (
    newAppVersion.localeCompare(oldAppVersion, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) > 0
  );
};

const isUserInAllowedEmailListForForceUpdate = (fireBaseConfig): boolean => {
  let showAlert = true;
  const firebaseAllowedEmailList = fireBaseConfig
    .get(`${Platform.OS}_force_update_email`)
    .asString();
  if (firebaseAllowedEmailList) {
    const emailList = _.split(firebaseAllowedEmailList, ',').map((item) =>
      item.trim(),
    );
    const userEmail = AccountStore.getEmail();
    if (emailList) {
      if (emailList && userEmail) {
        const userEmailMatched = _.includes(emailList, userEmail);
        if (userEmailMatched) showAlert = true;
        else showAlert = false;
      }
      // userEmail alone does not exist
      else showAlert = false;
    } else {
      // email list itself is not present in firebase
      showAlert = true;
    }
  } else {
    showAlert = true;
  }
  return showAlert;
};

const getAlertPayload = (fireBaseConfig, softUpdateAlert) => {
  let alertPayload;
  if (softUpdateAlert) {
    alertPayload = {
      alertTitle: fireBaseConfig
        .get(`${Platform.OS}_soft_update_alert_title`)
        .asString(),
      alertMessage: fireBaseConfig.get(`${Platform.OS}_soft_update_message`)[
        '_value'
      ],
      alertUpdateButtonTitle: fireBaseConfig
        .get(`${Platform.OS}_soft_update_alert_update_button_title`)
        .asString(),
      alertCancelButtonTitle: fireBaseConfig
        .get(`${Platform.OS}_soft_update_alert_cancel_button_title`)
        .asString(),
      alertUpdateActionUrl: fireBaseConfig
        .get(`${Platform.OS}_app_url`)
        .asString(),
      softUpdate: true,
    };
  } else {
    alertPayload = {
      alertTitle: fireBaseConfig
        .get(`${Platform.OS}_force_update_alert_title`)
        .asString(),
      alertMessage: fireBaseConfig
        .get(`${Platform.OS}_force_update_message`)
        .asString(),
      alertUpdateButtonTitle: fireBaseConfig
        .get(`${Platform.OS}_force_update_alert_button_title`)
        .asString(),
      alertUpdateActionUrl: fireBaseConfig
        .get(`${Platform.OS}_app_url`)
        .asString(),
      softUpdate: false,
    };
  }
  return alertPayload;
};

const getAppVersion = async () => {
  const versionNumber = await DeviceInfo.getVersion();
  return versionNumber;
};

export {
  isLatestAppVersionAvailable,
  isSoftDialogRenderRemaining,
  isAlertIntervalChangedOnFBaseRemote,
  isUserAppVersionBelowMinimum,
  isUserInAllowedEmailListForForceUpdate,
  getAlertPayload,
  getAppVersion,
};
