import React from 'react';
import {TouchableOpacity, TouchableOpacityProps} from 'react-native';
export default function AVTouchableOpacity(props: TouchableOpacityProps) {
  return (
    <TouchableOpacity
      activeOpacity={0.75}
      accessibilityLabelledBy={props?.accessibilityLabel}
      accessibilityHint={props?.accessibilityHint}
      accessibilityLabel={props?.accessibilityLabel}
      accessibilityRole={'button'}
      {...props}
    />
  );
}
