import React, {useState} from 'react';
import {View, Text, StyleSheet, Switch, Pressable} from 'react-native';
import Styles from 'src/components/Styles';

type SwitchProps = {
  testID: string;
  accessibilityLabel: string;
  accessibilityLabelledBy?: string;
  accessibilityState: {[key: string]: boolean};
  maxFontSizeMultiplier?: number;
  text: string;
  linkText?: string;
  value: boolean;
  onValueChange?: (value: boolean) => void;
  openLink?: () => void;
  disabled?: boolean;
  textColor?: string;
};

const SwitchComponent = (props: SwitchProps): JSX.Element => {
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
    props.onValueChange(isEnabled);
  };
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text
          maxFontSizeMultiplier={props.maxFontSizeMultiplier}
          accessible={true}
          accessibilityLabel={props.text}
          aria-label={props.text}
          style={[
            styles.text,
            {color: props.textColor ? props.textColor : Styles.darkColor},
          ]}
        >
          {props.text}
        </Text>
        {
          // ie., Privacy Policy, User Agreement, ect
          props.linkText && (
            <Pressable
              accessibilityRole="link"
              role="link"
              onPress={props.openLink}
              accessibilityLabel={props.linkText}
              aria-label={props.linkText}
            >
              <Text
                maxFontSizeMultiplier={props.maxFontSizeMultiplier}
                style={styles.linkText}
              >
                {props.linkText}
              </Text>
            </Pressable>
          )
        }
      </View>

      <Switch
        testID={props.testID}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityHint="Double tap to toggle the switch"
        accessibilityState={{checked: isEnabled}}
        accessibilityLabelledBy={props.accessibilityLabelledBy}
        accessibilityRole="switch"
        role="switch"
        aria-label={props.accessibilityLabel}
        aria-checked={isEnabled}
        aria-labelledby={props.accessibilityLabelledBy}
        trackColor={{false: Styles.lightGray, true: Styles.primaryColor}}
        onValueChange={toggleSwitch}
        value={isEnabled}
        style={styles.switch}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: Styles.Heights.h4,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  switch: {
    backgroundColor: 'transparent',
    borderRadius: Styles.Spacing.m3,
    overflow: 'hidden',
    marginLeft: 12,
  },
  linkText: {
    color: Styles.primaryColor,
    fontSize: 16,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m1,
  },
});

export default SwitchComponent;
