import React from 'react';
import {View, StyleSheet, Pressable} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';

interface INoCardAvailableProps {
  title: string;
  desc: string;
  onPress?(): void;
}

function NoCardAvailable({title, desc, onPress}: INoCardAvailableProps) {
  return (
    <Pressable
      accessible={true}
      accessibilityHint="Double tap to add a new payment card"
      accessibilityRole="imagebutton"
      role="button"
      onPress={onPress}
      style={styles.container}
    >
      <FontAwesome5Pro
        accessibilityLabel="Add card icon, "
        aria-label="Add card icon, "
        name="plus-circle"
        color={Styles.darkColor}
        size={28}
        light
      />
      <View style={styles.titleView}>
        <AVText
          accessible={true}
          accessibilityLabel={title}
          accessibilityRole="text"
          aria-label={`${title}, text`}
          style={styles.title}
        >
          {title}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={desc}
          accessibilityRole="text"
          aria-label={`${desc}, text`}
          style={styles.desc}
        >
          {desc}
        </AVText>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: Styles.Spacing.m3,
    alignItems: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Styles.lightGray,
  },
  titleView: {
    marginLeft: Styles.Spacing.m3,
  },
  title: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  desc: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
});

export default NoCardAvailable;
