import {createSlice} from '@reduxjs/toolkit';

export const initialState = {};

const fundingSlice = createSlice({
  name: 'funding',
  initialState,
  reducers: {},
});
export const {} = fundingSlice.actions;
export default fundingSlice.reducer;
