import React from 'react';
import Styles from '../Styles';
import Button from './Button';
type PrimaryButtonProps = {
  activeOpacity?: number;
  overrideStyles?: any;
  overrideTextStyles?: any;
  onPress: () => any;
  accessibilityLabel: string;
  text: string;
  disabled?: boolean;
  accessible?: boolean;
};
export default function PrimaryButton(props: PrimaryButtonProps) {
  return (
    <Button
      {...props}
      overrideStyles={Styles.primaryBtn(props.overrideStyles)}
    />
  );
}
