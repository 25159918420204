import {createSlice} from '@reduxjs/toolkit';
import {BlockedUser, GiftItem as GiftItemType} from '../../types/Snack';
import {
  acceptSnack,
  blockUser,
  cancelSnack,
  getSnackBlockedUsers,
  loadGiftFeed,
  loadSnackDetail,
} from 'src/redux/thunks/snackThunks';

export enum SnackStatus {
  Sent = 'S',
  Cancelled = 'C',
  Accepted = 'A',
  Expired = 'E',
}

interface ISnackState {
  gifts: GiftItemType[];
  isRefreshing: boolean;
  snackDetails?: GiftItemType;
  loading: boolean;
  blockedUsers: Array<BlockedUser>;
  loadingBlockedUsers: boolean;
}

export const initialState: ISnackState = {
  gifts: [],
  isRefreshing: false,
  loading: false,

  blockedUsers: [],
  loadingBlockedUsers: false,
};

const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSnackDetail.pending, (state) => {
      state.loading = true;
      state.snackDetails = undefined;
    });
    builder.addCase(loadSnackDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.snackDetails = action.payload;
    });

    builder.addCase(acceptSnack.fulfilled, (state, action) => {
      state.snackDetails = action.payload;
    });

    builder.addCase(blockUser.fulfilled, (state, action) => {
      state.snackDetails = action.payload;
    });

    builder.addCase(cancelSnack.fulfilled, (state, action) => {
      state.snackDetails = action.payload;
    });

    builder.addCase(loadGiftFeed.pending, (state) => {
      state.isRefreshing = true;
    });
    builder.addCase(loadGiftFeed.fulfilled, (state, action) => {
      state.isRefreshing = false;
      if (action.payload.items) {
        const newItems: GiftItemType[] = action.payload.items.map((item) => ({
          snackId: item.snackId,
          locationId: item.locationId,
          amount: item.amount,
          description: item.description,
          email: item.email,
          isPrivate: item.isPrivate,
          stickerId: item.stickerId,
          senderAccountId: item.senderAccountId,
          senderName: item.senderName,
          receiverAccountId: item.receiverAccountId,
          receiverName: item.receiverName,
          status: item.status,
          snackSent: item.snackSent,
          lastUpdated: item.lastUpdated,
        }));

        if (action.payload.offset > 0) {
          state.gifts = [...state.gifts, ...newItems];
        } else {
          state.gifts = newItems;
        }

        if (state.gifts.length > 0 && newItems.length < action.payload.limit) {
          state.gifts[state.gifts.length - 1].lastItem = true;
        }
      } else {
        state.gifts = [];
      }
    });

    builder.addCase(getSnackBlockedUsers.pending, (state) => {
      state.loadingBlockedUsers = true;
      state.blockedUsers = [];
    });
    builder.addCase(getSnackBlockedUsers.fulfilled, (state, action) => {
      state.loadingBlockedUsers = false;
      state.blockedUsers = action.payload;
    });
  },
});
export const {} = snackSlice.actions;
export default snackSlice.reducer;
