import React, {useEffect, useState} from 'react';
import Localized from '../constants/AppStrings';
import AVText from './elements/AVText';
import {
  AccessibilityValue,
  PixelRatio,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import Styles from './Styles';
import AllyTextInput from 'src/components/elements/AllyTextInput';

const MIN_PASSWORD_LENGTH = 8;
const DEFAULT_EYE_LASH_FONT_SIZE = 28;
const MINIMUM_EYE_LASH_FONT_SIZE = 15;

function hasCapitalLetterValidate(password: string) {
  let i = 0;
  if (password.length === 0) {
    return false;
  }
  while (i < password.length) {
    const character = password.charAt(i);
    if (character === character.toLowerCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character === character.toUpperCase()) {
      return true;
    }
    i++;
  }
  return false;
}

function hasLowerCaseLetterValidate(password: string) {
  let i = 0;
  if (password.length === 0) {
    return false;
  }
  while (i < password.length) {
    const character = password.charAt(i);
    if (character === character.toUpperCase()) {
      // Character is lowercase, numeric, or a symbol
    } else if (character === character.toLowerCase()) {
      return true;
    }
    i++;
  }
  return false;
}

interface IPasswordInputProps {
  label: string;
  value?: string;
  accessible?: boolean;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  accessibilityValue?: AccessibilityValue;
  nativeID?: string;
  needValidation?: boolean;
  maxLength?: number;
  onChangeText?: (value: string, errorMessage: string[]) => void;
  forwardRef?(ref): void;
  onFocus?(): void;
  onSubmitEditing?(): void;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({
  label,
  value,
  accessibilityLabel,
  accessibilityHint,
  accessibilityValue,
  nativeID,
  needValidation,
  onChangeText,
  onSubmitEditing,
}) => {
  const [localValue, setLocalValue] = useState<string>(value || '');
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
  const [validation, setValidation] = useState({
    hasSpecialChar: false,
    hasNumbers: false,
    hasLowerCaseLetter: false,
    hasCapitalLetter: false,
  });

  useEffect(() => {
    const errorMessage = [];
    const hasSpecialChar = /[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|":<>?._]/g.test(
      localValue,
    );
    const hasNumbers = /\d/g.test(localValue);
    const hasCapitalLetter = hasCapitalLetterValidate(localValue);
    const hasLowerCaseLetter = hasLowerCaseLetterValidate(localValue);

    const isPasswordValid =
      localValue.length >= MIN_PASSWORD_LENGTH &&
      [hasSpecialChar, hasCapitalLetter, hasLowerCaseLetter, hasNumbers].filter(
        (x) => x,
      ).length > 2;

    localValue.length < MIN_PASSWORD_LENGTH &&
      errorMessage.push('At least 8 characters');
    !hasLowerCaseLetter && errorMessage.push('Lower case letters');
    !hasCapitalLetter && errorMessage.push('Upper case letters (A-Z)');
    !hasNumbers && errorMessage.push('Numbers (0-9)');
    !hasSpecialChar && errorMessage.push('Special characters (e.g. !@#$%^&*)');

    onChangeText(localValue, isPasswordValid ? [] : errorMessage);

    setValidation({
      hasSpecialChar,
      hasCapitalLetter,
      hasLowerCaseLetter,
      hasNumbers,
    });
  }, [localValue]);

  /**
   * Return eye lash font icon height tailored for zoomed screens
   * @returns
   */
  function setEyeLashFontSize(): number {
    if (Platform.OS === 'web') {
      if (PixelRatio.get() > 2) {
        return MINIMUM_EYE_LASH_FONT_SIZE;
      }
      return DEFAULT_EYE_LASH_FONT_SIZE;
    }
    return DEFAULT_EYE_LASH_FONT_SIZE;
  }

  return (
    <>
      <View>
        <AllyTextInput
          value={value}
          autoCapitalize="none"
          onSubmitEditing={onSubmitEditing}
          returnKeyType="next"
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          accessibilityValue={accessibilityValue}
          label={label}
          nativeID={nativeID}
          onChangeText={(text: string) => setLocalValue(text)}
          secureTextEntry={secureTextEntry}
          maxLength={MIN_PASSWORD_LENGTH * 3}
          hasIcon={true}
          iconName={secureTextEntry ? 'eye-slash' : 'eye'}
          iconHeight={setEyeLashFontSize()}
          iconLabel={
            secureTextEntry
              ? 'Click to show password'
              : 'Click to hide password'
          }
          iconPressed={() => {
            setSecureTextEntry(!secureTextEntry);
          }}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
        />
      </View>

      {!!localValue && needValidation && (
        <View accessible={true} style={{marginTop: Styles.Spacing.m1}}>
          <AVText accessibilityRole="text">
            {Localized.Labels.password_must}:
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRuleText}>
            {Localized.Labels.at_least_8_chars}
            {localValue.length >= MIN_PASSWORD_LENGTH ? ' ✅' : ''}
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRuleText}>
            {Localized.Labels.at_least_3_of}:
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRule}>
            {Localized.Labels.letters_a_to_z}{' '}
            {validation.hasLowerCaseLetter ? '✅' : ''}
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRule}>
            {Localized.Labels.upper_letters_ato_z}{' '}
            {validation.hasCapitalLetter ? '✅' : ''}
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRule}>
            {Localized.Labels.numbers_0_9} {validation.hasNumbers ? '✅' : ''}
          </AVText>
          <AVText accessibilityRole="text" style={styles.passwordRule}>
            {Localized.Labels.special_chars}{' '}
            {validation.hasSpecialChar ? '✅' : ''}
          </AVText>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  passwordRuleText: {
    paddingLeft: 20,
    paddingVertical: 5,
    color: Styles.darkColor,
  },
  passwordRule: {
    paddingLeft: 40,
    paddingBottom: 5,
    color: Styles.lightGray,
  },
});

export default PasswordInput;
