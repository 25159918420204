import React from 'react';
import {
  AppState,
  TouchableOpacity,
  Platform,
  StyleSheet,
  PixelRatio,
} from 'react-native';
import Video from 'react-native-video';
import type {PromotionType} from 'src/types/Promotions';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Util from 'src/Util';
import Events from 'src/logging/Events';
import Styles from '../../Styles';
import InViewPort from '../../elements/InViewPort';
import {ensureHttps} from 'src/services/StringUtils';

type VideoPromotionProps = {
  navigation: any;
  promotion: PromotionType;
  onClick: () => void;
  width: number;
  promotionViewed: (promotion: PromotionType) => void;
};
type VideoPromotionState = {
  paused: boolean;
  controlsVisible: boolean;
};
const VIDEO_PLAYBACK = 2;
const POSTER_EXT = 'jpg';
const initialDelay = Platform.select({
  ios: 400,
  default: 0,
});
const displayTime = Platform.select({
  ios: 3000,
  default: 2800,
});

class VideoPromotion extends React.Component<
  VideoPromotionProps,
  VideoPromotionState
> {
  player: Video | null;
  willFocusSubscription: any;
  willBlurSubscription: any;
  videoLength = 0;
  videoEnded = false;
  controlsTimeout: any;
  triggeredViewed: boolean;
  handleAppStateChangeSubscription: any;

  constructor(props: VideoPromotionProps) {
    super(props);
    this.state = {
      paused: true,
      controlsVisible: false,
    };
    this.triggeredViewed = false;
    this.handleAppStateChange = this.handleAppStateChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
  }

  componentDidMount() {
    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange,
    );
    this.willFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        if (this.player && this.videoLength && this.videoEnded) {
          this.player.seek(this.videoLength - VIDEO_PLAYBACK);
        }

        this.setState({
          paused: false,
        });
      },
    );
  }

  componentWillUnmount() {
    this.handleAppStateChangeSubscription.remove();
    this.willFocusSubscription();

    if (this.controlsTimeout) {
      clearTimeout(this.controlsTimeout);
      this.controlsTimeout = null;
    }
  }

  shouldComponentUpdate(
    nextProps: VideoPromotionProps,
    nextState: VideoPromotionState,
  ) {
    return (
      this.props.promotion.imagePath !== nextProps.promotion.imagePath ||
      this.props.width !== nextProps.width ||
      this.state.controlsVisible !== nextState.controlsVisible ||
      this.state.paused !== nextState.paused
    );
  }

  handleAppStateChange(currentAppState: string) {
    if (
      currentAppState === 'active' &&
      this.player &&
      this.videoEnded &&
      this.videoLength
    ) {
      this.player.seek(this.videoLength - VIDEO_PLAYBACK);
    }
  }

  onVisibilityChanged(isVisible: boolean) {
    if (isVisible && !this.triggeredViewed) {
      this.triggeredViewed = true;
      this.props.promotionViewed(this.props.promotion);
    }

    this.setState({
      paused: !isVisible,
    });
  }

  onClick() {
    if (this.controlsTimeout) {
      clearTimeout(this.controlsTimeout);
      this.controlsTimeout = null;
    }

    setTimeout(() => {
      this.setState({
        controlsVisible: !this.state.controlsVisible,
      });
      this.controlsTimeout = setTimeout(() => {
        this.setState({
          controlsVisible: false,
        });
      }, displayTime);
    }, initialDelay);
  }

  ref = (player: any) => {
    this.player = player;
  };
  onLoad = (payload: any) => {
    this.videoLength = payload.duration;
  };
  onEnd = () => {
    if (!this.videoEnded) {
      this.videoEnded = true;
      Events.Promotion.trackEvent(
        this.props.promotion.discountHeaderId,
        this.props.promotion.name,
        'VIDEO_PLAYED',
      );
    }
  };
  onProgress = () => {
    if (!this.props.navigation.isFocused()) {
      this.setState({
        paused: true,
      });
    }
  };

  render() {
    const posterUrl = ensureHttps(
      Util.changeExtension(this.props.promotion.imagePath, POSTER_EXT),
    );
    const size = {
      width: this.props.width,
      height: this.props.width,
    };
    const path = this.props.promotion.imagePath.replace('http://', 'https://');
    return (
      <InViewPort onChange={this.onVisibilityChanged} stopWhenVisible={false}>
        <TouchableOpacity
          accessibilityLabel="Video promotion"
          style={[Styles.Style.promotionContainer, size]}
          onPress={this.onClick}
        >
          {this.state.controlsVisible && (
            <TouchableOpacity
              onPress={this.props.onClick}
              style={styles.videoLink}
            >
              <FontAwesome5Pro
                name="external-link"
                color={Styles.white}
                size={Styles.Fonts.f4}
                light
              />
            </TouchableOpacity>
          )}
          <Video
            source={{
              uri: path,
            }}
            ref={this.ref}
            style={[styles.video, size]}
            poster={posterUrl}
            paused={this.state.paused}
            onEnd={this.onEnd} // I couldn't get the didBlur event to work, so this was my workaround
            onProgress={this.onProgress}
            onLoad={this.onLoad}
            controls={Platform.OS === 'ios'}
            resizeMode="cover"
          />
        </TouchableOpacity>
      </InViewPort>
    );
  }
}

const styles = StyleSheet.create({
  videoLink: {
    position: 'absolute',
    alignSelf: 'center',
    top: '45%',
    zIndex: 100,
    padding: Styles.Spacing.m3,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
    ...Platform.select({
      ios: {
        backgroundColor: '#777777',
      },
      default: {
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
      },
    }),
  },
  video: {
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
  },
});
export default VideoPromotion;
