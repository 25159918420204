import moment from 'moment';
import Api from './Api';
import {UrlTypes} from 'src/types/UrlTypes';
import Settings from 'src/Settings';
import {store} from '../redux/store';

export const HardwareTypes = {
  VEX: 'VEX',
};

class AVLiveApi {
  login(username: string, password: string) {
    const url = this.getFullUrl('/v2/auth/login');
    const body = {
      username,
      password,
    };
    return Api.fetch(url, body, 'POST');
  }

  // Maybe can move to gmaapi, but would require more logic in the app
  authorizeTransaction(qrValue: string, deviceId: string, force: boolean) {
    const url = this.getFullUrl('/v2/authorizegmaqr', {
      deviceId,
    });
    const body = {
      force,
      qrValue,
    };
    return Api.fetch(url, body, 'POST');
  }

  // Doesn't really do anything?
  cancelTransaction(accountId: string, transactionId: string) {
    const url = this.getFullUrl('/cancelgma', {
      gmaid: accountId,
      transaction: transactionId,
    });
    return Api.fetch(url, null, 'POST');
  }

  captureTransaction(accountId: string, transactionId: string, items: any[]) {
    const url = this.getFullUrl('/gmaorder', {
      transaction: transactionId,
      test: false,
      gmaid: accountId,
    });
    return Api.fetch(url, items, 'POST');
  }

  setup(deviceId: string, request: any) {
    const url = this.getFullUrl('/2014-12-26/machine/setup', {
      deviceId,
    });
    return Api.fetch(url, request, 'POST');
  }

  sendEmail(
    deviceId: string,
    subject: string,
    name: string,
    email: string,
    comments: string,
  ) {
    const url = this.getFullUrl('/v2/email');
    const body = {
      deviceId,
      name,
      email,
      phone: '',
      subject,
      comments,
    };
    return Api.fetch(url, body, 'POST');
  }

  async getServerTime() {
    const url = this.getFullUrl('/test');
    const obj = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Origin: '',
      },
    };
    const response = await fetch(url, obj);
    const responseDate = response.headers.get('Date');
    const utcDate = moment(responseDate).utc();
    return utcDate.format('YYYY-MM-DD HH:mm:ss');
  }

  updateProducts(
    deviceId: string,
    userId: string,
    planogramId: string,
    products: [],
    date: string,
  ) {
    const url = this.getFullUrl('/planogram/updateLocations', {
      device: deviceId,
      userId,
      planogramId,
      gmtUpdateDate: date,
    });
    return Api.fetch(url, products, 'POST');
  }

  getCompanyProducts(deviceId: string) {
    const url = this.getFullUrl('/companyproducts', {
      device: deviceId,
    });
    return Api.fetch(url, null, 'GET');
  }

  getMachineInfo(deviceId: string, gmaId: string | null) {
    const params: any = {
      deviceId,
    };

    if (gmaId) {
      params.gmaid = gmaId;
    }

    const url = this.getFullUrl('/v2/machine/info', params);
    return Api.fetch(url, null, 'GET');
  }

  getMachineInfoV3(deviceId: string, accountId: string, hardwareType: string) {
    const params = {
      gmaid: accountId,
      deviceId,
      hardwareType,
    };
    const url = this.getFullUrl('/v3/machine/info', params);
    return Api.fetch(url, null, 'GET');
  }

  addMachineImage(deviceId: string, imageData: any, date: string) {
    const extension = '.jpg';
    const params = {
      deviceId,
      date,
      extension,
    };
    const url = this.getFullUrl('/addMachineImage', params);
    return Api.fetch(url, imageData, 'POST');
  }

  deleteMachineImage(deviceId: string, date: string) {
    const params = {
      deviceId,
      date,
    };
    const url = this.getFullUrl('/deleteMachineImage', params);
    return Api.fetch(url, null, 'POST');
  }

  changePassword(username: string, password: string, newPassword: string) {
    const url = this.getFullUrl('/auth/changePassword');
    const postObject = {
      Username: username,
      CurrentPassword: password,
      NewPassword: newPassword,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  uploadMDBOptions(deviceId: string, mdbOptions: any) {
    const params = {
      deviceId,
    };
    const url = this.getFullUrl('/updatemdbwriteoptions', params);
    return Api.fetch(url, mdbOptions, 'POST');
  }

  uploadVmcLog(deviceId: string, logs: any) {
    const params = {
      deviceId,
    };
    const url = this.getFullUrl('/mdblog', params);
    return Api.fetch(
      url,
      {
        log: logs,
      },
      'POST',
    );
  }

  serviceComplete(deviceId: string, date: string, cashBagAmount: string) {
    const params: any = {
      deviceId,
      gmtDateCompleted: date,
    };

    if (cashBagAmount != null) {
      params.cashBagAmount = cashBagAmount;
    }

    const url = this.getFullUrl('/servicecompleted', params);
    return Api.fetch(url, null, 'GET');
  }

  uploadDexFiles(deviceId: string, files: []) {
    const url = this.getFullUrl('/dexTR', {
      deviceId,
    });
    return Api.fetch(url, files, 'POST');
  }

  fetchPlanogram(deviceId: string) {
    const url = this.getFullUrl('/2016-03-07/planogram/machine', {
      device: deviceId,
      sendAllProducts: true,
    });
    return Api.fetch(url, null, 'GET');
  }

  uploadPing(deviceId: string, pingData: any) {
    const url = this.getFullUrl('/2016-01-06/ping', {
      deviceId,
    });
    return Api.fetch(url, pingData, 'POST');
  }

  isInSystem(deviceId: string): Promise<any> {
    const url = this.getFullUrl('/system', {
      deviceId,
    });
    return Api.fetch(url, null, 'GET');
  }

  getSharedKey(publicKey: string) {
    const url = this.getFullUrl('/ve/sharedkey', {
      publicKey,
    });
    return Api.fetch(url, null, 'GET');
  }

  checkVersion(deviceId: string, version: string) {
    const url = this.getFullUrl('/version/check', {
      deviceId,
      version,
    });
    return Api.fetch(url, null, 'POST');
  }

  getFullUrl(path: string, queryStringParams?: any) {
    const baseUrl = store.getState().environment.serviceUrls[UrlTypes.avlive];

    if (queryStringParams) {
      queryStringParams.key = Settings.key;
    } else {
      queryStringParams = {
        key: Settings.key,
      };
    }

    return Api.getFullUrl(baseUrl, path, queryStringParams);
  }
}

export default new AVLiveApi();
