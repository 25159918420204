import * as React from 'react';
import {View, StyleSheet} from 'react-native';
import Key from './Key';
import type {KeypadProps} from './types';
import Styles from '../../../../Styles';
import Localized from 'src/constants/AppStrings';
export default function Keypad1({
  onKeyClick,
  allowMoreSelections,
  onClearPressed,
  onEnterPressed,
}: KeypadProps) {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="1" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="2" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="3" />
      </View>
      <View style={styles.row}>
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="4" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="5" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="6" />
      </View>
      <View style={styles.row}>
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="7" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="8" />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="9" />
      </View>
      <View style={styles.row}>
        <Key
          specialKey
          onClick={onClearPressed}
          value={Localized.Buttons.clear}
        />
        <Key disabled={!allowMoreSelections} onClick={onKeyClick} value="0" />
        <Key
          specialKey
          onClick={onEnterPressed}
          value={Localized.Buttons.enter}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: Styles.Spacing.m5 + Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
