import {Characteristic} from 'react-native-ble-plx';
import {encodeValue, Radix, getCrc32Posix} from '../Encoding';
import {PaymentRequest} from './PaymentRequestCharacteristic';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
import {encrypt} from '../../AESEncryption';
const status = {
  approved: '55aa6699',
  notApproved: '00000000',
};
const paymentApprovalMeta = {
  priceLength: 8,
  selectionLength: 8,
  counterLength: 8,
};
export default class PaymentApprovalCharacteristic {
  characteristic: Characteristic;

  constructor(characteristic: Characteristic) {
    this.characteristic = characteristic;
  }

  async approvePayment(
    paymentRequest: PaymentRequest,
    approved: boolean,
    publicKeyHex: string,
  ) {
    const transactionCounterHex = paymentRequest.transactionCounter
      .toString(Radix.Hex)
      .padStart(paymentApprovalMeta.counterLength, '0');
    const selectionIdHex = paymentRequest.selectionId;
    const priceNoDecimals = paymentRequest.selectionPrice * 100;
    const priceHex = priceNoDecimals
      .toString(Radix.Hex)
      .padStart(paymentApprovalMeta.priceLength, '0');
    let statusHex = status.notApproved;

    if (approved) {
      statusHex = status.approved;
    }

    const unusedBytes = '00000000';
    const paymentApprovalPayloadHex =
      paymentRequest.id +
      transactionCounterHex +
      selectionIdHex +
      priceHex +
      statusHex +
      unusedBytes;
    const crc = getCrc32Posix(paymentApprovalPayloadHex);
    const finalPayload = paymentApprovalPayloadHex + crc;
    const encryptedPayload = await encrypt(finalPayload, publicKeyHex);
    const paymentApprovalPayloadBase64 = encodeValue(
      encryptedPayload,
      'hex',
      'base64',
    );
    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.CharacteristicPreWrite,
      {
        uuid: this.characteristic.uuid,
        value: finalPayload,
      },
    );
    await this.characteristic.writeWithResponse(paymentApprovalPayloadBase64);
    Events.VendorsExchange.trackEvent(
      VendorsExchangeActions.CharacteristicPostWrite,
      {
        uuid: this.characteristic.uuid,
        value: finalPayload,
      },
    );
  }
}
