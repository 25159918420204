import React, {useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import {ScreenWidth} from 'react-native-elements/dist/helpers';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import BackSubheader from 'src/components/elements/BackSubheader';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import PersonAddWhite from 'src/components/img/svg/PersonAddWhite';
import DevicesAndScanCardWhite from 'src/components/img/svg/DevicesAndScanCardWhite';
import Settings from 'src/Settings';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';

const NeverUsedAppScreen = () => {
  const gotoCreateAcount = useCallback(() => {
    if (Settings.buildType === BuildTypeConstants.pantry) {
      NavActions.push(AppRoutes.PantryMarketSearch);
    } else {
      NavActions.push(AppRoutes.CreateAccountEnterEmail);
    }
  }, []);

  const gotoLinkAcount = useCallback(() => {
    NavActions.push(AppRoutes.LinkExistingAccount);
  }, []);

  return (
    <BackSubheader title={Localized.Labels.all_new_to_me}>
      <View style={styles.container}>
        <View style={styles.box}>
          <AVTouchableOpacity style={styles.flex} onPress={gotoCreateAcount}>
            <View style={styles.row}>
              <View style={styles.flex}>
                <PersonAddWhite size={ScreenWidth * 0.2} />
              </View>
              <AVText style={styles.descText}>
                {Localized.Labels.dont_have_existing_account}
              </AVText>
            </View>
            <AVText style={styles.createAccountText}>
              {Localized.Labels.create_account}
            </AVText>
          </AVTouchableOpacity>
        </View>
        <View style={styles.middle} />

        <View style={styles.box}>
          <AVTouchableOpacity style={styles.flex} onPress={gotoLinkAcount}>
            <View style={styles.row}>
              <View style={styles.flex}>
                <DevicesAndScanCardWhite size={ScreenWidth * 0.2} />
              </View>
              <AVText style={styles.descText}>
                {Localized.Labels.account_on_kiosk_or_received_login}
              </AVText>
            </View>
            <AVText style={styles.linkAccountText}>
              {Localized.Labels.link_existing_account}
            </AVText>
          </AVTouchableOpacity>
        </View>
      </View>
    </BackSubheader>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: Styles.Spacing.m4,
  },
  box: {
    flex: 1,
    borderRadius: 8,
    backgroundColor: Styles.lightGray,
    padding: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
  flex: {
    flex: 1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
  middle: {
    height: Styles.Heights.h1,
  },
  descText: {
    textAlign: 'right',
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
    alignSelf: 'flex-end',
  },
  createAccountText: {
    textAlign: 'right',
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    color: Styles.white,
    marginRight: Styles.Spacing.m3,
  },
  linkAccountText: {
    textAlign: 'right',
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    color: Styles.white,
    marginRight: Styles.Spacing.m3,
  },
});

export default NeverUsedAppScreen;
