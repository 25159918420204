import React from 'react';
import {
  TouchableOpacity,
  View,
  StyleSheet,
  Text,
  Image,
  Platform,
} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {
  DietaryHealthGood,
  DietaryHealthWarning,
  DietaryHealthAlert,
} from 'src/components/img/svg/DietaryHealthGrades';
import AVFormattedCurrency from '../AVFormattedCurrency';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import {DNA} from 'src/types/dnaTypes';
import AccountStore from 'src/stores/AccountStore';

type SearchProductProps = {
  imageUrl?: string;
  name: string;
  price: number;
  item?: any;
  dnaConfiguration?: DNA;
  onPress: () => void;
};

/** Remove arrow functions for optimization */
function SearchProduct(props: SearchProductProps) {
  // isDisabled = false --> DNA is enabled
  // Color codes don't make sense without knowing the calories
  // ---> const showHealthGrade = !props?.dnaConfiguration?.isDisabled && props?.dnaConfiguration?.showColorCodes && props?.dnaConfiguration?.showCalories;
  const showHealthGrade =
    !props?.dnaConfiguration?.isDisabled &&
    props?.dnaConfiguration?.showColorCodes;
  const calories = props.item?.calories;

  const unitDisplayName = props?.dnaConfiguration?.showCaloriesAsKilocalories
    ? Localized.Labels.kcal
    : Localized.Labels.cal;

  let imageURL = null;
  if (
    props.imageUrl !== null &&
    props.imageUrl !== undefined &&
    props.item?.smallimageurl
  ) {
    imageURL = (
      <Image
        accessible={true}
        accessibilityElementsHidden={true}
        importantForAccessibility="no-hide-descendants"
        accessibilityRole="image"
        role="img"
        source={{uri: props.item.smallimageurl}}
        style={styles.image}
        resizeMode="contain"
        testID="productImage"
      />
    );
  }

  let healthGrade = null;
  const parsedCalorieValue = parseInt(calories);

  // calories is a number
  if (showHealthGrade === true) {
    if (!isNaN(parsedCalorieValue)) {
      // calories is a number greater than or equal to 0
      if (parsedCalorieValue >= 0) {
        if (parsedCalorieValue <= props?.dnaConfiguration?.levelGreenMax) {
          healthGrade = (
            <View
              accessible={true}
              accessibilityLabel={`${Localized.Labels.health_grade}: ${Localized.Labels.good}, `}
              accessibilityHint={`Calories are between 0 - ${props?.dnaConfiguration?.levelGreenMax}`}
              accessibilityRole="image"
              role="img"
              aria-label={`${Localized.Labels.health_grade}: ${Localized.Labels.good}, `}
              style={styles.healthGrade}
            >
              <DietaryHealthGood />
            </View>
          );
        } else if (
          parsedCalorieValue <= props?.dnaConfiguration?.levelYellowMax
        ) {
          healthGrade = (
            <View
              accessible={true}
              accessibilityLabel={`${Localized.Labels.health_grade}: ${Localized.Labels.warning}, `}
              accessibilityHint={`Calories are between ${props?.dnaConfiguration?.levelGreenMax} - ${props?.dnaConfiguration?.levelYellowMax}`}
              accessibilityRole="image"
              role="img"
              aria-label={`${Localized.Labels.health_grade}: ${Localized.Labels.warning}, `}
              style={styles.healthGrade}
            >
              <DietaryHealthWarning />
            </View>
          );
        } else {
          healthGrade = (
            <View
              accessible={true}
              accessibilityLabel={`${Localized.Labels.health_grade}: ${Localized.Labels.alert}, `}
              accessibilityHint={`Calories are greater than ${props?.dnaConfiguration?.levelYellowMax}`}
              accessibilityRole="image"
              role="img"
              aria-label={`${Localized.Labels.health_grade}: ${Localized.Labels.alert}, `}
              style={styles.healthGrade}
            >
              <DietaryHealthAlert />
            </View>
          );
        }
      } else {
        healthGrade = null;
      }
    }
    // calories is null or undefined
    else {
      healthGrade = null;
    }
  } else {
    healthGrade = null;
  }

  return (
    <TouchableOpacity
      accessible={true}
      accessibilityRole="button"
      accessibilityHint="Double tap to navigate to the product details screen"
      accessibilityState={{disabled: false}}
      aria-disabled={false}
      role="button"
      onPress={props.onPress}
    >
      <View style={styles.container}>
        <View style={styles.cellContent}>
          {imageURL !== null && (
            <View style={styles.imageContainer}>{imageURL}</View>
          )}

          <View style={styles.mainCellContent}>
            <Text
              accessible={true}
              accessibilityLabel={`${props.name}, `}
              accessibilityRole="text"
              aria-label={`${props.name}, `}
              style={styles.productName}
              maxFontSizeMultiplier={2}
            >
              {props.name}
            </Text>

            <View style={styles.row}>
              {props.dnaConfiguration?.showCalories && calories !== null && (
                <Text
                  accessible={true}
                  accessibilityLabel={`${calories} ${Localized.Labels.calories.toLowerCase()}, `}
                  accessibilityRole="text"
                  aria-label={`${calories} ${Localized.Labels.calories.toLowerCase()}, text, `}
                  style={styles.productCalories}
                  maxFontSizeMultiplier={2}
                >
                  {`${calories} ${unitDisplayName}`}
                </Text>
              )}
              {healthGrade}
            </View>
          </View>

          <View style={styles.row}>
            <AVFormattedCurrency
              style={styles.productPrice}
              value={props.price}
              accessible={true}
              aria-label={`$${props.price.toFixed(2)}, `}
              accessibilityLabel={`$${props.price.toFixed(2)}, text, `}
              currency={AccountStore.getCurrency()}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              maxFractionDigits={2}
            />

            <FontAwesome5Pro
              accessibilityLabel="right arrow"
              aria-label="right arrow"
              name="chevron-right"
              color={Styles.lightGray}
              size={Styles.Fonts.f2}
              light
            />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.white,
  },
  row: {
    flexDirection: 'row',
  },
  cellContent: {
    flexDirection: 'row',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 1,
    alignItems: 'center',
    paddingVertical: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
  },
  mainCellContent: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginRight: Styles.Spacing.m2,
    padding: 7.5,
  },
  productName: {
    fontSize: 18,
    fontWeight: '600',
    paddingRight: 8,
    color: Styles.darkColor,
    marginVertical: Styles.Spacing.m2,
  },
  productCalories: {
    fontSize: 14,
    color: Styles.darkColor,
    marginRight: Styles.Spacing.m1,
  },
  productPrice: {
    fontSize: 14,
    color: Styles.darkColor,
    marginRight: 10,
  },
  image: {
    width: Platform.OS === 'web' ? 150 : 100,
    height: Platform.OS === 'web' ? 150 : 100,
    backgroundColor: Styles.white,
    aspectRatio: 1.2 / 1,
  },
  imageContainer: {
    flexDirection: 'column',
    marginRight: Styles.Spacing.m2,
  },
  healthGrade: {},
});

export default SearchProduct;
