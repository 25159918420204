import {EventEmitter} from 'events';
import {PACKET_PREFIX} from './PacketConstants';
import {Radix} from '../Encoding';
import {commands} from 'src/constants/BluetoothCommands';
const LENGTH_START_INDEX = 2;
const LENGTH_END_INDEX = 4;
const BYTE_LENGTH = 2;
const PACKET_METADATA_LENGTH = 6;
const COMMAND_START_INDEX = 4;
const COMMAND_END_INDEX = 6;
const LONG_PACKET_NUMBER_START_INDEX = 6;
const LONG_PACKET_NUMBER_END_INDEX = 10;
const LONG_PACKET_COUNT_START_INDEX = 10;
const LONG_PACKET_COUNT_END_INDEX = 14;
export const PacketEvents = {
  onPacketReceived: 'onPacketReceived',
};
export default class PacketParser extends EventEmitter {
  partialPacket = '';
  longMessageIndex = 0;
  longMessageTotalCount = 0;
  longMessage = '';

  packetReceived(hexPayload: string) {
    if (this.partialPacket === null) {
      this.partialPacket = '';
    }

    const fullMessage = this.partialPacket + hexPayload;
    this.partialPacket = '';
    let rawPacket = '';

    if (fullMessage.startsWith(PACKET_PREFIX)) {
      const lengthOfPacket = fullMessage.substring(
        LENGTH_START_INDEX,
        LENGTH_END_INDEX,
      );
      const lengthAsInt = parseInt(lengthOfPacket, Radix.Hex);

      if (
        fullMessage.length <
        lengthAsInt * BYTE_LENGTH + PACKET_METADATA_LENGTH
      ) {
        this.partialPacket = fullMessage;
        return;
      } else {
        rawPacket = fullMessage.substring(
          0,
          lengthAsInt * BYTE_LENGTH + PACKET_METADATA_LENGTH,
        );

        if (
          fullMessage.length >
          lengthAsInt * BYTE_LENGTH + PACKET_METADATA_LENGTH
        ) {
          this.partialPacket = fullMessage.substring(
            lengthAsInt * BYTE_LENGTH + PACKET_METADATA_LENGTH,
          );

          if (
            this.partialPacket.substring(0, LENGTH_START_INDEX) !==
            PACKET_PREFIX
          ) {
            this.partialPacket = '';
          }
        }

        this.processPacket(rawPacket);
        return;
      }
    } else {
      this.partialPacket = '';
      return;
    }
  }

  processPacket(fullMessage: string) {
    if (
      fullMessage.substring(COMMAND_START_INDEX, COMMAND_END_INDEX) !==
      commands.LONG_PACKET_COMMAND
    ) {
      const commandAndMessage = fullMessage.substring(
        COMMAND_START_INDEX,
        fullMessage.length - BYTE_LENGTH,
      );
      this.triggerOnMessageReceived(commandAndMessage);
    } else {
      const packetNumber = fullMessage.substring(
        LONG_PACKET_NUMBER_START_INDEX,
        LONG_PACKET_NUMBER_END_INDEX,
      );
      const packetCount = fullMessage.substring(
        LONG_PACKET_COUNT_START_INDEX,
        LONG_PACKET_COUNT_END_INDEX,
      );

      if (this.longMessageIndex === null) {
        this.longMessageIndex = 0;
      }

      if (this.longMessage === null) {
        this.longMessage = '';
      }

      const packetNumberAsInt = parseInt(packetNumber, Radix.Decimal);

      if (packetNumberAsInt !== this.longMessageIndex) {
        this.resetLongMessage();
        return; // bad packet index
      }

      const packetCountAsInt = parseInt(packetCount, Radix.Decimal);
      this.longMessageTotalCount = packetCountAsInt;

      if (this.longMessageIndex === this.longMessageTotalCount) {
        const message = this.longMessage;
        this.resetLongMessage();
        this.triggerOnMessageReceived(message);
      } else {
        this.longMessage += fullMessage.substring(
          LONG_PACKET_COUNT_END_INDEX,
          fullMessage.length - BYTE_LENGTH,
        );
        this.longMessageIndex = packetNumberAsInt + 1;
      }
    }
  }

  triggerOnMessageReceived(commandAndMessage: string) {
    const command = commandAndMessage.substring(0, BYTE_LENGTH);
    const message = commandAndMessage.substring(BYTE_LENGTH);
    this.emit(PacketEvents.onPacketReceived, {
      command,
      message,
    });
  }

  resetLongMessage() {
    this.longMessage = '';
    this.longMessageIndex = 0;
    this.longMessageTotalCount = 0;
  }
}
