import React from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';
import {ProductType} from 'src/types/ProductType';
import Product from './Product';
type TrayProps = {
  deviceId: string;
  products: Array<ProductType>;
  productClicked: (product: ProductType) => void;
};

const Tray: React.FC<TrayProps> = (props) => {
  let i = 0;
  const productList = props.products.map((product) => (
    <Product
      productClicked={() => props.productClicked(product)}
      product={product.Product}
      key={i++}
    />
  ));
  return (
    <View style={styles.container}>
      <ScrollView
        automaticallyAdjustContentInsets={false}
        style={styles.scrollView}
        alwaysBounceVertical={false}
        horizontal
        showsHorizontalScrollIndicator
      >
        {productList}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  scrollView: {
    alignSelf: 'center',
    marginVertical: 5,
  },
});
export default Tray;
