import * as React from 'react';
import Svg, {Ellipse, G, Path} from 'react-native-svg';
type InviteFriendsProps = {
  size: number;
  isAllWhite?: boolean;
};
export default class InviteFriends extends React.Component<InviteFriendsProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 640 587.77"
      >
        <G id="Layer_2">
          <G id="icons">
            <G id="invitefriends">
              <G>
                <Path
                  d="M456.16,159.89c9.48,0,17.24-9.21,17.24-20.47h0c0-11.26-7.76-20.47-17.24-20.47s-17.25,9.21-17.25,20.47h0C438.91,150.68,446.67,159.89,456.16,159.89Z"
                  fill="#727179"
                />
                <Path
                  d="M470.86,448.55a14.09,14.09,0,0,0,14,12.82c.42,0,.85,0,1.28-.06A14.09,14.09,0,0,0,498.91,446L483.27,272l9.07-62.51c2,2.93,3.69,5.43,4.7,7.15-1.15,5.24-3.38,16.83-8,43.61a10.81,10.81,0,0,0,8.13,12.48,10.53,10.53,0,0,0,12.62-8.53c3.34-19.39,7.3-41.18,8.25-44.75,2.25-6.19-.63-11.44-12.57-27.94-6-8.26-12-16-12.24-16.34a10.51,10.51,0,0,0-6.52-3.91,23.85,23.85,0,0,0-9.62-2H445.44a27.61,27.61,0,0,1-11.95-2.72l-19.62-9.41a10.38,10.38,0,0,1-5.72-7.45c-1.27-6.76-3.61-18.38-7.94-38.67a10.56,10.56,0,0,0-20.65,4.4c4.1,19.25,8.53,40.95,9,44.61.2,6.59,4.83,10.39,22,21.27,3.53,2.24,7,4.39,10,6.16l7.61,70.12a.5.5,0,0,0,0,.12l-11.6,161.81a14.07,14.07,0,0,0,13,15.05,8.7,8.7,0,0,0,1,0c7.37-.18,10.49-6.71,10.88-13.66L453,301.18"
                  fill="#727179"
                />
              </G>
              <G>
                <Path
                  d="M366.89,172c6.76,0,12.28-6.55,12.28-14.57h0c0-8-5.52-14.58-12.28-14.58s-12.28,6.56-12.28,14.58h0C354.61,165.46,360.14,172,366.89,172Z"
                  fill="#727179"
                />
                <Path
                  d="M393.29,182.92a7.48,7.48,0,0,0-4.64-2.78,17,17,0,0,0-6.85-1.41h-27a17.19,17.19,0,0,0-6.45,1.23,7.52,7.52,0,0,0-6.56,4.55c-4.18,9.77-9.23,21.17-11.23,25.17a20.14,20.14,0,0,0-3.43,5.14c-1,1.85-2.25,4.41-3.8,7.61-2.6,5.39-5.12,10.86-5.15,10.92a7.51,7.51,0,0,0,3.68,10,7.36,7.36,0,0,0,3.15.7,7.52,7.52,0,0,0,6.83-4.37c3.26-7.08,7.27-15.42,9.06-18.72a11.83,11.83,0,0,0,2.42-3.2c.25-.47.55-1,.88-1.72l2.78,25.6a.28.28,0,0,0,0,.09L338.7,356.92A10,10,0,0,0,348,367.64c.24,0,.49,0,.73,0a10,10,0,0,0,10-9.31L365.39,265c.56,0,1.12,0,1.69,0h.17l10.11,112.55a10,10,0,0,0,10,9.13c.3,0,.6,0,.91,0a10,10,0,0,0,9.08-10.88L386.2,251.88l6.46-44.51c1.45,2.09,2.63,3.87,3.35,5.09-.83,3.77-2.44,12.11-5.75,31.31a7.51,7.51,0,0,0,6.13,8.68,7.18,7.18,0,0,0,1.29.11,7.52,7.52,0,0,0,7.4-6.24c2.38-13.81,5.2-29.33,5.88-31.86,1.6-4.41-.45-8.15-8.95-19.9C397.75,188.68,393.47,183.15,393.29,182.92Z"
                  fill="#727179"
                />
              </G>
              <G>
                <Path
                  d="M615.4,231.6c3.73,0,6.78-3.62,6.78-8h0c0-4.42-3-8.05-6.78-8.05s-6.78,3.63-6.78,8.05h0C608.62,228,611.67,231.6,615.4,231.6Z"
                  fill="#727179"
                />
                <Path
                  d="M630,237.62a4.12,4.12,0,0,0-2.56-1.54,9.38,9.38,0,0,0-3.78-.78H608.72a9.3,9.3,0,0,0-3.56.69,4.11,4.11,0,0,0-3.62,2.51c-2.31,5.39-5.1,11.68-6.2,13.89a11,11,0,0,0-1.9,2.84c-.54,1-1.24,2.43-2.09,4.2-1.44,3-2.83,6-2.84,6a4.15,4.15,0,1,0,7.53,3.47c1.81-3.91,4-8.51,5-10.33a6.69,6.69,0,0,0,1.33-1.77c.14-.26.3-.58.49-.95L604.4,270a.43.43,0,0,0,0,.05l-4.56,63.6a5.55,5.55,0,0,0,5.13,5.92h.4a5.53,5.53,0,0,0,5.51-5.14l3.7-51.55.93,0h.09l5.59,62.13a5.53,5.53,0,0,0,5.5,5,4.35,4.35,0,0,0,.51,0,5.52,5.52,0,0,0,5-6l-6.14-68.39,3.56-24.57c.8,1.16,1.45,2.14,1.85,2.82-.45,2.08-1.34,6.68-3.17,17.28a4.15,4.15,0,0,0,3.38,4.79,4.34,4.34,0,0,0,.71.06,4.15,4.15,0,0,0,4.09-3.44c1.31-7.63,2.87-16.19,3.24-17.59.89-2.44-.24-4.5-4.94-11C632.43,240.8,630.07,237.75,630,237.62Z"
                  fill="#727179"
                />
              </G>
              <G>
                <Path
                  d="M358.42,506.19,272.66,32.35A39.48,39.48,0,0,0,226.92.63L182.35,8.7,75.6,28,32.35,35.84A39.48,39.48,0,0,0,.63,81.58L86.39,555.43a39.47,39.47,0,0,0,45.74,31.71L326.7,551.93A39.48,39.48,0,0,0,358.42,506.19Zm-33.83,34.05L130,575.46a27.51,27.51,0,0,1-31.93-22.15L12.32,79.47A27.51,27.51,0,0,1,34.46,47.53l27.8-5a3.35,3.35,0,0,1,3.89,2.69L66.84,49A12.39,12.39,0,0,0,81.2,59L188,39.65a12.39,12.39,0,0,0,9.95-14.36l-.69-3.82a3.35,3.35,0,0,1,2.69-3.88L229,12.32A27.51,27.51,0,0,1,261,34.46l85.76,473.85A27.5,27.5,0,0,1,324.59,540.24Z"
                  fill="#727179"
                />
                <Path
                  d="M125.6,319.15c7-.45,11-2.3,16.15-5.3s8.8-5.53,12.67-11.33c.87-1.41,5.65-10.19,12.33-22.82L269,296.76c2.23.45,4.29-.2,4.7-1.44s-1.28-2.55-3.48-3.11l-94.36-30.07c4.58-8.68,9.56-18.44,14.61-28.5l1.45-2.88.72-1.45,1.45-2.89,1.45-2.91c1-1.94,1.93-3.87,2.89-5.81l1.44-2.9c8.36-16.88,16.34-33.26,22.41-45.53l1.51-3.06c4.58-9.23,7.84-15.64,9-17.38,4.08-6.1,5.8-3.47,7.33-.78l.29.52,46.52,80.65-35-193.12a18.26,18.26,0,0,0-18-15,18.93,18.93,0,0,0-3.27.29l-23.45,4.25A21.58,21.58,0,0,1,189.59,48.7L82.84,68A21.59,21.59,0,0,1,58.23,52.57l-22.13,4A18.32,18.32,0,0,0,21.36,77.83L49.2,231.66,217.5,134.6c7.81-4.5,10.92-3.65,12.1,2.61.64,3.56-2.71,9-4.76,12.07-2.55,4-20.36,32.36-38.82,61.81l-2.36,3.76c-20,32-40.13,64.06-41.57,66.29-2.82,4.36-6.35,7.67-12.67,11.32s-11,5.05-16.16,5.3c-2,.1-24.48.89-51.76,1.86l3.12,17.22c5.27.26,10.36.49,15.17.69l-8.24,37.63,4.66,25.72,23.47-62.49C114,318.92,123.87,319.23,125.6,319.15Z"
                  fill="#727179"
                />
                <Path
                  d="M303.14,319.06,87.53,443.41l19.59,108.26a18.25,18.25,0,0,0,21.26,14.74L323,531.2a18.32,18.32,0,0,0,14.74-21.26Z"
                  fill="#727179"
                />
              </G>
              <G>
                <Ellipse
                  cx="554.63"
                  cy="167.14"
                  rx="14.8"
                  ry="17.57"
                  fill="#727179"
                />
                <Path
                  d="M603.73,255.74c-4.74-6.82-10.35-15.11-11.27-16.75-1.49-3.35-5.43-22.63-8.26-38.65a7.74,7.74,0,0,0-1.27-3.08c-2.08-3.78-6.54-4.45-12.82-4.45H539.33a24.34,24.34,0,0,0-8.82,1.4,7.71,7.71,0,0,0-2.23,1.18l0,0-.33.26a6,6,0,0,0-1.24,1.35,7.1,7.1,0,0,0-.57.92c-8.29,15.69-18.15,35.8-18.12,42.44,0,1.16,0,3.88,7.34,27.62a7.77,7.77,0,0,0,7.41,5.47,7.58,7.58,0,0,0,2.29-.35,7.76,7.76,0,0,0,2.23-1.11c-7.33,25.07-11.52,67-11.52,67a71.31,71.31,0,0,0,11.55,6.57l-7.64,84.89A10.35,10.35,0,0,0,529,441.73c.31,0,.63,0,.94,0a10.35,10.35,0,0,0,10.29-9.42l7.35-81.82a53,53,0,0,0,14.32-1.37l4.31,60.07a10.34,10.34,0,0,0,10.3,9.6l.75,0a10.33,10.33,0,0,0,9.58-11.05l-4.82-67.22a82.44,82.44,0,0,0,18.8-16.24L575.8,249.17l1.24-7.54a29.26,29.26,0,0,0,1.57,4.36c1.33,2.63,9.1,13.89,12.38,18.61a7.76,7.76,0,0,0,12.74-8.86Zm-66.34-1.92c-2.61.67-5,4.28-7.21,9.66,0,0,0,0,0-.06-2.79-9.05-6-20-6.59-23.12.56-2.51,3.19-8.63,6.62-15.87l7.35,29.35Z"
                  fill="#727179"
                />
              </G>
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
