/* eslint-disable no-bitwise */
import EventEmitter from 'events';
import {BleError, Characteristic, Subscription} from 'react-native-ble-plx';
import PacketGenerator from './PacketGenerator';
import PacketParser, {PacketEvents} from './PacketParser';
import type {Packet} from './PacketGenerator';
import {encodeValue, Radix} from '../Encoding';
import Events from 'src/logging/Events';
import CrashlyticsEvents from 'src/logging/Crashlytics';
const CHUNK_LENGTH = 40;
const ESC_START = '1b';
const ESC_END = '1f';
const ESC_END_VALUE = parseInt(ESC_END, Radix.Hex);
const ESC_SELF = '1a';
const ESC_SELF_VALUE = parseInt(ESC_SELF, Radix.Hex);
const ESC_ENCODE_SELF = '40';
const ESC_ENCODE = '0f';
const ESC_ROW = '10';
export type PacketReceivedArgs = {
  command: string;
  message: string;
};
export const MessageQueueEvents = {
  onCharacteristicWrite: 'onCharacteristicWrite',
  onPacketReceived: 'onPacketReceived',
  onMessageReceived: 'onMessageReceived',
  onMessageProgress: 'onMessageProgress',
};
export default class MessageQueue extends EventEmitter {
  writeCharacteristic: Characteristic;
  readCharacteristic: Characteristic;
  monitorSubscription: Subscription;
  incomingData = '';
  packetParser: PacketParser;
  isInPackage = false;
  messageTotalByteCount = 0;
  messageBytesSent = 0;
  sendMessages = true;
  writingPromise: Promise<void>;

  constructor(
    writeCharacteristic: Characteristic,
    readCharacteristic: Characteristic,
  ) {
    super();
    this.packetParser = new PacketParser();
    this.packetParser.addListener(
      PacketEvents.onPacketReceived,
      this.onPacketReceived.bind(this),
    );
    this.writingPromise = Promise.resolve();
    this.writeCharacteristic = writeCharacteristic;
    this.readCharacteristic = readCharacteristic;

    if (this.readCharacteristic.isNotifiable) {
      this.monitorSubscription = this.readCharacteristic.monitor(
        this.readCharacteristicChanged.bind(this),
      );
    }
  }

  cleanup() {
    this.monitorSubscription.remove();
    this.sendMessages = false;
  }

  async sendMessage(command: string, hexMessage: string) {
    const packets = PacketGenerator.generatePackets(command, hexMessage);
    // If a message is currently being sent, wait until it's finished.
    await this.writingPromise;
    this.writingPromise = this.addPacketsToQueue(packets);
    return this.writingPromise;
  }

  async sendMessageChunked(message: string) {
    this.messageTotalByteCount = message.length / 2;
    this.messageBytesSent = 0;
    this.emit(
      MessageQueueEvents.onMessageProgress,
      this.messageBytesSent,
      this.messageTotalByteCount,
    );
    let messageToSend = message;

    while (this.sendMessages && messageToSend) {
      let chunk = '';

      if (messageToSend.length > CHUNK_LENGTH) {
        chunk = messageToSend.substring(0, CHUNK_LENGTH);
        messageToSend = messageToSend.substring(CHUNK_LENGTH);
      } else {
        chunk = messageToSend.slice();
        messageToSend = '';
      }

      const chunkBase64 = encodeValue(chunk, 'hex', 'base64');

      if (this.writeCharacteristic) {
        this.emit(MessageQueueEvents.onCharacteristicWrite, chunk);

        try {
          await this.writeCharacteristic.writeWithResponse(chunkBase64);
          this.messageBytesSent += chunk.length / 2;
          this.emit(
            MessageQueueEvents.onMessageProgress,
            this.messageBytesSent,
            this.messageTotalByteCount,
          );
        } catch (error) {
          CrashlyticsEvents.log(
            'Exception',
            'MessageQueue:sendMessageChunked',
            error.message ? error.message : error.toString(),
          );
          Events.Error.trackEvent(
            'Exception',
            'MessageQueue:sendMessageChunked',
            error.message ? error.message : error.toString(),
          );
        }
      }
    }
  }

  async addPacketsToQueue(packets: Packet[]) {
    if (packets) {
      let message = '';

      for (const packet of packets) {
        let packetQueueMessage = ESC_START;
        packetQueueMessage += this.escapePayload(packet.payload);
        packetQueueMessage += ESC_END;
        message += packetQueueMessage;
      }

      await this.sendMessageChunked(message);
    }
  }

  onPacketReceived(args: PacketReceivedArgs) {
    this.emit(MessageQueueEvents.onPacketReceived, args);
  }

  escapePayload(payload: string): string {
    let escapedPayload = '';

    for (let i = 0; i < payload.length; i += 2) {
      let byte = payload.substring(i, i + 2);
      const byteValue = parseInt(byte, Radix.Hex);

      if (byteValue <= ESC_END_VALUE && byteValue >= ESC_SELF_VALUE) {
        escapedPayload += ESC_SELF;
        byte = this.escapeByte(byte);
      }

      escapedPayload += byte;
    }

    return escapedPayload;
  }

  escapeByte(byte: string): string {
    return MessageQueue.orHexStrings(
      MessageQueue.andHexStrings(byte, ESC_ENCODE),
      ESC_ENCODE_SELF,
    );
  }

  readCharacteristicChanged(
    error: BleError | null,
    characteristic: Characteristic | null,
  ) {
    if (error) {
      CrashlyticsEvents.log(
        'Warning',
        'MessageQueue:readCharacteristicChanged',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Warning',
        'MessageQueue:readCharacteristicChanged',
        error.message ? error.message : error.toString(),
      );
    }

    if (characteristic) {
      const value = characteristic.value;
      this.processIncomingStream(value);
    }
  }

  processIncomingStream(base64Data: string) {
    const hexData = encodeValue(base64Data, 'base64', 'hex');
    let nextCharIsEscaped = false;

    for (let i = 0; i < hexData.length; i += 2) {
      let byte = hexData.substring(i, i + 2);
      byte = MessageQueue.andHexStrings(byte, 'ff');
      const byteValue = parseInt(byte, Radix.Hex);
      const rowByte = MessageQueue.andHexStrings(byte, 'f0');

      if (rowByte === ESC_ROW && byteValue >= ESC_SELF_VALUE) {
        if (byte === ESC_SELF) {
          nextCharIsEscaped = true;
        } else if (byte === ESC_START) {
          this.isInPackage = true;
          this.incomingData = '';
        } else if (byte === ESC_END) {
          this.isInPackage = false;
          this.emit(MessageQueueEvents.onMessageReceived);
          this.packetParser.packetReceived(this.incomingData);
          this.incomingData = '';
        }
      } else {
        if (nextCharIsEscaped) {
          nextCharIsEscaped = false;
          const id = MessageQueue.andHexStrings(byte, 'f0');

          if (id === ESC_ENCODE_SELF) {
            byte = MessageQueue.orHexStrings(
              ESC_ROW,
              MessageQueue.andHexStrings(byte, ESC_ENCODE),
            );
          }
        }

        if (this.isInPackage) {
          this.incomingData += byte;
        }
      }
    }
  }

  static andHexStrings(hex1: string, hex2: string): string {
    const hex1Value = parseInt(hex1, Radix.Hex);
    const hex2Value = parseInt(hex2, Radix.Hex);

    const result = hex1Value & hex2Value;
    return MessageQueue.toHexString(result);
  }

  static toHexString(result: number) {
    return result.toString(Radix.Hex).padStart(2, '0');
  }

  static orHexStrings(hex1: string, hex2: string): string {
    const hex1Value = parseInt(hex1, Radix.Hex);
    const hex2Value = parseInt(hex2, Radix.Hex);

    const result = hex1Value | hex2Value;
    return MessageQueue.toHexString(result);
  }
}
