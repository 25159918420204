import {Linking} from 'expo';
type MailOptions = {
  subject: string;
  body: string;
  isHTML?: boolean;
  recipients: string[];
  ccRecipients?: string[];
  bccRecipients?: string[];
  attachment?: {
    path: string;
    type: string;
    name?: string;
  };
};

class Mailer {
  mail(options: MailOptions, callback: (error: string, event: string) => void) {
    const {recipients, subject, body} = options;
    Linking.openURL(
      `mailto:${recipients[0]}?subject=${subject}&body=${encodeURIComponent(
        body,
      )}`,
    );
  }
}

export default new Mailer();
