import React from 'react';
import {
  ActivityIndicator,
  PermissionsAndroid,
  StyleSheet,
  View,
} from 'react-native';
import TimerMixin from 'react-timer-mixin';
import ReactMixin from 'react-mixin';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import moment from 'moment';
import SafeAreaView from '../../elements/SafeAreaView';
import ScreenContext from '../../ScreenContext';
import RequirePermission from '../../elements/RequirePermission';
import NavActions from 'src/actions/NavActions';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import BackSubheader from '../../elements/BackSubheader';
import Events from 'src/logging/Events';
import AVText from '../../elements/AVText';
import MachineStore from 'src/stores/MachineStore';
import BluetoothManager, {
  BluetoothEvents,
} from 'src/services/bluetooth/BluetoothManager';
import Icon from '../../elements/AVIcon';
import CreateAccountPrivacyPolicyHandler from '../../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import MachineActions from 'src/actions/MachineActions';
import BeaconDevice from 'src/services/bluetooth/BeaconDevice';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import type {Referral} from 'src/types/Referral';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

const TIMEOUT_MILLISECONDS = 10000;
type PantryMarketSearchProps = {
  referral?: Referral;
};
type PantryMarketSearchState = {
  searching: boolean;
  marketFound: boolean;
  market?: {id: string};
  showPolicy: boolean;
};

class PantryMarketSearch extends React.Component<
  PantryMarketSearchProps,
  PantryMarketSearchState
> {
  machineTimeout: NodeJS.Timeout;
  currentPrivacyVersion: string;
  startTime: moment.Moment;
  privacyHandler: CreateAccountPrivacyPolicyHandler | null | undefined;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: PantryMarketSearchProps) {
    super(props);
    this.state = {
      searching: false,
      marketFound: false,
      showPolicy: false,
    };
    this.startTime = moment();
    this.handleBack = this.handleBack.bind(this);
    this.onMachineStoreChange = this.onMachineStoreChange.bind(this);
    this.handleTryAgain = this.handleTryAgain.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.onBluetoothDeviceFound = this.onBluetoothDeviceFound.bind(this);
  }

  componentDidMount() {
    MachineStore.addChangeListener(this.onMachineStoreChange);
    BluetoothManager.addListener(
      BluetoothEvents.onDeviceFound,
      this.onBluetoothDeviceFound,
    );
    this.findMachines();
  }

  componentWillUnmount() {
    MachineStore.removeChangeListener(this.onMachineStoreChange);
    BluetoothManager.removeListener(
      BluetoothEvents.onDeviceFound,
      this.onBluetoothDeviceFound,
    );
  }

  async onBluetoothDeviceFound(device: BeaconDevice) {
    if (device.type === BluetoothDeviceTypes.beacon) {
      MachineActions.marketScannedLegacy(
        device.beaconId,
        AccountStore.getAccountId(),
        Settings.buildType,
      );
    }
  }

  onMachineStoreChange() {
    const markets = MachineStore.getMarkets();
    FirebaseAnalytic.trackEvent(
      'onMachineStoreChange',
      'PantryMarketSearchScreen',
      {
        ...this.props,
        ...this.state,
        markets,
      },
    );
    if (markets.length > 0) {
      const market = markets.find((m) => m.type === 'PANTRY');

      if (market) {
        this.setState({
          searching: false,
          marketFound: true,
          market,
        });
        clearTimeout(this.machineTimeout);
      }
    }
  }

  findMachines() {
    try {
      BluetoothManager.startScan();
      this.machineTimeout = setTimeout(() => {
        this.setState({
          searching: false,
          marketFound: false,
        });
      }, TIMEOUT_MILLISECONDS);
      this.setState({
        searching: true,
      });
    } catch (e) {
      this.setState({
        searching: false,
      });
      FirebaseAnalytic.trackEvent(
        'findMachines: error',
        'PantryMarketSearchScreen',
        {
          ...this.props,
          ...this.state,
          e,
        },
      );
    }
  }

  handleBack() {
    Events.AccountCreation.trackEvent(this.startTime, 'PantryMarketSearch');
    NavActions.pop();
  }

  handleTryAgain() {
    this.findMachines();
    FirebaseAnalytic.trackEvent(
      'findMachines: retry',
      'PantryMarketSearchScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  handleContinue() {
    if (this.state.market && this.privacyHandler) {
      this.privacyHandler.showPrivacyPolicy().then(() => {
        FirebaseAnalytic.trackEvent(
          'handleClick showPrivacyPolicy',
          'PantryMarketSearchScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      });
    }
  }

  renderMainContent() {
    if (this.state.searching) {
      return (
        <>
          <ActivityIndicator
            animating
            color={Styles.primaryColor}
            size="large"
            style={styles.activity}
          />
          <AVText style={styles.mainLabel}>
            {Localized.Labels.searching_for_market}
          </AVText>
        </>
      );
    } else if (this.state.marketFound) {
      return (
        <View style={styles.stretch}>
          <Icon
            name={'md-checkmark-circle'}
            size={120}
            color={Styles.primaryColor}
            style={styles.alertIcon}
          />
          <AVText style={styles.mainLabel}>{Localized.Success.success}</AVText>
          <AVText style={styles.information}>
            {Localized.Labels.connected_to_market}
          </AVText>
        </View>
      );
    } else {
      return (
        <View style={styles.stretch}>
          <Icon
            name={'md-alert'}
            size={120}
            color={Styles.warningColor}
            style={styles.alertIcon}
          />
          <AVText style={styles.mainLabel}>
            {Localized.Labels.unable_to_connect}
          </AVText>
          <AVText style={styles.information}>
            {Localized.Labels.ensure_you_are_close_to_market}
          </AVText>
          <RoundedButton
            buttonType={ButtonType.outline}
            accessibilityLabel={'TryAgain'}
            onPress={this.handleTryAgain}
            text={Localized.Buttons.try_again}
          />
        </View>
      );
    }
  }

  render() {
    return (
      <SafeAreaView
        forceInset={{
          top: 'never',
        }}
        style={Styles.Style.flex}
      >
        <CreateAccountPrivacyPolicyHandler
          ref={(privacyHandler) => {
            this.privacyHandler = privacyHandler;
          }}
          params={{
            location: this.state.market ? this.state.market.id : '',
            referral: this.props.referral,
          }}
        />
        <BackSubheader title={Localized.Labels.create_account}>
          <View style={styles.container}>
            <RequirePermission
              permission={PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION}
            />
            <RequirePermission
              permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN}
            />
            <RequirePermission
              permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_CONNECT}
            />
            <RequirePermission
              permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_ADVERTISE}
            />
            <View style={styles.content}>
              <View style={styles.header}>
                <AVText style={styles.instructions}>
                  {Localized.Labels.you_must_be_within_range}
                </AVText>
              </View>
              <View style={styles.mainContent}>{this.renderMainContent()}</View>
            </View>
          </View>
          {this.state.marketFound && (
            <RoundedButton
              buttonType={ButtonType.action}
              accessibilityLabel={'Continue'}
              onPress={this.handleContinue}
              text={Localized.Buttons.continue}
            />
          )}
        </BackSubheader>
      </SafeAreaView>
    );
  }
}

ReactMixin.onClass(PantryMarketSearch, TimerMixin);
const styles = StyleSheet.create({
  activity: {
    height: Styles.Heights.headerHeight,
    transform: [
      {
        scale: 2.0,
      },
    ],
  },
  alertIcon: {
    alignSelf: 'center',
    height: Styles.Heights.headerHeight,
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
    textAlign: 'center',
    width: Styles.Heights.headerHeight,
  },
  backgroundImage: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  header: {
    alignSelf: 'stretch',
    paddingHorizontal: Styles.Spacing.m2,
    paddingTop: Styles.Spacing.m2,
  },
  information: {
    fontSize: Styles.Fonts.f2,
    marginBottom: Styles.Spacing.m4,
    textAlign: 'center',
  },
  instructions: {
    fontSize: Styles.Fonts.f2,
    marginTop: 0,
  },
  mainContent: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: Styles.Spacing.m2,
  },
  mainLabel: {
    alignSelf: 'stretch',
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m2,
    textAlign: 'center',
  },
  stretch: {
    alignSelf: 'stretch',
  },
});
export default withForwardedNavigationParams<PantryMarketSearchProps>()(
  PantryMarketSearch,
);
