import {Characteristic, BleError, Subscription} from 'react-native-ble-plx';
import EventEmitter from 'events';
import Events from 'src/logging/Events';
import CrashlyticsEvents from 'src/logging/Crashlytics';
export const PaymentNotifyEvents = {
  updated: 'updated',
};
export default class PaymentNotifyCharacteristic extends EventEmitter {
  monitorSubscription: Subscription;

  constructor(characteristic: Characteristic) {
    super();
    this.characteristicChanged = this.characteristicChanged.bind(this);

    if (characteristic.isNotifiable) {
      this.monitorSubscription = characteristic.monitor(
        this.characteristicChanged,
      );
    }
  }

  deregister() {
    this.monitorSubscription.remove();
  }

  characteristicChanged(
    error: BleError | null,
    characteristic: Characteristic | null,
  ) {
    if (error) {
      CrashlyticsEvents.log(
        'Warning',
        'PaymentNotifyCharacteristic:characteristicChanged',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Warning',
        'PaymentNotifyCharacteristic:characteristicChanged',
        error.message ? error.message : error.toString(),
      );
    }

    if (characteristic) {
      this.emit(PaymentNotifyEvents.updated, characteristic.value);
    }
  }
}
