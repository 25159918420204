import React from 'react';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Icon from '../../elements/AVIcon';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type EatItOrDeleteItWelcomeScreenProps = Record<string, never>;
type EatItOrDeleteItWelcomeScreenState = {
  location: string;
};

class EatItOrDeleteItWelcomeScreen extends React.Component<
  EatItOrDeleteItWelcomeScreenProps,
  EatItOrDeleteItWelcomeScreenState
> {
  static contextType = ScreenContext;

  constructor(props: EatItOrDeleteItWelcomeScreenProps) {
    super(props);
    this.state = {
      location: AccountStore.getLocationId(),
    };
    this.continueClick = this.continueClick.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'ProductFeedbackWelcomeScreen',
      {
        ...this.props,
        ...this.state,
      },
    );

    ActionsFactory.getAccountActions().visitedEatItOrDeleteItWelcomeScreen();
  }

  continueClick() {
    FirebaseAnalytic.trackEvent(
      'continueClick',
      'ProductFeedbackWelcomeScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (this.state.location) {
      NavActions.replace(AppRoutes.ProductFeedbackInstruction, {
        eatItUrl: AccountStore.getEatItUrl(),
      });
    } else {
      NavActions.replace(AppRoutes.FeedbackLocationScan);
    }
  }

  render() {
    let locationInstruction;

    if (!this.state.location) {
      locationInstruction = (
        <AVText style={styles.text}>
          {Localized.Labels.eat_it_or_delete_it_scan_instruction}
        </AVText>
      );
    }

    return (
      <BackSubheader title={Localized.Labels.eat_it_or_delete_it}>
        <View style={styles.content}>
          <Icon name="ios-heart" color={Styles.primaryColor} size={48} />
          <AVText style={styles.title}>
            {Localized.Labels.welcome_to_eat_it_or_delete_it}
          </AVText>
          <AVText style={styles.text}>
            {Localized.Labels.eat_it_or_delete_it_instruction}
          </AVText>
          {locationInstruction}
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.continueClick}
          accessibilityLabel="Continue"
          text={Localized.Buttons.continue}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    borderRadius: 4,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m5,
    paddingHorizontal: Styles.Spacing.m1,
    paddingVertical: Styles.Spacing.m2,
  },
  override: {
    marginTop: Styles.Spacing.m1,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    marginVertical: Styles.Spacing.m2,
    textAlign: 'center',
  },
  title: {
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
export default withForwardedNavigationParams<EatItOrDeleteItWelcomeScreenProps>()(
  EatItOrDeleteItWelcomeScreen,
);
