import React from 'react';
import {useGlobalize} from 'react-native-globalize';
import Styles from 'src/components/Styles';
import AllyTextInput from 'src/components/elements/AllyTextInput';

type CurrencyInputProps = {
  value: number;
  currency: string;
  onUpdate: (value: string) => void;
  label: string;
  accessibilityLabel: string;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  currency,
  onUpdate,
  label,
  accessibilityLabel,
}) => {
  const {formatCurrency} = useGlobalize();
  return (
    <AllyTextInput
      label={label}
      value={formatCurrency(value, currency)}
      keyboardType="number-pad"
      accessible={true}
      accessibilityLabel={accessibilityLabel}
      accessibilityValue={{text: formatCurrency(value, currency)}}
      onChangeText={onUpdate}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
    />
  );
};

export default CurrencyInput;
