import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

function CardBackWhite({color = '#fff', size = 640}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 640 640">
      <Path fill={color} d="M457.36 387.31H548.9300000000001V454.61H457.36z" />
      <Path fill={color} d="M91.07 432.18H228.43V454.61H91.07z" />
      <Path
        d="M600.44 95.65H39.56c-22.17 0-39.92 17.53-40.06 39.26v370.18c.29 21.73 17.89 39.26 40.06 39.26h560.88c22.32 0 40.06-17.81 40.06-39.68V135.33c0-21.87-17.74-39.68-40.06-39.68zM45.29 156.93c1.43-9.54 9.01-16.41 18.74-16.41h511.8c9.87 0 17.31 7.15 18.74 16.83v28.04H45.29v-28.46zm549.42 325.72c-1.43 9.67-9.01 16.83-18.74 16.83H64.03c-9.87 0-17.31-6.87-18.74-16.41V320h549.42v162.65z"
        fill={color}
      />
      <Path fill={color} d="M91.07 387.31H365.78V409.74H91.07z" />
    </Svg>
  );
}

export default CardBackWhite;
