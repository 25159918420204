import * as React from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';
import Styles from '../Styles';

class LoadingScreen extends React.Component {
  render() {
    return (
      <View
        accessible={true}
        accessibilityLabel="Loading"
        accessibilityState={{busy: true}}
        aria-label="Loading"
        aria-busy={true}
        style={styles.container}
      >
        <ActivityIndicator color={Styles.primaryColor} size="large" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flex: 1,
    justifyContent: 'center',
  },
});
export default LoadingScreen;
