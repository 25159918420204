import React from 'react';
import {Platform, StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import Localized from 'src/constants/AppStrings';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import CollapsibleComponent from '../../elements/CollapsibleComponent';
import SupportEmailService from 'src/services/SupportEmailService';
import UIManager from '../../elements/ui/UIManager';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import {alertError} from '../../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type HelpProps = {
  navigation?: NavigationProp<HelpScreen>;
};

type HelpState = {
  previousRoute?: string | null;
};

class HelpScreen extends React.Component<HelpProps, HelpState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: HelpProps) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.handleContactClick = this.handleContactClick.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  handleContactClick() {
    const appname =
      Platform.OS === 'web'
        ? `${Settings.webAppDisplayName}`
        : `${Settings.appDisplayName} App`;
    const subject = `${appname} Help Inquiry`;
    const body = `${Localized.Labels.help_details_prompt}`;

    FirebaseAnalytic.trackEvent('handleContactClick', 'HelpScreen', {
      subject,
      body,
    });

    SupportEmailService.createEmail(
      subject,
      body,
      (email: string, guid: string | null | undefined) => {
        alertError(`${Localized.Errors.could_not_send_email} ${email}`, guid);
      },
    );
  }

  render() {
    const faqs: {
      question: any;
      answer: any;
    }[] = [
      {
        question: Localized.Labels.formatString(
          Localized.Labels.faq_market_compatible,
          Settings.appDisplayName,
        ),
        answer: Localized.Labels.formatString(
          Localized.Labels.faq_market_compatible_answer,
          Settings.appDisplayName,
        ),
      },
      {
        question: Localized.Labels.faq_auto_reload,
        answer: Platform.select({
          web: Localized.Labels.faq_auto_reload_answer_web,
          default: Localized.Labels.faq_auto_reload_answer,
        }),
      },
      {
        question: Localized.Labels.faq_no_notifications,
        answer: Platform.select({
          web: Localized.Labels.faq_no_notifications_answer_web,
          default: Localized.Labels.formatString(
            Localized.Labels.faq_no_notifications_answer,
            Settings.appDisplayName,
          ),
        }),
      },
      {
        question: Localized.Labels.faq_add_order_ahead_location,
        answer: Localized.Labels.faq_add_order_ahead_location_answer,
      },
      {
        question: Localized.Labels.faq_order_ahead_checkout,
        answer: Localized.Labels.faq_order_ahead_checkout_answer,
      },
      {
        question: Localized.Labels.faq_remove_items,
        answer: Localized.Labels.faq_remove_items_answer,
      },
      {
        question: Localized.Labels.faq_payment,
        answer: Localized.Labels.faq_payment_answer,
      },
    ];

    if (UIManager.showAllFAQs()) {
      faqs.push(
        {
          question: Localized.Labels.faq_diff_balance,
          answer: Localized.Labels.faq_diff_balance_answer,
        },
        {
          question: Localized.Labels.faq_fingerprints,
          answer: Localized.Labels.faq_fingerprints_answer,
        },
        {
          question: Localized.Labels.faq_link_market_card,
          answer: Localized.Labels.formatString(
            Localized.Labels.faq_link_market_card_answer,
            Settings.appDisplayName,
          ),
        },
        {
          question: Localized.Labels.faq_product_issue,
          answer: Localized.Labels.faq_product_issue_answer,
        },
        {
          question: Localized.Labels.faq_reward_points,
          answer: Localized.Labels.faq_reward_points_answer,
        },
      );
    }

    if (Platform.OS !== 'web') {
      faqs.push(
        {
          question: Localized.Labels.faq_bad_scan,
          answer: Localized.Labels.formatString(
            Localized.Labels.faq_bad_scan_answer,
            Settings.appDisplayName,
          ),
        },
        {
          question: Localized.Labels.faq_no_locations,
          answer: Localized.Labels.faq_no_locations_answer,
        },
      );
    }

    const faqElements = faqs
      .sort((a, b) => a.question.localeCompare(b.question))
      .map((faq, key) => (
        <CollapsibleComponent
          headerAccessibilityLabel={faq.question}
          contentAccessibilityLabel={faq.answer}
          header={<AVText style={styles.question}>{faq.question}</AVText>}
          content={
            <AVText
              accessible={true}
              accessibilityLabel={faq.answer}
              style={styles.answer}
            >
              {faq.answer}
            </AVText>
          }
          key={key}
        />
      ));
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        title={UIManager.getHelpLabel()}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <ScrollView>
          <AVText
            style={styles.header}
            accessibilityRole="header"
            accessibilityLabel={Localized.Labels.faq}
          >
            {Localized.Labels.faq}
          </AVText>
          {faqElements}
          <AVText
            style={styles.header}
            accessibilityRole="header"
            accessibilityLabel={Localized.Labels.contact_us}
          >
            {Localized.Labels.contact_us}
          </AVText>
          <AVText
            style={styles.text}
            onPress={this.handleContactClick}
            accessibilityRole="link"
            accessibilityHint="Double tap to send a message from your email client"
            accessible={true}
          >
            {Localized.Labels.formatString(
              Localized.Labels.contact_us_answer,
              <AVText style={styles.link}>{Settings.supportEmail}</AVText>,
            )}
          </AVText>
        </ScrollView>
      </BackSubheader>
    );
  }
}

export default withForwardedNavigationParams<HelpProps>()(HelpScreen);

const styles = StyleSheet.create({
  answer: {
    fontSize: Styles.Fonts.f1,
    flex: 1,
    flexWrap: 'wrap',
    color: Styles.darkColor,
  },
  header: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    paddingStart: Styles.Spacing.m3,
  },
  question: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
  },
  text: {
    marginBottom: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m3,
    color: Styles.darkColor,
  },
  link: {
    color: Styles.primaryColor,
  },
});
