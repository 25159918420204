import _ from 'lodash';
import TSFInsideDevice from './bluetooth/tsfInside/TSFInsideDevice';
import VendingMachineConfig from 'src/models/VendingMachineConfig';
import PlanogramStore from 'src/stores/PlanogramStore';
import AccountStore from 'src/stores/AccountStore';
import {store} from '../redux/store';
import {Balance} from '../models/Balance';
export type VendItem = {
  desc: string;
  price: number;
  key: string;
};

class TSFInsideVendSession {
  device: TSFInsideDevice;
  machineConfig: VendingMachineConfig;
  maxPlanogramPrice: number;
  pendingItem: VendItem = {} as VendItem;
  items: Array<VendItem> = [];
  machineHighestPrice = 0;
  planogram: any;
  timeoutBetweenVends: number;

  async startSession(device: any, config: VendingMachineConfig) {
    this.device = device;
    this.machineConfig = config;
    this.timeoutBetweenVends = config.TimeoutBetweenVends;
    this.setMaxPlanogramPrice();
    this.clearPendingItem();
    this.items = [];
    this.machineHighestPrice = 0;
    await this.device.startTransaction(config.TransactionId);
  }

  startTestSession(device: TSFInsideDevice, transactionId: string) {
    this.device = device;
    this.device.setTransactionId(transactionId);
    this.timeoutBetweenVends = 30;
    this.maxPlanogramPrice = 0;
    this.clearPendingItem();
    this.items = [];
    this.machineHighestPrice = 0;
  }

  async setMaxPlanogramPrice() {
    this.planogram = await PlanogramStore.getPlanogramAsync(
      this.device.deviceId,
    );
    this.maxPlanogramPrice = 0;

    if (
      this.planogram &&
      this.planogram.Trays &&
      this.planogram.Trays.length > 0
    ) {
      const products = _.flatten(_.map(this.planogram.Trays, 'Products'));

      const maxPriceProduct = _.maxBy(products, 'Price');

      if (maxPriceProduct) {
        this.maxPlanogramPrice = maxPriceProduct.Price;
      }
    }
  }

  setMachineHighestPrice(price: number) {
    this.machineHighestPrice = price;
  }

  get Device(): TSFInsideDevice {
    return this.device;
  }

  get MachineConfig(): VendingMachineConfig {
    return this.machineConfig;
  }

  get TimeoutBetweenVends(): number {
    return this.timeoutBetweenVends;
  }

  get Items(): Array<VendItem> {
    if (AccountStore.isDemo()) {
      return [
        {
          price: 1.5,
          desc: 'Pepsi',
          key: 'key',
        },
      ];
    }

    return this.items;
  }

  get NumVends(): number {
    if (!this.items) {
      return 0;
    }

    return this.items.length;
  }

  getTotalAmountOfVendedItems = () => {
    if (AccountStore.isDemo()) {
      return 1.5;
    }

    if (this.items.length > 0) {
      let total = 0;
      this.items.forEach((item) => {
        total += item.price;
      });
      return total;
    }

    return 0;
  };

  getLastItemVended() {
    if (AccountStore.isDemo()) {
      return {
        price: 1.5,
        desc: 'Pepsi',
      };
    }

    if (this.items.length > 0) {
      return this.items[this.items.length - 1];
    }

    return null;
  }

  isVendPending(): boolean {
    return this.pendingItem && !!this.pendingItem.key;
  }

  getPlanogramItem(itemKey: string) {
    if (
      this.planogram &&
      this.planogram.Trays &&
      this.planogram.Trays.length > 0
    ) {
      for (const tray of this.planogram.Trays) {
        for (const product of tray.Products) {
          if (product.MDBCode === itemKey) {
            return {
              Price: product.Price,
              Name: product.Product.Name,
            };
          }
        }
      }
    } else {
      return null;
    }
  }

  sendTestCredit() {
    this.device.sendCredit(5, 0, 60, true);
  }

  vendSucceeded() {
    if (this.pendingItem && this.pendingItem.key) {
      const item = this.getPlanogramItem(this.pendingItem.key);
      let itemName = 'Item';

      if (item) {
        itemName = item.Name;
      }

      const pendingItem = {
        desc: itemName,
        price: this.pendingItem.price,
        key: this.pendingItem.key,
      };
      this.items.push(pendingItem);
      this.clearPendingItem();
      AccountStore.adjustBalance(pendingItem.price * -1, 'VEND_SUCCEEDED');
    }
  }

  clearPendingItem() {
    this.pendingItem.price = 0;
    this.pendingItem.key = '';
    this.pendingItem.desc = '';
  }

  vendApproved(key: string, price: number, discountAmount: number) {
    this.pendingItem.key = key;
    this.pendingItem.price = price;
  }

  vendFailed() {
    this.clearPendingItem();
  }

  getCreditAmount() {
    if (this.NumVends >= this.machineConfig.VendsPerTransaction) {
      return 0;
    }

    let creditAmount = Math.max(
      this.maxPlanogramPrice,
      this.machineHighestPrice,
    );

    if (creditAmount === 0) {
      creditAmount = this.machineConfig.AuthorizationAmount;
    } else {
      creditAmount = Math.min(
        creditAmount,
        this.machineConfig.AuthorizationAmount,
      );
    }

    const balances: Array<Balance> = store.getState().account.account.balances;
    const accountBalance = balances.reduce((sum, item) => {
      if (!item.isDisabled) {
        return sum + item.balance;
      }
      return sum;
    }, 0);

    if (creditAmount === 0) {
      creditAmount = accountBalance;
    } else {
      creditAmount = Math.min(creditAmount, accountBalance);
    }

    return creditAmount;
  }

  getDiscountAmount() {
    return this.machineConfig.DiscountAmount;
  }
}

export default new TSFInsideVendSession();
