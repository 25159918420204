import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import Util from 'src/Util';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import GiftBox from '../../img/svg/GiftBox';
import StickerComponent from '../../elements/sendSnack/StickerComponent';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import Settings from 'src/Settings';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import {confirm} from '../../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {
  acceptSnack,
  AcceptSnackParams,
  blockUser,
  BlockUserParams,
  cancelSnack,
  CancelSnackParams,
  loadSnackDetail,
  LoadSnackDetailParams,
} from 'src/redux/thunks/snackThunks';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type SnackDetailScreenProps = WithGlobalizeProps & {
  snackId: string;
  snackLocationId: string;
  snackAction?: keyof typeof SnackActions;
  loadSnackDetail(params: LoadSnackDetailParams): void;
  acceptSnack(params: AcceptSnackParams): void;
  blockUser(params: BlockUserParams): void;
  cancelSnack(params: CancelSnackParams): void;
  snackDetails: GiftItemType;
  loading: boolean;
};

const SnackStatus = {
  Sent: 'S',
  Cancelled: 'C',
  Accepted: 'A',
  Expired: 'E',
};

class SnackDetailScreen extends React.Component<SnackDetailScreenProps> {
  static contextType = ScreenContext;

  constructor(props: SnackDetailScreenProps) {
    super(props);
    this.onBlockUser = this.onBlockUser.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderTopHeader = this.renderTopHeader.bind(this);
    this.renderSnackMessage = this.renderSnackMessage.bind(this);
    this.renderSnackStatusForSender =
      this.renderSnackStatusForSender.bind(this);
    this.renderSnackStatusForReceiver =
      this.renderSnackStatusForReceiver.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'SnackDetailScreen', {
      ...this.props,
      ...this.state,
    });
    this.props.loadSnackDetail({
      snackAction: this.props.snackAction,
      snackId: this.props.snackId,
      locationId: this.props.snackLocationId,
    });
  }

  onBlockUser() {
    FirebaseAnalytic.trackEvent('onBlockUser', 'SnackDetailScreen', {
      ...this.props,
      ...this.state,
    });
    confirm(
      `${Localized.Labels.blocking_user_will_prevent_from_accept_snack}\n\n${Localized.Labels.user_can_be_unblocked_from_settings}`,
      undefined,
      () =>
        this.props.blockUser({
          snackDetails: this.props.snackDetails,
        }),
      Localized.Labels.are_you_sure_to_block_user,
      Localized.Labels.yes_block,
      Localized.Buttons.cancel,
    );
  }

  formatCurrency(value: number) {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  }

  renderEmpty() {
    return <BackSubheader title="" />;
  }

  renderTopHeader() {
    const snackDate = Util.formatDate(
      this.props.snackDetails.snackSent,
      'YYYY-MM-DD hh:mm A',
    );
    return (
      <View style={styles.topRow}>
        <GiftBox width={Styles.Heights.h3} height={Styles.Heights.h3} />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
          style={styles.appTitleStyle}
        >
          {Settings.appDisplayName}
        </AVText>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={styles.dateStyle}
        >
          {snackDate}
        </AVText>
      </View>
    );
  }

  renderSnackMessage(isSender) {
    const {email, senderName, description} = this.props.snackDetails;
    return (
      <View>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={styles.description}
        >
          {isSender
            ? Localized.Labels.formatString(
                Localized.Labels.we_have_sent_your_snack_details,
                email,
              )
            : Localized.Labels.formatString(
                Localized.Labels.sent_you_snack,
                senderName,
              )}
        </AVText>
        {!isSender && <AVText style={styles.message}>{description}</AVText>}
      </View>
    );
  }

  getSnackStatusMessageForSender = () => {
    const {status} = this.props.snackDetails;

    if (status === SnackStatus.Sent) {
      return Localized.Labels.cancel_snack_button_description_message;
    } else if (status === SnackStatus.Accepted) {
      return Localized.Labels.snack_has_been_accepted;
    } else if (status === SnackStatus.Cancelled) {
      return Localized.Labels.snack_has_been_cancelled;
    } else if (status === SnackStatus.Expired) {
      return Localized.Labels.snack_has_expired;
    }
  };

  renderSnackStatusForSender() {
    return (
      <View style={styles.senderSnackStatusContainer}>
        <AVText style={styles.senderSnackStatusText}>
          {this.getSnackStatusMessageForSender()}
        </AVText>
        {this.props.snackDetails.status === SnackStatus.Sent && (
          <RoundedButton
            buttonType={ButtonType.outline}
            text={Localized.Buttons.cancel_snack}
            containerStyle={styles.cancelButton}
            color={Styles.dangerColor}
            onPress={() =>
              this.props.cancelSnack({
                snackDetails: this.props.snackDetails,
              })
            }
            accessibilityLabel="Cancel Snack"
          />
        )}
      </View>
    );
  }

  renderSnackStatusForReceiver() {
    const {stickerId, amount, isBlocked} = this.props.snackDetails;
    return (
      <View style={styles.receiverSnackStatusContainer}>
        <StickerComponent id={stickerId} imageSize={Styles.Spacing.m7} />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={styles.amount}
          accessibilityLabel="Snack Amount"
        >
          {this.formatCurrency(amount)}
        </AVText>
        <AVText
          style={styles.receiverSnackStatusText}
          accessibilityLabel="Snack Status Message"
        >
          {this.getSnackStatusMessageFoReceiver()}
        </AVText>
        {isBlocked ? (
          <RoundedButton
            buttonType={ButtonType.normal}
            text={Localized.Labels.blocked}
            containerStyle={styles.blockButton}
            color={Styles.dangerColor}
            accessibilityLabel="Blocked"
          />
        ) : (
          <RoundedButton
            buttonType={ButtonType.outline}
            text={Localized.Buttons.block_user}
            containerStyle={styles.blockButton}
            color={Styles.dangerColor}
            onPress={this.onBlockUser}
            accessibilityLabel="Block Snack User"
          />
        )}
      </View>
    );
  }

  getSnackStatusMessageFoReceiver = () => {
    const {status, isBlocked} = this.props.snackDetails;

    if (status === SnackStatus.Sent && !isBlocked) {
      return Localized.Labels.accept_snack_to_add_to_your_account;
    } else if (status === SnackStatus.Accepted) {
      return Localized.Labels.has_been_added_to_your_account;
    } else if (status === SnackStatus.Cancelled) {
      return Localized.Labels.gift_has_been_cancelled;
    } else if (status === SnackStatus.Expired) {
      return Localized.Labels.gift_has_expired;
    }

    return '';
  };

  render() {
    const {status, isBlocked, senderName, senderAccountId} =
      this.props.snackDetails || {};

    if (this.props.loading || !senderName || !senderAccountId) {
      return this.renderEmpty();
    }

    const isSender = senderAccountId === AccountStore.getAccountId();
    return (
      <BackSubheader
        title={
          isSender
            ? Localized.Labels.your_snack_sent
            : Localized.Labels.formatString(
                Localized.Labels.sent_you_snack,
                senderName,
              )
        }
      >
        <ScrollView style={Styles.Style.flex}>
          <View style={styles.content}>
            {this.renderTopHeader()}
            {this.renderSnackMessage(isSender)}
            {isSender
              ? this.renderSnackStatusForSender()
              : this.renderSnackStatusForReceiver()}
            <View style={styles.bottom} />
          </View>
        </ScrollView>
        {!isSender && status === SnackStatus.Sent && !isBlocked && (
          <RoundedButton
            accessibilityLabel="Accept Snack"
            buttonType={ButtonType.action}
            text={Localized.Buttons.accept_snack}
            onPress={() =>
              this.props.acceptSnack({
                snackDetails: this.props.snackDetails,
              })
            }
          />
        )}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
    flex: 1,
  },
  topRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m3,
  },
  appTitleStyle: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    marginLeft: Styles.Spacing.m3,
    flex: 1,
  },
  dateStyle: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginRight: Styles.Spacing.m2,
  },
  description: {
    color: Styles.black,
    flex: 1,
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f1,
  },
  message: {
    color: Styles.black,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
  senderSnackStatusContainer: {
    marginTop: Styles.Spacing.m3,
  },
  senderSnackStatusText: {
    color: Styles.black,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
  receiverSnackStatusText: {
    color: Styles.black,
    flex: 1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
  cancelButton: {
    alignSelf: 'flex-start',
    marginTop: Styles.Spacing.m2,
  },
  receiverSnackStatusContainer: {
    marginTop: Styles.Spacing.m3,
    alignItems: 'center',
  },
  sticker: {
    marginTop: Styles.Spacing.m3,
  },
  amount: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
  },
  blockButton: {
    alignSelf: 'center',
    marginTop: Styles.Spacing.m3,
  },
  bottom: {
    height: Styles.Spacing.m5,
    width: '100%',
  },
});

const mapStateToProps = (state: RootState) => ({
  loading: state.snack.loading,
  snackDetails: state.snack.snackDetails,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadSnackDetail: (params) => dispatch(loadSnackDetail(params)),
  blockUser: (params) => dispatch(blockUser(params)),
  acceptSnack: (params) => dispatch(acceptSnack(params)),
  cancelSnack: (params) => dispatch(cancelSnack(params)),
});
export default compose(
  withForwardedNavigationParams<SnackDetailScreenProps>(),
  connect(mapStateToProps, mapDispatchToProps),
)(SnackDetailScreen);
