import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import AVTouchableOpacity from './AVTouchableOpacity';
type DeleteSwipeableRowType = 'delete' | 'remove';
type DeleteSwipeableRowProps = {
  onDeletePressed: () => void;
  type: DeleteSwipeableRowType;
  children: React.ReactNode;
};
export default class DeleteSwipeableRow extends React.Component<DeleteSwipeableRowProps> {
  getIcon = () => {
    switch (this.props.type) {
      case 'delete':
        return (
          <FontAwesome5Pro
            name="trash-alt"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        );

      case 'remove':
        return (
          <FontAwesome5Pro
            name="times-circle"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        );
    }
  };
  close = () => ({});

  render() {
    const {children} = this.props;
    return (
      <View style={styles.container}>
        <AVTouchableOpacity
          onPress={this.props.onDeletePressed}
          style={styles.delete}
        >
          {this.getIcon()}
        </AVTouchableOpacity>
        {children}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  delete: {
    alignItems: 'center',
    paddingStart: Styles.Spacing.m3,
    justifyContent: 'center',
  },
});
