const Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/,
  phone: /^[+]?[0-9]{10}$/,
};
export function isValidEmail(email: string) {
  return Regex.email.test(email);
}
export function isValidPhoneNumber(number: string) {
  return Regex.phone.test(number);
}
