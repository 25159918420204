import CoffeeMachineTypes from 'src/constants/CoffeeMachineTypes';
import LockerTypes from 'src/constants/LockerTypes';

export type Sfecfg = {
  id: string;
  name: string;
  type: string;
  value: string;
  valuetype: number;
  isdisabled: string;
};
type ParsedSfecfg = Record<string, string>;
export default class MachineFeatures {
  readonly HasScanAndPay: boolean = true;
  readonly CoffeeMachine: string = 'None';
  readonly HasBunnCoffeeMachine: boolean = false;
  readonly IsInHiatusMode: boolean = false;
  readonly HasSendSnack: boolean = false;
  readonly HasOrderNote: boolean = false;
  LockerType: number = LockerTypes.None;

  constructor(features?: Sfecfg[]) {
    const sfeConfig = this.parseSfeCfgs(features);

    if (!sfeConfig) {
      return;
    }

    this.HasScanAndPay = (sfeConfig.SCANANDPAY || 'Y').toUpperCase() === 'Y';
    this.CoffeeMachine = sfeConfig.COFFEEMACHINE || 'None';
    this.HasBunnCoffeeMachine =
      this.CoffeeMachine.toUpperCase() === CoffeeMachineTypes.Bunn.type;
    this.IsInHiatusMode = (sfeConfig.HIATUSMODEON || 'N').toUpperCase() === 'Y';
    this.HasSendSnack = (sfeConfig.HASSENDSNACK || 'N').toUpperCase() === 'Y';
    this.HasOrderNote = (sfeConfig.HASORDERNOTE || 'N').toUpperCase() === 'Y';
    this.LockerType = Number(sfeConfig.HASLOCKER) || LockerTypes.None;
  }

  parseSfeCfgs(sfeList: Sfecfg[] | null | undefined) {
    const sfecfgs: ParsedSfecfg = {};

    if (!sfeList) {
      return sfecfgs;
    }

    sfeList.forEach((cfg) => (sfecfgs[cfg.type] = cfg.value));
    return sfecfgs;
  }
}
