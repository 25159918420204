import {UrlTypes} from '../types/UrlTypes';

export const enum EnvironmentKey {
  DEV = 'DEV',
  STAGING = 'STAGING',
  QA = 'QA',
  PROD = 'PROD',
}

export type EnvironmentsType = {
  [key in EnvironmentKey]: {[keyUrl in UrlTypes]?: string};
};
