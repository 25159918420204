import moment from 'moment';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import AVTouchableOpacity from '../AVTouchableOpacity';
import AccountStore from 'src/stores/AccountStore';
import AccountConstants from 'src/constants/AccountConstants';
import AVText from '../AVText';

type HistoryItemProps = {
  onPress: (item: HistoryItemType) => void;
  historyItem: HistoryItemType;
  index: number;
};

export const Reason = {
  Referral: 'Referral',
  SignUp: 'Referral-Signup',
};

const HistoryItem = (props: HistoryItemProps) => {
  const {formatCurrency} = useGlobalize();
  const {
    Type,
    TransDate,
    currency,
    Points,
    TransactionTypeDisplay,
    BalanceTypeDisplay,
  } = props.historyItem;

  let {Amount} = props.historyItem;

  if (Type === AccountConstants.SALE_TYPE) {
    Amount *= -1;
  }

  const isClickable =
    Type === AccountConstants.REFERRAL_TYPE ||
    Type === AccountConstants.REFERRAL_SIGNUP_TYPE ||
    Type === AccountConstants.SALE_TYPE ||
    Type === AccountConstants.FUNDING_TYPE ||
    Type === AccountConstants.REFUND_TYPE ||
    Type === AccountConstants.REDEMPTION_TYPE;

  const date = moment(TransDate).calendar(null, {
    sameDay: `[${Localized.Labels.today}]`,
    lastDay: `[${Localized.Labels.yesterday}]`,
    lastWeek: 'dddd',
    sameElse: 'MMM D',
  });

  const formattedCurrency = formatCurrency(Amount, AccountStore.getCurrency(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <AVTouchableOpacity
      accessible={false}
      style={styles.row}
      disabled={!isClickable}
      onPress={() => props.onPress(props.historyItem)}
    >
      <View style={styles.messageContainer}>
        <View style={styles.amountContainer}>
          <View style={Styles.Style.row}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
              numberOfLines={1}
              style={styles.item}
              accessible={true}
              accessibilityLabel={`${
                TransactionTypeDisplay ?? Localized.Labels.purchase
              }, `}
              aria-label={`${
                TransactionTypeDisplay ?? Localized.Labels.purchase
              }, `}
            >
              {TransactionTypeDisplay ?? Localized.Labels.purchase}
            </AVText>
          </View>
          <View style={Styles.Style.row}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              style={styles.balanceType}
              accessible={true}
              accessibilityLabel={`${
                BalanceTypeDisplay ?? Localized.Labels.my_account
              }, `}
              aria-label={`${
                BalanceTypeDisplay ?? Localized.Labels.my_account
              }, `}
            >
              {BalanceTypeDisplay ?? Localized.Labels.my_account}
            </AVText>
          </View>
          <View style={Styles.Style.row}>
            <AVText
              accessible={true}
              accessibilityLabel={`${Points} ${Localized.Labels.points_earned}, text`}
              aria-label={`${Points} ${Localized.Labels.points_earned}, text`}
              style={styles.points}
            >
              {Points ? `${Points} ${Localized.Labels.points_earned}` : null}
            </AVText>
          </View>
        </View>
        <View style={styles.amountView}>
          <AVFormattedCurrency
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            value={Amount}
            style={styles.amount}
            accessible={true}
            accessibilityLabel={`${formattedCurrency}, `}
            aria-label={`${formattedCurrency}, `}
            currency={currency}
          />
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            style={styles.date}
            accessible={true}
            accessibilityLabel={`${date}, `}
            aria-label={`${date}, `}
          >
            {date}
          </AVText>
        </View>
        {isClickable && (
          <View style={styles.arrow}>
            <FontAwesome5Pro
              accessible={true}
              accessibilityLabel={`Right arrow icon, Double tap to ${Localized.Labels.view_specfic_transaction}`}
              aria-label={`Right arrow icon, Double tap to ${Localized.Labels.view_specfic_transaction}`}
              name="chevron-right"
              color={Styles.lightGray}
              size={Styles.Fonts.f2}
              light
            />
          </View>
        )}
      </View>
    </AVTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  amount: {
    alignSelf: 'flex-end',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '300',
    textAlign: 'right',
    paddingRight: Styles.Spacing.m1,
  },
  amountContainer: {
    flex: 1,
    paddingRight: Styles.Spacing.m3,
  },
  amountView: {
    alignItems: 'center',

    justifyContent: 'center',
  },
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Styles.Fonts.f1,
    marginLeft: Styles.Spacing.m1 - 2,
  },
  balance: {
    color: Styles.lightGray,
    flex: 1.5,
    fontSize: Styles.Fonts.f5,
    fontWeight: '300',
    textAlign: 'right',
  },
  date: {
    alignSelf: 'flex-start',
    color: Styles.darkColor,
    flex: 0.5,
    fontSize: Styles.Fonts.f1,
    fontWeight: '300',
  },
  balanceType: {
    alignSelf: 'flex-start',
    color: Styles.black,
    flex: 0.5,
    fontSize: Styles.Fonts.f1,
    fontWeight: '300',
    paddingVertical: Styles.Spacing.m1 - 2,
  },
  id: {
    alignSelf: 'flex-start',
    color: Styles.darkColor,
    flex: 0.75,
    fontSize: Styles.Fonts.f0,
    fontWeight: '300',
  },
  item: {
    alignSelf: 'flex-start',
    color: Styles.darkColor,
    flex: 2,
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  messageContainer: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    width: '100%',
    padding: Styles.Spacing.m3,
  },
  row: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  points: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
  },
});

export default HistoryItem;
