import * as React from 'react';
import {
  Animated,
  Platform,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Collapsible from 'react-native-collapsible';
import Styles from '../Styles';
type CollapsibleComponentProps = {
  header: React.ReactNode;
  content: React.ReactNode;
  headerAccessibilityLabel?: string;
  contentAccessibilityLabel?: string;
};
export default function CollapsibleComponent(props: CollapsibleComponentProps) {
  const [collapsed, setCollapsed] = React.useState(true);
  const [chevronSpin] = React.useState(new Animated.Value(0));

  function toggleExpanded() {
    const c = !collapsed;
    setCollapsed(c);
    Animated.timing(chevronSpin, {
      toValue: c ? 0 : 90,
      duration: 300,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }

  const rotate = chevronSpin.interpolate({
    inputRange: [0, 90],
    outputRange: ['0deg', '90deg'],
  });
  return (
    <View style={styles.content}>
      <TouchableOpacity
        style={{flex: 1, flexDirection: 'row'}}
        accessible={true}
        accessibilityHint="Double tap to expand or collapse the item"
        accessibilityState={{expanded: !collapsed}}
        accessibilityRole="togglebutton"
        accessibilityLabel={props.headerAccessibilityLabel}
        onPress={toggleExpanded}
      >
        <Animated.View
          style={[
            styles.chevron,
            {
              transform: [
                {
                  rotate,
                },
              ],
            },
          ]}
        >
          <FontAwesome5Pro
            name={'chevron-right'}
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        </Animated.View>
        {props.header}
      </TouchableOpacity>

      <View
        accessible={true}
        accessibilityRole="text"
        accessibilityLabel={props.contentAccessibilityLabel}
      >
        <Collapsible collapsed={collapsed} style={styles.collapsible}>
          {props.content}
        </Collapsible>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  chevron: {
    alignSelf: 'baseline',
    marginEnd: Styles.Spacing.m3,
  },
  collapsible: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: Styles.Spacing.m1,
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m2,
  },
});
