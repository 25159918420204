// https://github.com/Andr3wHur5t/react-native-keyboard-spacer/blob/master/KeyboardSpacer.js
import React from 'react';
import {
  Keyboard,
  LayoutAnimation,
  View,
  Platform,
  StyleSheet,
  LayoutAnimationConfig,
} from 'react-native';
const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
  },
});
type KeyboardSpacerProps = {
  topSpacing?: number;
  onToggle?: (value: boolean, keyboardSpace: number) => void;
  style?: any;
  animationConfig: LayoutAnimationConfig;
};
type KeyboardSpacerState = {
  isKeyboardOpened: boolean;
  keyboardSpace: number;
};
export default class KeyboardSpacer extends React.Component<
  KeyboardSpacerProps,
  KeyboardSpacerState
> {
  static defaultProps = {
    topSpacing: 0,
    // From: https://medium.com/man-moon/writing-modern-react-native-ui-e317ff956f02
    animationConfig: {
      duration: 500,
      create: {
        duration: 300,
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.spring,
        springDamping: 200,
      },
    },
    onToggle: () => ({}),
    style: null,
  };
  _listeners: Array<any> | null;

  constructor(props: KeyboardSpacerProps) {
    super(props);
    this.state = {
      keyboardSpace: 0,
      isKeyboardOpened: false,
    };
    this._listeners = null;
    this.updateKeyboardSpace = this.updateKeyboardSpace.bind(this);
    this.resetKeyboardSpace = this.resetKeyboardSpace.bind(this);
  }

  componentDidMount() {
    const updateListener =
      Platform.OS === 'android' ? 'keyboardDidShow' : 'keyboardWillShow';
    const resetListener =
      Platform.OS === 'android' ? 'keyboardDidHide' : 'keyboardWillHide';
    this._listeners = [
      Keyboard.addListener(updateListener, this.updateKeyboardSpace),
      Keyboard.addListener(resetListener, this.resetKeyboardSpace),
    ];
  }

  componentDidUpdate(
    _prevProps: KeyboardSpacerProps,
    state: KeyboardSpacerState,
  ) {
    if (state.isKeyboardOpened !== this.state.isKeyboardOpened) {
      LayoutAnimation.configureNext(this.props.animationConfig);
    }
  }

  componentWillUnmount() {
    if (this._listeners) {
      this._listeners.forEach((listener) => listener.remove());
    }
  }

  updateKeyboardSpace(frames: any) {
    if (!frames.endCoordinates) {
      return;
    }

    const keyboardSpace = frames.endCoordinates.height + this.props.topSpacing;
    this.setState(
      {
        keyboardSpace,
        isKeyboardOpened: true,
      },
      () => this.props.onToggle && this.props.onToggle(true, keyboardSpace),
    );
  }

  resetKeyboardSpace() {
    this.setState(
      {
        keyboardSpace: 0,
        isKeyboardOpened: false,
      },
      () => this.props.onToggle && this.props.onToggle(false, 0),
    );
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          {
            height: this.state.keyboardSpace,
          },
          this.props.style,
        ]}
      />
    );
  }
}
