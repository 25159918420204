import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import Svg, {Defs, Path, Pattern, Rect} from 'react-native-svg';
type CanteenPromotionsHeaderBackgroundProps = {
  style: StyleProp<ViewStyle>;
  aspectRatio: number;
};
export default class CanteenPromotionsHeaderBackground extends React.Component<CanteenPromotionsHeaderBackgroundProps> {
  render() {
    const viewBoxHeight = this.props.aspectRatio * 792;
    return (
      <View
        style={this.props.style}
        accessible={true}
        accessibilityLabel="Canteen Connect & Pay logo"
        aria-label="Canteen Connect & Pay logo"
        accessibilityRole="image"
        role="img"
      >
        <Svg viewBox={`0 0 ${viewBoxHeight} 792`} width="100%" height="100%">
          <Defs>
            <Pattern
              id="canteenPattern"
              x={0}
              y={0}
              width="480.29"
              height="363.85"
              patternUnits="userSpaceOnUse"
              viewBox="70.07 -366.07 480.29 363.85"
            >
              <Path
                fill="#A5CF4F"
                d="M528.94-124.94c-2.94,1.54-5.79,3.31-8.5,5.26L539-97.05c1.04-0.73,2.12-1.4,3.24-2.02L528.94-124.94
                 M529.56-126.87l14.6,28.4c-1.93,0.96-3.73,2.08-5.41,3.36l-20.36-24.84C521.88-122.58,525.62-124.9,529.56-126.87
                L529.56-126.87z"
              />
              <Path
                fill="#A5CF4F"
                d="M546.43-131.25c-3.27,0.69-6.51,1.63-9.66,2.8l11.18,26.87c1.42-0.48,2.88-0.87,4.36-1.18L546.43-131.25
                 M547.55-132.93l6.47,31.31c-2.4,0.4-4.69,1.02-6.86,1.84l-12.27-29.48C538.95-130.86,543.18-132.11,547.55-132.93
                L547.55-132.93z"
              />
              <Path
                fill="#A5CF4F"
                d="M513.88-114.31c-14.33,13.3-22.04,31.55-21.19,50.29c0.83,18.42,7.72,34.15,19.92,45.48
                c12.05,11.19,28.16,17.11,46.57,17.11c1.28,0,2.58-0.03,3.87-0.09C580-2.29,596.11-9.87,607.5-22.39l-21.64-19.22
                c-6.38,6.43-15.49,10.61-24.12,11c-0.59,0.03-1.18,0.04-1.76,0.04c-19.47,0-34.83-14.99-35.73-34.87
                c-0.49-10.85,3.11-20.64,10.17-27.74L513.88-114.31 M513.95-116.29l22.5,23.14c-7.14,6.61-11.27,16.37-10.76,27.65
                c0.87,19.09,15.46,33.51,34.31,33.51c0.56,0,1.12-0.01,1.69-0.04c9.22-0.42,18.24-5.14,24.11-11.56l23.74,21.09
                c-11.7,13.46-28.58,21.61-46.4,22.42C561.81-0.03,560.49,0,559.19,0c-37.97,0-66.17-25.33-67.92-63.95
                C490.34-84.45,499.39-103.3,513.95-116.29L513.95-116.29z"
              />
              <Path
                fill="#A5CF4F"
                d="M80.73-132.71c-1.29,0-2.6,0.03-3.89,0.09c-0.73,0.03-1.48,0.08-2.26,0.15l3.62,28.95
                c0.57-0.03,1.14-0.04,1.71-0.04c0.93,0,1.87,0.03,2.81,0.1l1.69-29.17C83.18-132.68,81.95-132.71,80.73-132.71 M80.73-134.14
                c1.76,0,3.49,0.05,5.19,0.16l-1.86,32.08c-1.36-0.15-2.74-0.23-4.15-0.23c-0.56,0-1.12,0.01-1.69,0.04
                c-0.43,0.02-0.85,0.06-1.27,0.09l-3.97-31.75c1.26-0.13,2.52-0.23,3.79-0.29C78.1-134.11,79.42-134.14,80.73-134.14
                L80.73-134.14z"
              />
              <Path
                fill="#A5CF4F"
                d="M92.91-131.78l-4.1,29.23c1.42,0.34,2.82,0.75,4.2,1.25l10.22-27.87
                C99.95-130.32,96.49-131.19,92.91-131.78 M91.69-133.41c4.65,0.66,9.1,1.77,13.37,3.39L93.85-99.43
                c-2.13-0.87-4.34-1.54-6.64-1.99L91.69-133.41L91.69-133.41z"
              />
              <Path
                fill="#A5CF4F"
                d="M110.88-125.84L98.5-98.79c1.81,1,3.55,2.15,5.2,3.43l19.83-21.71
                C119.48-120.63,115.32-123.51,110.88-125.84 M110.2-127.78c5.35,2.64,10.45,6.13,15.36,10.61l-21.7,23.77
                c-2.24-1.86-4.65-3.45-7.22-4.76L110.2-127.78L110.2-127.78z"
              />
              <Path
                fill="#A5CF4F"
                d="M66.14-131.25c-3.27,0.69-6.51,1.63-9.66,2.8l11.18,26.87c1.42-0.48,2.88-0.87,4.36-1.18L66.14-131.25
                 M67.26-132.93l6.47,31.31c-2.4,0.4-4.69,1.02-6.86,1.84L54.6-129.25C58.66-130.86,62.89-132.11,67.26-132.93L67.26-132.93z"
              />
              <Path
                fill="#A5CF4F"
                d="M33.59-114.31C19.26-101,11.56-82.75,12.41-64.02c0.83,18.42,7.72,34.15,19.92,45.48
                C44.38-7.35,60.48-1.43,78.9-1.43c1.28,0,2.58-0.03,3.87-0.09c16.94-0.77,33.05-8.36,44.44-20.88l-21.64-19.22
                c-6.38,6.43-15.49,10.61-24.12,11c-0.59,0.03-1.18,0.04-1.76,0.04c-19.47,0-34.83-14.99-35.73-34.87
                c-0.49-10.85,3.11-20.64,10.17-27.74L33.59-114.31 M33.66-116.29l22.5,23.14c-7.14,6.61-11.27,16.37-10.76,27.65
                c0.87,19.09,15.46,33.51,34.31,33.51c0.56,0,1.12-0.01,1.69-0.04c9.22-0.42,18.24-5.14,24.11-11.56l23.74,21.09
                C117.53-9.05,100.65-0.9,82.84-0.09C81.52-0.03,80.2,0,78.9,0C40.93,0,12.73-25.33,10.98-63.95
                C10.05-84.45,19.1-103.3,33.66-116.29L33.66-116.29z"
              />
              <Path
                fill="#A5CF4F"
                d="M519.86-247.27v12.67h-4.61l-0.76-1.87c-1.25,1.56-2.93,2.33-5.06,2.33c-2.76,0-4.94-0.7-6.52-2.1
              c-1.58-1.4-2.38-3.08-2.38-5.02c0-1.22,0.29-2.32,0.86-3.29c0.57-0.97,1.33-1.74,2.29-2.31c0.96-0.57,2.02-1.01,3.19-1.31
              c1.17-0.31,2.39-0.44,3.64-0.4c1.26,0.04,2.46,0.24,3.6,0.6c0-1.25-0.4-2.17-1.2-2.75c-0.8-0.58-1.8-0.87-2.98-0.87
              c-1.88,0-3.81,0.54-5.8,1.62l-2.07-3.5c2.47-1.92,5.27-2.87,8.41-2.87c2.63,0,4.85,0.79,6.67,2.35
              C518.95-252.43,519.86-250.19,519.86-247.27z M514.15-243.65c-0.94-0.42-2.01-0.65-3.21-0.71c-1.2-0.06-2.28,0.15-3.23,0.6
              c-0.95,0.46-1.44,1.16-1.47,2.1c-0.03,0.78,0.35,1.44,1.12,2c0.77,0.56,1.81,0.83,3.11,0.83c1.24,0,2.47-0.5,3.69-1.5V-243.65z
               M537.07-254.96c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19c-1.71,0.28-3.37,0.71-4.99,1.29v20.04h5.71v-16.17
              c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35c0.82,0.28,1.49,0.79,2.01,1.54c0.52,0.75,0.77,1.68,0.77,2.79
              v12.08h5.71v-12.12c-0.03-2.06-0.48-3.78-1.36-5.19C539.58-253.32,538.45-254.34,537.07-254.96z M550.56-263.85h-5.71v8.12h-2.17
              v4.58h2.17v9.75c-0.06,1.78,0.37,3.24,1.27,4.4c0.9,1.15,2.06,1.9,3.46,2.25c1.4,0.35,2.94,0.4,4.6,0.15v-4.83
              c-1.12,0.14-2,0.01-2.65-0.4c-0.65-0.4-0.98-0.97-0.98-1.69v-9.62h3.62v-4.58h-3.62V-263.85z M573.29-253.21
              c1.96,2.01,2.94,4.52,2.94,7.52c0,1.03-0.08,1.97-0.25,2.83h-15.25c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69
              c1.5-0.21,2.82-0.81,3.96-1.81l3.21,2.83c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29
              c-1.92-2.19-2.86-4.78-2.83-7.75c0-2.94,0.96-5.5,2.88-7.67c1.92-2.17,4.54-3.26,7.87-3.29
              C568.83-256.22,571.33-255.23,573.29-253.21z M570.65-247.23c-0.22-1.21-0.79-2.11-1.69-2.7c-0.9-0.59-1.95-0.89-3.15-0.89
              c-1.22,0-2.32,0.3-3.29,0.9c-0.97,0.6-1.57,1.5-1.79,2.68H570.65z M596.16-253.21c1.96,2.01,2.94,4.52,2.94,7.52
              c0,1.03-0.08,1.97-0.25,2.83H583.6c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69c1.5-0.21,2.82-0.81,3.96-1.81
              l3.21,2.83c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29c-1.92-2.19-2.86-4.78-2.83-7.75
              c0-2.94,0.96-5.5,2.88-7.67c1.92-2.17,4.54-3.26,7.87-3.29C591.7-256.22,594.21-255.23,596.16-253.21z M593.52-247.23
              c-0.22-1.21-0.79-2.11-1.69-2.7c-0.9-0.59-1.95-0.89-3.15-0.89c-1.22,0-2.32,0.3-3.29,0.9c-0.97,0.6-1.57,1.5-1.79,2.68H593.52z
               M619.08-251.92c-0.88-1.4-2-2.42-3.38-3.04c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19
              c-1.71,0.28-3.37,0.71-4.99,1.29v20.04h5.71v-16.17c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35
              c0.82,0.28,1.49,0.79,2.01,1.54c0.52,0.75,0.77,1.68,0.77,2.79v12.08h5.71v-12.12C620.41-248.79,619.95-250.52,619.08-251.92z
               M491.38-250.96c0.33-0.01,0.65,0,0.96,0.03l0.31-5.29c-0.49-0.03-0.99-0.03-1.51-0.01c-0.21,0.01-0.42,0.03-0.62,0.05l0.65,5.23
              C491.24-250.95,491.31-250.96,491.38-250.96z M495.8-255.56c-0.7-0.27-1.44-0.45-2.2-0.56l-0.74,5.27
              c0.38,0.07,0.74,0.19,1.09,0.33L495.8-255.56z M495.6-249.53l3.58-3.92c-0.81-0.74-1.65-1.31-2.53-1.75l-2.23,4.88
              C494.84-250.1,495.23-249.84,495.6-249.53z M489.02-250.36l-2.4-4.68c-0.65,0.32-1.27,0.71-1.84,1.14l3.36,4.09
              C488.4-250.02,488.7-250.21,489.02-250.36z M490.64-250.88l-1.07-5.16c-0.72,0.14-1.42,0.34-2.09,0.61l2.02,4.86
              C489.87-250.72,490.24-250.82,490.64-250.88z M491.9-239.42c-3.24,0.15-5.78-2.27-5.93-5.51c-0.08-1.86,0.6-3.47,1.77-4.56
              l-3.71-3.81c-2.4,2.14-3.89,5.25-3.74,8.62c0.3,6.58,5.26,10.82,11.84,10.52c2.94-0.13,5.72-1.48,7.64-3.69l-3.91-3.48
              C494.91-240.27,493.42-239.49,491.9-239.42z"
              />
              <Path
                fill="#A5CF4F"
                d="M513.88-478.16c-14.33,13.3-22.04,31.55-21.19,50.29c0.83,18.42,7.72,34.15,19.92,45.48
                c12.05,11.19,28.16,17.11,46.57,17.11c1.28,0,2.58-0.03,3.87-0.09c16.94-0.77,33.05-8.36,44.44-20.88l-21.64-19.22
                c-6.38,6.43-15.49,10.61-24.12,11c-0.59,0.03-1.18,0.04-1.76,0.04c-19.47,0-34.83-14.99-35.73-34.87
                c-0.49-10.85,3.11-20.64,10.17-27.74L513.88-478.16 M513.95-480.15l22.5,23.14c-7.14,6.61-11.27,16.37-10.76,27.65
                c0.87,19.09,15.46,33.51,34.31,33.51c0.56,0,1.12-0.01,1.69-0.04c9.22-0.42,18.24-5.14,24.11-11.56l23.74,21.09
                c-11.7,13.46-28.58,21.61-46.4,22.42c-1.32,0.06-2.64,0.09-3.94,0.09c-37.97,0-66.17-25.33-67.92-63.95
                C490.34-448.3,499.39-467.15,513.95-480.15L513.95-480.15z"
              />
              <Path
                fill="#A5CF4F"
                d="M279.71-65.35v12.67h-4.61l-0.76-1.87c-1.25,1.56-2.93,2.33-5.06,2.33c-2.76,0-4.94-0.7-6.52-2.1
              c-1.58-1.4-2.38-3.08-2.38-5.02c0-1.22,0.29-2.32,0.86-3.29c0.57-0.97,1.33-1.74,2.29-2.31c0.96-0.57,2.02-1.01,3.19-1.31
              c1.17-0.31,2.39-0.44,3.64-0.4c1.26,0.04,2.46,0.24,3.6,0.6c0-1.25-0.4-2.17-1.2-2.75c-0.8-0.58-1.8-0.87-2.98-0.87
              c-1.88,0-3.81,0.54-5.8,1.62l-2.07-3.5c2.47-1.92,5.27-2.87,8.41-2.87c2.63,0,4.85,0.79,6.67,2.35
              C278.8-70.51,279.71-68.27,279.71-65.35z M274-61.72c-0.94-0.42-2.01-0.65-3.21-0.71c-1.2-0.06-2.28,0.15-3.23,0.6
              c-0.95,0.46-1.44,1.16-1.47,2.1c-0.03,0.78,0.35,1.44,1.12,2c0.77,0.56,1.81,0.83,3.11,0.83c1.24,0,2.47-0.5,3.69-1.5V-61.72z
               M296.93-73.04c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19c-1.71,0.28-3.37,0.71-4.99,1.29v20.04h5.71v-16.17
              c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35c0.82,0.28,1.49,0.79,2.01,1.54c0.52,0.75,0.77,1.68,0.77,2.79
              v12.08h5.71v-12.12c-0.03-2.06-0.48-3.78-1.36-5.19C299.43-71.4,298.31-72.41,296.93-73.04z M310.42-81.93h-5.71v8.12h-2.17v4.58
              h2.17v9.75c-0.06,1.78,0.37,3.24,1.27,4.4c0.9,1.15,2.06,1.9,3.46,2.25c1.4,0.35,2.94,0.4,4.6,0.15v-4.83
              c-1.12,0.14-2,0.01-2.65-0.4c-0.65-0.4-0.98-0.97-0.98-1.69v-9.62h3.62v-4.58h-3.62V-81.93z M333.15-71.29
              c1.96,2.01,2.94,4.52,2.94,7.52c0,1.03-0.08,1.97-0.25,2.83h-15.25c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69
              c1.5-0.21,2.82-0.81,3.96-1.81l3.21,2.83c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29
              c-1.92-2.19-2.86-4.78-2.83-7.75c0-2.94,0.96-5.5,2.88-7.67c1.92-2.17,4.54-3.26,7.87-3.29
              C328.68-74.29,331.19-73.3,333.15-71.29z M330.5-65.31c-0.22-1.21-0.79-2.11-1.69-2.7c-0.9-0.59-1.95-0.89-3.15-0.89
              c-1.22,0-2.32,0.3-3.29,0.9c-0.97,0.6-1.57,1.5-1.79,2.68H330.5z M356.02-71.29c1.96,2.01,2.94,4.52,2.94,7.52
              c0,1.03-0.08,1.97-0.25,2.83h-15.25c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69c1.5-0.21,2.82-0.81,3.96-1.81
              l3.21,2.83c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29c-1.92-2.19-2.86-4.78-2.83-7.75
              c0-2.94,0.96-5.5,2.88-7.67c1.92-2.17,4.54-3.26,7.87-3.29C351.55-74.29,354.06-73.3,356.02-71.29z M353.37-65.31
              c-0.22-1.21-0.79-2.11-1.69-2.7c-0.9-0.59-1.95-0.89-3.15-0.89c-1.22,0-2.32,0.3-3.29,0.9c-0.97,0.6-1.57,1.5-1.79,2.68H353.37z
               M378.93-69.99c-0.88-1.4-2-2.42-3.38-3.04c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19
              c-1.71,0.28-3.37,0.71-4.99,1.29v20.04h5.71v-16.17c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35
              c0.82,0.28,1.49,0.79,2.01,1.54c0.52,0.75,0.77,1.68,0.77,2.79v12.08h5.71v-12.12C380.26-66.86,379.81-68.59,378.93-69.99z
               M251.23-69.04c0.33-0.01,0.65,0,0.96,0.03l0.31-5.29c-0.49-0.03-0.99-0.03-1.51-0.01c-0.21,0.01-0.42,0.03-0.62,0.05l0.65,5.23
              C251.09-69.03,251.16-69.03,251.23-69.04z M255.66-73.64c-0.7-0.27-1.44-0.45-2.2-0.56l-0.74,5.27c0.38,0.07,0.74,0.19,1.09,0.33
              L255.66-73.64z M255.46-67.61l3.58-3.92c-0.81-0.74-1.65-1.31-2.53-1.75l-2.23,4.88C254.69-68.17,255.09-67.91,255.46-67.61z
               M248.87-68.44l-2.41-4.68c-0.65,0.32-1.27,0.71-1.84,1.14l3.36,4.09C248.26-68.1,248.55-68.28,248.87-68.44z M250.49-68.96
              l-1.07-5.16c-0.72,0.14-1.42,0.34-2.09,0.61l2.02,4.86C249.72-68.79,250.1-68.89,250.49-68.96z M251.76-57.49
              c-3.24,0.15-5.78-2.27-5.93-5.51c-0.08-1.86,0.6-3.47,1.77-4.56l-3.71-3.81c-2.4,2.14-3.89,5.25-3.74,8.62
              c0.3,6.58,5.26,10.82,11.84,10.52c2.94-0.13,5.72-1.48,7.64-3.69l-3.91-3.48C254.76-58.34,253.28-57.56,251.76-57.49z"
              />
              <Path
                fill="#A5CF4F"
                d="M320.87-314.64c-1.29,0-2.6,0.03-3.89,0.09c-0.73,0.03-1.48,0.08-2.26,0.15l3.62,28.95
                c0.57-0.03,1.14-0.04,1.71-0.04c0.93,0,1.87,0.03,2.81,0.1l1.69-29.17C323.33-314.61,322.09-314.64,320.87-314.64
                 M320.87-316.07c1.76,0,3.49,0.05,5.19,0.16l-1.86,32.08c-1.36-0.15-2.74-0.23-4.15-0.23c-0.56,0-1.12,0.01-1.69,0.04
                c-0.43,0.02-0.85,0.06-1.27,0.09l-3.97-31.75c1.26-0.13,2.52-0.23,3.79-0.29C318.25-316.04,319.57-316.07,320.87-316.07
                L320.87-316.07z"
              />
              <Path
                fill="#A5CF4F"
                d="M333.05-313.7l-4.1,29.23c1.42,0.34,2.82,0.75,4.2,1.25l10.22-27.87
                C340.1-312.24,336.63-313.12,333.05-313.7 M331.84-315.33c4.65,0.66,9.1,1.77,13.37,3.39L334-281.36
                c-2.13-0.87-4.34-1.54-6.64-1.99L331.84-315.33L331.84-315.33z"
              />
              <Path
                fill="#A5CF4F"
                d="M351.02-307.76l-12.38,27.04c1.81,1,3.55,2.15,5.2,3.43L363.67-299
                C359.63-302.55,355.47-305.44,351.02-307.76 M350.34-309.71c5.35,2.64,10.45,6.13,15.36,10.61L344-275.33
                c-2.24-1.86-4.65-3.45-7.22-4.76L350.34-309.71L350.34-309.71z"
              />
              <Path
                fill="#A5CF4F"
                d="M288.8-306.87c-2.94,1.54-5.79,3.31-8.5,5.26l18.55,22.63c1.04-0.73,2.12-1.4,3.24-2.02L288.8-306.87
                 M289.42-308.79l14.6,28.4c-1.93,0.96-3.73,2.08-5.41,3.36l-20.36-24.84C281.73-304.5,285.47-306.83,289.42-308.79
                L289.42-308.79z"
              />
              <Path
                fill="#A5CF4F"
                d="M306.29-313.17c-3.27,0.69-6.51,1.63-9.66,2.8l11.18,26.87c1.42-0.48,2.88-0.87,4.36-1.18L306.29-313.17
                 M307.4-314.85l6.47,31.31c-2.4,0.4-4.69,1.02-6.86,1.84l-12.27-29.48C298.8-312.79,303.04-314.03,307.4-314.85L307.4-314.85z"
              />
              <Path
                fill="#A5CF4F"
                d="M273.74-296.23c-14.33,13.3-22.04,31.55-21.19,50.29c0.83,18.42,7.72,34.15,19.92,45.48
                c12.05,11.19,28.16,17.11,46.57,17.11c1.28,0,2.58-0.03,3.87-0.09c16.94-0.77,33.05-8.36,44.44-20.88l-21.64-19.22
                c-6.38,6.43-15.49,10.61-24.12,11c-0.59,0.03-1.18,0.04-1.76,0.04c-19.47,0-34.83-14.99-35.73-34.87
                c-0.49-10.85,3.11-20.64,10.17-27.74L273.74-296.23 M273.8-298.22l22.5,23.14c-7.14,6.61-11.27,16.37-10.76,27.65
                c0.87,19.09,15.46,33.51,34.31,33.51c0.56,0,1.12-0.01,1.69-0.04c9.22-0.42,18.24-5.14,24.11-11.56l23.74,21.09
                c-11.7,13.46-28.58,21.61-46.4,22.42c-1.32,0.06-2.64,0.09-3.94,0.09c-37.97,0-66.17-25.33-67.92-63.95
                C250.19-266.38,259.25-285.23,273.8-298.22L273.8-298.22z"
              />
              <Path
                fill="#A5CF4F"
                d="M39.57-247.27v12.67h-4.61l-0.76-1.87c-1.25,1.56-2.93,2.33-5.06,2.33c-2.76,0-4.94-0.7-6.52-2.1
              c-1.58-1.4-2.38-3.08-2.38-5.02c0-1.22,0.29-2.32,0.86-3.29c0.57-0.97,1.33-1.74,2.29-2.31c0.96-0.57,2.02-1.01,3.19-1.31
              c1.17-0.31,2.39-0.44,3.64-0.4c1.26,0.04,2.46,0.24,3.6,0.6c0-1.25-0.4-2.17-1.2-2.75c-0.8-0.58-1.8-0.87-2.98-0.87
              c-1.88,0-3.81,0.54-5.8,1.62l-2.07-3.5c2.47-1.92,5.27-2.87,8.41-2.87c2.63,0,4.85,0.79,6.67,2.35
              C38.66-252.43,39.57-250.19,39.57-247.27z M33.86-243.65c-0.94-0.42-2.01-0.65-3.21-0.71c-1.2-0.06-2.28,0.15-3.23,0.6
              c-0.95,0.46-1.44,1.16-1.47,2.1c-0.03,0.78,0.35,1.44,1.12,2c0.77,0.56,1.81,0.83,3.11,0.83c1.24,0,2.47-0.5,3.69-1.5V-243.65z
               M56.78-254.96c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19c-1.71,0.28-3.37,0.71-4.99,1.29v20.04h5.71v-16.17
              c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35c0.82,0.28,1.49,0.79,2.01,1.54c0.52,0.75,0.77,1.68,0.77,2.79
              v12.08h5.71v-12.12c-0.03-2.06-0.48-3.78-1.36-5.19C59.29-253.32,58.16-254.34,56.78-254.96z M70.27-263.85h-5.71v8.12H62.4v4.58
              h2.17v9.75c-0.06,1.78,0.37,3.24,1.27,4.4c0.9,1.15,2.06,1.9,3.46,2.25c1.4,0.35,2.94,0.4,4.6,0.15v-4.83
              c-1.12,0.14-2,0.01-2.65-0.4c-0.65-0.4-0.98-0.97-0.98-1.69v-9.62h3.62v-4.58h-3.62V-263.85z M93-253.21
              c1.96,2.01,2.94,4.52,2.94,7.52c0,1.03-0.08,1.97-0.25,2.83H80.44c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69
              c1.5-0.21,2.82-0.81,3.96-1.81l3.21,2.83c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29
              c-1.92-2.19-2.86-4.78-2.83-7.75c0-2.94,0.96-5.5,2.88-7.67c1.92-2.17,4.54-3.26,7.87-3.29C88.54-256.22,91.04-255.23,93-253.21z
               M90.36-247.23c-0.22-1.21-0.79-2.11-1.69-2.7c-0.9-0.59-1.95-0.89-3.15-0.89c-1.22,0-2.32,0.3-3.29,0.9
              c-0.97,0.6-1.57,1.5-1.79,2.68H90.36z M115.88-253.21c1.96,2.01,2.94,4.52,2.94,7.52c0,1.03-0.08,1.97-0.25,2.83h-15.25
              c0.31,1.33,1.06,2.33,2.27,3c1.21,0.67,2.56,0.9,4.06,0.69c1.5-0.21,2.82-0.81,3.96-1.81l3.21,2.83
              c-2.39,2.64-5.19,3.96-8.42,3.96c-3.39,0-6.04-1.1-7.96-3.29c-1.92-2.19-2.86-4.78-2.83-7.75c0-2.94,0.96-5.5,2.88-7.67
              c1.92-2.17,4.54-3.26,7.87-3.29C111.41-256.22,113.92-255.23,115.88-253.21z M113.23-247.23c-0.22-1.21-0.79-2.11-1.69-2.7
              c-0.9-0.59-1.95-0.89-3.15-0.89c-1.22,0-2.32,0.3-3.29,0.9c-0.97,0.6-1.57,1.5-1.79,2.68H113.23z M138.79-251.92
              c-0.88-1.4-2-2.42-3.38-3.04c-1.38-0.62-2.94-1.01-4.7-1.17c-1.75-0.15-3.49-0.09-5.2,0.19c-1.71,0.28-3.37,0.71-4.99,1.29v20.04
              h5.71v-16.17c0.87-0.36,1.78-0.56,2.74-0.6c0.96-0.04,1.86,0.08,2.68,0.35c0.82,0.28,1.49,0.79,2.01,1.54
              c0.52,0.75,0.77,1.68,0.77,2.79v12.08h5.71v-12.12C140.12-248.79,139.66-250.52,138.79-251.92z M11.09-250.96
              c0.33-0.01,0.65,0,0.96,0.03l0.31-5.29c-0.49-0.03-0.99-0.03-1.51-0.01c-0.21,0.01-0.42,0.03-0.62,0.05l0.65,5.23
              C10.95-250.95,11.02-250.96,11.09-250.96z M15.51-255.56c-0.7-0.27-1.44-0.45-2.2-0.56l-0.74,5.27c0.38,0.07,0.74,0.19,1.09,0.33
              L15.51-255.56z M15.31-249.53l3.58-3.92c-0.81-0.74-1.65-1.31-2.53-1.75l-2.23,4.88C14.55-250.1,14.94-249.84,15.31-249.53z
               M8.73-250.36l-2.41-4.68c-0.65,0.32-1.27,0.71-1.84,1.14l3.36,4.09C8.11-250.02,8.41-250.21,8.73-250.36z M10.35-250.88
              l-1.07-5.16c-0.72,0.14-1.42,0.34-2.09,0.61l2.02,4.86C9.58-250.72,9.95-250.82,10.35-250.88z M11.61-239.42
              c-3.24,0.15-5.78-2.27-5.93-5.51c-0.08-1.86,0.6-3.47,1.77-4.56l-3.71-3.81c-2.4,2.14-3.89,5.25-3.74,8.62
              c0.3,6.58,5.26,10.82,11.84,10.52c2.94-0.13,5.72-1.48,7.64-3.69l-3.91-3.48C14.62-240.27,13.13-239.49,11.61-239.42z"
              />
              <Path
                fill="#A5CF4F"
                d="M33.59-478.16c-14.33,13.3-22.04,31.55-21.19,50.29c0.83,18.42,7.72,34.15,19.92,45.48
                c12.05,11.19,28.16,17.11,46.57,17.11c1.28,0,2.58-0.03,3.87-0.09c16.94-0.77,33.05-8.36,44.44-20.88l-21.64-19.22
                c-6.38,6.43-15.49,10.61-24.12,11c-0.59,0.03-1.18,0.04-1.76,0.04c-19.47,0-34.83-14.99-35.73-34.87
                c-0.49-10.85,3.11-20.64,10.17-27.74L33.59-478.16 M33.66-480.15l22.5,23.14c-7.14,6.61-11.27,16.37-10.76,27.65
                c0.87,19.09,15.46,33.51,34.31,33.51c0.56,0,1.12-0.01,1.69-0.04c9.22-0.42,18.24-5.14,24.11-11.56l23.74,21.09
                c-11.7,13.46-28.58,21.61-46.4,22.42c-1.32,0.06-2.64,0.09-3.94,0.09c-37.97,0-66.17-25.33-67.92-63.95
                C10.05-448.3,19.1-467.15,33.66-480.15L33.66-480.15z"
              />
            </Pattern>
          </Defs>
          <Rect fill="#003348" width="100%" height="100%" />
          <Rect fill="url(#canteenPattern)" width="100%" height="100%" />
        </Svg>
      </View>
    );
  }
}
