import * as React from 'react';
import {
  Image,
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  Dimensions,
  PixelRatio,
} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import AVText from './AVText';
import type {MachineType} from 'src/types/MachineType';
import SwipeableRow from '../elements/DeleteSwipeableRow';
import AccountStore from 'src/stores/AccountStore';
import UIManager from './ui/UIManager';
import Localized from 'src/constants/AppStrings';
import {ensureHttps} from 'src/services/StringUtils';
import {WithLocalizedProps} from 'src/types/PropTypes';

const deviceScreen = Dimensions.get('window');
type MarketCellProps = WithLocalizedProps & {
  machine: MachineType;
  machineSelect: (machine: any) => void;
  onDeletePressed: () => void;
};

export const isDisabled = function (machine: MachineType): boolean {
  return (
    machine.incompatibleCurrency ||
    machine.ccNotConfigured ||
    machine.incompatibleOrg ||
    machine.hiatusMode ||
    false
  );
};

export const getDisabledReason = function (
  disabled: boolean,
  machine: MachineType,
  strings: typeof Localized,
): React.ReactNode {
  let disabledReason: JSX.Element | null = null;

  if (disabled) {
    let reason = strings.Labels.incompatible_currency;

    if (machine.ccNotConfigured) {
      reason = strings.Labels.no_processor;
    }

    if (machine.incompatibleOrg) {
      reason = strings.Labels.account_not_available;
    }

    if (machine.hiatusMode) {
      reason = strings.Labels.out_of_service;
    }

    disabledReason = (
      <AVText
        accessible={true}
        accessibilityLabel={reason}
        aria-label={reason}
        style={Styles.Style.disabledReason}
      >
        {reason}
      </AVText>
    );
  }

  return disabledReason;
};

const MarketCell = (props: MarketCellProps) => {
  let image: JSX.Element | null = null;
  if (props.machine.imageUrl) {
    image = (
      <Image
        accessible={true}
        accessibilityLabel="location image"
        aria-label="location image"
        role="img"
        accessibilityRole="image"
        style={styles.machineImage}
        source={{
          uri: ensureHttps(props.machine.imageUrl),
        }}
      />
    );
  } else {
    image = UIManager.getMarketLogo();
  }

  const disabled = isDisabled(props.machine);
  const disabledReason = getDisabledReason(
    disabled,
    props.machine,
    props.strings,
  );
  let label = props.strings.Labels.scan_and_pay;

  if (props.machine.orderAhead) {
    label = UIManager.getOrderAheadLabel();
  }

  const debugMachineDistance =
    AccountStore.isDebug() && props.machine.distance
      ? `(${props.machine.distance.toFixed(2)}m)`
      : '';

  const onLongPress = () => {
    props.onDeletePressed();
  };

  const mainContent = (
    <TouchableWithoutFeedback
      style={[Styles.Style.machineName]}
      onPress={props.machineSelect}
      onLongPress={onLongPress}
      role="button"
      aria-disabled={disabled}
      aria-label={`${props.machine.name}, ${label}`}
      accessibilityState={{disabled: disabled}}
      accessibilityRole="button"
      accessibilityLabel={`${props.machine.name}, ${label}`}
      accessibilityHint={`Double tap to select ${props.machine.name} and continue to the next screen, long press to remove ${props.machine.name}`}
    >
      <View style={Styles.Style.marketContent}>
        {image}
        <View style={Styles.Style.machineTextContainer}>
          <AVText
            numberOfLines={PixelRatio.getFontScale() > 1 ? 0 : 1}
            maxFontSizeMultiplier={2}
            style={Styles.Style.machineText}
          >
            {props.machine.name} {debugMachineDistance}
          </AVText>
          <AVText maxFontSizeMultiplier={4} style={{color: Styles.lightGray}}>
            {label}
          </AVText>
          {disabledReason}
        </View>
        <FontAwesome5Pro
          name={'chevron-right'}
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </View>
    </TouchableWithoutFeedback>
  );

  if (props.machine.orderAhead && !props.machine.isNearby) {
    return (
      <View
        style={[
          Styles.Style.standalone,
          disabled && Styles.Style.disabledMarket,
        ]}
      >
        <SwipeableRow
          onDeletePressed={() => props.onDeletePressed()}
          type="remove"
        >
          {mainContent}
        </SwipeableRow>
      </View>
    );
  } else {
    return (
      <View
        style={[
          Styles.Style.standalone,
          disabled && Styles.Style.disabledMarket,
        ]}
      >
        {mainContent}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  machineImage: {
    height: deviceScreen.height / 6,
    width: deviceScreen.height / 11,
  },
});
export default MarketCell;
