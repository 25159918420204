import Api from './Api';
import type {PaymentType} from 'src/stores/CartStore';
import type {CartItem} from 'src/types/TransactionDetail';
import {UrlTypes} from 'src/types/UrlTypes';
import {store} from '../redux/store';

export type ProductType = {
  id: string;
  org: string;
  location: string;
  product: string;
  name: string;
  scancode: string;
  category1: string;
  cost: number;
  price: number;
  taxable: string;
  tax: number;
  hasdeposit: string;
  deposit: number;
  points: number;
  um: string;
  weigh: string;
  tareweight: number;
  hasmodifier: string;
  datecreated: string;
};

export class CartApi {
  updateCart(
    locationId: string,
    gmaId: string,
    coupons: Array<unknown>,
    cartItems: Array<CartItem>,
    type = 'sos',
    pickupDate?: string,
    pickupTime?: string,
    preparationMethod?: string,
    discountCode?: string | null,
  ) {
    const url = this.getAddItemUrl(type);
    const postObj = {
      LocationId: locationId,
      GMAId: gmaId,
      Coupons: coupons,
      CartItems: cartItems,
      PreparationMethod: preparationMethod,
      PickupTime: pickupTime,
      PickupDate: pickupDate,
      Version: 1,
      discountCode,
    };
    return Api.fetch(url, postObj, 'POST');
  }

  checkout(
    locationId: string,
    gmaId: string,
    payments: Array<PaymentType>,
    coupons: Array<unknown>,
    cartItems: Array<CartItem>,
    type = 'sos',
    transDate: string | null = null,
    transId: string,
    sequenceNumber: number,
    pickupTime?: string,
    preparationMethod?: string,
    pickupDate?: string | null,
    pickupLocation?: string | null,
    pickupLocationId?: string | null,
    orderNote?: string | null,
    fallbackPreparationMethod?: string | null,
    salesContext?: string | null,
    discountCode?: string | null,
    isPaid?: string,
    status?: string,
  ) {
    const url = this.getCheckoutUrl(type);
    const postObj = {
      LocationId: locationId,
      GMAId: gmaId,
      Coupons: coupons,
      Payments: payments,
      CartItems: cartItems,
      Version: 1,
      TransDate: transDate,
      TransId: transId,
      SeqNumber: sequenceNumber,
      PickupTime: pickupTime,
      IsPaid: isPaid,
      Status: status,
      PreparationMethod: preparationMethod,
      PickupDate: pickupDate,
      PickupLocation: pickupLocation,
      PickupLocationId: pickupLocationId,
      OrderNote: orderNote,
      FallbackPreparationMethod: fallbackPreparationMethod,
      saleContext: salesContext,
      discountCode,
    };
    return Api.fetch(url, postObj, 'POST');
  }

  searchProducts(
    location: string,
    query: string,
    category: string | number,
    offset: number,
    size: number,
  ): Promise<ProductType[]> {
    const params = {
      location,
      query,
      category,
      offset,
      size,
    };
    const url = this.getFullUrl('/cart/products', params);
    return Api.fetch(url, null, 'GET');
  }

  getCategories(location: string) {
    const url = this.getFullUrl('/cart/categories', {
      location,
    });
    return Api.fetch(url, null, 'GET');
  }

  getCheckoutUrl(type: string) {
    if (type === '365') {
      return `${
        store.getState().environment.serviceUrls[UrlTypes.shqcartapi]
      }/CheckOut`;
    }

    return `${
      store.getState().environment.serviceUrls[UrlTypes.cartapi]
    }/cart/checkout`;
  }

  getAddItemUrl(type: string) {
    if (type === '365') {
      return `${
        store.getState().environment.serviceUrls[UrlTypes.shqcartapi]
      }/AddItem`;
    }

    return `${
      store.getState().environment.serviceUrls[UrlTypes.cartapi]
    }/cart/additem`;
  }

  getFullUrl(path: string, params: any) {
    const baseUrl = store.getState().environment.serviceUrls[UrlTypes.cartapi];
    return Api.getFullUrl(baseUrl, path, params);
  }
}

export default new CartApi();
