import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import BackSubheader from '../../elements/BackSubheader';
import ItemAddedFeedback from '../../elements/cart/ItemAddedFeedback';
import Styles from '../../Styles';
import {Platform, ScrollView, StyleSheet, Switch, View} from 'react-native';
import UIManager from '../../elements/ui/UIManager';
import NavActions from 'src/actions/NavActions';
import ScreenContext from '../../ScreenContext';
import Localized from 'src/constants/AppStrings';
import AVText from '../../elements/AVText';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import {isValidEmail} from 'src/services/ValidatorService';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import StickerList from '../../elements/sendSnack/StickerList';
import AccountStore from 'src/stores/AccountStore';
import Util, {getPreviousRouteName} from 'src/Util';
import AppRoutes from 'src/AppRoutes';
import {alertError} from '../../helpers/AlertHelper';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {
  sendSnackToUser,
  SendSnackToUserParams,
} from '../../../redux/thunks/snackThunks';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type SendSnackScreenProp = WithGlobalizeProps & {
  sendSnackToUser(params: SendSnackToUserParams): void;
  navigation?: NavigationProp<SendSnackScreen>;
};
type SendSnackScreenState = {
  amount: string;
  description: string;
  email: string;
  isPrivate: boolean;
  stickerId: number;
  amountError?: string;
  mailError?: string;
  isSnackDataValid: boolean;
  isGiftTextInputFocused: boolean;
  previousRoute: string;
};

const dollarSymbol = '$';

class SendSnackScreen extends React.Component<
  SendSnackScreenProp,
  SendSnackScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      description: '',
      email: '',
      isPrivate: false,
      stickerId: 0,
      amountError: '',
      mailError: '',
      isSnackDataValid: false,
      isGiftTextInputFocused: false,
      previousRoute: '',
    };
    this.onBackSelect = this.onBackSelect.bind(this);
    this.getRightView = this.getRightView.bind(this);
    this.getBalanceContainer = this.getBalanceContainer.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onChangeGiftAmount = this.onChangeGiftAmount.bind(this);
    this.validateGiftAmount = this.validateGiftAmount.bind(this);
    this.onSendSnack = this.onSendSnack.bind(this);
    this.validateSnackData = this.validateSnackData.bind(this);
    this.handleSnackBalanceCovers = this.handleSnackBalanceCovers.bind(this);
    this.navigateToFunding = this.navigateToFunding.bind(this);
    this.onContactSelected = this.onContactSelected.bind(this);
    this.getGiftAmountTextValue = this.getGiftAmountTextValue.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  getBalanceContainer(smallText: boolean) {
    return UIManager.getBalanceContainer(smallText, Localized);
  }

  getRightView() {
    if (Platform.OS === 'web') {
      return null;
    } else {
      return (
        <View style={styles.rightContainer}>
          {this.getBalanceContainer(false)}
        </View>
      );
    }
  }

  onBackSelect() {
    NavActions.pop();
  }

  onChangeEmail(email: string) {
    FirebaseAnalytic.trackEvent('onChangeEmail', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.setState({
      email,
      mailError: undefined,
    });
    this.validateSnackData(
      Number(this.state.amount),
      email,
      this.state.stickerId,
    );
  }

  validateEmail() {
    if (isValidEmail(this.state.email)) {
      this.setState({
        mailError: undefined,
      });
    } else {
      this.setState({
        mailError: Localized.Errors.please_enter_the_valid_email,
      });
    }
  }

  onChangeGiftAmount(amount: string) {
    amount = amount.replace(dollarSymbol, '');
    FirebaseAnalytic.trackEvent('onChangeGiftAmount', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      amount,
    });
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({
        amount,
        amountError: undefined,
      });
    }

    this.validateSnackData(
      Number(amount),
      this.state.email,
      this.state.stickerId,
    );
  }

  validateGiftAmount() {
    FirebaseAnalytic.trackEvent('validateGiftAmount', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
    });
    this.setState({
      isGiftTextInputFocused: false,
    });
    const amountValue = Number(this.state.amount);

    if (amountValue >= 1.0) {
      this.setState({
        amount: amountValue.toFixed(2).toString(),
        amountError: undefined,
      });
    } else {
      this.setState({
        amountError: Localized.Errors.minimum_snack_gift_amount,
      });
    }
  }

  getGiftAmountTextValue() {
    FirebaseAnalytic.trackEvent('getGiftAmountTextValue', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
    });
    if (this.state.amount === '' && this.state.isGiftTextInputFocused) {
      return dollarSymbol;
    } else if (this.state.amount === '') {
      return '';
    } else {
      return dollarSymbol + this.state.amount;
    }
  }

  onContactSelected(email: string) {
    FirebaseAnalytic.trackEvent('onContactSelected', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.setState({
      email,
    });

    if (isValidEmail(email)) {
      this.setState({
        mailError: undefined,
      });
    } else {
      this.setState({
        mailError: Localized.Errors.please_enter_the_valid_email,
      });
    }

    this.validateSnackData(
      Number(this.state.amount),
      email,
      this.state.stickerId,
    );
  }

  validateSnackData(amount: number, email: string, stickerId: number) {
    const validData =
      isValidEmail(email) && Number(amount) >= 1.0 && stickerId > 0;

    FirebaseAnalytic.trackEvent('validateSnackData', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      validData,
      email,
      stickerId,
    });

    this.setState({
      isSnackDataValid: validData,
    });
  }

  handleSnackBalanceCovers() {
    const transDate = Util.getCurrentDate();
    if (this.state.email === AccountStore.getEmail()) {
      alertError(
        Localized.Errors.you_cannot_snack_to_yourself,
        null,
        undefined,
        Localized.Errors.oops,
      );
      return;
    }

    const snackAmount = Number(this.state.amount);
    const balance = AccountStore.getAccountBalance();
    const amountDue = snackAmount - balance;
    FirebaseAnalytic.trackEvent('handleSnackBalanceCovers', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
      snackAmount,
      balance,
      amountDue,
    });
    if (amountDue > 0) {
      this.navigateToFunding(amountDue, transDate);
    } else {
      this.onSendSnack(transDate);
    }
  }

  navigateToFunding(balance: number, transDate: string) {
    FirebaseAnalytic.trackEvent('navigateToFunding', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
      balance,
    });
    this.context.actions.navigateToFunding(true, {
      transDate,
      remainingDue: balance,
      isSnackGiftDue: true,
      addFundsSuccess: () => this.onSendSnack(transDate),
    });
  }

  async onSendSnack(transDate: string) {
    FirebaseAnalytic.trackEvent('onSendSnack', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
    });
    this.props.sendSnackToUser({
      locationId: AccountStore.getLocationId(),
      amount: Number(this.state.amount),
      description: this.state.description,
      email: this.state.email,
      isPrivate: this.state.isPrivate,
      stickerId: this.state.stickerId,
      transDate: transDate,
    });
  }

  render() {
    const rightView = this.getRightView();
    const content = (
      <KeyboardAvoidingView behavior="height" insideTab>
        <ScrollView
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={[styles.content, Styles.Style.maxWidthContainer]}>
            <AVText
              accessible={true}
              accessibilityLabel={Localized.Labels.choose_a_sticker}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.choose_a_sticker}, text`}
              style={styles.chooseStickerLabel}
            >
              {Localized.Labels.choose_a_sticker}
            </AVText>
            <StickerList
              size={Styles.Heights.h8}
              onStickerSelection={(id) => {
                this.setState({
                  stickerId: id,
                });
                this.validateSnackData(
                  Number(this.state.amount),
                  this.state.email,
                  id,
                );
              }}
            />
            <AllyTextInput
              label={Localized.Labels.gift_amount}
              value={this.getGiftAmountTextValue()}
              autoCapitalize="none"
              maxLength={10}
              accessible={true}
              accessibilityLabel={Localized.Labels.gift_amount}
              accessibilityValue={{text: this.getGiftAmountTextValue()}}
              aria-label={Localized.Labels.gift_amount}
              aria-valuetext={this.getGiftAmountTextValue()}
              onChangeText={(e) => this.onChangeGiftAmount(e)}
              keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AVText
              style={styles.errorLabel}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel="Snack Amount Error"
              aria-label="Snack amount error, text"
            >
              {this.state.amountError}
            </AVText>
            <AllyTextInput
              label="Your Message"
              value={this.state.description}
              autoCapitalize="none"
              accessible={true}
              accessibilityLabel={Localized.Labels.gift_amount}
              accessibilityValue={{text: this.state.description}}
              aria-label={Localized.Labels.gift_amount}
              aria-valuetext={this.state.description}
              onChangeText={(e) => {
                this.setState({
                  description: e,
                });
              }}
              clearButtonMode="while-editing"
              maxLength={140}
              returnKeyType="next"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />

            <AVText style={styles.descriptionCountLabel}>
              {this.state.description.length + '/140'}
            </AVText>
            <View style={styles.contactContainer}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={styles.toLabel}
              >
                {Localized.Labels.to}
              </AVText>
              <RoundedButton
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                buttonType={ButtonType.outline}
                text={Localized.Labels.choose_from_contacts}
                containerStyle={styles.button}
                disabled={Platform.OS === 'web'}
                onPress={() => {
                  NavActions.navigate(AppRoutes.ChooseContact, {
                    onContactSelect: (email) => {
                      this.onContactSelected(email);
                    },
                  });
                }}
                accessible={true}
                accessibilityLabel="Choose from contacts"
                accessibilityRole="button"
                role="button"
                aria-label="Choose from contacts"
              />
            </View>
            <AllyTextInput
              label={Localized.Labels.email_address}
              onChangeText={(e) => {
                this.onChangeEmail(e);
              }}
              accessible={true}
              accessibilityLabel="Email"
              aria-label="Email"
              value={this.state.email}
              autoCapitalize="none"
              keyboardType="email-address"
              onBlur={this.validateEmail}
              returnKeyType="next"
              maxLength={75}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AVText style={styles.errorLabel}>{this.state.mailError}</AVText>
            <View style={styles.privateStatusContainer}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                style={styles.privateLabel}
              >
                {Localized.Labels.private}
              </AVText>
              <Switch
                accessible={true}
                accessibilityLabel="Snack Private Status"
                accessibilityRole="switch"
                role="switch"
                aria-label="Send private status"
                nativeID="Snack Private Status"
                value={this.state.isPrivate}
                onValueChange={(value) =>
                  this.setState({
                    isPrivate: value,
                  })
                }
                trackColor={{
                  false: Styles.lightGray,
                  true: Styles.primaryColor,
                }}
              />
            </View>
            <View style={styles.spacer} />
          </View>
        </ScrollView>
        <RoundedButton
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          buttonType={ButtonType.action}
          onPress={this.handleSnackBalanceCovers}
          accessibilityLabel="Send Snack"
          disabled={!this.state.isSnackDataValid}
          text={Localized.Buttons.send_snack}
        />
      </KeyboardAvoidingView>
    );
    return (
      <BackSubheader
        title={Localized.Labels.send_a_snack_title}
        accessibilityLabel="Back arrow"
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        onBackSelect={this.onBackSelect}
        pop={false}
        rightView={rightView}
      >
        {content}
        <ItemAddedFeedback
          strings={Localized}
          extraMargin={Styles.Spacing.m0}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
  },
  rightContainer: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: Styles.Spacing.m4,
  },
  errorLabel: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
  },
  descriptionCountLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    alignSelf: 'flex-end',
  },
  textField: {
    marginTop: Styles.Spacing.m2,
    alignSelf: 'stretch',
    height: Styles.Heights.h3,
  },
  button: {
    alignSelf: 'flex-end',
    marginEnd: Styles.Spacing.m1,
  },
  privateStatusContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    padding: Styles.Spacing.m2,
  },
  privateLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  chooseStickerLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m2,
  },
  contactContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m3,
    alignItems: 'center',
  },
  toLabel: {
    fontWeight: 'bold',
    marginRight: Styles.Spacing.m1,
  },
  spacer: {
    height: Styles.Heights.h5,
    marginBottom: 200,
  },
});

export default compose(
  withForwardedNavigationParams(),
  withGlobalize,
  connect(null, (dispatch: AppDispatch) => ({
    sendSnackToUser: (params) => dispatch(sendSnackToUser(params)),
  })),
)(SendSnackScreen);
