import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Styles from '../../Styles';
type LogoProps = {
  size: number;
  color: string;
}; // tslint:disable

export default class Rocket extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 434 700"
      >
        <Path
          fill={this.props.color || Styles.primaryColor}
          d="M406,532.2c32.3-72.5,6.7-152.1-62.3-198.9c-0.4-112.2-40.6-227.9-117-307.3c-5.5-5.4-12.7-5.4-18.1,0.1
                    C132.9,106,93.5,222,92.1,332.4c-65,47.3-93.7,127.1-60.8,199.3c14.4-32.6,39.6-61.7,73.9-81.7c3.7,14.5,7.3,28.9,12.8,41.6
                    c1.8,5.4,5.5,9,12.7,9l181-0.7c3.6,0,7.2-3.6,9-9.1c5.4-12.7,9-27.2,10.7-39.9C366,470.8,391.4,499.7,406,532.2z"
        />
        <Path
          fill={this.props.color || Styles.primaryColor}
          d="M315.9,633.9l-0.3-76c-0.1-14.5-11-25.3-25.4-25.3c-14.5,0.1-25.3,11-25.3,25.4l0.3,76
                    c0.1,14.5,11,25.3,25.4,25.3C305.1,659.3,315.9,648.4,315.9,633.9z"
        />
        <Path
          fill={this.props.color || Styles.primaryColor}
          d="M174.7,634.4l-0.3-76c-0.1-14.5-11-25.3-25.4-25.3c-14.5,0.1-25.3,11-25.3,25.4l0.3,76
                    c0.1,14.5,11,25.3,25.4,25.3C163.9,659.8,174.7,648.9,174.7,634.4z"
        />
        <Path
          fill={this.props.color || Styles.primaryColor}
          d="M243.6,663.1l-0.4-105c-0.1-14.5-11-25.3-25.4-25.3c-14.5,0.1-25.3,11-25.3,25.4l0.4,105
                    c0.1,14.5,11,25.3,25.4,25.3C232.8,688.5,243.6,677.6,243.6,663.1z"
        />
      </Svg>
    );
  }
}
