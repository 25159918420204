import React from 'react';
import {StyleSheet} from 'react-native';
import {TextInput, HelperText} from 'react-native-paper';
import Styles from '../Styles';

type AVTextInputProps = React.ComponentProps<typeof TextInput> & {
  placeholder?: string;
  children?: React.ReactNode;
  title?: string;
};

class AVTextInput extends React.Component<AVTextInputProps> {
  _textInput: any;

  focus() {
    if (this._textInput) {
      this._textInput.focus();
    }
  }

  clear() {
    if (this._textInput) {
      this._textInput.clear();
    }
  }

  render() {
    const {style, title, ...rest} = this.props;
    const input = (
      <TextInput
        ref={(textInput) => {
          this._textInput = textInput;
        }}
        underlineColorAndroid="transparent"
        style={[Styles.Style.font500, styles.text, style]}
        {...rest}
      >
        {this.props.children}
      </TextInput>
    );

    if (!title) {
      return input;
    } else {
      return (
        <>
          {input}
          <HelperText accessibilityLabel={title} padding="none" type="info">
            {title}
          </HelperText>
        </>
      );
    }
  }
}

const styles = StyleSheet.create({
  text: {
    paddingHorizontal: 0,
    backgroundColor: Styles.white,
  },
});
export default AVTextInput;
