const buildTransform = (
  animationRange,
  startX,
  startY,
  startHeight,
  startWidth,
  endX,
  endY,
  scaleTo,
  includeRotate = false,
) => {
  const complementScaling = 1 - scaleTo;
  const verticalScalingDiff = (complementScaling * startHeight) / 2;
  const horizontalScalingDiff = (complementScaling * startWidth) / 2;
  const transform: any = {
    transform: [
      {
        translateY: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -(startY - endY) - verticalScalingDiff],
        }),
      },
      {
        translateX: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -(startX - endX) - horizontalScalingDiff],
        }),
      },
      {
        scale: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [1, scaleTo],
        }),
      },
    ],
  };

  if (includeRotate) {
    transform.transform.push({
      rotate: animationRange.interpolate({
        inputRange: [0, 1],
        outputRange: ['45deg', '0deg'],
      }),
    });
  }

  return transform;
};

export default buildTransform;
