import {DefaultTheme} from 'react-native-paper';
import Styles from './Styles';
export const PaperTheme = {
  ...DefaultTheme,
  dark: true,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: Styles.primaryColor,
    accent: Styles.secondaryColor,
    background: Styles.white,
  },
};
