import * as React from 'react';
import Styles from '../Styles';
import {View, ActivityIndicator, StyleSheet} from 'react-native';
type SpinnerProps = JSX.LibraryManagedAttributes<
  typeof ActivityIndicator,
  React.ComponentProps<typeof ActivityIndicator>
>;

const Spinner = (props: SpinnerProps) => (
  <View style={styles.container}>
    <ActivityIndicator
      animating
      color={Styles.primaryColor}
      size="large"
      {...props}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m1,
    padding: Styles.Spacing.m3,
  },
});
export default Spinner;
