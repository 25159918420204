import React from 'react';
import {TouchableOpacity} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';

type ButtonProps = JSX.LibraryManagedAttributes<
  typeof TouchableOpacity,
  React.ComponentProps<typeof TouchableOpacity>
> & {
  activeOpacity?: number;
  overrideStyles?: any;
  overrideTextStyles?: any;
  accessibilityLabel?: string;
  text: React.ReactNode;
};

const Button = (props: ButtonProps) => (
  <AVTouchableOpacity style={Styles.btn(props.overrideStyles)} {...props}>
    <AVText
      style={Styles.btnText(props.overrideTextStyles)}
      accessibilityLabel={props.accessibilityLabel}
      numberOfLines={1}
    >
      {props.text}
    </AVText>
  </AVTouchableOpacity>
);

Button.defaultProps = {
  disabled: false,
};

export default Button;
