export const allowedCardNetworks: google.payments.api.CardNetwork[] = [
  'AMEX',
  'MASTERCARD',
  'VISA',
];

export const allowedAuthMethods: google.payments.api.CardAuthMethod[] = [
  'PAN_ONLY',
  'CRYPTOGRAM_3DS',
];

export const apiVersion = 2;
export const apiVersionMinor = 0;
export const merchantId = '18167626495208014114';
export const merchantName = '365 Retail Markets, LLC';
