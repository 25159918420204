import React, {useContext, useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {useFocusEffect} from '@react-navigation/native';
import BaseScreen from '../BaseScreen';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import ScanditView from 'src/components/elements/ScanditView';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import ActionsFactory from 'src/actions/ActionsFactory';
import Events from 'src/logging/Events';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import {ScreenProps} from 'src/types/Screen';
import ScreenContext from 'src/components/ScreenContext';
import {Identifier} from 'src/types/serverTypes/Account';
import AccountStore from 'src/stores/AccountStore';
import {LocationType} from 'src/types/Location';
import CartTypes from 'src/constants/cart/CartTypes';
import AccountConstants from 'src/constants/AccountConstants';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ScanDiscountCodeScreenProps = {
  locationId: string;
  cartType: CartTypes;
  consumerRoleIdentifier: Identifier;
  addDiscountToCart: () => void;
  location?: LocationType;
};

const ScanDiscountCodeScreen = ({
  locationId,
  consumerRoleIdentifier,
  addDiscountToCart,
  cartType,
  location,
}: ScanDiscountCodeScreenProps) => {
  const {actions}: ScreenProps = useContext(ScreenContext);
  const [scanning, setScanning] = useState(true);
  const [roleIdentifier, setRoleIdentifier] = useState(
    consumerRoleIdentifier ?? AccountStore.getConsumerIdentifier(),
  );

  useEffect(() => {
    FirebaseAnalytic.trackEvent('useEffect', 'ScanDiscountCodeScreen', {
      locationId,
      consumerRoleIdentifier,
      addDiscountToCart,
      cartType,
      location,
    });

    AccountStore.addChangeListener(onAccountStoreChanged);
    return () => {
      AccountStore.removeChangeListener(onAccountStoreChanged);
    };
  }, []);

  const onAccountStoreChanged = () => {
    FirebaseAnalytic.trackEvent('useEffect', 'ScanDiscountCodeScreen', {
      locationId,
      consumerRoleIdentifier,
      addDiscountToCart,
      cartType,
      location,
      roleIdentifier: AccountStore.getConsumerIdentifier(),
    });

    setRoleIdentifier(AccountStore.getConsumerIdentifier());
  };

  useFocusEffect(
    React.useCallback(() => {
      setScanning(true);
      return () => {
        setScanning(false);
      };
    }, []),
  );

  const handleManualEntry = () => {
    setScanning(false);
    FirebaseAnalytic.trackEvent('handleManualEntry', 'ScanDiscountCodeScreen', {
      locationId,
      consumerRoleIdentifier,
      addDiscountToCart,
      cartType,
      location,
      navigate: AppRoutes.EnterDiscountCode,
    });

    NavActions.navigate(AppRoutes.EnterDiscountCode, {
      locationId,
      roleIdentifier,
      addDiscountToCart,
      cartType,
      location,
    });
  };

  const handleDiscountCodeScanned = async (scanCode: string) => {
    setScanning(false);
    actions.showSpinner();

    FirebaseAnalytic.trackEvent(
      'handleDiscountCodeScanned',
      'ScanDiscountCodeScreen',
      {
        locationId,
        consumerRoleIdentifier,
        addDiscountToCart,
        cartType,
        location,
        scanCode,
      },
    );

    try {
      const discountCodeResponse =
        await ActionsFactory.getAccountActions().validateDiscountCode(
          locationId,
          scanCode,
        );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'ValidateDiscountCode',
        JSON.stringify({locationId: locationId}),
        JSON.stringify(discountCodeResponse),
      );
      if (discountCodeResponse?.statusCode === 404) {
        throw discountCodeResponse;
      }
      if (roleIdentifier?.value) {
        const response =
          await ActionsFactory.getAccountActions().deleteAssociatedDiscountCode(
            roleIdentifier?.accountIdentifierId,
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'DeleteAssociatedDiscountCode',
          JSON.stringify({
            accountIdentifierId: roleIdentifier?.accountIdentifierId,
          }),
          JSON.stringify(response),
        );
      }
      const response =
        await ActionsFactory.getAccountActions().associateDiscountCode(
          scanCode,
        );

      FirebaseAnalytic.trackEvent(
        'handleDiscountCodeScanned associateDiscountCode',
        'ScanDiscountCodeScreen',
        {
          locationId,
          consumerRoleIdentifier,
          addDiscountToCart,
          cartType,
          location,
          response,
          scanCode,
        },
      );

      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      }),
        alertSuccess(
          Localized.Success.any_applicable_promotions_will_now_apply,
          () => {
            NavActions.navigate(AppRoutes.Scan, {
              locationId,
              cartType,
              marketName: location.name,
              locationType: AccountConstants.SOS_LOCATION_TYPE,
              beaconId: location.beaconId ?? location.locationId,
              location: location,
              discountCode: scanCode,
            }),
              addDiscountToCart();
          },
          Localized.Success.discount_recognized,
        );
    } catch (error) {
      alertError(
        Localized.Errors.we_couldnt_recognize_that_code,
        null,
        () => {
          setScanning(true),
            ActionsFactory.getAccountActions().reloadConsumerData({
              accountId: AccountStore.getAccountId(),
              accountBalanceId: AccountStore.getAccountBalanceId(),
              email: AccountStore.getEmail(),
              userInitiated: true,
            });
        },
        Localized.Errors.sorry,
      );
      CrashlyticsEvents.log(
        'Exception',
        'ScanDiscountCodeScreen:handleDiscountCodeScanned',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanDiscountCodeScreen:handleDiscountCodeScanned',
        error.message ? error.message : error.toString(),
      );
    } finally {
      actions.hideSpinner();
    }
  };

  return (
    <BaseScreen title={Localized.Labels.add_discount}>
      <AVText
        accessible={true}
        accessibilityLabel={Localized.Labels.scan_your_discount_code}
        aria-label={Localized.Labels.scan_your_discount_code}
        style={styles.descriptionText}
      >
        {Localized.Labels.scan_your_discount_code}
      </AVText>
      {scanning ? (
        <ScanditView
          accessible={true}
          accessibilityLabel="Scanning viewfinder is active"
          aria-label="Scanning viewfinder is active"
          barcodeScanned={handleDiscountCodeScanned}
          style={styles.scanditContainer}
          scanning={scanning}
        />
      ) : null}
      <RoundedButton
        containerStyle={styles.btnManualEntry}
        buttonType={ButtonType.outline}
        onPress={handleManualEntry}
        accessible={true}
        accessibilityLabel={Localized.Labels.manual_entry}
        accessibilityHint="Double tap to enter your discount code manually on the next screen"
        aria-label={Localized.Labels.manual_entry}
        text={Localized.Labels.manual_entry}
      />
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  descriptionText: {
    marginTop: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f2,
  },
  scanditContainer: {
    marginTop: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
    marginHorizontal: Styles.Spacing.m3,
    aspectRatio: 1,
  },
  btnManualEntry: {
    alignSelf: 'flex-end',
    marginTop: Styles.Spacing.m3,
    marginRight: Styles.Spacing.m3,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(ScanDiscountCodeScreen),
);
