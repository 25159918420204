import Mailer from 'react-native-mail';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class SupportEmailService {
  async createEmail(
    subject: string,
    message: string,
    callback: (email: string, guid?: string | null | undefined) => void,
  ) {
    const osName = await DeviceInfo.getSystemName();
    const appVersion = await DeviceInfo.getVersion();
    const osVersion = await DeviceInfo.getSystemVersion();
    const deviceType = await DeviceInfo.getModel();
    const email = AccountStore.getEmail();
    const location = AccountStore.getLocationName();
    const org = AccountStore.getOrgName();

    try {
      let body = message;
      body += '\n\n\n\n-------------------------\n';
      body += `OS Name: ${osName}\n`;
      body += `OS Version: ${osVersion}\n`;
      body += `Device Type: ${deviceType}\n`;
      body += `App Version: ${appVersion}\n`;

      if (email) {
        body += `Email: ${email}\n`;
      }

      if (location) {
        body += `Location: ${location}\n`;
      }

      if (org) {
        body += `Org: ${org}\n`;
      }

      body += '-------------------------\n';
      Mailer.mail(
        {
          subject,
          recipients: [Settings.supportEmail],
          body,
        },
        (error) => {
          if (error) {
            callback(Settings.supportEmail);
          }
        },
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'SupportEmailService:CreateEmail',
        error.message ? error.message : error.toString(),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'SupportEmailService:CreateEmail',
        error.message ? error.message : error.toString(),
        guid,
      );
      callback(Settings.supportEmail, guid);
    }
  }
}

export default new SupportEmailService();
