import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import UIManager from 'src/components/elements/ui/UIManager';
const TabRoutes = [
  {
    key: '0',
    label: Localized.Labels.home,
    isActive: false,
    onPress: () =>
      NavActions.navigate(AppRoutes.Home, {
        screen: AppRoutes.Promotions,
      }),
  },
  {
    key: '1',
    label: UIManager.getShopLabel(),
    isActive: false,
    onPress: () =>
      NavActions.navigate(AppRoutes.Shop, {
        screen: AppRoutes.Machines,
      }),
  },
  {
    key: '3',
    label: UIManager.getInboxLabel(),
    isActive: false,
    onPress: () =>
      NavActions.navigate(AppRoutes.Inbox, {
        screen: AppRoutes.Inbox,
      }),
  },
  {
    key: '4',
    label: Localized.Labels.settings,
    isActive: false,
    onPress: () =>
      NavActions.navigate(AppRoutes.Settings, {
        screen: AppRoutes.Account,
      }),
  },
];
export default TabRoutes;
