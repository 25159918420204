import React, {useEffect, useRef, useState} from 'react';
import {
  Text,
  TextInput,
  StyleSheet,
  View,
  Animated,
  Easing,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
  AccessibilityState,
  AccessibilityValue,
} from 'react-native';
import Styles from 'src/components/Styles';
import Icon from 'react-native-vector-icons/FontAwesome';

type Props = React.ComponentProps<typeof TextInput> & {
  label: string;
  value: string;
  usedInDropdowns?: boolean;
  useHalfSize?: boolean;
  hasError?: boolean;
  errorMessage?: string | null;
  placeholder?: string;
  multiline?: boolean;
  accessible?: boolean;
  accessibilityLabel?: string;
  maxFontSizeMultiplier?: number;
  accessibilityValue?: AccessibilityValue;
  accessibilityState?: AccessibilityState;
  secureTextEntry?: boolean;
  maxLength?: number;
  hasIcon?: boolean;
  iconStyle?: StyleProp<ViewStyle>;
  iconName?: string;
  iconHeight?: number;
  iconLabel?: string;
  hasInlineImage?: boolean;
  imageElement?: React.ReactElement;
  onChangeText?: (value: string) => void;
  iconPressed?: () => void;
  showHelperText?: boolean;
  helperText?: string;
  disabled?: boolean;
  testID?: string;
  iconContainerStyle?: StyleProp<ViewStyle>;
};

const AllyTextInput: React.FC<Props> = (props) => {
  const {
    label,
    hasError,
    errorMessage,
    value,
    onBlur,
    onFocus,
    usedInDropdowns = false,
    useHalfSize = false,
    ...restOfProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<TextInput>(null);
  const focusAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: isFocused || !!value ? 1 : 0,
      duration: 150,
      easing: Easing.bezier(0.4, 0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  }, [focusAnim, isFocused, value]);

  let color = isFocused ? Styles.primaryColor : Styles.lightGray;
  if (hasError) {
    color = Styles.dangerColor;
  }

  return (
    <View style={{marginTop: Styles.Spacing.m3}}>
      <TextInput
        multiline={props?.multiline}
        accessible={true}
        accessibilityLabel={props?.accessibilityLabel}
        maxFontSizeMultiplier={props?.maxFontSizeMultiplier}
        accessibilityLabelledBy={props?.nativeID}
        accessibilityValue={{text: props?.value}}
        aria-label={props?.accessibilityLabel}
        aria-labelledby={props?.nativeID}
        aria-valuetext={props?.value}
        style={[
          styles.input,
          {
            borderColor: color,
            color: isFocused ? Styles.primaryColor : Styles.darkColor,
            backgroundColor:
              isFocused || value ? Styles.white : Styles.inputBgColor,
            height: props?.multiline ? 55 : null,
          },
        ]}
        onChangeText={props.onChangeText}
        ref={inputRef}
        {...restOfProps}
        value={value}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus?.(event);
        }}
        editable={props.editable}
      />
      {props.hasIcon && (
        <TouchableWithoutFeedback
          accessible={true}
          accessibilityLabel={props.iconLabel}
          aria-label={props.iconLabel}
          accessibilityRole="button"
          role="button"
          onPress={props.iconPressed}
          style={styles.iconContainer}
        >
          <Icon
            style={styles.icon}
            name={props.iconName}
            size={28}
            color={isFocused ? Styles.primaryColor : Styles.darkColor}
          />
        </TouchableWithoutFeedback>
      )}
      {props?.hasInlineImage && (
        <View style={styles.imageContainer}>{props?.imageElement}</View>
      )}
      <TouchableWithoutFeedback onPress={() => inputRef.current?.focus()}>
        <Animated.View
          style={[
            styles.labelContainer,
            {
              backgroundColor:
                isFocused || value ? Styles.white : Styles.inputBgColor,
              transform: [
                {
                  scale: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0.75],
                  }),
                },
                {
                  translateY: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [20, -12],
                  }),
                },
                {
                  translateX: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [16, 0],
                  }),
                },
              ],
            },
          ]}
        >
          <Text
            nativeID={props?.nativeID}
            accessibilityLabel={`${props?.accessibilityLabel} label`}
            accessibilityRole="text"
            aria-label={`${props?.accessibilityLabel} label, text`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            style={[
              styles.label,
              {
                color,
              },
            ]}
          >
            {label}
            {hasError ? '*' : ''}
          </Text>
        </Animated.View>
      </TouchableWithoutFeedback>
      {hasError && errorMessage !== null && (
        <Text
          accessible={true}
          accessibilityLabel={`Error: ${errorMessage}`}
          accessibilityRole="text"
          aria-label={`Error: ${errorMessage}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={styles.error}
        >
          {errorMessage}
        </Text>
      )}
      {(!hasError || errorMessage === null) &&
        props.showHelperText &&
        props.helperText && (
          <Text
            accessible={true}
            accessibilityLabel={props.helperText}
            accessibilityRole="text"
            aria-label={`${props.helperText}, text`}
            style={styles.help}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          >
            {props.helperText}
          </Text>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    padding: 16,
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 5,
    backgroundColor: Styles.inputBgColor,
  },
  iconContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: 20,
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    bottom: 50,
    right: 10,
  },
  icon: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    width: 50,
    height: 50,
    bottom: 0,
  },
  labelContainer: {
    position: 'absolute',
    paddingHorizontal: 8,
    backgroundColor: Styles.white,
  },
  label: {
    fontSize: 14,
    color: Styles.lightGray,
  },
  error: {
    marginTop: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m1,
    fontSize: 12,
    color: Styles.dangerColor,
  },
  help: {
    marginTop: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m1,
    fontSize: 12,
    color: Styles.lightGray,
  },
});

export default AllyTextInput;
