import React from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  InteractionManager,
  RefreshControl,
  Platform,
  Modal as ModalNative,
  Text,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import MainConsumerContext from '../../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import BackSubheader from '../../elements/BackSubheader';
import CreditCardImage from '../../elements/CreditCardImage';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {CCModel} from 'src/types/CCModel';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import AccountConstants from 'src/constants/AccountConstants';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import CreditCard from 'src/models/CreditCard';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {fetchAccountBalanceCreditCards} from 'src/redux/slices/accountSlice';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {
  initStripe,
  CardForm,
  createPaymentMethod,
} from 'src/nativeModules/Stripe';
import NoCardAvailable from './NoCardAvailable';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Star from 'src/components/img/svg/inbox/Star';
import moment from 'moment';
import {WithLocalizedProps} from 'src/types/PropTypes';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {Modal as ModalPaper} from 'react-native-paper';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type CardsScreenProps = IsConnectedProps & {
  payrollId: string;
  dispatch: AppDispatch;
  accountId: string;
  defaultBalanceId: string;
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  navigation: NavigationProp<CardsScreen>;
};

type CardsScreenState = {
  showPayroll: boolean;
  payrollPending: boolean;
  isRefreshing: boolean;
  optionVisible: boolean;
  loading: boolean;
  previousRoute: string;
};

class CardsScreen extends React.Component<CardsScreenProps, CardsScreenState> {
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: CardsScreenProps) {
    super(props);
    this.state = {
      showPayroll: AccountStore.getShowPayroll(),
      payrollPending: !props.payrollId,
      isRefreshing: false,
      optionVisible: false,
      loading: false,
      previousRoute: '',
    };
    this._onRefresh = this._onRefresh.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.renderCreditCard = this.renderCreditCard.bind(this);
    this.onCreditCardClick = this.onCreditCardClick.bind(this);
    this.renderPayroll = this.renderPayroll.bind(this);
    this.onPayrollClick = this.onPayrollClick.bind(this);
    this.addCardStripe = this.addCardStripe.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getTokenCard = this.getTokenCard.bind(this);
    this.renderAddCardModal = this.renderAddCardModal.bind(this);
  }

  componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});

    InteractionManager.runAfterInteractions(() => {
      AccountStore.addCreditCardAddedListener(this.onCreditCardAddedOrDeleted);
      AccountStore.addCreditCardDeletedListener(
        this.onCreditCardAddedOrDeleted,
      );
      AccountStore.addDefaultTokenUpdatedListener(
        this.onCreditCardAddedOrDeleted,
      );
    });
  }

  componentWillUnmount() {
    AccountStore.removeCreditCardAddedListener(this.onCreditCardAddedOrDeleted);
    AccountStore.removeCreditCardDeletedListener(
      this.onCreditCardAddedOrDeleted,
    );
    AccountStore.removeDefaultTokenUpdatedListener(
      this.onCreditCardAddedOrDeleted,
    );
  }

  onCreditCardAddedOrDeleted = async (params?: any) => {
    const {accountId, defaultBalanceId} = this.props;
    await this.props.dispatch(
      fetchAccountBalanceCreditCards({
        accountId,
        accountBalanceId: defaultBalanceId,
      }),
    );
    if (params?.isDefault && this.props.creditCards.length > 0) {
      let recentCard: CreditCard = this.props.creditCards[0];
      this.props.creditCards.forEach((item) => {
        if (moment(item.dateCreated).isAfter(moment(recentCard.dateCreated))) {
          recentCard = item;
        }
      });
      try {
        await ActionsFactory.getAccountActions().updateDefaultToken(
          accountId || '',
          recentCard.accountBalanceId || '',
          recentCard.id || '',
        );
        FirebaseAnalytic.trackEvent(
          'onCreditCardAddedOrDeleted',
          'CreditCardScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardsScreen:OnCreditCardAddedOrDeleted',
          error.message ? error.message : error.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardsScreen:OnCreditCardAddedOrDeleted',
          error.message ? error.message : error.toString(),
        );
      }
    }
  };

  goToAddEGiftCard() {
    if (Platform.OS === 'web') {
      NavActions.navigate(AppRoutes.AddEGiftCardManual);
    } else {
      NavActions.navigate(AppRoutes.AddEGiftCard);
    }
  }

  onClose() {
    this.setState({optionVisible: false});
  }

  onPayrollClick() {
    NavActions.push(AppRoutes.PayrollDeduct);
  }

  onAddClicked() {
    if (
      this.props.paymentCredentials?.type === Settings.processorsAsString.stripe
    ) {
      if (Platform.OS === 'web') {
        NavActions.push(AppRoutes.CreditCardStripe);
      } else {
        this.setState({optionVisible: true});
      }
    } else {
      NavActions.push(AppRoutes.CreditCard);
    }
  }

  async _onRefresh() {
    this.setState({
      isRefreshing: true,
    });

    await this.props.dispatch(
      fetchAccountBalanceCreditCards({
        accountId: this.props.accountId,
        accountBalanceId: this.props.defaultBalanceId,
      }),
    );

    this.setState({
      isRefreshing: false,
    });
  }

  async getTokenCard() {
    try {
      const {paymentMethod: tokenCard, error: errorCard} =
        await createPaymentMethod({
          paymentMethodType: 'Card',
        });
      if (errorCard) {
        this.setState({loading: false});
        return alertError(
          Localized.Errors.error_adding_credit_card,
          errorCard.message,
        );
      }
      return tokenCard;
    } catch (error) {
      // This is when the user cancels out of the screen. Nothing to do here.
      return;
    }
  }

  async addCardStripe() {
    this.setState({loading: true});
    await initStripe({
      publishableKey: this.props.paymentCredentials?.key,
    });
    let token: any = {};
    try {
      const response =
        await ActionsFactory.getAccountActions().createSetupIntent(
          AccountStore.getAccountId(),
        );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'CreateSetupIntent',
        JSON.stringify({accountId: AccountStore.getAccountId()}),
        JSON.stringify(response),
      );

      if (response && response?.clientSecret) {
        token = await this.getTokenCard();
        if (token) {
          const ccModel: CCModel = {
            CardNumberMask: `x${token.Card.last4}`,
            Token: token.id,
            Provider: Settings.processors.stripe,
            AccountId: AccountStore.getAccountId(),
            BillingZip: token.billingDetails.address.postalCode,
            CardNumber: '',
            CvvCode: '',
            ExpirationMonth: token.Card.expMonth,
            ExpirationYear: token.Card.expYear,
            Issuer: token.Card.brand,
          };
          ActionsFactory.getAccountActions()
            .addCreditCardTokenStripe(ccModel)
            .catch(async (error) => {
              const guid = await uuid.getRandomUUID();
              CrashlyticsEvents.log(
                'Exception',
                'CreditCardsScreen:AddCardStripe',
                error.message ? error.message : error.toString(),
                guid,
              );
              Events.Error.trackEvent(
                'Exception',
                'CreditCardsScreen:AddCardStripe',
                error.message ? error.message : error.toString(),
                guid,
              );
              alertError(Localized.Errors.error_adding_credit_card, guid);
            })
            .finally(() =>
              this.setState({loading: false, optionVisible: false}),
            );
        }
      } else {
        alertError(Localized.Errors.error_adding_credit_card);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'CreditCardsScreen:AddCardStripe',
        error.message ? error.message : error.toString(),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'CreditCardsScreen:AddCardStripe',
        error.message ? error.message : error.toString(),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'CreateSetupIntent',
        JSON.stringify({accountId: AccountStore.getAccountId()}),
        JSON.stringify(error),
      );
      alertError(Localized.Errors.error_adding_credit_card, guid);
    }
  }

  onCreditCardClick(creditCard: CreditCard) {
    NavActions.push(AppRoutes.CreditCardDetail, {
      creditCard,
    });
  }

  renderCreditCard({item}: {item: CreditCard}) {
    return (
      <CreditCardCell
        key={item.id}
        strings={Localized}
        creditCard={item}
        onCreditCardClick={this.onCreditCardClick}
      />
    );
  }

  renderPayroll() {
    const footerComp: Array<JSX.Element> = [];

    if (!this.props.creditCards || this.props.creditCards.length === 0) {
      footerComp.push(
        <NoCardAvailable
          title={Localized.Labels.no_cards_available}
          desc={Localized.Labels.add_card_to_fund_account}
          onPress={this.onAddClicked}
        />,
      );
    }

    if (this.state.showPayroll) {
      const chevron = (
        <FontAwesome5Pro
          accessibilityLabel="View payroll information"
          aria-label="View payroll information"
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      );
      let rightView;
      let additionalText;

      if (this.state.payrollPending) {
        additionalText = (
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessibilityLabel="Payroll pending"
            accessibilityRole="text"
            aria-label={`${'Payroll pending'}, text`}
            style={[styles.cardNumber]}
          >
            {Localized.Labels.pending}
          </AVText>
        );
        rightView = (
          <View style={styles.pendingView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessibilityLabel={Localized.Labels.pending_upper_case}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.pending_upper_case}, text`}
              style={[styles.cardNumber, styles.pending]}
            >
              {Localized.Labels.pending_upper_case}
            </AVText>
            {chevron}
          </View>
        );
      } else {
        const last4 = this.props.payrollId.replace(/\d(?=\d{4})/g, 'x');
        additionalText = (
          <AVText
            accessibilityLabel={Localized.Labels.last_four_of_card}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.last_four_of_card}, text`}
            style={[styles.cardNumber]}
          >
            {last4}
          </AVText>
        );
        rightView = chevron;
      }

      footerComp.push(
        <TouchableOpacity
          accessible={true}
          onPress={this.onPayrollClick}
          style={styles.creditCard}
          accessibilityLabel="payrollDeductButton"
          aria-label="payrollDeductButton"
          role="button"
        >
          <View style={styles.creditCardInfo}>
            <View
              style={{
                marginRight: Styles.Spacing.m3,
                marginLeft: Styles.Spacing.m1,
              }}
              accessibilityLabel="Payroll image"
              accessibilityRole="image"
              aria-label="Payroll image"
              role="img"
            >
              <CreditCardImage
                issuer={AccountConstants.PAYROLL_TOKEN.toString()}
              />
            </View>
            <View>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                accessibilityLabel={Localized.Labels.payroll_deduct}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.payroll_deduct}, text`}
                style={styles.label}
              >
                {Localized.Labels.payroll_deduct}
              </AVText>
              {additionalText}
            </View>
          </View>
          {rightView}
        </TouchableOpacity>,
      );
    }

    return <>{footerComp}</>;
  }

  renderAddCardModal = () => {
    return (
      <View style={styles.modalContainer}>
        <View style={styles.subModalContainer}>
          <View style={styles.titleContainer}>
            <TouchableOpacity
              accessible={true}
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
              aria-label={Localized.Buttons.cancel}
              role="button"
              onPress={this.onClose}
            >
              <Text style={styles.cancelText}>{Localized.Buttons.cancel}</Text>
            </TouchableOpacity>

            <Text
              accessible={true}
              accessibilityLabel={Localized.Labels.add_card}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.add_card}, text`}
              style={styles.boldText}
            >
              {Localized.Labels.add_card}
            </Text>
            {this.state.loading ? (
              <View
                accessible={true}
                accessibilityState={{busy: this.state.loading}}
                aria-busy={this.state.loading}
              >
                <ActivityIndicator />
              </View>
            ) : (
              <TouchableOpacity
                accessible={true}
                accessibilityLabel={Localized.Buttons.done}
                accessibilityRole="button"
                accessibilityHint="Double tap to finish adding a new credit card"
                aria-label={Localized.Buttons.done}
                role="button"
                onPress={this.addCardStripe}
              >
                <Text style={styles.boldText}>{Localized.Buttons.done}</Text>
              </TouchableOpacity>
            )}
          </View>
          <ScrollView>
            <CardForm style={styles.cardForm} cardStyle={styles.cardStyle} />
          </ScrollView>
        </View>
      </View>
    );
  };

  render() {
    return (
      <>
        <BackSubheader
          title={Localized.Labels.payment}
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        >
          <FlatList
            data={this.props.creditCards}
            renderItem={this.renderCreditCard}
            automaticallyAdjustContentInsets={false}
            ListFooterComponent={this.renderPayroll}
            extraData={this.state}
            keyExtractor={(item) => item.id}
            refreshControl={
              <RefreshControl
                refreshing={this.state.isRefreshing}
                onRefresh={() => this._onRefresh()}
                tintColor={Styles.primaryColor}
                colors={[Styles.primaryColor]}
                titleColor={Styles.black}
              />
            }
          />
          <View style={styles.addView}>
            <RoundedButton
              accessible={true}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              accessibilityLabel={Localized.Labels.add_egift_card}
              accessibilityHint="Double tap to add egift card using your device's camera"
              accessibilityRole="button"
              aria-label={Localized.Labels.add_egift_card}
              role="button"
              containerStyle={styles.addBtn}
              buttonType={ButtonType.outline}
              text={Localized.Labels.add_egift_card}
              onPress={this.goToAddEGiftCard}
            />
            <RoundedButton
              accessible={true}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              accessibilityLabel={Localized.Labels.add_payment_card}
              accessibilityHint="Double tap to add new payment card"
              accessibilityRole="button"
              aria-label={Localized.Labels.add_payment_card}
              role="button"
              containerStyle={styles.addBtn}
              buttonType={ButtonType.outline}
              text={Localized.Labels.add_payment_card}
              onPress={this.onAddClicked}
            />
          </View>
        </BackSubheader>

        <View style={this.state.optionVisible && styles.blurBackground} />

        {Platform.OS === 'ios' ? (
          <ModalNative visible={this.state.optionVisible} style={styles.modal}>
            <KeyboardAvoidingView
              behavior={'padding'}
              style={styles.safeAreaView}
            >
              {this.renderAddCardModal()}
            </KeyboardAvoidingView>
          </ModalNative>
        ) : (
          <ModalPaper visible={this.state.optionVisible} style={styles.modal}>
            {this.renderAddCardModal()}
          </ModalPaper>
        )}
      </>
    );
  }
}

type CreditCardCellProps = WithLocalizedProps & {
  onCreditCardClick: (creditCard) => void;
  creditCard: CreditCard;
};

const CreditCardCell: React.FC<CreditCardCellProps> = ({
  onCreditCardClick,
  creditCard,
  strings,
}) => (
  <AVTouchableOpacity
    accessible={true}
    accessibilityRole="button"
    accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
    role="button"
    onPress={() => onCreditCardClick(creditCard)}
    accessibilityLabel="onCreditCardClick"
    aria-label="onCreditCardClick"
  >
    <View style={styles.creditCard}>
      <View style={styles.creditCardInfo}>
        <View
          accessibilityLabel={`Issuer ${creditCard.cardIssuer}`}
          accessibilityRole="image"
          aria-label={`Issuer ${creditCard.cardIssuer}`}
          role="img"
          style={{
            marginRight: Styles.Spacing.m3,
            marginLeft: Styles.Spacing.m1,
          }}
        >
          <CreditCardImage issuer={creditCard.cardIssuer} />
        </View>
        <View>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={strings.Labels.credit_card}
            accessibilityRole="text"
            aria-label={`${strings.Labels.credit_card}, text`}
            style={styles.label}
          >
            {strings.Labels.credit_card}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={creditCard.name}
            accessibilityRole="text"
            aria-label={`${creditCard.name}, text`}
            style={styles.cardNumber}
          >
            {creditCard.name}
          </AVText>
        </View>
      </View>
      <View style={styles.starIconView}>
        {creditCard.isDefault && (
          <View
            accessibilityLabel="Default payment method, "
            aria-label="Default payment method, "
            style={styles.starIcon}
          >
            <Star width={Styles.Fonts.f4} height={Styles.Fonts.f4} />
          </View>
        )}
        <FontAwesome5Pro
          accessibilityLabel="Double tap to edit payment method"
          aria-label="Double tap to edit payment method"
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </View>
    </View>
  </AVTouchableOpacity>
);

const textColor = Styles.lightGray;
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  btnStyle: {
    alignSelf: 'center',
  },
  safeAreaView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardNumber: {
    color: textColor,
    fontSize: Styles.Fonts.f1,
  },
  creditCard: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Styles.lightGray,
  },
  creditCardInfo: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  issuerImage: {
    alignSelf: 'flex-start',
    marginRight: Styles.Spacing.m2,
  },
  label: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  pending: {
    color: Styles.warningColor,
    marginRight: Styles.Spacing.m2,
  },
  pendingView: {
    flexDirection: 'row',
  },
  starIconView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  starIcon: {
    marginRight: Styles.Spacing.m2,
  },
  subLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  blurBackground: {
    position: 'absolute',
    top: Styles.Spacing.m0,
    bottom: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    right: Styles.Spacing.m0,
    backgroundColor: Styles.overlay,
  },
  modal: {
    flex: 1,
    borderRadius: Styles.Spacing.m2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: Styles.ScreenWidth / 1.1,
    height:
      Platform.OS === 'ios' ? Styles.ScreenHeight / 3 : Styles.ScreenHeight / 2,
    borderRadius: 10,
  },
  subModalContainer: {
    backgroundColor: Styles.addCardPopupColor,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelText: {
    color: Styles.primaryColor,
  },
  boldText: {
    fontWeight: 'bold',
    color: Styles.darkColor,
  },
  cardForm: {
    height: Styles.ScreenHeight / 2,
    marginTop: Styles.Spacing.m3,
  },
  cardStyle: {
    backgroundColor: Styles.addCardPopupColor,
  },
  addView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: Styles.Spacing.m3,
    marginLeft: Styles.Spacing.m2,
  },
  addViewWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: Styles.Spacing.m3,
  },
  addBtn: {
    marginRight: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m1,
    borderRadius: Styles.Spacing.m4,
    marginBottom: Styles.Spacing.m2,
  },
});

const ConnectedCardsScreen = connect(
  (state: RootState) => ({
    payrollId: state.account.account?.payrollIdentifier?.value,
    accountId: state.account.account.id,
    defaultBalanceId: state.account.account.defaultBalance?.id,
    paymentCredentials: state.account.paymentCredentials,
    creditCards: state.account.creditCards,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(CardsScreen);

export default withForwardedNavigationParams()(
  withIsConnected(ConnectedCardsScreen),
);
