import React from 'react';
import {StyleSheet, ScrollView, View, Text} from 'react-native';
import Autolink from 'react-native-autolink';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';

type FeedbackScreenProps = {
  feedback: string;
};

const FeedbackScreen = ({feedback}: FeedbackScreenProps) => (
  <BackSubheader title={Localized.Labels.feedback}>
    <ScrollView style={Styles.Style.flex}>
      <View style={styles.content}>
        <Text style={styles.headerText}>{Localized.Labels.have_feedback}</Text>
        <Autolink
          style={styles.infoText}
          text={feedback || Localized.Labels.feedback_info}
        />
      </View>
    </ScrollView>
  </BackSubheader>
);

const styles = StyleSheet.create({
  headerText: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
  },
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  infoText: {
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
});

export default withForwardedNavigationParams<FeedbackScreenProps>()(
  FeedbackScreen,
);
