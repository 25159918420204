import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

function ScanWhite({size = 640, color = '#fff'}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 640 640">
      <Path
        d="M0 100.29v113.05h73.85V100.29C73.85 88.73 81.9 80 92.57 80h104.35V0H94.11C42.64 0 0 44.53 0 100.29zM73.85 538.05V426.67H0v111.38C0 593.8 42.64 640 94.11 640h102.81v-81.67H92.57c-10.68 0-18.72-8.72-18.72-20.28zM566.15 538.05c0 11.56-8.05 20.28-18.73 20.28H443.07V640h104.35c51.47 0 92.57-46.2 92.57-101.95V426.67h-73.85v111.38zM547.04 0H443.07v80h103.41c10.67 0 19.66 8.72 19.66 20.29v113.05h73.85V100.29C639.99 44.53 598.5 0 547.03 0z"
        fill={color}
      />
    </Svg>
  );
}

export default ScanWhite;
