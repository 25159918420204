import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {useDispatch} from 'react-redux';
import Styles from 'src/components/Styles';
import BackSubheader from 'src/components/elements/BackSubheader';
import ScanditView from 'src/components/elements/ScanditView';
import {scanGiftCard} from 'src/redux/slices/accountSlice';
import AccountStore from 'src/stores/AccountStore';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError} from 'src/components/helpers/AlertHelper';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

function AddEGiftCard() {
  const dispatch = useDispatch();
  const [scanning, setScanning] = useState(true);
  return (
    <BackSubheader title={Localized.Labels.add_egift_card}>
      <View style={styles.scanCodeView}>
        <ScanditView
          barcodeScanned={(code) => {
            FirebaseAnalytic.trackEvent('barcodeScanned', 'AddEGiftCard', {
              code,
            });

            setScanning(false);
            if (code.indexOf('GMA:id:') !== -1) {
              dispatch(
                scanGiftCard({
                  code: code.replace('GMA:id:', ''),
                  callback: () =>
                    ActionsFactory.getAccountActions().reloadConsumerData({
                      accountId: AccountStore.getAccountId(),
                      accountBalanceId: AccountStore.getAccountBalanceId(),
                      email: AccountStore.getEmail(),
                      userInitiated: true,
                    }),
                }),
              );
            } else {
              alertError(Localized.Errors.error_scanning_qr_code, '', () => {
                setScanning(true);
              });
            }
          }}
          scanning={scanning}
          style={styles.scanCode}
        />
      </View>
    </BackSubheader>
  );
}

const styles = StyleSheet.create({
  scanCodeView: {
    flex: 1,
    backgroundColor: 'gray',
    margin: Styles.Spacing.m4,
    borderRadius: Styles.Spacing.m4,
    overflow: 'hidden',
  },
  scanCode: {flex: 1},
});

export default AddEGiftCard;
