import * as React from 'react';
import {PixelRatio, StyleSheet, View} from 'react-native';
import FontAwesome5Pro, {FontAwesomeIconType} from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import AVText from './AVText';
type IconWithBadgeProps = {
  iconName: FontAwesomeIconType;
  tintColor: string;
  badgeCount: number;
  size: number;
};

class IconWithBadge extends React.Component<IconWithBadgeProps> {
  render() {
    const icon = (
      <FontAwesome5Pro
        name={this.props.iconName}
        size={this.props.size}
        color={this.props.tintColor}
        solid
      />
    );

    if (!this.props.badgeCount) {
      return icon;
    }

    return (
      <View style={styles.container}>
        {icon}
        <View style={styles.badge}>
          <AVText style={styles.badgeText}>{this.props.badgeCount}</AVText>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    backgroundColor: Styles.dangerColor,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Fonts.f1 / 2),
    height: PixelRatio.roundToNearestPixel(Styles.Fonts.f1),
    justifyContent: 'center',
    position: 'absolute',
    right: -Styles.Spacing.m2 * 1.2,
    top: -Styles.Spacing.m1,
    width: PixelRatio.roundToNearestPixel(Styles.Fonts.f1),
    zIndex: 1,
  },
  badgeText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  container: {},
});
export default IconWithBadge;
