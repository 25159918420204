/**
 * Used to keep exclusive access to a single async `job`.
 * AsyncLock waits for the previous `job` to complete before running a new `job`.
 * ```
 * const lock = new AsyncLock();
 * lock.runExclusive(longAsyncTask1);
 * lock.runExclusive(longAsyncTask2);
 * ```
 * 1. longAsyncTask1 started
 * 2. longAsyncTask1 finished
 * 3. longAsyncTask2 started
 * 4. longAsyncTask2 finished
 */
export default class AsyncLock {
  private pendingJob: Promise<void> | undefined;

  public async runExclusive<T>(job: () => Promise<T>) {
    while (this.pendingJob !== undefined) {
      // wait for current `pendingJob`
      await this.pendingJob;
    }

    // this "thread" will have exclusive access so it is safe to start the `job`
    const jobPromise: Promise<T> = job();

    // assign a new `pendingJob` so another "thread" can't start
    this.pendingJob = jobPromise.then(() => {
      // clear `pendingJob` so another "thread" can start
      this.pendingJob = undefined;
    });

    // return `job` result, this can happen before another "thread" has started
    return jobPromise;
  }
}
