import React from 'react';
import {
  PlanogramInnerProduct,
  PlanogramProductType,
} from 'src/types/ProductType';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Styles from '../../Styles';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {StyleSheet, View, ScrollView, Dimensions} from 'react-native';
import PlanogramAction from 'src/actions/PlanogramActions';
import PlanogramStore from 'src/stores/PlanogramStore';
import Product from './Product';
import TextInput from '../AVTextInput';
import Tray from './Tray';
import {alertError} from '../../helpers/AlertHelper';
import {WithLocalizedProps} from 'src/types/PropTypes';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const {width} = Dimensions.get('window');
const PRODUCTS_PER_ROW = 3;
type ProductSearchScreenProps = WithLocalizedProps & {
  showSpinner: () => void;
  hideSpinner: () => void;
  deviceId: string;
  tagSelected: boolean;
  onConnect: () => void;
};
type ProductSearchScreenState = {
  products: any;
  searchFilter: string;
  trays: Array<TrayType>;
  planogramId: number;
};
type TrayType = {
  Products: Array<PlanogramProductType>;
};

class ProductSearchScreen extends React.Component<
  ProductSearchScreenProps,
  ProductSearchScreenState
> {
  static calculatedSize() {
    const size = (width - 32 - PRODUCTS_PER_ROW * 16) / PRODUCTS_PER_ROW;
    return {
      width: size,
      height: size,
      marginHorizontal: Styles.Spacing.m1,
    };
  }

  search: TextInput | null;

  constructor(props: ProductSearchScreenProps) {
    super(props);
    this.state = {
      products: PlanogramStore.getFilteredProducts(),
      searchFilter: '',
      trays: [],
      planogramId: -1,
    };
    this.onChange = this.onChange.bind(this);
    this.productClicked = this.productClicked.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderProductsInGroupsof = this.renderProductsInGroupsof.bind(this);
    this.renderPlanogram = this.renderPlanogram.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.planogramProductClicked = this.planogramProductClicked.bind(this);
  }

  componentDidMount() {
    PlanogramStore.addChangeListener(this.onChange);
    this.loadPlanogram();
  }

  componentWillUnmount() {
    PlanogramStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      products: PlanogramStore.getFilteredProducts(),
    });
    this.loadPlanogram();
  }

  handleSearchChange(value: string) {
    if (value.length === 0 || value.length >= 3) {
      PlanogramAction.filterEntered(value);
    }

    this.setState({
      searchFilter: value,
    });
  }

  productClicked(product: PlanogramInnerProduct) {
    NavActions.push(AppRoutes.ProductNutrition, {
      deviceId: this.props.deviceId,
      product,
      onConnect: this.props.onConnect,
    });
  }

  planogramProductClicked(product: PlanogramProductType) {
    this.productClicked(product.Product);
  }

  async loadPlanogram() {
    try {
      const planogram = await PlanogramStore.getPlanogramAsync(
        this.props.deviceId,
      );

      if (planogram && planogram.Trays && planogram.Trays.length > 0) {
        this.setState({
          trays: planogram.Trays,
          planogramId: planogram.Id,
        });
      } else if (planogram) {
        // don't show this message if we just haven't fetched the planogram yet.
        alertError(this.props.strings.Errors.nutrition_info_not_available);
        NavActions.pop();
      }
    } catch (err) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductSearch:LoadPlanogram',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductSearch:LoadPlanogram',
        err.message ? err.message : err.toString(),
      );
    }
  }

  renderRow(products: Array<PlanogramInnerProduct>): React.ReactNode {
    const size = ProductSearchScreen.calculatedSize();
    const productSize = {
      width: size.width - 10,
      height: size.height - 10,
    };
    return products.map((innerProduct: PlanogramInnerProduct) => (
      <Product
        style={size}
        productStyle={productSize}
        productClicked={() => this.productClicked(innerProduct)}
        product={innerProduct}
        key={innerProduct.Id}
      />
    ));
  }

  renderProductsInGroupsof(count: number) {
    let i;
    let j;
    let k = 0;
    let temparray;
    const chunk = count;
    const groups: JSX.Element[] = [];

    if (this.state.products && this.state.products.length > 0) {
      for (i = 0, j = this.state.products.length; i < j; i += chunk) {
        temparray = this.state.products.slice(i, i + chunk);
        groups.push(
          <View style={styles.row} key={k++}>
            {this.renderRow(temparray)}
          </View>,
        );
      }
    }

    return groups;
  }

  renderPlanogram() {
    let i = 0;
    const trayList = this.state.trays.map((tray) => (
      <Tray
        {...this.props}
        productClicked={this.planogramProductClicked}
        products={tray.Products}
        key={i++}
      />
    ));
    return <ScrollView style={styles.trayContainer}>{trayList}</ScrollView>;
  }

  renderProducts() {
    if (this.state.searchFilter.length >= 3 || this.props.tagSelected) {
      return (
        <ScrollView style={styles.trayContainer}>
          {this.renderProductsInGroupsof(PRODUCTS_PER_ROW)}
        </ScrollView>
      );
    } else {
      return this.renderPlanogram();
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.searchBar}>
          <FontAwesome5Pro
            name="search"
            style={{
              marginTop: Styles.Spacing.m3,
            }}
            color={'#999'}
            size={Styles.Fonts.f2}
          />
          <TextInput
            autoCorrect={false}
            value={this.state.searchFilter}
            autoCapitalize="none"
            placeholder={this.props.strings.Labels.filter_by_name}
            clearButtonMode="while-editing"
            ref={(search) => {
              this.search = search;
            }}
            style={styles.input}
            onChangeText={this.handleSearchChange}
          />
        </View>
        {this.renderProducts()}
      </View>
    );
  }
}

const borderColor = '#999';
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  input: {
    alignSelf: 'stretch',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    color: Styles.darkColor,
    flex: 1,
    fontSize: Styles.Fonts.f2,
    height: Styles.Heights.h4,
    paddingHorizontal: Styles.Spacing.m2,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: Styles.Spacing.m1,
  },
  searchBar: {
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  trayContainer: {
    flex: 1,
    marginTop: Styles.Spacing.m2,
  },
});
export default ProductSearchScreen;
