export enum UrlTypes {
  avweb = 'avweb',
  cartapi = 'cartapi',
  tallyapi = 'tallyapi',
  legacyappapi = 'appapi',
  legacyplatformapi = 'platformapi',
  shqcartapi = 'shqcartapi',
  avlive = 'avlive',
  queapi = 'queapi',
  gmaapi = 'gmaapi',
  capsvr = 'capsvr',
  bunnapi = 'bunnapi',
  appapi = 'app',
  accountapi = 'gma',
  legacyaccountapi = 'accountapi',
  platformapi = 'platform',
  receiptapi = 'receiptapi',
  smtmail = 'smtmail',
}
