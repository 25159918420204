import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import Localized from 'src/constants/AppStrings';
import IconWithBadge from '../IconWithBadge';
import Styles from '../../Styles';
import DefaultUIManager from './DefaultUIManager';
import RewardSection from '../CanteenRewardSection';
import type {RewardType} from 'src/types/Rewards';
import PromotionsHeader from '../promotions/CanteenPromotionsHeader';
const scrollRangeForAnimation = 210;

class CanteenUIManager extends DefaultUIManager {
  getShopIcon(tintColor: string, shopBadgeCount: number) {
    return (
      <IconWithBadge
        iconName="mobile-android-alt"
        tintColor={tintColor}
        size={Styles.Fonts.f2}
        badgeCount={shopBadgeCount}
      />
    );
  }

  getInboxLabel() {
    return Localized.Labels.notifications;
  }

  getShopTitle() {
    return Localized.Labels.mobile_locations;
  }

  getHomeRewardSection(
    rewards: number,
    rewardSteps: Array<RewardType>,
    style: StyleProp<ViewStyle>,
    isRewardButtonShown: boolean,
  ) {
    return (
      <RewardSection
        key={-1}
        style={style}
        rewards={rewards}
        rewardSteps={rewardSteps}
        isRewardButtonShown={isRewardButtonShown}
      />
    );
  }

  getPromotionsHeader(
    qrCode: string,
    balance: number,
    animationScrollY: any,
    strings: typeof Localized,
    isConnected: boolean,
  ) {
    return (
      <PromotionsHeader
        qrCode={qrCode}
        balance={balance}
        hideBalance={false}
        animationScrollY={animationScrollY}
        strings={strings}
        scrollRangeForAnimation={scrollRangeForAnimation}
        isConnected={isConnected}
      />
    );
  }

  getScrollRangeForAnimation() {
    return scrollRangeForAnimation;
  }
}

export default CanteenUIManager;
