import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../Styles';
import Spinner from '../elements/Spinner';
import AVText from '../elements/AVText';
import AVTouchableOpacity from '../elements/AVTouchableOpacity';
type ModalSpinnerProps = {
  callback: () => void;
  text: string;
  cancel: boolean;
  cancelText: string;
};
export default function ModalSpinner(props: ModalSpinnerProps) {
  return (
    <View
      style={[
        styles.container,
        !props.text && !props.cancel && styles.clearContainer,
      ]}
    >
      <View
        style={[
          styles.dialog,
          !props.text && !props.cancel && styles.clearDialog,
        ]}
      >
        <Spinner />
        <AVText style={styles.text}>{props.text}</AVText>
        {props.cancel && (
          <AVTouchableOpacity
            accessibilityLabel="Cancel"
            style={styles.btn}
            onPress={props.callback}
          >
            <AVText style={styles.btnText}>{props.cancelText}</AVText>
          </AVTouchableOpacity>
        )}
      </View>
    </View>
  );
}
const background = 'rgba(0, 0, 0, 0.7)';
const clearBackground = 'rgba(0, 0, 0, 0.0)';
const styles = StyleSheet.create({
  btn: {
    borderColor: Styles.lightGray,
    borderWidth: 0.5,
    marginTop: Styles.Spacing.m1,
    padding: Styles.Spacing.m1,
  },
  btnText: {
    color: Styles.warningColor,
  },
  clearDialog: {
    backgroundColor: clearBackground,
  },
  container: {
    alignItems: 'center',
    backgroundColor: background,
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  clearContainer: {
    backgroundColor: clearBackground,
  },
  dialog: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Styles.white,
    borderRadius: 4,
    marginHorizontal: Styles.Spacing.m4,
    opacity: 0.95,
    padding: Styles.Spacing.m1,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m4,
  },
});
