import * as React from 'react';
import {KeyboardTypeOptions, StyleSheet, View} from 'react-native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Styles from '../../Styles';
import CreditCardImage from '../CreditCardImage';
import creditCardType from 'credit-card-type';

type CreditCardInputProps = {
  onChangeText?: (text: string) => void;
  accessibilityLabel?: string;
  onSubmitEditing?: () => void;
  label?: string;
  issuer?: string;
  editable?: boolean;
  value?: string;
};
type CreditCardInputState = {
  issuer: string;
};

class CreditCardInput extends React.Component<
  CreditCardInputProps,
  CreditCardInputState
> {
  static defaultProps = {
    editable: true,
  };

  constructor(props: CreditCardInputProps) {
    super(props);
    this.state = {
      issuer: props.issuer || '',
    };
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(text: string) {
    if (this.props.onChangeText) {
      this.props.onChangeText(text);
    }

    const results = creditCardType(text);

    if (results.length === 1) {
      this.setState({
        issuer: results[0].type,
      });
    } else {
      this.setState({
        issuer: '',
      });
    }
  }

  render() {
    const textProps = {
      accessibilityLabel: this.props.accessibilityLabel,
      keyboardType: 'numeric' as KeyboardTypeOptions,
      onChangeText: this.onTextChange,
      onSubmitEditing: this.props.onSubmitEditing,
      editable: this.props.editable,
      value: this.props.value,
    };

    if (!this.props.value) {
      delete textProps.value;
    }
    return (
      <View style={[styles.container]}>
        <AllyTextInput
          label={this.props.label}
          value={this.props.value}
          defaultValue={this.props.value}
          hasInlineImage={true}
          imageElement={<CreditCardImage issuer={this.state.issuer} />}
          accessible={true}
          accessibilityLabel={this.props.accessibilityLabel}
          accessibilityValue={{text: this.props.value}}
          accessibilityState={{disabled: !this.props.editable}}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          {...textProps}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  issuerImage: {
    backgroundColor: Styles.transparent,
    bottom: Styles.Spacing.m3,
    position: 'absolute',
    right: Styles.Spacing.m1,
  },
});
export default CreditCardInput;
