import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type PersonProps = {
  size: number;
};
export default class Person extends React.Component<PersonProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        id="Person"
        x="0px"
        y="0px"
        viewBox="0 0 640 640"
      >
        <Path
          d="M640,640s0-44-3.67-67c-3-18.17-28.16-42.17-135.16-81.5-105.34-38.67-98.84-19.83-98.84-91,0-46.17,23.5-19.33,38.5-107,5.84-34.5,10.5-11.5,23.17-66.83,6.67-29-4.5-31.17-3.17-45s2.67-26.17,5.17-54.5C469,92.17,436.5,0,320,0S171,92.17,174.17,127.33c2.5,28.17,3.83,40.67,5.16,54.5s-9.83,16-3.16,45C188.83,282,193.5,259,199.33,293.67c15,87.66,38.5,60.83,38.5,107,0,71.33,6.5,52.5-98.83,91C32,530.83,6.67,555,3.83,573.17,0,596,0,640,0,640H640Z"
          fill="#22212d"
          fillRule="evenodd"
        />
      </Svg>
    );
  }
}
