import React from 'react';
import {StyleSheet, View, Switch} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
type SectionProps = {
  children: React.ReactNode;
  accessibilityLabel?: string;
  title: string;
  switchStatus?: boolean;
  onSwitchToggle?: () => void | null;
};
export default function Section(props: SectionProps) {
  const renderSwitch = () => {
    const {switchStatus, onSwitchToggle} = props;
    if (switchStatus !== undefined && onSwitchToggle) {
      return (
        <Switch
          accessible={true}
          accessibilityLabel={props.title}
          accessibilityState={{checked: props.switchStatus}}
          accessibilityHint="Double tap to activate the switch"
          accessibilityRole="switch"
          accessibilityValue={{text: props.switchStatus.toString()}}
          aria-label={props.title}
          aria-checked={props.switchStatus}
          aria-valuetext={props.switchStatus.toString()}
          role="switch"
          value={props.switchStatus}
          onValueChange={() => onSwitchToggle()}
          trackColor={{
            false: Styles.lightGray,
            true: Styles.primaryColor,
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {props.switchStatus === undefined && (
          <AVText
            style={styles.header}
            accessible={true}
            accessibilityRole={'header'}
            accessibilityLabel={props.title}
            role="heading"
            aria-label={props.title}
          >
            {props.title}
          </AVText>
        )}
        {props.switchStatus !== undefined && (
          <AVText
            accessible={true}
            accessibilityRole={'header'}
            accessibilityLabel={props.title}
            role="heading"
            aria-label={props.title}
            style={styles.header}
          >
            {props.title}
          </AVText>
        )}
        {renderSwitch()}
      </View>
      {props.children}
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    marginBottom: Styles.Spacing.m4,
    minHeight: Styles.Heights.touchTargetHeight0,
  },
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m1,
    paddingHorizontal: Styles.Spacing.m3,
  },
  header: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
  },
});
