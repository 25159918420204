import {createSlice} from '@reduxjs/toolkit';
import {RecentOrderType} from '../../types/RecentOrders';
import {getRecentOrders} from '../thunks/menuThunks';

interface IMenuState {
  recentOrders: Array<RecentOrderType>;
  loading: boolean;
}

export const initialState: IMenuState = {
  recentOrders: [],
  loading: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecentOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRecentOrders.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRecentOrders.fulfilled, (state, action) => {
      state.recentOrders = action.payload;
      state.loading = false;
    });
  },
});
export const {} = menuSlice.actions;
export default menuSlice.reducer;
