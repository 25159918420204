import type {
  Identifier as ServerIdentifier,
  IdentifierTypes,
} from 'src/types/serverTypes/Account';

export interface Identifier {
  id: string;
  value: string;
  type: IdentifierTypes;
  isDisabled: boolean;
}
export default class IdentifierBuilder {
  static build(serverIdentifier: ServerIdentifier): Identifier {
    return {
      value: serverIdentifier.value,
      type: serverIdentifier.type as IdentifierTypes,
      id: serverIdentifier.accountIdentifierId,
      isDisabled: serverIdentifier.isDisabled,
    };
  }
}
