import * as React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {WebView} from 'react-native-webview';
import ScreenContext from '../ScreenContext';
import BackSubheader from '../elements/BackSubheader';
import Styles from '../Styles';
import {StyleSheet} from 'react-native';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
type WebContentScreenProps = {
  url: string;
  backupUrl: string;
  title: string;
  navigation?: NavigationProp<WebContentScreen>;
};
type WebContentScreenState = {
  url: string;
  previousRoute: string | null;
};

class WebContentScreen extends React.Component<
  WebContentScreenProps,
  WebContentScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: WebContentScreenProps) {
    super(props);
    this.state = {
      url: props.url,
      previousRoute: null,
    };
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  // ACCESSIBILITY INFO:
  // WebView cannot have the accessible={true} prop
  // set otherwise it will NOT read the content contained within it.

  // https://github.com/react-native-webview/react-native-webview/issues/1863
  // If you do pass that prop as true, the whole WebView is treated as one item:
  // on iOS it seems accessibilityLabel etc is applied correctly, but it seems to
  // only have the label "WebView" on Android regardless of what accessibility is passed.

  render() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={this.props.title}
      >
        <WebView
          style={styles.viewport}
          source={{
            uri: this.state.url,
          }}
          onLoadEnd={(e) => {
            if (
              e.nativeEvent.title.startsWith('404') &&
              this.props.backupUrl &&
              this.state.url !== this.props.backupUrl
            ) {
              this.setState({
                url: this.props.backupUrl,
              });
            }
          }}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  viewport: {
    backgroundColor: Styles.white,
    flex: 1,
    marginHorizontal: Styles.Spacing.m1,
    paddingHorizontal: Styles.Spacing.m1,
  },
});
export default withForwardedNavigationParams<WebContentScreenProps>()(
  WebContentScreen,
);
