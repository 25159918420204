import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}
function PersonAddWhite({size = 640, color = '#fff'}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 640 640">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M567.14 288.5L567.14 212 507.14 212 507.14 288.5 434.29 288.5 434.29 351.5 507.14 351.5 507.14 428 567.14 428 567.14 351.5 640 351.5 640 288.5 567.14 288.5z"
      />
      <Path
        d="M548.57 607.54v-.92c-.03-4.49-.15-19.19-.93-34.08l-.14-2.55c-.46-8.08-1.13-16.04-2.07-22.28-2.57-16.35-24.14-37.95-115.86-73.35-79.45-30.62-84.67-21.17-84.78-62.11v-2.87c.01-4.92.07-10.51.07-16.92 0-41.55 20.14-17.4 33-96.3 2.09-13 4.01-16.93 6.33-20.76l.39-.64c3.15-5.13 7.08-10.96 13.14-38.76 5.71-26.1-3.86-28.05-2.71-40.5 1.14-12.45 2.29-23.55 4.43-49.05C402.01 114.95 374.15 32 274.3 32s-127.71 82.95-125 114.6c1.8 21.35 2.9 32.7 3.88 43.18l.18 1.96.36 3.91c1.14 12.45-8.43 14.4-2.71 40.5 5.81 26.56 9.65 32.99 12.74 38l.4.65c.07.11.13.21.2.32l.39.64c2.23 3.75 4.1 7.85 6.14 20.54 7.46 45.76 17.37 56.86 24.37 66.21l.71.96.69.96c4.31 6.07 7.22 12.38 7.22 28.17 0 64.2 5.57 47.25-84.71 81.9-91.73 35.25-113.45 57-115.87 73.35C.31 566.44.03 600.63 0 606.97V608h548.57v-.46z"
        fill={color}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default PersonAddWhite;
