import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import AVText from '../AVText';
import Localized from 'src/constants/AppStrings';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import StickerComponent from './StickerComponent';
import Util from 'src/Util';
type GiftItemProps = {
  gift: GiftItemType;
};
export default function GiftItem(props: GiftItemProps) {
  const {gift} = props;
  const snackMessage = Localized.Labels.formatString(
    Localized.Labels.gift_feed_snack_message,
    gift.senderName,
    gift.receiverName,
  );
  return (
    <View style={styles.messageContainer} testID="Gift Item Sticker">
      <StickerComponent id={gift.stickerId} imageSize={Styles.Heights.h3} />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        accessible={true}
        accessibilityLabel={snackMessage.toString()}
        accessibilityRole="text"
        aria-label={`${snackMessage}, text`}
        style={styles.messageText}
      >
        {snackMessage}
      </AVText>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        accessible={true}
        accessibilityLabel={`Snack sent ${Util.getDurationFromDate(
          gift.snackSent,
        )}`}
        accessibilityRole="text"
        aria-label={`Snack sent ${Util.getDurationFromDate(
          gift.snackSent,
        )}, text`}
        style={styles.sendDateText}
      >
        {Util.getDurationFromDate(gift.snackSent)}
      </AVText>
    </View>
  );
}
const styles = StyleSheet.create({
  messageContainer: {
    flex: 1,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: Styles.Spacing.m3,
  },
  messageText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'left',
    fontWeight: '300',
    flex: 1,
  },
  sendDateText: {
    color: Styles.lightGray,
    fontWeight: '300',
    marginRight: Styles.Spacing.m1,
  },
});
