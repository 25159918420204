import * as React from 'react';
import Svg, {G, Path, Rect} from 'react-native-svg';
type DoritosProps = {
  size: number | string;
};
export default class Doritos extends React.Component<DoritosProps> {
  render() {
    const {size} = this.props;
    return (
      <Svg width={size} height={size} viewBox="0 0 320 320">
        <G
          id="Brand/Doritos"
          stroke="none"
          stroke-width="1"
          fill="none"
          fillRule="evenodd"
        >
          <Rect
            id="Rectangle"
            fill="#FFFFFF"
            opacity="0"
            x="0"
            y="0"
            width="320"
            height="320"
          />
          <G id="Group-2" transform="translate(62.000000, 24.000000)">
            <Path
              d="M5.18095238,25.0446779 L5.02827368,25.049079 C3.56403586,25.133761 2.44844288,26.4286401 2.60484055,27.9075779 L5.12655238,51.7542144 L5.12655238,219.388246 L2.60484055,243.235746 C2.44304985,244.765682 3.64248579,246.098646 5.18095238,246.098646 L189.536508,246.098646 L189.687387,246.094344 C191.134643,246.011565 192.245513,244.745482 192.117068,243.281726 L190.02179,219.402926 L190.02179,51.739535 L192.117068,27.8615982 C192.249942,26.3473672 191.056558,25.0446779 189.536508,25.0446779 L5.18095238,25.0446779 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
            <Path
              d="M8.05897143,30.2256303 L186.708571,30.2256303 L184.851186,51.3946006 C184.844578,51.4699011 184.84127,51.5454548 184.84127,51.6210447 L184.84127,219.522279 L184.846849,219.692204 L186.708571,240.917694 L8.05897143,240.917694 L10.2935721,219.794703 C10.3031417,219.704211 10.3079365,219.613277 10.3079365,219.522279 L10.3079365,51.6210447 L10.3043429,51.4846436 L10.2998538,51.4165672 L8.05897143,30.2256303 Z"
              id="Path"
              fill="#C63434"
              fillRule="nonzero"
            />
            <Path
              d="M180.685714,5.73095091 L169.987954,0.285347406 L169.82775,0.210547168 C169.125419,-0.0886537827 168.323112,-0.0637203701 167.637443,0.285347406 L156.939683,5.73095091 L146.241922,0.285347406 L146.081719,0.210547168 C145.379387,-0.0886537827 144.57708,-0.0637203701 143.891412,0.285347406 L133.193651,5.73095091 L122.49589,0.285347406 L122.335687,0.210547168 C121.633355,-0.0886537827 120.831049,-0.0637203701 120.14538,0.285347406 L109.447619,5.73095091 L98.7498583,0.285347406 L98.5896551,0.210547168 C97.8873237,-0.0886537827 97.0850169,-0.0637203701 96.3993481,0.285347406 L85.7015873,5.73095091 L75.0038265,0.285347406 L74.8436234,0.210547168 C74.141292,-0.0886537827 73.3389852,-0.0637203701 72.6533163,0.285347406 L61.9555556,5.73095091 L51.2577948,0.285347406 L51.0975916,0.210547168 C50.3952602,-0.0886537827 49.5929534,-0.0637203701 48.9072846,0.285347406 L38.2095238,5.73095091 L27.5117631,0.285347406 L27.3515599,0.210547168 C26.6492285,-0.0886537827 25.8469217,-0.0637203701 25.1612528,0.285347406 L14.4634921,5.73095091 L3.76573131,0.285347406 C2.04228975,-0.592041024 0,0.659960438 0,2.59388424 L0,32.8161065 C0,34.246787 1.1597957,35.4065827 2.59047619,35.4065827 L192.55873,35.4065827 C193.989411,35.4065827 195.149206,34.246787 195.149206,32.8161065 L195.149206,2.59388424 C195.149206,0.659960438 193.106917,-0.592041024 191.383475,0.285347406 L180.685714,5.73095091 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
            <Path
              d="M168.812698,5.50039852 L179.510459,10.9468655 L179.670662,11.0216658 C180.372994,11.3208667 181.175301,11.2959333 181.860969,10.9468655 L189.968254,6.81895091 L189.968254,30.2256303 L5.18095238,30.2256303 L5.18095238,6.8198144 L13.2882369,10.9468655 L13.4484401,11.0216658 C14.1507715,11.3208667 14.9530783,11.2959333 15.6387472,10.9468655 L26.3365079,5.50039852 L37.0342687,10.9468655 L37.1944719,11.0216658 C37.8968033,11.3208667 38.6991101,11.2959333 39.3847789,10.9468655 L50.0825397,5.50039852 L60.7803004,10.9468655 L60.9405036,11.0216658 C61.642835,11.3208667 62.4451418,11.2959333 63.1308107,10.9468655 L73.8285714,5.50039852 L84.5263322,10.9468655 L84.6865354,11.0216658 C85.3888668,11.3208667 86.1911736,11.2959333 86.8768424,10.9468655 L97.5746032,5.50039852 L108.272364,10.9468655 L108.432567,11.0216658 C109.134899,11.3208667 109.937205,11.2959333 110.622874,10.9468655 L121.320635,5.50039852 L132.018396,10.9468655 L132.178599,11.0216658 C132.88093,11.3208667 133.683237,11.2959333 134.368906,10.9468655 L145.066667,5.50039852 L155.764427,10.9468655 C156.50284,11.3227847 157.376525,11.3227847 158.114938,10.9468655 L168.812698,5.50039852 Z"
              id="Path"
              fill="#9F2A2A"
              fillRule="nonzero"
            />
            <Path
              d="M192.55873,235.736741 L2.59047619,235.736741 C1.1597957,235.736741 0,236.896537 0,238.327218 L0,268.54944 L0.00398838083,268.696834 C0.104523177,270.546169 2.08648055,271.712868 3.76573131,270.857977 L14.4634921,265.41151 L25.1612528,270.857977 L25.321456,270.932777 C26.0237874,271.231978 26.8260942,271.207044 27.5117631,270.857977 L38.2095238,265.41151 L48.9072846,270.857977 L49.0674877,270.932777 C49.7698192,271.231978 50.572126,271.207044 51.2577948,270.857977 L61.9555556,265.41151 L72.6533163,270.857977 L72.8135195,270.932777 C73.5158509,271.231978 74.3181577,271.207044 75.0038265,270.857977 L85.7015873,265.41151 L96.3993481,270.857977 L96.5595512,270.932777 C97.2618826,271.231978 98.0641894,271.207044 98.7498583,270.857977 L109.447619,265.41151 L120.14538,270.857977 L120.305583,270.932777 C121.007914,271.231978 121.810221,271.207044 122.49589,270.857977 L133.193651,265.41151 L143.891412,270.857977 L144.051615,270.932777 C144.753946,271.231978 145.556253,271.207044 146.241922,270.857977 L156.939683,265.41151 L167.637443,270.857977 L167.797646,270.932777 C168.499978,271.231978 169.302285,271.207044 169.987954,270.857977 L180.685714,265.41151 L191.383475,270.857977 C193.106917,271.735365 195.149206,270.483364 195.149206,268.54944 L195.149206,238.327218 C195.149206,236.896537 193.989411,235.736741 192.55873,235.736741 Z"
              id="Path"
              fill="#000000"
              fillRule="nonzero"
            />
            <Path
              d="M189.968254,240.917694 L189.968254,264.32351 L181.860969,260.196459 L181.700766,260.121658 C180.998435,259.822457 180.196128,259.847391 179.510459,260.196459 L168.812698,265.642062 L158.114938,260.196459 L157.954734,260.121658 C157.252403,259.822457 156.450096,259.847391 155.764427,260.196459 L145.066667,265.642062 L134.368906,260.196459 L134.208703,260.121658 C133.506371,259.822457 132.704065,259.847391 132.018396,260.196459 L121.320635,265.642062 L110.622874,260.196459 L110.462671,260.121658 C109.76034,259.822457 108.958033,259.847391 108.272364,260.196459 L97.5746032,265.642062 L86.8768424,260.196459 L86.7166392,260.121658 C86.0143078,259.822457 85.212001,259.847391 84.5263322,260.196459 L73.8285714,265.642062 L63.1308107,260.196459 L62.9706075,260.121658 C62.2682761,259.822457 61.4659693,259.847391 60.7803004,260.196459 L50.0825397,265.642062 L39.3847789,260.196459 L39.2245758,260.121658 C38.5222443,259.822457 37.7199375,259.847391 37.0342687,260.196459 L26.3365079,265.642062 L15.6387472,260.196459 L15.478544,260.121658 C14.7762126,259.822457 13.9739058,259.847391 13.2882369,260.196459 L5.18095238,264.322646 L5.18095238,240.917694 L189.968254,240.917694 Z"
              id="Path"
              fill="#9F2A2A"
              fillRule="nonzero"
            />
            <G id="Group" transform="translate(50.082540, 71.673249)">
              <Path
                d="M53.2553624,18.5061752 C54.1084554,16.7999892 56.183161,16.1084206 57.889347,16.9615136 C58.5577845,17.2957324 59.0997899,17.8377377 59.4340086,18.5061752 L95.2929896,90.2241372 C96.1460826,91.9303233 95.4545141,94.0050289 93.7483281,94.8581219 C93.2687258,95.0979231 92.7398781,95.2227671 92.2036665,95.2227671 L20.4857045,95.2227671 C18.5781305,95.2227671 17.0317362,93.6763728 17.0317362,91.7687988 C17.0317362,91.2325872 17.1565802,90.7037395 17.3963813,90.2241372"
                id="Path"
                fill="#FFCC1E"
                transform="translate(56.345064, 55.909439) rotate(45.000000) translate(-56.345064, -55.909439) "
              />
              <Path
                d="M47.9505351,31.313161 C48.8036282,29.606975 50.8783338,28.9154065 52.5845198,29.7684995 C53.2529573,30.1027182 53.7949626,30.6447235 54.1291814,31.313161 L89.9881624,103.031123 C90.8412554,104.737309 90.1496869,106.812015 88.4435008,107.665108 C87.9638986,107.904909 87.4350509,108.029753 86.8988393,108.029753"
                id="Path"
                fill="#000000"
                opacity="0.2"
                transform="translate(69.152050, 68.716425) rotate(45.000000) translate(-69.152050, -68.716425) "
              />
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
