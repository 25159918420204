import Auth0 from 'react-native-auth0';
import Settings from '../Settings';
import {Platform} from 'react-native';
import {Auth0ClientOptions, Auth0Client} from './interfaces/IAuth0';

declare global {
  class auth0 {
    static Auth0Client: (options: Auth0ClientOptions) => void;
  }
}

let mobileAuth0Client = new Auth0({
  domain: Settings.auth0.PROD.domain,
  clientId: Settings.auth0.PROD.clientId,
});

let webAuth0Client: Auth0Client;

const changeAuth0Client = (config: string) => {
  if (!config) {
    return;
  }
  if (Platform.OS === 'web') {
    webAuth0Client = new auth0.Auth0Client({
      domain: Settings.auth0[config].domain,
      clientId: Settings.auth0[config].clientIdAuth0Web,
      authorizationParams: {
        redirect_uri: window.location.origin,
        organization: Settings.auth0[config].orgIdAuth0,
      },
      audience: Settings.auth0[config].audience,
      scope: Settings.scope,
      useRefreshTokens: true,
    });
  } else {
    mobileAuth0Client = new Auth0({
      domain: Settings.auth0[config].domain,
      clientId: Settings.auth0[config].clientId,
    });
  }
};
const getMobileAuth0Client = () => mobileAuth0Client;

const getWebAuth0Client = () => {
  if (webAuth0Client === null || webAuth0Client === undefined) {
    webAuth0Client = new auth0.Auth0Client({
      domain: Settings.auth0.PROD.domain,
      clientId: Settings.auth0.PROD.clientIdAuth0Web,
      authorizationParams: {
        redirect_uri: window.location.origin,
        organization: Settings.auth0.PROD.orgIdAuth0,
      },
      audience: Settings.auth0.PROD.audience,
      scope: Settings.scope,
      useRefreshTokens: true,
    });
  }
  return webAuth0Client;
};

export {getWebAuth0Client, getMobileAuth0Client, changeAuth0Client};
