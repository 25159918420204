import PlatformApi from 'src/api/PlatformApi';

class ReferralActions {
  async getReferralDetails(referralId: string) {
    return await PlatformApi.fetchReferralDetails(referralId);
  }

  async getReferrals(accountId: string) {
    return await PlatformApi.fetchReferrals(accountId);
  }

  async fetchReferralSummary(referralId: string) {
    return await PlatformApi.fetchReferralSummary(referralId);
  }

  async redeemReferral(
    accountId: string,
    referralId: string,
    transactionDate: string,
  ) {
    return await PlatformApi.redeemReferral(
      accountId,
      referralId,
      transactionDate,
    );
  }

  async getCampaignDetails(locationId: string, active: boolean) {
    return await PlatformApi.fetchCampaignDetails(locationId, active);
  }

  async getReferralStats(accountId: string) {
    return await PlatformApi.fetchUserReferralStat(accountId);
  }

  async sendReferralInvite(
    campaignId: string,
    accountId: string,
    method: string,
    contact: string,
    appName: string,
  ) {
    return await PlatformApi.sendReferralInvite(
      campaignId,
      accountId,
      method,
      contact,
      appName,
    );
  }
}

export default new ReferralActions();
