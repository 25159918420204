import React from 'react';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Header from '../../elements/Header';
import AVText from '../../elements/AVText';
import Icon from '../../elements/AVIcon';
import Localized from 'src/constants/AppStrings';

class ProductFeedbackCompletedScreen extends React.Component<never> {
  static contextType = ScreenContext;

  constructor(props: never) {
    super(props);
    this.okClick = this.okClick.bind(this);
  }

  okClick() {
    NavActions.pop();
  }

  render() {
    return (
      <Header title={Localized.Labels.eat_it_or_delete_it}>
        <View style={styles.content}>
          <Icon
            name="ios-heart"
            color={Styles.primaryColor}
            size={48}
            style={Styles.Style.image}
          />
          <AVText style={styles.title}>
            {Localized.Labels.congratulations}
          </AVText>
          <AVText style={styles.text}>
            {Localized.Labels.eat_it_or_delete_it_completed}
          </AVText>
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.okClick}
          accessibilityLabel="OK"
          text={Localized.Buttons.ok}
        />
      </Header>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    borderRadius: 4,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m5,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m2,
  },
  override: {
    marginTop: Styles.Spacing.m1,
  },
  text: {
    fontSize: Styles.Fonts.f1,
    marginVertical: Styles.Spacing.m2,
    textAlign: 'center',
  },
  title: {
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
export default withForwardedNavigationParams<never>()(
  ProductFeedbackCompletedScreen,
);
