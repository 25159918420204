export enum PreparationMethodValues {
  ToGo = 'T',
  DineIn = 'D',
  Locker = 'L',
  None = 'N',
}

export enum PreparationValues {
  On = '1',
  Off = '0',
}

export type PreparationMethodDefaults = {
  togoAvailable: boolean;
  dineInAvailable: boolean;
  lockerAvailable: boolean;
  selectedMethod: PreparationMethodValues;
};
