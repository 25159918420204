export default class HttpError extends Error {
  statusCode: number;
  message: string;
  url: string;
  code: string;
  okResponse: boolean;
  body?: any;

  constructor(
    url: string,
    statusCode: number,
    code: string,
    okResponse: boolean,
    message?: string,
    body?: any,
  ) {
    super(message);
    this.statusCode = statusCode;
    this.message = message || '';
    this.url = url;
    this.code = code || '';
    this.okResponse = okResponse;
    this.body = body;
  }
}
