import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type GiftBoxProps = {
  width: number;
  height: number;
};
export default class GiftBox extends React.Component<GiftBoxProps> {
  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        id="Layer_2"
        x="0px"
        y="0px"
        viewBox="0 0 599.87 640"
      >
        <Path
          fill="#FF8B4A"
          d="M565.77,136A61.48,61.48,0,0,0,573,107.4c0-36.14-25.41-82.85-98.66-82.85a248.23,248.23,0,0,0-81.3,21.94l5.84-8.21a23.68,23.68,0,1,0-38.52-27.46L302.93,91.15,258.1,12.24a23.72,23.72,0,0,0-41.2,23.52l10.73,18.78A241.87,241.87,0,0,0,126.92,24.08c-30.47,0-48.78,14.83-67.72,28.25a67.22,67.22,0,0,0-31.57,54.6,61.48,61.48,0,0,0,7.26,28.56A47.35,47.35,0,0,0,0,180.94v80.32a47.35,47.35,0,0,0,47.36,47.35v284A47.35,47.35,0,0,0,94.72,640H505.15a47.35,47.35,0,0,0,47.36-47.34v-284a47.35,47.35,0,0,0,47.36-47.35V181.41A47.35,47.35,0,0,0,565.77,136ZM127.08,71.89A178.75,178.75,0,0,1,257,135H119.18C89.51,135,75.93,118,75.93,108.19S86.19,71.89,127.08,71.89ZM269.15,592.66H95.51v-284H269.15Zm0-331.4h-221v-78.9h221ZM474.37,71.42c40.89.47,51.3,24.46,51.3,36a21.43,21.43,0,0,1-10.57,15.78,57.9,57.9,0,0,1-32.68,11.36h-138A178.74,178.74,0,0,1,474.37,71.42Zm31.57,521.24H316.51v-284H505.94Zm47.36-331.4H316.51v-78.9H553.3Z"
        />
      </Svg>
    );
  }
}
