import React from 'react';
import {Platform} from 'react-native';
import MarketConnectLogoWeb from 'src/components/img/svg/MarketConnectLogoWeb';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import CanteenPantryLogo from '../img/svg/CanteenPantryLogo';
import ConnectAndPayLogo from '../img/svg/ConnectAndPayLogo';
import Logo365Pay from '../img/svg/Logo365Pay';
import MarketConnectLogo from '../img/svg/MarketConnectLogo';
import SocialFeediaLogo from '../img/svg/SocialFeediaLogo';
import Styles from '../Styles';
import Localized from 'src/constants/AppStrings';

export default (props: {small?: boolean}) => {
  const size = props.small ? Styles.Fonts.f5 : Styles.Heights.h6 * 3;
  switch (Settings.buildType) {
    case BuildTypes.market:
      if (Platform.OS === 'web') {
        return <MarketConnectLogoWeb width={size} height={size} />;
      }
      return <MarketConnectLogo width={size} height={size} />;
    case BuildTypes.pantry:
      return <CanteenPantryLogo width={size} height={size} />;
    case BuildTypes.social:
      return <SocialFeediaLogo width={size} height={size} />;
    case BuildTypes.canteen:
      return <ConnectAndPayLogo width={size} height={size} />;
    default:
      return (
        <Logo365Pay
          accessible={true}
          accessibilityRole={'image'}
          accessibilityLabel={Localized.Labels.logo_365Pay}
          aria-label={Localized.Labels.logo_365Pay}
          width={size}
          height={size}
        />
      );
  }
};
