import * as React from 'react';
import {View, StyleSheet} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import BackSubheader from '../../elements/BackSubheader';
import YourItems from '../../elements/cart/ScanItems';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import Icon from '../../elements/AVIcon';
import CartTypes from 'src/constants/cart/CartTypes';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type InsufficientCreditScreenProps = {
  cardAdded: () => void;
  marketName: string;
  balance: number;
};

class InsufficientCreditScreen extends React.Component<InsufficientCreditScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: InsufficientCreditScreenProps) {
    super(props);
    this.addClicked = this.addClicked.bind(this);
  }

  addClicked() {
    FirebaseAnalytic.trackEvent(
      'InsufficientCredit',
      'InsufficientCreditScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    NavActions.replace(AppRoutes.CreditCard, {
      buttonText: Localized.Buttons.save_and_complete_purchase,
      cardAdded: this.props.cardAdded,
    });
  }

  render() {
    return (
      <BackSubheader title={this.props.marketName}>
        <View style={styles.content}>
          <Icon
            name={'md-alert'}
            size={Styles.Heights.h5}
            color={Styles.warningColor}
            style={styles.alertIcon}
          />
          <AVText style={styles.mainLabel}>
            {Localized.Labels.insufficient_credit_remaining}
          </AVText>
          <YourItems
            balance={this.props.balance}
            hideYourItems
            showGrandTotal
            cartType={CartTypes.ScanAndPay}
            preparationMethod={null}
            hasConsumerRoles={false}
            consumerRoleButton={null}
          />
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.addClicked}
            accessibilityLabel="Add Card and Checkout"
            text={Localized.Buttons.add_card_and_checkout}
          />
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  alertIcon: {
    alignSelf: 'center',
    height: Styles.Heights.h5,
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
    textAlign: 'center',
    width: Styles.Heights.h6,
  },
  content: {
    flex: 1,
  },
  mainLabel: {
    alignSelf: 'stretch',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Styles.borderSeparatorColor,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m2,
    textAlign: 'center',
  },
});
export default withForwardedNavigationParams<InsufficientCreditScreenProps>()(
  InsufficientCreditScreen,
);
