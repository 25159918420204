import * as React from 'react';
import Keypad1 from './Keypad1';
import Keypad3 from './Keypad3';
import Keypad4 from './Keypad4';
import Keypad5 from './Keypad5';
import Keypad6 from './Keypad6';
import Keypad7 from './Keypad7';
import Keypad8 from './Keypad8';
import Keypad9 from './Keypad9';
import Keypad10 from './Keypad10';
import Keypad11 from './Keypad11';
import Keypad12 from './Keypad12';
import Keypad13 from './Keypad13';
import Keypad14 from './Keypad14';
import Keypad15 from './Keypad15';
import Keypad16 from './Keypad16';
import UnknownKeypad from './UnknownKeypad';
import type {KeypadProps} from './types';

export enum KeypadTypes {
  K1 = '01',
  K2 = '02',
  K3 = '03',
  K4 = '04',
  K5 = '05',
  K6 = '06',
  K7 = '07',
  K8 = '08',
  K9 = '09',
  K10 = '0A',
  K11 = '0B',
  K12 = '0C',
  K13 = '0D',
  K14 = '0E',
  K15 = '0F',
  K16 = '10',
}

type VendorsExchangeKeypadProps = KeypadProps & {
  keypadType: KeypadTypes;
};

export default function VendorsExchangeKeyPad(
  props: VendorsExchangeKeypadProps,
) {
  switch (props.keypadType) {
    case KeypadTypes.K1:
    case KeypadTypes.K2:
      return <Keypad1 {...props} />;

    case KeypadTypes.K3:
      return <Keypad3 {...props} />;

    case KeypadTypes.K4:
      return <Keypad4 {...props} />;

    case KeypadTypes.K5:
      return <Keypad5 {...props} />;

    case KeypadTypes.K6:
      return <Keypad6 {...props} />;

    case KeypadTypes.K7:
      return <Keypad7 {...props} />;

    case KeypadTypes.K8:
      return <Keypad8 {...props} />;

    case KeypadTypes.K9:
      return <Keypad9 {...props} />;

    case KeypadTypes.K10:
      return <Keypad10 {...props} />;

    case KeypadTypes.K11:
      return <Keypad11 {...props} />;

    case KeypadTypes.K12:
      return <Keypad12 {...props} />;

    case KeypadTypes.K13:
      return <Keypad13 {...props} />;

    case KeypadTypes.K14:
      return <Keypad14 {...props} />;

    case KeypadTypes.K15:
      return <Keypad15 {...props} />;

    case KeypadTypes.K16:
      return <Keypad16 {...props} />;

    default:
      return <UnknownKeypad />;
  }
}
