import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type LogoProps = {
  size: number;
}; // tslint:disable

export default class PaperBill extends React.Component<LogoProps> {
  render() {
    return (
      <Svg
        width={this.props.size * 1.4}
        height={this.props.size * 1.4}
        viewBox="0 75 550 500"
      >
        <Path
          fill="#335755"
          d="M542.748,96.791c-3.614-3.617-7.898-5.426-12.847-5.426H18.274c-4.948,0-9.233,1.809-12.85,5.426
			C1.811,100.407,0,104.689,0,109.636v328.901c0,4.948,1.811,9.232,5.424,12.847c3.617,3.613,7.902,5.427,12.85,5.427h511.627
			c4.948,0,9.232-1.813,12.847-5.427c3.613-3.614,5.428-7.898,5.428-12.847V109.636C548.176,104.689,546.361,100.41,542.748,96.791z
			M511.627,347.179c-20.178,0-37.397,7.139-51.679,21.409c-14.276,14.271-21.412,31.497-21.412,51.674H109.634
			c0-20.177-7.137-37.403-21.411-51.674c-14.275-14.277-31.499-21.409-51.678-21.409V200.998c20.179,0,37.402-7.139,51.678-21.411
			c14.274-14.274,21.411-31.501,21.411-51.678h328.909c0,20.177,7.132,37.404,21.409,51.678
			c14.277,14.272,31.497,21.411,51.675,21.411V347.179L511.627,347.179z"
        />
        <Path
          fill="#335755"
          d="M342.463,195.293c-7.327-11.803-16.988-21.416-28.979-28.837c-11.991-7.423-25.122-11.134-39.399-11.134
			c-14.277,0-27.407,3.711-39.399,11.134c-11.991,7.421-21.652,17.034-28.98,28.837c-7.331,11.798-12.991,24.55-16.988,38.256
			c-3.999,13.704-5.996,27.221-5.996,40.541c0,13.317,1.997,26.835,5.996,40.537c3.997,13.702,9.657,26.46,16.988,38.26
			c7.329,11.807,16.989,21.412,28.98,28.835c11.993,7.427,25.122,11.14,39.399,11.14c14.277,0,27.408-3.713,39.399-11.14
			c11.991-7.423,21.652-17.032,28.979-28.835c7.33-11.8,12.99-24.558,16.987-38.26c4.004-13.702,5.995-27.22,5.995-40.537
			c0-13.319-1.991-26.836-5.995-40.541C355.453,219.843,349.793,207.091,342.463,195.293z M328.904,347.179H219.267v-27.411h36.54
			v-82.225h-0.572c-2.474,3.807-7.707,9.234-15.699,16.274l-21.982-22.839l42.253-39.115h32.551v127.905h36.546V347.179z"
        />
      </Svg>
    );
  }
}
