import AsyncStorage from '@react-native-async-storage/async-storage';
import Amplify from '@aws-amplify/core';
import AWSAppSyncClient from 'aws-appsync';
import Auth from '@aws-amplify/auth';
import AccountStore from 'src/stores/AccountStore';
import environmentConfigs from './environmentConfigs';
import {store} from '../../redux/store';

class AppSyncClientProvider {
  prevClients: Map<string, any> = new Map();
  environment: string;

  constructor() {
    this.updateEnvironment = this.updateEnvironment.bind(this);
    this.updateEnvironment();
  }

  async updateEnvironment() {
    await AccountStore.waitForData();
    const environment = store.getState().environment.env;

    if (this.environment !== environment) {
      this.environment = environment;
      const awsconfig = environmentConfigs[environment];
      Amplify.configure(awsconfig);
    }
  }

  getClientKey() {
    return `${this.environment}${AccountStore.getAccountId()}`;
  }

  async getClient(): Promise<any> {
    await this.updateEnvironment();
    const clientKey = this.getClientKey();

    if (this.prevClients.has(clientKey)) {
      return this.prevClients.get(clientKey);
    } else {
      const awsconfig = environmentConfigs[this.environment];
      const client = new AWSAppSyncClient({
        url: awsconfig.aws_appsync_graphqlEndpoint,
        region: awsconfig.aws_appsync_region,
        auth: {
          type: awsconfig.aws_appsync_authenticationType,
          jwtToken: async () => {
            try {
              return (await Auth.currentSession()).getIdToken().getJwtToken();
            } catch {
              return ''; // no current session
            }
          },
        },
        disableOffline: false,
        offlineConfig: {
          storage: AsyncStorage,
          keyPrefix: clientKey,
        },
      });
      this.prevClients.set(clientKey, client);
      return client;
    }
  }
}

const instance = new AppSyncClientProvider();
export default instance;
