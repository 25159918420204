import type {IPassKit} from './interfaces/IPassKit';

class PassKit implements IPassKit {
  async canAddPass() {
    return false;
  }

  async doesPassExist(passUrl: string) {
    return true;
  }

  async addPass(passUrl: string): Promise<boolean> {
    return true;
  }
}

export default new PassKit();
