import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

function PersonWhite({color = '#fff', size = 640}: IProps) {
  return (
    <Svg id="Layer_1" width={size} height={size} viewBox="0 0 640 640">
      <G id="Person">
        <G id="_Person">
          <G id="_-Color">
            <Path
              id="path-1"
              d="M640 640s0-44-3.7-67c-3-18.2-28.2-42.2-135.2-81.5-105.3-38.7-98.8-19.8-98.8-91 0-46.2 23.5-19.3 38.5-107 5.8-34.5 10.5-11.5 23.2-66.8 6.7-29-4.5-31.2-3.2-45s2.7-26.2 5.2-54.5C469 92.2 436.5 0 320 0S171 92.2 174.2 127.3c2.5 28.2 3.8 40.7 5.2 54.5s-9.8 16-3.2 45c12.7 55.2 17.3 32.2 23.2 66.8 15 87.7 38.5 60.8 38.5 107 0 71.3 6.5 52.5-98.8 91C32 530.8 6.7 555 3.8 573.2 0 596 0 640 0 640h640z"
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default PersonWhite;
