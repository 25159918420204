import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import {StyleSheet, ScrollView, View} from 'react-native';
import Settings from 'src/Settings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import BackSubheader from 'src/components/elements/BackSubheader';
import AVText from 'src/components/elements/AVText';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ChangeEmailScreenProps = IsConnectedProps & {
  navigation: NavigationProp<ChangeEmailScreen>;
};
type ChangeEmailScreenState = {
  password?: string;
  newEmail: string;
  confirmEmail: string;
  previousRoute: string;
};

class ChangeEmailScreen extends React.Component<
  ChangeEmailScreenProps,
  ChangeEmailScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ChangeEmailScreenProps) {
    super(props);
    this.state = {
      password: '',
      confirmEmail: '',
      newEmail: '',
      previousRoute: '',
    };
    this.validate = this.validate.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({
      previousRoute,
    });
  }

  saveClick() {
    const errorMessage = this.validate();

    if (errorMessage) {
      alertError(errorMessage);
    } else {
      this.changeEmail();
    }
  }

  validate() {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.newEmail)
    ) {
      return Localized.Errors.invalid_email;
    } else if (this.state.newEmail !== this.state.confirmEmail) {
      return Localized.Errors.emails_do_not_match;
    }
  }

  async changeEmail() {
    this.context.actions.showSpinner();
    let response;
    try {
      response = await ActionsFactory.getAccountActions().changeEmail(
        AccountStore.getAccountId(),
        this.state.newEmail,
        Settings.buildType,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangeEmail',
        JSON.stringify({
          accountId: AccountStore.getAccountId(),
          newEmail: this.state.newEmail,
        }),
        JSON.stringify(response),
      );

      alertSuccess(Localized.Success.verify_email_has_been_sent);

      FirebaseAnalytic.trackEvent('changeEmail', 'ChangeEmailScreen', {
        ...this.props,
        ...this.state,
      });
      NavActions.pop();
    } catch (error) {
      if (error.message.includes('Email already exists')) {
        alertError(Localized.Errors.email_already_exists);
      } else {
        alertError(Localized.Errors.unable_to_change_email);
      }

      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ChangeEmailScreen:ChangeEmail',
        error.message ? error.message : error.toString(),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ChangeEmailScreen:ChangeEmail',
        error.message ? error.message : error.toString(),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangeEmail-Error',
        JSON.stringify({
          email: this.state.newEmail,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    return (
      <BackSubheader title={Localized.Labels.personal_info}>
        <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
          <ScrollView>
            <View style={[styles.content, Styles.Style.maxWidthContainer]}>
              <AVText style={styles.instructions}>
                {Localized.Labels.update_email}
              </AVText>
              <AllyTextInput
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                label={Localized.Labels.new_email}
                value={this.state.newEmail}
                accessible={true}
                accessibilityLabel={Localized.Labels.new_email}
                accessibilityValue={{text: this.state.newEmail}}
                onChangeText={(text) => this.setState({newEmail: text})}
                autoCapitalize="none"
                keyboardType="email-address"
              />
              <AllyTextInput
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                label={Localized.Labels.confirm_new_email}
                value={this.state.confirmEmail}
                accessible={true}
                accessibilityLabel={Localized.Labels.confirm_new_email}
                accessibilityValue={{text: this.state.confirmEmail}}
                onChangeText={(text) => this.setState({confirmEmail: text})}
                autoCapitalize="none"
                keyboardType="email-address"
              />
            </View>
          </ScrollView>
          {this.props.isConnected && (
            <RoundedButton
              accessible={true}
              accessibilityLabel={Localized.Buttons.save}
              accessibilityRole="button"
              accessibilityHint="Double tap to save new email address"
              role="button"
              aria-label={Localized.Buttons.save}
              buttonType={ButtonType.action}
              text={Localized.Buttons.save}
              onPress={this.saveClick}
            />
          )}
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m6,
  },
  instructions: {
    alignSelf: 'stretch',
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    marginVertical: Styles.Spacing.m1,
    textAlign: 'left',
  },
});
export default withForwardedNavigationParams()(
  withIsConnected(ChangeEmailScreen),
);
