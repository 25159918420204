import React from 'react';
import {FlatList} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import withIsConnected from '../../hoc/withIsConnected';
import BaseScreen from '../BaseScreen';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import GiftItem from '../../elements/sendSnack/GiftItem';
import AccountStore from 'src/stores/AccountStore';
import moment from 'moment';
import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {
  loadGiftFeed,
  LoadGiftFeedParams,
} from '../../../redux/thunks/snackThunks';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type GiftFeedScreenProps = {
  loadGiftFeed(params: LoadGiftFeedParams): void;
  isRefreshing: boolean;
  gifts: Array<GiftItemType>;
};
type GiftFeedScreenState = {
  offset: number;
};

class GiftFeedScreen extends React.Component<
  GiftFeedScreenProps,
  GiftFeedScreenState
> {
  static contextType = ScreenContext;
  started = null;

  constructor(props: GiftFeedScreenProps) {
    super(props);
    this.renderGiftItem = this.renderGiftItem.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.getKeyForListItem = this.getKeyForListItem.bind(this);
    this._onRefresh = this._onRefresh.bind(this);
    this.state = {
      offset: 0,
    };
  }

  _onRefresh(offset: number) {
    FirebaseAnalytic.trackEvent('_onRefresh', 'GiftFeedScreen', {
      ...this.props,
      ...this.state,
      offset,
    });
    this.started = moment();
    this.props.loadGiftFeed({
      locationId: AccountStore.getLocationId(),
      offset,
    });
    this.setState({
      offset,
    });
  }

  handleLoadMore() {
    const fullItems = this.props.gifts;
    const lastItem = fullItems[fullItems.length - 1];
    const hasMore = !lastItem.lastItem;
    FirebaseAnalytic.trackEvent('handleLoadMore', 'GiftFeedScreen', {
      ...this.props,
      ...this.state,
      fullItems,
      lastItem,
      hasMore,
    });
    if (hasMore && !this.props.isRefreshing) {
      this._onRefresh(this.state.offset + 10);
    }
  }

  getKeyForListItem(item) {
    return item.snackId;
  }

  renderGiftItem({item}) {
    return <GiftItem key={item.snackId} gift={item} />;
  }

  render() {
    return (
      <BaseScreen title={Localized.Labels.gift_feed_title}>
        <FlatList
          refreshing={this.props.isRefreshing}
          onRefresh={() => this._onRefresh(0)}
          data={this.props.gifts}
          renderItem={this.renderGiftItem}
          keyExtractor={this.getKeyForListItem}
          onEndReached={this.handleLoadMore}
        />
      </BaseScreen>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  gifts: state.snack.gifts,
  isRefreshing: state.snack.isRefreshing,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadGiftFeed: (params) => dispatch(loadGiftFeed(params)),
});
export default compose(
  withForwardedNavigationParams(),
  withIsConnected,
  connect(mapStateToProps, mapDispatchToProps),
)(GiftFeedScreen);
