import * as React from 'react';
import NetInfo from '@react-native-community/netinfo';
type withIsConnectedState = {
  isConnected: boolean;
};
export default function withIsConnected(Component: any) {
  class ComponentWithIsConnected extends React.Component<
    any,
    withIsConnectedState
  > {
    unsubscribe: any;

    constructor(props: any) {
      super(props);
      this.state = {
        isConnected: true,
      };
    }

    componentDidMount() {
      this.unsubscribe = NetInfo.addEventListener((state) => {
        this.setState({
          isConnected: state.isConnected ?? false,
        });
      });
    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }

    render() {
      return <Component isConnected={this.state.isConnected} {...this.props} />;
    }
  }

  return ComponentWithIsConnected;
}
