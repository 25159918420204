import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import React from 'react';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {Platform, StyleSheet} from 'react-native';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import AVIcon from '../../elements/AVIcon';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type EnteredEmailProps = {
  importId?: string;
  importLocationId?: string;
};

class EnteredEmail extends React.Component<EnteredEmailProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: EnteredEmailProps) {
    super(props);
    this.onManualPress = this.onManualPress.bind(this);
    this.onScanPress = this.onScanPress.bind(this);
    this.onMarketCardScanPress = this.onMarketCardScanPress.bind(this);
  }

  onManualPress() {
    FirebaseAnalytic.trackEvent('onManualPress', 'EnteredEmailScreen', {
      ...this.props,
      ...this.state,
      navigate: AppRoutes.KioskCreateAccount,
    });
    NavActions.push(AppRoutes.KioskCreateAccount, {...this.props});
  }

  onScanPress() {
    FirebaseAnalytic.trackEvent('onScanPress', 'EnteredEmailScreen', {
      ...this.props,
      ...this.state,
      navigate: AppRoutes.ScanKioskQR,
    });
    NavActions.push(AppRoutes.ScanKioskQR, {...this.props});
  }

  onMarketCardScanPress() {
    FirebaseAnalytic.trackEvent('onMarketCardScanPress', 'EnteredEmailScreen', {
      ...this.props,
      ...this.state,
      navigate: AppRoutes.ScanMarketCard,
    });
    NavActions.push(AppRoutes.ScanMarketCard, {...this.props});
  }

  render() {
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.setup_app}
        instructionText={Localized.Labels.select_link_account_method}
      >
        {Platform.OS !== 'web' && (
          <AVTouchableOpacity
            style={[styles.bigBtn, styles.scanBtn]}
            onPress={this.onScanPress}
            accessibilityLabel="Scan QR Code From Kiosk"
          >
            <AVIcon
              style={styles.icon}
              name="scan-outline"
              color={Styles.white}
            />
            <AVText style={styles.btnText}>
              {Localized.Labels.scan_qr_code_from_kiosk}
            </AVText>
          </AVTouchableOpacity>
        )}
        <AVTouchableOpacity
          style={[styles.bigBtn, styles.marketCardBtn]}
          onPress={this.onMarketCardScanPress}
          accessibilityLabel="Scan Market Card"
        >
          <AVIcon style={styles.icon} name="ios-card" color={Styles.white} />
          <AVText style={styles.btnText}>
            {Platform.OS === 'web'
              ? Localized.Labels.enter_market_card
              : Settings.buildType === BuildTypes.canteen
              ? Localized.Labels.scan_connectandpay_card
              : Localized.Labels.scan_market_card}
          </AVText>
        </AVTouchableOpacity>
        <AVTouchableOpacity
          style={[styles.bigBtn, styles.manualBtn]}
          onPress={this.onManualPress}
          accessibilityLabel="Manually Enter Email"
        >
          <AVIcon style={styles.icon} name="ios-person" color={Styles.white} />
          <AVText style={styles.btnText}>
            {Localized.Labels.manually_enter_email}
          </AVText>
        </AVTouchableOpacity>
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  bigBtn: {
    borderRadius: Styles.Spacing.m2,
    flex: 1,
    justifyContent: 'center',
    marginTop: Styles.Spacing.m4,
  },
  btnText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'stretch',
    color: Styles.white,
    fontSize: Styles.Fonts.f5,
    lineHeight: Styles.Fonts.f5,
    textAlign: 'center',
  },
  manualBtn: {
    backgroundColor: Styles.primaryColor,
  },
  marketCardBtn: {
    backgroundColor: Styles.primaryColor,
  },
  scanBtn: {
    backgroundColor: Styles.primaryColor,
  },
});
export default withForwardedNavigationParams<EnteredEmailProps>()(EnteredEmail);
