import * as React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Autolink from 'react-native-autolink';
import type {LocationType} from 'src/types/Location';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import ScreenContext from '../../ScreenContext';
import Styles from '../../Styles';
import NavActions from 'src/actions/NavActions';
import Localized from 'src/constants/AppStrings';
import type {RouteInfo} from '../../elements/Machines';
import AppRoutes from 'src/AppRoutes';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type DisclaimerScreenProps = {
  location: LocationType;
  autoAdvanced: boolean;
  navigation?: NavigationProp<DisclaimerScreen>;
  getRouteFromPickupLocations: (
    location: LocationType,
    autoAdvanced?: boolean,
  ) => Promise<RouteInfo>;
};

type DisclaimerScreenState = {
  previousRoute: string | null;
};

class DisclaimerScreen extends React.Component<
  DisclaimerScreenProps,
  DisclaimerScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: DisclaimerScreenProps) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  async handleContinue() {
    const routeInfo = await this.props.getRouteFromPickupLocations(
      this.props.location,
      this.props.autoAdvanced,
    );
    if (!routeInfo.error) {
      FirebaseAnalytic.trackEvent(
        'DisclaimerContinue',
        'DisclaimerScreen: Continue',
        {
          ...this.props,
          ...this.state,
        },
      );
      NavActions.navigate(
        routeInfo.name as AppRoutes,
        {
          ...this.props,
          availableTimes: routeInfo.availableTimes,
        },
        'machines-pickup',
      );
    }
  }

  render() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.please_note}
      >
        <ScrollView contentContainerStyle={styles.content}>
          <Autolink
            accessible={true}
            accessibilityLabel={
              this.props.location.onlineOrderConfig.disclaimer
            }
            linkProps={{
              maxFontSizeMultiplier: Styles.FontSizeMultiplier.maxfm11,
            }}
            accessibilityRole="text"
            aria-label={this.props.location.onlineOrderConfig.disclaimer}
            style={styles.disclaimerText}
            text={this.props.location.onlineOrderConfig.disclaimer}
          />
        </ScrollView>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.handleContinue}
          accessible={true}
          accessibilityLabel="Continue to pickup locations"
          accessibilityRole="button"
          aria-label="Continue to pickup locations"
          role="button"
          text={Localized.Buttons.continue}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  disclaimerText: {
    marginBottom: Styles.Spacing.m5,
    color: Styles.darkColor,
  },
});
export default withForwardedNavigationParams<DisclaimerScreenProps>()(
  DisclaimerScreen,
);
