import Bowser from 'bowser';

function isValidBrowser(): boolean {
  try {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return !!browser.satisfies({
      chrome: '>23',
      firefox: '>21',
      opera: '>15',
      'microsoft edge': '>12',
      safari: '>6',
    });
  } catch (e) {
    // Browser doesn't support lookup
    return false;
  }
}

if (!isValidBrowser()) {
  window.location.href = '/invalid_browser.html';
}
