import * as React from 'react';
import KeyboardSpacer from './KeyboardSpacer';
import {ScrollView, findNodeHandle} from 'react-native';
import TextInput from '../elements/AVTextInput';
type KeyboardAwareScrollViewProps = JSX.LibraryManagedAttributes<
  typeof ScrollView,
  React.ComponentProps<typeof ScrollView>
> & {
  children: React.ReactNode;
};

class KeyboardAwareScrollView extends React.Component<KeyboardAwareScrollViewProps> {
  scrollView: ScrollView;

  getScrollResponder() {
    return this.scrollView.getScrollResponder();
  }

  scrollToElement(element: TextInput | null) {
    const nodeHandle = findNodeHandle(element);
    const scrollResponder = this.getScrollResponder();

    if (nodeHandle && scrollResponder) {
      scrollResponder.scrollResponderScrollNativeHandleToKeyboard(
        nodeHandle,
        220,
        true,
      );
    }
  }

  render() {
    return (
      <ScrollView
        ref={(scrollView) => {
          this.scrollView = scrollView;
        }}
        {...this.props}
      >
        {this.props.children}
        <KeyboardSpacer />
      </ScrollView>
    );
  }
}

export default KeyboardAwareScrollView;
