import Api, {HttpVerbs} from './Api';
import type {SetupModel} from 'src/types/SetupModel';
import {UrlTypes} from 'src/types/UrlTypes';
import {roundNumber} from 'src/Util';
import Events from 'src/logging/Events';

class AppApi {
  fetchPayrollImport(importId: string) {
    const url = this.getLegacyUrl(`/payroll-imports/${importId}`);
    return Api.fetch(url, null, HttpVerbs.Get);
  }

  linkPayroll(importId: string, accountId: string) {
    const url = this.getFullUrl('/actions/payroll-imports/link');
    return Api.fetchGateway(
      url,
      {
        accountId: accountId,
        payrollImportId: importId,
      },
      HttpVerbs.Post,
    );
  }

  payrollUnsubscribe(importId: string) {
    const url = this.getFullUrl(`/payroll-imports/${importId}/send-email`);
    return Api.fetchGateway(url, null, HttpVerbs.Delete);
  }

  deleteAccount(accountId: string, email: string) {
    const url = this.getFullUrl('/delete-request');
    const body = {
      accountId,
      email,
      marketAccount: accountId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  setupAndAttachToken(
    accountBalanceId: string,
    accountId: string,
    cardHolder: string,
    cardIssuer: string,
    expirationMonth: number,
    expirationYear: number,
    name: string,
    provider: number,
    token: string,
    zip: string,
    useTally = false,
    isDefault = false,
  ) {
    let url: string;
    const body = {
      accountBalanceId,
      accountId,
      cardHolder,
      cardIssuer,
      expirationMonth,
      expirationYear,
      name,
      provider,
      token,
      zip,
      isDefault,
    };

    if (useTally) {
      url = this.getLegacyUrl('/actions/tokens/setup-and-attach');
      return Api.fetch(url, body, HttpVerbs.Post, false);
    } else {
      url = this.getFullUrl('/actions/tokens/setup-and-attach');
      return Api.fetchGateway(url, body, HttpVerbs.Post);
    }
  }

  addFundsToken(
    accountId: string,
    accountBalanceId: string,
    tokenId: string,
    amount: number,
    transactionDate: string,
  ) {
    const url = this.getFullUrl('/actions/tokens/fund');
    const body = {
      accountId,
      accountBalanceId,
      balanceTokenId: tokenId,
      amount: roundNumber(amount, 2),
      transactionDate,
      kioskId: '365Pay',
      deviceName: '365Pay',
      autoFund: false,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  addFundsPayrollDeduct(
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transactionDate: string,
    payrollIdentifierId: string,
    transactionId: string,
  ) {
    const url = this.getFullUrl('/actions/payrolls/fund');
    const body = {
      accountId,
      accountBalanceId,
      amount: roundNumber(amount, 2),
      transactionDate,
      kioskId: '365Pay',
      deviceName: '365Pay',
      payrollIdentifierId,
      transactionId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }
  /** gma v2  */
  chargeCard(
    accountId: string,
    amount: number,
    locationId: string,
    accountBalanceId: string,
    balanceTokenId: string,
  ) {
    const url = this.getFullUrl('/actions/tokens/charge');
    const postObject = {
      accountId,
      accountBalanceId,
      balanceTokenId,
      locationId,
      amount,
    };
    return Api.fetchGateway(url, postObject, HttpVerbs.Post);
  }

  addFundsThirdParty(
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transactionDate: string,
    data: string,
    processor: number,
    sandbox: boolean,
    type: string,
  ) {
    const url = this.getFullUrl('/actions/3rdparties/fund');
    const body = {
      accountId,
      accountBalanceId,
      amount: roundNumber(amount, 2),
      transactionDate,
      kioskId: '365Pay',
      deviceName: '365Pay',
      data,
      processor,
      sandbox,
      type,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  deleteCreditCard(
    accountId: string,
    balanceTokenId: string,
    accountBalanceId: string,
  ) {
    const url = this.getFullUrl('/actions/tokens');
    const body = {
      accountId,
      accountBalanceId,
      balanceTokenId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Delete);
  }

  setupAccount(setupModel: SetupModel) {
    const url = Api.getGatewayUrl(`gma/accounts/${setupModel.accountId}`);
    return Api.fetchGateway(url, setupModel, HttpVerbs.Patch);
  }

  getPaymentCredentials(accountId: string) {
    const url = this.getFullUrl(`/accounts/${accountId}/payment-credentials`);
    return Api.fetchGateway(url, null, HttpVerbs.Get, false);
  }

  getPaymentCredentialsTally(accountId: string) {
    const url = this.getLegacyUrl(`/accounts/${accountId}/payment-credentials`);
    return Api.fetch(url, null, HttpVerbs.Get, false);
  }

  getFullUrl(path: string, params?: any) {
    const baseUrl = Api.getGatewayUrl(UrlTypes.appapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  getLegacyUrl(path: string, params?: any) {
    const baseUrl = Api.getTallyUrl(UrlTypes.legacyappapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  /**gma v2 load promotions */
  loadPromotionsV2(locationId: string) {
    if (locationId) {
      const url = this.getFullUrl('/promotions/v2', {
        locationId,
      });
      return Api.fetchGateway(url, null, HttpVerbs.Get);
    } else {
      return {};
    }
  }
  issueRefund(
    transactionId: string,
    accountId: string,
    transactionDate: string,
    accountBalanceId: string,
  ) {
    const url = this.getFullUrl('/actions/transactions/refund');
    const postObject = {
      transactionId,
      transactionDate,
      accountId,
      accountBalanceId,
    };
    return Api.fetchGateway(url, postObject, HttpVerbs.Post);
  }
  updateAppEvent(
    accountId: string,
    eventName: string,
    utcEventDate: string,
    localEventDate: string,
    localEventTimeZone: string,
    additionalData?: string,
  ) {
    const url = this.getFullUrl(`/actions/accounts/${accountId}/track-event`);
    const postObject = {
      eventName,
      utcEventDate,
      localEventDate,
      localEventTimeZone,
      additionalData,
    };
    return Api.fetchGateway(url, postObject, HttpVerbs.Post);
  }
  /**gma v2 setup intent stripe */
  createSetupIntent(accountId: string) {
    const url = this.getFullUrl(
      `/actions/accounts/${accountId}/stripeSetupIntent`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }
  canUserMakePurchase(accountId: string, orgId: string) {
    const url = this.getFullUrl(
      `/actions/accounts/${accountId}/can-user-make-purchase/${orgId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }
  addFundsApplePay(
    applePaymentId: string,
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transdate: string,
    processor: number,
    sandbox: boolean,
  ) {
    const url = this.getFullUrl('/actions/3rdparties/fund');

    const postObject = {
      amount,
      accountId,
      accountBalanceId,
      data: applePaymentId,
      transactionDate: transdate,
      processor,
      sandbox,
      type: 'applepay',
      kioskId: '365Pay',
      deviceName: '365Pay',
    };

    Events.Info.trackEvent('AppApi:addFundsApplePay', postObject);

    return Api.fetchGateway(url, postObject, HttpVerbs.Post);
  }

  fetchPaymentIntentClientSecret(
    data: any,
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transactionDate: string,
    processor: number,
    sandbox: boolean,
    type: string,
  ) {
    const url = this.getFullUrl('/actions/3rdparties/fund-payment-intent');
    const postObject = {
      amount,
      accountId,
      accountBalanceId,
      transactionDate,
      processor,
      data,
      sandbox,
      type,
      kioskId: '365Pay',
      deviceName: '365Pay',
    };
    return Api.fetchGateway(url, postObject, HttpVerbs.Post);
  }

  getAccountHistory(accountId: string, offset: number, limit: number) {
    if (!accountId) {
      return {
        items: [],
        metadata: {
          pagination: {
            limit,
            offset,
            previousOffset: null,
            nextOffset: null,
            currentPage: null,
            pageCount: 0,
            totalCount: 0,
          },
          sortedBy: [],
        },
      };
    }

    if (accountId && accountId !== '-1') {
      const url = this.getFullUrl(`/accounts/${accountId}/balance-history`, {
        offset,
        limit,
      });

      return Api.fetchGateway(url, null, HttpVerbs.Get);
    }
  }

  fetchRedemptionThresholds(accountId: string) {
    const url = this.getFullUrl(
      `/accounts/${accountId}/loyalty-redemption-thresholds`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchAnnouncements(locationId: string) {
    const url = this.getFullUrl(`/announcements/locations/${locationId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }
  /** confirm payment intent gateway endpoint */
  confirmPaymentIntent(
    accountId: string,
    accountBalanceId: string,
    paymentIntentId: string,
    transactionId: string,
    balanceTokenId: string,
  ) {
    const body = {
      accountId,
      accountBalanceId,
      paymentIntentId,
      transactionId,
      balanceTokenId,
    };
    const url = this.getFullUrl('/actions/tokens/confirm-fund-intent');
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }
}

export default new AppApi();
