import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Platform,
  PixelRatio,
} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import type {MenuOption} from 'src/types/MenuOption';
import {TextInput, Menu, Text} from 'react-native-paper';
import AVText from './AVText';
import {useComponentSize} from '../Hooks';
import AllyTextInput from 'src/components/elements/AllyTextInput';

const maxWidth = Dimensions.get('screen').width - 30;
const maxHeight = Dimensions.get('screen').height * 0.5;

type NBDropdownProps = React.ComponentProps<typeof TextInput> & {
  onSelect: (value: string) => void;
  selectedValue?: string | number;
  options: Array<MenuOption>;
  label: string;
  defaultLabel?: string;
  flexWidth?: boolean;
  anchorPosition?: string;
  accessibilityState?: string;
  accessibilityRole?: string;
  accessibilityLabel?: string;
  accessibilityValue?: {[key: string]: string};
  onVisibilityChange?: (visible: boolean) => void;
};

const NBDropdown: React.FC<NBDropdownProps> = (props) => {
  const [dropdownSize, onLayout] = useComponentSize();
  const [menuOpen, setMenu] = useState(false);

  const onOpen = () => {
    setMenu(true);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(true);
    }
  };

  const onClose = () => {
    setMenu(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
  };

  const selectedOption = props.options.find(
    (option) => option.value == props.selectedValue,
  );
  const title = selectedOption?.text || props.defaultLabel || '';
  let baseText;

  if (props.flexWidth) {
    baseText = (
      <AVText
        style={[
          styles.flexWidthBase,
          Styles.Style.font500,
          {color: Styles.darkColor},
        ]}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityRole="text"
        aria-label={`${props.accessibilityLabel}, text`}
      >
        {title}
      </AVText>
    );
  } else {
    baseText = (
      <AllyTextInput
        usedInDropdowns={true}
        pointerEvents="none"
        label={props.label}
        placeholder={props.placeholder}
        onChangeText={undefined}
        editable={false}
        value={title}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityValue={{text: props.value}}
        accessibilityState={{disabled: true}}
        disabled={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
      />
    );
  }

  const options = props.options.map(
    (item: {text: string; value: string; image: string}, index: number) => {
      return (
        <View key={index}>
          <TouchableOpacity
            accessible={true}
            accessibilityRole="menuitem"
            accessibilityLabel={item.text}
            aria-label={item.text}
            role="menuitem"
            style={[
              styles.option,
              {
                width: dropdownSize?.width || maxWidth,
              },
            ]}
            onPress={() => {
              props.onSelect(`${item.value}`);
              onClose();
            }}
            key={item.value}
          >
            {item.image && (
              <View style={styles.itemImageStyle}>{item.image}</View>
            )}
            <Text
              style={[
                styles.optionText,
                item.value === props.selectedValue && {
                  color: Styles.primaryColor,
                },
              ]}
            >
              {item.text}
            </Text>
          </TouchableOpacity>
        </View>
      );
    },
  );
  return (
    <View
      accessible={true}
      importantForAccessibility={menuOpen ? 'yes' : 'no-hide-descendants'}
      accessibilityElementsHidden={menuOpen}
      accessibilityRole="menu"
      accessibilityLabel={props.accessibilityLabel}
      accessibilityState={{expanded: menuOpen}}
      role="menu"
      aria-label={props.accessibilityLabel}
      aria-expanded={menuOpen}
    >
      <Menu
        visible={menuOpen}
        onDismiss={onClose}
        style={{width: Platform.OS !== 'web' ? '90%' : 'auto'}}
        contentStyle={{backgroundColor: Styles.white}}
        anchorPosition={props.anchorPosition === 'top' ? 'top' : 'bottom'}
        anchor={
          <TouchableOpacity
            onLayout={onLayout}
            onPress={onOpen}
            style={[
              {
                backgroundColor: Styles.white,
                color: Styles.darkColor,
                alignContent: 'center',
              },
              props.style,
            ]}
          >
            {baseText}
            <FontAwesome5Pro
              accessibilityLabel={'Open menu icon'}
              name={'caret-down'}
              color={Styles.black}
              size={Styles.Fonts.f3}
              style={
                props.flexWidth
                  ? styles.flexWidthDropDownIcon
                  : styles.dropDownIcon
              }
              solid
            />
          </TouchableOpacity>
        }
      >
        {options}
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  dropDown: {
    maxHeight: maxHeight,
  },
  dropDownIcon: {
    position: 'absolute',
    right: 8,
    bottom: Styles.Spacing.m2 + PixelRatio.getFontScale() * 3,
    margin: Styles.Spacing.m1,
  },
  flexWidthBase: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
  },
  flexWidthContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    textAlignVertical: 'center',
  },
  flexWidthDropDownIcon: {
    margin: Styles.Spacing.m2,
  },
  option: {
    padding: Styles.Spacing.m3,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  optionText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  base: {
    paddingHorizontal: 0,
  },
  itemImageStyle: {
    alignItems: 'center',
    height: Styles.Fonts.f3,
    marginRight: Styles.Spacing.m3,
    width: 40,
  },
});
export default NBDropdown;
