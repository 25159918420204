import React, {useState} from 'react';
import BackSubheader from 'src/components/elements/BackSubheader';
import Localized from 'src/constants/AppStrings';
import {StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import AVTextInput from 'src/components/elements/AVTextInput';
import Styles from 'src/components/Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {scanGiftCard} from 'src/redux/slices/accountSlice';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

function AddEGiftCardManual() {
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>('');

  return (
    <BackSubheader title={Localized.Labels.add_card}>
      <AVTextInput
        placeholder={Localized.Labels.enter_egift_card_number}
        style={styles.textInput}
        placeholderTextColor={Styles.lightGray}
        onChangeText={setCode}
        value={code}
      />
      <RoundedButton
        text={Localized.Buttons.add_card}
        buttonType={ButtonType.action}
        onPress={() => {
          FirebaseAnalytic.trackEvent(
            'Localized.Buttons.add_card',
            'AddEGiftCardManual',
            {
              code,
            },
          );
          dispatch(
            scanGiftCard({
              code,
              callback: () =>
                ActionsFactory.getAccountActions().reloadConsumerData({
                  accountId: AccountStore.getAccountId(),
                  accountBalanceId: AccountStore.getAccountBalanceId(),
                  email: AccountStore.getEmail(),
                  userInitiated: true,
                }),
            }),
          );
        }}
        disabled={code === ''}
      />
    </BackSubheader>
  );
}

const styles = StyleSheet.create({
  addBtnView: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3,
  },
  textInput: {
    marginHorizontal: Styles.Spacing.m3,
    fontWeight: 'bold',
  },
});

export default AddEGiftCardManual;
