import {Characteristic} from 'react-native-ble-plx';
import {encodeValue, Radix} from '../Encoding';
import {PaymentRequest} from './PaymentRequestCharacteristic';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
import {decrypt} from '../../AESEncryption';
const paymentStatusMeta = {
  uniqueIdLength: 16,
  counterLength: 8,
  selectionIdLength: 8,
  priceLength: 8,
  statusLength: 8,
  crcLength: 16,
  encryptedLength: 64,
};
export const SelectionStatus = {
  standby: 0,
  waitForApproval: 1,
  selectionDeny: 2,
  started: 3,
  selectionOk: 4,
  selectionKo: 5,
};
export default class PaymentRequestCharacteristic {
  characteristic: Characteristic;
  readInterval: any;
  statusSent: boolean;

  constructor(characteristic: Characteristic) {
    this.characteristic = characteristic;
  }

  async getPaymentStatus(publicKeyHex: string): Promise<PaymentStatus | null> {
    const readCharacteristic = await this.characteristic.read();

    if (readCharacteristic.value && !this.statusSent) {
      const paymentStatusPayloadHex = encodeValue(
        readCharacteristic.value,
        'base64',
        'hex',
      );
      Events.VendorsExchange.trackEvent(VendorsExchangeActions.PaymentStatus, {
        uuid: this.characteristic.uuid,
        value: paymentStatusPayloadHex,
      });
      return await PaymentStatus.getPaymentStatus(
        paymentStatusPayloadHex,
        publicKeyHex,
      );
    }

    return null;
  }
}
export class PaymentStatus {
  paymentRequest: PaymentRequest;
  status: number;

  static async getPaymentStatus(
    encryptedStatusHex: string,
    publicKeyHex: string,
  ) {
    const encryptedPortion = encryptedStatusHex.substring(
      0,
      paymentStatusMeta.encryptedLength,
    );
    const remainingPortion = encryptedStatusHex.substring(
      paymentStatusMeta.encryptedLength,
    );
    const decryptedPortion = await decrypt(encryptedPortion, publicKeyHex);
    const paymentStatusHex = decryptedPortion + remainingPortion;
    return new PaymentStatus(paymentStatusHex);
  }

  // 51f95d24000000000d000000333231000000000000000002000000007e73cebd0000000000
  constructor(paymentStatusHex: string) {
    this.paymentRequest = new PaymentRequest(paymentStatusHex);
    this.status = this.getStatus(paymentStatusHex);
  }

  getStatus(paymentStatusHex: string): number {
    const statusHex = paymentStatusHex.substring(
      paymentStatusMeta.uniqueIdLength +
        paymentStatusMeta.counterLength +
        paymentStatusMeta.selectionIdLength +
        paymentStatusMeta.priceLength,
      paymentStatusMeta.uniqueIdLength +
        paymentStatusMeta.counterLength +
        paymentStatusMeta.selectionIdLength +
        paymentStatusMeta.priceLength +
        paymentStatusMeta.statusLength,
    );
    return parseInt(statusHex, Radix.Decimal);
  }
}
