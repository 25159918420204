import Localized from 'src/constants/AppStrings';
import Alert, {AlertOptions} from 'src/nativeModules/Alert';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';

export function alertError(
  text: any,
  guid: string | null = null,
  callback?: () => void,
  title: string = Localized.Errors.uh_oh,
  options?: {
    isEscapeHTML?: boolean;
  },
) {
  if (guid) {
    text = `${text}\n\n${Localized.Labels.formatString(
      Localized.Labels.support_id,
      guid,
    )}`;
  }

  FirebaseAnalytic.trackEvent('alertError', 'AlertHelper', {
    text,
    guid,
    title,
  });

  Alert.alert(
    title,
    text,
    [
      {
        text: Localized.Labels.ok,
        onPress: callback,
      },
    ],
    {
      onDismiss: callback,

      isEscapeHTML: options?.isEscapeHTML,
    } as AlertOptions,
  );
}
export function alertSuccess(
  text: any,
  callback?: () => void,
  title: string = Localized.Success.success,
) {
  FirebaseAnalytic.trackEvent('alertSuccess', 'AlertHelper', {
    text,
    title,
  });

  Alert.alert(
    title,
    text,
    [
      {
        text: Localized.Labels.ok,
        onPress: callback,
      },
    ],
    {
      onDismiss: callback,
    },
  );
}
export function alertInProgress(
  text: any,
  callback?: () => void | Promise<void>,
  title: string = Localized.Success.working,
) {
  FirebaseAnalytic.trackEvent('alertInProgress', 'AlertHelper', {
    text,
    title,
  });
  Alert.alert(
    title,
    text,
    [
      {
        text: Localized.Buttons.cancel,
        onPress: () => null,
        style: 'cancel',
      },
      {
        text: Localized.Buttons.continue,
        onPress: callback,
      },
    ],
    {
      onDismiss: callback,
    },
  );
}
export function networkError() {
  Alert.alert(
    Localized.Errors.network_unavailable,
    Localized.Errors.network_not_available,
  );
}
export function confirm(
  text = '',
  handleYes?: () => void,
  handleNo?: () => void,
  title: string = Localized.Labels.confirm,
  button1Text: string = Localized.Labels.no,
  button2Text: string = Localized.Labels.yes,
) {
  Alert.alert(
    title,
    text,
    [
      {
        text: button1Text,
        onPress: handleNo,
      },
      {
        text: button2Text,
        onPress: handleYes,
      },
    ],
    {
      cancelable: false,
    },
  );
}

export function confirmNonDismissable(
  text = '',
  handleYes?: () => void | Promise<void>,
  title: string = Localized.Labels.confirm,
  button1Text: string = Localized.Labels.no,
) {
  Alert.alert(
    title,
    text,
    [
      {
        text: button1Text,
        onPress: handleYes,
      },
    ],
    {
      cancelable: false,
    },
  );
}
