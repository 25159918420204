//@ts-nocheck
import {Device} from 'react-native-ble-plx';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import BluetoothDevice from '../BluetoothDevice';
import PaymentConfigurationCharacteristic from './PaymentConfigurationCharacteristic';
import KeypadConfigurationCharacteristic from './KeypadConfigurationCharacteristic';
import {encodeValue, Radix} from '../Encoding';
import PaymentStartCharacteristic from './PaymentStartCharacteristic';
import PaymentRequestCharacteristic, {
  PaymentRequest,
} from './PaymentRequestCharacteristic';
import PaymentApprovalCharacteristic from './PaymentApprovalCharacteristic';
import PaymentStatusCharacteristic from './PaymentStatusCharacteristic';
import PaymentNotifyCharacteristic, {
  PaymentNotifyEvents,
} from './PaymentNotifyCharacteristic';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
import type {VendingMachine} from '../VendingMachineInterface';
export const VendorsExchangeDeviceEvents = {
  paymentRequested: 'paymentRequested',
  statusUpdated: 'statusUpdated',
};
export default class VendorsExchangeDevice
  extends BluetoothDevice
  implements VendingMachine
{
  deviceId = '';
  deviceName = '';
  letter: string | null | undefined = '';
  color: string | null | undefined = '';
  operator = false;
  transactionId = '';
  imageUrl: string | null | undefined = '';
  hidePlanogram: boolean | null | undefined = true;
  readOnly: boolean | null | undefined = false;
  paymentConfiguration: PaymentConfigurationCharacteristic;
  keypadConfiguration: KeypadConfigurationCharacteristic;
  paymentStatus: PaymentStatusCharacteristic;
  paymentStart: PaymentStartCharacteristic;
  paymentRequest: PaymentRequestCharacteristic;
  paymentApproval: PaymentApprovalCharacteristic;
  paymentNotify: PaymentNotifyCharacteristic;
  onPaymentRequested: (paymentRequest: PaymentRequest) => void;
  uuids: any = {
    keypad: {
      service: '0000aa00-fcf1-11ea-8bdc-005056c00008',
      characteristics: {
        configuration: '0000aa02-fcf1-11ea-8bdc-005056c00008',
      },
    },
    payment: {
      service: '0000ab00-fcf1-11ea-8bdc-005056c00008',
      characteristics: {
        configuration: '0000ab01-fcf1-11ea-8bdc-005056c00008',
        start: '0000ab02-fcf1-11ea-8bdc-005056c00008',
        request: '0000ab03-fcf1-11ea-8bdc-005056c00008',
        approval: '0000ab04-fcf1-11ea-8bdc-005056c00008',
        status: '0000ab05-fcf1-11ea-8bdc-005056c00008',
        notify: '0000ab06-fcf1-11ea-8bdc-005056c00008',
      },
    },
  };

  constructor(device: Device) {
    super(device);
    this.deviceName = device.name;
    this.paymentServiceUpdated = this.paymentServiceUpdated.bind(this);

    if (device.manufacturerData) {
      this.deviceId = encodeValue(device.manufacturerData, 'base64', 'hex');
    }
  }
  index: number;
  rawName?: string;
  localType?: string;
  distance?: number;
  incompatibleCurrency?: boolean;
  ccNotConfigured?: boolean;
  incompatibleOrg?: boolean;
  orderAhead?: boolean;
  hiatusMode?: boolean;
  broadcastid?: string;
  id: string;
  beacon?: boolean;
  locationType: string;

  get keypadType(): string {
    return this.keypadConfiguration.keypadType;
  }

  get type(): string {
    return BluetoothDeviceTypes.vendorsExchange;
  }

  get name(): string {
    return this.deviceName;
  }

  get currency(): string {
    if (this.paymentConfiguration) {
      return this.paymentConfiguration.currencyCode;
    }

    return 'unknown';
  }

  setDeviceName(deviceName: string) {
    this.deviceName = deviceName;
  }

  async connect() {
    await super.connect();
    await this.setupKeypadCharacteristics();
    await this.setupPaymentCharacteristics();
  }

  disconnect(): Promise<Device> {
    if (this.paymentNotify) {
      this.paymentNotify.deregister();
    }

    return super.disconnect();
  }

  async setupKeypadCharacteristics() {
    const keypadConfigurationCharacteristic =
      await this.device.readCharacteristicForService(
        this.uuids.keypad.service,
        this.uuids.keypad.characteristics.configuration,
      );
    this.keypadConfiguration = new KeypadConfigurationCharacteristic(
      keypadConfigurationCharacteristic.value,
      keypadConfigurationCharacteristic.uuid,
    );
  }

  async setupPaymentCharacteristics() {
    const paymentCharacteristics = await this.device.characteristicsForService(
      this.uuids.payment.service,
    );

    for (const characteristic of paymentCharacteristics) {
      if (
        characteristic.uuid === this.uuids.payment.characteristics.configuration
      ) {
        this.paymentConfiguration = new PaymentConfigurationCharacteristic(
          characteristic,
        );
        await this.paymentConfiguration.parsePaymentConfig();
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.configuration,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      } else if (
        characteristic.uuid === this.uuids.payment.characteristics.start
      ) {
        this.paymentStart = new PaymentStartCharacteristic(characteristic);
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.start,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      } else if (
        characteristic.uuid === this.uuids.payment.characteristics.request
      ) {
        this.paymentRequest = new PaymentRequestCharacteristic(characteristic);
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.request,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      } else if (
        characteristic.uuid === this.uuids.payment.characteristics.approval
      ) {
        this.paymentApproval = new PaymentApprovalCharacteristic(
          characteristic,
        );
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.approval,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      } else if (
        characteristic.uuid === this.uuids.payment.characteristics.status
      ) {
        this.paymentStatus = new PaymentStatusCharacteristic(characteristic);
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.status,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      } else if (
        characteristic.uuid === this.uuids.payment.characteristics.notify
      ) {
        this.paymentNotify = new PaymentNotifyCharacteristic(characteristic);
        this.paymentNotify.on(
          PaymentNotifyEvents.updated,
          this.paymentServiceUpdated,
        );
        Events.VendorsExchange.trackEvent(
          VendorsExchangeActions.CharacteristicFound,
          {
            uuid: this.uuids.payment.characteristics.notify,
            isWriteableWithResponse: characteristic.isWritableWithResponse,
            isNotifiable: characteristic.isNotifiable,
          },
        );
      }
    }
  }

  async paymentServiceUpdated(base64Value: string) {
    const hexValue = encodeValue(base64Value, 'base64', 'hex');

    if (hexValue.length === 4) {
      const notifySectionHex = hexValue.substring(hexValue.length - 2);
      const decimalValue = parseInt(notifySectionHex, Radix.Hex);
      const binaryValue = decimalValue.toString(Radix.Binary).padStart(6, '0');
      const readConfiguration = binaryValue.substring(4, 6) === '01';
      const readRequest = binaryValue.substring(2, 4) === '01';
      const readStatus = binaryValue.substring(0, 2) === '01';

      if (readConfiguration) {
        this.paymentConfiguration.parsePaymentConfig();
      }

      if (readRequest) {
        const paymentRequest = await this.paymentRequest.getPaymentRequest(
          this.paymentConfiguration.publicKey,
        );

        if (paymentRequest) {
          this.emit(
            VendorsExchangeDeviceEvents.paymentRequested,
            paymentRequest,
          );
        }
      }

      if (readStatus) {
        const paymentStatus = await this.paymentStatus.getPaymentStatus(
          this.paymentConfiguration.publicKey,
        );

        if (paymentStatus) {
          this.emit(VendorsExchangeDeviceEvents.statusUpdated, paymentStatus);
        }
      }
    }
  }

  async startPayment(
    userId: string,
    credit: number,
    selections: string[],
    currencyCode = 'USD',
    revalueCapable = false,
  ) {
    await this.paymentStart.startPayment(
      userId,
      credit,
      selections,
      currencyCode,
      revalueCapable,
    );
  }

  approvePayment(paymentRequest: PaymentRequest, approved: boolean) {
    return this.paymentApproval.approvePayment(
      paymentRequest,
      approved,
      this.paymentConfiguration.publicKey,
    );
  }

  setColor(color: string | null | undefined) {
    this.color = color;
  }

  setLetter(letter: string | null | undefined) {
    this.letter = letter;
  }

  setImageUrl(imageUrl: string | null | undefined) {
    this.imageUrl = imageUrl;
  }

  setHidePlanogram(hidePlanogram: boolean | null | undefined) {
    this.hidePlanogram = hidePlanogram;
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  setReadOnly(readOnly: boolean | null | undefined) {
    this.readOnly = readOnly;
  }
}
