import Settings from 'src/Settings';

// Import the functions you need from the SDKs you need
import {initializeApp} from '@firebase/app';
import {
  getAnalytics,
  logEvent,
  setUserId as setUserIdFB,
  setUserProperties,
} from '@firebase/analytics';

const firebaseConfig = (buildType: string) => {
  let config = {};
  switch (buildType) {
    case 'canteen':
      config = {
        apiKey: 'AIzaSyBQrO2yL9lQZQwHH480Lmftl3n2psMvFyQ',
        authDomain: 'connect-and-pay-push.firebaseapp.com',
        databaseURL: 'https://connect-and-pay-push.firebaseio.com',
        projectId: 'connect-and-pay-push',
        storageBucket: 'connect-and-pay-push.appspot.com',
        messagingSenderId: '428371404653',
        appId: '1:428371404653:web:5ce48e52faf45f3043dc64',
        measurementId: 'G-4WTWB6FXLB',
      };
      break;
    case 'pantry':
      config = {
        apiKey: 'AIzaSyCBxAE6KEAmeo_aUDAngtzyf9RBZTYZLYQ',
        authDomain: 'canteen-pantry-push.firebaseapp.com',
        databaseURL: 'https://canteen-pantry-push.firebaseio.com',
        projectId: 'canteen-pantry-push',
        storageBucket: 'canteen-pantry-push.appspot.com',
        messagingSenderId: '984050321150',
        appId: '1:984050321150:web:9cf47044fd87b1b3ea87af',
        measurementId: 'G-8WJW0GV56Y',
      };
      break;
    case 'social':
      config = {
        apiKey: 'AIzaSyDK6O9CPznrCXgPZah4dklbiyD09I2Zzjc',
        authDomain: 'socia-feedia.firebaseapp.com',
        databaseURL: 'https://socia-feedia.firebaseio.com',
        projectId: 'socia-feedia',
        storageBucket: 'socia-feedia.appspot.com',
        messagingSenderId: '273433977358',
        appId: '1:273433977358:web:d032232f0f331ce061d25f',
        measurementId: 'G-MXXDDB3J0V',
      };
      break;
    case 'market':
      config = {
        apiKey: 'AIzaSyC4POipNFkxu8EOVM69BUtQePdv7GLTH8U',
        authDomain: 'market-connect-6be64.firebaseapp.com',
        databaseURL: 'https://market-connect-6be64.firebaseio.com',
        projectId: 'market-connect-6be64',
        storageBucket: 'market-connect-6be64.appspot.com',
        messagingSenderId: '636131625540',
        appId: '1:636131625540:web:29eb358188bef4f24f8a11',
        measurementId: 'G-PB04801FSC',
      };
      break;
    default:
      config = {
        apiKey: 'AIzaSyAT5RzUz1CPITaqMRoxYWojQUvUarpHYr0',
        authDomain: 'paypush-742f6.firebaseapp.com',
        databaseURL: 'https://paypush-742f6.firebaseio.com',
        projectId: 'paypush-742f6',
        storageBucket: 'paypush-742f6.appspot.com',
        messagingSenderId: '791499406276',
        appId: '1:791499406276:web:2bf9d0c7bbe57aa0eb4291',
        measurementId: 'G-C8KML1X798',
      };
      break;
  }
  return config;
};

// Initialize Firebase
const app = initializeApp(firebaseConfig(Settings.buildType));
const analytics = getAnalytics(app);
type Properties = Record<string, string | object>;

function trackEvent(
  eventName: string,
  screenName: string,
  properties?: Properties,
) {
  //firebase web analytics
  logEvent(analytics, eventName, {...properties, screenName});
}

function trackPageView(screen: string, properties?: Properties) {
  logEvent(analytics, 'screen_view', {
    firebase_screen: screen,
    firebase_screen_class: screen,
    ...properties,
  });
}

function setUserId(accountId: string, properties: Record<string, string>) {
  setUserIdFB(analytics, accountId);
  setUserProperties(analytics, properties);
}

export default {
  trackEvent,
  trackPageView,
  setUserId,
};
