import * as React from 'react';
export default function MachinesProvider(accountId: string) {
  const [linkedLocations, setLinkedLocations] = React.useState([]);
  const [vendingMachines, setVendingMachines] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const [beaconMarkets, setBeaconMarkets] = React.useState([]);
  const [coffeeMachines, setCoffeeMachines] = React.useState([]);
  const [unattachedMachines, setUnattachedMachines] = React.useState([]);
  return {
    state: {
      linkedLocations,
      vendingMachines,
      devices,
      beaconMarkets,
      coffeeMachines,
      unattachedMachines,
    },
    actions: {},
  };
}
