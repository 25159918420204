import React from 'react';
import {StyleSheet, View, Text, Platform} from 'react-native';
import {CheckBox} from 'react-native-elements';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import RadioForm, {RadioButton} from 'react-native-simple-radio-button';
import CustomRadiobutton from '../CustomRadioButton';
import {ProductModifierType} from 'src/types/Menu';
import Styles from '../../Styles';
import {checkValue, formatCurrency} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import AllyTextInput from 'src/components/elements/AllyTextInput';

type ProductModifierProps = WithGlobalizeProps & {
  productModifier: ProductModifierType;
  selectionChanged: (modifierId: string, selections: Array<string>) => void;
  selectedValues?: Array<string>;
  showCalories?: boolean;
  showAsKiloCalories?: boolean;
  sectionCalories?: [];
};
type ProductModifierState = {
  values: Array<string>;
  radio: boolean;
  required: boolean;
  freeText: boolean;
  showUnitName: string;
};
const FREE_TEXT_MAX_LENGTH = 50;
export const ModifierTypes = {
  FreeText: 4,
  Upsell: 5,
};

class ProductModifier extends React.Component<
  ProductModifierProps,
  ProductModifierState
> {
  constructor(props: ProductModifierProps) {
    super(props);
    const radio =
      props.productModifier.maxsel === 1 &&
      props.productModifier.type !== ModifierTypes.Upsell;
    let values = props.productModifier.vals
      .filter((value) => value.isdefault === 'Y')
      .map((value) => value.id);

    if (this.props.selectedValues) {
      values = this.props.selectedValues;
    }
    if (!values) {
      values = [];
    }

    this.state = {
      values,
      radio,
      required: props.productModifier.forced === 'Y',
      freeText: props.productModifier.type === ModifierTypes.FreeText,
      showUnitName: props.showAsKiloCalories ? Localized.Labels.kcal : 'Cal',
    };

    if (values.length > 0) {
      props.selectionChanged(props.productModifier.id, values);
    }
    this.onPress = this.onPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onPress(value: string) {
    let values: Array<string> = [];

    if (this.state.radio) {
      if (!this.state.values.includes(value)) {
        values = [value];
      }
    } else {
      if (this.state.values.includes(value)) {
        values = this.state.values.filter((item) => item !== value);
      } else if (
        this.state.values.length < this.props.productModifier.maxsel ||
        this.props.productModifier.type === ModifierTypes.Upsell
      ) {
        values = [...this.state.values, value];
      } else {
        // values haven't changed
        values = [...this.state.values];
      }
    }

    this.setState({values}, () =>
      this.props.selectionChanged(
        this.props.productModifier.id,
        this.state.values,
      ),
    );
  }

  renderCheckBoxes() {
    return this.props.productModifier.vals.map((value) => {
      let price = '';
      if (value.price !== null) {
        price = `(+${formatCurrency(
          this.props,
          value?.price,
          AccountStore.getCurrency(),
        )})`;
      }
      let calories = '';
      if (checkValue(value.calories)) {
        calories = value.calories.toString() + ' calories';
      }
      return (
        <CheckBox
          key={value.id}
          accessible={true}
          accessibilityLabel={`${value.name}, ${price}, ${calories}`}
          aria-label={`${value.name}, ${price}, ${calories}`}
          accessibilityRole="checkbox"
          accessibilityState={{
            checked: this.state.values.includes(value.id),
          }}
          aria-checked={this.state.values.includes(value.id)}
          role="checkbox"
          title={
            <View style={styles.checkboxRow}>
              <View style={styles.checkboxNameContainer}>
                <Text
                  style={{
                    marginLeft: Styles.Spacing.m1,
                    color: Styles.darkColor,
                  }}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                >
                  {value.name}
                </Text>
              </View>
              {price !== null && (
                <View style={styles.checkboxPriceContainer}>
                  <Text
                    style={{color: Styles.darkColor}}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                  >
                    {price}
                  </Text>
                </View>
              )}
              {this.props.showCalories && checkValue(value.calories) && (
                <View style={styles.checkboxCalorieContainer}>
                  <Text
                    style={{color: Styles.darkColor}}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                  >
                    {`${value?.calories} ${this.state.showUnitName}`}
                  </Text>
                </View>
              )}
            </View>
          }
          checked={this.state.values.includes(value.id)}
          size={Styles.Fonts.f2}
          textStyle={[styles.optionText, styles.checkBoxText]}
          containerStyle={styles.checkBoxWrapper}
          onPress={() => this.onPress(value.id)}
          checkedColor={Styles.lightGray}
          uncheckedColor={Styles.lightGray}
        />
      );
    });
  }

  renderRadioButtons() {
    const buttons = this.props.productModifier.vals.map((value) => {
      let price = '';
      if (value.price !== null) {
        price = `(+${formatCurrency(
          this.props,
          value?.price,
          AccountStore.getCurrency(),
        )})`;
      }

      return {
        label: value.name,
        price,
        value: value.id,
        calories: value?.calories,
      };
    });
    return (
      <RadioForm>
        {buttons.map((obj, i) => {
          const isSelected = this.state.values.includes(obj.value);
          return (
            <View
              accessible={true}
              accessibilityRole="radio"
              accessibilityState={{
                selected: isSelected,
              }}
              key={obj.value + '-' + i}
              style={styles.customizationDivider}
            >
              <RadioButton key={i} style={styles.radioButtonWrapper}>
                <CustomRadiobutton
                  selected={isSelected}
                  accessibilityLabel={obj.label}
                  aria-label={obj.label}
                  onPress={() => this.onPress(obj.value)}
                />
                <View style={styles.checkboxRow}>
                  <View style={styles.checkboxNameContainer}>
                    <Text
                      accessibilityLabel={`${obj.label}, `}
                      aria-label={`${obj.label}, `}
                      style={{
                        color: Styles.darkColor,
                        width: Platform.OS === 'web' ? '100%' : undefined,
                        marginLeft: Styles.Spacing.m1,
                      }}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                    >
                      {obj.label}
                    </Text>
                  </View>

                  <View style={styles.checkboxPriceContainer}>
                    {obj.price !== null && (
                      <Text
                        accessibilityLabel={`${obj.price}, `}
                        aria-label={`${obj.price}, `}
                        style={{color: Styles.darkColor}}
                        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                      >
                        {obj.price}
                      </Text>
                    )}
                  </View>

                  <View style={styles.checkboxCalorieContainer}>
                    {this.props.showCalories && checkValue(obj.calories) && (
                      <Text
                        accessibilityLabel={`${
                          obj.calories
                        } ${Localized.Labels.calories.toLowerCase()}`}
                        aria-label={`${
                          obj.calories
                        } ${Localized.Labels.calories.toLowerCase()}`}
                        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                        style={{color: Styles.darkColor}}
                      >
                        {`${obj.calories} ${this.state.showUnitName}`}
                      </Text>
                    )}
                  </View>
                </View>
              </RadioButton>
            </View>
          );
        })}
      </RadioForm>
    );
  }

  onChange(value: string) {
    const values = [value];
    this.setState(
      {
        values,
      },
      () =>
        this.props.selectionChanged(
          this.props.productModifier.id,
          this.state.values,
        ),
    );
  }

  renderFreeTextInput() {
    return (
      <AllyTextInput
        accessible={true}
        label={this.props.productModifier.name}
        accessibilityLabel={this.props.productModifier.name}
        aria-label={this.props.productModifier.name}
        value={this.state.values?.[0]}
        showHelperText={true}
        helperText={this.getCounterText(
          Localized,
          this.state.values?.[0],
        ).toString()}
        multiline={true}
        numberOfLines={3}
        onChangeText={this.onChange}
        maxLength={FREE_TEXT_MAX_LENGTH}
      />
    );
  }

  render() {
    const {
      productModifier: {name, type, maxsel},
    } = this.props;

    const {sectionCalories, showCalories, productModifier} = this.props;

    const {required, freeText, values} = this.state;
    let content: React.ReactNode = this.renderCheckBoxes();

    if (type === ModifierTypes.FreeText) {
      content = this.renderFreeTextInput();
    } else if (maxsel === 1 && type !== ModifierTypes.Upsell) {
      content = this.renderRadioButtons();
    }

    const caloricSection = (
      <>
        {sectionCalories?.map(
          (section: {id: string; sectionCalories: number}) =>
            section.id === productModifier.id && (
              <Text
                accessible={true}
                accessibilityLabel={`${section?.sectionCalories} calories for section`}
                aria-label={`${section?.sectionCalories} calories for section`}
                key={section.id}
                style={{
                  fontSize: 16,
                  marginRight: Styles.Spacing.m2,
                  fontWeight: Styles.bold,
                  color: Styles.darkColor,
                }}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {`${section?.sectionCalories} ${this.state.showUnitName}`}
              </Text>
            ),
        )}
      </>
    );
    return (
      <View style={{backgroundColor: Styles.bgColor}}>
        {!freeText && (
          <View style={styles.titleRow}>
            {required ? (
              <View style={styles.requiredViewStyles}>
                <Text
                  accessible={true}
                  accessibilityLabel={`${name} section`}
                  aria-label={`${name} section`}
                  style={styles.titleText}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                >
                  {name}
                </Text>
                <View>
                  <Text
                    accessible={true}
                    accessibilityLabel="Selection is required"
                    aria-label="Selection is required"
                    style={styles.requiredText}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                  >
                    {Localized.Labels.required}
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      flex: 1,
                    }}
                  >
                    {showCalories && caloricSection}
                  </View>
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Text
                  accessible={true}
                  accessibilityLabel={`${name} section`}
                  aria-label={`${name} section`}
                  style={styles.titleText}
                >
                  {name}
                </Text>
                {showCalories && caloricSection}
              </View>
            )}
          </View>
        )}
        <View style={styles.options}>{content}</View>
      </View>
    );
  }

  getCounterText(strings, value) {
    return `${FREE_TEXT_MAX_LENGTH - (value?.length || 0)} ${
      strings.Labels.characters_left
    }`;
  }
}

const styles = StyleSheet.create({
  checkboxRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  checkboxNameContainer: {
    flexDirection: 'column',
    marginRight: Styles.Spacing.m2,
    alignItems: 'flex-start',
    flex: 7,
  },
  checkboxPriceContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 3,
  },
  checkboxCalorieContainer: {
    flexDirection: 'column',
    flex: 3,
    alignItems: 'flex-end',
    marginRight: Styles.Spacing.m2,
    color: Styles.darkColor,
  },
  titleRow: {
    marginTop: Styles.Spacing.m3,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  titleText: {
    color: Styles.darkColor,
    fontWeight: '600',
    fontSize: Styles.Fonts.f1,
    flex: 0.8,
  },
  optionText: {
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m2,
    color: Styles.darkColor,
    marginTop: -Styles.Spacing.m1 / 2,
    marginBottom: 5,
  },
  radioText: {
    marginLeft: Styles.Spacing.m2,
    color: Styles.darkColor,
  },
  options: {
    paddingVertical: Styles.Spacing.m2,
    marginVertical: Platform.OS === 'web' ? 5 : null,
  },
  checkBoxText: {
    marginLeft: Styles.Spacing.m2,
    color: Styles.darkColor,
  },
  radioButtonWrapper: {
    padding: Styles.Spacing.m1,
    backgroundColor: Styles.white,
    marginLeft: Styles.Spacing.m0,
    justifyContent: 'center',
    borderLeftWidth: Platform.OS === 'web' ? 0 : null,
    borderRightWidth: Platform.OS === 'web' ? 0 : null,
    borderTopWidth: Platform.OS === 'web' ? 0 : null,
  },
  checkBoxWrapper: {
    padding: Styles.Spacing.m1,
    backgroundColor: Styles.white,
    marginLeft: Styles.Spacing.m0,
    margin: null,
    justifyContent: 'center',
    minHeight: Styles.Heights.h3,
    borderLeftWidth: Platform.OS === 'web' ? 0 : null,
    borderRightWidth: Platform.OS === 'web' ? 0 : null,
    borderTopWidth: Platform.OS === 'web' ? 0 : null,
  },
  customizationDivider: {
    borderBottomWidth: 1,
    borderColor: Styles.lightGray,
    borderWidth: 0,
    marginTop: Styles.Spacing.m1,
    borderRadius: null,
  },
  requiredText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.dangerColor,
    fontStyle: 'italic',
  },
  freeText: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    paddingHorizontal: Styles.Spacing.m2,
  },
  input: {
    borderRadius: Styles.Spacing.m1,
    backgroundColor: Styles.inputBgColor,
    borderWidth: 1,
    padding: Styles.Spacing.m1,
    height: Styles.Heights.touchTargetHeight2,
    marginHorizontal: Styles.Spacing.m2,
  },
  requiredViewStyles: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
export default withGlobalize(ProductModifier);
