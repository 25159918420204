import * as React from 'react';
import {View} from 'react-native';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {AccountFundingScreenProps} from './AccountFundingScreen';
import Styles from '../../Styles';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import TabRoutes from 'src/navigators/TabRoutes';
import BackSubHeader from '../../elements/BackSubheader';
import FundAccountTab from '../../elements/funding/FundAccountTab';
import TopWebBar from '../../elements/TopWebBar';
import Localized from 'src/constants/AppStrings';

class AccountFundingScreen extends React.Component<AccountFundingScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: AccountFundingScreenProps) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    NavActions.navigate(AppRoutes.Main);
  }

  render() {
    return (
      <View style={Styles.Style.flex}>
        <TopWebBar hideFunding={true} routes={TabRoutes} />
        <BackSubHeader
          title={Localized.Labels.add_funds}
          onBackSelect={this.onClose}
        >
          <FundAccountTab
            transDate={this.props.transDate}
            remainingDue={this.props.remainingDue}
            isSnackGiftDue={this.props.isSnackGiftDue}
            addFundsSuccess={this.props.addFundsSuccess}
            close={this.onClose}
          />
        </BackSubHeader>
      </View>
    );
  }
}

export default withForwardedNavigationParams()(
  withGlobalize(AccountFundingScreen),
);
