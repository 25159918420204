import type {PaymentCredentials as ServerPaymentCredentials} from 'src/types/serverTypes/Account';
import Settings from 'src/Settings';
import ApplePay from 'src/nativeModules/ApplePay';
import GooglePay from 'src/nativeModules/GooglePay';
import {addLeadingZero} from 'src/Util';

export interface PaymentCredentials {
  paymentAvailable: boolean;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  isTest: boolean;
  type: string;
  key: string;
  url: string;
  merchantId: string;
}
export default class PaymentCredentialsBuilder {
  public static async build(
    payload: ServerPaymentCredentials,
  ): Promise<PaymentCredentials> {
    const applePayAvailable = await ApplePay.canMakePaymentsApplePay(
      payload.type,
    );
    const googlePayAvailable = await GooglePay.canMakePaymentsGooglePay(
      payload.type,
      payload.key,
      payload.test,
    );
    let paymentAvailable = false;
    if (Settings.processors[payload.type]) {
      paymentAvailable = true;
    }
    let merchantId = payload.merchantId;
    if (Settings.processors[payload.type] === Settings.processors.heartland) {
      merchantId = addLeadingZero(payload.merchantId, 12);
    }
    return {
      paymentAvailable,
      applePayAvailable,
      googlePayAvailable,
      isTest: payload.test,
      type: payload.type,
      key: payload.key,
      url: payload.url,
      merchantId: merchantId,
    };
  }
}
