import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useNetInfo} from '@react-native-community/netinfo';
import Styles from '../Styles';
import {useWindowSize} from '../Hooks';
import Logo from './Logo';
import AVText from './AVText';
import NBDropdown from './NBDropdown';
import BalanceContainer from './BalanceContainer';
import RoundedButton, {ButtonType} from './RoundedButton';
import AccountStore from 'src/stores/AccountStore';
import FontAwesome5Icon from '../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
type TabRoute = {
  key: string;
  label: string;
  isActive: boolean;
  onPress: () => void;
};
type TopWebBarProps = {
  hideFunding: boolean;
  onFundingPress?: () => void;
  routes: Array<TabRoute>;
};

const TopTabBar: React.FC<TopWebBarProps> = ({
  hideFunding,
  onFundingPress,
  routes,
}) => {
  const windowSize = useWindowSize();
  const netInfo = useNetInfo();
  const small = windowSize.width <= 600;
  const tiny = windowSize.width <= 450;
  return (
    <View style={[styles.header, small && styles.headerSmall]}>
      <View style={styles.rowFlex}>
        <View style={styles.rowFlex}>
          <View style={styles.logo}>
            <Logo small />
          </View>
          <View style={styles.rowFlex}>
            {small ? (
              <View style={styles.dropdown}>
                <NBDropdown
                  flexWidth={true}
                  accessibilityLabel="Tab Dropdown"
                  onSelect={(key) =>
                    routes.find((r) => r.key === key)?.onPress()
                  }
                  selectedValue={
                    routes.find((r) => r.isActive)?.key || AppRoutes.Funding
                  }
                  options={routes.map(({key, label}) => ({
                    value: key,
                    text: label,
                  }))}
                  label={Localized.Labels.funding}
                />
              </View>
            ) : (
              routes.map(({label, isActive, key, onPress}) => (
                <TouchableOpacity
                  key={key}
                  style={styles.tab}
                  onPress={onPress}
                >
                  <AVText
                    style={isActive ? styles.tabTextActive : styles.tabText}
                    accessibilityLabel={label}
                    nativeID={label}
                  >
                    {label}
                  </AVText>
                </TouchableOpacity>
              ))
            )}
          </View>
        </View>
        <View style={styles.rowFlex}>
          {hideFunding ? (
            <View style={styles.balance}>
              <BalanceContainer strings={Localized} primaryColor />
            </View>
          ) : (
            <>
              {netInfo.isConnected && (
                <View style={styles.fundButton}>
                  {tiny ? (
                    <FontAwesome5Icon
                      name="plus-circle"
                      size={20}
                      color={Styles.primaryColor}
                      onPress={onFundingPress}
                    />
                  ) : (
                    <RoundedButton
                      text={Localized.Labels.add_funds}
                      buttonType={ButtonType.normal}
                      onPress={onFundingPress}
                    />
                  )}
                </View>
              )}
              <TouchableOpacity
                style={styles.balance}
                onPress={() => {
                  NavActions.nestedPush(
                    [AppRoutes.Settings],
                    AppRoutes.Balances,
                  );
                }}
              >
                <BalanceContainer strings={Localized} primaryColor />
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
      {!netInfo.isConnected && (
        <AVText style={styles.offline}>{`${
          Localized.Labels.as_of
        } ${AccountStore.getDisplayDateAccountUpdated()}`}</AVText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  balance: {
    padding: Styles.Spacing.m2,
  },
  dropdown: {
    alignSelf: 'center',
  },
  fundButton: {
    alignSelf: 'center',
    padding: Styles.Spacing.m2,
  },
  header: {
    backgroundColor: Styles.white,
    boxShadow: '0 0 2px 0 rgba(0,0,0,.24), 0 2px 2px 0 rgba(0,0,0,.12)',
    justifyContent: 'space-between',
    padding: '1%',
    width: '100%',
    zIndex: 1,
  },
  headerSmall: {
    padding: Styles.Spacing.m1,
  },
  logo: {
    width: '75px',
    flexDirection: 'row',
    padding: Styles.Spacing.m2,
  },
  offline: {
    alignSelf: 'flex-end',
    padding: Styles.Spacing.m2,
  },
  rowFlex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: Styles.Heights.h5,
  },
  tab: {
    alignSelf: 'center',
    padding: Styles.Spacing.m3,
  },
  tabText: {
    alignSelf: 'center',
    flex: 1,
  },
  tabTextActive: {
    alignSelf: 'center',
    flex: 1,
    color: Styles.headerColor,
  },
});
export default TopTabBar;
