export type ProductCategory = {
  key: string | number;
  name: string;
  iconName?: string;
  type?: string;
};
export const CategoryType = {
  All: 'All',
  Product: 'Product',
  Menu: 'Menu',
  TopMenu: 'TopMenu',
};
