import {Characteristic} from 'react-native-ble-plx';
import {encodeValue, Radix} from '../Encoding';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
import {decrypt} from '../../AESEncryption';
const paymentRequestMeta = {
  uniqueIdLength: 16,
  counterLength: 8,
  selectionIdLength: 8,
  priceLength: 8,
  statusLength: 8,
  emptyBytesLength: 8,
  crcLength: 8,
  encryptedLength: 64,
};
export default class PaymentRequestCharacteristic {
  characteristic: Characteristic;

  constructor(characteristic: Characteristic) {
    this.characteristic = characteristic;
  }

  async getPaymentRequest(
    publicKeyHex: string,
  ): Promise<PaymentRequest | null> {
    const readCharacteristic = await this.characteristic.read();
    const value = readCharacteristic.value;
    const paymentRequestPayloadHex = encodeValue(value, 'base64', 'hex');
    Events.VendorsExchange.trackEvent(VendorsExchangeActions.PaymentRequest, {
      uuid: this.characteristic.uuid,
      value: paymentRequestPayloadHex,
    });
    return await PaymentRequest.getPaymentRequest(
      paymentRequestPayloadHex,
      publicKeyHex,
    );
  }

  allZeroes(value: string) {
    return /^0*$/.test(value);
  }
}
export class PaymentRequest {
  id: string;
  transactionCounter: number;
  selectionId: string;
  selectionPrice: number;
  status: number;
  crc: string;

  static async getPaymentRequest(
    rawPaymentRequestHex: string,
    publicKey: string,
  ) {
    const encryptedPortion = rawPaymentRequestHex.substring(
      0,
      paymentRequestMeta.encryptedLength,
    );
    const unencryptedPortion = rawPaymentRequestHex.substring(
      paymentRequestMeta.encryptedLength,
    );
    const decryptedValue = await decrypt(encryptedPortion, publicKey);
    const unencryptedValue = decryptedValue + unencryptedPortion;
    return new PaymentRequest(unencryptedValue);
  }

  constructor(paymentRequestHex: string) {
    this.id = paymentRequestHex.substring(0, paymentRequestMeta.uniqueIdLength);
    this.transactionCounter = this.getTransactionCounter(paymentRequestHex);
    this.selectionId = this.getSelectionId(paymentRequestHex);
    this.selectionPrice = this.getSelectionPrice(paymentRequestHex);
    this.status = this.getStatus(paymentRequestHex);
    this.crc = this.getCrc(paymentRequestHex);
  }

  getTransactionCounter(paymentRequestHex: string): number {
    const counterHex = paymentRequestHex.substring(
      paymentRequestMeta.uniqueIdLength,
      paymentRequestMeta.uniqueIdLength + paymentRequestMeta.counterLength,
    );
    return parseInt(counterHex, Radix.Hex);
  }

  getSelectionId(paymentRequestHex: string): string {
    return paymentRequestHex.substring(
      paymentRequestMeta.uniqueIdLength + paymentRequestMeta.counterLength,
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength,
    );
  }

  getSelectionPrice(paymentRequestHex: string): number {
    const selectionPriceHex = paymentRequestHex.substring(
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength,
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength +
        paymentRequestMeta.priceLength,
    );
    const price = parseInt(selectionPriceHex, Radix.Hex);
    return price / 100;
  }

  getStatus(paymentRequestHex: string): number {
    const statusHex = paymentRequestHex.substring(
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength +
        paymentRequestMeta.priceLength,
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength +
        paymentRequestMeta.priceLength +
        paymentRequestMeta.statusLength,
    );
    return parseInt(statusHex, Radix.Hex);
  }

  getCrc(paymentRequestHex: string): string {
    return paymentRequestHex.substring(
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength +
        paymentRequestMeta.priceLength +
        paymentRequestMeta.statusLength +
        paymentRequestMeta.emptyBytesLength,
      paymentRequestMeta.uniqueIdLength +
        paymentRequestMeta.counterLength +
        paymentRequestMeta.selectionIdLength +
        paymentRequestMeta.priceLength +
        paymentRequestMeta.statusLength +
        paymentRequestMeta.emptyBytesLength +
        paymentRequestMeta.crcLength,
    );
  }
}
