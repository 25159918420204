import * as React from 'react';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {FlashList} from '@shopify/flash-list';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import BackSubheader from '../../elements/BackSubheader';
import CheckoutBar from '../../elements/orderAhead/CheckoutBar';
import type {LocationType} from 'src/types/Location';
import type {ProductCategory} from 'src/types/ProductCategory';
import {CategoryType} from 'src/types/ProductCategory';
import MenuService from 'src/services/MenuService';
import Styles from '../../Styles';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AccountConstants from 'src/constants/AccountConstants';
import TransactionStore from 'src/stores/TransactionStore';
import SearchProduct from '../../elements/cart/SearchProduct';
import SearchField from '../../elements/cart/SearchField';
import ProductCategories from '../../elements/cart/ProductCategories';
import type {MenuCategoryType, MenuProductType} from 'src/types/Menu';
import CartTypes from 'src/constants/cart/CartTypes';
import RecentOrdersButton from '../../elements/orderAhead/RecentOrdersButton';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import SubMenu from '../../elements/orderAhead/SubMenu';
import {DNA} from 'src/types/dnaTypes';
import MenuActions from 'src/actions/MenuActions';
import Events from 'src/logging/Events';
import uuid from 'src/nativeModules/UUID';
import MainConsumerContext from 'src/components/MainConsumerContext';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import AVText from '../../elements/AVText';
import moment from 'moment';
import TransactionActions from 'src/actions/TransactionActions';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type MenuScreenProps = WithGlobalizeProps & {
  location: LocationType;
  navigation: NavigationProp<MenuScreen>;
};
type MenuScreenState = {
  items: Array<MenuProductType | MenuCategoryType>;
  categories: Array<ProductCategory>;
  selectedCategory: ProductCategory;
  searchValue: string;
  dnaConfiguration: DNA;
  previousRoute: string | null;
};

class MenuScreen extends React.Component<MenuScreenProps, MenuScreenState> {
  pickupTimeout: any;
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  constructor(props: MenuScreenProps) {
    super(props);
    this.getMenuCategories = this.getMenuCategories.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.goToProductDetail = this.goToProductDetail.bind(this);
    this.goToCart = this.goToCart.bind(this);
    this.onCategoryPress = this.onCategoryPress.bind(this);
    this.productAdded = this.productAdded.bind(this);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.goToRecentOrders = this.goToRecentOrders.bind(this);
    this.checkMenuHasProducts = this.checkMenuHasProducts.bind(this);

    const categories = this.getMenuCategories();
    const selectedCategory = categories.length > 0 ? categories[0] : null;

    const items =
      selectedCategory?.type === CategoryType.Product
        ? []
        : selectedCategory?.type === CategoryType.TopMenu
        ? this.getSubMenus(selectedCategory?.key)
        : this.getProducts(selectedCategory?.key);

    this.state = {
      items,
      categories,
      selectedCategory,
      searchValue: '',
      dnaConfiguration: null,
      previousRoute: null,
    };
    this.pickupTimeout = TransactionStore.getPickupTime();
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'MenuScreen', {
      ...this.props,
      ...this.state,
    });

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  componentWillUnmount() {
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const categories = this.getMenuCategories();
    const selectedCategory: ProductCategory = categories?.[0];
    const items =
      selectedCategory?.type === CategoryType.Product
        ? []
        : selectedCategory?.type === CategoryType.TopMenu
        ? this.getSubMenus(selectedCategory?.key)
        : this.getProducts(selectedCategory?.key);

    this.setState(
      {
        categories,
        items,
      },
      () => {
        FirebaseAnalytic.trackEvent('onTransactionStoreChanged', 'MenuScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }
  async goToCart() {
    const pickupTime = TransactionStore.getPickupTime();
    FirebaseAnalytic.trackEvent('goToCart', 'MenuScreen', {
      ...this.props,
      ...this.state,
      marketName: this.props.location.name,
      locationId: this.props.location.locationId,
      locationType: AccountConstants.SOS_LOCATION_TYPE,
      beaconId: this.props.location.beaconId ?? this.props.location.locationId,
      cartType: CartTypes.OrderAhead,
      location: this.props.location,
      showPreparationMethod:
        this.props.location.onlineOrderConfig.hasDiningPreference,
      defaultPreparationMethod:
        this.props.location.onlineOrderConfig.diningPreference,
      navigate: AppRoutes.Scan,
    });
    const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
    const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
    const now = moment()
      .add(leadTime, 'minutes')
      .add(timeZoneOffsetMinutes, 'minutes');
    if (!moment(pickupTime.date).isAfter(now)) {
      try {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        alertError(
          Localized.Errors.pickup_time_not_available,
          undefined,
          () => {
            TransactionActions.shoppingCartTransactionsCleared();
            NavActions.replace(AppRoutes.PickupTime, {
              strings: Localized,
              location: this.props.location,
              availableTimes,
            });
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'PickupTimeScreen:PickupTimeSelected',
          error.message ? error.message : error.toString(),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'PickupTimeScreen:PickupTimeSelected',
          error.message ? error.message : error.toString(),
          guid,
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    } else {
      this.context.actions.showSpinner();
      const availableTimes = await MenuActions.getAvailableTimeSlots(
        this.props.location,
        TransactionStore.getPickupLocationId(),
      );
      if (availableTimes && availableTimes.length > 0) {
        const isTimeSlotPresent = availableTimes.some(
          (obj) =>
            obj.timeString === pickupTime.timeString &&
            moment(pickupTime.date).isSame(moment(obj.date), 'day'),
        );
        if (moment(pickupTime.date).isAfter(now) && isTimeSlotPresent) {
          this.context.actions.hideSpinner();
          NavActions.push(AppRoutes.Scan, {
            marketName: this.props.location.name,
            locationId: this.props.location.locationId,
            locationType: AccountConstants.SOS_LOCATION_TYPE,
            beaconId:
              this.props.location.beaconId ?? this.props.location.locationId,
            cartType: CartTypes.OrderAhead,
            location: this.props.location,
            showPreparationMethod:
              this.props.location.onlineOrderConfig.hasDiningPreference,
            defaultPreparationMethod:
              this.props.location.onlineOrderConfig.diningPreference,
          });
        } else {
          this.context.actions.hideSpinner();
          alertError(
            Localized.Errors.order_limit_reached_for_pickup_time,
            undefined,
            () => {
              TransactionActions.shoppingCartTransactionsCleared();
              NavActions.replace(AppRoutes.PickupTime, {
                strings: Localized,
                location: this.props.location,
                availableTimes,
              });
            },
          );
        }
      } else {
        this.context.actions.hideSpinner();
        NavActions.push(AppRoutes.Scan, {
          marketName: this.props.location.name,
          locationId: this.props.location.locationId,
          locationType: AccountConstants.SOS_LOCATION_TYPE,
          beaconId:
            this.props.location.beaconId ?? this.props.location.locationId,
          cartType: CartTypes.OrderAhead,
          location: this.props.location,
          showPreparationMethod:
            this.props.location.onlineOrderConfig.hasDiningPreference,
          defaultPreparationMethod:
            this.props.location.onlineOrderConfig.diningPreference,
        });
      }
    }
  }

  goToProductDetail = async (product: MenuProductType) => {
    try {
      this.context.actions.showSpinner();
      const response = await MenuActions.getModifiers(
        this.props.location.locationId,
        product,
      );

      FirebaseAnalytic.trackEvent('goToProductDetail', 'MenuScreen', {
        ...this.props,
        ...this.state,
        strings: Localized,
        location: this.props.location,
        product,
        response,
        navigate: AppRoutes.ProductDetail,
      });
      NavActions.push(AppRoutes.ProductDetail, {
        strings: Localized,
        alertError: alertError,
        location: this.props.location,
        product,
        productAdded: this.productAdded,
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'MenuScreen:goToProductDetail',
        error.message ? error.message : error.toString(),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuScreen:goToProductDetail',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.internet_issue, guid);
    } finally {
      this.context.actions.hideSpinner();
    }
  };

  goToRecentOrders() {
    FirebaseAnalytic.trackEvent('goToRecentOrders', 'MenuScreen', {
      ...this.props,
      ...this.state,
      location: this.props.location,
      navigate: AppRoutes.RecentOrders,
    });
    NavActions.push(AppRoutes.RecentOrders, {
      location: this.props.location,
    });
  }

  onSearchChanged(text: string) {
    const {selectedCategory} = this.state;
    const items =
      selectedCategory?.type === CategoryType.Product
        ? []
        : selectedCategory?.type === CategoryType.TopMenu
        ? MenuService.searchSubMenus(text, selectedCategory.key)
        : MenuService.searchProducts(text, selectedCategory.key);
    this.setState(
      {
        items,
        searchValue: text,
      },
      () => {
        FirebaseAnalytic.trackEvent('onSearchChanged', 'MenuScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  onCategoryPress(category: ProductCategory) {
    FirebaseAnalytic.trackEvent('onCategoryPress', 'MenuScreen', {
      ...this.props,
      ...this.state,
      category,
    });

    if (category.type === CategoryType.Product) {
      const product: MenuProductType | null = MenuService.getFirstProduct(
        category.key,
      );

      if (product) {
        this.goToProductDetail(product);
      }
    } else {
      this.setState(
        {
          selectedCategory: category,
        },
        () => this.onSearchChanged(this.state.searchValue),
      );
    }
  }

  productAdded() {
    // Clear the search
    this.onSearchChanged('');
  }

  checkMenuHasProducts(category: MenuCategoryType, type: string) {
    FirebaseAnalytic.trackEvent('checkMenuHasProducts', 'MenuScreen', {
      ...this.props,
      ...this.state,
      category,
      type,
    });

    switch (type) {
      case CategoryType.All:
        if (MenuService.searchProducts('', -1).length <= 0) {
          return false;
        }
        break;
      case CategoryType.Menu:
        if (category.products.length <= 0) {
          return false;
        }
        break;
      case CategoryType.TopMenu:
        if (MenuService.searchSubMenus('', category.id).length <= 0) {
          return false;
        }
        break;
    }
    return true;
  }

  getMenuCategories() {
    const categories = [];
    MenuService.getMenuCategories().map((category) => {
      const isProduct =
        category.isautoadd === 'Y' && category.products?.length === 1;

      const type =
        category.name === CategoryType.All
          ? CategoryType.All
          : isProduct
          ? CategoryType.Product
          : category.products.length > 0
          ? CategoryType.Menu
          : CategoryType.TopMenu;

      if (this.checkMenuHasProducts(category, type)) {
        categories.push({
          key: category.name === CategoryType.All ? -1 : category.id,
          name: category.longname,
          iconName: isProduct ? 'plus' : 'bars',
          type,
        });
      }
    });

    FirebaseAnalytic.trackEvent('getMenuCategories', 'MenuScreen', {
      ...this.props,
      ...this.state,
      categories,
    });
    return categories;
  }

  getProducts(menuId: number | string = -1) {
    return MenuService.searchProducts('', menuId);
  }

  getSubMenus(menuId: number | string = -1) {
    return MenuService.searchSubMenus('', menuId);
  }

  // Always show images if available
  renderItem({item}) {
    let imageURL = null;

    if (item.parent) {
      return (
        <SubMenu
          menu={item}
          dnaConfiguration={this.props.location?.locationFeatures?.dna}
          onProductPress={this.goToProductDetail}
        />
      );
    }

    if (item.smallimageurl !== '') {
      imageURL = item.smallimageurl;
    }
    return (
      <View style={{flex: 1}}>
        <SearchProduct
          dnaConfiguration={this.props.location?.locationFeatures?.dna}
          onPress={() => this.goToProductDetail(item)}
          name={item.name}
          item={item}
          price={parseFloat(item.price)}
          imageUrl={imageURL}
        />
      </View>
    );
  }

  render() {
    const {selectedCategory} = this.state;
    const key =
      selectedCategory?.type !== CategoryType.Product
        ? selectedCategory?.key
        : '';

    return (
      <BackSubheader
        title={Localized.Labels.menu}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        rightView={<RecentOrdersButton onPress={this.goToRecentOrders} />}
      >
        <TouchableWithoutFeedback
          accessible={false}
          onPress={() => (Platform.OS !== 'web' ? Keyboard.dismiss : null)}
        >
          <KeyboardAvoidingView behavior="height" insideTab>
            <View style={styles.content}>
              <View style={{marginHorizontal: Styles.Spacing.m2}}>
                <SearchField
                  onChangeText={this.onSearchChanged}
                  strings={Localized}
                  value={this.state.searchValue}
                  currentCategory={selectedCategory?.name}
                />
              </View>

              <ProductCategories
                categories={this.state.categories}
                selectedCategory={key}
                style={styles.categories}
                onCategoryPress={this.onCategoryPress}
              />

              <FlashList
                data={this.state.items}
                renderItem={this.renderItem}
                estimatedItemSize={72}
                ListEmptyComponent={
                  <View style={styles.noProductsView}>
                    <AVText accessibilityRole="text" style={styles.noProducts}>
                      {Localized.Labels.no_products_available}
                    </AVText>
                  </View>
                }
              />
            </View>
            <CheckoutBar
              location={this.props.location}
              strings={Localized}
              onPress={this.goToCart}
            />
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: Styles.white,
  },
  list: {
    marginTop: Styles.Spacing.m2,
  },
  categories: {
    marginVertical: Styles.Spacing.m2,
  },
  noProducts: {
    fontSize: Styles.Fonts.f2,
  },
  noProductsView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3,
  },
});
export default withForwardedNavigationParams()(withGlobalize(MenuScreen));
