import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withScrollToElement from '../../hoc/withScrollToElement';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {SetupModel} from 'src/types/SetupModel';
import {Referral} from 'src/types/Referral';
import {ScrollToElementProps} from 'src/types/Screen';
import {StyleSheet, View, ScrollView} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type ItalyAccountInfoProps = ScrollToElementProps & {
  setupModel: SetupModel;
  email: string;
  pinRequired: boolean;
  referral: Referral | null | undefined;
};
type ItalyAccountInfoState = {
  address1: string;
  address2: string;
  taxId: string;
  province: string;
  city: string;
  postalCode: string;
  region: string;
};

class ItalyAccountInfo extends React.Component<
  ItalyAccountInfoProps,
  ItalyAccountInfoState
> {
  scrollView: any;
  address1: AVTextInput | null;
  address2: AVTextInput | null;
  postalCode: AVTextInput | null;
  taxId: AVTextInput | null;
  city: AVTextInput | null;
  province: AVTextInput | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ItalyAccountInfoProps) {
    super(props);
    this.state = {
      address1: '',
      address2: '',
      taxId: '',
      region: '',
      postalCode: '',
      province: '',
      city: '',
    };
    this.inputFocused = this.inputFocused.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  inputFocused(input: AVTextInput | null) {
    this.props.scrollToElement(this.scrollView, input);
  }

  focusInput(input: AVTextInput | null) {
    if (input) {
      input.focus();
    }
  }

  _validate(setupModel: SetupModel) {
    FirebaseAnalytic.trackEvent('handleClick', 'ItalyAccountInfoScreen', {
      ...this.props,
      ...this.state,
      setupModel,
    });
    if (
      !(
        setupModel.taxIdIdentifier &&
        setupModel.taxIdIdentifier.value &&
        setupModel.street1 &&
        setupModel.city &&
        setupModel.state &&
        setupModel.zip
      )
    ) {
      return Localized.Errors.all_fields_except_address_2_required;
    }

    return null;
  }

  displayAccountSetupError(error: any) {
    let message = Localized.Errors.problem_creating_account;

    if (
      typeof error === 'string' &&
      error.includes('specified already exists')
    ) {
      message += ` ${Localized.Errors.email_already_exists}`;

      if (!message.endsWith('.')) {
        message += '.';
      }
    } else {
      message += ' ' + Localized.Errors.check_internet_connection;
    }

    alertError(message);
  }

  async handleClick() {
    const model: SetupModel = {
      ...this.props.setupModel,
      street1: this.state.address1,
      street2: this.state.address2,
      city: this.state.city,
      state: this.state.province,
      zip: this.state.postalCode,
      taxIdIdentifier: {
        type: 4,
        value: this.state.taxId,
        usePin: false,
      },
    };

    const errorMessage = this._validate(model);

    FirebaseAnalytic.trackEvent('handleClick', 'ItalyAccountInfoScreen', {
      ...this.props,
      ...this.state,
      errorMessage,
    });

    if (errorMessage) {
      alertError(errorMessage);
    } else {
      NavActions.push(AppRoutes.Demographic, {
        setupModel: model,
        email: this.props.email,
        pinRequired: this.props.pinRequired,
        referral: this.props.referral,
      });
    }
  }

  render() {
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.user_info}
        instructionText={Localized.Labels.enter_information}
      >
        <ScrollView
          style={styles.scrollView}
          ref={(ref) => {
            this.scrollView = ref;
          }}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.inputsContainer}>
            <AllyTextInput
              label={Localized.Labels.tax_id}
              value={this.state.taxId}
              accessible={true}
              accessibilityLabel={Localized.Labels.tax_id}
              accessibilityValue={{text: this.state.taxId}}
              onChangeText={(text) => this.setState({taxId: text})}
              autoCapitalize="none"
              returnKeyType="next"
              maxLength={20}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AllyTextInput
              label={Localized.Labels.address + ' 1'}
              value={this.state.address1}
              accessible={true}
              accessibilityLabel={Localized.Labels.address + ' 1'}
              accessibilityValue={{text: this.state.address1}}
              onChangeText={(text) => this.setState({address1: text})}
              autoCapitalize="none"
              returnKeyType="next"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AllyTextInput
              label={Localized.Labels.address + ' 2'}
              value={this.state.address2}
              accessible={true}
              accessibilityLabel={Localized.Labels.address + ' 2'}
              accessibilityValue={{text: this.state.address2}}
              onChangeText={(text) => this.setState({address2: text})}
              autoCapitalize="none"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AllyTextInput
              label={Localized.Labels.postal_code}
              value={this.state.postalCode}
              accessible={true}
              accessibilityLabel={Localized.Labels.postal_code}
              accessibilityValue={{text: this.state.postalCode}}
              onChangeText={(text) =>
                this.setState({
                  postalCode: text,
                })
              }
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AllyTextInput
              label={Localized.Labels.city}
              value={this.state.city}
              onChangeText={(text) =>
                this.setState({
                  city: text,
                })
              }
              accessible={true}
              accessibilityLabel={Localized.Labels.city}
              accessibilityValue={{text: this.state.city}}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AllyTextInput
              label={Localized.Labels.province}
              value={this.state.province}
              onChangeText={(text) =>
                this.setState({
                  province: text,
                })
              }
              accessible={true}
              accessibilityLabel={Localized.Labels.province}
              accessibilityValue={{text: this.state.province}}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
          </View>
        </ScrollView>
        <RoundedButton
          accessibilityLabel="Next"
          buttonType={ButtonType.action}
          onPress={this.handleClick}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m5,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m1,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(ItalyAccountInfo),
);
