import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';

function CanteenPantryLogo(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={640}
      height={640}
      viewBox="0 0 640 640"
      {...props}
    >
      <Path
        d="M0 159.85C0 71.57 71.55 0 159.85 0h320.3C568.43 0 640 71.55 640 159.85v320.3C640 568.43 568.45 640 480.15 640h-320.3C71.57 640 0 568.45 0 480.15v-320.3z"
        fill="#9ec851"
        fillRule="evenodd"
      />
      <G fill="#fff" fillRule="evenodd">
        <Path d="M298.25 219.75l-46.92-90.03a227.92 227.92 0 00-35.92 21.96l65.47 78.74c5.38-4.07 11.2-7.64 17.38-10.67zM329.92 209.76l-20.79-99.25a222.874 222.874 0 00-40.69 11.66l39.43 93.43c6.97-2.62 14.34-4.58 22.04-5.84zM430.7 119.74c-13.74-5.14-28.03-8.67-42.99-10.75l-14.42 101.36a117.38 117.38 0 0121.36 6.32l36.06-96.93zM426.79 235.79l69.78-75.34c-15.79-14.22-32.18-25.28-49.39-33.63l-43.59 93.9c8.25 4.13 16.01 9.19 23.2 15.07zM432.08 393.64c-18.86 20.35-47.87 35.32-77.5 36.64-63.22 2.83-112.87-43.75-115.73-106.08-1.65-35.73 11.64-66.69 34.6-87.63l-72.33-73.33c-46.79 41.18-75.9 100.93-72.91 165.91 5.82 126.61 102.6 208.17 231.02 202.43 57.28-2.56 111.53-28.41 149.16-71.08l-76.31-66.85zM344.37 208.24c6.41-.29 12.67-.06 18.78.6l5.98-101.68c-9.54-.57-19.31-.66-29.42-.21-4.1.18-8.16.5-12.19.91l12.77 100.65c1.35-.1 2.71-.22 4.08-.28z" />
      </G>
    </Svg>
  );
}

export default CanteenPantryLogo;
