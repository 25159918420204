import React, {useEffect, useMemo} from 'react';
import {FlatList} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BaseScreen from '../BaseScreen';
import withIsConnected from '../../hoc/withIsConnected';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import Localized from 'src/constants/AppStrings';
import BalanceItem from '../../elements/account/BalanceItem';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import {fetchAccountBalances} from 'src/redux/slices/accountSlice';
import {useNavigation} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
const BalanceOrder = [
  BalanceTypes.Default,
  BalanceTypes.Payroll,
  BalanceTypes.RollOver,
  BalanceTypes.TopOff,
];

const BalancesScreen = () => {
  const dispatch = useAppDispatch();
  const balances = useAppSelector((s) => s.account.account.displayBalances);
  const accountId = useAppSelector((s) => s.account.account.id);
  const navigation = useNavigation();
  const [previousRoute] = React.useState(
    getPreviousRouteName(navigation?.getState()?.routes),
  );

  const sortedBalances = useMemo(() => {
    return balances.slice().sort((a, b) => {
      return BalanceOrder.indexOf(a.type) - BalanceOrder.indexOf(b.type);
    });
  }, [balances]);

  const getKeyForListItem = (item) => {
    return item.type;
  };

  useEffect(() => {
    dispatch(fetchAccountBalances(accountId));
  }, []);

  const renderBalanceItem = ({item}) => {
    return <BalanceItem balanceItem={item} />;
  };

  return (
    <BaseScreen
      title={Localized.Labels.balances}
      previousRoute={previousRoute}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
    >
      <FlatList
        refreshing={false}
        onRefresh={() => {
          dispatch(fetchAccountBalances(accountId));
        }}
        data={sortedBalances}
        renderItem={renderBalanceItem}
        keyExtractor={getKeyForListItem}
      />
    </BaseScreen>
  );
};

export default withForwardedNavigationParams()(withIsConnected(BalancesScreen));
