import {GmaApi} from './GmaApi';
import Api, {HttpVerbs} from './Api';
import type {CCModel} from 'src/types/CCModel';
export class PaymentApi extends GmaApi {
  async getToken(ccModel: CCModel, processorKey: string, processorUrl: string) {
    const params = {
      api_key: processorKey,
      object: 'token',
      token_type: 'supt',
      _method: 'post',
      'card[number]': ccModel.CardNumber,
      'card[cvc]': ccModel.CvvCode,
      'card[exp_month]': ccModel.ExpirationMonth,
      'card[exp_year]': ccModel.ExpirationYear,
    };
    const url = Api.getFullUrl(processorUrl, '', params);
    const response = await fetch(url);
    return response.json();
  }

  addCreditCard(ccModel: CCModel) {
    const url = this.getFullUrl('/mkt/v2');
    const postObject = {
      cardIssuer: ccModel.Issuer,
      expirationMonth: ccModel.ExpirationMonth,
      expirationYear: ccModel.ExpirationYear,
      marketaccount: ccModel.AccountId,
      name: ccModel.CardNumberMask,
      provider: ccModel.Provider,
      providerkey: ccModel.Token,
      cardHolder: '',
    };
    return Api.fetch(url, postObject, 'POST');
  }

  issueRefund(
    transactionId: string,
    marketAccount: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl('/fundings/refund');
    const postObject = {
      transactionId,
      marketAccount,
      transactionDate,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  loadCreditCards(accountId: string) {
    const url = this.getFullUrl('/mkt/show', {
      marketaccount: accountId,
    });
    return Api.fetch(url, null, 'GET');
  }

  createSetupIntent(accountId: string) {
    const url = this.getFullUrl('/mkt/setup', {
      id: accountId,
    });
    return Api.fetch(url, null, 'GET');
  }

  createPaymentIntent(
    accountId: string,
    token: string,
    amount: number,
    transdate: string,
  ) {
    const url = this.getFullUrl('/fundings/create');

    const postObject = {
      amount,
      marketAccount: accountId,
      marketAccountToken: token,
      transdate,
      source: '365Pay',
    };

    return Api.fetch(url, postObject, 'POST');
  }

  confirmPaymentIntent(
    accountId: string,
    paymentIntentId: string,
    transId: string,
  ) {
    const url = this.getFullUrl('/fundings/confirm');
    const postObject = {
      marketAccount: accountId,
      paymentIntentId,
      transId,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  turnOffAutoFund(accountId: string, accountBalanceId: string) {
    const url = Api.getGatewayUrl(
      `gma/accounts/${accountId}/balances/${accountBalanceId}/autofund`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Delete);
  }

  addFundsApplePay(
    apObject: any,
    marketAccount: string,
    amount: number,
    transdate: string,
    test = false,
    processor: number,
  ) {
    const url = this.getFullUrl('/fundings', {
      type: 'applepay',
    });
    apObject.test = test;
    const postObject = {
      amount,
      marketAccount,
      data: apObject,
      transdate,
      processor,
    };
    return Api.fetch(url, postObject, 'POST');
  }

  addFundsGooglePay(
    apObject: any,
    marketAccount: string,
    amount: number,
    transdate: string,
    test = false,
    processor: number,
  ) {
    const url = this.getFullUrl('/fundings', {
      type: 'googlepay',
    });
    apObject.test = test;
    const postObject = {
      amount,
      marketAccount,
      data: apObject,
      transdate,
      processor,
    };
    return Api.fetch(url, postObject, 'POST');
  }
}
export default new PaymentApi();
