export type MarketAccountIdentifier = {
  id: string;
  org: string;
  location: string;
  marketaccount: string;
  value: string;
  usepin: string;
  idtype: number;
  datecreated: string;
  lastupdated: string;
  isdisabled: string;
  marketaccountbalance?: string;
  scope: string;
};
export const MarketAccountIdentifierTypes = {
  ScanCode: 0,
  FingerPrint: 1,
  QrCode: 2,
  TaxId: 4,
  Vas: 5,
  PayrollId: 6,
  USConnect: 7,
  ConsumerRole: 8,
};
