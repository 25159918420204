import React, {useContext} from 'react';
import {TouchableWithoutFeedback, View} from 'react-native';
import type {BottomTabBarButtonProps} from '@react-navigation/bottom-tabs';
import createTopTabNavigator from './TopTabNavigator';
import NetInfo from '@react-native-community/netinfo';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import MainConsumerContext from 'src/components/MainConsumerContext';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import UIManager from 'src/components/elements/ui/UIManager';
import InboxNavigator from './tabs/InboxTab';
import ManageAccountNavigator from './tabs/ManageAccountTab';
import PromotionNavigator from './tabs/PromotionTab';
import ShopNavigator from './tabs/ShopTab';
import {networkError} from 'src/components/helpers/AlertHelper';

async function onPress(routeName: AppRoutes) {
  const state = await NetInfo.fetch();

  if (
    !state.isConnected &&
    (routeName === AppRoutes.Shop ||
      routeName === AppRoutes.Funding ||
      routeName === AppRoutes.Settings ||
      routeName === AppRoutes.Home)
  ) {
    networkError();
  }

  NavActions.navigate(routeName);
}

function wrapOnPress(routeName: AppRoutes) {
  return ({children, style, ...rest}: BottomTabBarButtonProps) => {
    return (
      <TouchableWithoutFeedback {...rest} onPress={() => onPress(routeName)}>
        <View style={style}>{children}</View>
      </TouchableWithoutFeedback>
    );
  };
}

const Navigator = createTopTabNavigator();
export default function TabNavigator() {
  const context = useContext(MainConsumerContext);
  return (
    <Navigator.Navigator
      initialRouteName={context?.state.initialTabRoute ?? AppRoutes.Home}
      tabBarOptions={{
        activeTintColor: Styles.primaryColor,
        inactiveTintColor: Styles.lightGray,
        style: {
          backgroundColor: Styles.tabBarBackgroundColor,
        },
      }}
      screenOptions={{
        title: DeviceInfo.getApplicationName(),
      }}
      lazy={false}
    >
      <Navigator.Screen
        name={AppRoutes.Home}
        component={PromotionNavigator}
        options={{
          tabBarLabel: Localized.Labels.home,
          tabBarAccessibilityLabel: Localized.Labels.home,
          tabBarButton: wrapOnPress(AppRoutes.Home),
        }}
      />
      <Navigator.Screen
        name={AppRoutes.Shop}
        component={ShopNavigator}
        options={{
          tabBarLabel: UIManager.getShopLabel(),
          tabBarAccessibilityLabel: UIManager.getShopLabel(),
          tabBarButton: wrapOnPress(AppRoutes.Shop),
          tabBarIcon: ({color}) =>
            UIManager.getShopIcon(color, context?.state.shopBadgeCount || 0),
        }}
      />
      <Navigator.Screen
        name={AppRoutes.Inbox}
        component={InboxNavigator}
        options={{
          tabBarLabel: UIManager.getInboxLabel(),
          tabBarAccessibilityLabel: UIManager.getInboxLabel(),
          tabBarButton: wrapOnPress(AppRoutes.Inbox),
        }}
      />
      <Navigator.Screen
        name={AppRoutes.Settings}
        component={ManageAccountNavigator}
        options={{
          tabBarLabel: Localized.Labels.settings,
          tabBarAccessibilityLabel: Localized.Labels.settings,
          tabBarButton: wrapOnPress(AppRoutes.Settings),
        }}
      />
    </Navigator.Navigator>
  );
}
