import * as React from 'react';
import {View} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import TabRoutes from 'src/navigators/TabRoutes';
import BackSubHeader from '../../elements/BackSubheader';
import ExpressFundingControl from '../../elements/funding/ExpressFundingControl';
import Styles from '../../Styles';
import TopWebBar from '../../elements/TopWebBar';
import type {ScreenProps} from 'src/types/Screen';
import Localized from 'src/constants/AppStrings';

class AutoReloadScreen extends React.Component<
  ScreenProps & WithGlobalizeProps
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ScreenProps & WithGlobalizeProps) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    NavActions.navigate(AppRoutes.MyModal);
  }

  render() {
    return (
      <View style={Styles.Style.flex}>
        <TopWebBar hideFunding={true} routes={TabRoutes} />
        <BackSubHeader
          title={Localized.Labels.edit_auto_reload}
          onBackSelect={this.onClose}
        >
          <ExpressFundingControl {...this.props} close={this.onClose} />
        </BackSubHeader>
      </View>
    );
  }
}

export default withForwardedNavigationParams()(withGlobalize(AutoReloadScreen));
