import * as React from 'react';
import {PixelRatio, StyleSheet, View} from 'react-native';
import {useWindowSize} from '../Hooks';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
type HeaderProps = {
  style?: StyleSheet;
  title: string;
  leftView?: React.ReactNode;
  rightView?: React.ReactNode;
  bottomView?: React.ReactNode;
  children?: React.ReactNode;
  onTitlePress?: () => void;
};

const Header = (props: HeaderProps) => {
  const windowSize = useWindowSize();
  let title = (
    <AVText
      numberOfLines={PixelRatio.getFontScale() > 1 ? 2 : 1}
      maxFontSizeMultiplier={2}
      style={styles.headerText}
    >
      {props.title}
    </AVText>
  );

  if (props.onTitlePress) {
    title = (
      <AVTouchableOpacity
        onPress={props.onTitlePress}
        style={Styles.Style.flex}
      >
        {title}
      </AVTouchableOpacity>
    );
  }

  const topView = (props.rightView || props.bottomView) && (
    <View style={styles.topView}>
      {props.rightView}
      {props.bottomView}
    </View>
  );
  let screenContainerStyle: any = styles.screenContainer;
  let titleRowStyle: any = styles.titleRow;

  if (windowSize.width <= 650) {
    screenContainerStyle = styles.screenContainerSmall;
    titleRowStyle = styles.titleRowSmall;
  }

  return (
    <View style={screenContainerStyle}>
      <View style={[titleRowStyle, props.style]}>
        <View style={styles.leftView}>
          {props.leftView}
          {title}
        </View>
      </View>
      <View style={Styles.Style.flex}>
        {topView}
        {props.children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
    margin: Styles.Spacing.m2,
    textAlign: 'left',
  },
  leftView: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  screenContainer: {
    backgroundColor: Styles.white,
    flex: 1,
    flexDirection: 'row',
  },
  screenContainerSmall: {
    backgroundColor: Styles.white,
    flex: 1,
    flexDirection: 'column',
  },
  titleRow: {
    backgroundColor: Styles.bgColor,
    boxShadow: '0 0 2px 0 rgba(0,0,0,.24), 0 2px 2px 0 rgba(0,0,0,.12)',
    justifyContent: 'space-evenly',
    paddingHorizontal: Styles.Spacing.m3,
    width: '40%',
    zIndex: 1,
  },
  titleRowSmall: {
    backgroundColor: Styles.bgColor,
    boxShadow: '0 0 2px 0 rgba(0,0,0,.24), 0 2px 2px 0 rgba(0,0,0,.12)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.Spacing.m3,
    zIndex: 1,
  },
  topView: {
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
  },
});
export default Header;
