import Icon from '../icons/IonicIcon';
import * as React from 'react';
type AVIconProps = JSX.LibraryManagedAttributes<
  typeof Icon,
  React.ComponentProps<typeof Icon>
> & {
  children?: React.ReactNode;
};

const AVIcon = (props: AVIconProps) => (
  <Icon allowFontScaling={false} nativeID={props.accessibilityLabel} {...props}>
    {props.children}
  </Icon>
);

export default AVIcon;
