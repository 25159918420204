import React from 'react';
import DashedLine from 'react-native-dashed-line';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {getTaxDescription} from 'src/services/TranslationService';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import ReceiptItem from '../../elements/cart/ReceiptItem';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import BaseSummaryScreen from '../account/history/BaseSummaryScreen';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import {PixelRatio, StyleSheet, View} from 'react-native';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Localized from 'src/constants/AppStrings';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type ReceiptScreenProps = {
  returnRoute: AppRoutes;
  navigation?: NavigationProp<ReceiptScreen>;
  balance: number;
  ccDisplay: string;
  totalDue: number;
  subtotal: number;
  discount: number;
  totalAmt: number;
  taxes: Array<any>;
  deposit: number;
  items: Array<any>;
  points: number;
  split: boolean;
  insideTab: boolean;
  deviceId: string;
  supFee: number;
};

type ReceiptScreenState = {
  previousRoute: string | null;
};

class ReceiptScreen extends React.Component<
  ReceiptScreenProps,
  ReceiptScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.onFeedbackSelect = this.onFeedbackSelect.bind(this);
    this.renderSummary = this.renderSummary.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ReceiptScreen', {
      ...this.props,
      ...this.state,
    });

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  onFeedbackSelect() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ReceiptScreen', {
      ...this.props,
      ...this.state,
      subject: 'Feedback',
      popToTop: true,
      insideTab: this.props.insideTab,
      deviceId: this.props.deviceId,
    });

    NavActions.replace(AppRoutes.ContactUs, {
      subject: 'Feedback',
      popToTop: true,
      insideTab: this.props.insideTab,
      deviceId: this.props.deviceId,
    });
  }

  renderSummary() {
    const labels: JSX.Element[] = [];
    const amounts: JSX.Element[] = [];
    if (this.props.supFee && this.props.supFee > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={[Styles.Style.summaryLabel, styles.supFeeStyle]}
          key="sup"
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.sup_fee}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessible={true}
          accessibilityLabel={`${Localized.Labels.sup_fee} $${this.props.supFee}`}
          aria-label={`${Localized.Labels.sup_fee} $${this.props.supFee}, text`}
          value={this.props.supFee}
          key="sup"
          style={[Styles.Style.summaryLabel, styles.supFeeStyle]}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.subtotal > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={Styles.Style.summaryLabel}
          key="subtotal"
        >
          {Localized.Labels.subtotal}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessibilityLabel={`${Localized.Labels.subtotal} $${this.props.subtotal}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.subtotal} $${this.props.subtotal}, text`}
          accessible={true}
          key="subtotal"
          style={Styles.Style.summaryLabel}
          value={this.props.subtotal}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.discount > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={[Styles.Style.summaryLabel, styles.discount]}
          key="discount"
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.discount}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessible={true}
          accessibilityLabel={`${Localized.Labels.discount} $${
            this.props.discount * -1
          }`}
          aria-label={`${Localized.Labels.discount} $${
            this.props.discount * -1
          }, text`}
          value={this.props.discount * -1}
          key="discount"
          style={[Styles.Style.summaryLabel, styles.discount]}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.taxes && this.props.taxes.length > 0) {
      for (let i = 0; i < this.props.taxes.length; i++) {
        const tax = this.props.taxes[i];
        labels.push(
          <AVText
            maxFontSizeMultiplier={1.0}
            accessibilityElementsHidden={true}
            importantForAccessibility="no-hide-descendants"
            style={Styles.Style.summaryLabel}
            key={`tax${i}`}
          >
            {getTaxDescription(Localized, tax.Description)}
          </AVText>,
        );
        amounts.push(
          <AVFormattedCurrency
            value={parseFloat(tax.TaxAmount)}
            style={Styles.Style.summaryLabel}
            accessible={true}
            accessibilityLabel={`${getTaxDescription(
              Localized,
              tax.Description,
            )} $${parseFloat(tax.TaxAmount)}`}
            accessibilityRole="text"
            aria-label={`${getTaxDescription(
              Localized,
              tax.Description,
            )} $${parseFloat(tax.TaxAmount)}, text`}
            key={`tax${i}`}
            currency={AccountStore.getCurrency()}
          />,
        );
      }
    }

    if (this.props.supFee && this.props.supFee > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={Styles.Style.summaryLabel}
          key="sup_fee"
        >
          {Localized.Labels.sup_fee}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessibilityLabel={`${Localized.Labels.sup_fee} $${this.props.supFee}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.sup_fee} $${this.props.supFee}, text`}
          accessible={true}
          key="sup_fee"
          style={Styles.Style.summaryLabel}
          value={this.props.subtotal}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.deposit > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={Styles.Style.summaryLabel}
          key="deposit"
        >
          {Localized.Labels.deposit}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          style={Styles.Style.summaryLabel}
          value={this.props.deposit}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.deposit} $${this.props.deposit}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.deposit} $${this.props.deposit}, text`}
          key="deposit"
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.split) {
      if (this.props.split) {
        let label = Localized.Labels.account_balance;

        if (Settings.buildType === BuildTypes.pantry) {
          label = Localized.Labels.remaining_credit;
        }

        labels.push(
          <AVText
            maxFontSizeMultiplier={1.0}
            accessibilityElementsHidden={true}
            importantForAccessibility="no-hide-descendants"
            style={Styles.Style.summaryLabel}
            key="remaininBalance"
          >
            {label}
          </AVText>,
        );
        amounts.push(
          <AVFormattedCurrency
            style={Styles.Style.summaryLabel}
            value={this.props.balance * -1}
            accessible={true}
            accessibilityLabel={`${label} $${this.props.balance * -1}`}
            accessibilityRole="text"
            aria-label={`${label} $${this.props.balance * -1}, text`}
            key="remaininBalance"
            currency={AccountStore.getCurrency()}
          />,
        );
      }
    }

    if (this.props.split) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          style={Styles.Style.summaryLabel}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          key="totalDue"
        >
          {Localized.Labels.total_due} ({this.props.ccDisplay})
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          style={Styles.Style.summaryLabel}
          value={this.props.totalDue}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.total_due} (${this.props.ccDisplay}) $${this.props.totalDue}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.total_due} (${this.props.ccDisplay}) $${this.props.totalDue}, text`}
          key="totalDue"
          currency={AccountStore.getCurrency()}
        />,
      );
    } else {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          style={Styles.Style.summaryTotalLabel}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          key="due"
        >
          {Localized.Labels.total}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          style={Styles.Style.summaryTotalLabel}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.total} $${this.props.totalAmt}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.total} $${this.props.totalAmt}, text`}
          key="due"
          value={this.props.totalAmt}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    return (
      <View style={Styles.Style.summarySection}>
        <View style={Styles.Style.labelColumn}>{labels}</View>
        <View style={Styles.Style.amountColumn}>{amounts}</View>
      </View>
    );
  }

  render() {
    let i = 0;
    const receiptItems = this.props.items.map((item) => (
      <ReceiptItem
        desc={item.desc}
        amount={item.price}
        key={i++}
        accessible={true}
        accessibilityLabel={`${item.name}`}
        aria-label={`${item.name}`}
      />
    ));
    return (
      <BaseSummaryScreen
        previousRoute={this.state.previousRoute}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        strings={Localized}
        titleText={Localized.Buttons.receipt}
        returnRoute={this.props.returnRoute}
      >
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={styles.dashStyle}
        />
        {receiptItems}
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={[
            styles.dashStyle,
            {
              marginTop: -1 * Styles.Spacing.m2,
            },
          ]}
        />
        {this.renderSummary()}
        <RoundedButton
          buttonType={ButtonType.outline}
          containerStyle={styles.button}
          onPress={this.onFeedbackSelect}
          accessible={true}
          accessibilityLabel={Localized.Buttons.report_problem}
          accessibilityHint='Double tap to go to the "Contact Us" screen'
          accessibilityRole="button"
          role="button"
          aria-label={Localized.Buttons.report_problem}
          text={Localized.Labels.report_problem}
        />
      </BaseSummaryScreen>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: Styles.Spacing.m4,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  discount: {
    color: Styles.positiveColor,
  },
  supFeeStyle: {
    color: Styles.positiveColor,
  },
});
export default withForwardedNavigationParams<ReceiptScreenProps>()(
  withGlobalize(ReceiptScreen),
);
