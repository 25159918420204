import React from 'react';
import {View, StyleSheet, SafeAreaView, Text, ScrollView} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BackSubheader from 'src/components/elements/BackSubheader';
import Localized from 'src/constants/AppStrings';
import {checkValue} from 'src/Util';
import Styles from '../../Styles';

type BlackLineProps = {
  height: number;
};
function BlackLine(props: BlackLineProps) {
  return <View style={[styles.blackLine, {height: props.height}]} />;
}

function ViewNutrition(props) {
  return (
    <BackSubheader title={props.nutrition?.productName}>
      <ScrollView style={{flex: 1}}>
        <SafeAreaView style={styles.container}>
          <View
            style={{
              paddingHorizontal: Styles.Spacing.m2,
              paddingVertical: Styles.Spacing.m1,
            }}
          >
            <Text
              style={styles.title}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            >
              {Localized.Labels.nutrition_facts}
            </Text>
            <BlackLine height={10} />

            <View style={styles.calorieContainer}>
              <Text
                style={styles.smallText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Labels.amount_per_serving}
              </Text>
              <BlackLine height={1} />

              <View style={[styles.fullInformationRow, {marginBottom: 2}]}>
                <Text
                  style={[styles.calorieText]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                >
                  {Localized.Labels.calories}
                </Text>
                <Text
                  style={[styles.calorieText]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                >
                  {props.product?.calories}
                </Text>
              </View>
              {checkValue(props.nutrition?.caloriesFromFat) && (
                <View>
                  <View
                    style={[
                      styles.fullInformationRow,
                      {justifyContent: 'flex-end'},
                    ]}
                  >
                    <Text
                      style={[styles.amtPerServingValue]}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                    >
                      {Localized.Labels.calories_from_fat}
                    </Text>
                    <Text
                      style={[styles.amtPerServingValue]}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                    >
                      {props.nutrition?.caloriesFromFat} g
                    </Text>
                  </View>
                </View>
              )}
              <BlackLine height={5} />
            </View>
            <View
              style={[
                styles.row,
                {justifyContent: 'flex-end', marginBottom: Styles.Spacing.m1},
              ]}
            >
              <Text
                style={styles.amtPerServingText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                % {Localized.Labels.daily_value} *
              </Text>
            </View>

            {props.nutrition && Object.keys(props.nutrition).length > 0 && (
              <React.Fragment>
                <View style={styles.column}>
                  {checkValue(props.nutrition?.totalFat) && (
                    <React.Fragment>
                      <BlackLine height={1} />
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: Styles.Spacing.m1,
                        }}
                      >
                        <View style={styles.column}>
                          <View style={styles.row}>
                            <Text
                              style={styles.amtPerServingText}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {Localized.Labels.total_fat}
                            </Text>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {props.nutrition?.totalFat} g
                            </Text>
                          </View>
                        </View>
                        {checkValue(
                          props.nutrition?.percentDailyValues?.totalFat,
                        ) && (
                          <View style={styles.column}>
                            <Text
                              style={styles.amtPerServingText}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {props.nutrition?.percentDailyValues?.totalFat} %
                            </Text>
                          </View>
                        )}
                      </View>
                    </React.Fragment>
                  )}

                  {checkValue(props.nutrition?.saturatedFat) && (
                    <React.Fragment>
                      <BlackLine height={1} />
                      <View
                        style={[
                          styles.fullInformationRow,
                          {
                            marginLeft: checkValue(props.nutrition?.totalFat)
                              ? Styles.Spacing.m4
                              : null,
                          },
                        ]}
                      >
                        <View style={styles.column}>
                          <View style={styles.row}>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {Localized.Labels.saturated_fat}
                            </Text>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {props.nutrition?.saturatedFat} g
                            </Text>
                          </View>
                        </View>
                        {checkValue(
                          props.nutrition?.percentDailyValues?.saturatedFat,
                        ) && (
                          <View style={styles.column}>
                            <Text
                              style={styles.amtPerServingText}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {
                                props.nutrition?.percentDailyValues
                                  ?.saturatedFat
                              }{' '}
                              %
                            </Text>
                          </View>
                        )}
                      </View>
                    </React.Fragment>
                  )}

                  {checkValue(props.nutrition?.unsaturatedFat) && (
                    <React.Fragment>
                      <BlackLine height={1} />

                      <View
                        style={[
                          styles.fullInformationRow,
                          {
                            marginLeft: checkValue(props.nutrition?.totalFat)
                              ? Styles.Spacing.m4
                              : null,
                          },
                        ]}
                      >
                        <View style={styles.column}>
                          <View style={styles.row}>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {Localized.Labels.unsaturated_fat}
                            </Text>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {props.nutrition?.unsaturatedFat} g
                            </Text>
                          </View>
                        </View>
                      </View>
                    </React.Fragment>
                  )}

                  {checkValue(props.nutrition?.transFat) && (
                    <React.Fragment>
                      <BlackLine height={1} />

                      <View
                        style={[
                          styles.fullInformationRow,
                          {
                            marginLeft: checkValue(props.nutrition?.totalFat)
                              ? Styles.Spacing.m4
                              : null,
                          },
                        ]}
                      >
                        <View style={styles.column}>
                          <View style={styles.row}>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {Localized.Labels.trans_fat}
                            </Text>
                            <Text
                              style={styles.amtPerServingValue}
                              maxFontSizeMultiplier={
                                Styles.FontSizeMultiplier.maxfm7
                              }
                            >
                              {props.nutrition?.transFat} g
                            </Text>
                          </View>
                        </View>
                      </View>
                    </React.Fragment>
                  )}
                </View>

                {checkValue(props.nutrition?.cholesterol) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.cholesterol}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.cholesterol} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.cholesterol,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.cholesterol} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.sodium) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.sodium}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.sodium} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.sodium,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.sodium} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.totalCarbs) && (
                  <View style={styles.column}>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.total_carbohydrate}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.totalCarbs} g
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.totalCarbs,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.totalCarbs} %
                          </Text>
                        </View>
                      )}
                    </View>

                    {checkValue(props.nutrition?.dietaryFiber) && (
                      <React.Fragment>
                        <BlackLine height={1} />
                        <View
                          style={[
                            styles.fullInformationRow,
                            {
                              marginLeft: checkValue(
                                props.nutrition?.totalCarbs,
                              )
                                ? Styles.Spacing.m4
                                : null,
                            },
                          ]}
                        >
                          <View style={styles.column}>
                            <View style={styles.row}>
                              <Text
                                style={styles.amtPerServingValue}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {Localized.Labels.dietary_fiber}
                              </Text>
                              <Text
                                style={styles.amtPerServingValue}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {props.nutrition?.dietaryFiber} g
                              </Text>
                            </View>
                          </View>
                          {checkValue(
                            props.nutrition?.percentDailyValues?.dietaryFiber,
                          ) && (
                            <View style={styles.column}>
                              <Text
                                style={styles.amtPerServingText}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {
                                  props.nutrition?.percentDailyValues
                                    ?.dietaryFiber
                                }{' '}
                                %
                              </Text>
                            </View>
                          )}
                        </View>
                      </React.Fragment>
                    )}

                    {checkValue(props.nutrition?.sugars) && (
                      <React.Fragment>
                        <BlackLine height={1} />
                        <View
                          style={[
                            styles.fullInformationRow,
                            {
                              marginLeft: checkValue(
                                props.nutrition?.totalCarbs,
                              )
                                ? Styles.Spacing.m4
                                : null,
                            },
                          ]}
                        >
                          <View style={styles.column}>
                            <View style={styles.row}>
                              <Text
                                style={styles.amtPerServingValue}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {Localized.Labels.sugars}
                              </Text>
                              <Text
                                style={styles.amtPerServingValue}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {props.nutrition?.sugars} g
                              </Text>
                            </View>
                          </View>
                          {checkValue(
                            props.nutrition?.percentDailyValues?.sugars,
                          ) && (
                            <View style={styles.column}>
                              <Text
                                style={styles.amtPerServingText}
                                maxFontSizeMultiplier={
                                  Styles.FontSizeMultiplier.maxfm7
                                }
                              >
                                {props.nutrition?.percentDailyValues?.sugars} %
                              </Text>
                            </View>
                          )}
                        </View>
                      </React.Fragment>
                    )}
                  </View>
                )}

                {checkValue(props.nutrition?.proteing) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.protein}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.proteing} g
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.protein,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.protein} %
                          </Text>
                        </View>
                      )}
                    </View>
                    <BlackLine height={10} />
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.vitaminA) && (
                  <React.Fragment>
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.vitaminA}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.vitaminA} mcg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.vitaminA,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.vitaminA} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.vitaminC) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.vitaminC}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.vitaminC} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.vitaminC,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.vitaminC} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.calcium) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.calcium}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.calcium} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.calcium,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.calcium} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.potassium) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.potassium}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.potassium} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.potassium,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.potassium} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.iron) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.iron}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.iron} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.iron,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.iron} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}

                {checkValue(props.nutrition?.caffeine) && (
                  <React.Fragment>
                    <BlackLine height={1} />
                    <View style={styles.fullInformationRow}>
                      <View style={styles.column}>
                        <View style={styles.row}>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {Localized.Labels.caffeine}
                          </Text>
                          <Text
                            style={styles.amtPerServingValue}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.caffeine} mg
                          </Text>
                        </View>
                      </View>
                      {checkValue(
                        props.nutrition?.percentDailyValues?.caffeine,
                      ) && (
                        <View style={styles.column}>
                          <Text
                            style={styles.amtPerServingText}
                            maxFontSizeMultiplier={
                              Styles.FontSizeMultiplier.maxfm7
                            }
                          >
                            {props.nutrition?.percentDailyValues?.caffeine} %
                          </Text>
                        </View>
                      )}
                    </View>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <BlackLine height={1} />
            <View style={styles.fullInformationRow}>
              <Text
                style={styles.bottomText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                * {Localized.Labels.percent_daily_values_explaination}
              </Text>
            </View>
          </View>
        </SafeAreaView>
      </ScrollView>
    </BackSubheader>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    borderColor: Styles.darkColor,
    borderWidth: 1,
    marginHorizontal: Styles.Spacing.m2,
    marginVertical: Styles.Spacing.m3,
  },
  title: {
    fontSize: Styles.Fonts.f3,
    fontWeight: '700',
    color: Styles.darkColor,
    marginBottom: Styles.Spacing.m2,
  },
  servingContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m2,
  },
  blackLine: {
    backgroundColor: Styles.darkColor,
    paddingHorizontal: 20,
  },
  calorieContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  smallText: {
    fontSize: 10,
    marginBottom: 7.5,
  },
  calorieText: {
    paddingTop: Styles.Spacing.m1,
    fontWeight: '700',
    fontSize: 24,
  },
  amtPerServingText: {
    paddingTop: 5,
    fontWeight: '700',
  },
  amtPerServingValue: {
    paddingTop: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m1,
  },
  bottomText: {
    marginTop: Styles.Spacing.m2,
    flexWrap: 'wrap',
    fontSize: 10,
  },
  fullInformationRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m1,
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 2.5,
  },
  column: {
    flexDirection: 'column',
  },
});

export default withForwardedNavigationParams()(ViewNutrition);
