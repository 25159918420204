import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
type MenuOptionProps = {
  accessible?: boolean;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  onPress: () => void;
  label: string;
  lightText?: boolean;
  style?: StyleProp<ViewStyle>;
  disabled: boolean;
  testID?: string;
};
export default class MenuOption extends React.Component<MenuOptionProps> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    return (
      <AVTouchableOpacity
        style={[styles.linkBtn, this.props.style]}
        accessible={true}
        accessibilityRole="button"
        accessibilityState={{disabled: this.props.disabled}}
        accessibilityHint={this.props?.accessibilityHint}
        aria-disabled={this.props.disabled}
        role="button"
        disabled={this.props.disabled}
        onPress={this.props.onPress}
        testID={this.props.testID}
      >
        <View style={[styles.row, this.props.disabled && styles.disabled]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={`${this.props.accessibilityLabel},`}
            aria-label={`${this.props.accessibilityLabel}, `}
            style={[styles.link, this.props.lightText && styles.linkLight]}
          >
            {this.props.label}
          </AVText>
          <FontAwesome5Pro
            accessibilityLabel="Right arrow icon"
            aria-label="Right arrow icon"
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        </View>
      </AVTouchableOpacity>
    );
  }
}
const styles = StyleSheet.create({
  link: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    minHeight: Styles.Heights.touchTargetHeight0,
  },
  linkBtn: {
    justifyContent: 'center',
    paddingHorizontal: Styles.Spacing.m3,
  },
  linkLight: {
    color: Styles.white,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: Styles.Spacing.m3,
  },
  disabled: {
    opacity: 0.5,
  },
});
