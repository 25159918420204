import Api, {HttpVerbs} from './Api';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
export type MoblicoGroup = {
  belongs: boolean;
  description: string;
  id: number;
  isRegisterable: boolean;
  name: string;
};
export class MoblicoApi {
  _fetch(url: string, data: any, type: string): Promise<any> {
    return Api.fetch(url, data, type, false, true);
  }

  authenticate(username?: string, platformName?: string) {
    const params: any = {
      apikey: AccountStore.getMoblicoApiKey(),
    };

    if (username) {
      params.username = username;
    }

    if (platformName) {
      params.platformName = platformName;
    }

    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      '/authenticate',
      params,
    );
    return this._fetch(url, null, HttpVerbs.Get);
  }

  getUser(token: string, email: string) {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/users/${encodeURIComponent(email)}`,
      {
        token,
      },
    );
    return this._fetch(url, null, HttpVerbs.Get);
  }

  registerUser(token: string, email: string, accountId: string) {
    const url = Api.getFullUrl(AccountStore.getMoblicoUrl(), '/users', {
      token,
      username: email,
      email,
      externalId: accountId,
    });
    return this._fetch(url, null, HttpVerbs.Post);
  }

  updateUserEmail(token: string, currentEmail: string, newEmail: string) {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/users/${encodeURIComponent(currentEmail)}`,
      {
        token,
        newUsername: newEmail,
        email: newEmail,
      },
    );
    return this._fetch(url, null, HttpVerbs.Put);
  }

  addUserToGroup(token: string, email: string, name: string) {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/users/${encodeURIComponent(email)}/groups`,
      {
        name,
        token,
      },
    );
    return this._fetch(url, null, HttpVerbs.Put);
  }

  removeUserFromGroup(token: string, email: string, name: string) {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/users/${encodeURIComponent(email)}/groups`,
      {
        name,
        token,
      },
    );
    return this._fetch(url, null, HttpVerbs.Delete);
  }

  getUserGroups(token: string, email: string): Promise<Array<MoblicoGroup>> {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/users/${encodeURIComponent(email)}/groups`,
      {
        token,
      },
    );
    return this._fetch(url, null, HttpVerbs.Get);
  }

  is404Error(error: any) {
    return (
      error && error.networkResponse && error.networkResponse.status === 404
    );
  }

  handleError(error: any) {
    if (this.is404Error(error)) {
      return Promise.resolve([]);
    }

    throw error;
  }

  async getPromos(token: string) {
    try {
      const url = Api.getFullUrl(AccountStore.getMoblicoUrl(), '/promos', {
        token,
        externalImpressions: true,
      });
      return await this._fetch(url, null, HttpVerbs.Get);
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getDeals(token: string) {
    try {
      const url = Api.getFullUrl(AccountStore.getMoblicoUrl(), '/deals', {
        token,
      });
      return await this._fetch(url, null, HttpVerbs.Get);
    } catch (error) {
      return this.handleError(error);
    }
  }

  acceptDeal(token: string, dealId: string) {
    const url = Api.getFullUrl(
      AccountStore.getMoblicoUrl(),
      `/deals/${encodeURIComponent(dealId)}/accept`,
      {
        token,
      },
    );
    return this._fetch(url, null, HttpVerbs.Put);
  }

  sendMetric(token: string, type: string, text: string | number) {
    const timestamp = Date.now();
    const url = Api.getFullUrl(AccountStore.getMoblicoUrl(), '/metrics', {
      token,
      timestamp,
      type,
      text,
    });
    return this._fetch(url, null, HttpVerbs.Post);
  }

  sendApplePayMetric(token: string, amount: number) {
    const url = Api.getFullUrl(AccountStore.getMoblicoUrl(), '/topup', {
      token,
      amount: Util.getAmountInCents(amount),
      source: 'applePay',
    });
    return this._fetch(url, null, HttpVerbs.Post);
  }
}
export default new MoblicoApi();
