import BalanceBuilder, {Balance} from './Balance';
import IdentifierBuilder, {Identifier} from './Identifier';
import {
  Account as ServerAccount,
  PayrollDeductInfo,
  BalanceTypes,
  IdentifierTypes,
} from 'src/types/serverTypes/Account';

export interface Account {
  id: string;
  balances: Array<Balance>;
  displayBalances: Array<Balance>;
  defaultBalance: Balance | null;
  identifiers: Array<Identifier>;
  email: string;
  points: number;
  paymentCustomerId?: string;
  payrollDeductGroupId?: string;
  locationId?: string;
  payrollDeduct?: PayrollDeductInfo;
  hasPayrollBalance: boolean;
  payrollIdentifier: Identifier;
  isPayrollAvailable: boolean;
  isPayrollDeductOptIn: boolean;
  appTour: string;
  accountLastUpdated?: string;
  accountBalancesLastUpdated?: string;
}

export const defaultAccount: Account = {
  id: '',
  balances: [],
  displayBalances: [],
  defaultBalance: null,
  identifiers: [],
  email: '',
  points: 0,
  paymentCustomerId: null,
  payrollDeductGroupId: null,
  locationId: null,
  payrollDeduct: null,
  hasPayrollBalance: false,
  payrollIdentifier: null,
  isPayrollAvailable: false,
  isPayrollDeductOptIn: false,
  appTour: '',
};

export default class AccountBuilder {
  static build(serverAccount: ServerAccount): Account {
    const balances = serverAccount.balances.map((balance) =>
      BalanceBuilder.build(balance),
    );
    const displayBalances = balances.filter(
      (balance) => balance.type !== BalanceTypes.Payroll && !balance.isDisabled,
    );
    const defaultBalance = balances.find((balance) => balance.isDefault);
    const identifiers = serverAccount.identifiers.map((identifier) =>
      IdentifierBuilder.build(identifier),
    );
    const payrollIdentifier: Identifier = identifiers.find(
      (identifier) =>
        identifier.type === IdentifierTypes.PayrollId && !identifier.isDisabled,
    );
    const hasPayrollBalance = balances.some(
      (balance) => balance.type === BalanceTypes.Payroll,
    );
    const isPayrollDeductOptIn = !serverAccount.optedOutPayrollDeduct;
    const isPayrollAvailable =
      payrollIdentifier?.value?.length > 0 && isPayrollDeductOptIn;

    return {
      id: serverAccount.accountId,
      email: serverAccount.email,
      points: serverAccount.points,
      paymentCustomerId: serverAccount.paymentCustomerId,
      payrollDeductGroupId: serverAccount.payrollDeductGroupId,
      locationId: serverAccount.locationId,
      balances,
      displayBalances,
      defaultBalance,
      identifiers,
      payrollIdentifier,
      hasPayrollBalance,
      isPayrollAvailable,
      isPayrollDeductOptIn,
      appTour: serverAccount.appTour,
    };
  }
}
