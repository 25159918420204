import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EnvironmentKey, EnvironmentsType} from '../../models/Environment';
import Settings from '../../Settings';
import {
  deepLinkCheckEnvironments,
  fetchEnvironments,
} from '../thunks/environmentThunks';
import {changeAuth0Client} from '../../nativeModules/Auth0';
import {REHYDRATE} from 'redux-persist';
import {UrlTypes} from '../../types/UrlTypes';
import {RootState} from '../store';

const defaultServiceUrls = {
  avweb: 'https://live.app.air-vend.com/',
  cartapi: 'https://tally.prod.readytouchpos.com/cartapi',
  tallyapi: 'https://tally.prod.readytouchpos.com/tallyapi',
  shqcartapi: 'https://tally.prod.readytouchpos.com/shqcartapi',
  avlive: 'https://tally.prod.readytouchpos.com/avapi',
  queapi: 'https://tally.prod.readytouchpos.com/queapi',
  gmaapi: 'https://tally.prod.readytouchpos.com/gmaapi',
  capsvr: 'https://tally.prod.readytouchpos.com/capsvr',
  bunnapi: 'https://api.bunn.com',
};

interface IEnvironmentInitial {
  environments?: EnvironmentsType;
  serviceUrls: {[key in UrlTypes]?: string};
  env: EnvironmentKey;
}

const envInit = Settings.env.toUpperCase() || EnvironmentKey.PROD;
export const initialState: IEnvironmentInitial = {
  env: envInit as EnvironmentKey,
  serviceUrls: defaultServiceUrls,
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    environmentChanged: (state, action: PayloadAction<EnvironmentKey>) => {
      const env = action.payload.toUpperCase() as EnvironmentKey;
      if (state.environments) {
        state.env = env;
        state.serviceUrls = state.environments[env];
        changeAuth0Client(env);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      const payloadAction: PayloadAction<RootState> = {
        payload: {},
        type: REHYDRATE,
      };
      Object.assign(payloadAction, action);
      changeAuth0Client(payloadAction.payload?.environment?.env);
    });

    builder.addCase(fetchEnvironments.fulfilled, (state, action) => {
      if (!action.payload?.error) {
        state.environments = action.payload;
      }
    });

    builder.addCase(deepLinkCheckEnvironments.fulfilled, (state, action) => {
      if (!action.payload?.error) {
        state.environments = action.payload.environment;
      }
    });
  },
});
export const {environmentChanged} = environmentSlice.actions;
export default environmentSlice.reducer;
