import * as React from 'react';
import {View, StyleSheet} from 'react-native';
import Key from './Key';
import type {KeypadProps} from './types';
import Styles from '../../../../Styles';
import Localized from 'src/constants/AppStrings';
export default function Keypad7({
  onKeyClick,
  allowMoreSelections,
  onClearPressed,
  onEnterPressed,
}: KeypadProps) {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="A"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="1"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="B"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="2"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="C"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="3"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="D"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="4"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="E"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="5"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="F"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="6"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="G"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="7"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="H"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="8"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="J"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="9"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="K"
        />
        <Key
          style={styles.key}
          disabled={!allowMoreSelections}
          onClick={onKeyClick}
          value="10"
        />
      </View>
      <View style={styles.row}>
        <Key
          style={styles.key}
          specialKey
          onClick={onClearPressed}
          value={Localized.Buttons.clear}
        />
        <Key
          style={styles.key}
          specialKey
          onClick={onEnterPressed}
          value={Localized.Buttons.enter}
        />
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: Styles.Spacing.m5 + Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  key: {
    fontSize: Styles.Fonts.f2,
  },
});
