import Api from './Api';
export class EatItApi {
  retrieveEatItLocation(baseUrl: string, orgUserKey: string) {
    const url = Api.getFullUrl(baseUrl, '/location/search', {
      operation_id: orgUserKey,
    });
    return Api.fetch(url, null, 'GET');
  }

  retrieveEatItItems(
    baseUrl: string,
    eatItLocation: string,
    idfa: string,
    count = 5,
  ) {
    const url = Api.getFullUrl(
      baseUrl,
      `/menu/items/random/location/${eatItLocation}`,
      {
        idfa,
        count,
      },
    );
    return Api.fetch(url, null, 'GET');
  }

  voteEatItItem(
    baseUrl: string,
    item_id: string,
    locationId: string,
    skip: boolean,
    idfa: string,
    latitude: number,
    longitude: number,
    locationUserKey: string,
    like?: boolean,
  ) {
    const url = Api.getFullUrl(baseUrl, '/vote');
    const postObject = {
      item_id,
      location: {
        latitude,
        longitude,
        id: locationId,
      },
      meta: {
        location_user_key: locationUserKey,
      },
      like,
      skip,
      idfa,
    };
    return Api.fetch(url, postObject, 'POST');
  }
}
export default new EatItApi();
