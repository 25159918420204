import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  PixelRatio,
} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import type {TimeSlotType} from 'src/types/Menu';
import TransactionStore from 'src/stores/TransactionStore';
import ItemAddedFeedback from '../cart/ItemAddedFeedback';
import MenuService from 'src/services/MenuService';
import TimeUtils from 'src/services/TimeUtils';
import {WithLocalizedProps} from 'src/types/PropTypes';
import {LocationType} from 'src/types/Location';

type CheckoutBarProps = WithLocalizedProps & {
  location: LocationType;
  onPress?: () => void;
};

type CheckoutBarState = {
  numItems: number;
  pickupTime?: TimeSlotType;
  displayLocation: string;
};

class CheckoutBar extends React.Component<CheckoutBarProps, CheckoutBarState> {
  constructor(props: CheckoutBarProps) {
    super(props);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.getNumItems = this.getNumItems.bind(this);
    const numItems = this.getNumItems();
    const pickupLocation = TransactionStore.getPickupLocationName();

    this.state = {
      numItems,
      pickupTime: TransactionStore.getPickupTime(),
      displayLocation: pickupLocation || props.location?.name,
    };
  }

  componentDidMount() {
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentWillUnmount() {
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const numItems = this.getNumItems();
    const pickupTime = TransactionStore.getPickupTime();
    const pickupLocation = TransactionStore.getPickupLocationName();

    this.setState({
      numItems,
      pickupTime,
      displayLocation: pickupLocation || this.props.location?.name,
    });
  }

  getNumItems() {
    const displayItems = TransactionStore.getDisplayItems();
    return displayItems?.length;
  }

  render() {
    let formattedLabel = '';
    let topRow = (
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={styles.title}
      >
        {this.props.strings.Labels.pickup_at}
      </Text>
    );

    if (this.state.pickupTime) {
      const timeLabel = TimeUtils.isDateGreaterThanToday(
        this.state.pickupTime?.date,
      )
        ? this.props.strings.Labels.pickup_tomorrow
        : this.props.strings.Labels.pickup_today;

      // Pickup today at 6:10 PM EST
      formattedLabel =
        this.props.strings.Labels.formatString(
          timeLabel,
          this.state.pickupTime?.time,
        ) + ` ${MenuService.getTimezoneDisplay()}`;

      topRow = (
        <View style={{flexDirection: 'column'}}>
          <View style={styles.row}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              style={styles.title}
              numberOfLines={1}
              adjustsFontSizeToFit
            >
              {formattedLabel}
            </Text>
          </View>
        </View>
      );
    }

    const cartItemText = this.state.numItems > 1 ? 'items' : 'item';
    const cart = (
      <View style={styles.cart}>
        {this.state.numItems > 0 && (
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            style={styles.cartText}
          >
            View
          </Text>
        )}
        <View>
          <FontAwesome5Pro
            name="shopping-cart"
            color={Styles.white}
            size={Styles.Fonts.f4}
            solid
          />
          <View style={styles.badge}>
            {this.state.numItems > 0 && (
              <Text
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                style={styles.badgeText}
              >
                {this.state.numItems}
              </Text>
            )}
          </View>
        </View>
        {this.state.numItems === 0 && <View style={styles.dummy} />}
      </View>
    );
    return (
      <TouchableOpacity
        accessible={true}
        accessibilityLabel={`${formattedLabel}, ${this.state.displayLocation}, View ${this.state.numItems} ${cartItemText} in cart`}
        aria-label={`${formattedLabel}, ${this.state.displayLocation}, View ${this.state.numItems} ${cartItemText} in cart`}
        accessibilityHint="Double tap to view your cart"
        accessibilityRole="button"
        role="button"
        accessibilityState={{
          disabled: this.state.numItems === 0 || !this.props.onPress,
        }}
        onPress={this.props.onPress}
        disabled={this.state.numItems === 0 || !this.props.onPress}
        style={styles.container}
      >
        <View style={styles.wrapper}>
          <View style={styles.content}>
            {topRow}
            <View style={styles.row}>
              <Text
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                style={styles.description}
              >
                {this.state.displayLocation}
              </Text>
            </View>
          </View>
          <View style={styles.itemAddedFeedbackView}>
            <ItemAddedFeedback strings={this.props.strings} />
          </View>
        </View>
        {cart}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.darkColor,
    flexDirection: 'row',
  },
  wrapper: {
    position: 'relative',
    flex: 3,
    flexDirection: 'column',
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  title: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
  },
  description: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  row: {
    flexDirection: 'row',
  },
  itemAddedFeedbackView: {
    position: 'absolute',
    top: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    right: Styles.Spacing.m0,
    bottom: Styles.Spacing.m0,
    flexDirection: 'row',
  },
  cart: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    backgroundColor: Styles.positiveColor,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2 + PixelRatio.getFontScale() * 1.3,
    color: Styles.positiveColor,
  },
  cartText: {
    fontSize: Styles.Fonts.f1 * 1.1,
    fontWeight: '600',
    paddingRight: Styles.Spacing.m2,
    color: Styles.white,
  },
  badge: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: Styles.Spacing.m0,
    top: Styles.Spacing.m1,
    bottom: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    zIndex: 1,
  },
  badgeText: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m1,
    color: Styles.positiveColor,
  },
  dummy: {
    backgroundColor: Styles.darkColor,
    paddingTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m1,
    position: 'absolute',
    right: Styles.Spacing.m0,
    top: Styles.Spacing.m0,
    bottom: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    zIndex: 1,
  },
});
export default CheckoutBar;
