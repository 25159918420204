import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import Styles from '../Styles';
import type {ScreenProps} from 'src/types/Screen';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import GiftBox from '../img/svg/GiftBox';
import AVText from './AVText';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Util from 'src/Util';
import TimeUtils from 'src/services/TimeUtils';
type SnackItemProps = ScreenProps &
  WithGlobalizeProps & {
    snack: GiftItemType;
    onPress: () => void;
  };

class SnackItem extends React.Component<SnackItemProps> {
  constructor(props: SnackItemProps) {
    super(props);
  }

  renderDescription(isSender) {
    const {snack} = this.props;
    const {Labels} = Localized;
    return isSender
      ? Labels.your_snack_sent
      : Labels.formatString(Labels.sent_you_snack, snack.senderName);
  }

  renderMessage(isSender) {
    const {snack} = this.props;
    const {Labels} = Localized;
    return isSender
      ? Labels.formatString(
          Labels.we_have_sent_your_snack,
          Util.formatCurrency(
            this.props,
            snack.amount,
            AccountStore.getCurrency(),
          ),
          snack.email,
        )
      : snack.description;
  }

  render() {
    const {snack, onPress} = this.props;
    const isSender = snack.senderAccountId === AccountStore.getAccountId();
    return (
      <TouchableOpacity
        onPress={onPress}
        style={styles.container}
        accessible
        accessibilityLabel="Inbox Snack Item"
      >
        <GiftBox width={Styles.Fonts.f2} height={Styles.Fonts.f2} />
        <View style={styles.messageLeft}>
          <View style={styles.topRow}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              style={styles.from}
            >
              {Settings.appDisplayName}
            </AVText>
            <View style={styles.topRight}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={styles.date}
              >
                {TimeUtils.formatInboxDate(snack.snackSent)}
              </AVText>
            </View>
          </View>
          <AVText style={styles.subject}>
            {this.renderDescription(isSender)}
          </AVText>
          <AVText style={styles.message} numberOfLines={1}>
            {this.renderMessage(isSender)}
          </AVText>
        </View>
        <View
          style={styles.arrow}
          accessible
          accessibilityLabel="Snack Item Icon"
        >
          <FontAwesome5Pro
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: Styles.Spacing.m3,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  message: {
    color: Styles.lightGray,
    flex: 1,
    fontSize: Styles.Fonts.f0,
  },
  messageLeft: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
  },
  subject: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m1,
  },
  topRight: {
    flexDirection: 'row',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  from: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
});
export default withGlobalize(SnackItem);
