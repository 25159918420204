import {
  Dimensions,
  Platform,
  StyleSheet,
  PixelRatio,
  StatusBar,
} from 'react-native';
import Theme from './Theme.json';

enum ScreenOrientations {
  Portrait,
  Landscape,
}
enum ScreenSizes {
  Small,
  Medium,
  Large,
}

const getMinusHeight = (isPortrait) => {
  // need this to consider notch-based devices
  // update this once RN fixes their height calcs
  if (StatusBar.currentHeight > 24) {
    return isPortrait ? 6 : 28;
  }

  return StatusBar.currentHeight;
};

class Styles {
  Style: any;

  constructor() {
    const dim = Dimensions.get('window');
    this.ScreenWidth = dim.width;
    this.ScreenHeight = dim.height;
    this.ViewportHeight = (2 * dim.width) / 3;
    this.Orientation = ScreenOrientations.Portrait;
    this.ScreenSize = ScreenSizes.Medium;

    if (dim.width > dim.height) {
      this.Orientation = ScreenOrientations.Landscape;
    } else if (dim.height < 568) {
      this.ScreenSize = ScreenSizes.Small;
    }

    this.rebuildSizing();
    this.rebuildStyle();
  }

  rebuildSizing() {
    switch (this.ScreenSize) {
      case ScreenSizes.Small:
        this.Fonts.f1 = 10;
        break;

      case ScreenSizes.Large:
        this.Fonts.f1 = 20;
        break;

      default:
        this.Fonts.f1 = 16;
        break;
    }

    this.Fonts.f0 = this.Fonts.f1 * 0.75;
    this.Fonts.f2 = this.Fonts.f1 * 1.333;
    this.Fonts.f3 = this.Fonts.f1 * 1.777;
    this.Fonts.f4 = this.Fonts.f1 * 2.369;
    this.Fonts.f5 = this.Fonts.f1 * 3.157;
    this.Spacing.m1 = this.Fonts.f1 * 0.25;
    this.Spacing.m2 = this.Fonts.f1 * 0.5;
    this.Spacing.m3 = this.Fonts.f1 * 1;
    this.Spacing.m4 = this.Fonts.f1 * 2;
    this.Spacing.m5 = this.Fonts.f1 * 4;
    this.Spacing.m6 = this.Fonts.f1 * 8;
    this.Spacing.m7 = this.Fonts.f1 * 16;
    this.Spacing.m8 = this.Fonts.f1 * 32;
    this.Heights.headerHeight = this.Fonts.f1 * 8;
    this.Heights.splashIconHeight = this.Fonts.f1 * 12;
    this.Heights.h1 = this.Heights.headerHeight * 0.1;
    this.Heights.h2 = this.Heights.headerHeight * 0.2;
    this.Heights.h3 = this.Heights.headerHeight * 0.3;
    this.Heights.h4 = this.Heights.headerHeight * 0.4;
    this.Heights.h5 = this.Heights.headerHeight * 0.5;
    this.Heights.h6 = this.Heights.headerHeight * 0.6;
    this.Heights.h7 = this.Heights.headerHeight * 0.7;
    this.Heights.h8 = this.Heights.headerHeight * 0.8;
  }

  rebuildStyle() {
    this.Style = StyleSheet.create({
      alertIcon: {
        alignSelf: 'center',
        height: this.Heights.headerHeight * 0.5,
        textAlign: 'center',
        width: this.Heights.headerHeight * 0.5,
      },
      alignItemsCenter: {
        alignItems: 'center',
      },
      amountColumn: {
        alignItems: 'flex-end',
      },
      bold: {
        fontWeight: this.bold,
      },
      bottomButtonContainer: {
        flex: 1,
        justifyContent: 'flex-end',
      },
      btn: {
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: 4,
        height: this.Heights.headerHeight * 0.6,
        justifyContent: 'center',
      },
      btnText: {
        alignSelf: 'center',
        color: this.white,
        fontSize: this.Fonts.f2,
        fontWeight: this.bold,
        textAlign: 'center',
      },
      description: {
        color: this.darkColor,
        fontSize: this.Fonts.f2,
        fontWeight: '300',
        marginRight: this.Spacing.m0,
        marginLeft: this.Spacing.m1,
      },
      dialogContainer: {
        backgroundColor: this.overlay,
        bottom: 0,
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      },
      dialogInnerContainer: {
        alignItems: 'center',
        backgroundColor: this.white,
        borderRadius: 4,
        marginHorizontal: this.Spacing.m1,
        overflow: 'hidden',
      },
      fieldItem: {
        height: undefined,
        marginBottom: this.Spacing.m1,
        marginLeft: 0,
      },
      fieldLabel: {
        color: this.lightGray,
        fontSize: this.Fonts.f0,
      },
      flex: {
        flex: 1,
      },
      font500: {
        fontWeight: '500',
      },
      input: {
        fontSize: this.Fonts.f1,
        height: undefined,
        justifyContent: 'center',
        paddingLeft: 0,
        paddingVertical: 0,
        textAlignVertical: 'center',
        top: undefined,
      },
      justifyContentCenter: {
        justifyContent: 'center',
      },
      labelColumn: {
        alignItems: 'flex-end',
        flex: 1,
        marginRight: this.Spacing.m3,
      },
      labelColumn1: {
        alignItems: 'flex-end',
        flex: 1,
        marginRight: this.Spacing.m1,
      },
      labelColumn2: {
        // marginRight: this.Spacing.m3,
      },
      maxWidthContainer: {
        alignSelf: 'center',
        flex: 1,
        maxWidth: this.Spacing.m8,
        width: '100%',
      },
      picker: {
        height: null,
        marginRight: 0,
        paddingBottom: 0,
        paddingTop: 0,
      },
      pickerItem: {
        flex: 1,
        fontSize: this.Fonts.f1,
        paddingLeft: 0,
        width: null,
      },
      primaryBtn: {
        backgroundColor: Theme.primaryColor,
      },
      promotionContainer: {
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: this.white,
        borderRadius: PixelRatio.roundToNearestPixel(this.Spacing.m2),
        elevation: 8,
        flex: 1,
        shadowColor: this.darkColor,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.4,
        shadowRadius: this.Spacing.m2,
      },
      row: {
        flexDirection: 'row',
      },
      shadow: {
        backgroundColor: this.white,
        borderBottomWidth: 0,
        elevation: 2,
        shadowColor: '#22212D',
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.2,
        shadowRadius: this.Spacing.m2,
      },
      summaryLabel: {
        color: this.lightGray,
        fontSize: this.Fonts.f1,
        marginBottom: this.Spacing.m1,
      },
      summarySection: {
        flexDirection: 'row',
        marginTop: this.Spacing.m2,
        paddingHorizontal: this.Spacing.m2,
      },
      summaryTotalLabel: {
        color: this.darkColor,
        fontSize: this.Fonts.f3,
        fontWeight: '300',
      },
      tabBarStyle: {
        borderWidth: 0,
        paddingHorizontal: this.Spacing.m2,
      },
      tabBarTextStyle: {
        color: Theme.darkColor,
        fontSize: this.Fonts.f1,
        paddingTop: this.Spacing.m1,
      },
      tabBarUnderlineStyle: {
        backgroundColor: Theme.primaryColor,
      },
      tabsContainerStyle: {
        justifyContent: 'flex-start',
      },
      warningBtn: {
        backgroundColor: Theme.warningColor,
      },
      marketContent: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: this.Spacing.m3,
      },
      machineName: {
        alignItems: 'center',
        backgroundColor: this.white,
        flexDirection: 'row',
        minHeight: this.Heights.touchTargetHeight2,
      },
      machineText: {
        color: this.darkColor,
        fontSize: this.Fonts.f2,
        fontWeight: this.bold,
      },
      machineTextContainer: {
        flex: 1,
        marginHorizontal: this.Spacing.m2,
      },
      standalone: {
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderColor: this.borderSeparatorColor,
        flex: 1,
      },
      disabledMarket: {
        opacity: 0.5,
      },
      disabledReason: {
        fontSize: this.Fonts.f0,
      },
      actionButton: {
        borderRadius: this.Spacing.m4,
        bottom: this.Spacing.m3,
        elevation: 6,
        paddingHorizontal: this.Spacing.m2,
        paddingVertical: this.Spacing.m2,
        shadowColor: this.overlay,
        shadowOffset: {
          width: this.Spacing.m1,
          height: this.Spacing.m2,
        },
        shadowOpacity: 0.4,
        shadowRadius: this.Spacing.m2,
      },
      actionButtonText: {
        fontSize: this.Fonts.f1,
      },
    });
  }

  ScreenHeight: number;
  ScreenWidth: number;
  ViewportHeight: number;
  Orientation: ScreenOrientations = ScreenOrientations.Portrait;
  ScreenSize: ScreenSizes = ScreenSizes.Medium;
  Spacing: Margins = new Margins();
  Fonts: Fonts = new Fonts();
  FontSizeMultiplier: FontSizeMultiplier = new FontSizeMultiplier();
  Heights: Heights = new Heights();
  menuHeight: number = this.Heights.headerHeight * 0.5;
  bold = 'bold' as const;
  bold600 = '600';
  mainIconUrl: string = Theme.mainIconUrl;
  iconUrl: string = Theme.iconUrl;

  /** COLORS */
  warningColor: string = Theme.warningColor;
  primaryColor: string = Theme.primaryColor;
  headerColor: string = Theme.primaryColor;
  secondaryColor: string = Theme.primaryColor;
  positiveColor: string = Theme.positiveColor;
  dangerColor: string = Theme.dangerColor;
  goldColor: string = Theme.warningColor;
  orangeColor: string = Theme.orangeColor;
  bgColor: string = Theme.white;
  borderSeparatorColor: string = Theme.lightGray;
  white = Theme.white;
  black = Theme.black;
  darkColor = Theme.darkColor;
  lightGray = Theme.lightGray;
  loginBackground: string = Theme.white;
  loginText: string = Theme.black;
  balanceColor: string = Theme.primaryColor;
  inputBgColor: string = Theme.inputBackgroundColor;

  // Navigator Colors
  tabBarBackgroundColor = '#F4F4F4';

  overlay = 'rgba(0, 0, 0, 0.65)';
  imageBackground = '#e9eaec';
  menuPlaceholderColor = '#d2d0d0';
  offwhite = '#fffffff1';
  transparent = 'transparent';
  addCardPopupColor = '#e6e6e6';

  btn(overrideStyles?: any) {
    if (overrideStyles) {
      return StyleSheet.flatten([this.Style.btn, overrideStyles]);
    }

    return this.Style.btn;
  }

  primaryBtn(overrideStyles?: any) {
    if (overrideStyles) {
      return StyleSheet.flatten([
        this.Style.btn,
        this.Style.primaryBtn,
        overrideStyles,
      ]);
    }

    return StyleSheet.flatten([this.Style.btn, this.Style.primaryBtn]);
  }

  warningBtn(overrideStyles?: any) {
    if (overrideStyles) {
      return StyleSheet.flatten([
        this.Style.btn,
        this.Style.warningBtn,
        overrideStyles,
      ]);
    }

    return StyleSheet.flatten([this.Style.btn, this.Style.warningBtn]);
  }

  btnText(overrideStyles?: any) {
    return StyleSheet.flatten([this.Style.btnText, overrideStyles]);
  }

  getHeaderHeight() {
    return Platform.OS === 'android'
      ? this.Heights.headerHeight - getMinusHeight(this.Orientation)
      : this.Heights.headerHeight;
  }
}

class Margins {
  m0 = 0;
  m1 = 4;
  m2 = 8;
  m3 = 16;
  m4 = 32;
  m5 = 64;
  m6 = 128;
  m7 = 256;
  m8 = 512;
}

class Fonts {
  f0 = 12;
  f1 = 16;
  f2 = 21.33;
  f3 = 28.43;
  f4 = 37.9;
  f5 = 50.52;
  f6 = 67.34;
}

class FontSizeMultiplier {
  maxfm0 = 1.0;
  maxfm1 = 1.2;
  maxfm2 = 1.3;
  maxfm3 = 1.4;
  maxfm4 = 1.5;
  maxfm5 = 1.6;
  maxfm6 = 1.7;
  maxfm7 = 1.8;
  maxfm8 = 2.0;
  maxfm9 = 2.2;
  maxfm10 = 2.3;
  maxfm11 = 2.4;
  maxfm12 = 3.0;
}

class Heights {
  h0 = 0;
  h1 = 10;
  h2 = 20;
  h3 = 30;
  h4 = 40;
  h5 = 50;
  h6 = 60;
  h7 = 70;
  h8 = 80;
  headerHeight = 128;
  splashIconHeight = 192;
  touchTargetHeight0 = 24;
  touchTargetHeight1 = 44;
  touchTargetHeight2 = 48;
}

export default new Styles();
