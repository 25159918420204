import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

function EmailLink({size = 640, color = '#fff'}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 640 640">
      <Path
        d="M55.11 317.5c15.42 7.95 229.57 122.12 237.56 126.23 7.99 4.11 16.12 6.03 28.73 6.03s20.74-1.92 28.73-6.03c7.99-4.11 222.14-118.28 237.56-126.23 5.75-2.88 15.42-8.09 17.52-13.98 3.64-10.42-.28-14.39-15.84-14.39H53.43c-15.56 0-19.48 4.11-15.84 14.39 2.1 6.03 11.77 11.1 17.52 13.98z"
        fill={color}
        fillRule="evenodd"
      />
      <Path
        d="M599.88 323.66c-7.59 3.8-55.1 36.38-106.15 70.5l-4.65 3.11c-1.55 1.04-3.11 2.07-4.66 3.11l-4.67 3.11-4.67 3.1-4.67 3.1c-18.65 12.35-37.04 24.36-53.42 34.73l115.2 126.78c2.8 2.74 4.06 6.03 2.52 7.68-1.68 1.51-5.33.68-8.27-1.92L388.25 462.93c-20.88 13.16-35.6 22.2-38.12 23.58-10.79 5.34-18.36 6.03-28.73 6.03s-17.94-.69-28.73-6.03c-2.66-1.37-17.24-10.42-38.12-23.58L116.36 576.96c-2.8 2.74-6.59 3.56-8.27 1.92-1.68-1.51-.42-4.93 2.38-7.68l115.06-126.78c-16.38-10.36-34.84-22.37-53.59-34.73l-4.69-3.1-4.7-3.1c-3.92-2.59-7.83-5.18-11.73-7.77l-4.67-3.1c-21.75-14.45-42.8-28.56-60.3-40.23l-5.5-3.66c-20.3-13.51-34.93-23.1-38.84-25.06-12.02-6.01-12.99.73-13.03 6.28v281.41c0 12.75 19.2 28.65 32.93 28.65h519.97c13.73 0 30.13-16.04 30.13-28.65V327.75c-.05-4.96-1.03-9.32-11.63-4.08zM205.96 269.11c4.74 4.71 11.01 7.06 18.01 7.06s13.35-2.35 18.01-7.06l84.41-78.71c10.05-9.42 10.05-24.56 0-33.9s-26.29-9.34-36.34 0l-40.52 37.83V24.01C249.53 10.75 238.03 0 223.8 0s-25.73 10.75-25.73 24.01v170.31l-40.52-37.83c-10.05-9.34-26.29-9.34-36.34 0-10.05 9.34-10.05 24.56 0 33.9l84.73 78.71z"
        fill={color}
        fillRule="evenodd"
      />
      <Path
        d="M345.06 122.03l40.52-37.83v170.31c0 13.26 11.5 24.01 25.73 24.01s25.72-10.75 25.72-24.01V84.2l40.52 37.83c10.05 9.34 26.29 9.34 36.34 0 10.05-9.34 10.05-24.56 0-33.9L429.14 9.41c-4.74-4.71-11.01-7.06-18.01-7.06s-13.34 2.36-18.01 7.06l-84.41 78.71c-10.05 9.42-10.05 24.56 0 33.9s26.29 9.34 36.34 0z"
        fill={color}
        fillRule="evenodd"
      />
    </Svg>
  );
}

export default EmailLink;
