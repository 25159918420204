import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import AVText from '../AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../RoundedButton';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import GiftItem from './GiftItem';
type SnackSectionProps = {
  giftFeeds: Array<GiftItemType>;
  onSendASnackClick: () => void;
  onViewMoreGiftsClick: () => void;
};
export default function SnackSection(props: SnackSectionProps) {
  const {giftFeeds} = props;
  return (
    <View style={styles.container}>
      <View style={styles.headerViewStyle}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          style={styles.title}
        >
          {Localized.Labels.gift_feed}
        </AVText>
        <RoundedButton
          buttonType={ButtonType.normal}
          onPress={props.onSendASnackClick}
          accessible={true}
          accessibilityLabel={Localized.Buttons.send_a_snack}
          accessibilityRole="button"
          role="button"
          aria-label={Localized.Buttons.send_a_snack}
          text={Localized.Buttons.send_a_snack}
          color={Styles.orangeColor}
          containerStyle={styles.sendSnackButton}
        />
      </View>
      <View style={styles.giftFeedViewStyle}>
        {giftFeeds.length > 0 ? (
          <View>
            {giftFeeds.map((item, index) => {
              return <GiftItem key={item.snackId} gift={item} />;
            })}
            <RoundedButton
              containerStyle={styles.viewMoreButton}
              buttonType={ButtonType.outline}
              accessible={true}
              accessibilityLabel={Localized.Buttons.view_more_gifts}
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.view_more_gifts}
              onPress={props.onViewMoreGiftsClick}
              text={Localized.Buttons.view_more_gifts}
            />
          </View>
        ) : (
          <View style={styles.noGiftsViewStyle}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessible={true}
              accessibilityLabel={Localized.Labels.no_gift_to_display}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.no_gift_to_display}, text`}
              style={styles.noGiftsMessageText}
            >
              {Localized.Labels.no_gift_to_display}
            </AVText>
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m3,
  },
  headerViewStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Styles.Spacing.m2,
  },
  giftFeedViewStyle: {
    flex: 1,
  },
  title: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m0,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m1,
    textAlign: 'left',
  },
  noGiftsViewStyle: {
    flex: 1,
    alignItems: 'center',
    marginTop: Styles.Spacing.m3,
  },
  noGiftsMessageText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginHorizontal: Styles.Spacing.m2,
  },
  sendSnackButton: {
    alignSelf: 'flex-end',
  },
  viewMoreButton: {
    alignSelf: 'center',
    marginTop: Styles.Spacing.m3,
  },
});
