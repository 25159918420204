import * as React from 'react';
import {
  PixelRatio,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
import {TouchableOpacityProps} from 'react-native';

export enum ButtonType {
  normal = 'normal',
  outline = 'outline',
  action = 'action',
}
interface RoundedButtonProps extends TouchableOpacityProps {
  onPress?: () => any;
  text: string;
  containerStyle?: StyleProp<ViewStyle>;
  maxFontSizeMultiplier?: number;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  buttonType: ButtonType;
  color?: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  icon?: React.ReactNode;
  hideAccessibleElements?: boolean;
}
export default function RoundedButton(props: RoundedButtonProps) {
  const buttonColor = props.disabled
    ? Styles.lightGray
    : props.color || Styles.primaryColor;
  let fillColor = Styles.primaryColor;
  let textColor = Styles.white;

  if (props.buttonType === 'outline') {
    fillColor = Styles.transparent;
    textColor = props.disabled
      ? Styles.lightGray
      : props.color || Styles.primaryColor;
  } else {
    fillColor = props.disabled
      ? Styles.lightGray
      : props.color || Styles.primaryColor;
    textColor = Styles.white;
  }

  return (
    <AVTouchableOpacity
      accessible={true}
      accessibilityElementsHidden={props?.hideAccessibleElements}
      importantForAccessibility={
        props?.hideAccessibleElements ? 'no-hide-descendants' : 'yes'
      }
      accessibilityRole={'button'}
      accessibilityLabel={props?.accessibilityLabel}
      accessibilityHint={props?.accessibilityHint}
      activeOpacity={0.2}
      disabled={props.disabled}
      style={[
        styles.container,
        props.buttonType === 'action' && styles.actionButton,
        props.containerStyle,
        {
          backgroundColor: fillColor,
          borderColor: buttonColor,
        },
        props.buttonType !== 'action' && {paddingVertical: Styles.Spacing.m2},
      ]}
      onPress={props.onPress}
    >
      <View style={styles.insideButton}>
        {props.icon}

        <AVText
          maxFontSizeMultiplier={
            props.maxFontSizeMultiplier ?? Styles.FontSizeMultiplier.maxfm8
          }
          style={[
            styles.text,
            {color: textColor},
            props.buttonType === 'action' && styles.actionButtonText,
            props.textStyle,
          ]}
        >
          {props.text}
        </AVText>
      </View>
    </AVTouchableOpacity>
  );
}
const styles = StyleSheet.create({
  actionButton: {
    borderRadius: Styles.Spacing.m4,
    bottom: Styles.Spacing.m3,
    elevation: 6,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: 8,
    position: 'absolute',
    right: Styles.Spacing.m3,
    shadowColor: Styles.overlay,
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    shadowRadius: Styles.Spacing.m2,
  },
  actionButtonText: {
    fontSize: Styles.Fonts.f1,
  },
  container: {
    alignSelf: 'flex-start',
    backgroundColor: Styles.white,
    borderColor: Styles.primaryColor,
    borderRadius: PixelRatio.roundToNearestPixel(
      Styles.Spacing.m3 + Styles.Spacing.m2,
    ),
    borderWidth: PixelRatio.roundToNearestPixel(0.5),
  },
  insideButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: PixelRatio.roundToNearestPixel(0.5),
    paddingHorizontal: 12,
    paddingVertical: PixelRatio.roundToNearestPixel(4),
  },
  text: {
    color: Styles.primaryColor,
    fontWeight: 'bold',
  },
});
