export const Keyboards = {
  email: {
    type: 'email-address',
    length: 75,
  },
  text: {
    type: 'phone-pad',
    length: 10,
  },
};
export enum InputTypes {
  email = 'email',
  text = 'text',
}
