import creditCardType from 'credit-card-type';
// decimal and comma are optional
const ValidMoneyRegex =
  /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;
export function getCardType(number: string): string {
  const results = creditCardType(number);

  if (results.length === 1) {
    return results[0].type;
  }

  return '';
}
export function parseValidMoney(value: string): number {
  if (ValidMoneyRegex.test(value)) {
    if (typeof value === 'number') {
      return value;
    }

    return Number(value.replace(/[$,]/g, ''));
  }

  return 0;
}
export default {
  getCardType,
  parseValidMoney,
};
