import React from 'react';
import {View, Image, StyleSheet, Dimensions, ViewStyle} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Styles from '../Styles';
import Icon from './AVIcon';
import AVText from './AVText';
import MarketCell from './MarketCell';
import CoffeeMachineCell from './CoffeeMachineCell';
import type {MachineType} from 'src/types/MachineType';
import AccountStore from 'src/stores/AccountStore';
import AVTouchableOpacity from './AVTouchableOpacity';
import {ensureHttps} from 'src/services/StringUtils';
import {WithLocalizedProps} from 'src/types/PropTypes';

const deviceScreen = Dimensions.get('window');
type MachineCellProps = WithLocalizedProps & {
  onPlanogramClick: (machine: MachineType) => void;
  onSelect: (machine: MachineType) => void;
  removeLocation: (machine: MachineType) => void;
  machine: MachineType;
};

class MachineCell extends React.Component<MachineCellProps> {
  constructor(props: MachineCellProps) {
    super(props);
    this.machineSelect = this.machineSelect.bind(this);
    this.onPlanogramClick = this.onPlanogramClick.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
  }

  shouldComponentUpdate(nextProps: MachineCellProps) {
    const debugMode = AccountStore.isDebug();
    const nameSame = nextProps.machine.name === this.props.machine.name;
    const hidePlanogramSame =
      nextProps.machine.hidePlanogram === this.props.machine.hidePlanogram;
    const letterSame = nextProps.machine.letter === this.props.machine.letter;
    const colorSame = nextProps.machine.color === this.props.machine.color;
    const imageUrlSame =
      nextProps.machine.imageUrl === this.props.machine.imageUrl;
    return (
      debugMode ||
      !nameSame ||
      !hidePlanogramSame ||
      !letterSame ||
      !colorSame ||
      !imageUrlSame
    );
  }

  onPlanogramClick() {
    if (this.props.onPlanogramClick) {
      this.props.onPlanogramClick(this.props.machine);
    }
  }

  machineSelect() {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.machine);
    }
  }

  removeLocation() {
    if (this.props.removeLocation) {
      this.props.removeLocation(this.props.machine);
    }
  }

  render() {
    if (this.props.machine.localType === 'market') {
      return (
        <MarketCell
          {...this.props}
          onDeletePressed={this.removeLocation}
          machineSelect={this.machineSelect}
        />
      );
    } else if (this.props.machine.localType === 'coffee') {
      return (
        <CoffeeMachineCell {...this.props} machineSelect={this.machineSelect} />
      );
    }

    let letterText: JSX.Element | null = null;

    if (this.props.machine.letter && this.props.machine.letter.length > 0) {
      letterText = (
        <View
          style={[
            styles.letterView,
            this.props.machine.color
              ? ({backgroundColor: this.props.machine.color} as ViewStyle)
              : null,
          ]}
        >
          <AVText style={[styles.letter]}>{this.props.machine.letter}</AVText>
        </View>
      );
    }

    let image: JSX.Element | null = null;
    if (this.props.machine.imageUrl) {
      image = (
        <Image
          style={styles.machineImage}
          source={{
            uri: ensureHttps(this.props.machine.imageUrl),
          }}
        />
      );
    } else {
      image = (
        <View style={[styles.emptyImage]}>
          <FontAwesome5Pro
            name="calculator"
            color={Styles.primaryColor}
            style={{}}
            size={Styles.Fonts.f4}
            solid
          />
        </View>
      );
    }

    let planogram: JSX.Element | null = null;
    if (!this.props.machine.hidePlanogram) {
      planogram = (
        <AVTouchableOpacity
          style={styles.planogram}
          onPress={this.onPlanogramClick}
          accessibilityLabel={'Planogram ' + this.props.machine.deviceId}
        >
          <Icon
            name="md-apps"
            color={Styles.primaryColor}
            size={Styles.Fonts.f4}
            style={styles.icon}
          />
        </AVTouchableOpacity>
      );
    }

    return (
      <View style={[styles.machine]}>
        <AVTouchableOpacity
          style={styles.machineName}
          onPress={this.machineSelect}
          accessible={false}
          accessibilityRole="button"
          role="button"
          accessibilityLabel={this.props.machine.name}
          aria-label={this.props.machine.name}
        >
          {image}
          <View style={styles.pairContainer}>
            <View style={styles.machineNameLetter}>
              <View style={styles.nameSection}>
                <AVText numberOfLines={1} style={styles.machineText}>
                  {this.props.machine.name || ''}
                </AVText>
                <AVText style={styles.machineLabelText}>
                  {this.props.strings.Labels.vending_machine}
                </AVText>
              </View>
              {letterText}
            </View>
          </View>
          {planogram}
          <FontAwesome5Pro
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        </AVTouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  disabledMachine: {
    opacity: 0.5,
  },
  emptyImage: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m3,
    transform: [
      {
        rotate: '-90deg',
      },
    ],
    width: Styles.Spacing.m5,
  },
  icon: {
    height: Styles.Heights.h3,
    textAlign: 'center',
    width: Styles.Heights.h3,
  },
  letter: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
  },
  letterView: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: Styles.Spacing.m2,
    padding: Styles.Spacing.m1,
  },
  machine: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
  },
  machineImage: {
    height: deviceScreen.height / 6,
    width: deviceScreen.height / 11,
  },
  machineLabelText: {
    textTransform: 'capitalize',
  },
  machineName: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: Styles.Spacing.m3,
  },
  machineNameLetter: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  machineText: {
    color: Styles.darkColor,
    flex: 1,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
  },
  nameSection: {
    flex: 1,
  },
  pairContainer: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
  },
  planogram: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: Styles.Spacing.m2,
  },
  rawName: {},
});
export default MachineCell;
