export const initStripe = () => ({});
export const presentApplePay = () => ({});
export const createToken = () => ({});
export const CardForm = () => ({});
export const confirmApplePayPayment = () => ({});
export const isApplePaySupported = () => ({});
export const createGooglePayPaymentMethod = () => ({});
export const initGooglePay = () => ({});
export const handleNextAction = () => ({});
export const createPaymentMethod = () => ({});
