enum TransactionConstants {
  AUTHORIZE = 'AUTHORIZE',
  AUTHORIZE_ERROR = 'AUTHORIZE_ERROR',
  TEST_TRANSACTION = 'TEST_TRANSACTION',
  SHOPPING_CART_UPDATED = 'SHOPPING_CART_UPDATED',
  SHOPPING_CART_CANCELLED = 'SHOPPING_CART_CANCELLED',
  CHECKOUT = 'CHECKOUT',
  PICKUP_TIME_CHANGED = 'PICKUP_TIME_CHANGED',
  AVAILABLE_TIMES_CHANGED = 'AVAILABLE_TIMES_CHANGED',
  PICKUP_LOCATION_CHANGED = 'PICKUP_LOCATION_CHANGED',
  ZERO_INVENTORY_UPDATE = 'ZERO_INVENTORY_UPDATE',
  SHOPPING_CART_TRANSACTIONS_CLEARED = 'SHOPPING_CART_TRANSACTIONS_CLEARED',
  AFTER_TAX_PROMO_APPLIED = 'AFTER_TAX_PROMO_APPLIED',
}

export default TransactionConstants;
