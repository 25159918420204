import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import Styles from 'src/components/Styles';
type LockerProps = {
  size: number | string;
  color?: string;
};
export default class Locker extends React.Component<LockerProps> {
  render() {
    let {size, color} = this.props;
    color = Styles.goldColor;
    return (
      <Svg width={size} height={size} viewBox="0 0 640 640">
        <G id="Layer_2" data-name="Layer 2">
          <G id="icons">
            <G id="lockers">
              <G id="Layer_157_copy_2" data-name="Layer 157 copy 2">
                <G>
                  <Path
                    d="M203.35,132.86H20a20,20,0,0,0-20,20v68.56a20,20,0,0,0,20,20H203.35a20,20,0,0,0,20-20V152.86A20,20,0,0,0,203.35,132.86ZM30,222a7.75,7.75,0,0,1-7.76,7.76H20.76A7.75,7.75,0,0,1,13,222V166.51a7.75,7.75,0,0,1,7.76-7.76h1.48A7.75,7.75,0,0,1,30,166.51Z"
                    fill={color}
                  />
                  <Path
                    d="M203.35,0H20A20,20,0,0,0,0,20V88.56a20,20,0,0,0,20,20H203.35a20,20,0,0,0,20-20V20A20,20,0,0,0,203.35,0ZM30,89a7.75,7.75,0,0,1-7.76,7.76H20.76A7.75,7.75,0,0,1,13,89V33.51a7.75,7.75,0,0,1,7.76-7.76h1.48A7.75,7.75,0,0,1,30,33.51Z"
                    fill={color}
                  />
                  <Path
                    d="M203.35,265.72H20a20,20,0,0,0-20,20v68.56a20,20,0,0,0,20,20H203.35a20,20,0,0,0,20-20V285.72A20,20,0,0,0,203.35,265.72ZM30,355a7.75,7.75,0,0,1-7.76,7.76H20.76A7.75,7.75,0,0,1,13,355V299.51a7.75,7.75,0,0,1,7.76-7.76h1.48A7.75,7.75,0,0,1,30,299.51Z"
                    fill={color}
                  />
                  <Path
                    d="M452,265.72H268.65a20,20,0,0,0-20,20v68.56a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V285.72A20,20,0,0,0,452,265.72ZM280,355a7.75,7.75,0,0,1-7.76,7.76h-1.48A7.75,7.75,0,0,1,263,355V299.51a7.75,7.75,0,0,1,7.76-7.76h1.48a7.75,7.75,0,0,1,7.76,7.76Z"
                    fill={color}
                  />
                  <Path
                    d="M203.35,398.58H20a20,20,0,0,0-20,20V620a20,20,0,0,0,20,20H203.35a20,20,0,0,0,20-20V418.58A20,20,0,0,0,203.35,398.58ZM30,619a7.75,7.75,0,0,1-7.76,7.76H20.76A7.75,7.75,0,0,1,13,619V563.51a7.75,7.75,0,0,1,7.76-7.76h1.48A7.75,7.75,0,0,1,30,563.51Z"
                    fill={color}
                  />
                  <Path
                    d="M452,398.58H268.65a20,20,0,0,0-20,20v68.56a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V418.58A20,20,0,0,0,452,398.58ZM280,486a7.75,7.75,0,0,1-7.76,7.76h-1.48A7.75,7.75,0,0,1,263,486V430.51a7.75,7.75,0,0,1,7.76-7.76h1.48a7.75,7.75,0,0,1,7.76,7.76Z"
                    fill={color}
                  />
                  <Path
                    d="M452,531.44H268.65a20,20,0,0,0-20,20V620a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V551.44A20,20,0,0,0,452,531.44ZM280,619a7.75,7.75,0,0,1-7.76,7.76h-1.48A7.75,7.75,0,0,1,263,619V563.51a7.75,7.75,0,0,1,7.76-7.76h1.48a7.75,7.75,0,0,1,7.76,7.76Z"
                    fill={color}
                  />
                  <Path
                    d="M549.13,156.74c15.24,0,27.71-14.81,27.71-32.9h0c0-18.09-12.47-32.89-27.71-32.89s-27.71,14.8-27.71,32.89h0C521.42,141.93,533.89,156.74,549.13,156.74Z"
                    fill={color}
                  />
                  <Path
                    d="M639.74,608.75,597.48,303.86l15.63-107.7a13.46,13.46,0,0,0,.06-3.75c-1.55-11.49-14.64-20.53-30.41-20.53H522.08a23.68,23.68,0,0,0-3.42-.81c-14.62-2.26-33.32-4.68-46.66-6.93V20A20,20,0,0,0,452,0H268.65a20,20,0,0,0-20,20V221.42a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V208.67q2.81.56,5.83,1.12c5.33,1,10.61,2,15.36,2.78l9.66,89-2.33,309.3a27,27,0,0,0,26.8,27.2c.83,0,1.64,0,2.45-.09,13.77-1.14,15.15-25,15.26-39.12L557.5,423.3l36.56,179.33c1.93,14,6.4,37.46,20.2,36.8.81,0,1.63-.11,2.44-.23A27,27,0,0,0,639.74,608.75ZM454,221.42a2,2,0,0,1-2,2H268.65a2,2,0,0,1-2-2V20a2,2,0,0,1,2-2H452a2,2,0,0,1,2,2V154.44c-7.28-8.59-19.7-21.84-37.58-48.78-6.89-10.37-18.06-16.33-28.44-9.44s-9.16,17.45-2.27,27.82c17.91,27,37.91,57.67,41.18,63.56,3.94,8.88,11.4,13.19,27.11,17.17Z"
                    fill={color}
                  />
                </G>
              </G>
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
