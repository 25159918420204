import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BaseSummaryScreen from './BaseSummaryScreen';
import ReceiptItem from '../../../elements/cart/ReceiptItem';
import Localized from 'src/constants/AppStrings';

type RefundSummaryProps = {
  refundRequest: number;
  balanceBeforeRefund: number;
  balanceAfterRefund: number;
  refundAmount: number;
};

const RefundSummaryScreen: React.FC<RefundSummaryProps> = (
  props: RefundSummaryProps,
) => (
  <BaseSummaryScreen
    strings={Localized}
    headlineText={Localized.Success.refund_complete}
    titleText={Localized.Labels.summary}
  >
    <ReceiptItem
      desc={Localized.Labels.funding_refund_request}
      amount={props.refundRequest * -1}
      key="refund-request"
      accessibilityLabel="refundRequest"
    />
    <ReceiptItem
      desc={Localized.Labels.subtotal}
      amount={props.refundRequest * -1}
      key="subtotal"
      accessibilityLabel="subtotal"
    />
    <ReceiptItem
      desc={Localized.Labels.balance_available}
      amount={props.balanceBeforeRefund}
      key="balance-available"
      accessibilityLabel="balanceAvailable"
    />
    <ReceiptItem
      desc={Localized.Labels.total_refunded}
      amount={props.refundAmount * -1}
      key="total-refunded"
      boldLabel
      accessibilityLabel="totalRefunded"
    />
    <ReceiptItem
      desc={Localized.Labels.new_balance}
      amount={props.balanceAfterRefund}
      key="new-balance"
      accessibilityLabel="newBalance"
    />
  </BaseSummaryScreen>
);

export default withForwardedNavigationParams<RefundSummaryProps>()(
  RefundSummaryScreen,
);
