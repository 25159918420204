import Analytics from 'src/nativeModules/Analytics';
import {store} from '../redux/store';
import AccountStore from '../stores/AccountStore';
import Settings from '../Settings';

class BalanceChangedEvent {
  trackEvent(source: string, newBalance: string) {
    //TODO waiting the store is ready, will update later
    setTimeout(() => {
      const logData = {
        email: store.getState().account.account.email || '',
        locationId: store.getState().account.account.locationId || '',
        locationName: AccountStore.getLocationName() || '',
        environment: store.getState().environment.env,
        app: Settings.buildType,
        source,
        newBalance,
      };
      Analytics.trackEvent('BalanceChanged', logData);
    }, 300);
  }
}

export default new BalanceChangedEvent();
