export default {
  SystemUnavailable: 0,
  SystemRecover: 1,
  WaitForDrinkSelection: 2,
  WaitForSizeSelection: 3,
  WaitForVendingCardSwipe: 4,
  WaitForVendingCheckout: 5,
  WaitForCupPlacement: 6,
  ReadyForStartDispense: 7,
  Dispensing: 8,
  Cancelled: 9,
  Resetting: 10,
  WaitForCupRemoval: 11,
  DispenseFinished: 12,
  WaitForOrderId: 13,
  InvalidPinEntry: 14,
  InvalidControlLevel: 253,
  DispenseNotAvailable: 254,
  Undefined: 255,
};
