import * as React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import NavActions from 'src/actions/NavActions';
import Button from '../Button';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../../screens/BaseAccountSetupScreen';
import ScanditView from '../../elements/ScanditView';
import Localized from 'src/constants/AppStrings';
import {WithLocalizedProps} from 'src/types/PropTypes';
type CreatePasswordScanScreenProps = WithLocalizedProps & {
  barcodeScanned: (code: string) => void;
  label: string;
  scanning: boolean;
  previousRoute?: string;
};

class CreatePasswordScanScreen extends React.Component<CreatePasswordScanScreenProps> {
  constructor(props: CreatePasswordScanScreenProps) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
    this.codeScanned = this.codeScanned.bind(this);
  }

  codeScanned(code: string) {
    this.props.barcodeScanned(code);
  }

  handleBack() {
    NavActions.pop();
  }

  render() {
    return (
      <BaseAccountSetupScreen
        previousRoute={this.props.previousRoute}
        headlineText={this.props.strings.Labels.setup_app}
        instructionText={this.props.label}
      >
        {this.props.scanning && (
          <Text
            accessible={true}
            accessibilityElementsHidden={false}
            importantForAccessibility="auto"
            accessibilityLabel={Localized.Labels.scanning_viewfinder_active}
            aria-label={Localized.Labels.scanning_viewfinder_active}
          ></Text>
        )}
        <ScanditView
          style={styles.scanditContainer}
          barcodeScanned={this.codeScanned}
          scanning={this.props.scanning}
        />
        <View style={styles.footer}>
          <Button
            accessible={true}
            accessibilityLabel={Localized.Buttons.cancel}
            aria-label={Localized.Labels.scanning_viewfinder_active}
            overrideStyles={styles.negativeBtn}
            onPress={this.handleBack}
            text={this.props.strings.Buttons.cancel}
          />
        </View>
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  footer: {
    alignSelf: 'stretch',
    marginHorizontal: Styles.Spacing.m2,
  },
  negativeBtn: {
    borderColor: Styles.white,
    borderWidth: 1,
    height: Styles.Heights.h4,
    marginVertical: Styles.Spacing.m2,
  },
  scanditContainer: {
    alignSelf: 'stretch',
    borderRadius: Styles.Spacing.m3,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
  },
});
export default CreatePasswordScanScreen;
