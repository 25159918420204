import moment from 'moment';
import Events from 'src/logging/Events';
import SQLite from 'src/nativeModules/SQLite';
import CrashlyticsEvents from 'src/logging/Crashlytics';
export type SliceRecordType = {
  fileName: string;
  dateAdded: string;
  checksum: string;
  startByte: number;
  endByte: number;
  id: string;
};
const DATABASE_NAME = 'SyncDb.db';
const DATABASE_VERSION = '1.0';
const DATABASE_DISPLAYNAME = '365 Pay Sync Database';
const DATABASE_SIZE = 200000;
const TABLE_NAME = 'SyncTable';
const UPDATE_SLICE_TABLE = {
  tableName: 'UpdateSliceTable',
  idColumn: 'ID',
  fileNameColumn: 'FILE_NAME',
  dateAddedColumn: 'DATE_ADDED',
  checksumColumn: 'CHECKSUM',
  startByteColumn: 'START_BYTE',
  endByteColumn: 'END_BYTE',
};

class DBHelper {
  _db: any;

  constructor() {
    SQLite.enablePromise(true);
  }

  _getRows(
    tx: {
      rows: {
        length: number;
        item: (i: number) => any;
      } | void;
    } | void,
  ) {
    const rows = [];

    if (tx && tx.rows) {
      for (let i = 0; i < tx.rows.length; i++) {
        rows.push(tx.rows.item(i));
      }
    }

    return rows;
  }

  async _deleteOldUpdateTable() {
    await this._db.executeSql('DROP TABLE IF EXISTS UpdateFileTable');
  }

  async _getUpdateSlices(sql, param): Promise<Array<SliceRecordType | null>> {
    const [tx] = await this._db.executeSql(sql, param);

    const rows = this._getRows(tx);

    return rows.map(this._convertToUpdateSliceRecord);
  }

  _executeInBatches(array, batchSize, fn) {
    const promises = [];

    for (let i = 0; i < array.length; i += batchSize) {
      const batch = array.slice(i, i + batchSize);
      promises.push(fn(batch));
    }

    return Promise.all(promises);
  }

  _convertToUpdateSliceRecord(row): SliceRecordType | null {
    if (!row) {
      return null;
    }

    return {
      id: row[UPDATE_SLICE_TABLE.idColumn],
      fileName: row[UPDATE_SLICE_TABLE.fileNameColumn],
      dateAdded: row[UPDATE_SLICE_TABLE.dateAddedColumn],
      checksum: row[UPDATE_SLICE_TABLE.checksumColumn],
      startByte: row[UPDATE_SLICE_TABLE.startByteColumn],
      endByte: row[UPDATE_SLICE_TABLE.endByteColumn],
    };
  }

  async initDb() {
    try {
      const DB = await SQLite.openDatabase(
        DATABASE_NAME,
        DATABASE_VERSION,
        DATABASE_DISPLAYNAME,
        DATABASE_SIZE,
      );
      this._db = DB;
      this.createTable();
      this.createUpdateSliceTable();

      this._deleteOldUpdateTable();
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'DBService:InitDb',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'DBService:InitDb',
        error.message ? error.message : error.toString(),
      );
    }
  }

  close() {
    if (this._db) {
      return this._db.close();
    }
  }

  createTable() {
    this._db
      .executeSql(
        `CREATE TABLE IF NOT EXISTS ${TABLE_NAME} (
          DEVICEID VARCHAR(50) NOT NULL,
          TYPE VARCHAR(25) NOT NULL,
          DATA TEXT NOT NULL
        );`,
      )
      .catch((error) => {
        CrashlyticsEvents.log(
          'Exception',
          'DBService:CreateTable',
          error.message ? error.message : error.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'DBService:CreateTable',
          error.message ? error.message : error.toString(),
        );
      });
  }

  createUpdateSliceTable() {
    this._db
      .executeSql(
        `CREATE TABLE IF NOT EXISTS ${UPDATE_SLICE_TABLE.tableName} (
          ID INTEGER PRIMARY KEY AUTOINCREMENT,
          FILE_NAME TEXT,
          DATE_ADDED TEXT,
          CHECKSUM TEXT,
          START_BYTE INTEGER,
          END_BYTE INTEGER
        );`,
      )
      .catch((error) => {
        CrashlyticsEvents.log(
          'Exception',
          'DBService:CreateUpdateSliceTable',
          error.message ? error.message : error.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'DBService:CreateUpdateSliceTable',
          error.message ? error.message : error.toString(),
        );
      });
  }

  async insertUpdateSlice(sliceRecord: SliceRecordType) {
    const dateAdded = moment().toJSON();
    const sql = `INSERT INTO ${UPDATE_SLICE_TABLE.tableName} (FILE_NAME,DATE_ADDED,CHECKSUM,START_BYTE,END_BYTE) VALUES (?,?,?,?,?)`;
    const result = await this._db.executeSql(sql, [
      sliceRecord.fileName,
      dateAdded,
      sliceRecord.checksum,
      sliceRecord.startByte,
      sliceRecord.endByte,
    ]);

    if (result && result.length > 0) {
      return result[0];
    }

    return null;
  }

  getUpdateSlices(checksum: string) {
    const sql = `SELECT * FROM ${UPDATE_SLICE_TABLE.tableName} WHERE ${UPDATE_SLICE_TABLE.checksumColumn}=?`;
    return this._getUpdateSlices(sql, [checksum]);
  }

  getNonMatchingUpdateSlices(checksum: string) {
    const sql = `SELECT * FROM ${UPDATE_SLICE_TABLE.tableName} WHERE ${UPDATE_SLICE_TABLE.checksumColumn}<>?`;
    return this._getUpdateSlices(sql, [checksum]);
  }

  deleteUpdateSlices(ids: Array<string>) {
    const batchSize = 50;
    return this._executeInBatches(ids, batchSize, (idBatch) => {
      const idList = idBatch
        .map((num) => parseInt(num, 10))
        .filter((num) => !isNaN(num))
        .join(',');
      const whereClause = `${UPDATE_SLICE_TABLE.idColumn} IN (${idList})`;
      return this._db.executeSql(
        `DELETE FROM ${UPDATE_SLICE_TABLE.tableName} WHERE ${whereClause}`,
      );
    });
  }

  getResponseRecords(deviceId: string) {
    const sql = `SELECT * FROM ${TABLE_NAME} WHERE TYPE LIKE "%RESPONSE%" AND DEVICEID = ?`;
    return this._db
      .executeSql(sql, [deviceId])
      .then(([tx]) => this._getRows(tx));
  }

  deleteAllDeviceRecords(deviceId: string) {
    return this._db.executeSql(`DELETE FROM ${TABLE_NAME} WHERE DEVICEID = ?`, [
      deviceId,
    ]);
  }

  deleteResponseRecords(deviceId: string) {
    return this._db.executeSql(
      `DELETE FROM ${TABLE_NAME} WHERE TYPE LIKE "%RESPONSE%" AND DEVICEID = ?`,
      [deviceId],
    );
  }

  getRequestRecords() {
    if (this._db) {
      return this._db
        .executeSql(`SELECT * FROM ${TABLE_NAME} WHERE TYPE LIKE "%REQUEST%"`)
        .then(([tx]) => this._getRows(tx));
    }

    return Promise.resolve([]);
  }

  get(deviceId: string, type: string) {
    return this._db
      .executeSql(
        `SELECT * FROM ${TABLE_NAME} WHERE DEVICEID = ? AND TYPE = ?`,
        [deviceId, type],
      )
      .then(([tx]) => this._getRows(tx));
  }

  add(deviceId: string, type: string, data: string) {
    if (this._db) {
      return this._db.executeSql(
        `INSERT INTO ${TABLE_NAME} (DEVICEID, TYPE, DATA) VALUES (?, ?, ?)`,
        [deviceId, type, data],
      );
    }

    return Promise.resolve();
  }

  delete(deviceId: string, type: string) {
    return this._db.executeSql(
      `DELETE FROM ${TABLE_NAME} WHERE DEVICEID = ? AND TYPE = ?`,
      [deviceId, type],
    );
  }
}

export default new DBHelper();
